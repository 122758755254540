import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** Date type */
  Date: any
}

/** トレードリクエストを承認するInput */
export type AcceptTradeRequestInput = {
  /** メッセージ */
  acceptComment?: Maybe<CommunityCommentInput>
  /** TradeNegotiationId */
  id: Scalars['ID']
  /** TradeCandidateId */
  tradeCandidateId: Scalars['String']
}

/** 攻略ミッション */
export type AccomplishedMission = Node & {
  artistId: Scalars['String']
  createdAt: Scalars['Date']
  id: Scalars['ID']
}

/**
 * アカウント認証情報
 *
 * Auth Providerから返される情報をもとにしている
 * 基本的にはこれは利用せず、auth sdkから取得したデータを直接使うか、
 * userを利用する
 */
export type Account = {
  displayName: Scalars['String']
  email: Scalars['String']
  emailVerified: Scalars['Boolean']
  password?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  photoURL?: Maybe<Scalars['String']>
  uid: Scalars['ID']
}

/**
 * アカウント
 * ユーザーとアーティストの2種類あります
 */
export type AccountRecord = Artist | User

/** アカウントステータス */
export enum AccountStatus {
  /** 有効 */
  Active = 'active',
  /** 削除済み（物理削除） */
  Archived = 'archived',
  /** 退会済み（論理削除） */
  Left = 'left',
}

/** アカウントのValidation */
export type AccountValidation = {
  /** AccountID */
  accountId: Scalars['Boolean']
  /** Email */
  email: Scalars['Boolean']
}

/** キャンペーンでの獲得ポイント詳細 */
export type AcquisitionPoints = {
  point: Scalars['Int']
  type: CampaignAcquisitionType
}

/** カート追加インプット */
export type AddToCartInput = {
  /** 追加数 */
  count: Scalars['Int']
  /** アイテムID */
  itemId: Scalars['String']
  /** SKU ID（ある場合） */
  skuId?: Maybe<Scalars['String']>
}

/** Stripe用の住所情報 */
export type Address = {
  city: Scalars['String']
  line1?: Maybe<Scalars['String']>
  line2?: Maybe<Scalars['String']>
  postalCode: Scalars['String']
  state: Scalars['String']
  town: Scalars['String']
}

/** ビジネス情報住所Input（Stripe用） */
export type AddressInput = {
  /** 市区 */
  city: Scalars['String']
  /** 番地 */
  line1: Scalars['String']
  /** ビル、マンション名など */
  line2: Scalars['String']
  /** 郵便番号 */
  postalCode?: Maybe<Scalars['String']>
  /** 都道府県 */
  state: Scalars['String']
  /** 町村 */
  town: Scalars['String']
}

/** アーティストバナー追加Input */
export type AdminAddArtistBannerInput = {
  link: BannerLinkInput
  tmpPath: Scalars['String']
}

/** イベントにコレクションを追加するInput */
export type AdminAddCollecitonToEventInput = {
  /** コレクションID配列 */
  collectionIds: Array<Scalars['String']>
  /** イベントID */
  eventId: Scalars['ID']
}

/** Organizationバナー追加Input */
export type AdminAddEventBannerInput = {
  /** 遷移先リンク */
  link: BannerLinkInput
  /** 画像パス */
  tmpPath: Scalars['String']
}

/** Organizationバナー追加Input */
export type AdminAddOrganizationBannerInput = {
  link: BannerLinkInput
  text: Scalars['String']
  tmpPath: Scalars['String']
}

/** Admin用先行販売抽選応募キャンセルインプット */
export type AdminCancelPreSaleLotteryInput = {
  /** 先行販売ID */
  preSaleId: Scalars['String']
  /** キャンセルするユーザーID */
  userId: Scalars['String']
}

/** 手数料追加徴収インプット */
export type AdminChagrgeCheckoutFeeInput = {
  /** 対象のカート決済ID */
  cartTransactionOrderId: Scalars['String']
  /** コレクション手数料 */
  collectionFee?: Maybe<Scalars['Int']>
  /** コレクション手数料のコレクションID */
  collectionFeeId?: Maybe<Scalars['String']>
  /** 説明（Stripeの領収書や明細に記載される） */
  description: Scalars['String']
  /**
   * DBへの記録のみとするかどうか
   * trueの場合、実際にStripeの請求は行われない
   * falseの場合、Stripeで同時に決済される
   */
  onlyRecord?: Maybe<Scalars['Boolean']>
  /** 配送手数料 */
  shippingFee?: Maybe<Scalars['Int']>
  /** 徴収するユーザーID */
  userId: Scalars['String']
}

/** Admin用 プリペイドポイントチャージInput */
export type AdminChargePrepaidPointInput = {
  points: Scalars['Int']
  userId: Scalars['String']
}

/** リセール振込完了インプット */
export type AdminCompleteResaleItemPaymentInput = {
  /** 完了にするリセールID配列 */
  resaleIds: Array<Scalars['ID']>
}

/** アイテム一括作成用インプット */
export type AdminCreateAcceptedItemBulkInput = {
  /** アイテム作成用csv storage tmp path */
  csvPath: Scalars['String']
}

/** Admin用コレクション作成Input */
export type AdminCreateCollectionInput = {
  artistId: Scalars['String']
  /** 背景ぼかし画像パス */
  backgroundBlurImagePath?: Maybe<Scalars['String']>
  /** 背景画像パス */
  backgroundImagePath?: Maybe<Scalars['String']>
  description: Scalars['String']
  /** 公開終了日 */
  endedAt?: Maybe<Scalars['Date']>
  /** ストアで公開するかどうか */
  isPublishOnStore?: Maybe<Scalars['Boolean']>
  /** コレクションのラベル */
  labels?: Maybe<Array<Maybe<CollectionLabelInput>>>
  /** NFT情報(チェーン別) */
  nft?: Maybe<CollectionNftInput>
  /** OrganizationId */
  organizationId?: Maybe<Scalars['String']>
  /** 公開開始日 */
  startedAt?: Maybe<Scalars['Date']>
  /** 画像パス */
  thumbnailPath: Scalars['String']
  title: Scalars['String']
}

/** イベント作成Input */
export type AdminCreateEventInput = {
  /** アーティストID */
  artistId?: Maybe<Scalars['String']>
  /** イベント非開催日 */
  closeDate?: Maybe<Array<Scalars['Date']>>
  /** イベント説明 */
  description: Scalars['String']
  /** イベント終了日時 */
  endedAt?: Maybe<Scalars['Date']>
  /** イベント位置情報 */
  location?: Maybe<GpsDataInput>
  /** 商品購入前に表示する注意文言 */
  note?: Maybe<Scalars['String']>
  /** OrganizationId */
  organizationId: Scalars['String']
  /** イベント開始日時 */
  startedAt?: Maybe<Scalars['Date']>
  /** サムネイル画像tmpパス */
  thumbnailPath: Scalars['String']
  /** イベントタイトル */
  title: Scalars['String']
}

/** イベント座席作成Input */
export type AdminCreateEventSeatInput = {
  /** イベントID */
  eventId: Scalars['String']
  /** 座席テンプレートID */
  seatTemplateId: Scalars['String']
  /** イベントで販売するチケットアイテムIDとSeatSectionIdのマッピング */
  ticketUidSeatSections: Array<TicketUidSeatSectionInput>
}

/** ログインボーナス作成インプット */
export type AdminCreateLoginBonusInput = {
  bonuses: Array<LoginBonusesInput>
  endDate: Scalars['Date']
  organizationId: Scalars['String']
  startDate: Scalars['Date']
  type: Scalars['String']
}

/** Organization作成Input */
export type AdminCreateOrganizationInput = {
  /** Application key */
  applicationKey?: Maybe<Scalars['String']>
  /** Organizationに所属させるアーティストID配列 */
  artistIds?: Maybe<Array<Scalars['String']>>
  /** RarityIcon link URL */
  gachaRarityIcons?: Maybe<OrganizationGachaRarityIconsInput>
  /** GachaTicket icons */
  gachaTicketIcons?: Maybe<OrganizationGachaTicketInput>
  /** Organizationで有効にする設定 */
  modules?: Maybe<OrganizationModulesInput>
  /** Organization名 */
  name: Scalars['String']
}

/** Organization通知作成Input */
export type AdminCreateOrganizationNotificationInput = {
  /**
   * アーティストID
   * Organization全体の公式お知らせの場合は不要
   */
  artistId?: Maybe<Scalars['String']>
  /** 通知本文 */
  body?: Maybe<Scalars['String']>
  /** 通知リンク */
  link?: Maybe<Scalars['String']>
  /** Published date */
  publishedAt?: Maybe<Scalars['Date']>
  /** Status */
  status?: Maybe<ArtistCreateOrganizationNotificationStatus>
  /** 通知タイトル */
  title: Scalars['String']
}

/** Admin create preSale first come first served input */
export type AdminCreatePreSaleFirstComeFirstServedInput = {
  /** 購入時のどの個人情報が必要かどうか */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /** 購入可能最大枚数 */
  purchasableCount: Scalars['Int']
  /** 購入終了日時 */
  purchasableEndedAt: Scalars['Date']
  /** 購入開始日時 */
  purchasableStartedAt: Scalars['Date']
}

/** Admin create preSale input */
export type AdminCreatePreSaleInput = {
  /** 先行販売説明 */
  description: Scalars['String']
  /** 公開終了日時 */
  endedAt: Scalars['Date']
  /** イベントID */
  eventId: Scalars['String']
  /** 先着の場合の設定 */
  firstComeFirstServed?: Maybe<AdminCreatePreSaleFirstComeFirstServedInput>
  /** 有料会員向けかどうか */
  isPremium?: Maybe<Scalars['Boolean']>
  /** 公開フラグ */
  isPublished: Scalars['Boolean']
  /**
   * チケットアイテムID
   * SKU付きの場合は itemId:skuId
   */
  itemIds: Array<Scalars['String']>
  /** 抽選の場合の設定 */
  lottery?: Maybe<AdminCreatePreSaleLotteryInput>
  /** 注意文言 */
  note: Scalars['String']
  /** 支払い方法 */
  paymentMethods?: Maybe<Array<PaymentMethod>>
  /**
   * 抽選応募できるメンバーシップのプランID
   * 指定がなければnull
   */
  planId?: Maybe<Scalars['String']>
  /** 公開開始日時 */
  startedAt: Scalars['Date']
  /** 先行販売タイトル */
  title: Scalars['String']
  /** 先行販売種別 */
  type: PreSaleType
}

/** Admin create preSale lottery input */
export type AdminCreatePreSaleLotteryInput = {
  /** 応募終了日時 */
  applicationEndedAt: Scalars['Date']
  /** 応募開始日時 */
  applicationStartedAt: Scalars['Date']
  /** 最大応募希望数 */
  maxChoice: Scalars['Int']
  /** 購入時のどの個人情報が必要かどうか */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /** 購入可能最大枚数 */
  purchasableCount: Scalars['Int']
  /** 購入終了日時 */
  purchasableEndedAt: Scalars['Date']
  /** 購入開始日時 */
  purchasableStartedAt: Scalars['Date']
  /** 抽選結果通知予定日時 */
  resultNoticedAt: Scalars['Date']
}

export type AdminCreatePrepaidPointInput = {
  exchangeRate: Scalars['Int']
  organizationId: Scalars['String']
  pointName: Scalars['String']
  purchasablePrepaidPoints: Array<PurchasablePrepaidPointInput>
}

/** Admin用リソース作成Input */
export type AdminCreateResourceInput = {
  artistId: Scalars['String']
  /** storageのtmpファイルパス tmp/... という形で渡す */
  filePath: Scalars['String']
  /** ファイル名 */
  name: Scalars['String']
}

/** 座席テンプレート作成Input */
export type AdminCreateSeatTemplateInput = {
  /** 会場のキャパ */
  capacity?: Maybe<Scalars['Int']>
  /** 会場名 */
  hallName: Scalars['String']
  /** 会場の位置情報 */
  location: GpsDataInput
  /** テンプレート名 */
  name: Scalars['String']
  /** 座席セクション情報 */
  seatSections?: Maybe<Array<SeatSectionInput>>
  /** 座席セクションをCSVから一括更新する場合のパス */
  seatSectionsCsvPath?: Maybe<Scalars['String']>
  /**
   * 座席文字列表現時のサフィックス
   * 例: ['ブロック', '列', '番'] の場合、Aブロック 1列 10番 のように表現される
   */
  seatSuffix: Array<Scalars['String']>
}

/** admin作成関連のcsvインプット */
export type AdminCsvCommonInput = {
  /** csv storage tmp path */
  csvPath: Scalars['String']
}

/** admin用配送アイテムInput */
export type AdminDeliveryItemsInput = {
  itemId?: Maybe<Scalars['String']>
}

/** Admin用背景アイテムのコンビニプリント用QRコード生成Input */
export type AdminGenerateWallpaperPrintQrcodeInput = {
  /** アイテムID */
  itemId: Scalars['String']
}

/** 外部クライアント発行Input */
export type AdminIssuePartnerClientInput = {
  /** クライアント名 */
  clientName: Scalars['String']
  /** 所属OrganizationID */
  organizationId: Scalars['String']
}

/** Admin用物販アイテム発送完了Input */
export type AdminMerchItemShipCompleteInput = {
  /** 発送完了のItemID */
  itemIds: Array<Scalars['String']>
}

/** Adminアイテムキャンセルインプット */
export type AdminRefundStoreItemInput = {
  /** カート決済履歴ID */
  cartTransactionOrderId: Scalars['String']
  /** アイテム決済時のitemOrderId */
  itemOrderId: Scalars['String']
  /** 決済手段 */
  paymentType: UserPaymentType
  /** プリペイドポイントID（ポイント返還の場合） */
  prepaidPointId?: Maybe<Scalars['String']>
  /** キャンセル理由 */
  reason?: Maybe<Scalars['String']>
  /** 返金額 */
  refundAmount: Scalars['Int']
  /**
   * キャンセル分のシリアルID
   * 指定しない場合は、itemOrderId.serialIdsすべてが対象
   */
  serialIdsToRefund?: Maybe<Array<Scalars['String']>>
}

/** アーティスト作成Input */
export type AdminRegisterArtistInput = {
  /** アカウントID */
  accountId: Scalars['String']
  /** 表示名 */
  displayName?: Maybe<Scalars['String']>
  /** メールアドレス */
  email: Scalars['String']
  /** 招待コード */
  invitationCode?: Maybe<Scalars['String']>
  /** トップページに表示するかどうか（デフォルトtrue） */
  isDisplayToTop?: Maybe<Scalars['Boolean']>
  /** Organizationに所属させる場合に指定 */
  organizationId?: Maybe<Scalars['String']>
  /** パスワード */
  password: Scalars['String']
  /** 支払い種別(billing or connect) */
  paymentType?: Maybe<PaymentType>
  /** 公開するかどうか */
  private?: Maybe<Scalars['Boolean']>
}

/** イベントからコレクションを削除するInput */
export type AdminRemoveCollectionFromEventInput = {
  /** コレクションID */
  collectionId: Scalars['String']
  /** イベントID */
  eventId: Scalars['ID']
}

/** Organizationバナー削除Input */
export type AdminRemoveEventBannerInput = {
  /** 削除するバナーのorder */
  order: Scalars['Int']
}

/** PUSH一括送信用インプット */
export type AdminSendPushNotificationBulkInput = {
  csvPath: Scalars['String']
}

/** 先行販売の抽選通知処理開始インプット */
export type AdminStartPreSaleLotteryNotificationInput = {
  /** 先行販売ID */
  preSaleId: Scalars['String']
}

/** 先行販売抽選の処理開始インプット */
export type AdminStartPreSaleLotteryProcessInput = {
  /** 先行販売ID */
  preSaleId: Scalars['String']
}

/** アイテムのシリアルトレードInput */
export type AdminTradeItemSerialsInput = {
  /** トレードAの情報 */
  traderA: TradedItemSerialInput
  /** トレードBの情報 */
  traderB: TradedItemSerialInput
  /** トレードタイプ */
  type: TradeType
}

/** Admin用 アーティストプロフィール更新Input */
export type AdminUpdateArtistInput = {
  /** アカウントID */
  accountId?: Maybe<Scalars['String']>
  /** アーティスト情報更新Input */
  appLinks?: Maybe<Array<ArtistAppLinkInput>>
  /**
   * アバター画像のパス
   * storageのtmpファイルパス または 画像URL(png, jpg)
   */
  avatarPath?: Maybe<Scalars['String']>
  /** 表示名 */
  displayName?: Maybe<Scalars['String']>
  /** 自己紹介 */
  introduction?: Maybe<Scalars['String']>
}

/** ADMIN用のCSVで座席割当て一括更新インプット */
export type AdminUpdateAssignEventSeatFromCsvInput = {
  /** CSVのtempパス */
  csvPath: Scalars['String']
  /** イベントシートID */
  eventSeatId: Scalars['String']
}

/** Collection fee settings input */
export type AdminUpdateCollectionFeeSettingsInput = {
  collectionFeeSettings: Array<Maybe<CollectionFeeSettingInput>>
}

/** Admin用コレクション更新Input */
export type AdminUpdateCollectionInput = {
  artistId: Scalars['String']
  description?: Maybe<Scalars['String']>
  /** 公開終了日 */
  endedAt?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  /** ストアで公開するかどうか */
  isPublishOnStore?: Maybe<Scalars['Boolean']>
  /** コレクションのラベル */
  labels?: Maybe<Array<Maybe<CollectionLabelInput>>>
  /** NFT情報(チェーン別) */
  nft?: Maybe<CollectionNftInput>
  /** 公開開始日 */
  startedAt?: Maybe<Scalars['Date']>
  /** 画像パス */
  thumbnailPath?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

/** Collection item order input */
export type AdminUpdateCollectionItemOrderInput = {
  /** Item Order */
  items: Array<ItemOrderInput>
}

/** イベント更新Input */
export type AdminUpdateEventInput = {
  /** アーティストID */
  artistId?: Maybe<Scalars['String']>
  /** イベント説明 */
  description?: Maybe<Scalars['String']>
  /** イベント終了日時 */
  endedAt?: Maybe<Scalars['Date']>
  /** イベント位置情報 */
  location?: Maybe<GpsDataInput>
  /** 商品購入前に表示する注意文言 */
  note?: Maybe<Scalars['String']>
  /** イベント開始日時 */
  startedAt?: Maybe<Scalars['Date']>
  /** ステータス */
  status?: Maybe<EventStatus>
  /** サムネイル画像tmpパス */
  thumbnailPath?: Maybe<Scalars['String']>
  /** イベントタイトル */
  title?: Maybe<Scalars['String']>
}

/** イベント座席セクション更新Input */
export type AdminUpdateEventSeatSectionInput = {
  /** イベント座席ID */
  eventSeatId: Scalars['String']
  /** イベント座席セクションID */
  eventSeatSectionId: Scalars['ID']
  /** 更新する座席セクション情報 */
  updateSeatSections: Array<EventSeatSectionInput>
}

/** アーティスト用アイテム更新Input */
export type AdminUpdateItemInput = {
  /** 説明 */
  description?: Maybe<Scalars['String']>
  /** シリアルや販売数など売上が分かる表示をしない場合はtrue */
  hideSalesNumber?: Maybe<Scalars['Boolean']>
  /** オプショナルの複数画像 */
  images?: Maybe<Array<UpdateItemImageInput>>
  /** 発行数量 */
  issuedNumber?: Maybe<Scalars['Int']>
  /** アイテムに付随する情報。メタデータ。 */
  metadata?: Maybe<ItemMetadata>
  /** 購入時のどの個人情報が必要かどうか */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /** 料金 */
  price?: Maybe<Scalars['Int']>
  /** アイテムのユーザー人ごとの購入制限 */
  purchaseLimitByUser?: Maybe<Scalars['Int']>
  /** アイテムの1注文あたりの購入制限 */
  purchaseLimitPerOrder?: Maybe<Scalars['Int']>
  /** アイテムレアリティ */
  rarity?: Maybe<Scalars['String']>
  /** DRM化が必要かどうか */
  requireDrm?: Maybe<Scalars['Boolean']>
  /** チケットのリセールが有効かどうか */
  resaleEnabled?: Maybe<Scalars['Boolean']>
  /** 動画・オーディオ・マガジンリソース */
  resource?: Maybe<ResourceUpdateInput>
  /** リソース配列 */
  resources?: Maybe<Array<Maybe<ResourcesUpdateInput>>>
  /** SKU設定 */
  skuSettings?: Maybe<SkuSettingsInput>
  /** カードで利用する画像 */
  thumbnail?: Maybe<Scalars['String']>
  /**
   * テンプレートなしのカード裏面で利用する画像
   * 指定しない場合はthumbnailで指定した画像が裏面になります。
   */
  thumbnailBack?: Maybe<Scalars['String']>
  /** タイトル */
  title?: Maybe<Scalars['String']>
  /** 壁紙リソース */
  wallpaperResources?: Maybe<WallpaperResourcesUpdateInput>
}

/** Organization Update Input */
export type AdminUpdateOrganizationInput = {
  /** Organizationに所属させるアーティストID配列 */
  artistIds?: Maybe<Array<Scalars['String']>>
  /** RarityIcon link URL */
  gachaRarityIcons?: Maybe<OrganizationGachaRarityIconsInput>
  /** Organizationで有効にする設定 */
  modules?: Maybe<OrganizationModulesInput>
  /** Organization名 */
  name: Scalars['String']
}

/** Admin update preSale input */
export type AdminUpdatePreSaleInput = {
  /** 先行販売説明 */
  description?: Maybe<Scalars['String']>
  /** 有料会員向けかどうか */
  isPremium?: Maybe<Scalars['Boolean']>
  /** 公開フラグ */
  isPublished?: Maybe<Scalars['Boolean']>
  /** 注意文言 */
  note?: Maybe<Scalars['String']>
  /** 支払い方法 */
  paymentMethods?: Maybe<Array<PaymentMethod>>
  /**
   * 抽選応募できるメンバーシップのプランID
   * 指定がなければnull
   */
  planId?: Maybe<Scalars['String']>
  /** 先行販売タイトル */
  title?: Maybe<Scalars['String']>
}

/** 座席テンプレート更新Input */
export type AdminUpdateSeatTemplateInput = {
  /** 会場のキャパ */
  capacity?: Maybe<Scalars['Int']>
  /** 会場名 */
  hallName?: Maybe<Scalars['String']>
  /** 会場の位置情報 */
  location?: Maybe<GpsDataInput>
  /** テンプレート名 */
  name?: Maybe<Scalars['String']>
  /** 座席セクション情報 */
  seatSections?: Maybe<Array<SeatSectionInput>>
  /** 座席セクションをCSVから一括更新する場合のパス */
  seatSectionsCsvPath?: Maybe<Scalars['String']>
  /**
   * 座席文字列表現時のサフィックス
   * 例: ['ブロック', '列', '番'] の場合、Aブロック 1列 10番 のように表現される
   */
  seatSuffix?: Maybe<Array<Scalars['String']>>
}

/** Admin update top display artist input */
export type AdminUpdateTopDisplayArtistsInput = {
  /** アーティストID */
  artistIds: Array<Scalars['String']>
  /** Organizationに所属させる場合に指定 */
  organizationId: Scalars['String']
}

export type AnnouncementSettings = {
  official?: Maybe<NotificationChannels>
}

/** 先行販売抽選応募インプット */
export type ApplyPreSaleLotteryInput = {
  /**
   * 応募希望アイテムID
   * 第1希望,第2希望...と配列で指定する
   */
  choices: Array<Scalars['String']>
  /**
   * コンビニ決済の場合に領収書に記載される氏名を指定する
   * クレジットカードの場合は不要
   */
  fullName?: Maybe<Scalars['String']>
  /** 支払い方法 */
  paymentMethod: PaymentMethod
  /** 先行販売ID */
  preSaleId: Scalars['String']
  /**
   * 応募希望数
   * それぞれchoicesの配列インデックスに合わせる
   */
  purchaseCountChoices: Array<Scalars['Int']>
}

/** リセール抽選申し込みインプット */
export type ApplyResaleItemRaffleInput = {
  /** 抽選に申し込む数 */
  itemCount: Scalars['Int']
  /** 申し込むItemID */
  itemId: Scalars['String']
  /** SKUアイテムの場合はSKU IDを指定 */
  skuId?: Maybe<Scalars['String']>
}

/** アーティスト情報 */
export type Artist = Node & {
  /**
   * アカウントID
   * アーティスト内でユニークになっている
   */
  accountId?: Maybe<Scalars['String']>
  /** アーティスト情報 */
  appLinks?: Maybe<Array<Maybe<ArtistAppLink>>>
  /** アーティストのアバター情報 */
  avatar?: Maybe<Avatar>
  bank?: Maybe<Bank>
  banners?: Maybe<Array<ArtistBanner>>
  /** ビジネス情報 */
  business?: Maybe<Business>
  createdAt?: Maybe<Scalars['Date']>
  /** 暗号資産ウォレット連携情報 */
  cryptoWallet?: Maybe<CryptoWallet>
  /** 現在配信しているライブ情報を取得する */
  currentLive?: Maybe<LiveStream>
  /** アカウント表示名 */
  displayName?: Maybe<Scalars['String']>
  entryCampaigns: Array<Campaign>
  follower?: Maybe<Follower>
  followerCount?: Maybe<Scalars['Int']>
  followers: PageResult
  formattedAccountId?: Maybe<Scalars['String']>
  formattedPatronCount?: Maybe<Scalars['String']>
  /** 未発送の物販アイテムがあるかどうか */
  hasArrangingDeliveryItems: Scalars['Boolean']
  /**
   * アーティストID
   * アーティストアカウントのauth uidと同じIDを持つ
   */
  id: Scalars['ID']
  introduction?: Maybe<Scalars['String']>
  /** ログイン中のユーザーがアーティストをブロックしているかどうか */
  isBlocked: Scalars['Boolean']
  /** アーティストのパトロン数、フォロワー数の数値を隠す */
  isHiddenCounter?: Maybe<Scalars['Boolean']>
  moduleActivations?: Maybe<ModuleActivations>
  /**
   * パトロン情報を取得する
   * userIdを指定しない場合、ログインしているユーザー自身が対象となる
   */
  patron?: Maybe<Patron>
  patronCount?: Maybe<Scalars['Int']>
  /** パトロン一覧 */
  patrons: PageResult
  /**
   * 支払い方法：connect or billing or null
   * nullの場合はデフォルト(=connect)扱いとなる
   */
  paymentType?: Maybe<ArtistPaymentType>
  /** コンビニ支払待ちメンバーシップ */
  pendingPurchasePatron?: Maybe<PendingPurchaseItem>
  /** ポストのカテゴリー設定 */
  postCategory?: Maybe<PostCategory>
  /**
   * アーティストのポスト一覧
   * statusを指定しない場合、デフォルトで status:publishedのみが返される
   * category: noneを渡すと設定されていないもののみに絞る
   */
  posts: PageResult
  /** アーティストがprivateかどうか */
  private?: Maybe<Scalars['Boolean']>
  /** アーティストのプロフィール情報 */
  profileText?: Maybe<Scalars['String']>
  providers?: Maybe<Providers>
  /** 設定関連 */
  settings?: Maybe<ArtistSettings>
  /** アーティストのsns情報（url） */
  sns?: Maybe<ArtistSns>
  /** 個別アプリで利用する情報 */
  standalone?: Maybe<ArtistStandalone>
  /** アーティストの状態 */
  status: Scalars['String']
  /**
   * メンバーシップ情報
   * メンバーシップを持っていない場合はこの情報が存在しません
   */
  subscription?: Maybe<MembershipSubscription>
  /** アーティストのテキストリンク情報 */
  textLink?: Maybe<Array<Maybe<ArtistTextLink>>>
  totalPurchasedNumber?: Maybe<Scalars['Int']>
  /**
   * ログインユーザーのアーティスト別未読メンション数
   * 未ログインの場合は0が返される
   */
  totalUnreadMentionCount: Scalars['Int']
}

/** アーティスト情報 */
export type ArtistFollowersArgs = {
  pageInput?: Maybe<PageInput>
}

/** アーティスト情報 */
export type ArtistPatronArgs = {
  userId?: Maybe<Scalars['String']>
}

/** アーティスト情報 */
export type ArtistPatronsArgs = {
  filters?: Maybe<PatronsFilters>
  pageInput?: Maybe<PageInputDate>
}

/** アーティスト情報 */
export type ArtistPostsArgs = {
  categoryId?: Maybe<Scalars['String']>
  pageInput?: Maybe<PageInputDate>
  resourceType?: Maybe<PostType>
  resourceTypes?: Maybe<Array<PostType>>
  status?: Maybe<Array<Maybe<PostStatus>>>
}

/** アーティスト用イベントにコレクションを追加するInput */
export type ArtistAddCollecitonToEventInput = {
  /** コレクションID配列 */
  collectionIds: Array<Scalars['String']>
  /** イベントID */
  eventId: Scalars['ID']
}

/** アーティスト情報 */
export type ArtistAppLink = {
  link: Scalars['String']
  type: Scalars['String']
}

/** アーティスト情報更新Input */
export type ArtistAppLinkInput = {
  /** 外部プロフィールURL */
  link: Scalars['String']
  /** 情報の種類 */
  type: Scalars['String']
}

export type ArtistBanner = {
  /** CLICK時に開くリンク */
  link?: Maybe<BannerLink>
  /** Banner Thumbanil URI */
  thumbnail: Image
}

/** アーティスト用キャンペーンランキング */
export type ArtistCampaignRanking = {
  createdAt?: Maybe<Scalars['Date']>
  formattedCreatedAt?: Maybe<Scalars['String']>
  id: Scalars['String']
  /** 対象アーティスト自身のランキング */
  myRank?: Maybe<CampaignArtistRanker>
  /**
   * ランキング
   * 同列ランクも考慮される
   */
  ranker: Array<CampaignArtistRanker>
}

/** アーティスト用コメント作成Input */
export type ArtistCreateCommentInput = {
  /** コメント内容 */
  content: Scalars['String']
  /**
   * 返信先のコメントID
   * 指定しない場合は返信ではなくコメントになる
   */
  replyToId?: Maybe<Scalars['String']>
  /** 画像などのリソース */
  resource?: Maybe<CommentResourceInput>
}

/** アーティスト用イベント作成Input */
export type ArtistCreateEventInput = {
  /** イベント非開催日 */
  closeDate?: Maybe<Array<Scalars['Date']>>
  /** イベント説明 */
  description: Scalars['String']
  /** イベント終了日時 */
  endedAt?: Maybe<Scalars['Date']>
  /** イベント位置情報 */
  location?: Maybe<GpsDataInput>
  /** 商品購入前に表示する注意文言 */
  note?: Maybe<Scalars['String']>
  /** イベント開始日時 */
  startedAt?: Maybe<Scalars['Date']>
  /** ステータス */
  status?: Maybe<EventStatus>
  /** サムネイル画像tmpパス */
  thumbnailPath: Scalars['String']
  /** イベントタイトル */
  title: Scalars['String']
}

/** Artist用Organization通知作成Input */
export type ArtistCreateOrganizationNotificationInput = {
  /** 通知本文 */
  body?: Maybe<Scalars['String']>
  /** 通知リンク */
  link?: Maybe<Scalars['String']>
  /** Published date */
  publishedAt?: Maybe<Scalars['Date']>
  /** Status */
  status?: Maybe<ArtistCreateOrganizationNotificationStatus>
  /** 通知タイトル */
  title: Scalars['String']
}

/** Organization notification status */
export enum ArtistCreateOrganizationNotificationStatus {
  /** 公開 */
  Published = 'published',
  /** 予約済み */
  Scheduled = 'scheduled',
}

/** ポストカテゴリー作成Input */
export type ArtistCreatePostCategoryInput = {
  /** カテゴリー説明 */
  description?: Maybe<Scalars['String']>
  /** カテゴリー表示名 */
  name: Scalars['String']
}

/** アーティストの先行販売作成インプット */
export type ArtistCreatePreSaleInput = {
  /** 先行販売説明 */
  description: Scalars['String']
  /** 公開終了日時 */
  endedAt: Scalars['Date']
  /** イベントID */
  eventId: Scalars['String']
  /** 先着の場合の設定 */
  firstComeFirstServed?: Maybe<AdminCreatePreSaleFirstComeFirstServedInput>
  /** 有料会員向けかどうか */
  isPremium?: Maybe<Scalars['Boolean']>
  /** 公開フラグ */
  isPublished: Scalars['Boolean']
  /**
   * チケットアイテムID
   * SKU付きの場合は itemId:skuId
   */
  itemIds: Array<Scalars['String']>
  /** 抽選の場合の設定 */
  lottery?: Maybe<ArtistCreatePreSaleLotteryInput>
  /** 注意文言 */
  note: Scalars['String']
  /** 支払い方法 */
  paymentMethods?: Maybe<Array<PaymentMethod>>
  /**
   * 抽選応募できるメンバーシップのプランID
   * 指定がなければnull
   */
  planId?: Maybe<Scalars['String']>
  /** 公開開始日時 */
  startedAt: Scalars['Date']
  /** 先行販売タイトル */
  title: Scalars['String']
  /** 先行販売種別 */
  type: PreSaleType
}

/** 先行販売の抽選設定インプット */
export type ArtistCreatePreSaleLotteryInput = {
  /** 応募終了日時 */
  applicationEndedAt: Scalars['Date']
  /** 応募開始日時 */
  applicationStartedAt: Scalars['Date']
  /** 最大応募希望数 */
  maxChoice: Scalars['Int']
  /** 購入時のどの個人情報が必要かどうか */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /** 購入可能最大枚数 */
  purchasableCount: Scalars['Int']
  /** 購入終了日時 */
  purchasableEndedAt: Scalars['Date']
  /** 購入開始日時 */
  purchasableStartedAt: Scalars['Date']
  /** 抽選結果通知予定日時 */
  resultNoticedAt: Scalars['Date']
}

/**
 * アーティスト顧客（ユーザー）
 * 顧客となるトリガー
 *   - メンバーシップ
 *   - フォロー
 *   - 有料アイテム購入
 */
export type ArtistCustomer = Node & {
  createdAt: Scalars['Date']
  /** フォロワー */
  follower?: Maybe<Follower>
  formattedLastPurchasedAt?: Maybe<Scalars['String']>
  formattedPurchaseCount?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** 最終購入日（アイテムのみ対象） */
  lastPurchasedAt?: Maybe<Scalars['Date']>
  /** パトロン */
  patron?: Maybe<Patron>
  /** 購入回数（メンバーシップ加入、継続は含まない） */
  purchaseCount?: Maybe<Scalars['Int']>
  /** そのアーティストのチケットの未使用数 */
  ticketUnusedCount?: Maybe<Scalars['Int']>
  updatedAt: Scalars['Date']
  /** ユーザー */
  user?: Maybe<User>
  /** ユーザーID */
  userId: Scalars['String']
}

/** アイテム検索のフィルター */
export type ArtistItemsFilterInput = {
  searchTerm?: Maybe<Scalars['String']>
  type?: Maybe<ItemType>
}

/** アーティスト用スレッド通知設定 */
export type ArtistMembershipSettings = {
  subscribe?: Maybe<NotificationChannels>
}

/** Artist用物販アイテム発送完了Input */
export type ArtistMerchItemShipCompleteInput = {
  /** delivery item Ids */
  deliveryItemIds: Array<Scalars['String']>
}

/** 通知チャネル:email, pushなどのオンオフフラグをここに追加する */
export type ArtistNotificationChannels = {
  email?: Maybe<Scalars['Boolean']>
  push?: Maybe<Scalars['Boolean']>
}

/** 通知チャネル種別 */
export enum ArtistNotificationChannelsType {
  /** メール通知 */
  Email = 'email',
  /** プッシュ通知 */
  Push = 'push',
}

/** アーティスト用通知設定 */
export type ArtistNotificationSettings = {
  artistThreadChannel?: Maybe<ArtistThreadChannelSettings>
  membership?: Maybe<ArtistMembershipSettings>
  post?: Maybe<ArtistPostSettings>
}

/** アーティスト通知設定Input */
export type ArtistNotificationSettingsInput = {
  /** 通知チャネル種別 */
  channel?: Maybe<ArtistNotificationChannelsType>
  /**
   * 通知を有効にする場合にtrue
   * default: true
   */
  flag?: Maybe<Scalars['Boolean']>
  /** 通知種別 */
  type: ArtistNotificationSettingsType
}

/** 通知設定種別 */
export enum ArtistNotificationSettingsType {
  /** 作成スレッドへのコメント */
  ArtistThreadChannelCommentThread = 'artistThreadChannel_commentThread',
  /** アーティストスレッド新規投稿 */
  ArtistThreadChannelNewThread = 'artistThreadChannel_newThread',
  /** スレッドコメントへの返信 */
  ArtistThreadChannelReplyCommentThread = 'artistThreadChannel_replyCommentThread',
  /** メンバーシップ新規登録 */
  MembershipSubscribe = 'membership_subscribe',
  /** ポスト返信通知 */
  PostReplyPost = 'post_replyPost',
}

/**
 * アーティストの出金ペイメントタイプ
 * connect: stripe connect経由でアーティストに支払われます
 * billing: 手動でアーティストに支払われます（事務処理）。組織単位などconnectだと効率が悪い場合に利用
 */
export enum ArtistPaymentType {
  Billing = 'billing',
  Connect = 'connect',
}

/** アーティスト用ポスト通知設定 */
export type ArtistPostSettings = {
  replyPost?: Maybe<NotificationChannels>
}

/** アーティスト用イベントからコレクションを削除するInput */
export type ArtistRemoveCollectionFromEventInput = {
  /** コレクションID */
  collectionId: Scalars['String']
  /** イベントID */
  eventId: Scalars['ID']
}

/** ポストカテゴリー削除Input */
export type ArtistRemovePostCategoryInput = {
  /**
   * カテゴリーID
   * {documentId}:{randomId}
   */
  categoryId: Scalars['String']
}

/** アイテム作成時に紐付けるリソース（Artist用） */
export type ArtistResource = Node & {
  createdAt?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  /** リソースの種類 */
  type?: Maybe<Scalars['String']>
  /** リソースのURL 動画、音声、画像など */
  uri: Scalars['String']
}

/** アーティスト設定（通知など） */
export type ArtistSettings = {
  notifications?: Maybe<ArtistNotificationSettings>
}

/** アーティストのsns情報（url） */
export type ArtistSns = {
  facebook?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  tiktok?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  youtube?: Maybe<Scalars['String']>
}

/** アーティストスタンドアロン情報 */
export type ArtistStandalone = {
  /** スタンドアローンアプリへのリンク情報 */
  link?: Maybe<StandaloneLink>
}

/** アーティストのプロフィール用テキスト */
export type ArtistTextLink = {
  content?: Maybe<Scalars['String']>
  uri?: Maybe<Scalars['String']>
}

/** アーティストスレッドのカテゴリー */
export enum ArtistThreadCategory {
  Event = 'event',
  Faq = 'faq',
  Request = 'request',
  TradeCall = 'trade_call',
}

/** アーティストスレッドチャンネル */
export type ArtistThreadChannel = Node & {
  artist?: Maybe<Artist>
  artistId: Scalars['String']
  category?: Maybe<Scalars['String']>
  commentCount: Scalars['Int']
  commentUserIds?: Maybe<Array<Scalars['String']>>
  commentUsers?: Maybe<Array<Maybe<User>>>
  /**
   * スレッドコメント
   * Node: Comment
   */
  comments: PageResult
  createdAt: Scalars['Date']
  createdByUser?: Maybe<User>
  createdByUserId: Scalars['String']
  description: Scalars['String']
  formattedCreatedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isAccessible: Scalars['Boolean']
  /**
   * このスレッドの通知を許可するかどうか
   * @auth: user
   */
  isAllowNotification: Scalars['Boolean']
  isLike?: Maybe<Scalars['Boolean']>
  isOfficial: Scalars['Boolean']
  isPremium: Scalars['Boolean']
  latestComment?: Maybe<Comment>
  likeCount: Scalars['Int']
  name: Scalars['String']
  organizationId?: Maybe<Scalars['String']>
  status: Scalars['String']
  thumbnail?: Maybe<Image>
  updatedAt: Scalars['Date']
  /**
   * ユーザの未読情報
   * @auth: user
   */
  userUnread?: Maybe<UserUnread>
}

/** アーティストスレッドチャンネル */
export type ArtistThreadChannelCommentsArgs = {
  pageInput?: Maybe<PageInput>
}

/** アーティストスレッド取得時のフィルタタイプ */
export enum ArtistThreadChannelFilterType {
  Event = 'event',
  Faq = 'faq',
  MyComment = 'my_comment',
  MyThread = 'my_thread',
  Official = 'official',
  Request = 'request',
  TradeCall = 'trade_call',
}

/** アーティストスレッドのいいね */
export type ArtistThreadChannelLike = {
  createdAt: Scalars['Date']
  id: Scalars['ID']
  user?: Maybe<User>
  userId: Scalars['String']
}

/**
 * アーティストスレッド取得時のソートタイプ
 * 注意: created_atは仕様変更により、内部的にupdated_atに変換される。
 */
export enum ArtistThreadChannelOrderByType {
  CommentCount = 'comment_count',
  CreatedAt = 'created_at',
  LikeCount = 'like_count',
  Recommend = 'recommend',
}

/** アーティスト用スレッド通知設定 */
export type ArtistThreadChannelSettings = {
  newThread?: Maybe<NotificationChannels>
}

/** アーティストスレッド取得Input */
export type ArtistThreadChannelsInput = {
  artistId: Scalars['String']
  options?: Maybe<ArtistThreadChannelsOptionInput>
}

/** アーティストスレッド取得のOptions Input */
export type ArtistThreadChannelsOptionInput = {
  filter?: Maybe<ArtistThreadChannelFilterType>
  orderBy?: Maybe<ArtistThreadChannelOrderByType>
}

export type ArtistUpdateArtistBannerInput = {
  link: BannerLinkInput
  tmpPath: Scalars['String']
}

/** アーティストバナー追加Input */
export type ArtistUpdateArtistBannersInput = {
  banners?: Maybe<Array<ArtistUpdateArtistBannerInput>>
}

/** アーティストプロフィール更新Input */
export type ArtistUpdateArtistProfileInput = {
  /** アカウントID */
  accountId?: Maybe<Scalars['String']>
  /**
   * アバター画像のパス
   * storageのtmpファイルパス または 画像URL(png, jpg)
   */
  avatarPath?: Maybe<Scalars['String']>
  /** 表示名 */
  displayName?: Maybe<Scalars['String']>
  /** 自己紹介 */
  introduction?: Maybe<Scalars['String']>
}

/** CSVで座席割当て一括更新インプット */
export type ArtistUpdateAssignSeatFromCsvInput = {
  /** CSVのtempパス */
  csvPath: Scalars['String']
}

/** アーティスト用イベント更新Input */
export type ArtistUpdateEventInput = {
  /** イベント非開催日 */
  closeDate?: Maybe<Array<Scalars['Date']>>
  /** イベント説明 */
  description?: Maybe<Scalars['String']>
  /** イベント終了日時 */
  endedAt?: Maybe<Scalars['Date']>
  /** イベント位置情報 */
  location?: Maybe<GpsDataInput>
  /** 商品購入前に表示する注意文言 */
  note?: Maybe<Scalars['String']>
  /** イベント開始日時 */
  startedAt?: Maybe<Scalars['Date']>
  /** ステータス */
  status?: Maybe<EventStatus>
  /** サムネイル画像tmpパス */
  thumbnailPath?: Maybe<Scalars['String']>
  /** イベントタイトル */
  title?: Maybe<Scalars['String']>
}

/** アーティスト用アイテム更新Input */
export type ArtistUpdateItemInput = {
  /** 説明 */
  description?: Maybe<Scalars['String']>
  /** シリアルや販売数など売上が分かる表示をしない場合はtrue */
  hideSalesNumber?: Maybe<Scalars['Boolean']>
  /** オプショナルの複数画像 */
  images?: Maybe<Array<UpdateItemImageInput>>
  /**
   * ダウンロード可能か
   * 壁紙の場合のみ指定
   */
  isDownloadable?: Maybe<Scalars['Boolean']>
  /** 有料会員向けかどうか */
  isPremium?: Maybe<Scalars['Boolean']>
  /** 発行数量 */
  issuedNumber?: Maybe<Scalars['Int']>
  /** アイテムのメタデータ */
  metadata?: Maybe<ItemMetadata>
  /** 決済手段 */
  paymentType?: Maybe<UserPaymentType>
  /** 購入時のどの個人情報が必要かどうか */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /** 料金 */
  price?: Maybe<Scalars['Int']>
  /** アイテムのユーザー人ごとの購入制限 */
  purchaseLimitByUser?: Maybe<Scalars['Int']>
  /** アイテムの1注文あたりの購入制限 */
  purchaseLimitPerOrder?: Maybe<Scalars['Int']>
  /** チケットのリセールが有効かどうか */
  resaleEnabled?: Maybe<Scalars['Boolean']>
  /** 動画・オーディオ・マガジンリソース */
  resource?: Maybe<ResourceUpdateInput>
  /** リソース配列 */
  resources?: Maybe<Array<Maybe<ResourcesUpdateInput>>>
  /** SKU設定 */
  skuSettings?: Maybe<SkuSettingsInput>
  /** カードで利用する画像 */
  thumbnail?: Maybe<Scalars['String']>
  /**
   * テンプレートなしのカード裏面で利用する画像
   * 指定しない場合はthumbnailで指定した画像が裏面になります。
   */
  thumbnailBack?: Maybe<Scalars['String']>
  /** タイトル */
  title?: Maybe<Scalars['String']>
  /** 壁紙リソース */
  wallpaperResources?: Maybe<WallpaperResourcesUpdateInput>
}

/** Artist Organization Notification update input */
export type ArtistUpdateOrganizationNotificationInput = {
  /** 通知本文 */
  body?: Maybe<Scalars['String']>
  /** 通知リンク */
  link?: Maybe<Scalars['String']>
  /** Published date */
  publishedAt?: Maybe<Scalars['Date']>
  /** 通知タイトル */
  title?: Maybe<Scalars['String']>
}

/** ポストカテゴリー更新Input */
export type ArtistUpdatePostCategoryInput = {
  /**
   * カテゴリーID
   * {documentId}:{randomId}
   */
  categoryId: Scalars['String']
  /** カテゴリー説明 */
  description: Scalars['String']
  /** カテゴリー表示名 */
  name: Scalars['String']
}

/** 先行販売の先着編集インプット */
export type ArtistUpdatePreSaleFirstComeFirstServedInput = {
  /** 購入時のどの個人情報が必要かどうか */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /** 購入可能最大枚数 */
  purchasableCount?: Maybe<Scalars['Int']>
  /** 購入終了日時 */
  purchasableEndedAt?: Maybe<Scalars['Date']>
  /** 購入開始日時 */
  purchasableStartedAt?: Maybe<Scalars['Date']>
}

/** Artist preSale update input */
export type ArtistUpdatePreSaleInput = {
  /** 先行販売説明 */
  description: Scalars['String']
  /** 公開終了日時 */
  endedAt: Scalars['Date']
  /** 先着の場合の設定 */
  firstComeFirstServed?: Maybe<ArtistUpdatePreSaleFirstComeFirstServedInput>
  /** 有料会員向けかどうか */
  isPremium?: Maybe<Scalars['Boolean']>
  /** 公開フラグ */
  isPublished: Scalars['Boolean']
  /**
   * チケットアイテムID
   * SKU付きの場合は itemId:skuId
   */
  itemIds: Array<Scalars['String']>
  /** 抽選の場合の設定 */
  lottery?: Maybe<ArtistUpdatePreSaleLotteryInput>
  /** 注意文言 */
  note: Scalars['String']
  /** 支払い方法 */
  paymentMethods?: Maybe<Array<PaymentMethod>>
  /**
   * 抽選応募できるメンバーシップのプランID
   * 指定がなければnull
   */
  planId?: Maybe<Scalars['String']>
  /** 公開開始日時 */
  startedAt: Scalars['Date']
  /** 先行販売タイトル */
  title: Scalars['String']
}

/** Artist先行販売抽選応募ステータス更新Input */
export type ArtistUpdatePreSaleLotteryApplicantStatusInput = {
  /** 抽選結果ユーザーリストの配列 */
  lotteryResults: Array<LotteryResultInput>
  /** 先行販売ID */
  preSaleId: Scalars['ID']
}

/** 先行販売の抽選編集インプット */
export type ArtistUpdatePreSaleLotteryInput = {
  /** 応募終了日時 */
  applicationEndedAt?: Maybe<Scalars['Date']>
  /** 応募開始日時 */
  applicationStartedAt?: Maybe<Scalars['Date']>
  /** 最大応募希望数 */
  maxChoice?: Maybe<Scalars['Int']>
  /** 購入時のどの個人情報が必要かどうか */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /** 購入可能最大枚数 */
  purchasableCount?: Maybe<Scalars['Int']>
  /** 購入終了日時 */
  purchasableEndedAt?: Maybe<Scalars['Date']>
  /** 購入開始日時 */
  purchasableStartedAt?: Maybe<Scalars['Date']>
  /** 抽選結果通知予定日時 */
  resultNoticedAt?: Maybe<Scalars['Date']>
}

/** アーティストのステータス変更Input */
export type ArtistUpdateStatusInput = {
  /**
   * アーティストのパトロン数、フォロワー数の数値を隠す場合にtrue
   * default false
   */
  isHiddenCounter?: Maybe<Scalars['Boolean']>
  /**
   * 検索にでないようにする場合にtrue
   * default false
   */
  private?: Maybe<Scalars['Boolean']>
}

/** 暗号資産ウォレット認証情報 */
export type AuthToken = {
  /** 作成日 */
  createdAt: Scalars['Date']
  /** 署名メッセージの有効期限 */
  expiredAt: Scalars['Date']
  /** Nonce(ランダム生成数値) */
  nonce: Scalars['String']
  /** 署名用メッセージ（nonce付き） */
  signatureMessage?: Maybe<Scalars['String']>
  /** nonceが使用済みの場合true */
  used?: Maybe<Scalars['Boolean']>
}

/** 暗号資産ウォレット承認インプット */
export type AuthorizeWalletInput = {
  /** ウォレットのアドレス */
  publicAddress: Scalars['String']
  /** nonceを利用した署名データ */
  signature: Scalars['String']
}

/** アバター情報 */
export type Avatar = {
  /** 80%圧縮のみ行ったURI(画像) */
  compressedUri?: Maybe<Scalars['String']>
  /** 通常画面表示用の中サイズURI(画像) */
  mediumUri?: Maybe<Scalars['String']>
  /** サムネイル等表示用の小サイズURI(画像) */
  smallUri?: Maybe<Scalars['String']>
  uri?: Maybe<Scalars['String']>
}

/** Avatarリソース */
export type AvatarResources = {
  /** 画像リソース */
  avatarResource?: Maybe<Resource>
  /** 音声リソース */
  voiceResource?: Maybe<Resource>
}

/** アバターリソースInput */
export type AvatarResourcesInput = {
  /** アバターリソース画像のドキュメントID */
  avatarResourceId: Scalars['String']
  /** 音声リソースのドキュメントID */
  voiceResourceId?: Maybe<Scalars['String']>
}

/** アバターリソース更新用Input */
export type AvatarResourcesUpdateInput = {
  /** アバターリソースの画像に設定する画像のパス(tmp/xxx) */
  avatarResourcePath?: Maybe<Scalars['String']>
  /** アバターリソースの音声に設定するオーディオのパス(tmp/xxx) */
  voiceResourcePath?: Maybe<Scalars['String']>
}

/** アバターリソースInput(URI指定) */
export type AvatarResourcesUriInput = {
  /** アバターリソース画像のURI */
  avatarResourceUri: Scalars['String']
  /** 音声リソースのURI */
  voiceResourceUri?: Maybe<Scalars['String']>
}

/** 銀行情報(Stripe用) */
export type Bank = {
  accountHolderName: Scalars['String']
  accountHolderType: Scalars['String']
  bankName: Scalars['String']
  id: Scalars['ID']
  last4: Scalars['String']
  routingNumber: Scalars['String']
}

/** 銀行口座種別 */
export enum BankAccountType {
  /** 普通 */
  Futsu = 'futsu',
  /** 当座 */
  Toza = 'toza',
}

/** 銀行情報 */
export type BankInput = {
  /** 口座名義人 */
  accountHolderName: Scalars['String']
  /** 口座番号 */
  accountNumber: Scalars['String']
  /** 銀行コード */
  bankCode: Scalars['String']
  /** 支店コード */
  branchCode: Scalars['String']
}

/** Click時に開くリンク */
export type BannerLink = {
  /** 外部遷移のURL */
  externalUrl?: Maybe<Scalars['String']>
  /** アプリケーション内部で開く場合のPath */
  innerPath?: Maybe<Scalars['String']>
}

/** バナーのリンクURL */
export type BannerLinkInput = {
  externalUrl?: Maybe<Scalars['String']>
  innerPath?: Maybe<Scalars['String']>
}

/** ボーナス詳細データ */
export type Bonus = {
  /**
   * ボーナスのキー名
   * 例: 1, 2, 3...(1日目、2日目、3日目)
   */
  bonusKey: Scalars['String']
  metadata?: Maybe<BonusMetadata>
  type: Scalars['String']
}

/** ボーナスデータのメタデータ */
export type BonusMetadata = {
  count?: Maybe<Scalars['Int']>
  gachaTicket?: Maybe<GachaTicket>
  gachaTicketId?: Maybe<Scalars['String']>
  point?: Maybe<Scalars['Int']>
}

export enum BonusType {
  GachaTicket = 'gachaTicket',
  NoBonus = 'noBonus',
  Point = 'point',
}

/** ビジネス情報(Stripe用) */
export type Business = {
  addressKana?: Maybe<Address>
  addressKanji?: Maybe<Address>
  birthday: Scalars['Date']
  firstNameKana: Scalars['String']
  firstNameKanji: Scalars['String']
  formattedBirthday?: Maybe<Scalars['String']>
  gender: Gender
  lastNameKana: Scalars['String']
  lastNameKanji: Scalars['String']
  phone: Scalars['String']
}

/** ビジネス情報Input（Stripe用） */
export type BusinessInput = {
  addressKana?: Maybe<AddressInput>
  addressKanji: AddressInput
  birthday: Scalars['Date']
  firstNameKana: Scalars['String']
  firstNameKanji: Scalars['String']
  gender: Scalars['String']
  lastNameKana: Scalars['String']
  lastNameKanji: Scalars['String']
  phone: Scalars['String']
}

/** コンテストのときに利用 */
export type Campaign = Node & {
  /** キャンペーンの獲得ポイントロジック */
  acquisitionPoints: Array<AcquisitionPoints>
  /** 指定したアーティストIDのキャンペーンに紐づくアイテム配列を取得する */
  artistItems?: Maybe<Array<Item>>
  /** キャンペーン説明 */
  description?: Maybe<Scalars['String']>
  /** キャンペーン終了日時 */
  endedAt?: Maybe<Scalars['Date']>
  /**
   * エントリーしているアーティスト一覧
   * @deprecated("no integration")
   */
  entryArtist?: Maybe<EntryArtist>
  /**
   * キャンペーンに自動エントリーする際の条件
   * クリアしていない場合はエラーになる
   */
  entryCondition?: Maybe<EntryCondition>
  /**
   * アーティストの参加状態
   * エントリー済み | エントリー可 | エントリー不可
   */
  entryStatus?: Maybe<Scalars['String']>
  formattedEndedAt?: Maybe<Scalars['String']>
  formattedStartedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /**
   * 画像テンプレート設定
   * 引数のartistIdから情報を取得しテンプレートに設定する
   * @params artistId
   */
  imageTemplates?: Maybe<EntryCampaignImageTemplates>
  /**
   * ログインしているユーザー自身が参加しているかどうか
   * アーティストの場合は、アーティストとして参加しているかどうかを返す
   */
  isEntry?: Maybe<Scalars['Boolean']>
  /**
   * キャンペーン開催期間中かどうか
   * サーバー内でstartedAt / endedAt を元に計算されて返される
   */
  open?: Maybe<Scalars['Boolean']>
  /**
   * キャンペーンの開催状態
   * 開催予定 | 開催中 | 開催終了
   */
  openStatus?: Maybe<Scalars['String']>
  /**
   * ランキング種別
   *   | user : userのみ
   *   | artist : artistのみ
   *   | both : user と artist両方
   */
  rankingType?: Maybe<Scalars['String']>
  /** キャンペーンスタート日時 */
  startedAt?: Maybe<Scalars['Date']>
  /** キャンペーンサムネイル画像 */
  thumbnail?: Maybe<Image>
  /** キャンペーンタイトル */
  title: Scalars['String']
}

/** コンテストのときに利用 */
export type CampaignArtistItemsArgs = {
  artistId: Scalars['String']
}

/** コンテストのときに利用 */
export type CampaignImageTemplatesArgs = {
  artistId: Scalars['String']
}

/** キャンペーンのポイント獲得ロジックInput */
export type CampaignAcquisitionPointsInput = {
  point: Scalars['Int']
  type: Scalars['String']
}

/** キャンペーン獲得タイプ */
export enum CampaignAcquisitionType {
  /** ガチャを回すとポイントが入る */
  GachaRoll = 'gacha_roll',
  /** アイテム購入するとポイントが入る */
  ItemBuy = 'item_buy',
  /** レアリティポイント：ノーマル */
  ItemRarityCommon = 'item_rarity_common',
  /** レアリティポイント：リミテッド */
  ItemRarityLimited = 'item_rarity_limited',
  /** レアリティポイント：レア */
  ItemRarityRare = 'item_rarity_rare',
  /** レアリティポイント：スーパーレア */
  ItemRaritySuperrare = 'item_rarity_superrare',
  /** レアリティポイント：ウルトラレア */
  ItemRarityUltrarare = 'item_rarity_ultrarare',
}

/** キャンペーンのアーティストランカー */
export type CampaignArtistRanker = {
  /** アカウントID */
  accountId?: Maybe<Scalars['String']>
  /** アーティストID */
  artistId?: Maybe<Scalars['String']>
  /**
   * ランキングに表示されるアバター画像
   * 非正規化されるため、集計時点の画像になる
   */
  avatar?: Maybe<Image>
  /**
   * ランキングに表示される名前
   * 非正規化されるため、集計時点の名前になる
   */
  displayName?: Maybe<Scalars['String']>
  /** 獲得ポイント */
  point: Scalars['Int']
  /** 現在のランク */
  rank: Scalars['Int']
}

/** キャンペーン一括エントリーインプット */
export type CampaignBulkEntryInput = {
  /**
   * アーティストID
   * スペースなしカンマ区切り
   */
  artistIds: Scalars['String']
}

/** キャンペーン自動エントリー時のコレクション作成情報 */
export type CampaignCollectionInfoInput = {
  description: Scalars['String']
  title: Scalars['String']
}

/** キャンペーン自動エントリーの条件 */
export type CampaignEntryConditionInput = {
  description: Scalars['String']
  items: CampaignEntryConditionItemsInput
}

/** キャンペーン自動エントリーのアイテムレアリティ別詳細条件 */
export type CampaignEntryConditionItemInput = {
  additionalDescriptionText?: Maybe<Scalars['String']>
  count: Scalars['Int']
  type?: Maybe<Array<Scalars['String']>>
  weight: Scalars['Float']
}

/** キャンペーン自動エントリーのアイテム詳細条件 */
export type CampaignEntryConditionItemsInput = {
  common?: Maybe<CampaignEntryConditionItemInput>
  rare?: Maybe<CampaignEntryConditionItemInput>
  superrare?: Maybe<CampaignEntryConditionItemInput>
}

/** キャンペーンエントリーInput */
export type CampaignEntryInput = {
  artistId: Scalars['String']
  items: CampaignEntryItemsInput
}

/** キャンペーンエントリーの作成アイテム情報詳細のInput */
export type CampaignEntryItemInput = {
  avatarResources?: Maybe<AvatarResourcesInput>
  rarity: Scalars['String']
  resourceId?: Maybe<Scalars['String']>
  thumbnail: Scalars['String']
  thumbnailBack?: Maybe<Scalars['String']>
  type: ItemType
  wallpaperResources?: Maybe<WallpaperResourcesInput>
}

/** キャンペーンエントリーの作成アイテム情報Input */
export type CampaignEntryItemsInput = {
  common?: Maybe<Array<CampaignEntryItemInput>>
  rare?: Maybe<Array<CampaignEntryItemInput>>
  superrare?: Maybe<Array<CampaignEntryItemInput>>
}

/** キャンペーン自動エントリー時のガチャ作成情報 */
export type CampaignGachaInfoInput = {
  description: Scalars['String']
  name: Scalars['String']
  rollAnimationTmpPath: Scalars['String']
}

/** キャンペーン自動エントリー時のアイテム用画像テンプレート設定 */
export type CampaignImageTemplateItemInput = {
  common?: Maybe<ImageTemplateItemInput>
  rare?: Maybe<ImageTemplateItemInput>
  superrare?: Maybe<ImageTemplateItemInput>
}

/** キャンペーン自動エントリー時の画像テンプレート設定 */
export type CampaignImageTemplatesInput = {
  collection: ImageTemplateBannerInput
  gacha: ImageTemplateBannerInput
  items: CampaignImageTemplateItemInput
}

/** 獲得ロジックがレアリティの場合のメタデータInput */
export type CampaignMetadataInput = {
  item_rarity: CampaignMetadataItemRarityPointTargetItemsInput
}

/** メタデータレアリティポイントの対象となるの詳細Input */
export type CampaignMetadataItemRarityInput = {
  ids: Array<Scalars['String']>
  type: Scalars['String']
}

/** レアリティポイントの対象となる設定Input */
export type CampaignMetadataItemRarityPointTargetItemsInput = {
  pointTargetItems: CampaignMetadataItemRarityInput
}

/** アーティストランキングかユーザーランキングが返される */
export type CampaignRanking = ArtistCampaignRanking | UserCampaignRanking

/** キャンペーンランキングの入力 */
export type CampaignRankingInput = {
  /**
   * アーティストID
   * CampaignRankingTypeがartistの場合は必須
   */
  artistId?: Maybe<Scalars['String']>
  /** キャンペーンコード（実態はCampaignのDocument ID） */
  code: Scalars['String']
  /** ランキングタイプ（artist | user） */
  type: CampaignRankingType
}

/**
 * キャンペーンのランキングタイプ
 * ユーザーランキング or アーティストランキング
 */
export enum CampaignRankingType {
  Artist = 'artist',
  User = 'user',
}

export type CampaignSettings = {
  endCampaign?: Maybe<NotificationChannels>
  startCampaign?: Maybe<NotificationChannels>
}

/** キャンペーンのユーザーランカー */
export type CampaignUserRanker = {
  /** アカウントID */
  accountId?: Maybe<Scalars['String']>
  /**
   * ランキングに表示されるアバター画像
   * 非正規化されるため、集計時点の画像になる
   */
  avatar?: Maybe<Image>
  /**
   * ランキングに表示される名前
   * 非正規化されるため、集計時点の名前になる
   */
  displayName?: Maybe<Scalars['String']>
  /** 獲得ポイント */
  point: Scalars['Int']
  /** 現在のランク */
  rank: Scalars['Int']
  /** ユーザーID */
  userId?: Maybe<Scalars['String']>
}

/** 先行販売抽選応募キャンセルインプット */
export type CancelPreSaleLotteryInput = {
  /** 先行販売ID */
  preSaleId: Scalars['String']
}

/** リセールのキャンセルインプット */
export type CancelResaleItemInput = {
  /** キャンセルするリセールID */
  resaleId: Scalars['String']
}

/** リセール抽選のキャンセルインプット */
export type CancelResaleItemRaffleInput = {
  /** キャンセルするリセールのItemID */
  itemId: Scalars['String']
  /** キャンセルしたいリセール抽選申し込みID（DocID） */
  resaleRaffleId: Scalars['ID']
}

/** チケット分配キャンセルInput */
export type CancelTicketDistributionInput = {
  /** URLパラメーターのTOKEN */
  token: Scalars['String']
}

/** トレード申請のキャンセル */
export type CancelTradeRequestInput = {
  /** TradeNegotiationId */
  id: Scalars['ID']
  /** TradeCandidateId */
  tradeCandidateId: Scalars['String']
}

/** カードの表裏情報 */
export type Card = {
  /** 後面のカード画像 */
  back?: Maybe<Resource>
  /** 前面のカード画像 */
  front?: Maybe<Resource>
}

/** カートでキャンセルされたデータ */
export type CartCancel = {
  /** キャンセル日 */
  canceledAt?: Maybe<Scalars['Date']>
  /** キャンセル数量 */
  canceledCount: Scalars['Int']
  /** キャンセルアイテム情報 */
  canceledItems: Array<UserCartItem>
  /** キャンセル理由 */
  reason?: Maybe<Scalars['String']>
  /** 払い戻し額 */
  refundedAmount: Scalars['Int']
}

/** プリペイドポイントのチャージ用Input（IAPで決済の場合） */
export type ChargePrepaidPointIapInput = {
  /** プリペイドポイントID */
  prepaidPointId: Scalars['String']
  /** 購入用のプリペイドポイントID */
  purchasablePrepaidPointId: Scalars['String']
  /** 決済ロックuuid */
  uuid?: Maybe<Scalars['String']>
}

/** プリペイドポイントのチャージ用Input（Stripeで決済の場合） */
export type ChargePrepaidPointInput = {
  /** プリペイドポイントID */
  prepaidPointId: Scalars['String']
  /** 購入用のプリペイドポイントID */
  purchasablePrepaidPointId: Scalars['String']
}

/** カートチェックアウトインプット */
export type CheckoutCartInput = {
  /** カートID */
  cartId: Scalars['ID']
  /** コンビニ決済の場合に領収書に記載される氏名を指定する */
  fullName?: Maybe<Scalars['String']>
  /** コンビニ決済の場合にtrueを指定する */
  isKonbini?: Maybe<Scalars['Boolean']>
  /** 受け取り時間（指定があるコレクションの場合のみ） */
  pickupTimes?: Maybe<Array<PickupTimeInput>>
  /**
   * 合計支払額
   * 処理内部で価格チェックを行い、一致しない場合はエラーを返す
   */
  totalPrice: Scalars['Int']
}

/** ストア決済の手数料 */
export type CheckoutFees = {
  /**
   * コレクションに設定された手数料
   * 単位：JPY/order
   */
  collections?: Maybe<Array<Maybe<Fee>>>
  /**
   * コンビニの場合の手数料
   * 単位：JPY/order
   */
  konbini?: Maybe<Fee>
  /**
   * 配送手数料全国一律
   * 単位：JPY/order
   */
  shipping?: Maybe<Fee>
  /** 合計手数料額 */
  totalFee?: Maybe<Scalars['Int']>
}

/** 応募希望アイテムの詳細データ */
export type ChoiceItemDetail = {
  /** 購入希望枚数 */
  count: Scalars['Int']
  /** 計算済み手数料 */
  fees: CheckoutFees
  /** アイテム */
  item?: Maybe<Item>
  /** アイテムID */
  itemId: Scalars['String']
  /** SKU ID */
  skuId?: Maybe<Scalars['String']>
  /** SKUデータ */
  skuUnit?: Maybe<SkuUnit>
}

/**
 * アイテムコレクション
 *
 * アイテムをGroupingしている
 */
export type Collection = Node & {
  /** アーティスト */
  artist: Artist
  artistId: Scalars['ID']
  /** コレクション背景画像・ぼかし画像 */
  backgroundImage?: Maybe<ImageWithBlur>
  /** Collection fee settings */
  collectionFeeSettings?: Maybe<Array<CollectionFeeSetting>>
  /**
   * コレクション内のアイテム情報。
   * publishedOnlyを指定すると公開中アイテムのみに絞られる（デフォルトはfalse)
   * Node: CollectionItem
   */
  collectionItems: PageResult
  /**
   * ユーザが保有するコレクション内のアイテム情報
   * userIdの指定がない場合、ログインしているユーザの情報を取得する。
   * userIdの指定がある場合、指定されたユーザのコレクションを表示する。
   * onlyTradable指定の場合は、protectedされていないものが対象
   * NOTE: ユーザ認証されていない場合はnullが入る。
   * @deprecated High Latency. Use 'userCollection()' instead.
   */
  collectionOwned?: Maybe<CollectionOwned>
  createdAt: Scalars['Date']
  description?: Maybe<Scalars['String']>
  endedAt?: Maybe<Scalars['Date']>
  /** 出品中のイベント */
  event?: Maybe<Event>
  formattedCreatedAt?: Maybe<Scalars['String']>
  formattedEndedAt?: Maybe<Scalars['String']>
  formattedStartedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /**
   * 出品中のイベントの場合、受け取りコレクションかどうか
   * 受け取りがない、またはイベントに関係のないコレクションの場合は常にfalseが返される
   */
  isEventPickup?: Maybe<Scalars['Boolean']>
  /** ストアで公開するかどうか */
  isPublishOnStore?: Maybe<Scalars['Boolean']>
  /** アイテム数（非公開アイテムも含む全て） */
  itemCount: Scalars['Int']
  labels?: Maybe<Array<Maybe<CollectionLabel>>>
  nft?: Maybe<CollectionNft>
  /** Organization */
  organization?: Maybe<Organization>
  /**
   * コレクションアイテムのうち、公開済みの数を取得する
   * # 処理内で、collectionItem全てを取得してcount queryを実行するため、必要な場合のみ呼び出すこと
   * # ガチャなど、非公開前提の場合はcollection.itemCountを使う
   */
  publishedItemCount: Scalars['Int']
  startedAt?: Maybe<Scalars['Date']>
  status: CollectionStatus
  /** コレクションサムネイル画像 */
  thumbnail: CollectionThumbnail
  title: Scalars['String']
  /**
   * 指定したユーザーのコレクション情報
   * userIdを指定しない場合はログインしているユーザーの情報
   */
  userCollection?: Maybe<UserCollection>
}

/**
 * アイテムコレクション
 *
 * アイテムをGroupingしている
 */
export type CollectionCollectionItemsArgs = {
  firstLabel?: Maybe<Scalars['Boolean']>
  label?: Maybe<Scalars['String']>
  pageInput?: Maybe<PageInput>
  publishedOnly?: Maybe<Scalars['Boolean']>
}

/**
 * アイテムコレクション
 *
 * アイテムをGroupingしている
 */
export type CollectionCollectionOwnedArgs = {
  onlyTradable?: Maybe<Scalars['Boolean']>
  userId?: Maybe<Scalars['ID']>
}

/**
 * アイテムコレクション
 *
 * アイテムをGroupingしている
 */
export type CollectionUserCollectionArgs = {
  userId?: Maybe<Scalars['ID']>
}

export type CollectionFeeSetting = {
  /** 手数料（単位：JPY） */
  fee?: Maybe<Scalars['Int']>
  /** 手数料詳細 */
  feeDescription?: Maybe<Scalars['String']>
  /** 手数料の表示名 */
  feeName?: Maybe<Scalars['String']>
  /** FeeのID（複数手数料設定時に見分けられるように） */
  id?: Maybe<Scalars['String']>
  /**
   * 手数料がかかる単位。デフォルトは互換対応のため order とする
   * - order : 注文1回あたり
   * - item : １アイテムあたり
   */
  per?: Maybe<FeeSettingPer>
}

export type CollectionFeeSettingInput = {
  /** 手数料（単位：JPY） */
  fee: Scalars['Int']
  /** 手数料詳細 */
  feeDescription?: Maybe<Scalars['String']>
  /** 手数料の表示名 */
  feeName: Scalars['String']
  /**
   * 手数料がかかる単位。デフォルトは互換対応のため order とする
   * - order : 注文1回あたり
   * - item : １アイテムあたり
   */
  per?: Maybe<FeeSettingPer>
}

/** コレクション内のアイテム情報 */
export type CollectionItem = Node & {
  createdAt: Scalars['Date']
  id: Scalars['ID']
  item: Item
  itemId: Scalars['ID']
  label: Array<Scalars['String']>
  /**
   * 順序
   * 低いほうが早い
   */
  order: Scalars['Int']
  updatedAt: Scalars['Date']
  /** 認証ユーザーが所有している場合、所有データを返す */
  userItem?: Maybe<UserItem>
}

/** CollectionItem一括更新Input */
export type CollectionItemBulkInput = {
  items: Array<CollectionItemInput>
}

/** CollectionのItem更新Input */
export type CollectionItemInput = {
  /** アイテムID */
  itemId: Scalars['String']
  /** ラベルID */
  labelId?: Maybe<Scalars['String']>
}

/** コレクションに表示するラベル情報 */
export type CollectionLabel = {
  createdAt: Scalars['Date']
  id: Scalars['ID']
  name: Scalars['String']
}

/** コレクションラベルInput */
export type CollectionLabelInput = {
  id: Scalars['String']
  name: Scalars['String']
}

/** CollectionのNFT情報 */
export type CollectionNft = {
  chains?: Maybe<Array<CollectionNftChain>>
  salesType: ItemNftSalesType
}

/** Collection NFTのチェーン別情報（polygon etc) */
export type CollectionNftChain = {
  chainName: Scalars['String']
  contractAddress?: Maybe<Scalars['String']>
  royalty: Scalars['Float']
  status: CollectionNftPrepareStatus
}

/** コレクションNFTチェーン別Input */
export type CollectionNftChainInput = {
  chainName: Scalars['String']
  royalty: Scalars['Float']
}

/** コレクションNFT情報Input */
export type CollectionNftInput = {
  chains?: Maybe<Array<CollectionNftChainInput>>
  salesType: ItemNftSalesType
}

/** Collection NFTの準備ステータス */
export enum CollectionNftPrepareStatus {
  /** 初期化中 */
  CreatingContract = 'creating_contract',
  /** 失敗 */
  Failed = 'failed',
  /** コントラクト準備完了 */
  Ready = 'ready',
}

/**
 * コレクションの保有情報
 * itemOwnedCount: アイテムを何種類保有しているか（重複除く）
 * totalOwnedCount: アイテムを何個保有しているか（重複含む）
 * userItems: 保有アイテムのUserItemのリスト
 */
export type CollectionOwned = {
  itemOwnedCount: Scalars['Int']
  totalOwnedCount: Scalars['Int']
  userItems: Array<UserItem>
}

/** CollectionのItem更新Input */
export type CollectionRemoveItemBulkInput = {
  /** Collection ID */
  id: Scalars['String']
  /** ItemId array to be removed from collection */
  itemIds: Array<Scalars['String']>
}

/** コレクションステータス */
export enum CollectionStatus {
  Archived = 'archived',
  Published = 'published',
  Unpublished = 'unpublished',
}

/** コレクションのサムネイル情報 */
export type CollectionThumbnail = {
  uri?: Maybe<Scalars['String']>
}

/**
 * コミュニティのコメント
 *
 * コメントの返信も同じ型
 * 原理上何層でもコメントに対してコメントが可能。
 *
 * 画像や動画は添付出来ない
 */
export type Comment = Node & {
  artistId: Scalars['String']
  /** コメントしたアカウント */
  commenter: AccountRecord
  content: Scalars['String']
  createdAt: Scalars['Date']
  formattedCreatedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /**
   * 現在のユーザーがLikeしているか
   *
   * 未認証の場合、falseを返します。
   */
  isLike: Scalars['Boolean']
  likeCount: Scalars['Int']
  /** 返信先コメントを取得します */
  parentComment?: Maybe<Comment>
  /**
   * コメントに対する返信
   * データ型はコメント自体と同じ
   * Node: Comment
   */
  replies: PageResult
  replyCount: Scalars['Int']
  /**
   * 旧コメントで利用
   * 現在はresourcesに統合されている
   * @deprecated Legacy
   */
  resource?: Maybe<CommentResource>
  resources?: Maybe<Array<CommentResource>>
  /** 投げ銭 */
  tip?: Maybe<Tip>
  type?: Maybe<Scalars['String']>
}

/**
 * コミュニティのコメント
 *
 * コメントの返信も同じ型
 * 原理上何層でもコメントに対してコメントが可能。
 *
 * 画像や動画は添付出来ない
 */
export type CommentRepliesArgs = {
  pageInput?: Maybe<PageInputDate>
}

/** コメントリソース情報 */
export type CommentResource = {
  uri?: Maybe<Scalars['String']>
}

/** コメントリソースInput */
export type CommentResourceInput = {
  tmpPath?: Maybe<Scalars['String']>
}

/** Comment type */
export enum CommentType {
  ArtistThread = 'artist_thread',
  Item = 'item',
  Mission = 'mission',
  Trade = 'trade',
}

/** コミュニティのコメント共通Input */
export type CommunityCommentInput = {
  artistId: Scalars['String']
  content: Scalars['String']
  isSilent?: Maybe<Scalars['Boolean']>
  /** Reply to AccountId */
  replyToAccountId?: Maybe<Scalars['String']>
  replyToId?: Maybe<Scalars['String']>
  resources?: Maybe<Array<Maybe<CommentResourceInput>>>
  /** 投げ銭 */
  tip?: Maybe<TipInput>
}

/** コミュニティコメントのいいね・いいね解除インプット */
export type CommunityCommentLikeUnlikeInput = {
  /** いいね対象のID */
  commentId: Scalars['String']
  /** RootCollectionのID */
  id: Scalars['String']
}

/**
 * 楽曲の著作権情報
 * Post投稿時に著作権楽曲を利用していた場合、申請する必要があるため保持
 */
export type Copyright = Node & {
  artistName: Scalars['String']
  artistNameKana: Scalars['String']
  id: Scalars['ID']
  songCode: Scalars['String']
  songTitle: Scalars['String']
  songTitleKana: Scalars['String']
}

export type Count = {
  count: Scalars['Int']
}

/** アーティストスレッドの作成Input */
export type CreateArtistThreadInput = {
  artistId: Scalars['String']
  category?: Maybe<ArtistThreadCategory>
  description: Scalars['String']
  isPremium: Scalars['Boolean']
  name: Scalars['String']
  status: ThreadStatus
  thumbnail?: Maybe<ThumbnailInput>
}

/** キャンペーン作成Input */
export type CreateCampaignInput = {
  acquisitionPoints: Array<CampaignAcquisitionPointsInput>
  description: Scalars['String']
  endedAt: Scalars['Date']
  metadata?: Maybe<CampaignMetadataInput>
  organizationId?: Maybe<Scalars['String']>
  rankingType: Scalars['String']
  startedAt?: Maybe<Scalars['Date']>
  thumbnailPath: Scalars['String']
  title: Scalars['String']
}

/** コレクション作成Input */
export type CreateCollectionInput = {
  /** 背景ぼかし画像パス */
  backgroundBlurImagePath?: Maybe<Scalars['String']>
  /** 背景画像パス */
  backgroundImagePath?: Maybe<Scalars['String']>
  description: Scalars['String']
  /** 公開終了日 */
  endedAt?: Maybe<Scalars['Date']>
  /** ストアで公開するかどうか */
  isPublishOnStore?: Maybe<Scalars['Boolean']>
  /** コレクションのラベル */
  labels?: Maybe<Array<Maybe<CollectionLabelInput>>>
  /** NFT情報(チェーン別) */
  nft?: Maybe<CollectionNftInput>
  /** 公開開始日 */
  startedAt?: Maybe<Scalars['Date']>
  /** 画像パス */
  thumbnailPath: Scalars['String']
  title: Scalars['String']
}

/** コメント作成Input */
export type CreateCommentInput = {
  /** アーティストID */
  artistId: Scalars['String']
  /** コメント内容 */
  content: Scalars['String']
  /**
   * 返信先のコメントID
   * 指定しない場合は返信ではなくコメントになる
   */
  replyToId?: Maybe<Scalars['String']>
  /** 画像などのリソース */
  resource?: Maybe<CommentResourceInput>
}

/** ガチャ作成Input */
export type CreateGachaInput = {
  /** ガチャのアーティストID */
  artistId: Scalars['String']
  /** 配達予定日 */
  deliveryDateText?: Maybe<Scalars['String']>
  /** ガチャ説明 */
  description: Scalars['String']
  /** 終了日（指定しない場合は無期限） */
  endAt?: Maybe<Scalars['Date']>
  /**
   * 有料ガチャかどうか(gacha_ticket or prepaid_point)
   * 指定しない場合はデフォルトでgacha_ticketが設定される
   */
  gachaRollType?: Maybe<GachaRollType>
  /** GPS情報 */
  gpsData?: Maybe<GpsDataInput>
  /** 有料会員向けかどうか */
  isPremium?: Maybe<Scalars['Boolean']>
  /** 名称 */
  name: Scalars['String']
  /** OrganizationId */
  organizationId?: Maybe<Scalars['String']>
  /** 複数メンバーシップある場合の閲覧制限 */
  planId?: Maybe<Scalars['String']>
  /** ガチャ1回の使用ポイント量 */
  price?: Maybe<Scalars['Int']>
  /**
   * 4種対応版
   * ロール時のアニメーションパス
   */
  rewardAnimations?: Maybe<RewardAnimationsInput>
  /**
   * ガチャの種別(limitless:無限 or limited:有限)
   * 指定しない場合はデフォルトでlimitedが設定される
   */
  rollAlgorithm?: Maybe<GachaRollAlgorithm>
  /** ロール時のアニメーションパス */
  rollAnimationPath?: Maybe<Scalars['String']>
  /** 引く前のアニメーションパス */
  standByAnimations?: Maybe<StandByAnimationsInput>
  /** 開始日（指定しない場合は現在時刻が自動的に入る） */
  startAt?: Maybe<Scalars['Date']>
  /** サムネイル画像パス */
  thumbnailPath: Scalars['String']
}

/**
 * アイテム作成Input
 * すでにリソースがアップロードされている場合に利用する（URI指定）
 */
export type CreateItemFromUriInput = {
  artistId?: Maybe<Scalars['String']>
  /** アバターリソースURI */
  avatarResources?: Maybe<AvatarResourcesUriInput>
  /** テンプレートで利用するカード枠のカラー */
  color?: Maybe<Scalars['String']>
  /** 著作権情報 */
  copyrights: Array<Scalars['String']>
  /** チケットアイテムの場合のカードに記載する日付 */
  date?: Maybe<Scalars['Date']>
  description: Scalars['String']
  /** シリアルや販売数など売上が分かる表示をしない場合はtrue */
  hideSalesNumber?: Maybe<Scalars['Boolean']>
  /**
   * ダウンロード可能か
   * 壁紙の場合のみ指定
   */
  isDownloadable?: Maybe<Scalars['Boolean']>
  /** メンバーシップ限定販売 */
  isPremium: Scalars['Boolean']
  /** アイテムを公開するかどうか */
  isPublished?: Maybe<Scalars['Boolean']>
  /**
   * 発行部数
   * 指定しないと無制限になります。
   */
  issuedNumber?: Maybe<Scalars['Int']>
  /** アイテムに付随する情報。メタデータ。 */
  metadata?: Maybe<ItemMetadata>
  /** 所属OrganizationId */
  organizationId?: Maybe<Scalars['String']>
  /**
   * アイテムの支払い種別
   * 指定がない場合はprepaid_point
   * cashは電子チケットのみ可
   */
  paymentType?: Maybe<UserPaymentType>
  /** 購入時のどの個人情報が必要かどうか */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /** チケットアイテムの場合のカードに記載する場所 */
  place?: Maybe<Scalars['String']>
  /** 価格 無料の可能 */
  price: Scalars['Int']
  /** アイテム公開日 */
  publishedAt?: Maybe<Scalars['Date']>
  /** アイテムのユーザー人ごとの購入制限 */
  purchaseLimitByUser?: Maybe<Scalars['Int']>
  /** アイテムの1注文あたりの購入制限 */
  purchaseLimitPerOrder?: Maybe<Scalars['Int']>
  /**
   * ランク
   * 現在利用していないので、指定しないでください。
   */
  rank?: Maybe<Scalars['Int']>
  /** アイテムレアリティ */
  rarity?: Maybe<Scalars['String']>
  /** DRM化が必要かどうか */
  requireDrm?: Maybe<Scalars['Boolean']>
  /** チケットのリセールが有効かどうか */
  resaleEnabled?: Maybe<Scalars['Boolean']>
  /** リソースURI */
  resourceUri?: Maybe<Scalars['String']>
  /**
   * リソースURI配列
   * magazineの複数画像など、複数のリソースを指定する場合に利用する
   */
  resourceUris?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 販売状態 */
  salesStatus?: Maybe<SalesStatus>
  /** SKU設定 */
  skuSettings?: Maybe<SkuSettingsInput>
  /** カードで利用する画像 */
  thumbnail: Scalars['String']
  /**
   * テンプレートなしのカード裏面で利用する画像
   * 指定しない場合はthumbnailで指定した画像が裏面になります。
   */
  thumbnailBack?: Maybe<Scalars['String']>
  /**
   * サムネイル種別
   * | 'square' 正方形
   * | 'rectangle' 長方形
   * | 'square:template' 正方形テンプレートあり
   * | 'rectangle:template' 長方形テンプレートあり
   * | 'passthrough' 加工なしでそのまま利用
   */
  thumbnailType?: Maybe<ItemThumbnailType>
  title: Scalars['String']
  type: ItemType
  /** 壁紙リソースURI */
  wallpaperResources?: Maybe<WallpaperResourcesUriInput>
}

/** アイテム作成Input */
export type CreateItemInput = {
  artistId?: Maybe<Scalars['String']>
  avatarResources?: Maybe<AvatarResourcesInput>
  /** テンプレートで利用するカード枠のカラー */
  color?: Maybe<Scalars['String']>
  /** 著作権情報 */
  copyrights: Array<Scalars['String']>
  /** チケットアイテムの場合のカードに記載する日付 */
  date?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  /** シリアルや販売数など売上が分かる表示をしない場合はtrue */
  hideSalesNumber?: Maybe<Scalars['Boolean']>
  /** アイテムの画像（最大10枚まで） */
  images?: Maybe<Array<ItemImageInput>>
  /**
   * ダウンロード可能か
   * 壁紙の場合のみ指定
   */
  isDownloadable?: Maybe<Scalars['Boolean']>
  /** メンバーシップ限定販売 */
  isPremium: Scalars['Boolean']
  /** アイテムを公開するかどうか */
  isPublished?: Maybe<Scalars['Boolean']>
  /**
   * 発行部数
   * 指定しないと無制限になります。
   */
  issuedNumber?: Maybe<Scalars['Int']>
  /** アイテムに付随する情報。メタデータ。 */
  metadata?: Maybe<ItemMetadata>
  /** 所属OrganizationId */
  organizationId?: Maybe<Scalars['String']>
  /**
   * アイテムの支払い種別
   * 指定がない場合はprepaid_point
   * cashは電子チケットのみ可
   */
  paymentType?: Maybe<UserPaymentType>
  /** 購入時のどの個人情報が必要かどうか */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /** チケットアイテムの場合のカードに記載する場所 */
  place?: Maybe<Scalars['String']>
  /** 価格 無料の可能 */
  price: Scalars['Int']
  /** アイテム公開日 */
  publishedAt?: Maybe<Scalars['Date']>
  /** アイテムのユーザー人ごとの購入制限 */
  purchaseLimitByUser?: Maybe<Scalars['Int']>
  /** アイテムの1注文あたりの購入制限 */
  purchaseLimitPerOrder?: Maybe<Scalars['Int']>
  /**
   * ランク
   *
   * 現在利用していないので、指定しないでください。
   */
  rank?: Maybe<Scalars['Int']>
  /** アイテムレアリティ */
  rarity?: Maybe<Scalars['String']>
  /** DRM化が必要かどうか */
  requireDrm?: Maybe<Scalars['Boolean']>
  /** チケットのリセールが有効かどうか */
  resaleEnabled?: Maybe<Scalars['Boolean']>
  /**
   * リソースID
   * resourceIdを指定してください
   * ただし、
   *   ・アバターの場合はavatarResourcesを指定してください
   *   ・壁紙の場合はwallpaperResourcesを指定してください
   * メディアデータはリソースとして一時保存する
   */
  resourceId?: Maybe<Scalars['String']>
  /**
   * リソースID配列
   * magazineの複数画像など、複数のリソースを指定する場合に利用する
   */
  resourceIds?: Maybe<Array<Maybe<Scalars['String']>>>
  /** 販売状態 */
  salesStatus?: Maybe<SalesStatus>
  /** 販売方法 */
  salesType?: Maybe<SalesType>
  /** SKU設定 */
  skuSettings?: Maybe<SkuSettingsInput>
  /** カードで利用する画像 */
  thumbnail: Scalars['String']
  /**
   * テンプレートなしのカード裏面で利用する画像
   * 指定しない場合はthumbnailで指定した画像が裏面になります。
   */
  thumbnailBack?: Maybe<Scalars['String']>
  /**
   * サムネイル種別
   * | 'square' 正方形
   * | 'rectangle' 長方形
   * | 'square:template' 正方形テンプレートあり
   * | 'rectangle:template' 長方形テンプレートあり
   * | 'passthrough' 加工なしでそのまま利用
   */
  thumbnailType?: Maybe<ItemThumbnailType>
  title: Scalars['String']
  type: ItemType
  wallpaperResources?: Maybe<WallpaperResourcesInput>
}

/** ライブ配信作成パラメーター */
export type CreateLiveStreamInput = {
  endedAt?: Maybe<Scalars['Date']>
  isPremium: Scalars['Boolean']
  startedAt?: Maybe<Scalars['Date']>
}

/** ミッション作成Input */
export type CreateMissionInput = {
  /** アーティストID */
  artistId?: Maybe<Scalars['String']>
  /** ミッションコンテンツ */
  content: QuizContentInput
  /** ミッション説明 */
  description: Scalars['String']
  /** 期限 */
  expiredAt: Scalars['Date']
  /** GPS情報 */
  gpsData?: Maybe<GpsDataInput>
  /** 公開する場合にtrue */
  isPublished: Scalars['Boolean']
  /** OrganizationId (指定しない場合はnullが設定される) */
  organizationId?: Maybe<Scalars['String']>
  /** 報酬 */
  reward: MissionRewardInput
  /** ミッション開始日時 */
  startedAt?: Maybe<Scalars['Date']>
  /** ミッションタイトル */
  title: Scalars['String']
  /**
   * ミッション種別
   * | 'quiz'
   * | 'twitter_retweet'
   * | 'twitter_follow'
   * | 'item_collect'
   */
  type: MissionType
}

/**
 * createPaymentMethodの結果
 * RequiresActionの場合はクライアントサイドでnextActionを実行する必要がある
 *
 * PaymentSource | RequiresAction としたいがtypeの問題でできないのでCreditCard以外のtypeがPaymentSourceに追加される場合は両方変更する
 */
export type CreatePaymentMethodResponse = CreditCard | RequiresAction

/** ポスト作成Input */
export type CreatePostInput = {
  /** ポストのカテゴリー */
  categoryId?: Maybe<Scalars['String']>
  /** ポスト内容 */
  content: Scalars['String']
  /** 著作権情報 */
  copyrights?: Maybe<Array<Scalars['String']>>
  /** 有料会員向けかどうか */
  isPremium?: Maybe<Scalars['Boolean']>
  /** 複数メンバーシップある場合の閲覧制限 */
  planId?: Maybe<Scalars['String']>
  /** 予約投稿日時 */
  publishedAt?: Maybe<Scalars['Date']>
  /** 音声などのリソース */
  resource?: Maybe<ResourceInput>
  /**
   * ポスト種別
   * 'video' | 'image' | 'text' | 'sound'
   */
  resourceType: PostType
  /** 画像リソース(複数) */
  resources?: Maybe<Array<ImageResourceInput>>
  /** ステータス(指定しない場合は published: 即時公開) */
  status?: Maybe<PostStatus>
  /** twitterにも投稿する場合はtrue */
  withTwitter?: Maybe<Scalars['Boolean']>
}

/** リセール作成インプット */
export type CreateResaleItemInput = {
  /** ItemID */
  itemId: Scalars['String']
  /** 出品するシリアルID配列 */
  serialIds: Array<Scalars['String']>
  /** SkuID */
  skuId?: Maybe<Scalars['String']>
}

/** リソース作成Input */
export type CreateResourceInput = {
  /** storageのtmpファイルパス tmp/... という形で渡す */
  filePath: Scalars['String']
  /** ファイル名 */
  name: Scalars['String']
}

/** 自動ミッション作成Input */
export type CreateStepMissionInput = {
  /** アーティストID */
  artistId: Scalars['String']
  /** 自動ミッションの達成ステップ */
  steps?: Maybe<Array<MissionStepInput>>
  /** ミッション種別 */
  type: StepMissionType
}

/** トレード申請の作成 */
export type CreateTradeRequestInput = {
  /** トレード先候補のアイテム配列 */
  candidateItems: Array<TradeItemInput>
  /** トレード候補者のユーザーID */
  candidateUserId: Scalars['String']
  /** トレードに必要なポイント */
  point?: Maybe<Scalars['Int']>
  /** リクエストするユーザーが提供可能なアイテム配列 */
  providableItems: Array<TradeItemInput>
  /** リクエストコメント */
  requestComment?: Maybe<CommunityCommentInput>
  /** リクエストするItemID */
  requestItemId: Scalars['String']
  /** リクエストを作成したユーザーID */
  requestedByUserId: Scalars['String']
}

/** クレジットカード情報 */
export type CreditCard = {
  brand: Scalars['String']
  country?: Maybe<Scalars['String']>
  expMonth: Scalars['Int']
  expYear: Scalars['Int']
  id: Scalars['ID']
  last4: Scalars['String']
  name?: Maybe<Scalars['String']>
}

/** 暗号資産ウォレット情報 */
export type CryptoWallet = {
  /** ウォレットアドレス */
  address?: Maybe<Scalars['String']>
  /** 暗号資産ウォレット認証情報 */
  authToken?: Maybe<AuthToken>
  /** ウォレット連携日 */
  connectedAt?: Maybe<Scalars['Date']>
  /** ステータス */
  status: Scalars['String']
  /** ウォレット種別 */
  walletType: Scalars['String']
}

/** 暗号資産ウォレットの種別（metamask etc) */
export enum CryptoWalletType {
  Metamask = 'metamask',
  Web3auth = 'web3auth',
}

/** CSVダウンロードデータ */
export type CsvDownload = {
  /** CSV url */
  csvUrl?: Maybe<Scalars['String']>
}

export type CustomProfile = {
  avatarItem?: Maybe<Item>
  avatarItemId?: Maybe<Scalars['ID']>
  wallpaperItem?: Maybe<Item>
  wallpaperItemId?: Maybe<Scalars['ID']>
}

/** 日次ごとの売上データ */
export type DailySales = {
  /** 日付 */
  createdAt: Scalars['Date']
  /** ストア売上 */
  store?: Maybe<TotalSales>
  /** サブスク売上 */
  subscription?: Maybe<TotalSales>
  /** 投げ銭売上 */
  tip?: Maybe<TotalSales>
}

/** 退会処理インプット */
export type DeactivateUserInput = {
  /** 退会時のご意見 */
  opinion?: Maybe<Scalars['String']>
  /** 退会理由で「その他」を選択したときの入力 */
  otherReason?: Maybe<Scalars['String']>
  /** 退会理由の選択番号 */
  reasonNumber: Scalars['Int']
}

/** トレードリクエストを却下するInput */
export type DeclineTradeRequestInput = {
  /** メッセージ */
  declineComment?: Maybe<CommunityCommentInput>
  /** TradeNegotiationId */
  id: Scalars['ID']
  /** TradeCandidateId */
  tradeCandidateId: Scalars['String']
}

/** カート削除インプット */
export type DeleteCartInput = {
  /** カートID */
  cartId: Scalars['ID']
}

/**
 * 配送先住所
 * @deprecated("DeliveryInformation に置き換えられました。今後は使用しないでください")
 */
export type Delivery = {
  address?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  prefecture?: Maybe<Scalars['String']>
}

/** 配送先住所Input */
export type DeliveryAddressInput = {
  /** 市区町村 */
  city: Scalars['String']
  /** 番地 */
  line1: Scalars['String']
  /** ビル、マンション名など */
  line2?: Maybe<Scalars['String']>
  /** 郵便番号 */
  postalCode: Scalars['String']
  /** 都道府県 */
  prefecture: Scalars['String']
}

/** 配送先情報 */
export type DeliveryInformation = {
  /** 配送先住所 */
  address?: Maybe<UserAddress>
  /** ユーザーのフルネーム */
  fullName?: Maybe<Scalars['String']>
  /** ユーザーの電話番号 */
  phoneNumber?: Maybe<Scalars['String']>
}

/** 物販アイテム等の配送情報 */
export type DeliveryItem = Node & {
  /** ユーザーからの配送申請日 */
  appliedAt?: Maybe<Scalars['Date']>
  createdAt: Scalars['Date']
  deliveryInfo?: Maybe<DeliveryInformation>
  formattedAppliedAt?: Maybe<Scalars['String']>
  formattedShippedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  item: Item
  itemId: Scalars['String']
  itemSerial?: Maybe<ItemSerial>
  /** シリアルID */
  serialId?: Maybe<Scalars['String']>
  /** 出荷日（出荷ステータスに変更した日） */
  shippedAt?: Maybe<Scalars['Date']>
  /** 配送ステータス */
  status: DeliveryStatus
  user?: Maybe<User>
  userId: Scalars['String']
}

/** 配送ステータス */
export enum DeliveryStatus {
  /** 出荷手配中 */
  Arranging = 'arranging',
  /** 出荷手配済み */
  Shipped = 'shipped',
  /** ユーザ0未申請 */
  Unapplied = 'unapplied',
}

/** Edge */
export type Edge = {
  /** データの位置 */
  cursor: Scalars['String']
  /**
   * 情報の実体
   * 各Filedの取得はinline fragmentでtypeを解決して領する
   */
  node: Node
}

/** メール認証確認情報 */
export type EmailVerification = {
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  verificationCode?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
}

/** DRMの暗号化ステータス */
export enum EncryptingStatus {
  Encrypted = 'encrypted',
  Prepared = 'prepared',
  Processing = 'processing',
}

/** キャンペーンにエントリーしているアーティスト */
export type EntryArtist = Node & {
  artist: Artist
  artistId: Scalars['ID']
  createdAt: Scalars['Date']
  id: Scalars['ID']
  point: Scalars['Int']
  updatedAt: Scalars['Date']
}

/** アーティストのキャンペーンランキング */
export type EntryCampaignArtistMyRank = {
  artistId: Scalars['String']
  displayName: Scalars['String']
  point: Scalars['Int']
  rank: Scalars['Int']
}

/** キャンペーン用の画像テンプレート設定 */
export type EntryCampaignImageTemplates = {
  collection?: Maybe<ImageTemplateCommon>
  gacha?: Maybe<ImageTemplateCommon>
  items?: Maybe<Array<ImageTemplateItem>>
}

/** ユーザーランキング、またはアーティストランキング */
export type EntryCampaignMyRank =
  | EntryCampaignArtistMyRank
  | EntryCampaignUserMyRank

/** エントリー中キャンペーンのランキング */
export type EntryCampaignRanking = {
  id: Scalars['ID']
  myRank: EntryCampaignMyRank
  /** キャンペーンのサムネイル画像 */
  thumbnail: Image
}

/** ユーザーのキャンペーンランキング */
export type EntryCampaignUserMyRank = {
  displayName: Scalars['String']
  point: Scalars['Int']
  rank: Scalars['Int']
}

/**
 * キャンペーンのエントリー条件設定
 * アーティストによる自動エントリーで利用
 */
export type EntryCondition = {
  description: Scalars['String']
  items: EntryConditionItems
}

/** キャンペーンエントリー時のアイテム条件 */
export type EntryConditionItem = {
  /** 必要なアイテム数 */
  count: Scalars['Int']
  /**
   * 必要なアイテムタイプ。配列のため、指定されているどれかに合致すればOK
   * 空配列の場合は全種別登録可能
   */
  type: Array<ItemType>
  /** レアリティの加重(0-1) */
  weight: Scalars['Float']
}

/** キャンペーンエントリー条件となるアイテム設定 */
export type EntryConditionItems = {
  common?: Maybe<EntryConditionItem>
  rare?: Maybe<EntryConditionItem>
  superrare?: Maybe<EntryConditionItem>
}

/** イベント */
export type Event = Node & {
  aboutDateRange?: Maybe<Scalars['String']>
  /** アーティスト */
  artist: Artist
  /** アーティストID */
  artistId?: Maybe<Scalars['String']>
  /** バナー */
  banners: Array<EventBanner>
  /** イベント非開催日配列 */
  closeDate?: Maybe<Array<Scalars['Date']>>
  /** イベント作成日 */
  createdAt: Scalars['Date']
  dateArray?: Maybe<Array<Maybe<Scalars['String']>>>
  dateRange?: Maybe<Scalars['String']>
  /** イベント説明 */
  description?: Maybe<Scalars['String']>
  /** イベント終了日時 */
  endedAt?: Maybe<Scalars['Date']>
  /**
   * イベントに設定されたコレクション
   * filterを指定しない場合は公開・非公開のものが返される
   * Node: EventCollection
   * @auth: none
   */
  eventCollections: PageResult
  formattedEndedAt?: Maybe<Scalars['String']>
  formattedEventTime?: Maybe<Scalars['String']>
  formattedStartedAt?: Maybe<Scalars['String']>
  /** イベントID */
  id: Scalars['ID']
  /** イベント会場の位置情報 */
  location?: Maybe<GpsData>
  /** 先行販売で所有している自分のアイテム */
  myPreSaleItems?: Maybe<Array<PreSaleItem>>
  /** 商品購入前に表示する注意文言 */
  note?: Maybe<Scalars['String']>
  /**
   * イベントの公開中の先行販売を一覧で取得する
   * ※アーティスト権限で非公開のものも欲しい場合は、artistPreSalesを使ってください
   */
  preSales: PageResult
  /** アーティスト用 先行販売の集計データ */
  preSalesAggregation?: Maybe<PreSalesAggregation>
  /** イベント開始日時 */
  startedAt?: Maybe<Scalars['Date']>
  /** イベントステータス */
  status: EventStatus
  /** イベントサムネイル */
  thumbnail?: Maybe<Image>
  /** イベントタイトル */
  title: Scalars['String']
  /** イベント更新日 */
  updatedAt: Scalars['Date']
}

/** イベント */
export type EventEventCollectionsArgs = {
  filter?: Maybe<CollectionStatus>
  pageInput?: Maybe<PageInput>
}

/** イベント */
export type EventPreSalesArgs = {
  pageInput?: Maybe<PageInput>
}

/** イベントバナー */
export type EventBanner = {
  /** CLICK時に開くリンク */
  link?: Maybe<BannerLink>
  /** 表示する順番 */
  order: Scalars['Int']
  /** Banner Thumbanil URI */
  thumbnail: Image
}

/** イベントコレクション */
export type EventCollection = Node & {
  /** コレクション */
  collection: Collection
  /** コレクションID */
  collectionId: Scalars['String']
  /** イベントID */
  eventId: Scalars['String']
  /** ID (=コレクションID) */
  id: Scalars['ID']
  /**
   * ログインしているユーザーの受け取り時間
   * 同じコレクションでも時間が違う場合もあるため配列で返される
   */
  myPickupUsers?: Maybe<Array<PickupUser>>
  /** 受け取り場所 */
  pickupLocation?: Maybe<Scalars['String']>
  /** 受け取り時間設定 */
  pickupTime?: Maybe<Array<EventPickupTime>>
  /** 注文あたりのアイテム数購入上限 */
  purchaseLimitPerPickup?: Maybe<Scalars['Int']>
  /** 有効時間（単位：分） */
  validMinutes?: Maybe<Scalars['Int']>
}

/** イベントフィルター */
export enum EventFilter {
  /** すべて */
  All = 'all',
  /** 終了したイベント */
  Finished = 'finished',
  /** 開催中のイベント */
  Ongoing = 'ongoing',
  /** 開催予定のイベント */
  Upcoming = 'upcoming',
}

/** イベント開催ステータス */
export enum EventHeldStatus {
  /** すべて */
  All = 'all',
  /** 開催終了 */
  Finished = 'finished',
  /** 開催中 */
  Ongoing = 'ongoing',
  /** 開催中、または開催予定 */
  OngoingUpcoming = 'ongoing_upcoming',
  /** 開催中 */
  Upcoming = 'upcoming',
}

/** イベントの受け取り時間 */
export type EventPickupTime = {
  /** コレクションID */
  collectionId: Scalars['String']
  /** イベントID */
  eventId: Scalars['String']
  /** 受け取り時間オプション */
  options: EventPickupTimeOption
}

/** 受け取り日時Input */
export type EventPickupTimeInput = {
  /** 日時オプション */
  options: EventPickupTimeOptionInput
}

/** 受け取り時間オプション */
export type EventPickupTimeOption = {
  /** 受け取り可能人数 */
  availableUserNumber: Scalars['Int']
  /** コレクションID */
  collectionId: Scalars['String']
  /** 現在の予約済みユーザー数 */
  currentUserNumber: Scalars['Int']
  /** イベントID */
  eventId: Scalars['String']
  /** 時間 */
  time: Scalars['Date']
}

/** 受け取り時間オプションInput */
export type EventPickupTimeOptionInput = {
  /** 対応可能人数 */
  availableUserNumber: Scalars['Int']
  /** 受け取り日時 */
  time: Scalars['Date']
}

/** イベント受け取りユーザーの検索オプション */
export type EventPickupUsersOptions = {
  /** 過去の受け取りかどうか */
  isHistory?: Maybe<Scalars['Boolean']>
  /** 受け取り日時の昇順・降順 */
  orderBy?: Maybe<OrderBy>
  /** ユーザーの名前、または電話番号（前方一致で検索） */
  searchTerm?: Maybe<Scalars['String']>
  /** 受け取り状況 */
  type?: Maybe<PickupTicketStatus>
}

/**
 * イベントの座席情報
 * 詳細はサブコレクションにある
 */
export type EventSeat = {
  /** イベント */
  event: Event
  /** イベント終了日時 */
  eventEndedAt?: Maybe<Scalars['Date']>
  /** イベントID */
  eventId: Scalars['String']
  /** イベント開始日時 */
  eventStartedAt?: Maybe<Scalars['Date']>
  /** ID */
  id: Scalars['ID']
  /** イベントのチケットアイテムID */
  itemIds: Array<Scalars['String']>
  /** アイテム */
  items: Array<Item>
  /** 座席テンプレート */
  seatTemplate: SeatTemplate
  /** 座席テンプレートID */
  seatTemplateId: Scalars['String']
}

/** イベント座席Input */
export type EventSeatInput = {
  /** 座席の連番表現用マーカー */
  consecutive?: Maybe<SeatConsecutiveLabel>
  /** 割当て無効な席かどうか */
  disabled?: Maybe<Scalars['Boolean']>
  /** 列番号 */
  line: Scalars['String']
  /** 座席番号、座席名 */
  seat: Scalars['String']
  /** チケットシリアルUID */
  ticketSerialUid?: Maybe<Scalars['String']>
}

/** イベントの座席情報詳細 */
export type EventSeatSection = {
  /** ID */
  id: Scalars['ID']
  /**
   * 座席セクション
   * イベント作成時にSeatTemplateのseatSectionsをコピーして作成される
   */
  seatSections: Array<SeatSection>
}

/** イベント座席セクションInput */
export type EventSeatSectionInput = {
  /** 座席情報 */
  seats: Array<EventSeatInput>
  /** セクション名 */
  sectionName: Scalars['String']
}

/** イベントステータス */
export enum EventStatus {
  /** 削除状態 */
  Archived = 'archived',
  /** 公開 */
  Published = 'published',
  /** 非公開（限定リンク） */
  Unpublished = 'unpublished',
}

/** 手数料設定 */
export type Fee = {
  collectionFee?: Maybe<Collection>
  /** 計算済み手数料（単位：JPY） */
  fee: Scalars['Int']
  /** 手数料詳細 */
  feeDescription?: Maybe<Scalars['String']>
  /** 手数料表示名 */
  feeName?: Maybe<Scalars['String']>
  /** 手数料種別 */
  feeType?: Maybe<FeeType>
  formattedFee?: Maybe<Scalars['String']>
  /**
   * IDに紐づく手数料場合のID（コレクションIDなど）
   * なお、コレクションの場合、配列で複数手数料が設定できる。その場合は、 {collectoinId}:{連番} のフォーマットで持たせること
   */
  id?: Maybe<Scalars['ID']>
  /**
   * 手数料の支払単位
   * nullの場合は注文ごと(=order)
   */
  per?: Maybe<FeePerType>
}

/** 手数料の支払単位 */
export enum FeePerType {
  /** アイテム1つにつき（購入数分必要） */
  Item = 'item',
  /** 注文ごと */
  Order = 'order',
}

export enum FeeSettingPer {
  Item = 'item',
  Order = 'order',
}

/** 手数料種別 */
export enum FeeType {
  /** コレクションのカスタム手数料 */
  Collection = 'collection',
  /** コンビニ決済手数料 */
  Konbini = 'konbini',
  /** 配送料 */
  Shipping = 'shipping',
}

/** フィード */
export type Feed = Node & {
  artist?: Maybe<Artist>
  createdAt: Scalars['Date']
  data?: Maybe<FeedData>
  id: Scalars['ID']
  type: Scalars['String']
  user: User
}

/**
 * フィードデータ
 * コメントかポスト
 */
export type FeedData = Comment | Post

/**
 * フィードの検索フィルタリング
 * より詳細に検索したい場合はアプリ側でフィルタリングする
 */
export enum FeedType {
  Comment = 'comment',
  Post = 'post',
}

/** File用オブジェクト */
export type File = {
  encoding: Scalars['String']
  filename: Scalars['String']
  mimetype: Scalars['String']
}

export type FollowSettings = {
  autoFollow: Scalars['Boolean']
}

/**
 * フォロワー情報
 * IDは uid:artistId で作られます
 */
export type Follower = Node & {
  artist: Artist
  artistId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  uid?: Maybe<Scalars['String']>
  user: User
}

/** ガチャ情報 */
export type Gacha = Node & {
  /** 選定中のアニメーション webpを想定 */
  animation: GachaAnimation
  artist?: Maybe<Artist>
  artistId: Scalars['String']
  /** 配達予定日 */
  deliveryDateText?: Maybe<Scalars['String']>
  description: Scalars['String']
  /** なければ無期限 */
  endAt?: Maybe<Scalars['Date']>
  formattedEndAt?: Maybe<Scalars['String']>
  /**
   * ガチャのロール種別
   * gachaTicket or prepaidPoint
   */
  gachaRollType?: Maybe<GachaRollType>
  /** GPS情報 */
  gpsData?: Maybe<GpsData>
  id: Scalars['ID']
  /**
   * ガチャ表示で適切な権限を持っているか
   * メンバーシップ向けガチャで、適切なプランに加入していない場合はfalseが返される
   */
  isAccessible: Scalars['Boolean']
  isLimited?: Maybe<Scalars['Boolean']>
  /** 有料会員向けかどうか */
  isPremium?: Maybe<Scalars['Boolean']>
  /** 公開されているガチャかどうか */
  isPublished?: Maybe<Scalars['Boolean']>
  isSoldOut?: Maybe<Scalars['Boolean']>
  items: Array<GachaItem>
  name: Scalars['String']
  /** 所属organizationId */
  organizationId?: Maybe<Scalars['String']>
  /** 複数メンバーシップある場合の閲覧制限 */
  planId?: Maybe<Scalars['String']>
  /** ガチャの価格 */
  price?: Maybe<Scalars['Int']>
  /** このガチャで購入可能なチケットセットリスト */
  purchasableGachaTickets: Array<Maybe<PurchasableGachaTicket>>
  /** レアリティ、出現確率 1になるように設定 */
  rarities: Array<GachaRarity>
  /** 有限ガチャで残っているアイテム総数 */
  remainingLimitedItemCount?: Maybe<Scalars['Int']>
  /** ガチャの4種類アニメーション */
  rewardAnimations?: Maybe<RewardAnimations>
  /** ガチャのアルゴリズム */
  rollAlgorithm?: Maybe<GachaRollAlgorithm>
  /** ガチャの引く前のアニメーション */
  standByAnimations?: Maybe<GachaStandByAnimations>
  /** デフォルト値は当日 */
  startAt?: Maybe<Scalars['Date']>
  thumbnail: Image
  /** 発行されたアイテムの総和 */
  totalIssuedItemCount?: Maybe<Scalars['Int']>
  /** 有限ガチャに設定されているアイテム総数 */
  totalLimitedItemCount?: Maybe<Scalars['Int']>
}

/** ガチャを引いた場合のアニメーション */
export type GachaAnimation = {
  roll: Image
  winning?: Maybe<Image>
}

/** ガチャで手に入るアイテム情報 */
export type GachaItem = {
  /** 排出数（有限ガチャの場合） */
  count?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  /**
   * アイテムの発行数
   * issued-item-countsの値を格納する
   * @params gachaId
   */
  issuedCount?: Maybe<Scalars['Int']>
  item: Item
  rarityId: Scalars['String']
  /** 確率（無限ガチャの場合） */
  weight?: Maybe<Scalars['Float']>
}

/** ガチャで手に入るアイテム情報 */
export type GachaItemIssuedCountArgs = {
  gachaId: Scalars['ID']
}

/** ガチャアイテム作成Input */
export type GachaItemInput = {
  id: Scalars['String']
  /** RarityID */
  rarityId: Scalars['String']
}

/** 有限ガチャのセット */
export type GachaLimitedItemSet = {
  /** ID */
  id: Scalars['ID']
  /** アイテム */
  items: Array<GachaLimitedItemSetItem>
  /** アイテムセットのサイズ */
  setSize: Scalars['Int']
  /** ステータス */
  status: GachaLimitedItemSetStatus
}

/** 有限ガチャのアイテム */
export type GachaLimitedItemSetItem = {
  /** 使用済みかどうか */
  isUsed: Scalars['Boolean']
  /** アイテムID */
  itemId: Scalars['String']
  /** シリアルID */
  serialId: Scalars['String']
}

/** 有限ガチャアイテムセットのステータス */
export enum GachaLimitedItemSetStatus {
  /** 単発ガチャで消費中 */
  Halfway = 'halfway',
  /** 未使用 */
  Unused = 'unused',
  /** 使用済み */
  Used = 'used',
}

/** ガチャの取引履歴 */
export type GachaOrder = {
  createdAt: Scalars['Date']
  gacha: Gacha
  gachaId: Scalars['String']
  id: Scalars['ID']
  /**
   * ガチャ結果のアニメーションURI
   * 指定したレアリティ（当たり）が含まれる場合は特別演出アニメーションになる
   */
  rewardAnimation?: Maybe<Image>
  rewardItems: Array<GachaRewardItem>
  status: GachaOrderStatus
  usedGachaTicket: UsedGachaTicket
}

/** ガチャ購入ステータス種別 */
export enum GachaOrderStatus {
  Failed = 'failed',
  Success = 'success',
}

/** ガチャのレアリティ */
export type GachaRarity = {
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  /** 0~1 小数 */
  weight: Scalars['Float']
}

/** ガチャの実行結果 */
export type GachaResult = {
  gachaOrder: GachaOrder
}

/** 報酬アニメーション詳細 */
export type GachaRewardAnimation = {
  /** レアリティ別アニメーション:ノーマル演出 */
  common?: Maybe<Image>
  /** レアリティ別アニメーション:リミテッド演出 */
  limited?: Maybe<Image>
  /** 4種アニメーション:通常時演出 */
  normal?: Maybe<Image>
  /** レアリティ別アニメーション:レア演出 */
  rare?: Maybe<Image>
  /** 4種アニメーション:当たり演出 */
  special?: Maybe<Image>
  /** レアリティ別アニメーション:スーパーレア演出 */
  superrare?: Maybe<Image>
  /** レアリティ別アニメーション:ウルトラレア演出 */
  ultrarare?: Maybe<Image>
}

/** ミッション報酬がGachaTicketの場合の報酬アイテム */
export type GachaRewardItem = {
  count: Scalars['Int']
  item: Item
  itemId: Scalars['String']
  obtainedSerialIds: Array<Scalars['String']>
  /** ガチャで獲得したアイテムのシリアル情報 */
  obtainedUserItemSerials: Array<UserItemSerial>
  /** ユーザーが保有しているアイテム */
  userItem: UserItem
}

/** ガチャアルゴリズム */
export enum GachaRollAlgorithm {
  Limited = 'limited',
  Limitless = 'limitless',
}

/** ガチャロール種別 */
export enum GachaRollType {
  /** チケット優先（プリペイドポイントも可能） */
  GachaTicket = 'gacha_ticket',
  /** プリペイドポイントのみ対応 */
  PrepaidPoint = 'prepaid_point',
}

export type GachaSettings = {
  upcomingExpiry?: Maybe<NotificationChannels>
}

/** ガチャの引く前アニメーション */
export type GachaStandByAnimations = {
  /** 10連ガチャ用 */
  bulk?: Maybe<Image>
  /** 単発ガチャ用 */
  single?: Maybe<Image>
}

/** ガチャチケット情報 */
export type GachaTicket = Node & {
  artist?: Maybe<Artist>
  artistId: Scalars['String']
  createdAt: Scalars['Date']
  description: Scalars['String']
  id: Scalars['ID']
  isPurchasable: Scalars['Boolean']
  name: Scalars['String']
  price: Scalars['Int']
  serial?: Maybe<Serial>
  serialNumber?: Maybe<Scalars['Int']>
  specifiedGachas?: Maybe<Array<Scalars['String']>>
  thumbnails?: Maybe<GachaTicketThumbnails>
  updatedAt: Scalars['Date']
}

/** ガチャチケット情報 */
export type GachaTicketSerialArgs = {
  id: Scalars['ID']
}

/** ガチャチケットの取引履歴 */
export type GachaTicketOrder = Node & {
  artistId?: Maybe<Scalars['String']>
  count: Scalars['Int']
  createdAt: Scalars['Date']
  formattedCreatedAt?: Maybe<Scalars['String']>
  gachaId?: Maybe<Scalars['String']>
  gachaTicketId: Scalars['String']
  id: Scalars['ID']
  orderType: Scalars['String']
  status: Scalars['String']
  title?: Maybe<Scalars['String']>
}

/** ガチャチケット履歴の種別 */
export enum GachaTicketOrderType {
  LoginBonus = 'login_bonus',
  MembershipReward = 'membership_reward',
  MissionReward = 'mission_reward',
  Purchase = 'purchase',
  Roll = 'roll',
  Serial = 'serial',
}

/**
 * ガチャチケットのサムネイル
 * 報酬で表示する場合がreward / 通常のアイコン表示などがstandard
 */
export type GachaTicketThumbnails = {
  reward?: Maybe<Image>
  standard?: Maybe<Image>
}

/** 性別種別 */
export enum Gender {
  /** 女性 */
  Female = 'female',
  /** 男性 */
  Male = 'male',
  /** 指定しない */
  None = 'none',
}

/** Generate Multiple Item Serials Input */
export type GenerateMultiItemSerialsInput = {
  /** URL Issue Count */
  issueCount: Scalars['Int']
  /** アイテムと獲得数のマッピングの配列データ */
  items: Array<ItemSerialCountInput>
}

/** チケット分配リンク生成Input */
export type GenerateTicketDistributionLinkInput = {
  /** アイテムID */
  itemId: Scalars['String']
  /** シリアルID */
  serialId: Scalars['String']
}

/** 暗号資産ウォレット連携用の署名作成インプット */
export type GenerateWalletSignatureMessageInput = {
  /** ウォレットの種別（metamask etc) */
  cryptoWalletType: CryptoWalletType
}

/** 背景アイテムのコンビニプリント用QRコード生成Input */
export type GenerateWallpaperPrintQrcodeInput = {
  /** アイテムID */
  itemId: Scalars['String']
  /** シリアルID */
  serialId: Scalars['String']
}

/** ガチャチケット付与Input */
export type GiveGachaTicketInput = {
  /** 枚数 */
  count: Scalars['Int']
  /** organizationId */
  organizationId?: Maybe<Scalars['String']>
  /** 付与理由 */
  reason?: Maybe<Scalars['String']>
  /** ユーザーID */
  userId: Scalars['String']
}

/** GPSデータ */
export type GpsData = {
  /** おおよその住所（○○付近） */
  approximateAddress?: Maybe<Scalars['String']>
  /** 緯度 */
  latitude: Scalars['Float']
  /** 軽度 */
  longitude: Scalars['Float']
  /** マップURI（外部サイトの画像など） */
  mapUri?: Maybe<Scalars['String']>
  /** 有効とする半径 */
  radius?: Maybe<Scalars['Float']>
}

/** GPS情報 */
export type GpsDataInput = {
  /**
   * おおよその住所
   * 入力がない場合は緯度経度からreverse geoして取得してくる
   */
  approximateAddress?: Maybe<Scalars['String']>
  /** 緯度 */
  latitude: Scalars['Float']
  /** 経度 */
  longitude: Scalars['Float']
  /** 外部サイトなどのマップURL */
  mapUri?: Maybe<Scalars['String']>
  /** 緯度経度を起点とした半径(単位:meter) */
  radius?: Maybe<Scalars['Float']>
}

/** IAPによる定期購入のプロダクト情報 */
export type IapProductInfo = {
  /** プランID（現状Androidでのみ利用） */
  planId?: Maybe<Scalars['String']>
  /** 各プラットフォームのサブスクリプションのプロダクトID */
  productId: Scalars['String']
}

/** IAPによる定期課金情報 */
export type IapSubscriptionInfo = {
  /** 購入元ストア */
  store: StoreType
}

/** 画像情報 */
export type Image = {
  /** オリジナルに対し80%圧縮のみ行ったURI(画像) */
  compressedUri?: Maybe<Scalars['String']>
  contentType?: Maybe<Scalars['String']>
  /**
   * 通常画面表示用の中サイズURI(画像)
   * 1000x1000 / 80%圧縮
   */
  mediumUri?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  /**
   * サムネイル等表示用の小サイズURI(画像)
   * 200x200 / 80%圧縮
   */
  smallUri?: Maybe<Scalars['String']>
  uri?: Maybe<Scalars['String']>
}

/** 画像Input */
export type ImageInput = {
  /** 画像URI */
  uri: Scalars['String']
}

/** 画像リソースInput */
export type ImageResourceInput = {
  /** storageに保存された一時ファイルのパス */
  tmpPath?: Maybe<Scalars['String']>
  /** リソースのURL */
  uri?: Maybe<Scalars['String']>
}

/** 画像テンプレートの情報 */
export type ImageTemplate = {
  overrideElements: Array<ImageTemplateOverrideElement>
  templateFormatName?: Maybe<Scalars['String']>
  templateId: Scalars['String']
}

/** 画像テンプレートバナーInput */
export type ImageTemplateBannerInput = {
  banner?: Maybe<ImageTemplateInput>
}

/** 画像テンプレート群（共通） */
export type ImageTemplateCommon = {
  banner: ImageTemplate
}

/** 画像テンプレートInput */
export type ImageTemplateInput = {
  overrideElements: Array<ImageTemplateOverrideElementsInput>
  templateFormatName: Scalars['String']
  templateId: Scalars['String']
}

/** 画像テンプレート群（アイテム専用） */
export type ImageTemplateItem = {
  back?: Maybe<ImageTemplate>
  front?: Maybe<ImageTemplate>
  rarity: Scalars['String']
}

/** アイテム用画像テンプレートインプット（表と裏がある） */
export type ImageTemplateItemInput = {
  back?: Maybe<ImageTemplateInput>
  front?: Maybe<ImageTemplateInput>
}

/** アイテム用画像テンプレート設定 */
export type ImageTemplateItems = {
  items: Array<ImageTemplateItem>
}

/** 画像テンプレートの上書き設定情報 */
export type ImageTemplateOverrideElement = {
  elementName: Scalars['String']
  type?: Maybe<ImageTemplateOverrideElementType>
  value?: Maybe<Scalars['String']>
}

/** 画像テンプレートの設定上書き種別 */
export enum ImageTemplateOverrideElementType {
  Image = 'image',
  Text = 'text',
}

/** 画像テンプレート内overrideElements用Input */
export type ImageTemplateOverrideElementsInput = {
  elementName: Scalars['String']
  type: ImageTemplateOverrideElementsType
  value?: Maybe<Scalars['String']>
}

/**
 * 画像テンプレートの上書き種別
 * image: 画像で上書き
 * text: テキストで上書き
 */
export enum ImageTemplateOverrideElementsType {
  Image = 'image',
  Text = 'text',
}

/** 通常の画像とぼかし画像のセット */
export type ImageWithBlur = {
  blurUri?: Maybe<Scalars['String']>
  uri?: Maybe<Scalars['String']>
}

/** アイテム */
export type Item = Node & {
  /** typeがavatgarの場合のリソース */
  avatarResources?: Maybe<AvatarResources>
  buyOverLimit: Scalars['Boolean']
  /** キャンセルされた数 */
  canceledNumber?: Maybe<Scalars['Int']>
  /** 所属しているコレクション */
  collection?: Maybe<Collection>
  /** コメント数 */
  commentCount: Scalars['Int']
  /**
   * アイテムコメント
   * Node: Comment
   */
  comments: PageResult
  /** 著作権情報 */
  copyrights?: Maybe<Array<Scalars['String']>>
  createdAt: Scalars['Date']
  /** アイテム説明 */
  description?: Maybe<Scalars['String']>
  /**
   * アイテム単体購入時の購入時手数料
   * purchaseNumberはデフォルト1
   */
  fees?: Maybe<CheckoutFees>
  formattedPrice?: Maybe<Scalars['String']>
  formattedRemainingNumber?: Maybe<Scalars['String']>
  formattedResaleOpenCount?: Maybe<Scalars['String']>
  formattedResaleRaffleAppliedCount?: Maybe<Scalars['String']>
  /** Gachas */
  gachas?: Maybe<Array<Gacha>>
  /** シリアルや販売数など売上が分かる表示をしない場合はtrue */
  hideSalesNumber?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  /** アイテムの画像（最大10枚） */
  images?: Maybe<Array<ItemImage>>
  /**
   * ダウンロード可能か
   * 壁紙の場合のみ指定
   */
  isDownloadable?: Maybe<Scalars['Boolean']>
  isDrm: Scalars['Boolean']
  /** メンバーシップ入会者限定のアイテムかどうか */
  isPremium: Scalars['Boolean']
  /**
   * 出品されたかどうか
   * 検索画面やストアに表示されるようになります。
   */
  isPublished: Scalars['Boolean']
  /** 認証ユーザが獲得済みかどうか */
  isPurchased: Scalars['Boolean']
  /**
   * リセールが可能かどうか。resaleEnabledがtrueであっても、状態によりリセールできない場合がある
   * チケットアイテムでリセールの有効無効、リセール期間、アイテムがすべて完売した場合に可能
   */
  isResaleAvailable: Scalars['Boolean']
  isResaleEnable?: Maybe<Scalars['Boolean']>
  /**
   * 分配が可能かどうか。metadata.distributionEnabledがtrueの場合であったとしても、以下のケースではfalseになる
   * - 分配期限が切れている
   * - 保持数が1つ以下である
   */
  isTicketDistributionEnabled?: Maybe<Scalars['Boolean']>
  /** 発行したアーティスト */
  issuedArtist?: Maybe<Artist>
  /**
   * アイテム発行数。nullの場合は無制限
   * issuedNumber >= purchasedNumber + pendingPurchaseNumber となる
   */
  issuedNumber?: Maybe<Scalars['Int']>
  /** アイテムメタデータ */
  metadata?: Maybe<ItemMetadataDetail>
  /** ItemのNFT情報 */
  nft?: Maybe<ItemNft>
  /** OGPに利用する画像 */
  ogp?: Maybe<Ogp>
  /** ユーザー自身の対象アイテムの所持数 */
  ownedCount?: Maybe<Scalars['Int']>
  /**
   * アイテムの支払い種別
   * デフォルトはprepaid_point
   */
  paymentType?: Maybe<UserPaymentType>
  /** 購入保留中の数（コンビニ決済） */
  pendingPurchaseNumber?: Maybe<Scalars['Int']>
  /** 購入時にどの個人情報が必須か */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /**
   * Itemに関連するイベントの先行販売
   * 基本1つのみの想定
   */
  preSale?: Maybe<PreSale>
  /**
   * 利用可能なプリペイドポイント
   * デフォルトでUTNQ、organizationとして持っている場合はorganizationIdから逆引き
   */
  prepaidPoint?: Maybe<PrepaidPoint>
  /**
   * 価格
   * ０円の場合、決済処理を通らず、獲得出来ます
   */
  price: Scalars['Int']
  /** プリントアイテムのQR生成状況 */
  printQrUsage?: Maybe<PrintQrUsage>
  /** 初回公開日時 */
  publishedAt?: Maybe<Scalars['Date']>
  /** Purchasable users */
  purchasableUsers?: Maybe<Array<PurchasableUser>>
  /** アイテムのユーザー人ごとの購入制限 */
  purchaseLimitByUser?: Maybe<Scalars['Int']>
  /** 1回の注文ごとの購入制限 */
  purchaseLimitPerOrder?: Maybe<Scalars['Int']>
  /** ユーザーがポイントで購入した数 */
  purchasedByPointNumber?: Maybe<Scalars['Int']>
  /** 購入された数 */
  purchasedNumber: Scalars['Int']
  /** レアリティ */
  rarity?: Maybe<Rarity>
  /** レアリティ画像 */
  rarityIconImage?: Maybe<Image>
  /**
   * 物販アイテムの場合の配送申請受付期間
   * @deprecated use 'metadata' field instead
   */
  receptionPeriod?: Maybe<ReceptionPeriod>
  /** 関連するミッション */
  relatedMissions?: Maybe<Array<Mission>>
  remainingNumber?: Maybe<Scalars['Int']>
  /** DRM有効かどうか */
  requireDrm?: Maybe<Scalars['Boolean']>
  /**
   * 購入時に個人情報の登録を必須にするかどうか
   * @deprecated use 'personalInformationRequirements' field instead.
   */
  requirePersonalInformation?: Maybe<Scalars['Boolean']>
  /**
   * 購入に必要なプリペイドポイント数
   * prepaid-points/:id/exchangeRate とItemのpriceから算出される
   * organizationで使用するプリペイドポイント
   */
  requiredPrepaidPoint?: Maybe<Scalars['Int']>
  /**
   * チケットのリセールが可能かどうかの設定フラグ
   * ここがtrueであっても、isResaleAvailalbeがfalseの場合はリセール出品できない
   */
  resaleEnabled?: Maybe<Scalars['Boolean']>
  /** リセール出品中の総数 */
  resaleOpenCount: Scalars['Int']
  /** リセール申し込み数の総数 */
  resaleRaffleAppliedCount: Scalars['Int']
  /**
   * アイテムのリソース
   * avatarResources(typeがavatarのとき)
   * wallpaperResources(typeがwallpaperのとき)
   * resource(typeがavatar/wallpaper以外のとき)
   */
  resource?: Maybe<Resource>
  resourceType: Scalars['String']
  /**
   * アイテムのリソース（複数）
   * 主にマガジンの複数ページ画像で利用する
   */
  resources?: Maybe<Array<Maybe<Resource>>>
  /**
   * レビュー審査情報
   * レビュー審査を通過したアイテムだけが出品出来ます
   */
  review?: Maybe<ItemReview>
  /** 販売状態 */
  salesStatus: Scalars['String']
  /**
   * 販売方法
   * シリアルか一般販売か
   */
  salesType: Scalars['String']
  /** シリアル情報 */
  serial?: Maybe<Serial>
  /**
   * アイテムのシリアル一覧
   * Node: ItemSerial
   */
  serials: PageResult
  sku?: Maybe<SkuUnit>
  /** SKU設定 */
  skuSettings?: Maybe<SkuSettings>
  status?: Maybe<ItemStatus>
  /** 通し券の利用済み数カウント */
  throughTicketUsedNumbers?: Maybe<Array<ThroughTicketUsage>>
  /** カード画像 */
  thumbnail: Card
  thumbnailType?: Maybe<ItemThumbnailType>
  /**
   * チケットの利用状況
   * チケットアイテムでない場合はnull
   */
  ticketUsage?: Maybe<TicketUsage>
  title: Scalars['String']
  /**
   * トレード可能なUserItem(Serial)
   *   UserItemのisUserProtectedとisSystemProtectedがfalseのもののみ返される
   * @auth: user
   * Node: TradableUser
   */
  tradableUsers: PageResult
  /** アイテムの種類 */
  type: ItemType
  /**
   * 利用されてないチケットの数
   * @deprecated use 'ticketUsage' field instead
   */
  unusedNumber?: Maybe<Scalars['Int']>
  updatedAt: Scalars['Date']
  /**
   * チケットが利用された数
   * @deprecated use 'ticketUsage' field instead
   */
  usedNumber?: Maybe<Scalars['Int']>
  /**
   * ユーザーが出品中のリセール
   * userIdの指定がない場合はログインユーザーの出品中のリセールを返す
   */
  userOpenResaleItem?: Maybe<ResaleItem>
  /** typeがwallpaperの場合のリソース */
  wallpaperResources?: Maybe<WallpaperResources>
  willResaleAvailable?: Maybe<Scalars['Boolean']>
}

/** アイテム */
export type ItemCommentsArgs = {
  pageInput?: Maybe<PageInput>
}

/** アイテム */
export type ItemFeesArgs = {
  isKonbini?: Maybe<Scalars['Boolean']>
  purchaseNumber?: Maybe<Scalars['Int']>
}

/** アイテム */
export type ItemIsResaleAvailableArgs = {
  skuId?: Maybe<Scalars['String']>
}

/** アイテム */
export type ItemResaleOpenCountArgs = {
  skuId?: Maybe<Scalars['String']>
}

/** アイテム */
export type ItemResaleRaffleAppliedCountArgs = {
  skuId?: Maybe<Scalars['String']>
}

/** アイテム */
export type ItemSerialArgs = {
  id: Scalars['ID']
}

/** アイテム */
export type ItemSerialsArgs = {
  filter?: Maybe<SerialFilter>
  pageInput?: Maybe<PageInput>
}

/** アイテム */
export type ItemTradableUsersArgs = {
  pageInput?: Maybe<PageInput>
}

/** アイテム */
export type ItemUserOpenResaleItemArgs = {
  skuId?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

/** ミッションtypeがitem_collectの場合のコンテンツ */
export type ItemCollectContent = {
  /** 画像 */
  image?: Maybe<Image>
  /** 達成が必要なアイテム配列 */
  items: Array<ItemCollectContentItem>
}

/** item_collectミッションで集めなければならないアイテム情報 */
export type ItemCollectContentItem = {
  /** アイテム情報 */
  item: Item
  /** 対象のアイテムID */
  itemId: Scalars['String']
  /** ユーザーの現在の所持数 */
  ownedCount: Scalars['Int']
  /** ミッション達成に必要な個数 */
  requiredCount: Scalars['Int']
}

/** 複数アイテムミッションのコンテンツInput */
export type ItemCollectContentItemInput = {
  /** 対象のアイテムID */
  itemId: Scalars['String']
  /** ミッション達成に必要な個数 */
  requiredCount: Scalars['Int']
}

/** ミッションtypeがitem_collectの場合の結果 */
export type ItemCollectMissionResult = {
  correct: Scalars['Boolean']
  /** 不足しているアイテム情報配列 */
  lackItems?: Maybe<Array<ItemCollectContentItem>>
  /** ミッションデータ */
  mission: Mission
  missionId: Scalars['String']
  reward?: Maybe<QuizReward>
}

/** ItemのDRM情報 */
export type ItemDrm = {
  /** 暗号化ステータス */
  encryptingStatus: Scalars['String']
}

/** アイテム保有者情報 */
export type ItemHolder = {
  item: Item
  /** 保有アイテム一覧情報 */
  userItems: PageResult
}

/** アイテム保有者情報 */
export type ItemHolderUserItemsArgs = {
  pageInput?: Maybe<PageInputDate>
}

/** アイテム画像 */
export type ItemImage = {
  /** 画像キャプション */
  caption?: Maybe<Scalars['String']>
  /** 80%圧縮のみ行ったURI(画像) */
  compressedUri?: Maybe<Scalars['String']>
  /** 通常画面表示用の中サイズURI(画像) */
  mediumUri?: Maybe<Scalars['String']>
  /** 画像のストレージパス */
  path: Scalars['String']
  /** サムネイル等表示用の小サイズURI(画像) */
  smallUri?: Maybe<Scalars['String']>
  /** 画像URI */
  uri: Scalars['String']
}

/** アイテム画像Input */
export type ItemImageInput = {
  /** キャプション */
  caption?: Maybe<Scalars['String']>
  /** tmpパス */
  tmpPath: Scalars['String']
}

/** アイテムメタデータInput */
export type ItemMetadata = {
  /** マガジンアイテムのメタデータ */
  magazine?: Maybe<MagazineItemMetadataInput>
  /** 物販アイテム用のメタデータ */
  merch?: Maybe<MerchItemMetadata>
  /** チケットアイテムのメタデータ */
  ticket?: Maybe<TicketItemMetadataInput>
  /** 背景アイテムのメタデータ */
  wallpaper?: Maybe<WallpaperItemMetadataInput>
}

/** アイテムメタデータ */
export type ItemMetadataDetail = {
  /** マガジンアイテムメタデータ */
  magazine?: Maybe<ItemMetadataDetailMagazine>
  /** 物販アイテムメタデータ */
  merch?: Maybe<ItemMetadataDetailMerch>
  /** チケットアイテムメタデータ */
  ticket?: Maybe<ItemMetadataDetailTicket>
  /** 背景アイテムメタデータ */
  wallpaper?: Maybe<ItemWallpaperMetadata>
}

/** マガジンメタデータ */
export type ItemMetadataDetailMagazine = {
  /** ページ送り方向 */
  pageDirection?: Maybe<MagazinePageDirection>
  /** ページタイプ */
  pageType?: Maybe<MagazinePageType>
  /** ページ数（pageType: imageの場合のみ） */
  totalPages?: Maybe<Scalars['Int']>
}

/** 物販アイテムメタデータ */
export type ItemMetadataDetailMerch = {
  /** 配送申請受付期間 */
  receptionPeriod?: Maybe<ReceptionPeriod>
}

/** チケットアイテムメタデータ */
export type ItemMetadataDetailTicket = {
  /** 接頭辞 */
  assignedNumberPrefix?: Maybe<Scalars['String']>
  /** 整理番号の種別 */
  assignedNumberType?: Maybe<TicketItemMetadataAssignedNumberType>
  /** チケット内容 */
  description?: Maybe<Scalars['String']>
  /** 分配可能な設定かどうか（実際に分配できるかどうかは isTicketDistributionEnabled を使うこと） */
  distributionEnabled?: Maybe<Scalars['Boolean']>
  /** 分配可能な場合の分配終了日時 */
  distributionEndedAt?: Maybe<Scalars['Date']>
  /**
   * チケット獲得後の有効期限
   * nullの場合は無期限
   */
  expirationTerm?: Maybe<TicketItemExpirationTerm>
  /** 通し券かどうか */
  isThroughTicket?: Maybe<Scalars['Boolean']>
  isTicketDistributionEnabled?: Maybe<Scalars['Boolean']>
  /** 注意事項 */
  note?: Maybe<Scalars['String']>
  /** リセール手数料設定 */
  resaleFee?: Maybe<ItemResaleFees>
  /** リセール期間 */
  resalePeriod?: Maybe<ReceptionPeriod>
  /** 整理番号 または 座席番号の開始番号 */
  startAssignedNumber?: Maybe<Scalars['Int']>
  /** 通し券データ */
  throughTicket?: Maybe<Array<TicketItemMetadataThroughTicket>>
}

/** ItemのNFT情報 */
export type ItemNft = {
  chains?: Maybe<Array<ItemNftChain>>
}

/** Item NFTのチェーン別情報（polygon etc) */
export type ItemNftChain = {
  chainName: Scalars['String']
  royalty: Scalars['Float']
  status: ItemNftPrepareStatus
}

/** ItemNFTのMintステータス */
export enum ItemNftMintStatus {
  /** Mint完了 */
  Completed = 'completed',
  /** Mint失敗 */
  Failed = 'failed',
  /** 処理中 */
  Processing = 'processing',
}

/** Item NFTの準備ステータス */
export enum ItemNftPrepareStatus {
  /** 失敗 */
  Failed = 'failed',
  /** 初期化中 */
  Processing = 'processing',
  /** Mint準備完了 */
  ReadyForMint = 'ready_for_mint',
}

/** NFTの販売種別 */
export enum ItemNftSalesType {
  /** NFT販売 */
  NftSales = 'nft_sales',
  /** ユーザーによるNFT化 */
  UserActivation = 'user_activation',
}

/**
 * アイテム注文履歴
 *
 * ユーザー毎に保有
 * 現在は１つのアイテムにつきひとつの注文履歴が生成される
 */
export type ItemOrder = Node & {
  artist?: Maybe<Artist>
  artistId: Scalars['ID']
  /** キャンセルされたシリアルID配列 */
  canceledSerialIds?: Maybe<Array<Scalars['String']>>
  canceledSerials?: Maybe<Array<ItemSerial>>
  createdAt?: Maybe<Scalars['Date']>
  formattedCreatedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  item?: Maybe<Item>
  itemId: Scalars['ID']
  /** プリペイドポイント購入の場合 */
  prepaidPoint?: Maybe<Scalars['Int']>
  /** 現金購入の場合 */
  price?: Maybe<Scalars['Int']>
  /** 購入数量 */
  purchaseNumber?: Maybe<Scalars['Int']>
  /** 獲得したシリアルID配列 */
  serialIds?: Maybe<Array<Scalars['String']>>
  serials?: Maybe<Array<ItemSerial>>
  /** 注文の合計金額 */
  totalAmount: Scalars['Int']
  user?: Maybe<User>
  userId: Scalars['ID']
}

/** Item order input */
export type ItemOrderInput = {
  /** Item ID */
  itemId: Scalars['String']
  /** Order number */
  order: Scalars['Int']
}

/** リセールの手数料 */
export type ItemResaleFees = {
  /**
   * 1注文あたりの振込手数料（販売者側）
   * 単位：JPY/ticket
   */
  bankTransferFeePerOrder?: Maybe<Scalars['Int']>
  /**
   * チケット1枚あたりの振込手数料（販売者側）
   * 単位：JPY/ticket
   * deprecated: 1「枚」ではなく1「注文」あたりが正解のためこちらは使わないこと。bankTransferFeePerOrderを使ってください。
   * @deprecated use 'bankTransferFeePerOrder' field instead
   */
  bankTransferFeePerTicket: Scalars['Int']
  /**
   * チケット1枚あたりの手数料
   * 単位：パーセンテージ（0 ~ 1)/ticket
   */
  feeRatePerTicket: Scalars['Float']
  /**
   * リセールチケット購入手数料（購入者側）
   * 単位：JPY/purchase
   */
  purchaseFee: Scalars['Int']
}

/** リセールアイテムの抽選申し込みユーザー */
export type ItemResaleRaffleUser = Node & {
  createdAt: Scalars['Date']
  formattedCreatedAt?: Maybe<Scalars['String']>
  formattedTotalItemPrice?: Maybe<Scalars['String']>
  formattedUpdatedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /**
   * 支払でエラーがあった場合にtrueとなる
   * 決済成功した時点でfalseが入る
   */
  isPaymentError?: Maybe<Scalars['Boolean']>
  /** アイテム */
  item: Item
  /** 申し込み数 */
  itemCount: Scalars['Int']
  /** アイテムID */
  itemId: Scalars['String']
  /** 当選した場合のリセールID */
  resaleIds?: Maybe<Array<Scalars['String']>>
  /** リセールアイテム */
  resaleItems: Array<ResaleItem>
  /** SKU ID */
  skuId?: Maybe<Scalars['String']>
  /**
   * 抽選ステータス
   * | '10_win' 当選
   * | '20_applied' 申し込み済み
   * | '30_invalid' 無効（支払い期限切れ）
   * | '40_canceled' キャンセル
   * | '50_lost' 落選
   * | '60_purchased' 購入済み
   */
  status: Scalars['String']
  /** リセールアイテム合計価格（定価×itemCount） */
  totalItemPrice: Scalars['Int']
  updatedAt: Scalars['Date']
  /** 申し込みユーザー */
  user: User
  /** 申し込みユーザーID */
  userId: Scalars['String']
}

export type ItemReview = {
  status?: Maybe<Scalars['String']>
}

/** アイテムシリアル */
export type ItemSerial = Node & {
  /** シリアル認証データ */
  authentication?: Maybe<SerialAuthentication>
  createdAt: Scalars['Date']
  /**
   * 表示用の整理番号 または 座席番号
   * 接頭辞 + 0埋めの連番 で構成される
   * 接頭辞は ItemのmetadataのassignedNumberPrefix から取っている
   * 整理番号か座席番号かは、ItemのmetadataのassignedNumberTypeでフロント側で判定
   */
  formattedAssignedNumber?: Maybe<Scalars['String']>
  formattedCreatedAt?: Maybe<Scalars['String']>
  formattedObtainedDate?: Maybe<Scalars['String']>
  formattedObtainedDateTime?: Maybe<Scalars['String']>
  /**
   * 表示用のシリアルナンバー
   * #で結合された値となる
   */
  formattedSerialNumber: Scalars['String']
  /** シリアルID */
  id: Scalars['ID']
  /** NFTアイテムの場合、NFT発行済かどうか */
  isNft?: Maybe<Scalars['Boolean']>
  /** アイテムID */
  itemId: Scalars['String']
  /** シリアルのメタデータ */
  metadata?: Maybe<SerialMetadata>
  /**
   * シリアル取得日(=updatedAt)
   * トレード等やQR獲得で取得日が変わることがあるためupdatedAtを使っている
   */
  obtainedDate?: Maybe<Scalars['Date']>
  /** オーナーUser */
  owner?: Maybe<User>
  /** オーナーのUserID */
  ownerId?: Maybe<Scalars['String']>
  /**
   * シリアルナンバー（数字のみ）
   * 表示用はformattedSerialNumberを使う
   */
  serialNumber?: Maybe<Scalars['Int']>
  /** SKUデータ */
  sku?: Maybe<SkuUnit>
  /** SKUが設定されているアイテムを購入した場合のSKU ID */
  skuId?: Maybe<Scalars['String']>
  /** チケット分配リンクデータ */
  ticketDistributionLink?: Maybe<TicketDistributionLink>
  /** チケットアイテムの場合の利用状況 */
  ticketStatus?: Maybe<TicketStatus>
  updatedAt: Scalars['Date']
  /** 所有しているユーザー情報 */
  user?: Maybe<User>
}

/** Item Serial Count */
export type ItemSerialCount = {
  count: Scalars['Int']
  /** アイテム */
  item: Item
  /**
   * itemSkuIdのフォーマットは以下の通り
   * ・skuがない場合 {itemId}
   * ・skuがある場合 {itemId}:{skuId}"
   */
  itemSkuId: Scalars['String']
  /** SKUデータ */
  sku?: Maybe<SkuUnit>
}

/** Item Serial Count Input */
export type ItemSerialCountInput = {
  count: Scalars['Int']
  itemSkuId: Scalars['String']
}

export enum ItemStatus {
  Pause = 'pause',
  Private = 'private',
  Sale = 'sale',
}

/**
 * アイテムサムネイルの種別
 * _templateがついていないものは画像のみ
 * _templateのものは画像がテンプレートに埋め込まれる
 * passthroughは加工せずそのまま(apngなど)
 */
export enum ItemThumbnailType {
  Passthrough = 'passthrough',
  Rectangle = 'rectangle',
  RectangleTemplate = 'rectangle_template',
  Square = 'square',
  SquareTemplate = 'square_template',
}

/**
 * アイテムの種類
 * ticket, imageは現在使われていません。
 * ticketは実装はあります。
 */
export enum ItemType {
  Audio = 'audio',
  Avatar = 'avatar',
  Image = 'image',
  Magazine = 'magazine',
  Merch = 'merch',
  Ticket = 'ticket',
  Video = 'video',
  Wallpaper = 'wallpaper',
}

/** 背景アイテムメタデータ */
export type ItemWallpaperMetadata = {
  /** 印刷可能かどうか */
  isPrintEnabled?: Maybe<Scalars['Boolean']>
  /** 印刷可能枚数 */
  printCount?: Maybe<Scalars['Int']>
  /** 背景印刷タイプ */
  printType?: Maybe<WallpaperPrintType>
}

/** アイテム検索のフィルター */
export type ItemsFilterInput = {
  isPublished?: Maybe<Scalars['Boolean']>
  reviewStatus?: Maybe<Scalars['String']>
  salesStatus?: Maybe<Scalars['String']>
}

/** 過去30日の売上レポート */
export type Last30DaysSalesReport = {
  formattedSalesWithoutFee?: Maybe<Scalars['String']>
  /** 手数料控除後の売上 */
  sales: Scalars['Int']
  /** 手数料控除前の売上 */
  salesWithoutFee: Scalars['Int']
  /** ストアの過去30日の売上レポート詳細 */
  store: Last30DaysSalesReportDetail
  /** サブスクの過去30日の売上レポート詳細 */
  subscription: Last30DaysSalesReportDetail
  /** 投げ銭の過去30日の売上レポート詳細 */
  tip: Last30DaysSalesReportDetail
}

/** 過去30日の売上レポート詳細 */
export type Last30DaysSalesReportDetail = {
  /** 手数料控除後の売上 */
  sales: Scalars['Int']
  /** 手数料控除前の売上 */
  salesWithoutFee: Scalars['Int']
}

/** 匿名ユーザーのアカウントをリンクするInput */
export type LinkAnonymousAccountInput = {
  /** 表示名 */
  displayName?: Maybe<Scalars['String']>
  /**
   * 匿名ユーザーのときのID
   * カートのマージ処理に利用する
   */
  guestUserId: Scalars['String']
}

/** 配信イベントの情報 */
export type LiveStream = Node & {
  /** アーティスト */
  artist: Artist
  artistId: Scalars['String']
  /**
   * Agora RTCのチャンネルの識別子
   * チャンネルに参加するための認証トークンの生成に必要
   */
  channelName: Scalars['String']
  /** コメント数 */
  commentCount: Scalars['Int']
  /**
   * ライブ配信コメント
   * Node: Comment
   */
  comments: PageResult
  createdAt: Scalars['Date']
  /** ライブの終了予定時刻 */
  endedAt?: Maybe<Scalars['Date']>
  formattedCommentCount?: Maybe<Scalars['String']>
  formattedLikeCount?: Maybe<Scalars['String']>
  formattedSubscriberCount?: Maybe<Scalars['String']>
  formattedTipTotal?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isAccessible: Scalars['Boolean']
  /** 配信がプレミアム会員限定かどうか */
  isPremium: Scalars['Boolean']
  /** いいね数 */
  likeCount: Scalars['Int']
  liveStreamDuration?: Maybe<Scalars['String']>
  organizationId?: Maybe<Scalars['String']>
  /** ライブ配信録画データ */
  recording?: Maybe<LiveStreamRecording>
  /**
   * ライブの開始予定時刻
   * nullの場合は即時配信
   */
  startedAt?: Maybe<Scalars['Date']>
  /** 配信ステータス */
  status: LiveStreamStatus
  /** ユニークなトータル視聴者数 */
  subscriberCount: Scalars['Int']
  /** 投げ銭トータル（ポイント数） */
  tipTotal: Scalars['Int']
  /** Agoraチャンネルの認証トークン */
  token?: Maybe<Scalars['String']>
  updatedAt: Scalars['Date']
}

/** 配信イベントの情報 */
export type LiveStreamCommentsArgs = {
  pageInput?: Maybe<PageInput>
}

/** ライブ配信録画データ */
export type LiveStreamRecording = {
  deletedAt?: Maybe<Scalars['Date']>
  status: Scalars['String']
  uri?: Maybe<Scalars['String']>
}

/**
 * 配信チャンネルのユーザーロール
 * ホスト(配信者)かオーディエンス(視聴者)のどちらか
 */
export enum LiveStreamRole {
  Audience = 'audience',
  Host = 'host',
}

export type LiveStreamSettings = {
  startLiveStream?: Maybe<NotificationChannels>
}

/** 配信イベントのステータス */
export enum LiveStreamStatus {
  /** 配信予定キャンセル済み */
  Canceled = 'canceled',
  /** 配信終了済み */
  Ended = 'ended',
  /** 配信中 */
  Live = 'live',
  /** 配信予定 */
  Ready = 'ready',
}

/** ログインボーナス */
export type LoginBonus = {
  bonuses?: Maybe<Array<Bonus>>
  createdAt: Scalars['Date']
  endDate: Scalars['Date']
  id: Scalars['ID']
  loginBonusUser?: Maybe<LoginBonusUser>
  startDate: Scalars['Date']
  todayBonus?: Maybe<Bonus>
  todayTargetDate?: Maybe<Scalars['Int']>
  updatedAt: Scalars['Date']
}

/** ログインボーナスメタデータインプット */
export type LoginBonusMetaataInput = {
  count?: Maybe<Scalars['Int']>
  gachaTicketId?: Maybe<Scalars['String']>
  point?: Maybe<Scalars['Int']>
}

/** ユーザーのボーナス獲得状態 */
export type LoginBonusUser = {
  createdAt: Scalars['Date']
  id: Scalars['ID']
  obtainedBonuses?: Maybe<Array<Bonus>>
  updatedAt: Scalars['Date']
  user: User
  userId?: Maybe<Scalars['String']>
}

/** ログインボーナスデータインプット */
export type LoginBonusesInput = {
  bonusKey: Scalars['String']
  metadata?: Maybe<LoginBonusMetaataInput>
  type: Scalars['String']
}

/** 抽選結果インプット */
export type LotteryResultInput = {
  /** 応募ユーザーID */
  userId: Scalars['String']
  /** 当選希望（第N希望のN） */
  winChoiceNumber?: Maybe<Scalars['Int']>
}

/** マガジンアイテムメタデータInput */
export type MagazineItemMetadataInput = {
  /**
   * ページ方向
   * 指定しない場合はデフォルトで左開き(left)
   */
  pageDirection?: Maybe<MagazinePageDirection>
  /** ページの種別 */
  pageType?: Maybe<MagazinePageType>
}

/** マガジンページ方向 */
export enum MagazinePageDirection {
  /** 左開き（右から左にスワイプ・デフォルトはこちら） */
  Left = 'left',
  /** 右開き（左から右にスワイプ） */
  Right = 'right',
  /** 縦方向 */
  Vertical = 'vertical',
}

/** マガジンのページ種別 */
export enum MagazinePageType {
  /** 画像 */
  Image = 'image',
  /** PDF */
  Pdf = 'pdf',
}

/** サブスクの更新期間 */
export enum MembershipInterval {
  /** 月次 */
  Month = 'month',
  /** 年次 */
  Year = 'year',
}

export enum MembershipPlanRelationship {
  ForJoinedLower = 'forJoinedLower',
  ForJoinedUpper = 'forJoinedUpper',
  ForLeave = 'forLeave',
  Joined = 'joined',
  KonbiniForJoinedLower = 'konbini_forJoinedLower',
  KonbiniForJoinedUpper = 'konbini_forJoinedUpper',
  KonbiniJoined = 'konbini_joined',
  KonbiniLower = 'konbini_lower',
  KonbiniNotDowngradingLower = 'konbini_notDowngradingLower',
  KonbiniNotDowngradingUpper = 'konbini_notDowngradingUpper',
  KonbiniUnjoined = 'konbini_unjoined',
  KonbiniUpper = 'konbini_upper',
  KonbiniWaitingPayment = 'konbini_waitingPayment',
  Lower = 'lower',
  NotDowngradingLower = 'notDowngradingLower',
  NotDowngradingUpper = 'notDowngradingUpper',
  Stopping = 'stopping',
  StoppingLower = 'stoppingLower',
  StoppingUpper = 'stoppingUpper',
  Unjoined = 'unjoined',
  Upper = 'upper',
}

/**
 * メンバーシップ基本情報
 * todo: SubscriptionはGraphQLで利用しているので、typeを変更する
 */
export type MembershipSubscription = {
  /**
   * 月額料金(税込み)
   * @deprecated use 'products' field instead
   */
  amount: Scalars['Int']
  /**
   * appstoreのproductIdのProductId
   * @deprecated use 'products' field instead
   */
  appstore?: Maybe<IapProductInfo>
  /** birthday message */
  birthdayMessage?: Maybe<Scalars['String']>
  /**
   * メンバーシップの内容
   * @deprecated use 'products' field instead
   */
  content: Scalars['String']
  isActive?: Maybe<Scalars['Boolean']>
  /**
   * サブスクリプションプラン（複数プラン対応）
   * @deprecated use 'products' field instead
   */
  plans?: Maybe<Array<MembershipSubscriptionPlan>>
  /**
   * google playstoreのProductId
   * @deprecated use 'products' field instead
   */
  playstore?: Maybe<IapProductInfo>
  /**
   * StripeのProductId
   * @deprecated use 'products' field instead
   */
  productId?: Maybe<Scalars['String']>
  /** サブスクリプションプラン（複数プロダクト ✕ 複数プラン対応） */
  products?: Maybe<Array<MembershipSubscriptionProduct>>
  /** 個人情報の収集を必須とするかどうか */
  requirePersonalInformation?: Maybe<Scalars['Boolean']>
  /**
   * メンバーシップが受付中かどうか。
   * @deprecated use 'products' field instead
   */
  status?: Maybe<Scalars['String']>
}

/** メンバーシッププラン */
export type MembershipSubscriptionPlan = {
  /** メンバーシップ金額 */
  amount: Scalars['Int']
  /** appstoreのproductIdのProductId */
  appstore?: Maybe<IapProductInfo>
  /**
   * メンバーシップコンテンツ内容
   * @deprecated use product.content instead
   */
  content: Scalars['String']
  formattedAmount?: Maybe<Scalars['String']>
  /** メンバーシップ更新間隔 */
  interval: MembershipInterval
  /** メンバーシッププランの継続月 */
  month?: Maybe<Scalars['Int']>
  /**
   * プランに加入中のメンバー数
   * @auth: artist
   */
  patronCount?: Maybe<Scalars['Int']>
  /**
   * プランに加入中のメンバー一覧
   * Node: Patron
   * @auth: artist
   */
  patrons?: Maybe<PageResult>
  /** プランID */
  planId: Scalars['String']
  /**
   * ユーザーが現在加入しているプランとの関係
   * 未登録・加入中・上位・下位
   * ダウングレード先・ダウングレード元・ダウングレード中の無関係のプラン
   * 更新停止中・他のプランが更新停止中
   * に分けられる
   */
  planRelationship?: Maybe<MembershipPlanRelationship>
  /** google playstoreのProductId */
  playstore?: Maybe<IapProductInfo>
  /** メンバーシッププランステータス */
  status: MembershipSubscriptionPlanStatus
  /**
   * メンバーシップタイトル
   * @deprecated use product.title instead
   */
  title: Scalars['String']
}

/** メンバーシッププラン */
export type MembershipSubscriptionPlanPatronsArgs = {
  pageInput?: Maybe<PageInput>
}

/** メンバーシッププランステータス */
export enum MembershipSubscriptionPlanStatus {
  /** 公開中 */
  Active = 'active',
  /** 非公開中（新規入会停止、既存会員継続） */
  Closed = 'closed',
  Inactive = 'inactive',
  /** 停止中（既存会員翌月停止） */
  Stopped = 'stopped',
}

/** メンバーシッププロダクト */
export type MembershipSubscriptionProduct = {
  /** 年額プラン限定コンテンツ内容 */
  annualContent?: Maybe<Scalars['String']>
  /** 年額プランのお得パーセンテージテキスト（表示用） */
  annualDiscountText?: Maybe<Scalars['String']>
  /** メンバーシップコンテンツ内容 */
  content: Scalars['String']
  /** プロダクトに紐づくメンバーシッププラン */
  plans: Array<MembershipSubscriptionPlan>
  /** プロダクトID */
  productId?: Maybe<Scalars['String']>
  /** メンバーシップタイトル */
  title: Scalars['String']
}

/** 物販アイテム用のメタデータInput */
export type MerchItemMetadata = {
  /** 配送申請受付期間 */
  receptionPeriod?: Maybe<ReceptionPeriodInput>
}

/** Item NFT発行インプット */
export type MintItemNftInput = {
  itemId: Scalars['String']
  serialId: Scalars['String']
}

/** ミッション情報 */
export type Mission = Node & {
  /** ミッション達成総回数 */
  accomplishedCount: Scalars['Int']
  /** アーティスト情報 */
  artist: Artist
  artistId: Scalars['String']
  /** コメント数 */
  commentCount: Scalars['Int']
  /**
   * アイテムコメント
   * Node: Comment
   */
  comments: PageResult
  /**
   * ミッション達成に必要なコンテンツ
   * typeにより中身が変動する
   */
  content: MissionContent
  createdAt: Scalars['Date']
  description: Scalars['String']
  /** ミッションの有効期限 */
  expiredAt: Scalars['Date']
  formattedCreatedAt?: Maybe<Scalars['String']>
  formattedExpiredAt?: Maybe<Scalars['String']>
  /** GPS情報 */
  gpsData?: Maybe<GpsData>
  id: Scalars['ID']
  /** ミッション攻略済みかどうか */
  isAccomplished: Scalars['Boolean']
  /** 公開されているミッションかどうか */
  isPublished: Scalars['Boolean']
  label?: Maybe<MissionLabel>
  /** 回答が不要なミッションかどうか */
  noAnswer: Scalars['Boolean']
  /** 所属organizationId(所属していない場合はnull) */
  organizationId?: Maybe<Scalars['String']>
  /** ミッション報酬 */
  reward: MissionReward
  /** ミッションの開始日時 */
  startedAt?: Maybe<Scalars['Date']>
  title: Scalars['String']
  /**
   * ミッション種別
   * 種別によりcontentが変わる
   */
  type: MissionType
  updatedAt: Scalars['Date']
}

/** ミッション情報 */
export type MissionCommentsArgs = {
  pageInput?: Maybe<PageInput>
}

/** ミッションタイプによるコンテンツ */
export type MissionContent =
  | ItemCollectContent
  | QuizContent
  | TwitterFollowContent
  | TwitterRetweetContent

export enum MissionLabel {
  Clear = 'clear',
  Expired = 'expired',
  New = 'new',
}

/**
 * ミッション達成時の報酬
 * itemかgachaTicketかどちらかが入る
 */
export type MissionReward = {
  gachaTicket?: Maybe<QuizGachaTicket>
  item?: Maybe<QuizRewardItem>
}

/** ミッション報酬Input */
export type MissionRewardInput = {
  /** ガチャチケット */
  gachaTicket?: Maybe<QuizGachaTicketInput>
  /** 報酬アイテム */
  item?: Maybe<QuizRewardItemInput>
}

export type MissionSettings = {
  newMission?: Maybe<NotificationChannels>
  replyCommentMission?: Maybe<NotificationChannels>
}

/** 自動ミッションの達成ステップ */
export type MissionStep = {
  id: Scalars['ID']
  /** 達成条件(個数) */
  requiredCount: Scalars['Int']
  /** 達成報酬 */
  reward: StepMissionReward
}

/** 自動ミッション達成ステップInput */
export type MissionStepInput = {
  requiredCount: Scalars['Int']
  reward: StepMissionRewardInput
}

/** ミッション種別 */
export enum MissionType {
  /** 複数アイテム */
  ItemCollect = 'item_collect',
  /** クイズ（キーワード） */
  Quiz = 'quiz',
  /** Twitter（フォロー） */
  TwitterFollow = 'twitter_follow',
  /** Twitter（リツイート） */
  TwitterRetweet = 'twitter_retweet',
}

/**
 * どの機能を有効にするか
 * 主にstandaloneアプリで利用します。
 */
export type ModuleActivations = {
  /** コミュニティ機能 */
  community?: Maybe<Scalars['Boolean']>
  /** メンバシップ（有料会員）機能 */
  membership?: Maybe<Scalars['Boolean']>
  /** ストア機能 */
  store?: Maybe<Scalars['Boolean']>
}

/** Multiple Item Serial */
export type MultiItemSerial = {
  /** 獲得日時、初期値null */
  authenticatedAt?: Maybe<Scalars['Date']>
  /** ステータス */
  canBeObtain: Scalars['Boolean']
  /** リンク作成日 */
  createdAt: Scalars['Date']
  /** リンクの期限 */
  expiredAt: Scalars['Date']
  id: Scalars['ID']
  isExpired?: Maybe<Scalars['Boolean']>
  /** 設定されたItemId配列（ドキュメント検索用） */
  itemIds?: Maybe<Array<Scalars['String']>>
  /** アイテムと獲得数のマッピングの配列データ */
  items: Array<ItemSerialCount>
  /** シリアルを獲得できるページヘのURL */
  link: Scalars['String']
  /** 獲得したユーザーID、初期値null */
  userId?: Maybe<Scalars['String']>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type Mutation = {
  /**
   * アイテムをレビュー許可をする
   * @auth: admin
   */
  acceptItem: Item
  /**
   * トレード申請を承認する
   * @auth: user
   */
  acceptTradeRequest: TradeNegotiation
  /**
   * コレクションにアイテムを追加する
   * コレクションのオーナーでない場合はForbiddenErrorがthrowされる
   * @auth: artist
   * @params:
   *   collectionId: Collection DocumentID
   *   itemId: Item DocumentID
   */
  addItemToCollection: CollectionItem
  /**
   * 通知用登録トークンを保存する。
   * @auth: user
   */
  addRegistrationToken?: Maybe<User>
  /**
   * Organizationの通知用登録トークンを保存する
   * x-utoniq-application-keyがない場合はエラーとなる
   * @auth: user
   */
  addRegistrationTokenForOrganization?: Maybe<UserOrganization>
  /**
   * スタンドアローンの通知用登録トークンを保存する。
   * @auth: user
   */
  addRegistrationTokenForStandalone?: Maybe<UserStandalone>
  /**
   * シェアポイントを加算する
   * @auth: user
   */
  addSharePoint: Scalars['Boolean']
  /**
   * カートにアイテムを追加する
   * @auth: guest-user
   */
  addToCart: UserCart
  /**
   * admin権限でアーティストのバナーを追加する
   * @auth admin
   */
  adminAddArtistBanner: Artist
  /**
   * コレクションのラベルを追加する
   * @auth: admin
   * @params:
   *   collectionId: Collection DocumentID
   *   inputs: CollectionLabelInput
   */
  adminAddCollectionLabel: Collection
  /**
   * イベントにコレクションを追加する
   * @auth: admin
   */
  adminAddCollectionsToEvent: Event
  /**
   * Eventのバナーを追加する
   * @auth: admin
   */
  adminAddEventBanner: Event
  /**
   * コレクションにアイテムを追加する
   * @auth: admin
   * @params:
   *   inputs: Collection ID & Item ID to add
   */
  adminAddItemToCollection: CollectionItem
  /**
   * コレクションにアイテムを一括で追加する
   * @auth: admin
   */
  adminAddItemToCollectionBulk: Collection
  /**
   * CSVでアイテムをコレクションに一括設定する
   * @auth: admin
   */
  adminAddItemToCollectionsBulk: Scalars['Boolean']
  /**
   * Organizationのバナーを追加する
   * @auth admin
   */
  adminAddOrganizationBanner: Organization
  /**
   * Archive comment
   * @auth: admin
   * id: アイテムID
   * commentID: アイテムのコメントID
   * commentType: | 'trade'
   *              | 'item'
   *              | 'mission'
   *              | 'artist_thread'
   */
  adminArchiveComment: Comment
  /**
   * ポストへの返信を削除する。
   * @auth: admin
   */
  adminArchivePostReply: PostReply
  /**
   * キャンペーンに一括でアーティストエントリーする
   * @auth: admin
   */
  adminBulkEntryArtists: Campaign
  /**
   * Admin権限でユーザーの抽選応募をキャンセルする
   * @auth: admin
   */
  adminCancelPreSaleLottery: PreSaleLotteryApplicant
  /**
   * Admin権限で、カート決済の手数料を追加徴収する（Stripe）
   * @auth: admin
   */
  adminChargeCheckoutFees: UserCartTransactionOrder
  /**
   * Admin権限でプリペイドポイントをチャージする
   * @auth: admin
   */
  adminChargePrepaidPoint: PrepaidPointTransaction
  /**
   * Admin権限で指定したリセールを振込完了にする
   * @auth: admin
   */
  adminCompleteResaleItemPayment: Array<ResaleItem>
  /**
   * レビューをacceptされたアバターアイテムを作成する（URI指定）
   * @auth: admin
   */
  adminCreateAcceptedAvatarItemFromUri: Item
  /**
   * アイテムを一括で作成する
   * 作成後はitemId付きのcsvがstorageにアップロードされるので、そのURLが返される
   * @auth: admin
   */
  adminCreateAcceptedItemBulk: Scalars['String']
  /**
   * レビューをacceptされたアイテムを作成する（URI指定）
   * @auth: admin
   */
  adminCreateAcceptedItemFromUri: Item
  /**
   * レビューをacceptされた壁紙アイテムを作成する（URI指定）
   * @auth: admin
   */
  adminCreateAcceptedWallpaperItemFromUri: Item
  /**
   * アバターアイテムを作成する(admin)
   * @auth: admin
   */
  adminCreateAvatarItem: Item
  /**
   * キャンペーンを作成する
   * @auth: admin
   */
  adminCreateCampaign: Campaign
  /**
   * コレクションを作成する
   * @auth: admin
   */
  adminCreateCollection: Collection
  /**
   * イベントを作成する
   * @auth: admin
   */
  adminCreateEvent: Event
  /**
   * イベントの座席情報を作成する
   * @auth: admin
   */
  adminCreateEventSeat: EventSeat
  /**
   * ガチャを作成する
   * @auth: admin
   */
  adminCreateGacha: Gacha
  /**
   * アイテムを作成する(admin)
   * @auth: admin
   */
  adminCreateItem: Item
  /**
   * Admin権限でアイテムの購入可能ユーザー制限を一括で作成する
   * @auth admin
   */
  adminCreateItemPurchasableUsersBulk: Item
  /**
   * ログインボーナスを作成する
   * @auth: admin
   */
  adminCreateLoginBonus: LoginBonus
  /**
   * 物販アイテムを作成する(admin)
   * @auth: admin
   */
  adminCreateMerchItem: Item
  /**
   * ミッションを作成する(管理者用)
   * @auth: admin
   */
  adminCreateMission: Mission
  /**
   * admin権限で公式アーティストスレッドを作成する
   * @auth: admin
   */
  adminCreateOfficialArtistThreadChannel: ArtistThreadChannel
  /**
   * Organizationを作成する
   * @auth: admin
   */
  adminCreateOrganization: Organization
  /**
   * Organization通知を作成する
   * @auth: admin
   */
  adminCreateOrganizationNotification: OrganizationNotification
  /**
   * admin create preSale
   * @auth: admin
   */
  adminCreatePreSale: PreSale
  /** Admin権限でプリペイドポイントを登録する */
  adminCreatePrepaidPoint: PrepaidPoint
  /**
   * アーティストの画像を一時保存する
   * @auth: admin
   */
  adminCreateResource: ArtistResource
  /**
   * 座席のテンプレートを作成する
   * @auth: admin
   */
  adminCreateSeatTemplate: SeatTemplate
  /**
   * 個人達成型の自動ミッションを作成する(アドミン用)
   * @auth: admin
   */
  adminCreateStepMission: StepMission
  /**
   * チケットアイテムを作成する(admin)
   * @auth: admin
   */
  adminCreateTicketItem: Item
  /**
   * 背景アイテムを作成する(admin)
   * @auth: admin
   */
  adminCreateWallpaperItem: Item
  /**
   * 個人達成型の自動ミッションを削除する(アドミン用)
   * @auth: admin
   */
  adminDeleteStepMission: StepMission
  /**
   * Admin権限でユーザを退会させる。
   * @auth: admin
   */
  adminDeleteUser: User
  /**
   * Admin権限で背景アイテムのコンビニプリント用QRコードを生成する
   * @auth: admin
   */
  adminGenerateWallpaperPrintQrcode: SerialMetadataWallpaperPrint
  /**
   * ガチャチケットをユーザーに付与する
   * @auth: admin
   */
  adminGiveGachaTicket: GachaTicketOrder
  /**
   * 外部から連携されるクライアントを発行する
   * @auth: admin
   */
  adminIssuePartnerClient: PartnerClient
  /**
   * 指定した物販アイテムでステータスが"arranging"のものを発送完了ステータスに変更する
   * @auth: admin
   */
  adminMerchItemShipComplete: Array<Item>
  /**
   * Admin権限でストアのカート決済をキャンセルする（アイテム単位）
   * @auth: admin
   */
  adminRefundStoreItem: UserCart
  /**
   * アーティストを作成する
   * @auth: admin
   */
  adminRegisterArtist: Account
  /**
   * admin権限でサブスクプランを作成する
   * @auth: admin
   */
  adminRegisterSubscriptions: Artist
  /**
   * admin権限でアーティストのバナーを削除する
   * @auth admin
   */
  adminRemoveArtistBanner: Artist
  /**
   * イベントからコレクションを削除する
   * @auth: admin
   */
  adminRemoveCollectionFromEvent: Event
  /**
   * コレクションのラベルを削除する
   * @auth: admin
   * @params:
   *   collectionId: Collection DocumentID
   *   labelId: LabelId
   */
  adminRemoveCollectionLabel: Collection
  /**
   * Eventのバナーを削除する
   * @auth: admin
   */
  adminRemoveEventBanner: Event
  /**
   * コレクションからアイテムを削除する
   * @auth: admin
   * @params:
   *   inputs: Collection ID & Item ID to add
   */
  adminRemoveItemFromCollection: CollectionItem
  /**
   * Organizationのバナーを削除する
   * @auth admin
   */
  adminRemoveOrganizationBanner: Organization
  /**
   * CSVで一括PUSH通知
   * @auth: admin
   */
  adminSendPushNotificationBulk: Scalars['Boolean']
  /**
   * 有限ガチャの未使用アイテムのみシャッフルしてセットを更新する
   * @auth: admin
   * @params:
   *   id: ガチャID
   */
  adminShuffleLimitedGachaItemSet?: Maybe<Gacha>
  /**
   * admin権限で、先行販売の抽選当落通知を開始する
   * @auth: admin
   */
  adminStartPreSaleLotteryNotification: PreSale
  /**
   * admin権限で、先行販売の抽選当落処理を開始する（通知はされない）
   * @auth: admin
   */
  adminStartPreSaleLotteryProcess: PreSale
  /**
   * 指定したアイテムの所有付け替えをする
   * type: trade => ユーザー同士のトレード
   * type: transfer => どちらかのユーザーに付け替えをする
   * AdminTradeItemSerialsInput
   *   tradeA / tradeB両方指定の場合はトレード(type: trade)
   *   tradeA / tradeBどちらか指定の場合は指定しているほうからnullのほうに送付する(type: transfer)
   * @auth: admin
   */
  adminTradeItemSerialsUser: Scalars['Boolean']
  /**
   * アーティスト権限でアーティスト情報を更新する。
   * @auth: admin
   */
  adminUpdateArtist: Artist
  /**
   * Admin権限でイベント座席割当てをCSVから一括更新する
   * イベントシートテンプレートの更新も同時に行われる
   * @auth: admin
   */
  adminUpdateAssignSeatFromCsv: Array<Item>
  adminUpdateCampaign: Campaign
  /**
   * キャンペーンのエントリー条件を更新する
   * @auth: admin
   */
  adminUpdateCampaignEntryCondition: Campaign
  /**
   * コレクションを更新する
   * @auth: admin
   */
  adminUpdateCollection: Collection
  /**
   * admin update collection fee settings
   * @auth: admin
   * @params:
   *   inputs: Collection ID & CollectionFeeSettingsInput
   */
  adminUpdateCollectionFeeSettings: Collection
  /**
   * admin update collection item order
   * @auth: admin
   * @params:
   *   inputs: Collection ID & Item IDs to remove
   */
  adminUpdateCollectionItemOrder: Collection
  /**
   * コレクションのラベルを更新する
   * @auth: admin
   * @params:
   *   collectionId: Collection DocumentID
   *   inputs: CollectionLabelInput
   */
  adminUpdateCollectionLabel: Collection
  /**
   * イベントを更新する
   * @auth: admin
   */
  adminUpdateEvent: Event
  /**
   * イベントシートセクションを更新する
   * @auth: admin
   */
  adminUpdateEventSeatSection: EventSeatSection
  /**
   * ガチャを更新する
   * @auth: admin
   */
  adminUpdateGacha: Gacha
  /**
   * ガチャデータを更新する
   * @auth: admin
   */
  adminUpdateGachaData: Gacha
  /**
   * ガチャのアイテムを更新する
   * @auth: admin
   */
  adminUpdateGachaItems?: Maybe<Gacha>
  /**
   * CSVでアイテムをガチャに一括設定する
   * @auth: admin
   */
  adminUpdateGachaItemsBulk: Scalars['Boolean']
  /**
   * 有限ガチャのアイテムセットを更新する。ない場合は新規作成する。
   * @auth: admin
   */
  adminUpdateGachaLimitedItemSets?: Maybe<Gacha>
  /**
   * ガチャのレアリティを更新する
   * @auth: admin
   */
  adminUpdateGachaRarities: Gacha
  /**
   * アイテム情報を更新する
   * @auth: admin
   */
  adminUpdateItem: Item
  /**
   * Update an organization
   * @auth: admin
   */
  adminUpdateOrganization: Organization
  /**
   * admin update preSale
   * @auth: admin
   */
  adminUpdatePreSale: PreSale
  /**
   * 座席のテンプレートを更新する
   * @auth: admin
   */
  adminUpdateSeatTemplate: SeatTemplate
  /**
   * Admin update top display artist
   * @auth: admin
   */
  adminUpdateTopDisplayArtists: Organization
  /**
   * Twitterフォローミッション答え合わせ
   * @auth: user
   */
  answerFollowMission: QuizResult
  /**
   * 複数アイテムミッション答え合わせ
   * 不足がある場合はlackItemsに不足分のカウントとともに返却される
   * @auth: user
   */
  answerItemCollectMission: ItemCollectMissionResult
  /**
   * 謎解きミッション答え合わせ
   * @auth: user
   */
  answerQuizMission: QuizResult
  /**
   * Twitterリツイートミッション答え合わせ
   * @auth: user
   */
  answerRetweetMission: QuizResult
  /**
   * 物販アイテムの配送申請
   * @auth: user
   */
  applyMerchItemDelivery: Array<DeliveryItem>
  /**
   * 先行販売の抽選に応募する
   * ワンタイムトークンが必要な場合（外部アプリからの参照）clientId, tokenがない場合はforbiddenとなる
   * @auth: user
   */
  applyPreSaleLottery: PreSaleLotteryApplicant
  /**
   * リセール抽選申し込み
   * @auth: user
   */
  applyResaleItemRaffle: ItemResaleRaffleUser
  /**
   * アーティストスレッドを論理削除する
   * 作成したユーザー本人のみ許可されている
   * @auth: user
   */
  archiveArtistThreadChannel: ArtistThreadChannel
  /**
   * アイテムをアーカイブする
   * @auth: none
   */
  archiveItem: Item
  /**
   * アーティスト用 イベントにコレクションを追加する
   * @auth: admin
   */
  artistAddCollectionsToEvent: Event
  /**
   * アーティストの通知用登録トークンを保存する。
   * @auth: artist
   */
  artistAddRegistrationToken: Artist
  /**
   * Artist用Organization通知をアーカイブする
   * @auth: artist
   */
  artistArchiveOrganizationNotification: OrganizationNotification
  /**
   * (アーティスト用)署名を検証し、暗号資産ウォレットの連携を承認する
   * @auth: artist
   */
  artistAuthorizeWallet: CryptoWallet
  /**
   * アーティストのコメントを作成する
   * @auth: artist
   */
  artistCreateComment: Comment
  /**
   * アーティスト用 イベントを作成する
   * @auth: artist
   */
  artistCreateEvent: Event
  /**
   * Artist用Organization通知を作成する
   * @auth: artist
   */
  artistCreateOrganizationNotification: OrganizationNotification
  /**
   * ポストカテゴリーを作成する
   * @auth: artist
   */
  artistCreatePostCategory: PostCategory
  /**
   * アーティストの先行販売を作成する
   * @auth: artist
   */
  artistCreatePreSale: PreSale
  /**
   * アーティスト用 イベントを削除する。アーティストのイベントでない場合、イベントコレクション・または先行販売が設定されている場合は削除できない
   * @auth artist
   */
  artistDeleteEvent: Event
  /**
   * (アーティスト用) 暗号資産ウォレット連携用の署名作成用メッセージを返す
   * @auth: artist
   */
  artistGenerateWalletSignatureMessage: CryptoWallet
  /**
   * 指定した物販アイテムでステータスが"arranging"のものを発送完了ステータスに変更する
   * @auth: artist
   */
  artistMerchItemShipComplete: Array<DeliveryItem>
  /**
   * アーティスト権限でサブスクリプションプランを作成
   * @auth: artist
   * @deprecated use artistRegisterSubscriptions
   */
  artistRegisterSubscription: Artist
  /**
   * artist権限でサブスクプランを作成する
   * @auth: artist
   */
  artistRegisterSubscriptions: Artist
  /**
   * イベントからコレクションを削除する
   * @auth: admin
   */
  artistRemoveCollectionFromEvent: Event
  /**
   * Remove item
   * @auth: artist
   */
  artistRemoveItem: Item
  /**
   * ポストカテゴリーを編集する
   * @auth: artist
   */
  artistRemovePostCategory: PostCategory
  /**
   * アーティストがポストへ返信する
   * @auth: artist
   */
  artistReplyPost: PostReply
  /**
   * アーティスト権限でイベント受け取りのチケットをまとめて未使用に戻す
   * @auth: artist
   */
  artistUnuseEventPickupTickets: Array<ItemSerial>
  /**
   * アーティスト権限でチケットアイテム（複数）を使用済みにする
   * イベント受け取りの場合は artistUseEventPickupTickets を利用すること
   * @auth: artist
   */
  artistUnuseTickets: Array<ItemSerial>
  /**
   * Update artist banners with artist privileges
   * @auth artist
   */
  artistUpdateArtistBanners: Artist
  /**
   * アーティスト権限でアーティスト情報を更新する。
   * @auth: artist
   */
  artistUpdateArtistProfile: Artist
  /**
   * アーティストが座席割当てをCSVから一括更新する
   * イベントシートテンプレートの更新はこの処理では行わない
   * @auth: artist
   */
  artistUpdateAssignSeatFromCsv: Array<Item>
  /**
   * アーティスト用 イベントを更新する
   * @auth: admin
   */
  artistUpdateEvent: Event
  /**
   * アイテム情報を更新する
   * @auth: artist
   */
  artistUpdateItem: Item
  /**
   * Artist用Organization通知を更新する
   * @auth: artist
   */
  artistUpdateOrganizationNotification: OrganizationNotification
  /**
   * ポストカテゴリーを更新する
   * @auth: artist
   */
  artistUpdatePostCategory?: Maybe<PostCategory>
  /**
   * Update an artist presale
   * @auth: artist
   */
  artistUpdatePreSale: PreSale
  /**
   * アーティストが先行販売の抽選応募者のステータスを更新する
   * この更新ではユーザー通知は行われない
   * @auth: artist
   */
  artistUpdatePreSaleLotteryApplicantStatus: Array<PreSaleLotteryApplicant>
  /**
   * アーティストの状態を更新する
   * isHiddenCounter / isPrivate
   * @auth: artist
   */
  artistUpdateStatus: Artist
  /**
   * アーティスト権限でイベント受け取りのチケットをまとめて使用済みにする
   * @auth: artist
   */
  artistUseEventPickupTickets: Array<ItemSerial>
  /**
   * アーティスト権限でチケットアイテム（複数）を使用済みにする
   * イベント受け取りの場合は artistUseEventPickupTickets を利用すること
   * @auth: user
   */
  artistUseTickets: Array<ItemSerial>
  /**
   * 署名を検証し、暗号資産ウォレットの連携を承認する
   * @auth: user
   */
  authorizeWallet: CryptoWallet
  /**
   * ユーザー・またはアーティストをブロックする
   * user.blockedUsers / user.blockedArtis でID一覧を取得
   * artistの場合はログインしていればartist.isblocked でも確認可能
   * @auth: user
   */
  blockUser?: Maybe<User>
  /**
   * ライブ配信のアーカイブをキャンセルする
   * @auth: artist
   */
  cancelArchiveLiveStream: LiveStream
  /**
   * サブスリプションのダウングレードをキャンセルする
   * @auth: user
   */
  cancelDowngradeSubscriptionPlan: Patron
  /**
   * 先行販売の抽選応募をキャンセルする
   * @auth: user
   */
  cancelPreSaleLottery: PreSaleLotteryApplicant
  /**
   * リセールをキャンセルする
   * @auth: user
   */
  cancelResaleItem: ResaleItem
  /**
   * リセール抽選のキャンセル
   * @auth: user
   */
  cancelResaleItemRaffle: ItemResaleRaffleUser
  /** 分配をキャンセルする */
  cancelTicketDistribution: TicketDistributionLink
  /**
   * トレード申請をキャンセルする
   * @auth: user
   */
  cancelTradeRequest: TradeNegotiation
  /**
   * プリペイドポイントを購入してチャージする
   * Stripe決済用のため、Webでの利用を想定
   * @auth: user
   */
  chargePrepaidPoint: PrepaidPointTransaction
  /**
   * プリペイドポイントをチャージする
   * RevenueCatによる決済後に呼ぶAPI
   * uuidを指定した場合はロックの解放を同時に行う。
   * 指定したuuidが取得中のロックのuuidと異なっている，もしくは，ロックの有効期限が切れている場合はForbiddenErrorを返す。
   * @auth: user
   * @deprecated no transaction guaranteed.
   */
  chargePrepaidPointByIap: PrepaidPointTransaction
  /**
   * カート内のアイテムをチェックアウトし購入完了する
   * コンビニ決済の場合は仮購入状態となる
   * @auth: user
   */
  checkoutCart: UserCartTransactionOrder
  /**
   * 手動入金を完了する。payoutドキュメントのstatusをpaidにし、アーティストの残高を減らす。
   * - id: 入金完了にするpayoutドキュメントのID
   *
   * @auth: admin
   */
  closePayout: Payout
  /**
   * レビューをacceptされたアイテムを作成する
   * @auth: artist
   */
  createAcceptedItem: Item
  /**
   * アーティストスレッドを作成する
   * @auth: user
   */
  createArtistThreadChannel: ArtistThreadChannel
  /**
   * アーティストスレッドのコメントを作成する
   * @auth: user
   */
  createArtistThreadComment: Comment
  /**
   * コレクションを作成する
   * @auth: artist
   */
  createCollection: Collection
  /**
   * コメントを作成する
   * コメントにリプライをするときはreplyToIdに返信するcommentIdを設定する
   * @auth: user
   */
  createComment: Comment
  /**
   * RevenueCatの情報からPatronドキュメントを作成する。(iOS/Android共通で使用する。)
   * uuidを指定した場合はロックの解放を同時に行う。
   * 指定しない場合はPatronドキュメントの作成のみを行う（主にリストアで使用する）。
   * 指定したuuidが取得中のロックのuuidと異なっている，もしくは，ロックの有効期限が切れている場合はForbiddenErrorを返す。
   * また，RevenueCat上に課金情報が無い場合やStripeで既に課金済みの場合もForbiddenErrorを返す。
   * - productId: RevenueCatで支払いを行った商品のproductId
   * - [Optional] uuid: ロック解放用のUUID。指定した場合は取得していたロックを解放する。
   * - [Optional] app: 使用するRevenueCatアプリ名（デフォルト: "utoniq"）
   * @auth: user
   */
  createIapSubscription: Patron
  /**
   * アイテムを作成する
   * @auth: artist
   */
  createItem: Item
  /**
   * アイテムのコメントを作成する
   * inputのreplyToIdを指定した場合は、そのコメントへの返信となる
   * @auth: user
   */
  createItemComment: Comment
  /**
   * 配信ライブの予約リクエストを行う
   * @auth: artist
   */
  createLiveStream: LiveStream
  /**
   * ライブ配信のコメントを作成する
   * inputのreplyToIdを指定した場合は、そのコメントへの返信となる
   * @auth: user
   */
  createLiveStreamComment: Comment
  /**
   * ミッションを作成する(アーティスト用)
   * @auth: artist
   */
  createMission: Mission
  /**
   * ミッションのコメントを作成する
   * inputのreplyToIdを指定した場合は、そのコメントへの返信となる
   * @auth: user
   */
  createMissionComment: Comment
  /**
   * 支払い情報を登録する(3Dセキュア対応)
   * @auth: user
   */
  createPaymentMethod: CreatePaymentMethodResponse
  /**
   * 支払い情報を登録する
   * @auth: user
   * @deprecated(reason: "use createPaymentMethod")
   */
  createPaymentSource: PaymentSource
  /**
   * ポストの新規投稿
   * withTwitterの場合はtwitterにも投稿する
   * @auth: artist
   */
  createPost: Post
  /**
   * リセール出品する
   * @auth: user
   */
  createResaleItem: ResaleItem
  /**
   * アーティストの画像を一時保存する
   * @auth: artist
   */
  createResource: ArtistResource
  /**
   * Create short link
   * @auth: none
   */
  createShortLink: ShortLink
  /**
   * トレードのコメントを作成する
   * @auth: user
   */
  createTradeComment: Comment
  /**
   * トレード申請をする
   * @auth: user
   */
  createTradeRequest: TradeNegotiation
  /**
   * ユーザーを退会させる
   * @auth user
   */
  deactivateUser: User
  /**
   * トレード申請を却下する
   * @auth: user
   */
  declineTradeRequest: TradeNegotiation
  /**
   * アーティストスレッドのコメントを削除する
   * id: アイテムID
   * commentID: アイテムのコメントID
   * @auth: user
   */
  deleteArtistThreadComment: Comment
  /**
   * カートを削除する
   * @auth: user
   */
  deleteCart: UserCart
  /**
   * コメントを削除する
   * 紐付いている返信もすべて削除される
   * @auth: users
   */
  deleteComment: Comment
  /**
   * 期限切れのカートをすべて削除する
   * @auth: user
   */
  deleteExpiredCarts: Array<UserCart>
  /**
   * アイテムのコメントを削除する
   * id: アイテムID
   * commentID: アイテムのコメントID
   * @auth: user
   */
  deleteItemComment: Comment
  /**
   * ミッションを削除する(アーティスト用)
   * @auth: artist
   */
  deleteMission: Mission
  /**
   * ミッションのコメントを削除する
   * id: ミッションID
   * commentID: ミッションのコメントID
   * @auth: user
   */
  deleteMissionComment: Comment
  /**
   * 支払い情報を削除する
   * @auth: user
   */
  deletePaymentSource: PaymentSource
  /**
   * ポストの削除。ポストへの返信も全て削除する。
   * 投稿した本人のみが削除可能
   * @auth: artist
   */
  deletePost: Post
  /**
   * ポストへの返信を削除する。投稿した本人のみが削除可能
   * @auth: user
   */
  deletePostReply: PostReply
  /**
   * サブスリプションをダウングレードする
   * @auth: user
   */
  downgradeSubscriptionPlan: Patron
  /**
   * 配信ライブを終了する
   * @auth: artist
   */
  endLiveStream: LiveStream
  /**
   * キャンペーンにエントリーする
   * 指定したアイテムに基づいて、ガチャ、コレクションまですべて作成する
   * @auth: artist
   */
  entryCampaign: Campaign
  /**
   * アーティストをフォローする
   * @auth: user
   */
  follow: Follower
  /**
   * 複数獲得アイテム設定の作成
   * @auth: admin
   */
  generateMultiItemSerials: CsvDownload
  /**
   * チケットの分配リンクを生成する
   * @auth: user
   */
  generateTicketDistributionLink: TicketDistributionLink
  /**
   * 暗号資産ウォレット連携用の署名作成用メッセージを返す
   * @auth: user
   */
  generateWalletSignatureMessage: CryptoWallet
  /**
   * 背景アイテムのコンビニプリント用QRコードを生成する
   * @auth: user
   */
  generateWallpaperPrintQrcode: ItemSerial
  /**
   * 自動ミッションの報酬を受け取る
   * @auth: user
   */
  getStepMissionReward: UserStepMission
  /**
   * アーティストスレッドにいいねする
   * @auth: user
   */
  likeArtistThreadChannel: ArtistThreadChannel
  /**
   * アーティストスレッドコメントにいいねする
   * @auth: user
   */
  likeArtistThreadChannelComment: Comment
  /**
   * コメントをLIKEする
   * @auth: user
   */
  likeComment: Comment
  /**
   * アイテムコメントにいいねする
   * @auth: user
   */
  likeItemComment: Comment
  /**
   * "
   * 配信ライブにいいねをする
   * @auth: user
   */
  likeLiveStream: LiveStream
  /**
   * ミッションコメントにいいねする
   * @auth: user
   */
  likeMissionComment: Comment
  /**
   * ポストをいいねする
   * @auth: user
   */
  likePost: Post
  /**
   * ポスト返信をいいねする
   * @auth: user
   */
  likePostReply: PostReply
  /**
   * 匿名認証からユーザー登録した後に、ユーザードキュメントを生成する
   * 内部処理で、ゲストカートと既存カートのマージを行う
   * @auth: user
   */
  linkAnonymousAccount: User
  /**
   * ItemのNFTを発行する
   * このエンドポイントでは、NFT発行のコントラクト実行までを行う
   * ブロックチェーンのファイナライズはwebhook経由でFirestoreを更新するため、このエンドポイントが完了してもNFT発行が完了するわけではない
   * @auth: user
   */
  mintItemNft: NftToken
  /**
   * ガチャチケットをシリアル経由で獲得
   * @auth: user
   */
  obtainGachaTicketSerial: GachaTicketOrder
  /**
   * ログインボーナスを獲得する
   * @auth: user
   */
  obtainLoginBonus: LoginBonus
  /**
   * 複数獲得アイテムの獲得処理
   * @auth: user
   */
  obtainMultiItemSerials: MultiItemSerial
  /**
   * シリアル番号のアイテムを取得する
   * @auth: user
   */
  obtainSerial: Item
  /**
   * 売上の入金申請を行う。売上の全額が入金待ちになる。
   *
   * @auth: artist
   */
  openPayout?: Maybe<Payout>
  /**
   * 所属しているOrganizationのアーティストを作成する
   * request headerに'x-utoniq-application-key'が必須（organizationId自動取得のため）
   * @auth: manager
   */
  organizationManagerRegisterArtist: Account
  /** 先行販売の抽選当選者がクレジットカードで支払いをする */
  payPreSaleLottery: UserCartTransactionOrder
  /**
   * アーティストへconnectアカウントから銀行へ払い出しを行う。
   * 手数料以下の場合はutoniqの手数料としてすべて徴収されるので注意
   *
   * amount: 払い出す金額。指定しないと残高全額はらいだしをする。
   * @auth: admin
   */
  payoutToArtist?: Maybe<Transaction>
  /**
   * UserItemのprotect状態を更新する
   * @auth: user
   */
  protectUserItem: UserItem
  /**
   * アイテムを公開する
   * @auth: none
   */
  publishItem: Item
  /**
   * ガチャチケをポイントで購入する
   * @auth: user
   */
  purchaseGachaTicketByPoint: GachaTicketOrder
  /**
   * ガチャチケ10枚の価格で11枚をポイントで購入する
   * @auth: user
   */
  purchaseGachaTicketByPointInBulk: GachaTicketOrder
  /**
   * 保留状態でアイテムを購入する（コンビニ決済）
   * @auth: user
   */
  purchaseItemByPendingPayment: PendingPurchaseItem
  /**
   * プリペイドポイントでアイテムを購入する
   * @auth: user
   */
  purchaseItemByPoint: ItemOrder
  /**
   * いますぐ購入（有料アイテムで、現金・プリペイドのみ対応）
   * 内部では、カート作成 → チェックアウト までを一気通貫せ行う
   * @auth: user
   */
  purchaseItemImmediately: UserCartTransactionOrder
  /**
   * リセールの購入
   * @auth: user
   */
  purchaseResaleItem: ItemResaleRaffleUser
  /**
   * 分配チケットを受け取る
   * @auth: user
   */
  receiveTicketDistribution: TicketDistributionLink
  /**
   * チケットの分配リンクを再生成する
   * @auth: user
   */
  regenerateTicketDistributionLink: TicketDistributionLink
  /**
   * アーティストを登録する
   * @auth: admin
   * @params:
   *   email: email
   *   accountId: accountId
   *   displayName: 表示用の名称
   *   paymentType: connect | billing
   * @deprecated use adminRegisterArtist or organizationManagerRegisterArtist
   */
  registerArtist: Account
  /**
   * Register artist from user
   * @auth: user
   */
  registerArtistFromUser: Artist
  /** @deprecated integrated into updateProfile */
  registerProfile: User
  /**
   * 管理者権限でサブスクリプションプランを作成
   * @auth: admin
   * @deprecated use adminRegisterSubscriptoins
   */
  registerSubscription: Artist
  /**
   * ユーザーの銀行口座を登録する
   * すでに登録済みの場合は上書きされる
   * @auth: user
   */
  registerUserBank: User
  /**
   * アイテムをレビューでリジェクトする
   * @auth: admin
   */
  rejectItem: Item
  /**
   * コレクションを削除
   * コレクションのオーナーでない場合はForbiddenErrorがthrowされる
   * @auth: artist
   * @params:
   *   id: Collection DocumentID
   */
  removeCollection: Collection
  /**
   * カートからアイテムを1つ削除する
   * @auth: guest-user
   */
  removeFromCart?: Maybe<UserCart>
  /**
   * コレクションからアイテムを削除する
   * コレクションのオーナーでない場合はForbiddenErrorがthrowされる
   * @auth: artist
   * @params:
   *   collectionId: Collection DocumentID
   *   itemId: Item DocumentID
   */
  removeItemFromCollection: CollectionItem
  /**
   * Bulk remove item from collection
   * @auth: admin
   * @params:
   *   inputs: Collection ID & Item IDs to remove
   */
  removeItemFromCollectionBulk: Collection
  /**
   * 認証ユーザーのoauth tokenを削除する
   * アーティストログインの場合はArtist Documentを更新する
   * @auth: user
   */
  removeOauthToken: AccountRecord
  /** アーティストの一時保存しているリソースを削除する */
  removeResource: ArtistResource
  /**
   * ユーザーがポストへ返信する
   * @auth: user
   */
  replyPost: PostReply
  /**
   * RevenueCatの情報からPatronドキュメントを作成する。（iOS/Android共通で使用する。）
   * 複数のPatronドキュメントをまとめてリストアする際に使用する。
   * RevenueCat上に課金情報が無かった場合はForbiddenErrorを返す。
   * - productIds: RevenueCatで支払い済みのproductIdのリスト
   * - [Optional] app: 使用するRevenueCatアプリ名（デフォルト: "utoniq"）
   * @auth: user
   */
  restoreIapSubscriptions: Array<Patron>
  /**
   * サブスクリプションのキャンセルをやめて継続する
   * @auth: user
   */
  resumeSubscriptionPlan: Patron
  /**
   * ガチャをまわす
   * @auth: user
   */
  rollGacha: GachaResult
  /**
   * 認証ユーザーのoauth tokenを保存する
   * アーティストログインの場合はArtist Documentを更新する
   * @auth: user
   */
  saveOauthToken: AccountRecord
  /**
   * 認証確認メールを認証ユーザーのメールアドレスに送信する
   * @auth: none
   * @params:
   *   email: ユーザーemail
   */
  sendVerificationEmail: EmailVerification
  /**
   * 認証コードをSMSで送信する
   * @auth: user
   */
  sendVerificationSMS: PhoneNumberVerification
  /**
   * アーティストでサインアップする
   * entryの指定がある場合で、かつキャンペーンが開催中の場合はエントリーする
   * @auth: none
   */
  signupAsArtist: Artist
  /**
   * ユーザーでサインアップする(事前にEmailのCode認証が必須)
   * @auth: none
   */
  signupAsUser: User
  /**
   * ライブ配信を開始する
   * @auth: artist
   */
  startLiveStream: LiveStream
  /**
   * コンビニ支払いによるメンバーシップ加入手続きを開始する
   * 別途支払いが完了するまでは有効化されない
   * @auth: user
   */
  startPendinggSubscription: PendingSubscription
  /**
   * アイテムを販売開始状態にする
   * @auth: none
   */
  startSellingItem: Item
  /**
   * サブスクリプションをスタートし、Patronドキュメントを作成する
   * @auth: user
   */
  startSubscription: Patron
  /**
   * アイテムを販売停止状態にする
   * @auth: none
   */
  stopSellingItem: Item
  /**
   * サブスクリプションを次回の更新時に継続しない
   * Patronドキュメントは残ったまま、ステータスが変わる
   * @auth: user
   */
  stopSubscription: Patron
  /**
   * 決済用のロックを取得する。呼び出し元でランダム文字列(UUID)を生成して引数として渡す。
   * 他のUUIDで既にロックが取得されている場合は失敗する。
   * 同じUUIDであれば有効期限内でも再度ロックを取得することが可能。
   * ロックの取得に成功した場合はsuccess=True，失敗した場合はsuccess=Falseを返す。
   * - uuid: ロック取得用のUUID
   * - [Optional] sec: ロックの有効期限[単位:秒] (default: 60, min: 0, max: 600)
   * @auth: user
   */
  tryPaymentLock: PaymentLockResponse
  /**
   * ユーザーのブロックを戻す
   * user.blockedUsersでID一覧を取得
   * @auth: none
   */
  unblockUser?: Maybe<User>
  /**
   * アーティストのフォローを解除する
   * @auth: user
   */
  unfollow?: Maybe<Follower>
  /**
   * アーティストスレッドにいいねを取り消す
   * @auth: user
   */
  unlikeArtistThreadChannel: ArtistThreadChannel
  /**
   * アーティストスレッドコメントのいいねを取り消す
   * @auth: user
   */
  unlikeArtistThreadChannelComment: Comment
  /**
   * コメントをUNLIKEする
   * @auth: user
   */
  unlikeComment: Comment
  /**
   * アイテムコメントのいいねを取り消す
   * @auth: user
   */
  unlikeItemComment: Comment
  /**
   * ミッションコメントのいいねを取り消す
   * @auth: user
   */
  unlikeMissionComment: Comment
  /**
   * ポストのいいねをキャンセルする
   * @auth: user
   */
  unlikePost: Post
  /**
   * ポスト返信のいいねを取り消す
   * @auth: user
   */
  unlikePostReply: PostReply
  /**
   * アイテムを非公開にする
   * @auth: none
   */
  unpublishItem: Item
  /**
   * チケットアイテムを未使用に戻す
   * @auth: artist
   * @deprecated use artistUnuseTickets instead
   */
  unuseTicket: ItemSerial
  /**
   * アーティスト通知設定の更新
   * @auth: artist
   */
  updateArtistNotificationSettings: Artist
  /**
   * アーティスト権限でアーティストのプロフィールテキストを更新する。
   * @auth: artist
   */
  updateArtistProfileText: Artist
  /**
   * アーティスト権限でアーティストのsns情報を更新する。
   * @auth: artist
   */
  updateArtistSns: Artist
  /**
   * アーティスト権限でアーティストのプロフィールテキストを更新する。
   * @auth: artist
   */
  updateArtistTextLink: Artist
  /**
   * アーティストスレッドを更新する
   * 作成したユーザー本人のみ許可されている
   * @auth: user
   */
  updateArtistThreadChannel: ArtistThreadChannel
  /**
   * 指定したアーティストスレッドをミュート/ミュート解除する
   * @auth: user
   */
  updateArtistThreadMuteStatus: ArtistThreadChannel
  /**
   * deprecated
   * @deprecated integrated into updateProfile
   */
  updateAvatar: User
  /**
   * 銀行情報を更新する
   * 情報が有効であればStripe側でactiveステータスとなる
   * 失敗した場合はinactiveになる
   * @auth: artist
   */
  updateBank: Artist
  /**
   * アーティストのビジネス情報を登録する
   * 情報が有効であればStripe側でactiveステータスとなる
   * 失敗した場合はinactiveになる
   * @auth: artist
   */
  updateBusiness: Artist
  /**
   * コレクションを更新する
   * @auth: artist
   */
  updateCollection: Collection
  /**
   * コメントを更新する
   * @auth: user
   */
  updateComment: Comment
  /**
   * カスタムプロフィールを設定する
   * @auth: user
   */
  updateCustomProfile: User
  /**
   * 配送先設定の更新
   * すべての値を指定して更新します
   * @deprecated no more maintenance. use updateDeliveryInfo.
   */
  updateDelivery: User
  /**
   * イベントコレクションの受け取り時間を更新する
   * @auth: artist
   */
  updateEventCollectionPickupTime: EventCollection
  /**
   * アイテム情報を更新する
   * @deprecated artistUpdateItemを利用すること
   */
  updateItem: Item
  /**
   * ユーザー通知の最終既読時間を現在時刻に更新する
   * @auth: user
   */
  updateLastNotificationReadAt: Scalars['Int']
  /**
   * ミッションを編集する(アーティスト用)
   * @auth: artist
   */
  updateMission: Mission
  /**
   * 支払いアカウントをアップデートする
   * @auth: artist
   */
  updatePaymentAccount: Scalars['Boolean']
  /**
   * 支払い情報を更新する
   * @auth: user
   * @deprecated(reason: "use createPaymentMethod")
   */
  updatePaymentSource: PaymentSource
  /**
   * ポストの更新、投稿
   * @auth: artist
   */
  updatePost: Post
  /**
   * 先行販売の抽選結果をCSVアップロードで一括更新する
   * @auth: artist
   */
  updatePreSaleLotteryResultFromCsv: PreSale
  /**
   * プロフィールの更新
   * authでアカウントを作成した際に必ず呼ぶ。
   * その際、accountId、displayNameが指定されなければ自動で割り振られる。
   * deprecated: Artistで更新した場合、Artistレコードが更新されるが、レスポンスはUserとなってしまう
   * @deprecated Use updateUserProfile or artistUpdateArtistProfile
   */
  updateProfile: AccountRecord
  /**
   * サブスクリプションプランを更新する。
   * 価格は変更できない
   * @auth: user
   */
  updateSubscription: Artist
  /**
   * サブスク時に個人情報を必須とするかのフラグを切り替える
   * @auth: artist
   */
  updateSubscriptionRequirePersonalInformation: Artist
  /**
   * ユーザーの氏名・住所・電話番号・性別情報を更新する
   * @auth: user
   */
  updateUserDeliveryInfo: User
  /**
   * User update follow settings
   * @auth: user
   */
  updateUserFollowSettings: User
  /**
   * ユーザー設定の更新
   * @auth: user
   */
  updateUserNotificationSettings: User
  /**
   * ユーザーのプライバシー情報の更新
   * @auth: user
   */
  updateUserPrivacySettings: User
  /**
   * ユーザー情報を更新する。
   * isRegisteredProfileがなく、user documentもない場合初期登録とみなしユーザー登録
   * @auth: user
   */
  updateUserProfile: User
  /**
   * 最新既読時間を現在時刻に更新する
   * 未読メンションがある場合、このタイミングでクリアされる
   * @auth: user
   */
  updateUserUnread: UserUnread
  /**
   * サブスリプションプランをアップグレードする
   * @auth: user
   */
  upgradeSubscriptionPlan: Patron
  /**
   * イベント受け取りのチケットをまとめて使用済みにする
   * @auth: user
   */
  useEventPickupTickets: Array<ItemSerial>
  /**
   * チケットアイテム（シリアル指定）を使用済みにする
   * @auth: user
   * @deprecated use useTickets or artistUseTickets instead.
   */
  useTicket: ItemSerial
  /**
   * チケットアイテム（複数）を使用済みにする
   * イベント受け取りの場合は useEventPickupTickets を利用すること
   * @auth: user
   */
  useTickets: Array<ItemSerial>
  /**
   * メール確認用のコードをでメールアドレスを認証する
   * 認証失敗した場合はValidationInputErrorをthrowする
   * @auth: none
   * @params:
   *   email: Email
   *   code: 認証コード
   */
  verifyCode: EmailVerification
  /**
   * SMS確認用のコードを認証する
   * 認証失敗した場合はValidationInputErrorをthrowする
   * @auth: user
   */
  verifySMSCode: PhoneNumberVerification
  /**
   * コレクションアイテム閲覧データを更新する
   * @auth: user
   */
  viewCollectionItem: RecentlyViewedCollection
  /**
   * ストア閲覧データを更新する
   * @auth: user
   */
  viewStoreItem: RecentlyStoreViewedItem
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAcceptItemArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAcceptTradeRequestArgs = {
  inputs: AcceptTradeRequestInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAddItemToCollectionArgs = {
  collectionId: Scalars['ID']
  itemId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAddRegistrationTokenArgs = {
  token: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAddRegistrationTokenForOrganizationArgs = {
  token: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAddRegistrationTokenForStandaloneArgs = {
  artistId: Scalars['String']
  token: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAddSharePointArgs = {
  itemId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAddToCartArgs = {
  inputs: AddToCartInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminAddArtistBannerArgs = {
  id: Scalars['ID']
  inputs: AdminAddArtistBannerInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminAddCollectionLabelArgs = {
  collectionId: Scalars['ID']
  inputs: CollectionLabelInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminAddCollectionsToEventArgs = {
  inputs: AdminAddCollecitonToEventInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminAddEventBannerArgs = {
  id: Scalars['ID']
  inputs: AdminAddEventBannerInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminAddItemToCollectionArgs = {
  collectionId: Scalars['ID']
  inputs: CollectionItemInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminAddItemToCollectionBulkArgs = {
  id: Scalars['ID']
  inputs: CollectionItemBulkInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminAddItemToCollectionsBulkArgs = {
  inputs: AdminCsvCommonInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminAddOrganizationBannerArgs = {
  id: Scalars['ID']
  inputs: AdminAddOrganizationBannerInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminArchiveCommentArgs = {
  commentId: Scalars['ID']
  commentType: CommentType
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminArchivePostReplyArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminBulkEntryArtistsArgs = {
  id: Scalars['ID']
  inputs: CampaignBulkEntryInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCancelPreSaleLotteryArgs = {
  inputs: AdminCancelPreSaleLotteryInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminChargeCheckoutFeesArgs = {
  inputs: AdminChagrgeCheckoutFeeInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminChargePrepaidPointArgs = {
  id: Scalars['ID']
  inputs: AdminChargePrepaidPointInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCompleteResaleItemPaymentArgs = {
  inputs: AdminCompleteResaleItemPaymentInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateAcceptedAvatarItemFromUriArgs = {
  inputs?: Maybe<CreateItemFromUriInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateAcceptedItemBulkArgs = {
  inputs?: Maybe<AdminCreateAcceptedItemBulkInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateAcceptedItemFromUriArgs = {
  inputs?: Maybe<CreateItemFromUriInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateAcceptedWallpaperItemFromUriArgs = {
  inputs?: Maybe<CreateItemFromUriInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateAvatarItemArgs = {
  inputs?: Maybe<CreateItemInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateCampaignArgs = {
  id: Scalars['ID']
  inputs: CreateCampaignInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateCollectionArgs = {
  inputs?: Maybe<AdminCreateCollectionInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateEventArgs = {
  inputs: AdminCreateEventInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateEventSeatArgs = {
  inputs: AdminCreateEventSeatInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateGachaArgs = {
  inputs: CreateGachaInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateItemArgs = {
  inputs?: Maybe<CreateItemInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateItemPurchasableUsersBulkArgs = {
  inputs: AdminCsvCommonInput
  itemId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateLoginBonusArgs = {
  inputs: AdminCreateLoginBonusInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateMerchItemArgs = {
  inputs?: Maybe<CreateItemInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateMissionArgs = {
  inputs: CreateMissionInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateOfficialArtistThreadChannelArgs = {
  inputs: CreateArtistThreadInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateOrganizationArgs = {
  inputs: AdminCreateOrganizationInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateOrganizationNotificationArgs = {
  id: Scalars['ID']
  inputs: AdminCreateOrganizationNotificationInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreatePreSaleArgs = {
  inputs: AdminCreatePreSaleInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreatePrepaidPointArgs = {
  id: Scalars['ID']
  inputs: AdminCreatePrepaidPointInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateResourceArgs = {
  inputs: AdminCreateResourceInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateSeatTemplateArgs = {
  inputs: AdminCreateSeatTemplateInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateStepMissionArgs = {
  input: CreateStepMissionInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateTicketItemArgs = {
  inputs?: Maybe<CreateItemInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminCreateWallpaperItemArgs = {
  inputs?: Maybe<CreateItemInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminDeleteStepMissionArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminDeleteUserArgs = {
  userId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminGenerateWallpaperPrintQrcodeArgs = {
  inputs: AdminGenerateWallpaperPrintQrcodeInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminGiveGachaTicketArgs = {
  gachaTicketId: Scalars['String']
  inputs: GiveGachaTicketInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminIssuePartnerClientArgs = {
  inputs: AdminIssuePartnerClientInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminMerchItemShipCompleteArgs = {
  inputs: AdminMerchItemShipCompleteInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminRefundStoreItemArgs = {
  inputs: AdminRefundStoreItemInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminRegisterArtistArgs = {
  inputs: AdminRegisterArtistInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminRegisterSubscriptionsArgs = {
  inputs: RegisterSubscriptionsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminRemoveArtistBannerArgs = {
  id: Scalars['ID']
  order: Scalars['Int']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminRemoveCollectionFromEventArgs = {
  inputs: AdminRemoveCollectionFromEventInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminRemoveCollectionLabelArgs = {
  collectionId: Scalars['ID']
  labelId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminRemoveEventBannerArgs = {
  id: Scalars['ID']
  inputs: AdminRemoveEventBannerInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminRemoveItemFromCollectionArgs = {
  collectionId: Scalars['ID']
  inputs: CollectionItemInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminRemoveOrganizationBannerArgs = {
  id: Scalars['ID']
  order: Scalars['Int']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminSendPushNotificationBulkArgs = {
  inputs?: Maybe<AdminSendPushNotificationBulkInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminShuffleLimitedGachaItemSetArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminStartPreSaleLotteryNotificationArgs = {
  inputs: AdminStartPreSaleLotteryNotificationInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminStartPreSaleLotteryProcessArgs = {
  inputs: AdminStartPreSaleLotteryProcessInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminTradeItemSerialsUserArgs = {
  inputs: AdminTradeItemSerialsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateArtistArgs = {
  id: Scalars['ID']
  inputs: AdminUpdateArtistInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateAssignSeatFromCsvArgs = {
  inputs: AdminUpdateAssignEventSeatFromCsvInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateCampaignArgs = {
  id: Scalars['ID']
  inputs: UpdateCampaignInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateCampaignEntryConditionArgs = {
  id: Scalars['ID']
  inputs: UpdateCampaignEntryConditionInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateCollectionArgs = {
  inputs?: Maybe<AdminUpdateCollectionInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateCollectionFeeSettingsArgs = {
  collectionId: Scalars['ID']
  inputs: AdminUpdateCollectionFeeSettingsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateCollectionItemOrderArgs = {
  id: Scalars['ID']
  inputs: AdminUpdateCollectionItemOrderInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateCollectionLabelArgs = {
  collectionId: Scalars['ID']
  inputs: CollectionLabelInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateEventArgs = {
  id: Scalars['ID']
  inputs: AdminUpdateEventInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateEventSeatSectionArgs = {
  inputs: AdminUpdateEventSeatSectionInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateGachaArgs = {
  id: Scalars['String']
  inputs: UpdateGachaInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateGachaDataArgs = {
  id: Scalars['String']
  inputs: UpdateGachaDataInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateGachaItemsArgs = {
  id: Scalars['String']
  inputs: UpdateGachaItemsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateGachaItemsBulkArgs = {
  inputs: AdminCsvCommonInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateGachaLimitedItemSetsArgs = {
  id: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateGachaRaritiesArgs = {
  id: Scalars['String']
  inputs: UpdateGachaRaritiesInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateItemArgs = {
  id: Scalars['ID']
  inputs?: Maybe<AdminUpdateItemInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateOrganizationArgs = {
  id: Scalars['ID']
  inputs: AdminUpdateOrganizationInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdatePreSaleArgs = {
  id: Scalars['ID']
  inputs: AdminUpdatePreSaleInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateSeatTemplateArgs = {
  id: Scalars['ID']
  inputs: AdminUpdateSeatTemplateInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAdminUpdateTopDisplayArtistsArgs = {
  inputs?: Maybe<AdminUpdateTopDisplayArtistsInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAnswerFollowMissionArgs = {
  missionId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAnswerItemCollectMissionArgs = {
  missionId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAnswerQuizMissionArgs = {
  answer: Scalars['String']
  missionId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAnswerRetweetMissionArgs = {
  missionId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationApplyMerchItemDeliveryArgs = {
  ids: Array<Scalars['ID']>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationApplyPreSaleLotteryArgs = {
  clientId?: Maybe<Scalars['String']>
  inputs: ApplyPreSaleLotteryInput
  token?: Maybe<Scalars['String']>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationApplyResaleItemRaffleArgs = {
  inputs: ApplyResaleItemRaffleInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArchiveArtistThreadChannelArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArchiveItemArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistAddCollectionsToEventArgs = {
  inputs: ArtistAddCollecitonToEventInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistAddRegistrationTokenArgs = {
  token: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistArchiveOrganizationNotificationArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistAuthorizeWalletArgs = {
  inputs: AuthorizeWalletInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistCreateCommentArgs = {
  inputs: ArtistCreateCommentInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistCreateEventArgs = {
  inputs: ArtistCreateEventInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistCreateOrganizationNotificationArgs = {
  inputs: ArtistCreateOrganizationNotificationInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistCreatePostCategoryArgs = {
  inputs: ArtistCreatePostCategoryInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistCreatePreSaleArgs = {
  inputs: ArtistCreatePreSaleInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistDeleteEventArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistGenerateWalletSignatureMessageArgs = {
  inputs: GenerateWalletSignatureMessageInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistMerchItemShipCompleteArgs = {
  inputs: ArtistMerchItemShipCompleteInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistRegisterSubscriptionArgs = {
  amount: Scalars['Int']
  content: Scalars['String']
  title?: Maybe<Scalars['String']>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistRegisterSubscriptionsArgs = {
  inputs: RegisterSubscriptionsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistRemoveCollectionFromEventArgs = {
  inputs: ArtistRemoveCollectionFromEventInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistRemoveItemArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistRemovePostCategoryArgs = {
  inputs: ArtistRemovePostCategoryInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistReplyPostArgs = {
  inputs: ReplyPostInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUnuseEventPickupTicketsArgs = {
  inputs: UnuseEventPickupTicketsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUnuseTicketsArgs = {
  inputs: UnuseTicketsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUpdateArtistBannersArgs = {
  inputs: ArtistUpdateArtistBannersInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUpdateArtistProfileArgs = {
  inputs: ArtistUpdateArtistProfileInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUpdateAssignSeatFromCsvArgs = {
  inputs: ArtistUpdateAssignSeatFromCsvInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUpdateEventArgs = {
  id: Scalars['ID']
  inputs: ArtistUpdateEventInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUpdateItemArgs = {
  id: Scalars['ID']
  inputs?: Maybe<ArtistUpdateItemInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUpdateOrganizationNotificationArgs = {
  id: Scalars['ID']
  inputs: ArtistUpdateOrganizationNotificationInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUpdatePostCategoryArgs = {
  inputs: ArtistUpdatePostCategoryInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUpdatePreSaleArgs = {
  id: Scalars['ID']
  inputs: ArtistUpdatePreSaleInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUpdatePreSaleLotteryApplicantStatusArgs = {
  inputs: ArtistUpdatePreSaleLotteryApplicantStatusInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUpdateStatusArgs = {
  inputs: ArtistUpdateStatusInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUseEventPickupTicketsArgs = {
  inputs: UseEventPickupTicketsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationArtistUseTicketsArgs = {
  inputs: UseTicketsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationAuthorizeWalletArgs = {
  inputs: AuthorizeWalletInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationBlockUserArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCancelArchiveLiveStreamArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCancelDowngradeSubscriptionPlanArgs = {
  artistId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCancelPreSaleLotteryArgs = {
  inputs: CancelPreSaleLotteryInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCancelResaleItemArgs = {
  inputs: CancelResaleItemInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCancelResaleItemRaffleArgs = {
  inputs: CancelResaleItemRaffleInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCancelTicketDistributionArgs = {
  inputs: CancelTicketDistributionInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCancelTradeRequestArgs = {
  inputs: CancelTradeRequestInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationChargePrepaidPointArgs = {
  inputs: ChargePrepaidPointInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationChargePrepaidPointByIapArgs = {
  inputs: ChargePrepaidPointIapInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCheckoutCartArgs = {
  inputs: CheckoutCartInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationClosePayoutArgs = {
  id: Scalars['ID']
  type: PayoutType
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateAcceptedItemArgs = {
  inputs?: Maybe<CreateItemInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateArtistThreadChannelArgs = {
  inputs: CreateArtistThreadInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateArtistThreadCommentArgs = {
  id: Scalars['String']
  inputs: CommunityCommentInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateCollectionArgs = {
  inputs?: Maybe<CreateCollectionInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateCommentArgs = {
  inputs: CreateCommentInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateIapSubscriptionArgs = {
  app?: Maybe<Scalars['String']>
  artistId?: Maybe<Scalars['String']>
  productId: Scalars['String']
  uuid?: Maybe<Scalars['String']>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateItemArgs = {
  inputs?: Maybe<CreateItemInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateItemCommentArgs = {
  id: Scalars['String']
  inputs: CommunityCommentInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateLiveStreamArgs = {
  inputs: CreateLiveStreamInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateLiveStreamCommentArgs = {
  id: Scalars['String']
  inputs: CommunityCommentInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateMissionArgs = {
  inputs: CreateMissionInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateMissionCommentArgs = {
  id: Scalars['String']
  inputs: CommunityCommentInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreatePaymentMethodArgs = {
  token: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreatePaymentSourceArgs = {
  token: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreatePostArgs = {
  inputs: CreatePostInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateResaleItemArgs = {
  inputs: CreateResaleItemInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateResourceArgs = {
  filePath: Scalars['String']
  name: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateShortLinkArgs = {
  redirectUrl: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateTradeCommentArgs = {
  id: Scalars['String']
  inputs: CommunityCommentInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationCreateTradeRequestArgs = {
  inputs: CreateTradeRequestInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationDeactivateUserArgs = {
  inputs: DeactivateUserInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationDeclineTradeRequestArgs = {
  inputs: DeclineTradeRequestInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationDeleteArtistThreadCommentArgs = {
  commentId: Scalars['String']
  id: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationDeleteCartArgs = {
  inputs: DeleteCartInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationDeleteCommentArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationDeleteItemCommentArgs = {
  commentId: Scalars['String']
  id: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationDeleteMissionArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationDeleteMissionCommentArgs = {
  commentId: Scalars['String']
  id: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationDeletePostArgs = {
  postId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationDeletePostReplyArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationDowngradeSubscriptionPlanArgs = {
  artistId: Scalars['String']
  paymentMethod?: Maybe<PaymentMethod>
  planId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationEndLiveStreamArgs = {
  id: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationEntryCampaignArgs = {
  id: Scalars['ID']
  inputs: CampaignEntryInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationFollowArgs = {
  artistId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationGenerateMultiItemSerialsArgs = {
  inputs: GenerateMultiItemSerialsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationGenerateTicketDistributionLinkArgs = {
  inputs: GenerateTicketDistributionLinkInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationGenerateWalletSignatureMessageArgs = {
  inputs: GenerateWalletSignatureMessageInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationGenerateWallpaperPrintQrcodeArgs = {
  inputs: GenerateWallpaperPrintQrcodeInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationGetStepMissionRewardArgs = {
  stepMissionId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationLikeArtistThreadChannelArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationLikeArtistThreadChannelCommentArgs = {
  inputs: CommunityCommentLikeUnlikeInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationLikeCommentArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationLikeItemCommentArgs = {
  inputs: CommunityCommentLikeUnlikeInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationLikeLiveStreamArgs = {
  id: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationLikeMissionCommentArgs = {
  inputs: CommunityCommentLikeUnlikeInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationLikePostArgs = {
  postId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationLikePostReplyArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationLinkAnonymousAccountArgs = {
  inputs: LinkAnonymousAccountInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationMintItemNftArgs = {
  inputs: MintItemNftInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationObtainGachaTicketSerialArgs = {
  gachaTicketId: Scalars['String']
  serialId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationObtainLoginBonusArgs = {
  inputs: ObtainLoginBonusInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationObtainMultiItemSerialsArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationObtainSerialArgs = {
  itemId: Scalars['ID']
  serialId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationOrganizationManagerRegisterArtistArgs = {
  inputs: RegisterArtistInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationPayPreSaleLotteryArgs = {
  inputs: PayPreSaleLotteryInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationPayoutToArtistArgs = {
  amount?: Maybe<Scalars['Int']>
  artistId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationProtectUserItemArgs = {
  inputs: ProtectUserItemInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationPublishItemArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationPurchaseGachaTicketByPointArgs = {
  gachaTicketId: Scalars['ID']
  inputs: PurchaseGachaTicketPointInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationPurchaseGachaTicketByPointInBulkArgs = {
  gachaTicketId: Scalars['ID']
  inputs: PurchaseGachaTicketPointInBulkInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationPurchaseItemByPendingPaymentArgs = {
  fullName: Scalars['String']
  itemId: Scalars['ID']
  price?: Maybe<Scalars['Int']>
  purchaseNumber?: Maybe<Scalars['Int']>
  skuId?: Maybe<Scalars['String']>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationPurchaseItemByPointArgs = {
  itemId: Scalars['ID']
  prepaidPoint?: Maybe<Scalars['Int']>
  purchaseNumber?: Maybe<Scalars['Int']>
  skuId?: Maybe<Scalars['String']>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationPurchaseItemImmediatelyArgs = {
  inputs: PurchaseItemImmediatelyInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationPurchaseResaleItemArgs = {
  inputs: PurchaseResaleItemInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationReceiveTicketDistributionArgs = {
  inputs: ReceiveTicketDistributionInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRegenerateTicketDistributionLinkArgs = {
  inputs: RegenerateTicketDistributionLinkInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRegisterArtistArgs = {
  accountId: Scalars['String']
  displayName: Scalars['String']
  email: Scalars['String']
  paymentType?: Maybe<PaymentType>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRegisterArtistFromUserArgs = {
  inputs: RegisterArtistFromUserInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRegisterProfileArgs = {
  inputs?: Maybe<RegisterProfileInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRegisterSubscriptionArgs = {
  amount: Scalars['Int']
  artistId: Scalars['ID']
  content: Scalars['String']
  title?: Maybe<Scalars['String']>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRegisterUserBankArgs = {
  inputs: UserBankInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRejectItemArgs = {
  id: Scalars['ID']
  reason: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRemoveCollectionArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRemoveFromCartArgs = {
  inputs: RemoveFromCartInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRemoveItemFromCollectionArgs = {
  collectionId: Scalars['ID']
  itemId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRemoveItemFromCollectionBulkArgs = {
  inputs: CollectionRemoveItemBulkInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRemoveOauthTokenArgs = {
  providerId: ProviderType
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRemoveResourceArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationReplyPostArgs = {
  inputs: ReplyPostInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRestoreIapSubscriptionsArgs = {
  app?: Maybe<Scalars['String']>
  productIds: Array<Scalars['String']>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationResumeSubscriptionPlanArgs = {
  artistId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationRollGachaArgs = {
  gachaId: Scalars['ID']
  inputs: RollGachaInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationSaveOauthTokenArgs = {
  inputs: SaveOauthTokenInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationSendVerificationEmailArgs = {
  email: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationSignupAsArtistArgs = {
  inputs: SignupAsArtistInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationSignupAsUserArgs = {
  inputs: SignupAsUserInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationStartLiveStreamArgs = {
  id: Scalars['String']
  input?: Maybe<StartLiveStreamInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationStartPendinggSubscriptionArgs = {
  artistId: Scalars['ID']
  planId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationStartSellingItemArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationStartSubscriptionArgs = {
  artistId: Scalars['ID']
  planId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationStopSellingItemArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationStopSubscriptionArgs = {
  artistId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationTryPaymentLockArgs = {
  sec?: Maybe<Scalars['Int']>
  uuid: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUnblockUserArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUnfollowArgs = {
  artistId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUnlikeArtistThreadChannelArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUnlikeArtistThreadChannelCommentArgs = {
  inputs: CommunityCommentLikeUnlikeInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUnlikeCommentArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUnlikeItemCommentArgs = {
  inputs: CommunityCommentLikeUnlikeInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUnlikeMissionCommentArgs = {
  inputs: CommunityCommentLikeUnlikeInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUnlikePostArgs = {
  postId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUnlikePostReplyArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUnpublishItemArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUnuseTicketArgs = {
  inputs: UnuseTicketInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateArtistNotificationSettingsArgs = {
  inputs: ArtistNotificationSettingsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateArtistProfileTextArgs = {
  profileText?: Maybe<Scalars['String']>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateArtistSnsArgs = {
  inputs: UpdateArtistSnsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateArtistTextLinkArgs = {
  inputs: Array<Maybe<UpdateArtistTextLinkInput>>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateArtistThreadChannelArgs = {
  id: Scalars['ID']
  inputs: UpdateArtistThreadInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateArtistThreadMuteStatusArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateAvatarArgs = {
  path: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateBankArgs = {
  inputs?: Maybe<BankInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateBusinessArgs = {
  inputs?: Maybe<BusinessInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateCollectionArgs = {
  inputs?: Maybe<UpdateCollectionInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateCommentArgs = {
  id: Scalars['ID']
  inputs: UpdateCommentInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateCustomProfileArgs = {
  inputs?: Maybe<UpdateCustomProfileInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateDeliveryArgs = {
  inputs: UpdateDeliveryInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateEventCollectionPickupTimeArgs = {
  inputs: UpdateEventCollectionPickupTimeInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateItemArgs = {
  id: Scalars['ID']
  price: Scalars['Int']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateLastNotificationReadAtArgs = {
  readAt: Scalars['Date']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateMissionArgs = {
  id: Scalars['ID']
  inputs: UpdateMissionInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdatePaymentAccountArgs = {
  inputs?: Maybe<UpdatePaymentAccountInput>
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdatePaymentSourceArgs = {
  token: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdatePostArgs = {
  inputs: UpdatePostInput
  postId: Scalars['ID']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdatePreSaleLotteryResultFromCsvArgs = {
  inputs: UpdatePreSaleLotteryResultFromCsvInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateProfileArgs = {
  inputs: UpdateProfileInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateSubscriptionArgs = {
  artistId: Scalars['ID']
  inputs: UpdateSubscriptionInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateSubscriptionRequirePersonalInformationArgs = {
  isRequired: Scalars['Boolean']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateUserDeliveryInfoArgs = {
  inputs: UserDeliveryInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateUserFollowSettingsArgs = {
  inputs: UserFollowSettingsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateUserNotificationSettingsArgs = {
  inputs: UserNotificationSettingsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateUserPrivacySettingsArgs = {
  inputs: UserPrivacySettingsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateUserProfileArgs = {
  inputs: UpdateProfileInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpdateUserUnreadArgs = {
  inputs: UpdateUnreadInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUpgradeSubscriptionPlanArgs = {
  artistId: Scalars['String']
  paymentMethod?: Maybe<PaymentMethod>
  planId: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUseEventPickupTicketsArgs = {
  inputs: UseEventPickupTicketsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUseTicketArgs = {
  inputs: UseTicketInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationUseTicketsArgs = {
  inputs: UseTicketsInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationVerifyCodeArgs = {
  code: Scalars['String']
  email: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationVerifySmsCodeArgs = {
  code: Scalars['String']
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationViewCollectionItemArgs = {
  inputs: ViewCollectionItemInput
}

/**
 * GraphQL Mutation Type
 * データのCreate / Updateに利用するType
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type MutationViewStoreItemArgs = {
  inputs: ViewStoreItemInput
}

/** NFT Tokenデータ */
export type NftToken = {
  chain: Scalars['String']
  createdAt?: Maybe<Scalars['Date']>
  formattedCreatedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  metadata: NftTokenMetadata
  ownedByUserAddress?: Maybe<Scalars['String']>
  status: ItemNftMintStatus
  tokenDetailUri?: Maybe<Scalars['String']>
  tokenId?: Maybe<Scalars['String']>
  tokenURI?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Date']>
}

/** NFT Token Item用メタデータ */
export type NftTokenItemMetadata = {
  id: Scalars['String']
  item: Item
  itemSerial?: Maybe<ItemSerial>
  serialId: Scalars['String']
}

/** NFT Tokenメタデータ */
export type NftTokenMetadata = {
  item?: Maybe<NftTokenItemMetadata>
}

/**
 * Node
 * Paginationで返す場合はNodeのimplementsが必要
 */
export type Node = {
  id: Scalars['ID']
}

/** お知らせ情報 */
export type Notification = Node & {
  /** お知らせ内容 */
  body: Scalars['String']
  /** 通知日時 */
  date?: Maybe<Scalars['Date']>
  formattedDate?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** リンク先 */
  link: Scalars['String']
  title: Scalars['String']
}

/** 通知チャネル:email, pushなどのオンオフフラグをここに追加する */
export type NotificationChannels = {
  email?: Maybe<Scalars['Boolean']>
  push?: Maybe<Scalars['Boolean']>
}

/** 通知チャネル種別 */
export enum NotificationChannelsType {
  /** メール通知 */
  Email = 'email',
  /** プッシュ通知 */
  Push = 'push',
}

export type NotificationSettings = {
  announcement?: Maybe<AnnouncementSettings>
  campaign?: Maybe<CampaignSettings>
  gacha?: Maybe<GachaSettings>
  liveStream?: Maybe<LiveStreamSettings>
  mission?: Maybe<MissionSettings>
  post?: Maybe<PostSettings>
  threadChannel?: Maybe<ThreadChannelSettings>
  trade?: Maybe<TradeSettings>
}

/** 通知設定種別 */
export enum NotificationSettingsType {
  /** お知らせの運営からのお知らせ */
  AnnouncementOfficial = 'announcement_official',
  /** フォロー中アーティストのキャンペーン終了 */
  CampaignEndCampaign = 'campaign_endCampaign',
  /** フォロー中アーティストのキャンペーン開始 */
  CampaignStartCampaign = 'campaign_startCampaign',
  /** Upcoming expiry */
  GachaUpcomingExpiry = 'gacha_upcomingExpiry',
  /** フォロー中アーティストのLive配信開始 */
  LiveStreamStartLiveStream = 'liveStream_startLiveStream',
  /** 新規ミッション */
  MissionNewMission = 'mission_newMission',
  /** ミッションコメントへの返信 */
  MissionReplyCommentMission = 'mission_replyCommentMission',
  /** 新規ポスト通知 */
  PostNewPost = 'post_newPost',
  /** ポスト返信通知 */
  PostReplyPost = 'post_replyPost',
  /** 作成スレッドへのコメント */
  ThreadChannelCommentThread = 'threadChannel_commentThread',
  /** スレッドコメントへの返信 */
  ThreadChannelReplyCommentThread = 'threadChannel_replyCommentThread',
  /** トレード成立 */
  TradeAgreedTrade = 'trade_agreedTrade',
  /** トレードのコメント */
  TradeCommentTrade = 'trade_commentTrade',
  /** トレード不成立 */
  TradeDeclinedTrade = 'trade_declinedTrade',
  /** トレード申請 */
  TradeRequestTrade = 'trade_requestTrade',
}

/** 認証トークンのペア */
export type OauthTokenPair = {
  oauthToken?: Maybe<Scalars['String']>
  oauthTokenSecret?: Maybe<Scalars['String']>
}

/** ボーナス獲得インプット */
export type ObtainLoginBonusInput = {
  /**
   * ボーナスのKey名
   * 例：1, 2, 3,... (1日目、2日目、3日目...)
   */
  bonusKey: Scalars['String']
  loginBonusId: Scalars['String']
}

export type Ogp = {
  rectUri?: Maybe<Scalars['String']>
  uri?: Maybe<Scalars['String']>
}

/** 並び順の指定 */
export enum OrderBy {
  Asc = 'asc',
  Desc = 'desc',
}

/** 注文履歴 */
export type OrderHistory = {
  itemOrders: PageResult
  totalSales: Scalars['Int']
}

/** 複数のアーティストをまとめる組織（事務所など） */
export type Organization = {
  /** 所属しているアーティストID配列 */
  artistIds?: Maybe<Array<Scalars['String']>>
  /** Organizationのバナー情報配列 */
  banners?: Maybe<Array<OrganizationBanner>>
  /**
   * Organizationの開催中キャンペーン一覧
   * Node: Campaign
   * @auth: user
   * @deprecated use campains()
   */
  campaigns: PageResult
  createdAt: Scalars['Date']
  /**
   * エントリー中キャンペーンのランキング
   * 1アーティストのみエントリーの場合はユーザーランキング
   * 複数アーティストエントリーの場合はアーティストでログイン中ユーザーのポイントが一番多いアーティストランキング
   * idを指定した場合はそのIDで処理される。なければログインユーザーのuserIdでデータが返される
   * @auth: user
   */
  entryCampaignRankings?: Maybe<Array<Maybe<EntryCampaignRanking>>>
  /** Organizationの手数料関連 */
  fees?: Maybe<OrganizationFees>
  /**
   * Organizationに所属しているフォローアーティストのガチャ一覧
   * 注意：FirestoreのINクエリ最大10制限のため、10を超えてフォローしている場合にすべてが出ない
   * 今後、Algolia等で代用する可能性がある
   * Node: Gacha
   * @auth: user
   */
  followArtistGachas: PageResult
  /**
   * Organizationに所属しているフォローアーティストのミッション一覧
   * 注意：FirestoreのINクエリ最大10制限のため、10を超えてフォローしている場合にすべてが出ない
   * 今後、Algolia等で代用する可能性がある
   * Node: Mission
   * @auth: user
   */
  followArtistMissions: PageResult
  id: Scalars['ID']
  /** 所属しているマネージャーID配列 */
  managerIds?: Maybe<Array<Scalars['String']>>
  /**
   * Organizationに所属しているアーティストのミッション一覧
   * Node: Mision
   * @auth: user
   */
  missions: PageResult
  /** 有効にするModule */
  modules?: Maybe<OrganizationModuels>
  /** Organization名 */
  name: Scalars['String']
  /**
   * Organization用の通知詳細
   * ドキュメントの場所はusers/{uid}/organizations/{orgId}/notifications
   * @auth: user
   */
  notification: Notification
  /**
   * Organization用の通知一覧
   * ドキュメントの場所はusers/{uid}/organizations/{orgId}/notifications
   * @auth: user
   */
  notifications: PageResult
  /** プリペイドポイント */
  prepaidPoint?: Maybe<PrepaidPoint>
  /** アプリのグローバルトップに表示するアーティストID */
  topDisplayArtistIds?: Maybe<Array<Scalars['String']>>
  /**
   * トレードに必要なポイント数
   * 無料の場合はnullまたは0
   */
  tradePoint?: Maybe<Scalars['Int']>
  updatedAt: Scalars['Date']
}

/** 複数のアーティストをまとめる組織（事務所など） */
export type OrganizationCampaignsArgs = {
  pageInput?: Maybe<PageInput>
}

/** 複数のアーティストをまとめる組織（事務所など） */
export type OrganizationEntryCampaignRankingsArgs = {
  id?: Maybe<Scalars['ID']>
}

/** 複数のアーティストをまとめる組織（事務所など） */
export type OrganizationFollowArtistGachasArgs = {
  pageInput?: Maybe<PageInputDate>
}

/** 複数のアーティストをまとめる組織（事務所など） */
export type OrganizationFollowArtistMissionsArgs = {
  pageInput?: Maybe<PageInput>
  typeFilter?: Maybe<Array<MissionType>>
}

/** 複数のアーティストをまとめる組織（事務所など） */
export type OrganizationMissionsArgs = {
  pageInput?: Maybe<PageInput>
  typeFilter?: Maybe<Array<MissionType>>
}

/** 複数のアーティストをまとめる組織（事務所など） */
export type OrganizationNotificationArgs = {
  id: Scalars['ID']
}

/** 複数のアーティストをまとめる組織（事務所など） */
export type OrganizationNotificationsArgs = {
  pageInput?: Maybe<PageInputDate>
}

/** Organization Banner */
export type OrganizationBanner = {
  /** CLICK時に開くリンク */
  link?: Maybe<BannerLink>
  /** 表示順 */
  order: Scalars['Int']
  /** Banner Text */
  text: Scalars['String']
  /** Banner Thumbanil URI */
  thumbnail: Image
}

/** Organization単位の手数料関連 */
export type OrganizationFees = {
  /** 配送アイテムの配送料 */
  shipping?: Maybe<Scalars['Int']>
}

/** RarityIcon link URL input */
export type OrganizationGachaRarityIconsInput = {
  commonPath?: Maybe<Scalars['String']>
  limitedPath?: Maybe<Scalars['String']>
  rarePath?: Maybe<Scalars['String']>
  superrarePath?: Maybe<Scalars['String']>
  ultrararePath?: Maybe<Scalars['String']>
}

/** ガチャチケット画像 */
export type OrganizationGachaTicketInput = {
  rewardPath?: Maybe<Scalars['String']>
  standardPath?: Maybe<Scalars['String']>
}

/** Organizationで有効にするモジュール設定 */
export type OrganizationModuels = {
  /** 複数ガチャ */
  multiGacha: Scalars['Boolean']
  /** アイテムトレード */
  trade: Scalars['Boolean']
}

/** Organizationで有効にする設定Input */
export type OrganizationModulesInput = {
  /** プリペイドポイント */
  prepaidPoint: Scalars['Boolean']
  /** アイテムトレード */
  trade: Scalars['Boolean']
}

/** Organization通知 */
export type OrganizationNotification = Node & {
  artist?: Maybe<Artist>
  artistId?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
  createdAt: Scalars['Date']
  formattedCreatedAt?: Maybe<Scalars['String']>
  formattedPublishedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  link?: Maybe<Scalars['String']>
  publishedAt?: Maybe<Scalars['Date']>
  status: OrganizationNotificationStatus
  title: Scalars['String']
  type: Scalars['String']
}

/** Organization notification status */
export enum OrganizationNotificationStatus {
  /** 削除状態 */
  Archived = 'archived',
  /** 公開 */
  Published = 'published',
  /** 予約済み */
  Scheduled = 'scheduled',
}

/**
 * ページングの情報
 *
 * hasNextPage 指定した数量より少ない場合にfalseを返す
 *
 * ※prevは未実装です。
 */
export type PageInfo = {
  endCursor?: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hits: Scalars['Int']
}

/** ページ入力 */
export type PageInput = {
  cursor?: Maybe<Scalars['String']>
  limit?: Maybe<Scalars['Int']>
}

/**
 * ページングの情報を渡す
 *
 * cursorはデフォルトでcreatedAt descを利用している。
 * cursorがDateの場合、timestampを渡してください。
 */
export type PageInputDate = {
  cursor?: Maybe<Scalars['Date']>
  limit?: Maybe<Scalars['Int']>
}

/** PaginationのResult */
export type PageResult = {
  /** edge一覧 */
  edges: Array<Edge>
  /** ページング情報。取得した最後のCursorや次のページが存在するかが含まれる */
  pageInfo: PageInfo
}

/** 外部から連携されるパートナークライアント情報 */
export type PartnerClient = {
  /** client名 */
  clientName: Scalars['String']
  /** clientToken */
  clientToken: Scalars['String']
  /** 作成日時 */
  createdAt: Scalars['Date']
  /** id (=clientId) */
  id: Scalars['ID']
  /** Organiaztion ID */
  organizationId: Scalars['String']
  /** ステータス */
  status: PartnerClientStatus
}

/** パートナークライアントステータス */
export enum PartnerClientStatus {
  /** 有効 */
  Active = 'active',
  /** 無効 */
  Inactive = 'inactive',
}

/** パートナークライアントトークン */
export type PartnerClientToken = {
  /** 作成日時 */
  createdAt: Scalars['Date']
  /** 有効期限（3分間） */
  expiredAt: Scalars['Date']
  /** ID */
  id: Scalars['ID']
  /** 使用済みかどうか */
  isUsed: Scalars['Boolean']
  /** ワンタイムトークン */
  token: Scalars['String']
}

/**
 * パトロン
 * メンバーシップに加入しているユーザーの購読情報
 */
export type Patron = Node & {
  amount: Scalars['Int']
  artist: Artist
  artistId: Scalars['String']
  /** 継続購読をキャンセルている状態かどうか */
  cancelAtPeriodEnd: Scalars['Boolean']
  continueMonth?: Maybe<Scalars['Int']>
  createdAt: Scalars['Date']
  /** 次の更新でプランをダウングレードするかどうか */
  downgradeAtNextPeriod?: Maybe<Scalars['Boolean']>
  expiredAt: Scalars['Date']
  formattedCreatedAt?: Maybe<Scalars['String']>
  formattedExpiredAt?: Maybe<Scalars['String']>
  formattedUpdatedAt?: Maybe<Scalars['String']>
  /** IAP関連の補足情報 */
  iapSubscription?: Maybe<IapSubscriptionInfo>
  id: Scalars['ID']
  isFollower: Scalars['Boolean']
  /** 個人情報の登録が必要かどうか */
  isPersonalInformationRequired?: Maybe<Scalars['Boolean']>
  /** 支払い方法 */
  paymentMethod?: Maybe<PaymentMethod>
  /** そのアーティストの商品の購入回数 */
  purchaseCount?: Maybe<Scalars['Int']>
  /** ユーザーの購読プラン情報 */
  subscription?: Maybe<PatronSubsription>
  /** そのアーティストのチケットの未使用数 */
  ticketUnusedCount?: Maybe<Scalars['Int']>
  type: PatronType
  /** ユーザーID */
  uid: Scalars['String']
  updatedAt: Scalars['Date']
  user: User
}

/** 購読しているメンバーシップのプラン内容 */
export type PatronSubsription = {
  amount: Scalars['Int']
  content: Scalars['String']
  /**
   * 次回更新時にダウングレード予定のプランID
   * downgradeAtNextPeriod: trueの場合に設定される。それ以外はnull
   */
  downgradePlanId?: Maybe<Scalars['String']>
  planId?: Maybe<Scalars['String']>
  status: Scalars['String']
  subscriptionId?: Maybe<Scalars['String']>
  title: Scalars['String']
}

/**
 * メンバーシップ会員の決済方法
 * stripe クレジットカードプラットフォーム利用
 * iap アプリ内課金
 */
export enum PatronType {
  Iap = 'iap',
  Stripe = 'stripe',
}

/** パトロン一覧取得時の並び順指定 */
export type PatronsFilters = {
  createdOrderBy?: Maybe<OrderBy>
  /** プランID */
  planIds?: Maybe<Array<Scalars['String']>>
}

/** 先行販売抽選結果の支払いインプット */
export type PayPreSaleLotteryInput = {
  /** 先行販売ID */
  preSaleId: Scalars['ID']
  /**
   * 合計購入金額
   * 手数料関連がある場合、その額も足してください
   */
  price: Scalars['Int']
}

/** 決済用ロックレスポンス */
export type PaymentLockResponse = {
  /** 作成日 */
  createdAt: Scalars['Date']
  /** 期限日 */
  expiredAt: Scalars['Date']
  /** 成功した場合にtrue */
  success: Scalars['Boolean']
}

/** 支払い方法 */
export enum PaymentMethod {
  /** クレジットカード */
  CreditCard = 'credit_card',
  /** コンビニ支払い */
  Konbini = 'konbini',
}

/**
 * 決済方法の情報
 * 現在取得できるのはクレジットカードのみ
 */
export type PaymentSource = CreditCard

/**
 * 入金種別
 * connectの場合はconnect経由で入金
 * billingの場合は手動で入金される
 */
export enum PaymentType {
  Billing = 'billing',
  Connect = 'connect',
}

/** 入金申請情報 */
export type Payout = Node & {
  artistId: Scalars['String']
  createdAt: Scalars['Date']
  iap?: Maybe<PayoutItem>
  id: Scalars['ID']
  prepaidPoint?: Maybe<PayoutItem>
  status: PayoutStatus
  stripe?: Maybe<PayoutItem>
}

/** 入金詳細情報 */
export type PayoutItem = {
  amount: Scalars['Int']
  fee: Scalars['Int']
  status: PayoutStatus
}

/** 入金申請ステータス */
export enum PayoutStatus {
  Billed = 'billed',
  Cancelled = 'cancelled',
  Open = 'open',
  Paid = 'paid',
}

/**
 * 決済種別
 * Webはstripe / アプリはiap / プリペイドポイントはprepaidPoint
 */
export enum PayoutType {
  Iap = 'iap',
  PrepaidPoint = 'prepaidPoint',
  Stripe = 'stripe',
}

/** 購入保留状態の情報 */
export type PendingPurchaseItem = Node & {
  /** アーティスト */
  artist: Artist
  /** アーティストID */
  artistId: Scalars['String']
  /** カート決済履歴 */
  cartTransactionOrder?: Maybe<UserCartTransactionOrder>
  /** カート決済ID */
  cartTransactionOrderId?: Maybe<Scalars['String']>
  /** 手数料 */
  checkoutFees?: Maybe<CheckoutFees>
  /** 作成日 */
  createdAt: Scalars['Date']
  /** 有効期限 */
  expiredAt: Scalars['Date']
  formattedExpiredAt?: Maybe<Scalars['String']>
  formattedTotalPrice?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** 購入アイテムデータ */
  items?: Maybe<Array<PendingPurchaseItemSku>>
  /** Metadata */
  metadata?: Maybe<PendingPurchaseItemMetadata>
  /** この支払で購入する数 */
  purchaseNumber?: Maybe<Scalars['Int']>
  /** 支払い状況ステータス */
  status: PendingPurchaseItemStatus
  totalPrice: Scalars['Int']
  /** 支払い手順が記載されたURL(Stripe) */
  voucherUrl?: Maybe<Scalars['String']>
}

/** 購入保留メタデータ */
export type PendingPurchaseItemMetadata = {
  /** Metadata membership */
  membership?: Maybe<PendingPurchaseItemMetadataMembership>
}

/** メンバーシップメタデータ */
export type PendingPurchaseItemMetadataMembership = {
  /** プランID */
  planId?: Maybe<Scalars['String']>
}

/** 購入保留アイテムとSKUの関連情報 */
export type PendingPurchaseItemSku = {
  /** 購入数量 */
  count: Scalars['Int']
  /** アイテム */
  item: Item
  /** ItemID */
  itemId: Scalars['String']
  /** SKU情報 */
  skus: Array<PendingPurchaseItemSkuUnit>
}

/** 購入保留SKU */
export type PendingPurchaseItemSkuUnit = {
  /** 購入予定数 */
  count: Scalars['Int']
  /** SKU情報 */
  sku: SkuUnit
  /** SKU ID */
  skuId: Scalars['String']
}

/** 仮購入アイテムステータス */
export enum PendingPurchaseItemStatus {
  /** 支払い完了 */
  Completed = 'completed',
  /** 支払い失敗（期限切れ） */
  Failed = 'failed',
  /** 支払い待ち */
  WaitingPayment = 'waiting_payment',
}

/**
 * サブスクリプションのコンビニ支払い時の戻り
 * すでにメンバーシップになっている状態だった場合はPatronが返される
 */
export type PendingSubscription = Patron | PendingPurchaseItem

/** 個人情報のタイプ */
export enum PersonalInformationType {
  /** 住所 */
  Address = 'address',
  /** 生年月日 */
  Birthday = 'birthday',
  /** 氏名 */
  FullName = 'full_name',
  /** 性別 */
  Gender = 'gender',
  /** 電話番号 */
  PhoneNumber = 'phone_number',
}

/** SMS認証情報 */
export type PhoneNumberVerification = {
  id: Scalars['ID']
  phoneNumber?: Maybe<Scalars['String']>
  verificationCode?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
}

export type PickupOrder = {
  count: Scalars['Int']
  itemId: Scalars['String']
  serialIds: Array<Maybe<Scalars['String']>>
  serials: Array<ItemSerial>
  title: Scalars['String']
}

export type PickupRemainingTime = {
  expiring: Scalars['Boolean']
  hours?: Maybe<Scalars['Int']>
  minutes: Scalars['Int']
}

/** チケットアイテムの受け取りステータス（アーティスト用） */
export enum PickupTicketStatus {
  /** すべて */
  All = 'all',
  /** 未使用 */
  Unused = 'unused',
  /** 使用済み */
  Used = 'used',
}

/** 受け取り時間インプット */
export type PickupTimeInput = {
  /** コレクションID */
  collectionId: Scalars['String']
  /** 受け取り時間 */
  pickupTime: Scalars['Date']
}

/** 受け取り時間予約アイテム */
export type PickupTimeItem = {
  /** 受け取り完了数 */
  completedCount: Scalars['Int']
  /** 予約数合計（支払い待ち含む） */
  count: Scalars['Int']
  /** アイテム */
  item: Item
  /** アイテムID */
  itemId: Scalars['String']
  /** 支払い待ち数（仮確保数） */
  pendingCount: Scalars['Int']
  sku?: Maybe<PickupTimeItemSku>
  /** SKU別データ */
  skus?: Maybe<Array<PickupTimeItemSku>>
}

/** 受け取り時間SKU別データ */
export type PickupTimeItemSku = {
  /** SKU別の受け取り完了数 */
  completedCount: Scalars['Int']
  /** SKU別の合計数（支払い待ち含む） */
  count: Scalars['Int']
  /** SKU別の支払い待ち数（仮確保数） */
  pendingCount: Scalars['Int']
  /** SKU */
  sku: SkuUnit
  /** SKU ID */
  skuId: Scalars['String']
}

/** 受け取り時間別予約アイテム数集計 */
export type PickupTimeItemsAggregation = {
  /** 予約アイテム数集計 */
  items?: Maybe<Array<PickupTimeItem>>
  /** 受け取り時間 */
  pickupTime: Scalars['Date']
}

/** 受け取りユーザー */
export type PickupUser = Node & {
  actualTotalAmount: Scalars['Int']
  /** カート決済履歴配列 */
  cartTransactionOrderIds: Array<Scalars['String']>
  /** カート決済履歴ID */
  cartTransactionOrders: Array<UserCartTransactionOrder>
  /** コレクション */
  collection: Collection
  /** コレクションID */
  collectionId: Scalars['String']
  /** 受け取り完了日時 */
  completedAt?: Maybe<Scalars['Date']>
  /** 作成日 */
  createdAt: Scalars['Date']
  /** イベント */
  event: Event
  /** イベントコレクション */
  eventCollection: EventCollection
  /** イベントID */
  eventId: Scalars['String']
  formattedActualTotalAmount: Scalars['String']
  formattedCompletedAt?: Maybe<Scalars['String']>
  formattedPickupStartEndTime?: Maybe<Scalars['String']>
  formattedPickupTime?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
  /** 受け取り完了済みかどうか */
  isCompleted?: Maybe<Scalars['Boolean']>
  isExpired?: Maybe<Scalars['Boolean']>
  /** 受取ペンディング状態かどうか（コンビニ支払い待ちなど） */
  isPending?: Maybe<Scalars['Boolean']>
  itemsString?: Maybe<Scalars['String']>
  pickupOrder: Array<PickupOrder>
  pickupRemainingTime?: Maybe<PickupRemainingTime>
  /** 指定した受け取り日時 */
  pickupTime?: Maybe<Scalars['Date']>
  ticketItemSerialsInput: Array<TicketItemSerials>
  /** 更新日 */
  updatedAt: Scalars['Date']
  /** ユーザー */
  user: User
  /** ユーザーID */
  userId: Scalars['String']
  /** 受け取るItemID */
  userItemIds: Array<Scalars['String']>
  /** 受け取りアイテム */
  userItems: Array<UserItem>
}

/** イベント受け取り予約集計データ */
export type PickupUserAggregation = {
  /** 集計する日付 */
  date: Scalars['Date']
  /** イベントID */
  eventId: Scalars['ID']
  /** 集計データ */
  pickupUserCollections: Array<PickupUserCollection>
}

/** コレクション別の受取予約集計 */
export type PickupUserCollection = {
  /** コレクションID */
  collectionId: Scalars['ID']
  /** 受け取りユーザー数 */
  pickupUsersCount: Array<PickupUsersCount>
}

/** ユーザーの受け取り一覧検索オプション */
export type PickupUsersByEventOptions = {
  /** 過去の受け取りかどうか */
  isHistory?: Maybe<Scalars['Boolean']>
  /** 受け取り状況 */
  type?: Maybe<UserPickupTicketStatus>
}

/** 受け取りユーザー数 */
export type PickupUsersCount = {
  /** 受け取り日時 */
  pickupTime: Scalars['Date']
  /** 受け取り済みユーザー数 */
  pickupUserCompleteCount: Scalars['Int']
  /** 受け取りユーザー数 */
  pickupUserCount: Scalars['Int']
}

/** UserItems検索時オプショナルインプット */
export type PortfoliosOptionInput = {
  /** リセール有効かどうか */
  resaleEnabled?: Maybe<Scalars['Boolean']>
}

/** ポスト */
export type Post = Node & {
  artist: Artist
  artistId: Scalars['String']
  /** ポストカテゴリー詳細 */
  category?: Maybe<PostCategoryDetail>
  /** ポストカテゴリーのID */
  categoryId?: Maybe<Scalars['String']>
  content: Scalars['String']
  createdAt: Scalars['Date']
  formattedCreatedAt?: Maybe<Scalars['String']>
  formattedPublishedAt?: Maybe<Scalars['String']>
  formattedRelativePublishedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** 画像サムネイル */
  imageThumbnail?: Maybe<PostImageThumbnail>
  /**
   * ポスト表示で適切な権限を持っているか
   * メンバーシップ向けポストで、適切なプランに加入していない場合はfalseが返される
   */
  isAccessible: Scalars['Boolean']
  /** 認証ユーザーがいいねしたか */
  isLike: Scalars['Boolean']
  /** メンバーシップ限定公開かどうか */
  isPremium: Scalars['Boolean']
  /** いいね数 */
  likeCount: Scalars['Int']
  /** ポストを閲覧できるメンバーシップのプランID */
  planId?: Maybe<Scalars['String']>
  planTitle?: Maybe<Scalars['String']>
  /** ポスト公開日時 */
  publishedAt?: Maybe<Scalars['Date']>
  /**
   * 返信一覧
   * Node: PostReply
   */
  replies: PageResult
  /** 返信数 */
  replyCount: Scalars['Int']
  /** ポストリソース */
  resource?: Maybe<PostResource>
  /** ポストリソース種別 */
  resourceType: PostType
  /** ポスト画像リソース(複数) */
  resources?: Maybe<Array<PostImageResource>>
  /** ポストのステータス */
  status?: Maybe<PostStatus>
  /** 動画サムネイル */
  videoThumbnail?: Maybe<PostVideoThumbnail>
}

/** ポスト */
export type PostRepliesArgs = {
  pageInput?: Maybe<PageInput>
}

/** ポストカテゴリー */
export type PostCategory = {
  /** アーティスト */
  artistId: Scalars['String']
  /** カテゴリー一覧 */
  categories: Array<PostCategoryDetail>
  /** 作成日 */
  createdAt: Scalars['Date']
  /** ID */
  id: Scalars['ID']
  /** 更新日 */
  updatedAt: Scalars['Date']
}

/** ポストカテゴリー */
export type PostCategoryDetail = {
  /** 作成日 */
  createdAt: Scalars['Date']
  /** カテゴリー説明 */
  description: Scalars['String']
  /**
   * カテゴリーID
   * {PostCategoryId}:{randomId}の形式になっている
   */
  id: Scalars['String']
  /** カテゴリー表示名 */
  name: Scalars['String']
  /** 更新日 */
  updatedAt: Scalars['Date']
}

/** ポスト画像リソース情報 */
export type PostImageResource = {
  /** 通常画面表示用の中サイズURI(画像) */
  mediumUri?: Maybe<Scalars['String']>
  /** サムネイル等表示用の小サイズURI(画像) */
  smallUri?: Maybe<Scalars['String']>
  uri?: Maybe<Scalars['String']>
}

/** ポスト画像のサムネイル */
export type PostImageThumbnail = {
  /** ぼかしURI */
  blurUri?: Maybe<Scalars['String']>
}

/** ポストへのリプライ */
export type PostReply = Node & {
  content: Scalars['String']
  createdAt: Scalars['Date']
  formattedCreatedAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** ポスト返信にいいねしたか */
  isLike: Scalars['Boolean']
  /** いいねの数 */
  likeCount?: Maybe<Scalars['Int']>
  /** 返信したポストのID */
  replyToId: Scalars['String']
  /**
   * 返信者アカウント
   * userRefをArtistまたはUser型で返す
   * 対象のユーザーが存在しない場合はnullを返す。
   */
  responder?: Maybe<AccountRecord>
}

/** ポストリソース情報 */
export type PostResource = {
  uri?: Maybe<Scalars['String']>
  /** youtubeのビデオID */
  videoId?: Maybe<Scalars['String']>
}

export type PostSettings = {
  newPost?: Maybe<NotificationChannels>
  replyPost?: Maybe<NotificationChannels>
}

/** ポストのステータス */
export enum PostStatus {
  /** 公開済み */
  Published = 'published',
  /** 予約済み */
  Scheduled = 'scheduled',
}

/** ポスト種別 */
export enum PostType {
  Image = 'image',
  Sound = 'sound',
  Text = 'text',
  Video = 'video',
}

/** ポスト動画のサムネイル */
export type PostVideoThumbnail = {
  /** 動画サムネイルのぼかしURI */
  blurUri?: Maybe<Scalars['String']>
  /** 通常画面表示用の中サイズURI(画像) */
  mediumUri?: Maybe<Scalars['String']>
  /** サムネイル等表示用の小サイズURI(画像) */
  smallUri?: Maybe<Scalars['String']>
  /** 動画サムネイルURI */
  uri?: Maybe<Scalars['String']>
}

/** 先行販売 */
export type PreSale = Node & {
  /** アーティスト */
  artist: Artist
  /** 作成日時 */
  createdAt: Scalars['Date']
  /** 先行販売説明 */
  description: Scalars['String']
  /** 公開終了日時 */
  endedAt: Scalars['Date']
  /** イベント */
  event: Event
  /** イベントID */
  eventId: Scalars['String']
  /** 先着の場合の設定 */
  firstComeFirstServed?: Maybe<PreSaleFirstComeFirstServed>
  formattedEndedAt?: Maybe<Scalars['String']>
  formattedStartedAt?: Maybe<Scalars['String']>
  /** 先行販売ID */
  id: Scalars['ID']
  /**
   * 先行販売で適切な権限を持っているか
   * メンバーシップ向けで、適切なプランに加入していない場合はfalseが返される
   */
  isAccessible: Scalars['Boolean']
  /** メンバーシップ限定公開かどうか */
  isPremium?: Maybe<Scalars['Boolean']>
  /** 公開フラグ */
  isPublished: Scalars['Boolean']
  /** 先着チケットがすべて売り切れかどうか（先着のみ・抽選は常にnull） */
  isSoldOut?: Maybe<Scalars['Boolean']>
  /**
   * チケットアイテムID
   * SKU付きの場合は itemId:skuId
   */
  itemIds: Array<Scalars['String']>
  /** チケットアイテム */
  items: Array<Item>
  /** 抽選の場合の設定 */
  lottery?: Maybe<PreSaleLottery>
  /** 注意文言 */
  note: Scalars['String']
  /** 支払い方法 */
  paymentMethods?: Maybe<Array<PaymentMethod>>
  /**
   * 抽選応募できるメンバーシップのプランID
   * 指定がなければnull
   */
  planId?: Maybe<Scalars['String']>
  preSaleStatus?: Maybe<PreSaleStatus>
  /** 先行販売の集計 */
  preSalesAggregation?: Maybe<PreSalesAggregation>
  /** purchased cart transaction orders */
  purchasedCartTransactionOrders: Array<UserCartTransactionOrder>
  /** 公開開始日時 */
  startedAt: Scalars['Date']
  /** 先行販売タイトル */
  title: Scalars['String']
  /** 先行販売種別 */
  type: PreSaleType
  /** 更新日時 */
  updatedAt: Scalars['Date']
}

/** 先行販売集計 */
export type PreSaleAggregation = {
  /** 購入関連の集計 */
  purchases?: Maybe<PreSaleAggregationPurchases>
  /** チケット利用状況の集計 */
  ticketUsage?: Maybe<PreSaleAggregationTicketUsage>
}

/** 先行販売アイテム集計データ */
export type PreSaleAggregationItem = {
  /** アイテム */
  item: Item
  /** アイテムID */
  itemId: Scalars['String']
  /** チケット単位の未使用数 */
  ticketUnusedCount: Scalars['Int']
  /** チケット単位の使用済数 */
  ticketUsedCount: Scalars['Int']
}

/** 先行販売購入関連の集計 */
export type PreSaleAggregationPurchases = {
  /** チケット在庫数 */
  issuedNumber: Scalars['Int']
  /** チケット購入数量 */
  purchasedNumber: Scalars['Int']
}

/** 先行販売チケットの利用状況 */
export type PreSaleAggregationTicketUsage = {
  /** トータルの未使用数 */
  ticketUnusedCount: Scalars['Int']
  /** トータルの使用済数 */
  ticketUsedCount: Scalars['Int']
}

/** PreSale Applicant */
export type PreSaleApplicantCsv = {
  /** CSV url */
  csvUrl?: Maybe<Scalars['String']>
}

/** 先行販売の先着設定 */
export type PreSaleFirstComeFirstServed = {
  /** applicant aggregation */
  applicantAggregation?: Maybe<PreSaleFirstComeFirstServedAggregation>
  formattedPurchasableEndedAt?: Maybe<Scalars['String']>
  formattedPurchasableStartedAt?: Maybe<Scalars['String']>
  /** 購入時のどの個人情報が必要かどうか */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /** 購入可能最大枚数 */
  purchasableCount: Scalars['Int']
  /** 購入終了日時 */
  purchasableEndedAt: Scalars['Date']
  /** 購入開始日時 */
  purchasableStartedAt: Scalars['Date']
}

export type PreSaleFirstComeFirstServedAggregation = {
  /** credit card */
  creditCard?: Maybe<PreSaleFirstComeFirstServedAggregationCreditCard>
  /** konbini */
  konbini?: Maybe<PreSaleFirstComeFirstServedAggregationKonbini>
}

export type PreSaleFirstComeFirstServedAggregationCreditCard = {
  /** applied count */
  appliedCount: Scalars['Int']
  /** purchased count */
  purchasedCount: Scalars['Int']
}

export type PreSaleFirstComeFirstServedAggregationKonbini = {
  /** applied count */
  appliedCount: Scalars['Int']
  /** purchased count */
  purchasedCount: Scalars['Int']
  /** unpaid count */
  unpaidCount: Scalars['Int']
}

/** 先行販売アイテム */
export type PreSaleItem = {
  /** アイテム */
  item: Item
  /** Item ID */
  itemId: Scalars['String']
  /** SKU ID */
  skuId?: Maybe<Scalars['String']>
}

/** 先行販売の抽選設定 */
export type PreSaleLottery = {
  /** applicant aggregation */
  applicantAggregation?: Maybe<PreSaleLotteryAggregation>
  /** 応募終了日時 */
  applicationEndedAt: Scalars['Date']
  /** 応募開始日時 */
  applicationStartedAt: Scalars['Date']
  formattedApplicationEndedAt?: Maybe<Scalars['String']>
  formattedApplicationStartedAt?: Maybe<Scalars['String']>
  formattedPurchasableEndedAt?: Maybe<Scalars['String']>
  formattedPurchasableStartedAt?: Maybe<Scalars['String']>
  formattedResultNoticedAt?: Maybe<Scalars['String']>
  /** 最大応募希望数 */
  maxChoice: Scalars['Int']
  /** 購入時のどの個人情報が必要かどうか */
  personalInformationRequirements?: Maybe<Array<PersonalInformationType>>
  /** 購入可能最大枚数 */
  purchasableCount: Scalars['Int']
  /** 購入終了日時 */
  purchasableEndedAt: Scalars['Date']
  /** 購入開始日時 */
  purchasableStartedAt: Scalars['Date']
  /** 抽選結果通知ステータス */
  resultNoticeStatus: PreSaleLotteryResultNoticeStatus
  /** 抽選結果通知予定日時 */
  resultNoticedAt?: Maybe<Scalars['Date']>
}

export type PreSaleLotteryAggregation = {
  /** credit card */
  creditCard?: Maybe<PreSaleLotteryAggregationCreditCard>
  /** konbini */
  konbini?: Maybe<PreSaleLotteryAggregationKonbini>
}

export type PreSaleLotteryAggregationCreditCard = {
  /** applied count */
  appliedCount: Scalars['Int']
  /** failed count */
  failedCount: Scalars['Int']
  /** purchased count */
  purchasedCount: Scalars['Int']
}

export type PreSaleLotteryAggregationKonbini = {
  /** applied count */
  appliedCount: Scalars['Int']
  /** purchased count */
  purchasedCount: Scalars['Int']
  /** unpaid count */
  unpaidCount: Scalars['Int']
}

/** 先行販売の抽選応募者データ */
export type PreSaleLotteryApplicant = Node & {
  /** 応募日時 */
  appliedAt?: Maybe<Scalars['Date']>
  /** カート決済 */
  cartTransactionOrder?: Maybe<UserCartTransactionOrder>
  /** カート決済ID */
  cartTransactionOrderId?: Maybe<Scalars['String']>
  /**
   * 応募希望アイテムの詳細
   * 手数料などすべて計算済みのものを返す
   */
  choiceItemDetails: Array<ChoiceItemDetail>
  /** 応募希望アイテム */
  choiceItems: Array<Item>
  /**
   * 応募希望アイテムID
   * 第1希望、第2希望...と配列で指定する
   */
  choices: Array<Scalars['String']>
  formattedAppliedAt?: Maybe<Scalars['String']>
  /** ID(=ユーザーIDと同じ) */
  id: Scalars['ID']
  /**
   * 支払でエラーがあった場合にtrueとなる
   * 決済成功した時点でfalseが入る
   */
  isPaymentError?: Maybe<Scalars['Boolean']>
  /** 支払い方法 */
  paymentMethod: PaymentMethod
  /** 先行販売情報 */
  preSale: PreSale
  /** 先行販売ID */
  preSaleId: Scalars['String']
  /**
   * 応募希望アイテム数量
   * choicesの配列インデックスにそれぞれ対応する
   */
  purchaseCountChoices: Array<Scalars['Int']>
  /** 抽選結果送信日時 */
  resultNoticedAt?: Maybe<Scalars['Date']>
  /**
   * ステータス
   * ステータスで順番指定するため数字始まりにしているが、GQLのenumにできないのでString扱い
   * | '10_win' // 当選
   * | '20_waiting_payment' // 支払い待ち(コンビニ決済)
   * | '30_waiting_lottery' // 抽選待ち
   * | '40_lose' // 落選
   * | '50_invalid' // 無効（支払い期限切れ）
   * ただし、当選・落選状態でも resultNoticedAt がnullの場合はまだ当選結果が通知されていない状態のため、強制的に30_waiting_lotteryとして返す
   */
  status: Scalars['String']
  /** 応募ユーザー */
  user: User
  /** ユーザーID */
  userId: Scalars['String']
  /** 当選した場合、第何希望が当選したか。1始まり。ex) 第2希望が当選の場合、2が入る */
  winChoiceNumber?: Maybe<Scalars['Int']>
}

/** 抽選応募状況の集計データ */
export type PreSaleLotteryApplicantAggregation = {
  /** 集計結果 */
  aggregationResult?: Maybe<Array<PreSaleLotteryApplicantAggregationResult>>
  /** 全応募者ユニーク数 */
  allApplicantsCount: Scalars['Int']
  /** クレジットカードを選択した応募者ユニーク数 */
  creditCardApplicantsCount: Scalars['Int']
  /** コンビニ決済を選択した応募者ユニーク数 */
  konbiniApplicantsCount: Scalars['Int']
  /** 先行販売 */
  preSale: PreSale
  /** 先行販売ID */
  preSaleId: Scalars['String']
}

/** 抽選販売応募集計結果 */
export type PreSaleLotteryApplicantAggregationResult = {
  /** 第1希望〜第3希望に分離した応募総枚数 */
  choices?: Maybe<Array<PreSaleLotteryApplicantAggregationResultChoice>>
  /** アイテム */
  item: Item
  /** アイテムID */
  itemId: Scalars['String']
  /** 応募総枚数 */
  totalItemCount: Scalars['Int']
}

/** 抽選販売応募集計結果の選択肢別集計 */
export type PreSaleLotteryApplicantAggregationResultChoice = {
  /** 応募されたチケット総数 */
  totalItemCount: Scalars['Int']
}

/** PreSale lottery applicant filter inputs */
export type PreSaleLotteryApplicantFilterInputs = {
  choiceNumber?: Maybe<Scalars['Int']>
  choices?: Maybe<Array<Scalars['String']>>
  status?: Maybe<Array<Scalars['String']>>
}

/** 抽選結果通知ステータス */
export enum PreSaleLotteryResultNoticeStatus {
  /** 通知済み */
  Done = 'done',
  /** 通知エラー */
  Error = 'error',
  /** 設定なし */
  None = 'none',
  /** 処理中 */
  Processing = 'processing',
  /** 当選支払準備OK */
  Ready = 'ready',
  /** 通知予定準備OK */
  ReadyForNotice = 'ready_for_notice',
}

export enum PreSaleStatus {
  Accepting = 'accepting',
  BeforeReceiption = 'beforeReceiption',
  Expired = 'expired',
  SoldOut = 'soldOut',
}

/** 先行販売種別 */
export enum PreSaleType {
  FirstComeFirstServed = 'first_come_first_served',
  Lottery = 'lottery',
}

/** 先行販売の集計データ */
export type PreSalesAggregation = {
  /** 先行販売集計 */
  aggregation?: Maybe<PreSaleAggregation>
  /** 先行販売アイテム別データ */
  preSaleItems?: Maybe<Array<PreSaleAggregationItem>>
}

/** プリペイドポイント情報 */
export type PrepaidPoint = {
  exchangeRate: Scalars['Float']
  id: Scalars['ID']
  organizationId?: Maybe<Scalars['String']>
  pointName: Scalars['String']
}

/** プリペイドポイント残高 */
export type PrepaidPointBalance = {
  /** 残高 */
  balance: Scalars['Int']
  formattedPrepaidPointBarance?: Maybe<Scalars['String']>
  /** 残高の最終更新日（最終TransactionのcreatedAtと同じ） */
  lastUpdatedAt?: Maybe<Scalars['Date']>
  /** プリペイドポイントID */
  prepaidPointId: Scalars['ID']
}

/** プリペイドポイントトランザクションデータ */
export type PrepaidPointTransaction = Node & {
  /** トランザクション実行日時 */
  createdAt: Scalars['Date']
  /** 期限切れポイント */
  expiredPrepaidPoint: Scalars['Int']
  formattedCreatedAt?: Maybe<Scalars['String']>
  formattedTransactionPrepaidPoint: Scalars['String']
  /** トランザクションID */
  id: Scalars['ID']
  /** 現在の最新残高 */
  prepaidPointBalance: Scalars['Int']
  /** 前回のトランザクションまでの残高 */
  prepaidPointBalanceBefore: Scalars['Int']
  /** プリペイドポイントID */
  prepaidPointId: Scalars['String']
  /** 今回の取引ポイント数（マイナスの場合は消費） */
  transactionPrepaidPoint: Scalars['Int']
  /** トランザクション種別 */
  type: Scalars['String']
}

/** メンバーシッププラン変更後の差額プレビュー */
export type PreviewSubscriptionUpdate = {
  /** 調整差額（更新時に支払う額） */
  diffAmount: Scalars['Int']
  /** 変更後のPlanId */
  planId: Scalars['String']
}

/** コンビニプリント利用状況 */
export type PrintQrUsage = {
  /** QR生成数 */
  qrGeneratedCount: Scalars['Int']
  /** QR未生成数 */
  qrNotGeneratedCount: Scalars['Int']
}

export type PrivacySettings = {
  item?: Maybe<Scalars['Boolean']>
}

/** プライバシー設定種別 */
export enum PrivacySettingsType {
  /** アイテムの非公開 */
  Item = 'item',
}

/** プロテクトインプット */
export type ProtectUserItemInput = {
  /** itemId(=userItemId) */
  id: Scalars['String']
  /** プロテクトする場合はtrue、解除する場合はfalse */
  isUserProtected: Scalars['Boolean']
}

/**
 * ユーザーが保有する連携アプリ情報の種類
 * 現在はTwitterAPI連携のみ
 */
export enum ProviderType {
  Twitter = 'twitter',
}

export type Providers = {
  twitter?: Maybe<OauthTokenPair>
}

/** 購入可能なガチャチケット情報 */
export type PurchasableGachaTicket = {
  /** 購入可能枚数 */
  count: Scalars['Int']
  /** 表示用のカウント (10+1などがあるため) */
  displayCount: Scalars['String']
  formattedPrepaidPoint?: Maybe<Scalars['String']>
  formattedPrice?: Maybe<Scalars['String']>
  /** ガチャチケットID */
  id: Scalars['ID']
  /** 購入に必要なプリペイドポイント数 */
  prepaidPoints?: Maybe<Scalars['Int']>
  /** 購入に必要な価格 */
  price: Scalars['Int']
}

/** 購入可能ガチャチケットインプット */
export type PurchasableGachaTicketInput = {
  artistId?: Maybe<Scalars['String']>
}

/** 購入用のプリペイドポイントIDリスト */
export type PurchasablePrepaidPoint = Node & {
  /** ポイントの説明 */
  description: Scalars['String']
  id: Scalars['ID']
  /** メタデータ */
  metadata: PurchasablePrepaidPointMetadata
  /** ポイント数 */
  points: Scalars['Int']
  /** プリペイドポイントID(parentId) */
  prepaidPointId: Scalars['String']
  /** 購入に必要な価格 */
  price: Scalars['Int']
}

export type PurchasablePrepaidPointInput = {
  description?: Maybe<Scalars['String']>
  platform: Scalars['String']
  points: Scalars['Int']
  price: Scalars['Int']
  /**
   * プロダクトIDの後ろに着ける文字列。指定がなければランダム文字列が付与される。
   * 例: point_ios_UTNQ_[suffix]
   */
  productIdSuffix?: Maybe<Scalars['String']>
}

/** 購入用プリペイドポイントIDのメタデータ */
export type PurchasablePrepaidPointMetadata = {
  /** プロダクトのID（決済プラットフォームにより異なる） */
  id: Scalars['String']
  /** 決済プラットフォーム（web or ios or android) */
  platform: Scalars['String']
}

/** Purchasable User */
export type PurchasableUser = {
  /** Purchasable Count */
  purchasableCount: Scalars['Int']
  /** Purchased Count */
  purchasedCount: Scalars['Int']
  /** User */
  user: User
  /** User ID */
  userId: Scalars['String']
}

/** ガチャチケットまとめて購入Input */
export type PurchaseGachaTicketInBulkInput = {
  /** 料金 */
  price: Scalars['Int']
}

/** ガチャチケット購入Input */
export type PurchaseGachaTicketInput = {
  /** 購入枚数 */
  count: Scalars['Int']
  /** 料金 */
  price: Scalars['Int']
}

/** ポイントでガチャチケまとめて購入Input */
export type PurchaseGachaTicketPointInBulkInput = {
  /** ポイント数 */
  point: Scalars['Int']
}

/** ポイントでガチャチケ購入Input */
export type PurchaseGachaTicketPointInput = {
  /** 購入枚数 */
  count: Scalars['Int']
  /** ポイント数 */
  point: Scalars['Int']
}

/** 今すぐ購入インプット */
export type PurchaseItemImmediatelyInput = {
  /** 購入数量 */
  count: Scalars['Int']
  /** コンビニ決済の場合に領収書に記載される氏名を指定する */
  fullName?: Maybe<Scalars['String']>
  /** コンビニ決済の場合にtrueを指定する */
  isKonbini?: Maybe<Scalars['Boolean']>
  /** ItemID */
  itemId: Scalars['ID']
  /** 先行販売ID */
  preSaleId?: Maybe<Scalars['String']>
  /**
   * 合計購入金額
   * 手数料関連がある場合、その額も足してください
   */
  price: Scalars['Int']
  /** SKU ID */
  skuId?: Maybe<Scalars['String']>
}

/** リセールの購入インプット */
export type PurchaseResaleItemInput = {
  /** アイテムID */
  itemId: Scalars['String']
  /** 購入するリセール抽選ID */
  resaleRaffleId: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type Query = {
  /**
   * 先行販売抽選の集計データを取得する
   * @auth: admin
   */
  adminAggregatePreSaleLotteryApplication: PreSaleLotteryApplicantAggregation
  /**
   * Archived comments
   * @auth: admin
   * @params:
   *   id: DocumentID
   *   commentType: | 'trade'
   *                | 'item'
   *                | 'mission'
   *                | 'artist_thread'
   *   pageInput: PageInput
   * Node: Comment
   */
  adminArchivedComments: PageResult
  /**
   * Archived reply list
   * @auth: admin
   * @params:
   *   id: PostのDocumentID
   *   pageInput: PageInput
   * Node: PostReply
   */
  adminArchivedPostReplies: PageResult
  /**
   * Admin collection
   * Node: Collection
   * @auth: admin
   */
  adminCollections: PageResult
  /**
   * Comments
   * @auth: admin
   * @params:
   *   id: DocumentID
   *   commentType: | 'trade'
   *                | 'item'
   *                | 'mission'
   *                | 'artist_thread'
   *   pageInput: PageInput
   * Node: Comment
   */
  adminComments: PageResult
  /**
   * [Admin用] 物販アイテム配送一覧の取得
   * @auth: admin
   * @params:
   *   statusFilter: 取得したいステータス。指定しない場合はすべて取得する
   *                 | 'unapplied' 未申請
   *                 | 'arranging' 手配中
   *                 | 'shipped' 出荷済み
   *   inputs: ArtistIdかItemIdで絞る場合に指定
   *   pageInput: pageINputDate
   */
  adminDeliveryItems: PageResult
  /**
   * Admin用 イベント参加アーティストの売上レポート
   * artistIdを指定するとそのアーティストに絞られる
   * @auth: admin
   */
  adminEventSalesReport: Array<SalesReport>
  /**
   * Admin用イベント一覧の取得（非公開イベントも含まれる）
   * Node: Event
   * @auth: admin
   */
  adminEvents: PageResult
  /**
   * Admin権限で指定したチケットアイテムCSVのダウンロードパスを取得する
   * @auth admin
   */
  adminExportTicketItemCsv: CsvDownload
  /** Admin権限で指定したチケットシリアルCSVのダウンロードパスを取得する */
  adminExportTicketSerialCsv: CsvDownload
  /**
   * ガチャの詳細取得（管理者向け）
   * すべてのキーを抽出可能
   * @auth: admin
   * @params:
   *   id: ガチャ DocumentID
   */
  adminGacha?: Maybe<Gacha>
  /**
   * 有限ガチャのアイテムセットを全て取得する
   * 全ドキュメントが返されるため、DB readは多くなりがちなので注意
   * @auth: admin
   */
  adminGachaLimitedItemSet: Array<GachaLimitedItemSet>
  /**
   * 指定したアーティストIDのガチャチケット一覧の取得
   * @auth: admin
   * @params:
   *   artistId: アーティストID
   *   pageInput: PageInputDate
   */
  adminGachaTickets: PageResult
  /**
   * ガチャ一覧の詳細取得（管理者向け）
   * 公開・非公開関わらずすべて取得する
   * @auth: admin
   * @params:
   *   pageInput: PageInputDate
   */
  adminGachas: PageResult
  /**
   * Admin権限でアイテムを一括で取得する
   * @auth: admin
   */
  adminItems: Array<Item>
  /**
   * Get all organization
   *
   * @auth: admin
   */
  adminOrganizations?: Maybe<Array<Organization>>
  /**
   * Payout(入金情報)一覧を取得する
   * Node: Payout
   * order: createdAt desc
   * cursor: createdAt
   * @auth: admin
   * @params:
   *   pageInput: PageInputDate
   */
  adminPayouts: PageResult
  /** Admin用 イベント受け取り予約の集計データ取得 */
  adminPickupUserAggregationByEvent: PickupUserAggregation
  /**
   * Admin権限でユーザー所有のIteｍを取得する
   * auth: admin
   */
  adminPortfolio: UserItem
  /**
   * 管理者用先行販売一覧の取得
   * 公開・非公開すべて取得
   * Node: PreSale
   * @auth: admin
   */
  adminPreSales: PageResult
  /**
   * Admin権限でリセール振込待ちを取得する
   * termは YYYYMM の形式で入力する（例: 202306 => 2023年6月分のデータが返される）
   * @auth: admin
   */
  adminResaleItemPayments: Array<ResaleItem>
  /**
   * 指定したイベント・座席セクションの座席仮割当てシミュレーション行う
   * 実際にはデータは更新されず、割当てシミュレーションされた結果が返される
   * @auth: admin
   */
  adminSimulateBulkAssignEventSeatFromCsv: CsvDownload
  /**
   * アーティストのスレッドを取得する
   * @auth: admin
   */
  adminThreadChannel?: Maybe<ArtistThreadChannel>
  /**
   * 動画がアーカイブされたライブ配信一覧を取得する
   * @auth: user
   * Node: LiveStream
   */
  archivedLiveStreams: PageResult
  /**
   * 指定したDocumentIDのアーティストを取得する
   * IDを指定しない場合、アーティストがログインしている場合は自分自身の情報が返却される
   * それ以外はIDを指定しないとValidationErrorがthrowされる
   * @auth: none
   * @params:
   *   id: アーティストのDocumentID
   */
  artist?: Maybe<Artist>
  /**
   * イベントコレクションの受け取り時間別予約アイテム数集計を取得する。日付単位
   * @auth: artist
   */
  artistAggregatePickupTimeItems?: Maybe<Array<PickupTimeItemsAggregation>>
  /**
   * 指定したAccount名のアーティストを取得する
   * @auth: none
   * @params:
   *   name: ArtistのaccountId
   */
  artistByName?: Maybe<Artist>
  /**
   * ログインしているアーティスト自身のコレクション一覧
   * @auth: artist
   * @params:
   *   pageInput: PageInputDate
   *   orderBy: 昇順・降順の指定
   */
  artistCollections: PageResult
  /**
   * アーティスト: コメント一覧を取得
   * @auth: artist
   * @params:
   *   pageInput: PageInputDate
   */
  artistComments: PageResult
  /**
   * アーティストの顧客（ユーザー）を取得する
   * @auth: artist
   */
  artistCustomer?: Maybe<ArtistCustomer>
  /**
   * アーティストの顧客（ユーザー）一覧を取得する
   * Node: ArtistCustomer
   * @auth: artist
   */
  artistCustomers: PageResult
  /**
   * Node: DeliveryItem
   * @auth: artist
   * @params:
   *   statusFilter: 取得したいステータス。指定しない場合はすべて取得する
   *                 | 'unapplied' 未申請
   *                 | 'arranging' 手配中
   *                 | 'shipped' 出荷済み
   *   pageInput: pageInputDate
   */
  artistDeliveryItems: PageResult
  /**
   * アーティスト権限でイベントの受け取りユーザー一覧を取得する
   * Node: PickupUser
   * @auth: artist
   */
  artistEventPickupUsers: PageResult
  /**
   * アーティスト用イベント一覧の取得（非公開イベントも含まれる）
   * Node: Event
   * @auth: artist
   */
  artistEvents: PageResult
  /**
   * アーティストの顧客一覧CSVのダウンロード用URLを取得する
   * @auth: artist
   */
  artistExportCustomersCsv: CsvDownload
  /**
   * アーティストのパトロン一覧CSVのダウンロード用URLを取得する
   * @auth: artist
   */
  artistExportPatronsCsv: CsvDownload
  /**
   * ガチャ一覧の詳細取得（アーティスト向け）
   * 公開・非公開関わらずすべて取得する
   * @auth: artist
   * @params:
   *   artistId: アーティストID
   *   pageInput: PageInputDate
   */
  artistGachas: PageResult
  /**
   * ログインしているアーティスト自身の売上アイテム一覧を取得する
   * Node: ItemOrder
   * order: createdAt desc
   * cusor: createdAt
   * @auth: artist
   * @params:
   *   pageInput: PageInputDate
   */
  artistItemOrders: OrderHistory
  /**
   * ログインアーティスト自身のItem一覧を取得する
   * Node: Item
   * order: createdAt desc
   * cusor: documentId
   * @auth: artist
   * @params:
   *   filters: ArtistItemsFilterInput
   *   pageInput: PageInput
   */
  artistItems: PageResult
  /**
   * ログインアーティスト自身のミッション一覧を取得する
   * @auth: artist
   * @params:
   *   typeFilter: 取得したいミッションType。指定しない場合はすべて取得する
   *               | 'quiz'
   *               | 'twitter_retweet'
   *               | 'twitter_follow'
   *               | 'item_collect'
   *   pageInput: PageInput
   *   orderBy: 昇順・降順の指定
   */
  artistMissions: PageResult
  /**
   * アーティストのお知らせ一覧を取得する
   * Node: Notification
   * @auth: artist
   * @params:
   *   pageInput: PageInputDate
   */
  artistNotifications: PageResult
  /**
   * Get organization official notification detail
   * @auth: artist
   */
  artistOrganizationNotification?: Maybe<OrganizationNotification>
  /**
   * Get organization official notification
   * @auth: artist
   */
  artistOrganizationOfficialNotifications: PageResult
  /**
   * アーティストのPayout(入金情報)一覧の取得
   * Node: Payout
   * order: createdAt desc
   * cursor: createdAt
   * @auth: artist
   * @params:
   *   pageInput: PageInputDate
   */
  artistPayouts: PageResult
  /**
   * アーティスト権限で受け取り予約詳細の取得
   * @auth: artist
   */
  artistPickupUser: PickupUser
  /**
   * ログインしているアーティストの権限で指定したUserItemを取得する
   * @auth: user
   * @params:
   *   id: UserItem DocumentID
   */
  artistPortfolio?: Maybe<UserItem>
  /**
   * アーティスト用先行販売の取得
   * 非公開のものでも取得可能
   * @auth: artist
   */
  artistPreSale: PreSale
  /**
   * 先行販売の抽選申込者詳細を取得する
   * @auth: artist
   */
  artistPreSaleLotteryApplicant: PreSaleLotteryApplicant
  /**
   * 先行販売の抽選申込者一覧を取得する
   * @auth: artist
   */
  artistPreSaleLotteryApplicants: PageResult
  /**
   * アーティスト用先行販売一覧の取得
   * 公開・非公開すべて取得
   * Node: PreSale
   * @auth: artist
   */
  artistPreSales: PageResult
  /**
   * アーティストのランキング
   * @auth: none
   */
  artistRankings?: Maybe<Ranking>
  /**
   * ユーザーが保持している自動ミッションのデータをアーティスト単位で取得する
   * Node: UserStepMission
   * order: createdAt desc
   * cusor: createdAt
   * @auth: user
   * @params:
   *   artistId: アーティストID
   *   type: 自動ミッションの種別
   *   filter: ミッションの達成状況によるフィルター
   *   pageInput: PageInput
   */
  artistStepMissions: PageResult
  /**
   * アーティストのサブスリプションプラン詳細の取得
   * @auth: artist
   */
  artistSubscriptionPlan?: Maybe<MembershipSubscriptionPlan>
  /**
   * アーティストのスレッドを取得する
   * @auth: none
   */
  artistThreadChannel: ArtistThreadChannel
  /**
   * アーティストスレッドの指定したコメントを取得する
   * @auth: none
   */
  artistThreadChannelComment?: Maybe<Comment>
  /**
   * アーティストのスレッドを取得する
   * Node: ArtistThreadChannel
   * @auth: user
   */
  artistThreadChannels: PageResult
  /**
   * アーティストのトレード一覧を取得する
   * ステータス指定しない場合はすべてのステータスが対象
   * @auth: none
   * Node: TradeNegotiation
   */
  artistTradeNegotiations: PageResult
  /**
   * アーティストのカート決済履歴詳細の取得
   * @auth: artist
   */
  artistUserCartTransactionOrder: UserCartTransactionOrder
  /**
   * ログインアーティストのカート決済履歴一覧（注文履歴として）
   * userIdを指定した場合は、そのuserに絞って取得する
   * @auth: artist
   */
  artistUserCartTransactionOrders: PageResult
  /**
   * 全アーティストの一覧を取得する
   * Node: Artist
   * order: accountId
   * cursor: accountId
   * @auth: admin
   * @params:
   *   pageInput: PageInput
   */
  artists: PageResult
  /**
   * キャンペーン情報の取得
   * @auth: none
   * @params:
   *   code: キャンペーンコード（CampaignのDocumentID）
   */
  campaign?: Maybe<Campaign>
  /**
   * 最新キャンペーンランキングの取得
   * @auth: none
   * @params:
   *   inputs: CampaignRankingInput
   */
  campaignRanking?: Maybe<CampaignRanking>
  /**
   * キャンペーン一覧を取得する
   * x-utoniq-application-keyを指定した場合は、organizationに所属するものが返される
   * node: Campaign
   */
  campaigns: PageResult
  /**
   * 指定したIDのコレクションを取得する
   * @auth: none
   * @params:
   *   id: Collection DocumentID
   */
  collection?: Maybe<Collection>
  /**
   * 指定したIDのコメント詳細を取得する
   * @auth: none
   * @params:
   *   id: Comment DocumentID
   */
  comment: Comment
  /**
   * アーティスト毎のコメント一覧の取得
   * @auth: none
   * @params:
   *   artistId: アーティストID
   *   pageInput: PageInputDate
   */
  comments: PageResult
  /**
   * ログインボーナス（連続ログイン）設定を1つのみ取得する
   * その時点で獲得可能なボーナスがbonuses配列に格納される。ない場合は獲得できるボーナスなし
   * @auth: user
   */
  continuousLoginBonus?: Maybe<LoginBonus>
  /**
   * 指定したwordのCopyrightの一覧を取得する
   * Node: Copyright
   * @auth: none
   * @params:
   *   word: 曲名
   */
  copyrights: PageResult
  /**
   * ログインしているアーティスト自身の指定期間内売上日次データ（グラフ等のplot用）を取得する。デフォルト最新30日分
   * @auth: artist
   */
  dailySalesData: Array<DailySales>
  /**
   * ログインしているユーザー自身の物販アイテム配送一覧の取得
   * x-utoniq-application-keyがある場合はorganizationに所属するアーティストのみのアイテムが対象になる
   * Node: DeliveryItem
   * @auth: user
   * @params:
   *   statusFilter: 取得したいステータス。指定しない場合はすべて取得する
   *                 | 'unapplied' 未申請
   *                 | 'arranging' 手配中
   *                 | 'shipped' 出荷済み
   *   pageInput: pageInputDate
   */
  deliveryItems: PageResult
  /**
   * 指定したキャンペーンコードにエントリーしているアーティスト一覧を取得する
   * @auth: none
   * @params:
   *   code: キャンペーンコード
   */
  entryArtists: PageResult
  /** イベントの取得 */
  event: Event
  /**
   * イベント一覧の取得（公開イベントのみ）
   * Node: Event
   */
  events: PageResult
  /**
   * アーティストに関連するイベント一覧の取得
   * ユーザー用
   */
  eventsByArtist: PageResult
  /**
   * 先行販売の受付申込者一覧をCSVでダウンロードするURLを取得する
   * @auth: artist
   */
  exportPreSaleApplicants: PreSaleApplicantCsv
  /**
   * ログインしているアーティスト自身の売上トランザクション一覧のダウンロードURLを取得する
   * @auth: artist
   * @params:
   *   year: Int!
   */
  exportRevenueTransactions: RevenueTransaction
  /**
   * 指定したIDのガチャ詳細取得
   * 以下のキーのみ中抽出可能
   *   'id',
   *   'rollAlgorithm',
   *   'name',
   *   'artistId',
   *   'description',
   *   'thumbnail',
   *   'animation',
   *   'startAt',
   *   'endAt',
   *   'isPublished',
   * @auth: none
   * @params:
   *   id: ガチャ DocumentID
   */
  gacha?: Maybe<Gacha>
  /**
   * 指定したIDのガチャチケットを取得する
   * @auth: none
   * @params:
   *   id: ガチャチケット DocumentID
   */
  gachaTicket?: Maybe<GachaTicket>
  /**
   * ログインしているユーザーのガチャチケット履歴の取得
   * Node: GachaTicketOrder
   *     x-utoniq-application-keyがあればorganization所属のみが対象
   *     x-utoniq-application-keyがなければorganizationに所属しないものが対象
   * @auth: user
   */
  gachaTicketOrders: PageResult
  /**
   * 公開中のガチャの一覧取得
   *   → x-utoniq-application-keyがある場合: organizationに属するガチャのみ取得
   *   → x-utoniq-application-keyがない場合: organizationに属さないガチャのみ取得
   * @auth: none
   * @params:
   *   artistId: アーティストID
   *   pageInput: PageInputDate
   */
  gachas: PageResult
  /**
   * 指定したDocumentIDのアイテムを取得する
   *   adminログインの場合：全アイテムが対象
   *   artistログインの場合：
   *     自身が発行したアイテムの場合：自身の全アイテムが対象
   *     他のアーティストが発行したアイテムの場合：公開中のアイテムのみが対象
   *   userログインの場合：公開中のアイテムのみが対象
   * @auth: none
   * @params:
   *   id: アイテムのDocumentID
   */
  item?: Maybe<Item>
  /**
   * 指定したアイテムが含まれる複数アイテムミッションの取得
   * @auth: user
   * @params:
   *   itemId: アイテムID
   *   artistId: アーティストID
   */
  itemCollectMissions?: Maybe<Array<Mission>>
  /**
   * Get specified comments in item
   * @auth: none
   * @params:
   *   id: ミッション DocumentID
   *   commentId: ミッション DocumentID
   */
  itemComment?: Maybe<Comment>
  /**
   * アイテムの所有者を取得する
   * Node: UserItem
   * order: createdAt desc
   * cusor: createdAt
   * @auth: artist
   * @params:
   *   itemId: 対象のItem DocumentID
   *   pageInput: PageInputDate
   */
  itemHolders: ItemHolder
  /**
   * リセール抽選申し込みデータを取得する
   * @auth: user
   */
  itemResaleRaffleUser: ItemResaleRaffleUser
  /**
   * ログインユーザーのリセール抽選申し込み一覧を取得する
   * Node: ItemResaleRaffleUser
   * @auth: user
   */
  itemResaleRaffleUsers: PageResult
  /**
   * 指定したアーティストのアイテムの一覧を取得する。
   * Node: Item
   * order: publishedAt desc
   * cursor: publishedAt
   * @auth: none
   * @params:
   *   artistId: アーティストID
   *   pageInput: PageInputDate
   */
  items: PageResult
  /**
   * ライブ配信を取得する
   * @auth: user
   */
  liveStream: LiveStream
  /**
   * ライブ配信コメントをページングで取得する
   * @auth: user
   * Node: Comment
   */
  liveStreamComments: PageResult
  /**
   * ログインボーナス（新規登録）設定を1つのみ取得する
   * その時点で獲得可能なボーナスがbonuses配列に格納される。ない場合は獲得できるボーナスなし
   * @auth: user
   */
  loginBonus?: Maybe<LoginBonus>
  /**
   * ミッションの取得
   * @auth: user
   * @params:
   *   id: ミッション DocumentID
   */
  mission?: Maybe<Mission>
  /**
   * Get specified comments in mission
   * @auth: none
   * @params:
   *   id: ミッション DocumentID
   *   commentId: ミッション DocumentID
   */
  missionComment?: Maybe<Comment>
  /**
   * ミッション一覧の取得
   * @auth: user
   * @params:
   *   artistId: アーティストID
   *   typeFilter: 取得したいミッションType。指定しない場合はすべて取得する
   *               | 'quiz'
   *               | 'twitter_retweet'
   *               | 'twitter_follow'
   *               | 'item_collect'
   *   pageInput: PageInputDate
   *   orderBy: 昇順・降順の指定
   * @deprecated: CreatedAtが同じ場合に正しくページングできない不具合があるため、missionsWithFilteresを使うこと
   * @deprecated use missionsWithFilters
   */
  missions: PageResult
  /**
   * ミッション一覧の取得
   * @auth: none
   * @params:
   *   artistId: アーティストID
   *   typeFilter: 取得したいミッションType。指定しない場合はすべて取得する
   *               | 'quiz'
   *               | 'twitter_retweet'
   *               | 'twitter_follow'
   *               | 'item_collect'
   *   pageInput: PageInput
   *   orderBy: 昇順・降順の指定
   */
  missionsWithFilteres: PageResult
  /**
   * Get monthly events of an artist
   * @params:
   *   month: format yyyymm
   *   artistId: アーティストID
   */
  monthlyEventsByArtist: Array<Event>
  /**
   * 複数獲得アイテムの詳細取得
   * @auth: none
   */
  multiItemSerials: MultiItemSerial
  /**
   * イベントで自分がアイテム保有しているものをページングで取得する
   * @auth: user
   */
  myPreSaleEvents: PageResult
  nftToken?: Maybe<NftToken>
  /**
   * ユーザーお知らせ一覧を取得する
   * Node: Notification
   * @auth: user
   * @params:
   *   pageInput: PageInputDate
   *   artistId: アーティストID
   */
  notifications: PageResult
  /**
   * IDで指定したOrganizationを返す。
   * IDを指定しない場合、ヘッダに x-utoniq-application-key が設定されていればIDを自動で探す
   * なければauthorization errorをthrowする
   * @auth: none
   */
  organization?: Maybe<Organization>
  /**
   * Organizationアプリ用
   * ログインしているアーティスト自身の売上アイテム一覧を取得する
   * node: ItemOrder
   * @auth: artist
   */
  organizationArtistItemOrders: PageResult
  /**
   * Organizationに所属しているアーティストのランキング
   * Node: Ranking
   * @auth: none
   */
  organizationArtistRankings: PageResult
  /**
   * Organization公式アーティスト一覧を取得する
   * x-utoniq-application-key に設定されたAppKeyに紐づくOrganizationの一覧を返す
   *
   * node: OrganizationNotification
   * @auth: none
   */
  organizationOfficialArtistNotifications: PageResult
  /**
   * Organization公式お知らせの詳細を取得する
   * x-utoniq-application-key に設定されたAppKeyに紐づくOrganizationの一覧を返す
   *
   * @auth: none
   */
  organizationOfficialNotification?: Maybe<OrganizationNotification>
  /**
   * Organization公式お知らせ一覧を取得する
   * x-utoniq-application-key に設定されたAppKeyに紐づくOrganizationの一覧を返す
   *
   * node: OrganizationNotification
   * @auth: none
   */
  organizationOfficialNotifications: PageResult
  /**
   * 所有しているUserCollection一覧を取得する
   * userIdを指定しない場合、ログインしているuserが対象となる
   * また、
   *   x-utoniq-application-keyがあればorganization所属のみが対象
   *   x-utoniq-application-keyがなければorganizationに所属しないものが対象
   */
  ownedUserCollections: PageResult
  /**
   * ログインしているユーザー自身の決済手段を取得する
   * @auth: user
   */
  paymentSource?: Maybe<PaymentSource>
  /**
   * アイテムの支払い待ちデータの取得（コンビニ決済）
   * @auth: user
   */
  pendingPurchaseItem: PendingPurchaseItem
  /**
   * ログインユーザーの全体の支払い待ちデータの取得（コンビニ決済）
   * Node: PendingPurchaseItem
   * @auth: user
   */
  pendingPurchaseItems: PageResult
  /**
   * ピックアップアーティストに指定されているアーティスト情報を取得する
   * @auth: none
   */
  pickupArtists: Array<Artist>
  /**
   * 受け取り予約詳細の取得
   * @auth: user
   */
  pickupUser: PickupUser
  /**
   * 受け取り商品があるイベント一覧
   * Node: Event
   * @auth: user
   */
  pickupUserEvents: PageResult
  /**
   * イベントの受け取り予約一覧
   * Node: PickupUser
   * @auth: user
   */
  pickupUsersByEvent: PageResult
  /**
   * 指定したUserItemを取得する
   * userIdを指定しない場合は、ログインしているユーザー自身のポートフォリオ
   * @auth: user
   * @params:
   *   id: UserItem DocumentID
   */
  portfolio?: Maybe<UserItem>
  /**
   * ログインしているユーザーのポートフォリオ（所有アイテム一覧）を取得する
   * artistAccountIdとartistIdはどちらかしか指定できない。
   * Node: UserItem
   * order: createdAt desc
   * cusor: createdAt
   * @auth: user
   * @params:
   *   artistId: アーティストID
   *   artistAccountId: アーティストのaccountID
   *   type: | 'ticket'
   *         | 'audio'
   *         | 'video'
   *         | 'magazine'
   *         | 'avatar'
   *         | 'wallpaper'
   *         | 'merch'
   *   pageInput: PageInputDate
   */
  portfolios: PageResult
  /**
   * 指定したDocumentIDの投稿を取得する
   * @auth: none
   * @params:
   *   id: PostのDocumentID
   */
  post?: Maybe<Post>
  /**
   * 先行販売の取得
   * 非公開のものにアクセスしようとした場合は例外が発生する
   *
   * ワンタイムトークンが必要な場合（外部アプリからの参照）clientId, tokenがない場合はforbiddenとなる
   */
  preSale: PreSale
  /**
   * 先行販売の抽選申込者詳細を取得する
   * @auth: user
   */
  preSaleLotteryApplicant?: Maybe<PreSaleLotteryApplicant>
  /**
   * 先行販売の受付申込者一覧を取得する
   * @auth: user
   */
  preSaleLotteryApplicants: PageResult
  /**
   * 先行販売一覧の取得
   * 公開済みのもののみ取得
   * Node: PreSale
   */
  preSales: PageResult
  /**
   * ログインユーザーのプリペイドポイント残高の取得
   * prepaidPointIdを指定した場合、そのIDの残高を取得（なければエラー）
   * prepaidPointIdを指定しない場合、
   *   → x-utoniq-application-keyがある場合: そのorganizationのポイントを取得
   *   → x-utoniq-application-keyがない場合: デフォルトでUTNQポイントを取得
   * @auth: user
   */
  prepaidPointBalance: PrepaidPointBalance
  /**
   * プリペイドポイント取引履歴の取得
   * @auth: user
   * Node: PrepaidPointTransaction
   */
  prepaidPointTransactions: PageResult
  /**
   * メンバーシップの更新時の料金変更分をプレビューとして取得する
   * @auth: user
   */
  previewSubscriptionUpdate: PreviewSubscriptionUpdate
  /**
   * 購入可能なガチャチケットリストの取得
   * inputでartistIdを指定した場合はそのアーティストのチケット
   * 指定しない場合はx-utoniq-application-keyからorganization共通チケットを返す
   * artistIdかx-utoniq-application-keyどちらか必須
   * @auth: user
   */
  purchasableGachaTickets: Array<Maybe<PurchasableGachaTicket>>
  /**
   * プリペイドポイントの購入リストを取得する(ポイントのセット)
   * プリペイドポイントIDが分かる場合、idに指定。
   * id指定がない場合、organizationのポイントを検索して取得する
   * organization用のポイントがない場合は、デフォルトのUTNQポイントを利用する
   * @auth: none
   * Node: PurchasablePrepaidPoint
   */
  purchasablePrepaidPoints: PageResult
  /**
   * 最近ストアで見たアイテム一覧を取得する
   * Node: RecentlyStoreViewedItem
   * @auth: user
   */
  recentlyStoreViewedItems: PageResult
  /**
   * 最近閲覧したコレクション一覧を取得する
   * Node: RecentlyViewedCollection
   * @auth: user
   */
  recentlyViewedCollections: PageResult
  /** リセールアイテムを取得する */
  resaleItem: ResaleItem
  /**
   * アイテムのリセール出品リストをページネーションで取得する
   * リセール期間中でない場合は空のリストを返す
   * Node: ResaleItem
   */
  resaleItemsByItem: PageResult
  /**
   * ユーザーのリセール出品リストをページネーションで取得する
   * userIdを指定しない場合はログインユーザーが対象となる
   * Node: ResaleItem
   */
  resaleItemsByUser: PageResult
  /**
   * アーティストがアイテム投稿のために一時保存している画像や動画など、指定したDocumentIDのファイルを取得する
   * 存在しない場合、DataNotFoundErrorがthrowされる
   * @auth: artist
   * @params:
   *   id: ResourceのDocumentID
   */
  resource: ArtistResource
  /**
   * アーティストがアイテム投稿のために一時保存している画像や動画などのファイル一覧を取得する
   * Node: ArtistResource
   * order: createdAt desc
   * cursor: createdAt
   * @auth: artist
   * @params:
   *   type: 'audio' | 'video' | 'image' | 'magazine'
   *   pageInput: PageInputDate
   */
  resources: PageResult
  /**
   * ログインしているアーティスト自身の銀行情報を取得する
   * @auth: artist
   */
  retrieveBank?: Maybe<Bank>
  /**
   * ログインしているアーティスト自身のビジネス情報を取得する
   * @auth: artist
   */
  retrieveBusiness?: Maybe<Business>
  /**
   * アイテムのレビューステータスが指定したステータスの一覧を取得する
   * Node: Item
   * order: createdAt desc
   * cusor: createdAt
   * @auth: none
   * @params:
   *   status: Review Status
   *   pageInput: PageInputDate
   */
  reviewItems: PageResult
  /**
   * ログインしているアーティスト自身の売上レポートを取得する
   * @auth: artist
   * @params:
   *   days: 収益情報を集計する日数(指定しない場合はデフォルト:30日)
   */
  salesReport: SalesReport
  /**
   * @deprecated: use copyrights
   * @deprecated use copyrights
   */
  searchCopyrights: Array<Maybe<Copyright>>
  /** Get Shor Link */
  shortLink: ShortLink
  /**
   * アーティストのストア公開コレクション一覧を取得する
   * 公開期限が終了したものは含まれない
   * Node: Collection
   * @auth: none
   */
  storeCollections: PageResult
  /**
   * チケットの分配リンク詳細を取得する
   * @auth: user
   */
  ticketDistributionLink?: Maybe<TicketDistributionLink>
  /**
   * アーティストのトレードで、自分に申請が来ているものを取得する
   * artistIdを指定するとそのartistに関するものが対象
   * artistIdを指定しない場合はorganizationに紐づくすべてが対象
   * Node: TradeCandidate
   */
  tradeMyCandidates: PageResult
  /**
   * トレード交渉を取得する
   * @auth: user
   */
  tradeNegotiation: TradeNegotiation
  /**
   * トレードで、自分が申請したものを取得する
   * artistIdを指定するとそのartistに関するものが対象
   * artistIdを指定しない場合はorganizationに紐づくすべてが対象
   * Node: TradeNegotiation
   */
  tradeNegotiationsMyRequests: PageResult
  /**
   * ログインしているアーティスト自身の売上トランザクション一覧を取得する
   * @auth: artist
   * @params:
   *   pageInput: PageInputDate
   */
  transactions: PageResult
  /**
   * IDで指定されたユーザー情報を返す。IDを指定しない場合、自分自身が返却される
   * ログインしていない場合はnullが返却される
   * @auth: none
   * @params:
   *   id: UserID
   */
  user?: Maybe<User>
  /**
   * ユーザーの有効な申し込み抽選データ
   * 当選・購入済み・応募済みがあれば返される
   * userIdの指定がない場合はログインユーザーのデータを返す
   * @auth: user
   */
  userActiveResaleItemRaffle?: Maybe<ItemResaleRaffleUser>
  /**
   * ユーザーのカート詳細
   * @auth: guest-user
   */
  userCart?: Maybe<UserCart>
  /**
   * ユーザーのorganization内カート数
   * アーティスト/ストア単位が返される（カート内のアイテム点数ではない）
   * @auth: user
   */
  userCartCount: Scalars['Int']
  /**
   * カート決済履歴詳細の取得
   * @auth: user
   */
  userCartTransactionOrder: UserCartTransactionOrder
  /**
   * カート決済履歴一覧の取得
   * Node: UserCartTransaction
   * @auth: user
   */
  userCartTransactionOrders: PageResult
  /**
   * ユーザーのorganization内カート一覧
   * Node: UserCart
   * @auth: guest-user
   */
  userCarts: PageResult
  /**
   * ログインしているユーザー側で表示するコレクション一覧
   * artistIdの指定がある場合、指定されたアーティストのコレクションのみを取得する。
   * artistIdの指定がない場合、全てのコレクションを取得する。
   *     x-utoniq-application-keyがあればorganization所属のみが対象
   *     x-utoniq-application-keyがなければorganizationに所属しないものが対象
   * @auth: user
   * @params:
   *   pageInput: PageInputDate
   *   artistId: アーティストID
   * @deprecated No more maintenanced. Use ownedUserCollections for better performance
   */
  userCollections: PageResult
  /**
   * ログインしているユーザー自身の全アイテム購入履歴一覧を取得する
   * Node: ItemOrder
   * order: createdAt desc
   * cusor: createdAt
   * @auth: user
   * @params:
   *   pageInput: PageInputDate
   *   artistId: アーティストID
   */
  userItemOrders: PageResult
  /**
   * 指定ユーザーのトレード可能なUserItem(Serial)
   * userIdを指定しない場合はログインユーザー自身のアイテムを検索
   * artistIdを指定しない場合はorganization全体を検索
   * @auth: user
   * Node: UserItem
   */
  userTradableUserItems: PageResult
  /**
   * emailとaccountIdがユニークかどうかを判定する
   * @auth: none
   * @params:
   *   inputs: ValidateAccountInput
   */
  validateAccount: AccountValidation
  /**
   * カートの購入前チェック
   * @auth: user
   */
  validateCart: UserCart
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminAggregatePreSaleLotteryApplicationArgs = {
  preSaleId: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminArchivedCommentsArgs = {
  commentType?: Maybe<CommentType>
  id: Scalars['ID']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminArchivedPostRepliesArgs = {
  id: Scalars['ID']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminCollectionsArgs = {
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminCommentsArgs = {
  commentType?: Maybe<CommentType>
  id: Scalars['ID']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminDeliveryItemsArgs = {
  inputs?: Maybe<AdminDeliveryItemsInput>
  pageInput?: Maybe<PageInput>
  statusFilter?: Maybe<Array<DeliveryStatus>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminEventSalesReportArgs = {
  artistId?: Maybe<Scalars['String']>
  eventId: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminEventsArgs = {
  filter: EventFilter
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminExportTicketItemCsvArgs = {
  itemIds: Array<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminExportTicketSerialCsvArgs = {
  itemIds: Array<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminGachaArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminGachaLimitedItemSetArgs = {
  gachaId: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminGachaTicketsArgs = {
  artistId?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminGachasArgs = {
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminItemsArgs = {
  itemIds: Array<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminPayoutsArgs = {
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminPickupUserAggregationByEventArgs = {
  artistId: Scalars['String']
  date: Scalars['Date']
  eventId: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminPortfolioArgs = {
  id: Scalars['ID']
  userId: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminPreSalesArgs = {
  eventId: Scalars['String']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminResaleItemPaymentsArgs = {
  term?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminSimulateBulkAssignEventSeatFromCsvArgs = {
  csvPath: Scalars['String']
  eventSeatId: Scalars['ID']
  lines?: Maybe<Array<Scalars['String']>>
  sectionOrder?: Maybe<SectionOrder>
  sections?: Maybe<Array<Scalars['String']>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryAdminThreadChannelArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArchivedLiveStreamsArgs = {
  artistId: Scalars['String']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistArgs = {
  id?: Maybe<Scalars['ID']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistAggregatePickupTimeItemsArgs = {
  collectionId: Scalars['String']
  eventId: Scalars['ID']
  pickupDate: Scalars['Date']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistByNameArgs = {
  name: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistCollectionsArgs = {
  orderBy?: Maybe<OrderBy>
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistCommentsArgs = {
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistCustomerArgs = {
  userId: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistCustomersArgs = {
  orderBy?: Maybe<OrderBy>
  pageInput?: Maybe<PageInput>
  searchTerm?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistDeliveryItemsArgs = {
  pageInput?: Maybe<PageInput>
  statusFilter?: Maybe<Array<DeliveryStatus>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistEventPickupUsersArgs = {
  collectionId: Scalars['String']
  eventId: Scalars['String']
  options: EventPickupUsersOptions
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistEventsArgs = {
  filter: EventFilter
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistExportPatronsCsvArgs = {
  productIds: Array<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistGachasArgs = {
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistItemOrdersArgs = {
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistItemsArgs = {
  filters?: Maybe<ArtistItemsFilterInput>
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistMissionsArgs = {
  orderBy?: Maybe<OrderBy>
  pageInput?: Maybe<PageInput>
  typeFilter?: Maybe<Array<MissionType>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistNotificationsArgs = {
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistOrganizationNotificationArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistOrganizationOfficialNotificationsArgs = {
  pageInput?: Maybe<PageInput>
  status?: Maybe<Array<OrganizationNotificationStatus>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistPayoutsArgs = {
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistPickupUserArgs = {
  collectionId: Scalars['String']
  eventId: Scalars['String']
  pickupUserId: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistPortfolioArgs = {
  id: Scalars['ID']
  userId?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistPreSaleArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistPreSaleLotteryApplicantArgs = {
  preSaleId: Scalars['String']
  userId: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistPreSaleLotteryApplicantsArgs = {
  filters?: Maybe<PreSaleLotteryApplicantFilterInputs>
  pageInput?: Maybe<PageInput>
  preSaleId: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistPreSalesArgs = {
  eventId: Scalars['String']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistRankingsArgs = {
  artistId: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistStepMissionsArgs = {
  artistId: Scalars['ID']
  filter?: Maybe<StepMissionFilter>
  pageInput?: Maybe<PageInput>
  type?: Maybe<StepMissionType>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistSubscriptionPlanArgs = {
  planId: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistThreadChannelArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistThreadChannelCommentArgs = {
  commentId: Scalars['ID']
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistThreadChannelsArgs = {
  inputs: ArtistThreadChannelsInput
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistTradeNegotiationsArgs = {
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInput>
  status?: Maybe<Array<TradeNegotiationStatus>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistUserCartTransactionOrderArgs = {
  id: Scalars['ID']
  userId: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistUserCartTransactionOrdersArgs = {
  orderBy?: Maybe<OrderBy>
  pageInput?: Maybe<PageInput>
  userId?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryArtistsArgs = {
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryCampaignArgs = {
  code?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryCampaignRankingArgs = {
  inputs?: Maybe<CampaignRankingInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryCampaignsArgs = {
  excludeExpired?: Maybe<Scalars['Boolean']>
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryCollectionArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryCommentArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryCommentsArgs = {
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryCopyrightsArgs = {
  word: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryDailySalesDataArgs = {
  days?: Maybe<Scalars['Int']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryDeliveryItemsArgs = {
  pageInput?: Maybe<PageInput>
  statusFilter?: Maybe<Array<DeliveryStatus>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryEntryArtistsArgs = {
  code: Scalars['String']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryEventArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryEventsArgs = {
  filter: EventFilter
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryEventsByArtistArgs = {
  artistId: Scalars['String']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryExportPreSaleApplicantsArgs = {
  preSaleId: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryExportRevenueTransactionsArgs = {
  year: Scalars['Int']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryGachaArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryGachaTicketArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryGachaTicketOrdersArgs = {
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryGachasArgs = {
  artistId?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryItemArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryItemCollectMissionsArgs = {
  artistId: Scalars['ID']
  itemId: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryItemCommentArgs = {
  commentId: Scalars['ID']
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryItemHoldersArgs = {
  itemId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryItemResaleRaffleUserArgs = {
  itemId: Scalars['String']
  raffleId: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryItemResaleRaffleUsersArgs = {
  pageInput?: Maybe<PageInput>
  status: Array<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryItemsArgs = {
  artistId: Scalars['ID']
  filters?: Maybe<ItemsFilterInput>
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryLiveStreamArgs = {
  id: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryLiveStreamCommentsArgs = {
  id: Scalars['ID']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryMissionArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryMissionCommentArgs = {
  commentId: Scalars['ID']
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryMissionsArgs = {
  artistId: Scalars['ID']
  orderBy?: Maybe<OrderBy>
  pageInput?: Maybe<PageInputDate>
  typeFilter?: Maybe<Array<MissionType>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryMissionsWithFilteresArgs = {
  artistId: Scalars['ID']
  orderBy?: Maybe<OrderBy>
  pageInput?: Maybe<PageInput>
  typeFilter?: Maybe<Array<MissionType>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryMonthlyEventsByArtistArgs = {
  artistId: Scalars['String']
  month: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryMultiItemSerialsArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryMyPreSaleEventsArgs = {
  filter: EventHeldStatus
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryNftTokenArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryNotificationsArgs = {
  artistId?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryOrganizationArgs = {
  id?: Maybe<Scalars['ID']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryOrganizationArtistItemOrdersArgs = {
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryOrganizationArtistRankingsArgs = {
  pageInput?: Maybe<PageInput>
  userId?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryOrganizationOfficialArtistNotificationsArgs = {
  artistId: Scalars['String']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryOrganizationOfficialNotificationArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryOrganizationOfficialNotificationsArgs = {
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryOwnedUserCollectionsArgs = {
  pageInput?: Maybe<PageInput>
  userId?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPendingPurchaseItemArgs = {
  pendingPurchaseId: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPendingPurchaseItemsArgs = {
  pageInput?: Maybe<PageInputDate>
  status?: Maybe<Array<PendingPurchaseItemStatus>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPickupUserArgs = {
  collectionId: Scalars['String']
  eventId: Scalars['String']
  pickupUserId: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPickupUserEventsArgs = {
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPickupUsersByEventArgs = {
  eventId: Scalars['ID']
  options: PickupUsersByEventOptions
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPortfolioArgs = {
  id: Scalars['ID']
  userId?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPortfoliosArgs = {
  artistAccountId?: Maybe<Scalars['String']>
  artistId?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInputDate>
  type?: Maybe<ItemType>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPostArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPreSaleArgs = {
  clientId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  token?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPreSaleLotteryApplicantArgs = {
  preSaleId: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPreSaleLotteryApplicantsArgs = {
  filter?: Maybe<Array<Scalars['String']>>
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPreSalesArgs = {
  eventId: Scalars['String']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPrepaidPointBalanceArgs = {
  prepaidPointId?: Maybe<Scalars['ID']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPrepaidPointTransactionsArgs = {
  pageInput?: Maybe<PageInput>
  prepaidPointId?: Maybe<Scalars['ID']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPreviewSubscriptionUpdateArgs = {
  artistId: Scalars['String']
  planId: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPurchasableGachaTicketsArgs = {
  inputs?: Maybe<PurchasableGachaTicketInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryPurchasablePrepaidPointsArgs = {
  id?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInput>
  platform: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryRecentlyStoreViewedItemsArgs = {
  artistId?: Maybe<Scalars['String']>
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryRecentlyViewedCollectionsArgs = {
  artistId?: Maybe<Scalars['String']>
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryResaleItemArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryResaleItemsByItemArgs = {
  itemId: Scalars['String']
  pageInput?: Maybe<PageInput>
  status?: Maybe<Array<Scalars['String']>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryResaleItemsByUserArgs = {
  pageInput?: Maybe<PageInput>
  status?: Maybe<Array<Scalars['String']>>
  userId?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryResourceArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryResourcesArgs = {
  pageInput?: Maybe<PageInputDate>
  type?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryReviewItemsArgs = {
  pageInput?: Maybe<PageInputDate>
  status?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QuerySalesReportArgs = {
  days?: Maybe<Scalars['Int']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QuerySearchCopyrightsArgs = {
  word: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryShortLinkArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryStoreCollectionsArgs = {
  artistId: Scalars['String']
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryTicketDistributionLinkArgs = {
  token: Scalars['String']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryTradeMyCandidatesArgs = {
  artistId?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInput>
  status?: Maybe<Array<TradeCandidateNegotiationStatus>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryTradeNegotiationArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryTradeNegotiationsMyRequestsArgs = {
  artistId?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInput>
  status?: Maybe<Array<TradeNegotiationStatus>>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryTransactionsArgs = {
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryUserActiveResaleItemRaffleArgs = {
  itemId: Scalars['String']
  skuId?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryUserCartArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryUserCartTransactionOrderArgs = {
  id: Scalars['ID']
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryUserCartTransactionOrdersArgs = {
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryUserCartsArgs = {
  pageInput?: Maybe<PageInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryUserCollectionsArgs = {
  artistId?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryUserItemOrdersArgs = {
  artistId?: Maybe<Scalars['String']>
  pageInput?: Maybe<PageInputDate>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryUserTradableUserItemsArgs = {
  artistId?: Maybe<Scalars['String']>
  pageInput?: Maybe<PageInput>
  userId?: Maybe<Scalars['ID']>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryValidateAccountArgs = {
  inputs?: Maybe<ValidateAccountInput>
}

/**
 * GraphQL Query Type
 * 情報をAPIから取得する場合に利用
 *
 * authorizationはヘッダにfirebase auth idTokenを付与する
 *   Authorization: 'Bearer {idToken}'
 * サーバー側でfirebase authに対しidTokenの検証を行い、ユーザーIDを抽出する
 *
 * 権限スコープは以下の通り。
 * @auth: none => 権限がないpublicな情報
 * @auth: user => ログインしていないとアクセスできない
 * @auth: artist => アーティストとしてログインしていないとアクセスできない
 * @auth: admin => アドミンとしてログインしていないとアクセスできない
 */
export type QueryValidateCartArgs = {
  inputs: ValidateCartInput
}

/** ミッションtypeがquizの場合のコンテンツ情報 */
export type QuizContent = {
  /** 答え */
  answer?: Maybe<Scalars['String']>
  /** 画像 */
  image?: Maybe<Image>
}

/** ミッションのコンテンツInput */
export type QuizContentInput = {
  /** type quizの場合の答え */
  answer?: Maybe<Scalars['String']>
  /** ミッション画像 */
  image?: Maybe<ImageInput>
  /** type item_collectの場合のコンテンツ */
  items?: Maybe<Array<Maybe<ItemCollectContentItemInput>>>
  /** type twitter_retweetの場合のtweetId */
  tweetId?: Maybe<Scalars['String']>
  /** type twitter_followの場合のtwitterUserId */
  twitterUserId?: Maybe<Scalars['String']>
}

/** ミッション報酬がGachaTicketの場合の報酬 */
export type QuizGachaTicket = {
  count: Scalars['Int']
  gachaTicket: GachaTicket
  gachaTicketId: Scalars['String']
  id: Scalars['String']
}

/** 報酬ガチャチケットInput */
export type QuizGachaTicketInput = {
  /** 報酬チケット枚数 */
  count: Scalars['Int']
  /** ガチャチケットID */
  gachaTicketId: Scalars['String']
}

/** ミッションの結果 */
export type QuizResult = {
  /** 達成かどうか */
  correct: Scalars['Boolean']
  /** ミッションデータ */
  mission: Mission
  /** 対象のミッションID */
  missionId: Scalars['String']
  /** 達成した場合は報酬が入る */
  reward?: Maybe<QuizReward>
}

/**
 * ミッション報酬
 * itemかgachaTicketのどちらかが入る
 */
export type QuizReward = {
  gachaTicket?: Maybe<QuizGachaTicket>
  item?: Maybe<QuizRewardItem>
}

/** ミッション報酬がItemの場合の情報 */
export type QuizRewardItem = {
  id: Scalars['String']
  item?: Maybe<Item>
}

/** 報酬アイテムInput */
export type QuizRewardItemInput = {
  /** アイテムのDocumentID */
  id: Scalars['String']
}

/** ランキングデータ */
export type Ranking = Node & {
  artist: Artist
  artistId: Scalars['String']
  createdAt?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  /** 最新のランキング集計データ */
  latestRankingPeriod?: Maybe<RankingPeriod>
  status?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['Date']>
}

/** ランキングデータ */
export type RankingLatestRankingPeriodArgs = {
  periodType: RankingPeriodType
}

/**
 * ランキング集計期間
 * monthlyの場合、月ごと
 * yearlyの場合、年ごとにデータが作成されるようになっている
 */
export type RankingPeriod = {
  createdAt: Scalars['Date']
  id: Scalars['ID']
  /**
   * 指定したuserIdの自分のランキング
   * userIdを指定しない場合、ログインユーザーのランキング
   */
  myRanking?: Maybe<RankingUser>
  /**
   * 集計期間
   * ex) 2023-01, 2023
   */
  period: Scalars['String']
  /**
   * 集計期間種別
   * monthly or yearly
   */
  periodType: RankingPeriodType
  /** ランキングID */
  rankingId?: Maybe<Scalars['String']>
  /**
   * ユーザーのランキング
   * Node: RankingUser
   */
  rankings?: Maybe<PageResult>
  /** ランキングのトータルユーザー数 */
  totalUsers: Scalars['Int']
  /** 集計のタイプ（sum-rarities） */
  type: Scalars['String']
}

/**
 * ランキング集計期間
 * monthlyの場合、月ごと
 * yearlyの場合、年ごとにデータが作成されるようになっている
 */
export type RankingPeriodMyRankingArgs = {
  userId?: Maybe<Scalars['String']>
}

/**
 * ランキング集計期間
 * monthlyの場合、月ごと
 * yearlyの場合、年ごとにデータが作成されるようになっている
 */
export type RankingPeriodRankingsArgs = {
  pageInput?: Maybe<PageInput>
}

/** ランキング集計期間種別 */
export enum RankingPeriodType {
  /** 月間 */
  Monthly = 'monthly',
  /** 総合（年間） */
  Yearly = 'yearly',
}

export enum RankingStatus {
  Active = 'active',
  Inactive = 'inactive',
}

/** ユーザーランキングの一つ一つのデータ */
export type RankingUser = Node & {
  id: Scalars['ID']
  /** 集計期間ID */
  periodId?: Maybe<Scalars['String']>
  /** ポイント数 */
  point?: Maybe<Scalars['Int']>
  /** 前回集計時の順位 */
  previousRank?: Maybe<Scalars['Int']>
  /** 順位（同列あり） */
  rank?: Maybe<Scalars['Int']>
  /** ランキングID */
  rankingId?: Maybe<Scalars['String']>
  user?: Maybe<User>
  /** ユーザーID */
  userId: Scalars['String']
}

/** レアリティのIDと表示名 */
export type Rarity = {
  /** ID */
  id: Scalars['String']
  /** 表示名 */
  name: Scalars['String']
}

/** 出現確率Input */
export type RarityInput = {
  /** 名称 */
  name?: Maybe<Scalars['String']>
  /** ID */
  rarityId: Scalars['String']
  /** 加重 */
  weight: Scalars['Float']
}

/** チケット分配受け取りInput */
export type ReceiveTicketDistributionInput = {
  /** URLパラメーターのTOKEN */
  token: Scalars['String']
}

/** 最近ストアで見たアイテム */
export type RecentlyStoreViewedItem = Node & {
  /** アーティスト */
  artist: Artist
  /** アーティストID */
  artistId: Scalars['String']
  /** 作成日 */
  createdAt: Scalars['Date']
  id: Scalars['ID']
  /** アイテム */
  item: Item
  /** アイテムID */
  itemId: Scalars['String']
  /** 更新日 */
  updatedAt: Scalars['Date']
  /** ユーザー */
  user?: Maybe<User>
  /** ユーザーID */
  userId: Scalars['String']
  /** 閲覧回数 */
  viewCount: Scalars['Int']
}

/** 最近ストアで見たアイテム */
export type RecentlyViewedCollection = Node & {
  /** アーティスト */
  artist: Artist
  /** アーティストID */
  artistId: Scalars['String']
  /** コレクション */
  collection: Collection
  /** コレクションID */
  collectionId: Scalars['String']
  /** 作成日 */
  createdAt: Scalars['Date']
  id: Scalars['ID']
  /** 最後に見たコレクションのアイテム */
  latestItem?: Maybe<Item>
  /** 最後に見たコレクションのアイテムID */
  latestItemId: Scalars['String']
  /** 更新日 */
  updatedAt: Scalars['Date']
  /** ユーザー */
  user?: Maybe<User>
  /** ユーザーID */
  userId: Scalars['String']
  /** 閲覧回数 */
  viewCount: Scalars['Int']
}

/**
 * 物販アイテムの場合の配送申請受付期間
 * startedAtにnullを指定した場合：endedAtまで受付可能
 * endedAtにnullを指定した場合：startedAt以降受付可能
 * 両方nullを指定した場合：期限なし
 */
export type ReceptionPeriod = {
  endedAt?: Maybe<Scalars['Date']>
  formattedEndedAt?: Maybe<Scalars['String']>
  formattedStartedAt?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['Date']>
}

/** 物販アイテム配送受付期間Input */
export type ReceptionPeriodInput = {
  /** 受付終了日 */
  endedAt?: Maybe<Scalars['Date']>
  /** 受付開始日 */
  startedAt?: Maybe<Scalars['Date']>
}

/** チケット分配リンクの再生成Input */
export type RegenerateTicketDistributionLinkInput = {
  /** URLパラメーターのTOKEN */
  token: Scalars['String']
}

/** Register Artist From User Input */
export type RegisterArtistFromUserInput = {
  accountId?: Maybe<Scalars['String']>
  avatarPath?: Maybe<Scalars['String']>
  displayName: Scalars['String']
  introduction?: Maybe<Scalars['String']>
  invitationCode?: Maybe<Scalars['String']>
}

/** アーティスト作成Input */
export type RegisterArtistInput = {
  /** アカウントID */
  accountId: Scalars['String']
  /** 表示名 */
  displayName?: Maybe<Scalars['String']>
  /** メールアドレス */
  email: Scalars['String']
  /** トップページに表示するかどうか（デフォルトtrue） */
  isDisplayToTop?: Maybe<Scalars['Boolean']>
  /** パスワード */
  password: Scalars['String']
  /** 支払い種別(billing or connect) */
  paymentType?: Maybe<PaymentType>
  /** 公開するかどうか */
  private?: Maybe<Scalars['Boolean']>
}

/** プロフィール登録Input */
export type RegisterProfileInput = {
  /** アカウントID */
  accountId: Scalars['String']
  /** アバター画像パス */
  avatarPath?: Maybe<Scalars['String']>
  /** 生年月日 */
  birthday?: Maybe<Scalars['Date']>
  /** 表示名 */
  displayName: Scalars['String']
  /** 性別 */
  gender?: Maybe<Scalars['String']>
  /** 自己紹介 */
  introduction: Scalars['String']
}

/** サブスクプラン作成Input */
export type RegisterSubscriptionPlanInput = {
  amount: Scalars['Int']
  interval: MembershipInterval
}

/** 複数サブスクプラン作成Input */
export type RegisterSubscriptionsInput = {
  annualContent?: Maybe<Scalars['String']>
  artistId?: Maybe<Scalars['String']>
  content: Scalars['String']
  plans: Array<RegisterSubscriptionPlanInput>
  productId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

/** カートから削除インプット */
export type RemoveFromCartInput = {
  /** カートID */
  cartId: Scalars['ID']
  /** 削除する数量 */
  count: Scalars['Int']
  /** アイテムID */
  itemId: Scalars['String']
  /** SKU ID（ある場合） */
  skuId?: Maybe<Scalars['String']>
}

/** ポスト返信Input */
export type ReplyPostInput = {
  /** 返信内容 */
  content: Scalars['String']
  /** 返信先のポストID */
  postId: Scalars['ID']
  /** Reply to AccountId */
  replyToAccountId?: Maybe<Scalars['String']>
}

/** クレジットカード登録でStripeのSetupIntentにおいてクライアントサイドでのnextActionが必要な場合の情報 */
export type RequiresAction = {
  /** setupIntentのclient_secret (stripe.handleNextAction の引数) */
  clientSecret: Scalars['String']
  /** setupIntentのstatus */
  status: Scalars['String']
}

/** リセールアイテム */
export type ResaleItem = Node & {
  /** リセール手数料 */
  fees: ResaleItemFee
  formattedListedAt?: Maybe<Scalars['String']>
  formattedPaymentExpiredAt?: Maybe<Scalars['String']>
  formattedTotalItemPrice?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** 出品アイテム */
  item: Item
  /** 出品数 */
  itemCount: Scalars['Int']
  /** アイテムID */
  itemId: Scalars['String']
  /** 出品日時 */
  listedAt: Scalars['Date']
  /** 支払いの期限 */
  paymentExpiredAt?: Maybe<Scalars['Date']>
  /** 購入したユーザー */
  purchasedUser?: Maybe<User>
  /** リセールアイテムの購入者ID */
  purchasedUserId?: Maybe<Scalars['String']>
  /** 出品されたシリアルのID */
  serialIds: Array<Scalars['String']>
  /** 出品されたシリアルのシリアルナンバー */
  serialNumbers: Array<Scalars['String']>
  /** 出品アイテムのシリアル */
  serials: Array<ItemSerial>
  /** SKU ID */
  skuId?: Maybe<Scalars['String']>
  /** リセールアイテムの購入日時 */
  soldAt?: Maybe<Scalars['Date']>
  /**
   * リセールアイテムのステータス
   * | '10_open' 出品中
   * | '20_matched' 支払い待ち（UI上は出品中）
   * | '30_no_match' 売却不成立
   * | '40_sold' 売却済（未払い）
   * | '50_paid' 売却済（支払済）
   * | '60_canceled' キャンセル
   */
  status: Scalars['String']
  /** リセールアイテム合計価格（定価×itemCount） */
  totalItemPrice: Scalars['Int']
  /** 出品ユーザー */
  user: User
  /** 出品したユーザーID */
  userId: Scalars['String']
}

/** リセール手数料内訳 */
export type ResaleItemFee = {
  /** 振込手数料 */
  bankTransfer: Scalars['Int']
  /** チケット手数料 */
  ticket: Scalars['Int']
}

/** アイテムリソース(ArtistResourceから移動したリソース) */
export type Resource = {
  /** 80%圧縮のみ行ったURI(画像) */
  compressedUri?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['Date']>
  /** dashの暗号化ファイルパス */
  dashUri?: Maybe<Scalars['String']>
  /** DRMのメタ情報 */
  drm?: Maybe<ResourceDrm>
  /** hlsの暗号化ファイルパス */
  hlsUri?: Maybe<Scalars['String']>
  /** 通常画面表示用の中サイズURI(画像) */
  mediumUri?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  /** サムネイル等表示用の小サイズURI(画像) */
  smallUri?: Maybe<Scalars['String']>
  /** リソースの種類 */
  type?: Maybe<Scalars['String']>
  /** リソースのURL 動画、音声、画像など */
  uri: Scalars['String']
}

/** DRMの暗号化状態 */
export type ResourceDrm = {
  /**
   * DRMの暗号化状態。encryptedで暗号化済み
   * 暗号化されたリソースURIはdashUri, hlsUriに格納される。
   */
  encryptingStatus?: Maybe<EncryptingStatus>
}

/** リソースInput */
export type ResourceInput = {
  /** storageに保存された一時ファイルのパス */
  tmpPath?: Maybe<Scalars['String']>
  /** リソースのURL */
  uri?: Maybe<Scalars['String']>
  /** youtube埋め込みのvideoId */
  videoId?: Maybe<Scalars['String']>
}

/** アイテムリソース講師尿Input */
export type ResourceUpdateInput = {
  tmpPath?: Maybe<Scalars['String']>
}

/** アイテム画像(配列)更新Input */
export type ResourcesUpdateInput = {
  /**
   * 現在設定されいているpath
   * 指定しない場合は新規追加となる
   */
  currentPath?: Maybe<Scalars['String']>
  /**
   * 更新する画像のtmpPath
   * currentPathのみ指定し、tmpPathを指定しない場合は削除される
   */
  tmpPath?: Maybe<Scalars['String']>
}

/** Revenue transaction */
export type RevenueTransaction = {
  /** CSV url */
  csvUrl?: Maybe<Scalars['String']>
}

/** 報酬アニメーション */
export type RewardAnimation = {
  /** 報酬アニメーションの詳細 */
  animation: GachaRewardAnimation
}

/** 4種、またはレアリティ別アニメーション */
export type RewardAnimations = {
  /** 10連時のアニメーション */
  bulk: RewardAnimation
  /** 単発アニメーション */
  single: RewardAnimation
  /**
   * 当たり判定とするレアリティ
   * 4種アニメーションの当たり判定でのみ使用する
   */
  specialAnimationRarity?: Maybe<Scalars['String']>
}

/**
 * レアリティ別対応アニメーションインプット
 * 4種アニメーション、またはレアリティ個別アニメーションに対応
 * より細かい演出を行う場合、レアリティ別にアニメーションを設定する
 */
export type RewardAnimationsInput = {
  /** レアリティ別アニメーション:ノーマル10連 */
  bulkCommonAnimationPath?: Maybe<Scalars['String']>
  /** レアリティ別アニメーション:リミテッド10連 */
  bulkLimitedAnimationPath?: Maybe<Scalars['String']>
  /** 4種アニメーション:ノーマル10連 */
  bulkNormalAnimationPath?: Maybe<Scalars['String']>
  /** レアリティ別アニメーション:レア10連 */
  bulkRareAnimationPath?: Maybe<Scalars['String']>
  /** 4種アニメーション:当たり10連 */
  bulkSpecialAnimationPath?: Maybe<Scalars['String']>
  /** レアリティ別アニメーション:スーパーレア10連 */
  bulkSuperrareAnimationPath?: Maybe<Scalars['String']>
  /** レアリティ別アニメーション:ウルトラレア10連 */
  bulkUltrarareAnimationPath?: Maybe<Scalars['String']>
  /** レアリティ別アニメーション:ノーマル単発 */
  singleCommonAnimationPath?: Maybe<Scalars['String']>
  /** レアリティ別アニメーション:リミテッド単発 */
  singleLimitedAnimationPath?: Maybe<Scalars['String']>
  /** 4種アニメーション:ノーマル単発 */
  singleNormalAnimationPath?: Maybe<Scalars['String']>
  /** レアリティ別アニメーション:レア単発 */
  singleRareAnimationPath?: Maybe<Scalars['String']>
  /** 4種アニメーション:当たり単発 */
  singleSpecialAnimationPath?: Maybe<Scalars['String']>
  /** レアリティ別アニメーション:スーパーレア単発 */
  singleSuperrareAnimationPath?: Maybe<Scalars['String']>
  /** レアリティ別アニメーション:ウルトラレア単発 */
  singleUltrarareAnimationPath?: Maybe<Scalars['String']>
  /**
   * 4種アニメーション:当たり判定とするレアリティ（rare, superrare, ultrarare, limitedのどれか）
   * レアリティ別アニメーションの場合は設定不要
   */
  specialAnimationRarity?: Maybe<Scalars['String']>
}

/** ガチャチケット引くInput */
export type RollGachaInput = {
  /** 引く回数 */
  count?: Maybe<Scalars['Int']>
  /** ガチャチケットID */
  gachaTicketId?: Maybe<Scalars['String']>
  /**
   * ロール種別
   * 指定がない場合はガチャチケット
   */
  rollType?: Maybe<GachaRollType>
}

/** 売上レポート */
export type SalesReport = {
  /** 残高 */
  balance: Scalars['Int']
  formattedBalance?: Maybe<Scalars['String']>
  formattedTotalSales?: Maybe<Scalars['String']>
  /** 直近30日間の売上 */
  last30Days: Last30DaysSalesReport
  /** アイテムの売上 */
  store: TotalSalesReportDetail
  /** メンバーシップでの売上 */
  subscription: TotalSalesReportDetail
  /** 投げ銭の売上 */
  tip: TotalSalesReportDetail
  /** 手数料控除後の売上 */
  totalSales: Scalars['Int']
  /** 手数料控除後の売上 */
  totalSalesWithoutFee: Scalars['Int']
}

/** 販売状態 */
export enum SalesStatus {
  Active = 'active',
  Stopped = 'stopped',
}

/** 販売方法 */
export enum SalesType {
  /** Multi generate serial QR code limited items */
  MultiSerial = 'multiSerial',
  /** 基本的な販売アイテム */
  Public = 'public',
  /** シリアルQRコード限定のアイテム */
  Serial = 'serial',
}

/** Oauth Token保存Input */
export type SaveOauthTokenInput = {
  /** トークン */
  oauthToken: Scalars['String']
  /** トークンシークレット */
  oauthTokenSecret: Scalars['String']
  /** 連携先ID */
  providerId: ProviderType
}

/** 座席 */
export type Seat = {
  /**
   * 座席連番表現用のマーカー
   * nullの場合は連番の中間座席
   */
  consecutive?: Maybe<SeatConsecutiveLabel>
  /** 割当て無効な席かどうか */
  disabled?: Maybe<Scalars['Boolean']>
  /** 列名 */
  line?: Maybe<Scalars['String']>
  /** 座席番号、座席名 */
  seat: Scalars['String']
  /** チケットシリアルUID */
  ticketSerialUid?: Maybe<Scalars['String']>
}

/** 座席連番表現用のマーカー */
export enum SeatConsecutiveLabel {
  /** 座席連番終了 */
  End = 'end',
  /** 座席連番開始 */
  Start = 'start',
}

/** 座席Input */
export type SeatInput = {
  /** 連番表現用マーカー */
  consecutive?: Maybe<SeatConsecutiveLabel>
  /** 割当て無効な席かどうか */
  disabled?: Maybe<Scalars['Boolean']>
  /** 列番号 */
  line?: Maybe<Scalars['String']>
  /** 座席番号、座席名 */
  seat: Scalars['String']
}

/**
 * 座席セクション
 * 連番が形成可能な座席をまとめたもの
 * このseatsの長さ以内であれば連番で座席を形成できる
 */
export type SeatSection = {
  /** セクションの座席 */
  seats: Array<Seat>
  /** セクション名 */
  sectionName: Scalars['String']
}

/**
 * 座席セクション情報Input
 * 連番が形成可能な座席をまとめたもの
 * このseatsの長さ以内であれば連番で座席を形成できる
 */
export type SeatSectionInput = {
  /** セクションの座席 */
  seats: Array<SeatInput>
  /** セクション名 */
  sectionName: Scalars['String']
}

/** 会場の座席テンプレート */
export type SeatTemplate = {
  /** 会場のキャパ */
  capacity?: Maybe<Scalars['Int']>
  /** 会場名 */
  hallName: Scalars['String']
  /** ID */
  id: Scalars['ID']
  /** 会場の位置情報 */
  location: GpsData
  /** テンプレート名 */
  name: Scalars['String']
  /** 座席セクション */
  seatSections: Array<SeatSection>
}

/** 座席割当て時のセクション並び順 */
export enum SectionOrder {
  /** 昇順 */
  Asc = 'asc',
  /** 降順 */
  Desc = 'desc',
  /** ランダム選択 */
  Random = 'random',
}

/** シリアル情報 */
export type Serial = {
  authentication?: Maybe<SerialAuthentication>
  createdAt: Scalars['Date']
  id: Scalars['ID']
  isProtected?: Maybe<Scalars['Boolean']>
  issueCount?: Maybe<Scalars['Int']>
  /** シリアルのメタデータ */
  metadata?: Maybe<SerialMetadata>
  skuId?: Maybe<Scalars['String']>
  ticketStatus?: Maybe<TicketStatus>
  updatedAt: Scalars['Date']
}

/**
 * シリアル番号の認可情報
 * 認可済み(獲得済み)だとauthenticatedAtに認可した時間が入る
 */
export type SerialAuthentication = {
  authenticatedAt?: Maybe<Scalars['Date']>
  /** シリアルアイテムの獲得期限 */
  expiredAt: Scalars['Date']
  /** シリアルを獲得できるページヘのURL */
  link?: Maybe<Scalars['String']>
  /** QRコードの画像情報 */
  qrcode?: Maybe<Scalars['String']>
}

/** シリアル一覧取得時のfilter条件 */
export type SerialFilter = {
  ticketUsed?: Maybe<Scalars['Boolean']>
}

/** シリアルのメタデータ */
export type SerialMetadata = {
  /** チケットのメタデータ */
  ticket?: Maybe<SerialMetadataTicket>
  /** 背景のメタデータ */
  wallpaper?: Maybe<SerialMetadataWallpaper>
}

/** シリアルのメタデータ（チケット） */
export type SerialMetadataTicket = {
  /** ユーザーの氏名（購入時点のもの、転売防止のため後から変更不可） */
  fullName?: Maybe<Scalars['String']>
}

/** シリアルのメタデータ（背景） */
export type SerialMetadataWallpaper = {
  /** コンビニプリント設定 */
  print?: Maybe<SerialMetadataWallpaperPrint>
}

/** シリアルのメタデータ（背景）のプリント設定 */
export type SerialMetadataWallpaperPrint = {
  /** シリアルコード */
  contentsCode?: Maybe<Scalars['String']>
  /** QRコードの有効期限 */
  expiredAt?: Maybe<Scalars['Date']>
  /** QRコードの画像リンク */
  qrcode?: Maybe<Image>
}

/** Short Link */
export type ShortLink = Node & {
  /** 作成日時 */
  createdAt: Scalars['Date']
  /** DocId */
  id: Scalars['ID']
  /** リダイレクトする本当のURL ex) https://xxx.com/yyyyy/zzzzzz */
  redirectUrl: Scalars['String']
  /** 短縮URL表記 ex) https://xxx.com/ulink/:id */
  shortLink: Scalars['String']
}

/** アーティストサインアップInput */
export type SignupAsArtistInput = {
  /** アカウントID */
  accountId: Scalars['String']
  /** 表示名 */
  displayName: Scalars['String']
  /** Email */
  email: Scalars['String']
  /**
   * キャンペーンにエントリーする場合のコード
   * CampaignsのドキュメントID
   */
  entry?: Maybe<Scalars['String']>
  /** 招待コード */
  invitationCode?: Maybe<Scalars['String']>
  /** パスワード */
  password: Scalars['String']
}

/** ユーザーサインアップInput */
export type SignupAsUserInput = {
  /** アカウントID */
  accountId?: Maybe<Scalars['String']>
  /** 表示名 */
  displayName: Scalars['String']
  /** Email */
  email: Scalars['String']
  /** パスワード */
  password: Scalars['String']
}

/** SKU設定 */
export type SkuSettings = {
  /** SKU表示名 ex) Size/Color, サイズ/色 etc */
  keyName?: Maybe<Scalars['String']>
  /** SKU設定 */
  units: Array<SkuUnit>
}

/** SKU設定インプット */
export type SkuSettingsInput = {
  /** SKU表示名 例）サイズ/色 */
  keyName?: Maybe<Scalars['String']>
  /** SKU詳細リスト */
  units?: Maybe<Array<SkuUnitInput>>
}

/** SKU個別設定 */
export type SkuUnit = {
  /** キャンセルされた数 */
  canceledNumber?: Maybe<Scalars['Int']>
  formattedPrice?: Maybe<Scalars['String']>
  /** SKU ID( {itemId}:{random_string} ) */
  id: Scalars['ID']
  /** 並び順 */
  order: Scalars['Int']
  /** 仮購入数（コンビニ決済支払い待ち） */
  pendingPurchaseNumber?: Maybe<Scalars['Int']>
  /**
   * SKUの価格
   * この設定がない場合は、item.priceと同じ価格となる
   */
  price?: Maybe<Scalars['Int']>
  /** 購入済み数 */
  purchasedNumber?: Maybe<Scalars['Int']>
  remainingNumber?: Maybe<Scalars['Int']>
  /** SKUの合計在庫数 */
  stock?: Maybe<Scalars['Int']>
  /** サムネイル画像 */
  thumbnail?: Maybe<Image>
  /** SKU名 ex) S/Black, M/ホワイト etc */
  unitName: Scalars['String']
}

/** SKU詳細インプット */
export type SkuUnitInput = {
  /** SKU ID(作成の場合は不要) */
  id?: Maybe<Scalars['ID']>
  /** 表示上の並び順 */
  order: Scalars['Int']
  /**
   * SKUの価格
   * 設定しない場合は item.price と同じ価格となる
   */
  price?: Maybe<Scalars['Int']>
  /** SKU在庫、無制限の場合はnull指定 */
  stock?: Maybe<Scalars['Int']>
  /** SKUの画像パス */
  thumbnail?: Maybe<Scalars['String']>
  /** SKU名 例）M/ブラック */
  unitName: Scalars['String']
}

/** SNSシェア */
export enum SocialShare {
  /** Twitterシェア */
  Twitter = 'twitter',
}

/** ガチャの引く前演出用アニメーションパス */
export type StandByAnimationsInput = {
  /** 10連ガチャ用 */
  bulkPath: Scalars['String']
  /** 単発ガチャ用 */
  singlePath: Scalars['String']
}

/** スタンドアロンアプリへのリンク */
export type StandaloneLink = {
  android?: Maybe<Scalars['String']>
  common?: Maybe<Scalars['String']>
  ios?: Maybe<Scalars['String']>
}

/** ライブ配信開始パラメーター */
export type StartLiveStreamInput = {
  withTwitter?: Maybe<Scalars['Boolean']>
}

/** 個人達成型自動ミッション */
export type StepMission = Node & {
  artistId: Scalars['String']
  createdAt: Scalars['Date']
  id: Scalars['ID']
  organizationId?: Maybe<Scalars['String']>
  /**
   * 自動ミッションの達成ステップの配列
   * 1つ1つのステップにクリア条件と報酬がある
   */
  steps: Array<MissionStep>
  /** 自動ミッション種別 */
  type: StepMissionType
  updatedAt?: Maybe<Scalars['Date']>
}

export enum StepMissionFilter {
  /** 達成済み */
  Accomplished = 'accomplished',
  /** 未達成 */
  NotAccomplished = 'not_accomplished',
}

/** 自動ミッションの報酬について */
export type StepMissionReward = {
  /** 報酬のメタデータ、idなど */
  metadata: StepMissionRewardMetadata
  /** ミッション報酬種別 */
  type: StepMissionRewardType
  /** 報酬値 */
  value: Scalars['Int']
}

/** 自動ミッションの報酬Input */
export type StepMissionRewardInput = {
  /** 報酬のメタデータ、idなど */
  metadata: StepMissionRewardMetadataInput
  /** ミッション報酬種別 */
  type: StepMissionRewardType
  /** 報酬値 */
  value: Scalars['Int']
}

/** 自動ミッションの報酬のメタデータ */
export type StepMissionRewardMetadata = {
  gachaTicket?: Maybe<GachaTicket>
  gachaTicketId?: Maybe<Scalars['String']>
  item?: Maybe<Item>
  itemId?: Maybe<Scalars['String']>
  prepaidPoint?: Maybe<PrepaidPoint>
  prepaidPointId?: Maybe<Scalars['String']>
}

/** 自動ミッションの報酬のメタデータInput */
export type StepMissionRewardMetadataInput = {
  gachaTicketId?: Maybe<Scalars['String']>
  itemId?: Maybe<Scalars['String']>
  prepaidPointId?: Maybe<Scalars['String']>
}

/** 自動ミッション報酬の種別 */
export enum StepMissionRewardType {
  /** アーティストポイント（無償ポイント） */
  ArtistPoint = 'artist_point',
  /** ガチャチケット */
  GachaTicket = 'gacha_ticket',
  /** アイテム */
  Item = 'item',
  /** プリペイドポイント */
  PrepaidPoint = 'prepaid_point',
}

/** 自動ミッション種別 */
export enum StepMissionType {
  /** コンプリートしたコレクションの個数によるミッション */
  CompletesCollections = 'completes_collections',
  /** メンバーシップ継続によるミッション */
  ContinuousMembership = 'continuous_membership',
  /** 所持しているアイテムの累計値によるミッション */
  SumCardsets = 'sum_cardsets',
  /** コメントした回数によるミッション */
  SumComments = 'sum_comments',
  /** 取得アイテムのレアリティ合計値によるミッション */
  SumRarities = 'sum_rarities',
  /** トレード回数によるミッション */
  SumTrades = 'sum_trades',
}

/**
 * IAPのストア
 * app_store  iOSアプリ内課金
 * play_store Androidアプリ内課金
 */
export enum StoreType {
  AppStore = 'app_store',
  PlayStore = 'play_store',
}

export type Subscription = {
  /** 新規コメント作成時のSubscription */
  liveStreamCommentCreated?: Maybe<Comment>
  /** ライブ配信いいねのSubscription */
  liveStreamLiked: Count
  /** 視聴者数が増えた場合のSubscription */
  liveStreamSubscribed: Count
}

export type SubscriptionLiveStreamCommentCreatedArgs = {
  id: Scalars['ID']
}

export type SubscriptionLiveStreamLikedArgs = {
  id: Scalars['ID']
}

export type SubscriptionLiveStreamSubscribedArgs = {
  id: Scalars['ID']
}

export type ThreadChannelSettings = {
  commentThread?: Maybe<NotificationChannels>
  replyCommentThread?: Maybe<NotificationChannels>
}

/** スレッドステータス */
export enum ThreadStatus {
  Archived = 'archived',
  Draft = 'draft',
  Published = 'published',
  Unpublished = 'unpublished',
}

export type ThroughTicketUsage = {
  /** '0', '1', '2'...subTicketのインデックス */
  subTicketId: Scalars['String']
  /** 利用済みカウント */
  usedNumber: Scalars['Int']
}

/** サムネイルInput */
export type ThumbnailInput = {
  tmpPath: Scalars['String']
}

/** チケットの分配データ */
export type TicketDistribution = {
  /** ID */
  id: Scalars['ID']
  /** アイテム */
  item: Item
  /** アイテムID */
  itemId: Scalars['String']
  /** 親ユーザー */
  user: User
  /** 親ユーザーID */
  userId: Scalars['String']
}

/** チケット分配リンクデータ */
export type TicketDistributionLink = {
  /** 親の分配データID */
  distributionId: Scalars['String']
  /** 分配リンクの期限 */
  expiredAt: Scalars['Date']
  formattedExpiredAt?: Maybe<Scalars['String']>
  /** ID */
  id: Scalars['ID']
  isExpired?: Maybe<Scalars['Boolean']>
  /** アイテム */
  item: Item
  /** アイテムID */
  itemId: Scalars['String']
  /** シリアル */
  serial: ItemSerial
  /** シリアルID */
  serialId: Scalars['String']
  /** URLのワンタイムトークン */
  token: Scalars['String']
  /** 分配を受け取った子ユーザー */
  user?: Maybe<User>
  /** 子ユーザーID */
  userId?: Maybe<Scalars['String']>
}

/** チケット獲得後の有効期限 */
export enum TicketItemExpirationTerm {
  Halfyear = 'halfyear',
  Oneyear = 'oneyear',
}

/** チケットの整理番号種別 */
export enum TicketItemMetadataAssignedNumberType {
  /** 入場整理番号 */
  Admission = 'admission',
  /** 座席番号 */
  Seat = 'seat',
}

/** チケットアイテムInput */
export type TicketItemMetadataInput = {
  /**
   * 整理番号の接頭辞
   * ない場合は指定しない
   */
  assignedNumberPrefix?: Maybe<Scalars['String']>
  /** 整理番号種別 */
  assignedNumberType?: Maybe<TicketItemMetadataAssignedNumberType>
  /** 説明 */
  description?: Maybe<Scalars['String']>
  /** 分配可能かどうか */
  distributionEnabled?: Maybe<Scalars['Boolean']>
  /** 分配可能な場合の分配終了日時 */
  distributionEndedAt?: Maybe<Scalars['Date']>
  /**
   * チケット獲得後の有効期限種別
   * ユーザーのチケット獲得時に自動的に期限設定されるため、編集での更新は不可
   */
  expirationTerm?: Maybe<TicketItemExpirationTerm>
  /** 通し券かどうか */
  isThroughTicket?: Maybe<Scalars['Boolean']>
  /** 注意文言 */
  note?: Maybe<Scalars['String']>
  /**
   * リセール期間
   * resaleEnabledの場合は必須
   */
  resalePeriod?: Maybe<ReceptionPeriodInput>
  /** 座席公開日 */
  seatPublishedAt?: Maybe<Scalars['Date']>
  /**
   * 整理番号 または 座席番号の開始番号
   * 最小は1（1から開始）
   */
  startAssignedNumber?: Maybe<Scalars['Int']>
  /** 通し券の場合の設定 */
  throughTicket?: Maybe<Array<TicketItemMetadataThroughTicketInput>>
}

/** チケットアイテムのメタデータ：通し券情報 */
export type TicketItemMetadataThroughTicket = {
  name: Scalars['String']
}

/** 通し券 */
export type TicketItemMetadataThroughTicketInput = {
  /**
   * 名称（日付などの表示文字列）
   * チケット利用時もこの値を利用する
   */
  name: Scalars['String']
}

export type TicketItemSerials = {
  itemId: Scalars['String']
  serialIds: Array<Scalars['String']>
}

/** チケットアイテム、シリアルインプット */
export type TicketItemSerialsInput = {
  /** アイテムID */
  itemId: Scalars['String']
  /** シリアルID配列 */
  serialIds: Array<Scalars['String']>
  /** 通し券の場合、サブチケット名称を指定 */
  throughTicketName?: Maybe<Scalars['String']>
}

/** チケット使用時の受け取り予約情報 */
export type TicketPickupInput = {
  /** コレクションID */
  collectionId: Scalars['String']
  /** イベントID */
  eventId: Scalars['String']
  /** 受け取り予約ID */
  pickupUserId: Scalars['String']
}

/** チケット利用状況 */
export type TicketStatus = {
  canUseTicket: Scalars['Boolean']
  /**
   * 有効期限期限（獲得日起点のためシリアルごとに変わる）
   * nullの場合は無期限
   */
  expiredAt?: Maybe<Scalars['Date']>
  formattedExpiredAt?: Maybe<Scalars['String']>
  formattedUsedAt?: Maybe<Scalars['String']>
  isExpired: Scalars['Boolean']
  /** チケットが利用済かどうか */
  isUsed?: Maybe<Scalars['Boolean']>
  /** 通し券の場合の個別利用状況 */
  throughTicket?: Maybe<Array<TicketStatusThroughTicket>>
  /** チケット利用日時 */
  usedAt?: Maybe<Scalars['Date']>
}

/** シリアルの通し券の場合の利用状況 */
export type TicketStatusThroughTicket = {
  formattedUsedAt?: Maybe<Scalars['String']>
  /** 配列のインデックス */
  index: Scalars['Int']
  /** サブチケット利用済みかどうか */
  isUsed: Scalars['Boolean']
  /** 親のアイテムID */
  itemId: Scalars['String']
  /** 通し券のサブチケット名称 */
  name?: Maybe<Scalars['String']>
  /** 座席指定 */
  seat?: Maybe<Scalars['String']>
  /** サブチケット利用日時 */
  usedAt?: Maybe<Scalars['Date']>
}

/** アイテムと座席セクションIDのマッピングInput */
export type TicketUidSeatSectionInput = {
  /** チケットUID配列 */
  ticketUids: Array<Scalars['String']>
}

/** チケット利用状況 */
export type TicketUsage = {
  /** 未使用数 */
  unusedCount: Scalars['Int']
  /** 使用済み数 */
  usedCount: Scalars['Int']
}

/** 投げ銭情報 */
export type Tip = {
  points: Scalars['Int']
}

/** 投げ銭インプット */
export type TipInput = {
  /** ポイントで投げ銭 */
  points?: Maybe<Scalars['Int']>
}

/** 合計売上 */
export type TotalSales = {
  /** 手数料控除後の売上 */
  totalSales: Scalars['Int']
  /** 手数料控除前の売上 */
  totalSalesWithoutFee: Scalars['Int']
}

/** トレード可能なユーザー */
export type TradableUser = Node & {
  /** ユーザーID */
  id: Scalars['ID']
  /** ユーザー保有のシリアル */
  serials: Array<UserItemSerial>
  /** ユーザー */
  user: User
}

/** トレードされる候補 */
export type TradeCandidate = Node & {
  /** トレード申請したユーザーが欲しいアイテム配列 */
  candidateItems: Array<TradeItem>
  /** トレードされたユーザーが保持している保有数 */
  candidateItemsOwnedCount: Array<TradeItemOwnedCount>
  createdAt: Scalars['Date']
  /** トレードの期限（デフォルト1週間） */
  expiredAt: Scalars['Date']
  id: Scalars['ID']
  /** トレード交渉ステータス */
  negotiationStatus: TradeCandidateNegotiationStatus
  /** トレード交渉データ（ドキュメントのルート） */
  tradeNegotiation: TradeNegotiation
  /** トレード交渉IDで */
  tradeNegotiationId: Scalars['String']
  updatedAt: Scalars['Date']
  /** トレード申請されたユーザー */
  user?: Maybe<User>
  /** トレード申請されたユーザーID */
  userId: Scalars['String']
}

/** 候補ユーザーごとのトレード交渉ステータス */
export enum TradeCandidateNegotiationStatus {
  /** 成立 */
  Accepted = 'accepted',
  /** キャンセル */
  Dropped = 'dropped',
  /** 期限切れ */
  Expired = 'expired',
  /** 交渉中 */
  InProgress = 'in_progress',
  /** 却下 */
  Rejected = 'rejected',
}

/** トレードするアイテムとシリアルIDの組み合わせ */
export type TradeItem = {
  /** アイテム */
  item: Item
  /** アイテムID */
  itemId: Scalars['String']
  /** アイテムシリアルデータ */
  itemSerial: ItemSerial
  /** シリアルID */
  serialId: Scalars['String']
}

/** トレードするアイテムとシリアルIDの組み合わせ */
export type TradeItemInput = {
  itemId: Scalars['String']
  serialId: Scalars['String']
}

/** トレード対象アイテムの保有数 */
export type TradeItemOwnedCount = {
  /** アイテムID */
  itemId: Scalars['String']
  /** 保有数 */
  ownedCount: Scalars['Int']
}

/** トレード交渉 */
export type TradeNegotiation = Node & {
  /** アーティストデータ */
  artist: Artist
  artistId: Scalars['String']
  /** トレードされるユーザー候補 */
  candidates: Array<TradeCandidate>
  /**
   * トレードコメント
   * Node: Comment
   */
  comments: PageResult
  completedAt?: Maybe<Scalars['Date']>
  createdAt: Scalars['Date']
  description?: Maybe<Scalars['String']>
  expiredAt: Scalars['Date']
  formattedCreatedAt?: Maybe<Scalars['String']>
  formattedExpiredAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** 申請したユーザーがのリクエストデータ */
  request: TradeRequest
  status: TradeNegotiationStatus
  title?: Maybe<Scalars['String']>
  type: TradeNegotiationType
  updatedAt: Scalars['Date']
}

/** トレード交渉 */
export type TradeNegotiationCommentsArgs = {
  pageInput?: Maybe<PageInput>
}

/** トレード交渉ステータス */
export enum TradeNegotiationStatus {
  /** 成立 */
  Agreed = 'agreed',
  /** 不成立 */
  BrokenOff = 'broken_off',
  /** 完了 */
  Completed = 'completed',
  /** キャンセル */
  Dropped = 'dropped',
  /** エラー */
  Error = 'error',
  /** 交渉中 */
  InProgress = 'in_progress',
}

/** トレードの申請タイプ */
export enum TradeNegotiationType {
  /** トレード募集 */
  Call = 'call',
  /** トレード申請 */
  Request = 'request',
}

/** トレードリクエスト */
export type TradeRequest = Node & {
  /** アーティスト */
  artist?: Maybe<Artist>
  /** アーティストID */
  artistId: Scalars['String']
  id: Scalars['ID']
  /** トレード申請するユーザーが提供するアイテム配列 */
  providableItems: Array<TradeItem>
  /** トレードしたユーザーが提供するアイテムの保有数 */
  providableItemsOwnedCount: Array<TradeItemOwnedCount>
  /** トレード申請したユーザーがリクエストしたアイテム */
  requestItem?: Maybe<Item>
  /** トレードでリクエストしたアイテムId */
  requestItemId: Scalars['String']
  /** トレード申請したユーザー */
  requestedByUser?: Maybe<User>
  /** トレード申請したユーザーID */
  requestedByUserId: Scalars['String']
  /** トレード申請データ（ドキュメントのルートデータ） */
  tradeNegotiation: TradeNegotiation
  /** トレード交渉ID */
  tradeNegotiationId: Scalars['String']
}

export type TradeSettings = {
  agreedTrade?: Maybe<NotificationChannels>
  commentTrade?: Maybe<NotificationChannels>
  declinedTrade?: Maybe<NotificationChannels>
  requestTrade?: Maybe<NotificationChannels>
}

/** トレードタイプ */
export enum TradeType {
  /** ユーザー同士の交換 */
  Trade = 'trade',
  /** アイテムをユーザーAからユーザーBに付け替え */
  Transfer = 'transfer',
}

/** アイテムトレードの対象アイテム詳細 */
export type TradedItemInput = {
  /** トレード対象のItem Document ID */
  itemId: Scalars['String']
  /** トレード対象のSerial Document ID */
  serialId: Scalars['String']
}

/** アイテムトレードのユーザーとitem情報 */
export type TradedItemSerialInput = {
  /** トレードするアイテム */
  items?: Maybe<Array<TradedItemInput>>
  /** トレードするUser Document ID */
  userId: Scalars['String']
}

/** アーティストの決済履歴 */
export type Transaction = Node & {
  /** 決済金額 */
  amount: Scalars['Int']
  /** 現在の残高 */
  balance: Scalars['Int']
  /** 現在の振込待ち金額 */
  balanceInPayout?: Maybe<Scalars['Int']>
  createdAt: Scalars['Date']
  formattedAmount?: Maybe<Scalars['String']>
  formattedBalance?: Maybe<Scalars['String']>
  formattedBalanceInPayout?: Maybe<Scalars['String']>
  formattedCreatedAt?: Maybe<Scalars['String']>
  formattedTotalBalance?: Maybe<Scalars['String']>
  formattedTotalBalanceNumber?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  /** 備考 */
  note: Scalars['String']
  type: Scalars['String']
}

/** ミッションtypeがtwitter_followの場合のコンテンツ */
export type TwitterFollowContent = {
  /** 画像 */
  image?: Maybe<Image>
  /**
   * フォロー対象twitterのdescription
   * サーバーで自動的に取得
   */
  twitterDescription?: Maybe<Scalars['String']>
  /**
   * フォロー対象twitterのtwitterName
   * サーバーで自動的に取得
   */
  twitterName?: Maybe<Scalars['String']>
  /**
   * フォロー対象twitterのprofile画像
   * サーバーで自動的に取得
   */
  twitterProfileImage?: Maybe<Image>
  /** フォロー対象のtwitterId */
  twitterUserId?: Maybe<Scalars['String']>
  /**
   * フォロー対象twitterのtwitterUserName
   * サーバーで自動的に取得
   */
  twitterUserName?: Maybe<Scalars['String']>
}

/** ミッションtypeがtwitter_retweetの場合のコンテンツ情報 */
export type TwitterRetweetContent = {
  /** 画像 */
  image?: Maybe<Image>
  /**
   * ツイート作成日
   * サーバーでツイートIDから自動的に取得
   */
  tweetCreatedAt?: Maybe<Scalars['Date']>
  /** ツイートID */
  tweetId?: Maybe<Scalars['String']>
  /**
   * ツイートテキスト
   * サーバーでツイートIDから自動的に取得
   */
  tweetText?: Maybe<Scalars['String']>
  /**
   * ツイートしたユーザーのtwitterName
   * サーバーでツイートIDから自動的に取得
   */
  twitterName?: Maybe<Scalars['String']>
  /**
   * ツイートしたユーザーのtwitterプロフィール画像
   * サーバーでツイートIDから自動的に取得
   */
  twitterProfileImage?: Maybe<Image>
  /**
   * ツイートしたユーザーのtwitterId
   * サーバーでツイートIDから自動的に取得
   */
  twitterUserId?: Maybe<Scalars['String']>
  /**
   * ツイートしたユーザーのtwitterUserName
   * サーバーでツイートIDから自動的に取得
   */
  twitterUserName?: Maybe<Scalars['String']>
}

/** イベント用の受け取りチケット未使用インプット */
export type UnuseEventPickupTicketsInput = {
  /** イベントコレクションID */
  collectionId: Scalars['String']
  /** イベントID */
  eventId: Scalars['String']
  /** 受け取り予約ID */
  pickupUserId: Scalars['String']
}

/** チケットアイテム未使用インプット */
export type UnuseTicketInput = {
  /** アイテムID */
  itemId: Scalars['String']
  /** シリアルID */
  serialId: Scalars['String']
}

/** チケットアイテム未使用インプット */
export type UnuseTicketsInput = {
  /** 未使用に戻すアイテム、シリアル配列 */
  items: Array<TicketItemSerialsInput>
  /** 受け取り予約がある場合に指定 */
  pickup?: Maybe<TicketPickupInput>
}

/** アーティストのsns保存Input */
export type UpdateArtistSnsInput = {
  /** facebook */
  facebook?: Maybe<Scalars['String']>
  /** instagram */
  instagram?: Maybe<Scalars['String']>
  /** tiktok */
  tiktok?: Maybe<Scalars['String']>
  /** twitter */
  twitter?: Maybe<Scalars['String']>
  /** youtube */
  youtube?: Maybe<Scalars['String']>
}

/** アーティストのテキストリンク保存Input */
export type UpdateArtistTextLinkInput = {
  /** プロフィールテキスト本文 */
  content: Scalars['String']
  /** 外部リンクurl */
  uri: Scalars['String']
}

/** アーティストスレッドの更新Input */
export type UpdateArtistThreadInput = {
  category?: Maybe<ArtistThreadCategory>
  description?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  status?: Maybe<ThreadStatus>
  thumbnail?: Maybe<ThumbnailInput>
}

/** キャンペーン自動エントリーの条件と画像テンプレート情報 */
export type UpdateCampaignEntryConditionInput = {
  collectionInfo?: Maybe<CampaignCollectionInfoInput>
  entryCondition?: Maybe<CampaignEntryConditionInput>
  gachaInfo?: Maybe<CampaignGachaInfoInput>
  imageTemplates?: Maybe<CampaignImageTemplatesInput>
}

/** キャンペーン更新Input */
export type UpdateCampaignInput = {
  description?: Maybe<Scalars['String']>
  endedAt?: Maybe<Scalars['Date']>
  organizationId?: Maybe<Scalars['String']>
  rankingType?: Maybe<Scalars['String']>
  startedAt?: Maybe<Scalars['Date']>
  thumbnailPath?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

/** コレクション更新Input */
export type UpdateCollectionInput = {
  description?: Maybe<Scalars['String']>
  /** 公開終了日 */
  endedAt?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  /** ストアで公開するかどうか */
  isPublishOnStore?: Maybe<Scalars['Boolean']>
  /** コレクションのラベル */
  labels?: Maybe<Array<Maybe<CollectionLabelInput>>>
  /** NFT情報(チェーン別) */
  nft?: Maybe<CollectionNftInput>
  /** 公開開始日 */
  startedAt?: Maybe<Scalars['Date']>
  /** 画像パス */
  thumbnailPath?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

/** コメント更新Input */
export type UpdateCommentInput = {
  /** コメント内容 */
  content: Scalars['String']
  /** 画像などのリソース */
  resource?: Maybe<CommentResourceInput>
}

/** カスタムプロフィールのInput */
export type UpdateCustomProfileInput = {
  /** アバターアイテムのDocumentID */
  avatarItemId?: Maybe<Scalars['String']>
  /** 壁紙アイテムのDocumentID */
  wallpaperItemId?: Maybe<Scalars['String']>
}

/** @deprecated 今後はUserDeliveryInputを使ってください */
export type UpdateDeliveryInput = {
  /** @deprecated 今後はUserDeliveryInputを使ってください */
  address: Scalars['String']
  /** @deprecated 今後はUserDeliveryInputを使ってください */
  name: Scalars['String']
  /** @deprecated 今後はUserDeliveryInputを使ってください */
  postalCode: Scalars['String']
  /** @deprecated 今後はUserDeliveryInputを使ってください */
  prefecture: Scalars['String']
}

/** イベントコレクションの受け取り時間を更新するInput */
export type UpdateEventCollectionPickupTimeInput = {
  /** コレクションID */
  collectionId: Scalars['String']
  /** イベントID */
  eventId: Scalars['ID']
  /** 受け取り場所 */
  pickupLocation?: Maybe<Scalars['String']>
  /** 受け取り日時配列 */
  pickupTime: Array<EventPickupTimeInput>
  /** 受け渡し商品数の上限 */
  purchaseLimitPerPickup?: Maybe<Scalars['Int']>
  /** 受け渡し有効時間 */
  validMinutes?: Maybe<Scalars['Int']>
}

/** ガチャデータ更新Input */
export type UpdateGachaDataInput = {
  /** 公開する場合はtrue */
  isPublished: Scalars['Boolean']
}

/** ガチャ更新Input */
export type UpdateGachaInput = {
  /** 配達予定日 */
  deliveryDateText?: Maybe<Scalars['String']>
  /** 詳細名 */
  description?: Maybe<Scalars['String']>
  /** 終了日（指定しない場合は無期限） */
  endAt?: Maybe<Scalars['Date']>
  /** GPS情報 */
  gpsData?: Maybe<GpsDataInput>
  /** 有料会員向けかどうか */
  isPremium?: Maybe<Scalars['Boolean']>
  /** 名称 */
  name?: Maybe<Scalars['String']>
  /** 複数メンバーシップある場合の閲覧制限 */
  planId?: Maybe<Scalars['String']>
  /**
   * 4種対応版
   * ロール時のアニメーションパス
   */
  rewardAnimations?: Maybe<RewardAnimationsInput>
  /** ロール時のアニメーションパス */
  rollAnimationPath?: Maybe<Scalars['String']>
  /** 引く前のアニメーションパス */
  standByAnimations?: Maybe<StandByAnimationsInput>
  /** 開始日（指定しない場合は現在時刻が自動的に入る） */
  startAt?: Maybe<Scalars['Date']>
  /** サムネイルパス */
  thumbnailPath?: Maybe<Scalars['String']>
}

/** ガチャアイテム更新Input */
export type UpdateGachaItemsInput = {
  /** ガチャアイテム配列 */
  gachaItems: Array<GachaItemInput>
}

/** ガチャレアリティInput */
export type UpdateGachaRaritiesInput = {
  /** 出現確率配列 */
  rarities: Array<RarityInput>
}

/** アイテム画像更新Input */
export type UpdateItemImageInput = {
  /** 更新する画像のcaption */
  caption?: Maybe<Scalars['String']>
  /**
   * 現在設定されいているpath
   * 指定しない場合は新規追加となる
   */
  currentPath?: Maybe<Scalars['String']>
  /**
   * 更新する画像のtmpPath
   * currentPathのみ指定し、tmpPathを指定しない場合は削除される
   */
  tmpPath?: Maybe<Scalars['String']>
}

/** ミッション更新Input */
export type UpdateMissionInput = {
  /** ミッションコンテンツ */
  content?: Maybe<QuizContentInput>
  /** ミッション説明 */
  description?: Maybe<Scalars['String']>
  /** 期限 */
  expiredAt?: Maybe<Scalars['Date']>
  /** GPS情報 */
  gpsData?: Maybe<GpsDataInput>
  /** 公開する場合にtrue */
  isPublished?: Maybe<Scalars['Boolean']>
  /** OrganizationId (指定しない場合はnullが設定される) */
  organizationId?: Maybe<Scalars['String']>
  /** 報酬 */
  reward?: Maybe<MissionRewardInput>
  /** ミッション開始日時 */
  startedAt?: Maybe<Scalars['Date']>
  /** ミッションタイトル */
  title?: Maybe<Scalars['String']>
  /**
   * ミッション種別
   * | 'quiz'
   * | 'twitter_retweet'
   * | 'twitter_follow'
   * | 'item_collect'
   */
  type?: Maybe<MissionType>
}

/** 支払いアカウント更新Input */
export type UpdatePaymentAccountInput = {
  email?: Maybe<Scalars['String']>
}

/** ポスト更新Input */
export type UpdatePostInput = {
  /** ポストのカテゴリー */
  categoryId?: Maybe<Scalars['String']>
  /** ポスト内容 */
  content?: Maybe<Scalars['String']>
  /** 有料会員向けの場合はtrue */
  isPremium?: Maybe<Scalars['Boolean']>
  /** 複数メンバーシップある場合の閲覧制限 */
  planId?: Maybe<Scalars['String']>
  /** 予約投稿日時 */
  publishedAt?: Maybe<Scalars['Date']>
}

/** 先行販売抽選結果一括更新インプット */
export type UpdatePreSaleLotteryResultFromCsvInput = {
  /** CSVのtmpパス */
  csvPath: Scalars['String']
  /** 先行販売ID */
  preSaleId: Scalars['ID']
  /** 結果通知予定日時 */
  resultNoticedAt: Scalars['Date']
}

/** プロフィール更新Input */
export type UpdateProfileInput = {
  /** アカウントID */
  accountId?: Maybe<Scalars['String']>
  /**
   * アバター画像のパス
   * storageのtmpファイルパス または 画像URL(png, jpg)
   */
  avatarPath?: Maybe<Scalars['String']>
  /** 生年月日 */
  birthday?: Maybe<Scalars['Date']>
  /** 表示名 */
  displayName?: Maybe<Scalars['String']>
  /** 性別 */
  gender?: Maybe<Scalars['String']>
  /** 自己紹介 */
  introduction?: Maybe<Scalars['String']>
}

/** サブスクリプション更新Input */
export type UpdateSubscriptionInput = {
  /** 年額プラン限定コンテンツ内容 */
  annualContent?: Maybe<Scalars['String']>
  /** Birthday message */
  birthdayMessage?: Maybe<Scalars['String']>
  /** メンバーシップ内容 */
  content?: Maybe<Scalars['String']>
  /** 更新対象のプランID */
  planId?: Maybe<Scalars['String']>
  /** 更新対象のプロダクトID */
  productId?: Maybe<Scalars['String']>
  /** プランのステータス */
  status?: Maybe<MembershipSubscriptionPlanStatus>
  /** タイトル */
  title?: Maybe<Scalars['String']>
}

/** 未読更新インプット */
export type UpdateUnreadInput = {
  /** アーティストID */
  artistId: Scalars['String']
  /** 親ID（スレッドIDなど） */
  parentId: Scalars['String']
  /** 未読種別 */
  userUnreadType: UserUnreadType
}

/** イベント用の受け取りチケット使用インプット */
export type UseEventPickupTicketsInput = {
  /** イベントコレクションID */
  collectionId: Scalars['String']
  /** イベントID */
  eventId: Scalars['String']
  /** 受け取り予約ID */
  pickupUserId: Scalars['String']
}

/** チケットアイテム使用インプット */
export type UseTicketInput = {
  /** アイテムID */
  itemId: Scalars['String']
  /** シリアルID */
  serialId: Scalars['String']
  /**
   * ユーザーID
   * 指定がない場合はログインユーザーとなる
   */
  userId?: Maybe<Scalars['String']>
}

/** チケットアイテム使用インプット */
export type UseTicketsInput = {
  /** 使用済みにするアイテム、シリアル配列 */
  items: Array<TicketItemSerialsInput>
}

/** 使用されたガチャチケット情報 */
export type UsedGachaTicket = {
  count: Scalars['Int']
  id: Scalars['String']
}

/**
 * 認証情報を持つアカウント
 * すべての認証アカウントはこの情報をもつ。
 * ログインユーザーの情報を表示する場合はAuth Provider、jwtからん０情報優先で利用してください。
 */
export type User = Node & {
  accomplishedMissions: PageResult
  /**
   * ユーザーでユニークなアカウントID
   * twitterと同じく@xxxxのように利用しています。
   */
  accountId?: Maybe<Scalars['String']>
  /** 住所情報 */
  address?: Maybe<UserAddress>
  /**
   * ユーザーPortfolio一覧(organizationIdが入っているもの)
   * type: | 'ticket'
   *       | 'audio'
   *       | 'video'
   *       | 'magazine'
   *       | 'avatar'
   *       | 'wallpaper'
   *       | 'merch'
   */
  artistPortfolios: PageResult
  /** ユーザーのアバター画像 */
  avatar?: Maybe<Avatar>
  /** 銀行情報（振込用） */
  bank?: Maybe<UserBank>
  birthday?: Maybe<Scalars['Date']>
  /** ブロックしたアーティストのID */
  blockedArtists: Array<Scalars['String']>
  /** ブロックしたユーザーのUser ID */
  blockedUsers: Array<Scalars['String']>
  credit?: Maybe<CreditCard>
  /** 暗号資産ウォレット連携情報 */
  cryptoWallet?: Maybe<CryptoWallet>
  customProfile?: Maybe<CustomProfile>
  /**
   * 配送先情報
   * @deprecated(reason: "addressに置き換えられました。今後は使用しないでください")
   */
  delivery?: Maybe<Delivery>
  displayName?: Maybe<Scalars['String']>
  /** ユーザーのメールアドレス */
  email?: Maybe<Scalars['String']>
  /**
   * ユーザーのフィード一覧
   * Node: Feed
   */
  feeds: PageResult
  /**
   * フォローしているアーティストの一覧
   * Node: Follower
   */
  followees: PageResult
  formattedAccountId?: Maybe<Scalars['String']>
  formattedBirthday?: Maybe<Scalars['String']>
  formattedPhoneNumber?: Maybe<Scalars['String']>
  /** 氏名 */
  fullName?: Maybe<Scalars['String']>
  /** ガチャオーダーの取得 */
  gachaOrder?: Maybe<GachaOrder>
  /** 所持ガチャチケットオブジェクト(Artist用のもの) */
  gachaTicket?: Maybe<UserGachaTicket>
  gender?: Maybe<Gender>
  hasManagementTask?: Maybe<Scalars['Boolean']>
  /** 未申請の配送アイテムがあるかどうか */
  hasUnappliedDeliveryItems: Scalars['Boolean']
  /** （コンビニ決済）支払い待ちのアイテムがあるかどうか */
  hasWaitingPaymentItems: Scalars['Boolean']
  /** （リセール）支払い待ちの当選アイテムがあるかどうか */
  hasWaitingResaleRafflePaymentItems: Scalars['Boolean']
  /**
   * ユーザーID
   * auth uidと同じIDを持つ
   */
  id: Scalars['ID']
  introduction?: Maybe<Scalars['String']>
  /** メンバーシップで個人情報登録が必要なものがあるかどうか */
  isMembershipPersonalInformationRequired?: Maybe<Scalars['Boolean']>
  /** 電話番号がSMS認証済みかどうか */
  isPhoneNumberVerified: Scalars['Boolean']
  /** プロフィール登録をしたか */
  isRegisteredProfile?: Maybe<Scalars['Boolean']>
  /** Organizationでフォローしているアーティストの一覧 */
  organizationFollowees: PageResult
  /** 所持ガチャチケットオブジェクト(Organization用のもの) */
  organizationGachaTicket?: Maybe<UserGachaTicket>
  /** Organizationでメンバーシップになっているアーティストの購読情報 */
  organizationPatrons: Array<Patron>
  /**
   * ユーザーのアーティスト単位のPortfolio一覧
   * @auth artist
   * type: | 'ticket'
   *       | 'audio'
   *       | 'video'
   *       | 'magazine'
   *       | 'avatar'
   *       | 'wallpaper'
   *       | 'merch'
   */
  organizationPortfolios: PageResult
  /** メンバーシップになっているアーティストの購読情報 */
  patrons: Array<Patron>
  /**
   * 電話番号
   * 認証はphone-number-verificationsで行う
   */
  phoneNumber?: Maybe<Scalars['String']>
  /** ユーザーPortfolio一覧(organizationIdが入っていないもの) */
  portfolios: PageResult
  /** 連携情報。現在はTwitteのみ */
  providers?: Maybe<Providers>
  /** 設定関連 */
  settings?: Maybe<UserSettings>
  /** 個別アプリ対応の変数 */
  standalone?: Maybe<UserStandalone>
  /** アカウントステータス */
  status?: Maybe<AccountStatus>
  /**
   * 通知の未読数
   * UserUnread(スレッドのメンション通知)は含まない
   */
  unreadNotificationCount: Scalars['Int']
}

/**
 * 認証情報を持つアカウント
 * すべての認証アカウントはこの情報をもつ。
 * ログインユーザーの情報を表示する場合はAuth Provider、jwtからん０情報優先で利用してください。
 */
export type UserAccomplishedMissionsArgs = {
  pageInput?: Maybe<PageInput>
}

/**
 * 認証情報を持つアカウント
 * すべての認証アカウントはこの情報をもつ。
 * ログインユーザーの情報を表示する場合はAuth Provider、jwtからん０情報優先で利用してください。
 */
export type UserArtistPortfoliosArgs = {
  option?: Maybe<PortfoliosOptionInput>
  pageInput?: Maybe<PageInput>
  type?: Maybe<ItemType>
}

/**
 * 認証情報を持つアカウント
 * すべての認証アカウントはこの情報をもつ。
 * ログインユーザーの情報を表示する場合はAuth Provider、jwtからん０情報優先で利用してください。
 */
export type UserFeedsArgs = {
  pageInput?: Maybe<PageInputDate>
  type?: Maybe<FeedType>
}

/**
 * 認証情報を持つアカウント
 * すべての認証アカウントはこの情報をもつ。
 * ログインユーザーの情報を表示する場合はAuth Provider、jwtからん０情報優先で利用してください。
 */
export type UserFolloweesArgs = {
  pageInput?: Maybe<PageInput>
}

/**
 * 認証情報を持つアカウント
 * すべての認証アカウントはこの情報をもつ。
 * ログインユーザーの情報を表示する場合はAuth Provider、jwtからん０情報優先で利用してください。
 */
export type UserGachaOrderArgs = {
  id: Scalars['ID']
}

/**
 * 認証情報を持つアカウント
 * すべての認証アカウントはこの情報をもつ。
 * ログインユーザーの情報を表示する場合はAuth Provider、jwtからん０情報優先で利用してください。
 */
export type UserGachaTicketArgs = {
  id: Scalars['ID']
}

/**
 * 認証情報を持つアカウント
 * すべての認証アカウントはこの情報をもつ。
 * ログインユーザーの情報を表示する場合はAuth Provider、jwtからん０情報優先で利用してください。
 */
export type UserOrganizationFolloweesArgs = {
  pageInput?: Maybe<PageInput>
}

/**
 * 認証情報を持つアカウント
 * すべての認証アカウントはこの情報をもつ。
 * ログインユーザーの情報を表示する場合はAuth Provider、jwtからん０情報優先で利用してください。
 */
export type UserOrganizationPortfoliosArgs = {
  option?: Maybe<PortfoliosOptionInput>
  pageInput?: Maybe<PageInput>
  type?: Maybe<ItemType>
}

/**
 * 認証情報を持つアカウント
 * すべての認証アカウントはこの情報をもつ。
 * ログインユーザーの情報を表示する場合はAuth Provider、jwtからん０情報優先で利用してください。
 */
export type UserPortfoliosArgs = {
  pageInput?: Maybe<PageInputDate>
}

/**
 * 認証情報を持つアカウント
 * すべての認証アカウントはこの情報をもつ。
 * ログインユーザーの情報を表示する場合はAuth Provider、jwtからん０情報優先で利用してください。
 */
export type UserStandaloneArgs = {
  artistId: Scalars['String']
}

/** 配送などの住所情報 */
export type UserAddress = {
  city: Scalars['String']
  fullAddress?: Maybe<Scalars['String']>
  line1: Scalars['String']
  line2?: Maybe<Scalars['String']>
  postalCode: Scalars['String']
  prefecture: Scalars['String']
  shortAddress?: Maybe<Scalars['String']>
}

/** 銀行口座 */
export type UserBank = {
  /** 口座名義 */
  accountHolderName: Scalars['String']
  /** 口座番号 */
  accountNumber: Scalars['String']
  /** 口座種別（普通・当座） */
  accountType: BankAccountType
  /** 銀行名 */
  bankName: Scalars['String']
  /** 支店コード */
  branchCode: Scalars['String']
  /** 支店名 */
  branchName: Scalars['String']
}

/** 銀行口座インプット */
export type UserBankInput = {
  accountHolderName: Scalars['String']
  accountNumber: Scalars['String']
  accountType: BankAccountType
  bankName: Scalars['String']
  branchCode: Scalars['String']
  branchName: Scalars['String']
}

/** ユーザー用キャンペーンランキング */
export type UserCampaignRanking = {
  createdAt?: Maybe<Scalars['Date']>
  formattedCreatedAt?: Maybe<Scalars['String']>
  id: Scalars['String']
  myRank?: Maybe<CampaignUserRanker>
  /**
   * ランキング
   * 同列ランクも考慮される
   */
  ranker: Array<CampaignUserRanker>
}

/** カート */
export type UserCart = Node & {
  /** アーティスト */
  artist: Artist
  /** アーティストID */
  artistId: Scalars['String']
  /**
   * 手数料
   * コンビニ決済を利用する場合はisKonbini: true
   */
  checkoutFees?: Maybe<CheckoutFees>
  /** カート作成日 */
  createdAt: Scalars['Date']
  formattedTotalPrice?: Maybe<Scalars['String']>
  /** カートID */
  id: Scalars['ID']
  /** カート内アイテム配列 */
  items: Array<UserCartItem>
  /** カート決済種別 */
  paymentType: UserPaymentType
  /** カート内のアイテム合計数 */
  totalCount: Scalars['Int']
  totalPrice: Scalars['Int']
  /** カート最終更新日 */
  updatedAt: Scalars['Date']
  /** ユーザー */
  user: User
  /** ユーザーID */
  userId: Scalars['String']
}

/** カート */
export type UserCartCheckoutFeesArgs = {
  isKonbini?: Maybe<Scalars['Boolean']>
}

/** カート内のアイテム */
export type UserCartItem = {
  /**
   * アイテムが所属するコレクション
   * なければnullが返される
   */
  collection?: Maybe<Collection>
  /** アイテム毎の合計 */
  count: Scalars['Int']
  formattedTotalPrice?: Maybe<Scalars['String']>
  /** アイテム */
  item: Item
  /** アイテムID */
  itemId: Scalars['String']
  /** アイテムSKU */
  skus: Array<UserCartItemSku>
  totalPrice: Scalars['Int']
}

/** アイテムSKU */
export type UserCartItemSku = {
  /** SKUアイテムの合計数 */
  count: Scalars['Int']
  /** SKU */
  sku: SkuUnit
  /** SKU ID */
  skuId: Scalars['String']
}

/** カート決済アイテム */
export type UserCartTransactionItem = {
  /** アイテムごとの合計支払い額 */
  amount: Scalars['Int']
  /** 購入数量 */
  count: Scalars['Int']
  item: Item
  /** アイテムID */
  itemId: Scalars['String']
  /** SKU（ある場合） */
  skus: Array<Maybe<UserCartItemSku>>
}

/** カートの決済履歴 */
export type UserCartTransactionOrder = Node & {
  /** アーティスト */
  artist?: Maybe<Artist>
  /** アーティストID */
  artistId?: Maybe<Scalars['String']>
  /** キャンセルデータ */
  cancels?: Maybe<Array<CartCancel>>
  /** 手数料 */
  checkoutFees?: Maybe<CheckoutFees>
  /** 作成日 */
  createdAt: Scalars['Date']
  formattedCreatedAt?: Maybe<Scalars['String']>
  formattedOriginalTotalAmount?: Maybe<Scalars['String']>
  formattedSaleProfit?: Maybe<Scalars['String']>
  formattedStoreFee?: Maybe<Scalars['String']>
  formattedTotalAmount?: Maybe<Scalars['String']>
  formattedTotalAmountWithoutFee?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** コンビニ決済による購入の場合にtrue */
  isKonbini: Scalars['Boolean']
  /** アイテムごとの獲得履歴 */
  itemOrderIds: Array<Scalars['String']>
  /** アイテム獲得履歴 */
  itemOrders: Array<ItemOrder>
  /** 購入したアイテム情報 */
  items: Array<UserCartTransactionItem>
  originalTotalAmount?: Maybe<Scalars['Int']>
  /** 決済種別 */
  paymentType: UserPaymentType
  /** コンビニ決済の場合の決済状況 */
  pendingPurchaseItem?: Maybe<PendingPurchaseItem>
  /** コンビニ決済の場合の決済状況ID */
  pendingPurchaseItemId?: Maybe<Scalars['String']>
  /** 予約済みの受け取り時間 */
  pickupUsers?: Maybe<Array<PickupUser>>
  /**
   * アーティストから徴収する販売手数料
   * デフォルト15%、またはアーティストごとに設定された割合
   */
  storeFee?: Maybe<Scalars['Int']>
  /** 合計支払い額（手数料もろもろ込み） */
  totalAmount: Scalars['Int']
  /** 手数料が差し引かれた純粋な商品のみの売上合計額 */
  totalAmountWithoutFee?: Maybe<Scalars['Int']>
  /** 取引ID（Stripeの場合はpaymentIntentId） */
  transferId?: Maybe<Scalars['String']>
}

/** ユーザーのコレクション情報 */
export type UserCollection = Node & {
  collection?: Maybe<Collection>
  createdAt: Scalars['Date']
  id: Scalars['ID']
  itemOwnedCount: Scalars['Int']
  ogp?: Maybe<Image>
  ownedItems?: Maybe<Array<UserCollectionItem>>
  totalOwnedCount: Scalars['Int']
  totalOwnedItemCount?: Maybe<Scalars['Int']>
  updatedAt: Scalars['Date']
  userId: Scalars['String']
}

export type UserCollectionItem = {
  itemId: Scalars['String']
  ownedCount: Scalars['Int']
  userItem?: Maybe<UserItem>
}

/** ユーザー配送先情報Input */
export type UserDeliveryInput = {
  /** 配送先住所 */
  address: DeliveryAddressInput
  /** 誕生日 */
  birthday?: Maybe<Scalars['Date']>
  /** ユーザーフルネーム */
  fullName: Scalars['String']
  /** 性別 */
  gender?: Maybe<Gender>
  /** ユーザー電話番号 */
  phoneNumber: Scalars['String']
}

/** User follow settings input */
export type UserFollowSettingsInput = {
  autoFollow: Scalars['Boolean']
}

/**
 * ユーザーが保有しているガチャチケット情報
 * Artist用のものとOrganization共通のものがある
 */
export type UserGachaTicket = {
  acquisition?: Maybe<Scalars['Int']>
  artistId?: Maybe<Scalars['String']>
  /** acquisition - used */
  available?: Maybe<Scalars['Int']>
  /** 初取得タイミング */
  createdAt?: Maybe<Scalars['Date']>
  formattedAvailable: Scalars['String']
  updatedAt?: Maybe<Scalars['Date']>
  used?: Maybe<Scalars['Int']>
}

/**
 * ユーザーが保有するアイテム
 * ユーザー一つのアイテムにつき、UserItemはひとつ
 */
export type UserItem = Node & {
  createdAt: Scalars['Date']
  /** uid:itemIdという形で生成される */
  id: Scalars['ID']
  /**
   * システムによるプロテクト中かどうか
   * 以下の場合trueとなる
   *   - カスタムプロフィール設定
   *   - 物販アイテム申請・出荷済み
   * プロテクトしている場合はトレードできない
   */
  isSystemProtected: Scalars['Boolean']
  /** チケットが利用済みかどうか */
  isUsed?: Maybe<Scalars['Boolean']>
  /**
   * ユーザー操作によるプロテクト中かどうか
   * プロテクトしている場合はトレードできない
   */
  isUserProtected: Scalars['Boolean']
  item: Item
  /** アイテム保有数 */
  number: Scalars['Int']
  /** ogp画像 */
  ogp?: Maybe<Image>
  /** チケットの場合にリセール可能かどうか */
  resaleEnabled?: Maybe<Scalars['Boolean']>
  /** シリアル情報 */
  serials: Array<UserItemSerial>
  /** アイテムの種類 */
  type: ItemType
  updatedAt: Scalars['Date']
  /** チケット使用済みアイテムの数 */
  usedNumber?: Maybe<Scalars['Int']>
  user: User
}

/** シリアル情報 */
export type UserItemSerial = {
  formattedObtainedDate?: Maybe<Scalars['String']>
  formattedObtainedDateTime?: Maybe<Scalars['String']>
  /** 表示用シリアルナンバー */
  formattedSerialNumber?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** NFT化されているかどうか */
  isNft?: Maybe<Scalars['Boolean']>
  /**
   * シリアルがロックされているか
   * trueの場合はトレード出来ない
   */
  isProtected?: Maybe<Scalars['Boolean']>
  /** 使用済みか */
  isUsed?: Maybe<Scalars['Boolean']>
  itemId: Scalars['String']
  /** nft-tokensのデータ */
  nftToken?: Maybe<NftToken>
  /**
   * シリアル取得日(=updatedAt)
   * トレード等やQR獲得で取得日が変わることがあるためupdatedAtを使っている
   */
  obtainedDate?: Maybe<Scalars['Date']>
  /** アイテムシリアル */
  serial?: Maybe<ItemSerial>
  serialId: Scalars['String']
  /** 通し券の場合の個別利用状況 */
  throughTicket?: Maybe<Array<TicketStatusThroughTicket>>
  /** NFTのtokenId */
  tokenId?: Maybe<Scalars['String']>
}

/** ユーザー通知設定Input */
export type UserNotificationSettingsInput = {
  /** 通知チャネル種別 */
  channel?: Maybe<NotificationChannelsType>
  /**
   * 通知を有効にする場合にtrue
   * default: true
   */
  flag?: Maybe<Scalars['Boolean']>
  /** 通知種別 */
  type: NotificationSettingsType
}

/**
 * Organization情報
 * アプリのregistrationTokenが入ります
 */
export type UserOrganization = {
  id: Scalars['ID']
  registrationTokens: Array<Scalars['String']>
}

/** ユーザー側の支払い種別 */
export enum UserPaymentType {
  /** クレジットカード・コンビニ払い */
  Cash = 'cash',
  /** プリペイドポイント */
  PrepaidPoint = 'prepaid_point',
}

/** チケットアイテムの受け取りステータス（ユーザー用） */
export enum UserPickupTicketStatus {
  /** すべて */
  All = 'all',
  /** 期限切れ */
  Expired = 'expired',
  /** 未使用 */
  Unused = 'unused',
  /** 使用済み */
  Used = 'used',
}

/** ユーザープライバシー設定Input */
export type UserPrivacySettingsInput = {
  /**
   * プライバシー設定を有効にする場合はtrue
   * default: false
   */
  flag?: Maybe<Scalars['Boolean']>
  /** 設定種別 */
  type: PrivacySettingsType
}

export type UserSettings = {
  follow?: Maybe<FollowSettings>
  notifications?: Maybe<NotificationSettings>
  privacy?: Maybe<PrivacySettings>
}

/**
 * スタンドアロン情報
 * アプリのregistrationTokenが入ります
 */
export type UserStandalone = {
  registrationTokens: Array<Scalars['String']>
}

/**
 * 自動ミッションに対応するユーザーの達成状況のデータ
 * 自動ミッションのステップと1:1で関連付く
 */
export type UserStepMission = Node & {
  accomplishedAt?: Maybe<Scalars['Date']>
  artistId: Scalars['String']
  createdAt: Scalars['Date']
  id: Scalars['ID']
  /** ミッションを達成しているかどうか */
  isAccomplished: Scalars['Boolean']
  /** 達成報酬を取得しているかどうか */
  isRewardReceived: Scalars['Boolean']
  label?: Maybe<MissionLabel>
  organizationId?: Maybe<Scalars['String']>
  /** 現在の進捗数 */
  progress: Scalars['Float']
  step: MissionStep
  type: StepMissionType
  updatedAt?: Maybe<Scalars['Date']>
}

/** ユーザーの未読・既読データ */
export type UserUnread = {
  id: Scalars['ID']
  /** 最新閲覧時間 */
  lastReadAt?: Maybe<Scalars['Date']>
  /** 親ID（スレッドIDなど） */
  parentId: Scalars['String']
  /** アプリ（Organization）全体での未読メンション数 */
  totalUnreadMentionCount?: Maybe<Scalars['Int']>
  /** 未読メンション数 */
  unreadMentionCount?: Maybe<Scalars['Int']>
  /** データ種別 */
  unreadType: UserUnreadType
}

/** 未読データ種別 */
export enum UserUnreadType {
  /** アーティストのコミュニティスレッド */
  ArtistThread = 'artist_thread',
}

/** アカウントバリデーション情報を渡す */
export type ValidateAccountInput = {
  /** AccountID */
  accountId?: Maybe<Scalars['String']>
  /** Email */
  email?: Maybe<Scalars['String']>
}

/** カート購入チェックインプット */
export type ValidateCartInput = {
  cartId: Scalars['ID']
  isKonbini: Scalars['Boolean']
  totalPrice: Scalars['Int']
}

/** コレクションアイテム閲覧Input */
export type ViewCollectionItemInput = {
  /** コレクションID */
  collectionId: Scalars['String']
  /** アイテムID */
  itemId: Scalars['String']
}

/** ストアアイテム閲覧Input */
export type ViewStoreItemInput = {
  /** アイテムID */
  itemId: Scalars['String']
}

/** 背景アイテムメタデータインプット */
export type WallpaperItemMetadataInput = {
  /** 印刷可能かどうか */
  isPrintEnabled?: Maybe<Scalars['Boolean']>
  /** 印刷可能枚数 */
  printCount?: Maybe<Scalars['Int']>
  /** 印刷種別 */
  printType?: Maybe<WallpaperPrintType>
}

/** 背景印刷タイプ */
export enum WallpaperPrintType {
  /** A4光沢紙 */
  GlossyPaperA4 = 'glossy_paper_a4',
  /** 写真2L版 */
  Photo_2l = 'photo_2l',
  /** 写真L版 */
  PhotoL = 'photo_l',
  /** 写真スクエア */
  PhotoSquare = 'photo_square',
  /** ハガキ */
  Postcard = 'postcard',
  /** シール2L版 */
  Seal_2l = 'seal_2l',
  /** シールL版 */
  SealL = 'seal_l',
  /** シールスクエア */
  SealSquare = 'seal_square',
}

/** 壁紙リソース */
export type WallpaperResources = {
  /** BGM用オーディオリソース */
  audioResource?: Maybe<Resource>
  /** 壁紙画像リソース */
  wallpaperResource?: Maybe<Resource>
}

/** 壁紙リソースInput */
export type WallpaperResourcesInput = {
  /** 音声リソースのドキュメントID */
  audioResourceId?: Maybe<Scalars['String']>
  /** 壁紙リソース画像のドキュメントID */
  wallpaperResourceId: Scalars['String']
}

/** 壁紙リソース更新用Input */
export type WallpaperResourcesUpdateInput = {
  /** 背景リソースのBGMに設定するオーディオのパス(tmp/xxx) */
  audioResourcePath?: Maybe<Scalars['String']>
  /** 背景リソースの画像に設定する画像のパス(tmp/xxx) */
  wallpaperResourcePath?: Maybe<Scalars['String']>
}

/** 壁紙リソースInput(URI指定) */
export type WallpaperResourcesUriInput = {
  /** 音声リソースのURI */
  audioResourceUri?: Maybe<Scalars['String']>
  /** 壁紙リソース画像のURI */
  wallpaperResourceUri: Scalars['String']
}

/** 総売上レポート情報 */
export type TotalSalesReportDetail = {
  formattedTotalSales?: Maybe<Scalars['String']>
  /** 手数料控除後の売上 */
  totalSales: Scalars['Int']
  /** 手数料控除前の売上 */
  totalSalesWithoutFee: Scalars['Int']
}

export type UserAvatarFragment = Pick<
  User,
  'id' | 'displayName' | 'accountId'
> & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> }

export type ArtistBottomTabFragment = Pick<
  Artist,
  'id' | 'displayName' | 'accountId' | 'totalUnreadMentionCount'
> & {
  avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
  appLinks?: Maybe<Array<Maybe<Pick<ArtistAppLink, 'link' | 'type'>>>>
}

export type ItemCardFragment = Pick<Item, 'id' | 'hideSalesNumber'> & {
  sku?: Maybe<
    Pick<
      SkuUnit,
      | 'id'
      | 'order'
      | 'pendingPurchaseNumber'
      | 'purchasedNumber'
      | 'stock'
      | 'unitName'
    > & {
      thumbnail?: Maybe<
        Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
      >
    }
  >
  skuSettings?: Maybe<
    Pick<SkuSettings, 'keyName'> & {
      units: Array<
        Pick<
          SkuUnit,
          | 'id'
          | 'order'
          | 'pendingPurchaseNumber'
          | 'purchasedNumber'
          | 'stock'
          | 'unitName'
        > & {
          thumbnail?: Maybe<
            Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
          >
        }
      >
    }
  >
}

export type UserDetailCacheQueryVariables = Exact<{ [key: string]: never }>

export type UserDetailCacheQuery = { user?: Maybe<Pick<User, 'id'>> }

export type ArtistPlanMonthQueryVariables = Exact<{
  artistId: Scalars['ID']
}>

export type ArtistPlanMonthQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      patron?: Maybe<
        Pick<Patron, 'id' | 'formattedCreatedAt'> & {
          subscription?: Maybe<Pick<PatronSubsription, 'planId'>>
        }
      >
    }
  >
}

export type ArtistPatronPlanQueryVariables = Exact<{
  artistId: Scalars['ID']
}>

export type ArtistPatronPlanQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<
            Pick<MembershipSubscriptionProduct, 'productId'> & {
              plans: Array<
                Pick<MembershipSubscriptionPlan, 'planId' | 'amount'>
              >
            }
          >
        >
      }>
      pendingPurchasePatron?: Maybe<
        Pick<PendingPurchaseItem, 'id' | 'artistId' | 'status'> & {
          metadata?: Maybe<{
            membership?: Maybe<
              Pick<PendingPurchaseItemMetadataMembership, 'planId'>
            >
          }>
        }
      >
      patron?: Maybe<
        Pick<
          Patron,
          'id' | 'downgradeAtNextPeriod' | 'cancelAtPeriodEnd' | 'paymentMethod'
        > & {
          subscription?: Maybe<
            Pick<PatronSubsription, 'planId' | 'amount' | 'downgradePlanId'>
          >
        }
      >
    }
  >
}

export type CampaignBoxFragment = Pick<Campaign, 'id' | 'title'> & {
  thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
}

export type ConsoleCampaignBoxFragment = Pick<
  Campaign,
  | 'id'
  | 'title'
  | 'startedAt'
  | 'formattedStartedAt'
  | 'endedAt'
  | 'formattedEndedAt'
  | 'open'
  | 'isEntry'
  | 'openStatus'
  | 'entryStatus'
> & { thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>> }

export type CollectionBoxFragment = Pick<
  Collection,
  | 'id'
  | 'title'
  | 'formattedCreatedAt'
  | 'createdAt'
  | 'endedAt'
  | 'description'
  | 'isEventPickup'
  | 'status'
  | 'itemCount'
> & {
  event?: Maybe<Pick<Event, 'id'>>
  artist: Pick<Artist, 'id' | 'displayName'> & {
    avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
  }
  thumbnail: Pick<CollectionThumbnail, 'uri'>
  userCollection?: Maybe<
    Pick<
      UserCollection,
      'userId' | 'id' | 'itemOwnedCount' | 'totalOwnedItemCount'
    > & {
      ownedItems?: Maybe<
        Array<Pick<UserCollectionItem, 'itemId' | 'ownedCount'>>
      >
    }
  >
}

export type UserCollectionBoxFragment = Pick<
  UserCollection,
  'userId' | 'id' | 'itemOwnedCount' | 'totalOwnedItemCount'
> & {
  collection?: Maybe<
    Pick<
      Collection,
      'id' | 'title' | 'isEventPickup' | 'itemCount' | 'artistId'
    > & { thumbnail: Pick<CollectionThumbnail, 'uri'> }
  >
  ownedItems?: Maybe<Array<Pick<UserCollectionItem, 'itemId' | 'ownedCount'>>>
}

export type CommentBoxFragment = {
  replies: {
    edges: Array<{ node: CommentBoxWithoutRepliesFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
} & CommentBoxWithoutRepliesFragment

export type CommentBoxWithoutRepliesFragment = Pick<
  Comment,
  | 'id'
  | 'isLike'
  | 'content'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'likeCount'
  | 'replyCount'
> & {
  commenter:
    | (Pick<
        Artist,
        'id' | 'displayName' | 'accountId' | 'formattedAccountId'
      > & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> })
    | (Pick<User, 'id' | 'displayName' | 'accountId' | 'formattedAccountId'> & {
        avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
      })
  parentComment?: Maybe<Pick<Comment, 'id'>>
  resource?: Maybe<Pick<CommentResource, 'uri'>>
  resources?: Maybe<Array<Pick<CommentResource, 'uri'>>>
  tip?: Maybe<Pick<Tip, 'points'>>
}

export type CommentsConnectionFragment = {
  edges: Array<{ node: CommentBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type RepliesConnectionFragment = {
  edges: Array<{ node: ReplyBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type ThreadBoxFragment = Pick<
  ArtistThreadChannel,
  | 'id'
  | 'name'
  | 'description'
  | 'artistId'
  | 'isAllowNotification'
  | 'category'
  | 'likeCount'
  | 'commentCount'
  | 'isPremium'
  | 'isAccessible'
  | 'isOfficial'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'isLike'
> & {
  userUnread?: Maybe<
    Pick<
      UserUnread,
      | 'totalUnreadMentionCount'
      | 'id'
      | 'unreadMentionCount'
      | 'lastReadAt'
      | 'parentId'
      | 'unreadType'
    >
  >
  thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri' | 'smallUri'>>
  commentUsers?: Maybe<
    Array<
      Maybe<
        Pick<
          User,
          'id' | 'accountId' | 'formattedAccountId' | 'displayName'
        > & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> }
      >
    >
  >
  artist?: Maybe<
    Pick<
      Artist,
      | 'id'
      | 'accountId'
      | 'formattedAccountId'
      | 'displayName'
      | 'totalUnreadMentionCount'
    > & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> }
  >
  createdByUser?: Maybe<
    Pick<User, 'id' | 'accountId' | 'formattedAccountId' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
  latestComment?: Maybe<
    Pick<Comment, 'id' | 'content' | 'createdAt' | 'formattedCreatedAt'> & {
      commenter:
        | (Pick<
            Artist,
            'id' | 'accountId' | 'formattedAccountId' | 'displayName'
          > & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> })
        | (Pick<
            User,
            'id' | 'accountId' | 'formattedAccountId' | 'displayName'
          > & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> })
    }
  >
}

export type ConsecutiveLoginBonusModalFragment = Pick<
  LoginBonus,
  'id' | 'todayTargetDate'
> & {
  loginBonusUser?: Maybe<
    Pick<LoginBonusUser, 'id'> & {
      obtainedBonuses?: Maybe<Array<LoginBonusBoxFragment>>
    }
  >
  bonuses?: Maybe<Array<LoginBonusBoxFragment>>
}

export type LoginBonusBoxFragment = Pick<Bonus, 'type' | 'bonusKey'> & {
  metadata?: Maybe<
    Pick<BonusMetadata, 'count' | 'point'> & {
      gachaTicket?: Maybe<
        Pick<GachaTicket, 'id'> & {
          thumbnails?: Maybe<{
            standard?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
          }>
        }
      >
    }
  >
}

export type CollectionsSelectBoxFragment = Pick<
  Collection,
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'id'
  | 'artistId'
  | 'itemCount'
  | 'title'
  | 'description'
  | 'status'
  | 'startedAt'
  | 'endedAt'
  | 'isPublishOnStore'
  | 'formattedStartedAt'
  | 'formattedEndedAt'
> & {
  thumbnail: Pick<CollectionThumbnail, 'uri'>
  nft?: Maybe<Pick<CollectionNft, 'salesType'>>
}

export type ConsoleEventTicketInfoBoxFragment = Pick<
  Event,
  | 'id'
  | 'title'
  | 'status'
  | 'startedAt'
  | 'endedAt'
  | 'closeDate'
  | 'dateRange'
> & {
  preSales: EventPreSaleConnectionFragment
  location?: Maybe<Pick<GpsData, 'approximateAddress'>>
  preSalesAggregation?: Maybe<{
    aggregation?: Maybe<{
      purchases?: Maybe<
        Pick<PreSaleAggregationPurchases, 'purchasedNumber' | 'issuedNumber'>
      >
      ticketUsage?: Maybe<
        Pick<
          PreSaleAggregationTicketUsage,
          'ticketUnusedCount' | 'ticketUsedCount'
        >
      >
    }>
  }>
}

export type ConsoleMissionBoxFragment = Pick<
  Mission,
  | 'id'
  | 'title'
  | 'description'
  | 'type'
  | 'isAccomplished'
  | 'formattedExpiredAt'
  | 'label'
  | 'expiredAt'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'startedAt'
  | 'isPublished'
> & {
  gpsData?: Maybe<
    Pick<GpsData, 'approximateAddress' | 'latitude' | 'longitude' | 'radius'>
  >
  artist: Pick<Artist, 'displayName'>
  content:
    | {
        image?: Maybe<Pick<Image, 'uri' | 'path'>>
        items: Array<Pick<ItemCollectContentItem, 'itemId' | 'requiredCount'>>
      }
    | (Pick<QuizContent, 'answer'> & {
        image?: Maybe<Pick<Image, 'uri' | 'path'>>
      })
  reward: {
    item?: Maybe<
      Pick<QuizRewardItem, 'id'> & {
        item?: Maybe<
          Pick<
            Item,
            | 'id'
            | 'title'
            | 'issuedNumber'
            | 'isPremium'
            | 'isPublished'
            | 'isDrm'
            | 'salesStatus'
          > & {
            thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
          }
        >
      }
    >
    gachaTicket?: Maybe<
      Pick<QuizGachaTicket, 'id' | 'count' | 'gachaTicketId'> & {
        gachaTicket: Pick<GachaTicket, 'id'> & {
          thumbnails?: Maybe<{
            reward?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
          }>
        }
      }
    >
  }
}

export type ItemSelectBoxFragment = Pick<
  Item,
  | 'id'
  | 'type'
  | 'description'
  | 'thumbnailType'
  | 'issuedNumber'
  | 'usedNumber'
  | 'purchasedNumber'
  | 'pendingPurchaseNumber'
  | 'formattedRemainingNumber'
  | 'purchaseLimitByUser'
  | 'purchaseLimitPerOrder'
  | 'personalInformationRequirements'
  | 'title'
  | 'isDownloadable'
  | 'hideSalesNumber'
  | 'price'
  | 'salesStatus'
  | 'isPublished'
  | 'status'
  | 'isDrm'
  | 'resaleEnabled'
  | 'paymentType'
  | 'formattedPrice'
  | 'isPremium'
  | 'requiredPrepaidPoint'
> & {
  thumbnail: { back?: Maybe<ResourceFragment>; front?: Maybe<ResourceFragment> }
  collection?: Maybe<Pick<Collection, 'id'>>
  images?: Maybe<Array<Pick<ItemImage, 'uri' | 'caption'>>>
  issuedArtist?: Maybe<Pick<Artist, 'id'>>
  prepaidPoint?: Maybe<Pick<PrepaidPoint, 'exchangeRate' | 'pointName'>>
  sku?: Maybe<
    Pick<
      SkuUnit,
      | 'id'
      | 'order'
      | 'pendingPurchaseNumber'
      | 'purchasedNumber'
      | 'stock'
      | 'unitName'
      | 'price'
    > & {
      thumbnail?: Maybe<
        Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
      >
    }
  >
  skuSettings?: Maybe<
    Pick<SkuSettings, 'keyName'> & {
      units: Array<
        Pick<
          SkuUnit,
          | 'id'
          | 'order'
          | 'pendingPurchaseNumber'
          | 'purchasedNumber'
          | 'stock'
          | 'price'
          | 'unitName'
        > & {
          thumbnail?: Maybe<
            Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
          >
        }
      >
    }
  >
  wallpaperResources?: Maybe<{
    wallpaperResource?: Maybe<ResourceFragment>
    audioResource?: Maybe<ResourceFragment>
  }>
  avatarResources?: Maybe<{
    avatarResource?: Maybe<ResourceFragment>
    voiceResource?: Maybe<ResourceFragment>
  }>
  resource?: Maybe<ResourceFragment>
  receptionPeriod?: Maybe<
    Pick<ReceptionPeriod, 'startedAt' | 'endedAt' | 'formattedEndedAt'>
  >
  nft?: Maybe<{ chains?: Maybe<Array<Pick<ItemNftChain, 'chainName'>>> }>
  metadata?: Maybe<{
    ticket?: Maybe<
      Pick<
        ItemMetadataDetailTicket,
        'description' | 'note' | 'expirationTerm'
      > & {
        throughTicket?: Maybe<
          Array<Pick<TicketItemMetadataThroughTicket, 'name'>>
        >
        resalePeriod?: Maybe<Pick<ReceptionPeriod, 'startedAt' | 'endedAt'>>
      }
    >
    magazine?: Maybe<Pick<ItemMetadataDetailMagazine, 'pageDirection'>>
  }>
  rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
  rarityIconImage?: Maybe<Pick<Image, 'smallUri' | 'uri'>>
}

export type MemberBoxFragment = Pick<
  Patron,
  | 'id'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'continueMonth'
  | 'isFollower'
  | 'paymentMethod'
  | 'purchaseCount'
  | 'ticketUnusedCount'
> & {
  subscription?: Maybe<
    Pick<
      PatronSubsription,
      'title' | 'content' | 'status' | 'amount' | 'planId'
    >
  >
  user: Pick<
    User,
    | 'id'
    | 'email'
    | 'gender'
    | 'birthday'
    | 'formattedBirthday'
    | 'displayName'
    | 'fullName'
    | 'phoneNumber'
    | 'formattedPhoneNumber'
  > & {
    avatar?: Maybe<MemberAvatarBoxFragment>
    address?: Maybe<
      Pick<
        UserAddress,
        'postalCode' | 'prefecture' | 'city' | 'line1' | 'line2' | 'fullAddress'
      >
    >
  }
}

export type MemberAvatarBoxFragment = Pick<
  Avatar,
  'uri' | 'smallUri' | 'mediumUri'
>

export type OrderedItemBoxFragment = Pick<
  ItemOrder,
  | 'id'
  | 'serialIds'
  | 'purchaseNumber'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'price'
  | 'prepaidPoint'
  | 'totalAmount'
> & {
  serials?: Maybe<
    Array<
      Pick<ItemSerial, 'id' | 'itemId' | 'skuId'> & {
        sku?: Maybe<
          Pick<
            SkuUnit,
            | 'id'
            | 'order'
            | 'pendingPurchaseNumber'
            | 'purchasedNumber'
            | 'stock'
            | 'unitName'
          > & {
            thumbnail?: Maybe<
              Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
            >
          }
        >
      }
    >
  >
  item?: Maybe<
    Pick<Item, 'issuedNumber' | 'purchasedNumber' | 'id' | 'title'> & {
      rarity?: Maybe<Pick<Rarity, 'name'>>
      thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
    }
  >
  user?: Maybe<{ avatar?: Maybe<Pick<Avatar, 'smallUri' | 'uri'>> }>
}

export type EventTicketCardFragment = Pick<
  Event,
  'id' | 'title' | 'startedAt' | 'endedAt' | 'closeDate' | 'dateRange'
> & {
  location?: Maybe<Pick<GpsData, 'approximateAddress'>>
  preSales: EventPreSaleConnectionFragment
  myPreSaleItems?: Maybe<
    Array<
      Pick<PreSaleItem, 'itemId' | 'skuId'> & {
        item: Pick<Item, 'id' | 'title'> & {
          sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
          skuSettings?: Maybe<
            Pick<SkuSettings, 'keyName'> & {
              units: Array<Pick<SkuUnit, 'id' | 'unitName'>>
            }
          >
          metadata?: Maybe<{
            ticket?: Maybe<Pick<ItemMetadataDetailTicket, 'isThroughTicket'>>
          }>
        }
      }
    >
  >
}

export type GachaBoxFragment = Pick<
  Gacha,
  'id' | 'name' | 'endAt' | 'isPremium' | 'formattedEndAt'
> & {
  thumbnail: Pick<Image, 'uri' | 'smallUri'>
  gpsData?: Maybe<
    Pick<GpsData, 'approximateAddress' | 'latitude' | 'longitude' | 'radius'>
  >
}

export type GachaTicketThumbnailsBoxFragment = {
  reward?: Maybe<Pick<Image, 'uri' | 'smallUri' | 'mediumUri'>>
  standard?: Maybe<Pick<Image, 'uri' | 'smallUri' | 'mediumUri'>>
}

export type ItemBoxFragment = Pick<
  Item,
  | 'id'
  | 'title'
  | 'price'
  | 'formattedPrice'
  | 'type'
  | 'paymentType'
  | 'requiredPrepaidPoint'
  | 'personalInformationRequirements'
  | 'hideSalesNumber'
> & {
  thumbnail: {
    front?: Maybe<
      Pick<Resource, 'uri' | 'mediumUri' | 'smallUri' | 'compressedUri'>
    >
  }
  prepaidPoint?: Maybe<Pick<PrepaidPoint, 'id' | 'exchangeRate' | 'pointName'>>
  issuedArtist?: Maybe<
    Pick<Artist, 'id' | 'displayName' | 'introduction'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
  sku?: Maybe<
    Pick<
      SkuUnit,
      | 'id'
      | 'order'
      | 'pendingPurchaseNumber'
      | 'purchasedNumber'
      | 'stock'
      | 'unitName'
      | 'price'
      | 'formattedPrice'
    > & {
      thumbnail?: Maybe<
        Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
      >
    }
  >
  skuSettings?: Maybe<
    Pick<SkuSettings, 'keyName'> & {
      units: Array<
        Pick<
          SkuUnit,
          | 'id'
          | 'order'
          | 'pendingPurchaseNumber'
          | 'purchasedNumber'
          | 'stock'
          | 'price'
          | 'formattedPrice'
          | 'unitName'
        > & {
          thumbnail?: Maybe<
            Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
          >
        }
      >
    }
  >
  collection?: Maybe<Pick<Collection, 'id' | 'title'>>
}

export type ItemDescriptionFragment = Pick<
  Item,
  | 'id'
  | 'title'
  | 'type'
  | 'description'
  | 'price'
  | 'formattedPrice'
  | 'isDrm'
  | 'isDownloadable'
  | 'ownedCount'
  | 'hideSalesNumber'
  | 'paymentType'
  | 'isTicketDistributionEnabled'
  | 'requirePersonalInformation'
  | 'formattedRemainingNumber'
  | 'personalInformationRequirements'
  | 'isResaleAvailable'
  | 'resaleOpenCount'
  | 'resaleRaffleAppliedCount'
  | 'resaleEnabled'
  | 'thumbnailType'
  | 'issuedNumber'
  | 'purchasedNumber'
  | 'pendingPurchaseNumber'
  | 'commentCount'
> & { skuIsResaleAvailable: Item['isResaleAvailable'] } & {
  preSale?: Maybe<
    Pick<PreSale, 'id' | 'title'> & {
      event: Pick<Event, 'id' | 'title' | 'description'>
    }
  >
  collection?: Maybe<
    Pick<Collection, 'id' | 'itemCount' | 'isPublishOnStore'> & {
      event?: Maybe<EventBoxFragment>
      collectionItems: { edges: Array<{ node: CollectionItemDetailFragment }> }
    }
  >
  images?: Maybe<Array<Pick<ItemImage, 'caption' | 'mediumUri' | 'uri'>>>
  sku?: Maybe<
    Pick<
      SkuUnit,
      | 'id'
      | 'order'
      | 'pendingPurchaseNumber'
      | 'purchasedNumber'
      | 'stock'
      | 'unitName'
      | 'price'
      | 'formattedPrice'
    > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>> }
  >
  skuSettings?: Maybe<
    Pick<SkuSettings, 'keyName'> & {
      units: Array<
        Pick<
          SkuUnit,
          | 'id'
          | 'order'
          | 'pendingPurchaseNumber'
          | 'purchasedNumber'
          | 'remainingNumber'
          | 'stock'
          | 'price'
          | 'formattedPrice'
          | 'unitName'
        > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>> }
      >
    }
  >
  purchasableUsers?: Maybe<Array<PurchasableUserDetailFragment>>
  issuedArtist?: Maybe<Pick<Artist, 'id'>>
  rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
  thumbnail: {
    front?: Maybe<ThumbnailResourceFragment>
    back?: Maybe<ThumbnailResourceFragment>
  }
  nft?: Maybe<{ chains?: Maybe<Array<Pick<ItemNftChain, 'chainName'>>> }>
  rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
  resource?: Maybe<ResourceFragment>
  resources?: Maybe<Array<Maybe<ResourceFragment>>>
  avatarResources?: Maybe<{
    voiceResource?: Maybe<ResourceFragment>
    avatarResource?: Maybe<ResourceFragment>
  }>
  wallpaperResources?: Maybe<{
    audioResource?: Maybe<ResourceFragment>
    wallpaperResource?: Maybe<ResourceFragment>
  }>
  relatedMissions?: Maybe<
    Array<Pick<Mission, 'id' | 'expiredAt' | 'isAccomplished' | 'label'>>
  >
  ogp?: Maybe<Pick<Ogp, 'rectUri' | 'uri'>>
  metadata?: Maybe<{
    ticket?: Maybe<
      Pick<
        ItemMetadataDetailTicket,
        | 'note'
        | 'description'
        | 'expirationTerm'
        | 'isThroughTicket'
        | 'assignedNumberType'
        | 'startAssignedNumber'
        | 'assignedNumberPrefix'
        | 'distributionEnabled'
        | 'distributionEndedAt'
      > & {
        resalePeriod?: Maybe<Pick<ReceptionPeriod, 'startedAt' | 'endedAt'>>
        throughTicket?: Maybe<
          Array<Pick<TicketItemMetadataThroughTicket, 'name'>>
        >
      }
    >
    magazine?: Maybe<
      Pick<ItemMetadataDetailMagazine, 'pageDirection' | 'pageType'>
    >
    wallpaper?: Maybe<
      Pick<ItemWallpaperMetadata, 'isPrintEnabled' | 'printCount' | 'printType'>
    >
  }>
  userOpenResaleItem?: Maybe<Pick<ResaleItem, 'id'>>
}

export type ResourceFragment = Pick<
  Resource,
  'smallUri' | 'mediumUri' | 'compressedUri' | 'uri' | 'hlsUri' | 'dashUri'
> & { drm?: Maybe<Pick<ResourceDrm, 'encryptingStatus'>> }

export type ThumbnailResourceFragment = Pick<
  Resource,
  'mediumUri' | 'uri' | 'hlsUri' | 'dashUri'
> & { drm?: Maybe<Pick<ResourceDrm, 'encryptingStatus'>> }

export type CollectionItemDetailFragment = Pick<
  CollectionItem,
  'id' | 'itemId' | 'order'
> & {
  userItem?: Maybe<Pick<UserItem, 'id' | 'type'>>
  item: Pick<
    Item,
    | 'id'
    | 'title'
    | 'paymentType'
    | 'price'
    | 'purchaseLimitByUser'
    | 'purchasedByPointNumber'
    | 'purchasedNumber'
    | 'buyOverLimit'
    | 'salesStatus'
    | 'issuedNumber'
    | 'formattedPrice'
  > & {
    thumbnail: {
      front?: Maybe<ThumbnailResourceFragment>
      back?: Maybe<ThumbnailResourceFragment>
    }
  }
}

export type UserInfoFragment = Pick<User, 'id' | 'displayName'> & {
  avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
}

export type ItemImageAreaFragment = Pick<
  Item,
  'id' | 'thumbnailType' | 'hideSalesNumber' | 'issuedNumber' | 'title'
> & {
  issuedArtist?: Maybe<Pick<Artist, 'id'>>
  skuSettings?: Maybe<
    Pick<SkuSettings, 'keyName'> & {
      units: Array<
        Pick<
          SkuUnit,
          | 'id'
          | 'order'
          | 'pendingPurchaseNumber'
          | 'purchasedNumber'
          | 'stock'
          | 'unitName'
        > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>> }
      >
    }
  >
  thumbnail: {
    front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>>
    back?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>>
  }
  rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
  rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
  sku?: Maybe<
    Pick<
      SkuUnit,
      | 'id'
      | 'order'
      | 'pendingPurchaseNumber'
      | 'purchasedNumber'
      | 'stock'
      | 'unitName'
    > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>> }
  >
}

export type MagazineItemFragment = Pick<
  Item,
  | 'id'
  | 'title'
  | 'description'
  | 'price'
  | 'formattedPrice'
  | 'paymentType'
  | 'isPremium'
> & {
  issuedArtist?: Maybe<Pick<Artist, 'id'>>
  thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri' | 'mediumUri'>> }
  collection?: Maybe<Pick<Collection, 'id' | 'title'>>
}

export type PendingPurchaseItemDetailFragment = Pick<
  PendingPurchaseItem,
  | 'id'
  | 'cartTransactionOrderId'
  | 'createdAt'
  | 'expiredAt'
  | 'formattedExpiredAt'
  | 'purchaseNumber'
  | 'status'
  | 'voucherUrl'
  | 'totalPrice'
  | 'formattedTotalPrice'
  | 'artistId'
> & {
  checkoutFees?: Maybe<
    Pick<CheckoutFees, 'totalFee'> & {
      shipping?: Maybe<FeeDetailFragment>
      konbini?: Maybe<FeeDetailFragment>
      collections?: Maybe<Array<Maybe<CollectionFeeDetailFragment>>>
    }
  >
  items?: Maybe<Array<PendingPurchaseItemSkuDetailFragment>>
  metadata?: Maybe<{
    membership?: Maybe<Pick<PendingPurchaseItemMetadataMembership, 'planId'>>
  }>
  artist: Pick<Artist, 'displayName'> & {
    avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
  } & ArtistMembershipSubscriptionDetailFragment
}

export type PendingPurchaseItemSkuDetailFragment = Pick<
  PendingPurchaseItemSku,
  'itemId' | 'count'
> & {
  item: {
    collection?: Maybe<Pick<Collection, 'id' | 'title'>>
  } & ItemBoxFragment
  skus: Array<
    Pick<PendingPurchaseItemSkuUnit, 'skuId' | 'count'> & {
      sku: Pick<
        SkuUnit,
        | 'id'
        | 'unitName'
        | 'order'
        | 'price'
        | 'formattedPrice'
        | 'pendingPurchaseNumber'
        | 'purchasedNumber'
        | 'stock'
      > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri' | 'mediumUri'>> }
    }
  >
}

export type ResaleItemBoxFragment = Pick<
  ResaleItem,
  | 'id'
  | 'status'
  | 'itemId'
  | 'itemCount'
  | 'listedAt'
  | 'totalItemPrice'
  | 'skuId'
  | 'formattedListedAt'
  | 'formattedTotalItemPrice'
> & { item: ItemDetailBoxFragment }

export type ResaleRaffleBoxFragment = Pick<
  ItemResaleRaffleUser,
  | 'id'
  | 'status'
  | 'itemCount'
  | 'createdAt'
  | 'totalItemPrice'
  | 'skuId'
  | 'formattedCreatedAt'
  | 'formattedTotalItemPrice'
  | 'itemId'
> & { item: ItemDetailBoxFragment }

export type SkuTagFragment = Pick<
  SkuUnit,
  | 'id'
  | 'order'
  | 'pendingPurchaseNumber'
  | 'purchasedNumber'
  | 'stock'
  | 'price'
  | 'formattedPrice'
  | 'remainingNumber'
  | 'unitName'
> & {
  thumbnail?: Maybe<
    Pick<Image, 'smallUri' | 'uri' | 'mediumUri' | 'compressedUri'>
  >
}

export type LoginBonusModalFragment = Pick<
  LoginBonus,
  'id' | 'startDate' | 'endDate' | 'todayTargetDate'
> & {
  loginBonusUser?: Maybe<
    Pick<LoginBonusUser, 'id'> & {
      obtainedBonuses?: Maybe<Array<BonusElementFragment>>
    }
  >
  bonuses?: Maybe<Array<BonusElementFragment>>
  todayBonus?: Maybe<BonusElementFragment>
}

export type BonusElementFragment = Pick<Bonus, 'type' | 'bonusKey'> & {
  metadata?: Maybe<
    Pick<BonusMetadata, 'count' | 'point'> & {
      gachaTicket?: Maybe<{
        thumbnails?: Maybe<{
          standard?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
        }>
      }>
    }
  >
}

export type LoginBonusModalQueryVariables = Exact<{ [key: string]: never }>

export type LoginBonusModalQuery = {
  loginBonus?: Maybe<LoginBonusModalFragment>
  continuousLoginBonus?: Maybe<ConsecutiveLoginBonusModalFragment>
}

export type ObtainLoginBonusMutationVariables = Exact<{
  inputs: ObtainLoginBonusInput
}>

export type ObtainLoginBonusMutation = {
  obtainLoginBonus: Pick<
    LoginBonus,
    'id' | 'startDate' | 'endDate' | 'createdAt' | 'todayTargetDate'
  > & {
    bonuses?: Maybe<
      Array<
        Pick<Bonus, 'type' | 'bonusKey'> & {
          metadata?: Maybe<
            Pick<BonusMetadata, 'count' | 'point'> & {
              gachaTicket?: Maybe<Pick<GachaTicket, 'id'>>
            }
          >
        }
      >
    >
    loginBonusUser?: Maybe<
      Pick<LoginBonusUser, 'id'> & {
        obtainedBonuses?: Maybe<
          Array<
            Pick<Bonus, 'type' | 'bonusKey'> & {
              metadata?: Maybe<
                Pick<BonusMetadata, 'count' | 'point'> & {
                  gachaTicket?: Maybe<Pick<GachaTicket, 'id'>>
                }
              >
            }
          >
        >
      }
    >
  }
}

export type DeliveryItemBoxFragment = Pick<
  DeliveryItem,
  'id' | 'appliedAt' | 'formattedAppliedAt' | 'status'
> & {
  deliveryInfo?: Maybe<{
    address?: Maybe<
      Pick<
        UserAddress,
        'postalCode' | 'prefecture' | 'city' | 'line1' | 'line2' | 'fullAddress'
      >
    >
  }>
  itemSerial?: Maybe<
    Pick<ItemSerial, 'serialNumber' | 'formattedSerialNumber'> & {
      sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
    }
  >
  item: Pick<Item, 'id' | 'title' | 'description' | 'hideSalesNumber'> & {
    thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
    receptionPeriod?: Maybe<
      Pick<ReceptionPeriod, 'endedAt' | 'formattedEndedAt'>
    >
  }
}

export type MembershipPlanBoxFragment = Pick<
  MembershipSubscriptionPlan,
  'status' | 'content' | 'amount'
>

export type NeedSettingPersonalUserSnackbarQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>
  skipArtist: Scalars['Boolean']
}>

export type NeedSettingPersonalUserSnackbarQuery = {
  user?: Maybe<Pick<User, 'id' | 'isMembershipPersonalInformationRequired'>>
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      patron?: Maybe<Pick<Patron, 'id' | 'isPersonalInformationRequired'>>
    }
  >
}

export type MissionBoxFragment = Pick<
  Mission,
  | 'id'
  | 'title'
  | 'description'
  | 'type'
  | 'isAccomplished'
  | 'formattedExpiredAt'
  | 'label'
  | 'expiredAt'
> & {
  gpsData?: Maybe<
    Pick<GpsData, 'approximateAddress' | 'latitude' | 'longitude' | 'radius'>
  >
  artist: Pick<Artist, 'id' | 'displayName'> & {
    avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
  }
  reward: {
    item?: Maybe<
      Pick<QuizRewardItem, 'id'> & {
        item?: Maybe<MultipleItemsMissionFragment>
      }
    >
    gachaTicket?: Maybe<
      Pick<QuizGachaTicket, 'gachaTicketId'> & {
        gachaTicket: Pick<GachaTicket, 'id'> & {
          thumbnails?: Maybe<{
            reward?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
          }>
        }
      }
    >
  }
}

export type MultipleItemsMissionFragment = Pick<
  Item,
  'id' | 'title' | 'description' | 'issuedNumber'
> & {
  thumbnail: { front?: Maybe<Pick<Resource, 'smallUri' | 'uri'>> }
  rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
  rarity?: Maybe<Pick<Rarity, 'name'>>
  sku?: Maybe<
    Pick<
      SkuUnit,
      | 'id'
      | 'order'
      | 'pendingPurchaseNumber'
      | 'purchasedNumber'
      | 'stock'
      | 'unitName'
    > & {
      thumbnail?: Maybe<
        Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
      >
    }
  >
  skuSettings?: Maybe<
    Pick<SkuSettings, 'keyName'> & {
      units: Array<
        Pick<
          SkuUnit,
          | 'id'
          | 'order'
          | 'pendingPurchaseNumber'
          | 'purchasedNumber'
          | 'stock'
          | 'unitName'
        > & {
          thumbnail?: Maybe<
            Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
          >
        }
      >
    }
  >
}

export type UserStepMissionBoxFragment = Pick<
  UserStepMission,
  | 'id'
  | 'accomplishedAt'
  | 'artistId'
  | 'createdAt'
  | 'isAccomplished'
  | 'isRewardReceived'
  | 'organizationId'
  | 'progress'
  | 'label'
  | 'type'
  | 'updatedAt'
> & {
  step: Pick<MissionStep, 'id' | 'requiredCount'> & {
    reward: Pick<StepMissionReward, 'value' | 'type'> & {
      metadata: Pick<
        StepMissionRewardMetadata,
        'itemId' | 'gachaTicketId' | 'prepaidPointId'
      > & {
        gachaTicket?: Maybe<GachaTicketConnectionFragment>
        item?: Maybe<Pick<Item, 'id'> & ItemBoxFragment>
      }
    }
  }
}

export type NotificationBoxFragment = Pick<
  Notification,
  'id' | 'body' | 'date' | 'formattedDate' | 'link' | 'title'
>

export type OrganizationNotificationBoxFragment = Pick<
  OrganizationNotification,
  'id' | 'body' | 'link' | 'title' | 'createdAt' | 'formattedCreatedAt'
> & {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
}

export type OfficialInformationConsoleBoxFragment = Pick<
  OrganizationNotification,
  | 'id'
  | 'title'
  | 'body'
  | 'link'
  | 'status'
  | 'publishedAt'
  | 'formattedPublishedAt'
  | 'createdAt'
  | 'formattedCreatedAt'
>

export type PostBoxFragment = Pick<
  Post,
  | 'id'
  | 'categoryId'
  | 'content'
  | 'createdAt'
  | 'status'
  | 'publishedAt'
  | 'formattedPublishedAt'
  | 'formattedRelativePublishedAt'
  | 'formattedCreatedAt'
  | 'planTitle'
  | 'likeCount'
  | 'replyCount'
  | 'resourceType'
  | 'isLike'
  | 'isPremium'
  | 'planId'
  | 'artistId'
  | 'isAccessible'
> & {
  category?: Maybe<Pick<PostCategoryDetail, 'id' | 'name'>>
  resources?: Maybe<Array<Pick<PostImageResource, 'uri' | 'mediumUri'>>>
  resource?: Maybe<Pick<PostResource, 'uri' | 'videoId'>>
  videoThumbnail?: Maybe<
    Pick<PostVideoThumbnail, 'uri' | 'mediumUri' | 'blurUri'>
  >
  imageThumbnail?: Maybe<Pick<PostImageThumbnail, 'blurUri'>>
  artist: Pick<
    Artist,
    'id' | 'displayName' | 'accountId' | 'formattedAccountId'
  > & {
    avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri' | 'mediumUri'>>
    patron?: Maybe<
      Pick<Patron, 'id' | 'isPersonalInformationRequired'> & {
        subscription?: Maybe<
          Pick<PatronSubsription, 'planId' | 'amount' | 'title'>
        >
      }
    >
    subscription?: Maybe<{
      plans?: Maybe<
        Array<Pick<MembershipSubscriptionPlan, 'planId' | 'amount' | 'title'>>
      >
      products?: Maybe<
        Array<
          Pick<MembershipSubscriptionProduct, 'productId' | 'title'> & {
            plans: Array<Pick<MembershipSubscriptionPlan, 'planId' | 'amount'>>
          }
        >
      >
    }>
  }
}

export type ReplyBoxFragment = Pick<
  PostReply,
  | 'id'
  | 'content'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'replyToId'
  | 'isLike'
  | 'likeCount'
> & {
  responder?: Maybe<
    | (Pick<
        Artist,
        'id' | 'accountId' | 'formattedAccountId' | 'displayName'
      > & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> })
    | (Pick<User, 'id' | 'accountId' | 'formattedAccountId' | 'displayName'> & {
        avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
      })
  >
}

export type ArtistStoreCollectionBoxFragment = Pick<
  Collection,
  'id' | 'title' | 'itemCount' | 'artistId'
> & { thumbnail: Pick<CollectionThumbnail, 'uri'> }

export type SkuTypeFragment = Pick<SkuSettings, 'keyName'> & {
  units: Array<SkuTagFragment>
}

export type StoreItemFragment = Pick<
  Item,
  | 'id'
  | 'title'
  | 'price'
  | 'type'
  | 'formattedPrice'
  | 'salesStatus'
  | 'paymentType'
  | 'isPremium'
  | 'pendingPurchaseNumber'
  | 'isResaleAvailable'
  | 'remainingNumber'
  | 'hideSalesNumber'
  | 'issuedNumber'
  | 'resaleEnabled'
  | 'purchasedNumber'
  | 'isDownloadable'
> & {
  rarity?: Maybe<Pick<Rarity, 'name'>>
  prepaidPoint?: Maybe<Pick<PrepaidPoint, 'exchangeRate'>>
  issuedArtist?: Maybe<Pick<Artist, 'id'>>
  sku?: Maybe<
    Pick<
      SkuUnit,
      | 'id'
      | 'order'
      | 'pendingPurchaseNumber'
      | 'purchasedNumber'
      | 'stock'
      | 'unitName'
    > & {
      thumbnail?: Maybe<
        Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
      >
    }
  >
  skuSettings?: Maybe<
    Pick<SkuSettings, 'keyName'> & {
      units: Array<
        Pick<
          SkuUnit,
          | 'id'
          | 'order'
          | 'pendingPurchaseNumber'
          | 'purchasedNumber'
          | 'stock'
          | 'price'
          | 'unitName'
        > & {
          thumbnail?: Maybe<
            Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
          >
        }
      >
    }
  >
  thumbnail: {
    front?: Maybe<
      Pick<Resource, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
    >
  }
  nft?: Maybe<{ chains?: Maybe<Array<Pick<ItemNftChain, 'chainName'>>> }>
  metadata?: Maybe<{
    wallpaper?: Maybe<
      Pick<ItemWallpaperMetadata, 'isPrintEnabled' | 'printCount' | 'printType'>
    >
  }>
}

export type StoreItemAreaTemporaryFragment = Pick<
  Item,
  'id' | 'title' | 'isPremium' | 'price' | 'formattedPrice' | 'paymentType'
> & {
  collection?: Maybe<Pick<Collection, 'id' | 'title'>>
  issuedArtist?: Maybe<Pick<Artist, 'id'>>
  thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
}

export type StorePurchaseHistoryFragment = Pick<
  UserCartTransactionOrder,
  | 'id'
  | 'paymentType'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'isKonbini'
  | 'totalAmount'
  | 'formattedTotalAmount'
  | 'originalTotalAmount'
  | 'formattedOriginalTotalAmount'
> & {
  pickupUsers?: Maybe<
    Array<
      Pick<
        PickupUser,
        | 'id'
        | 'collectionId'
        | 'pickupTime'
        | 'formattedPickupTime'
        | 'formattedPickupStartEndTime'
      > & { eventCollection: Pick<EventCollection, 'eventId' | 'validMinutes'> }
    >
  >
  checkoutFees?: Maybe<
    Pick<CheckoutFees, 'totalFee'> & {
      shipping?: Maybe<FeeDetailFragment>
      konbini?: Maybe<FeeDetailFragment>
      collections?: Maybe<Array<Maybe<CollectionFeeDetailFragment>>>
    }
  >
  items: Array<
    Pick<UserCartTransactionItem, 'itemId' | 'count' | 'amount'> & {
      item: {
        collection?: Maybe<
          Pick<Collection, 'id' | 'title'> & {
            event?: Maybe<Pick<Event, 'id' | 'title' | 'note'>>
          }
        >
      } & StoreItemDetailFragment
      skus: Array<
        Maybe<
          Pick<UserCartItemSku, 'count' | 'skuId'> & {
            sku: Pick<
              SkuUnit,
              'id' | 'unitName' | 'price' | 'formattedPrice'
            > & { thumbnail?: Maybe<Pick<Image, 'uri'>> }
          }
        >
      >
    }
  >
  cancels?: Maybe<
    Array<
      Pick<
        CartCancel,
        'canceledAt' | 'canceledCount' | 'reason' | 'refundedAmount'
      > & {
        canceledItems: Array<
          Pick<UserCartItem, 'count' | 'itemId'> & {
            skus: Array<Pick<UserCartItemSku, 'skuId' | 'count'>>
            item: Pick<Item, 'id' | 'title'>
          }
        >
      }
    >
  >
}

export type GachaTicketOrderBoxFragment = Pick<
  GachaTicketOrder,
  'id' | 'orderType' | 'title' | 'count' | 'createdAt' | 'formattedCreatedAt'
>

export type TradeBoxFragment = Pick<
  TradeNegotiation,
  'id' | 'status' | 'createdAt' | 'formattedCreatedAt'
> & {
  request: Pick<TradeRequest, 'id'> & {
    requestedByUser?: Maybe<
      Pick<User, 'id' | 'displayName'> & {
        avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
      }
    >
    providableItems: Array<
      Pick<TradeItem, 'itemId'> & {
        item: Pick<Item, 'id' | 'ownedCount' | 'title'> & {
          issuedArtist?: Maybe<Pick<Artist, 'id'>>
          thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>> }
        }
        itemSerial: Pick<ItemSerial, 'formattedSerialNumber'>
      }
    >
  }
  candidates: Array<
    Pick<TradeCandidate, 'id'> & {
      user?: Maybe<
        Pick<User, 'id' | 'displayName'> & {
          avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
        }
      >
      candidateItems: Array<
        Pick<TradeItem, 'itemId'> & {
          item: Pick<Item, 'id' | 'ownedCount' | 'title'> & {
            issuedArtist?: Maybe<Pick<Artist, 'id'>>
            thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>> }
          }
          itemSerial: Pick<ItemSerial, 'formattedSerialNumber'>
        }
      >
    }
  >
}

export type UserTradeBoxFragment = Pick<TradableUser, 'id'> & {
  user: Pick<User, 'id' | 'displayName'> & {
    avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
  }
  serials: Array<
    Pick<
      UserItemSerial,
      'id' | 'itemId' | 'serialId' | 'isProtected' | 'formattedSerialNumber'
    >
  >
}

export type PrepaidPointBoxFragment = Pick<
  PrepaidPointTransaction,
  | 'id'
  | 'type'
  | 'transactionPrepaidPoint'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'formattedTransactionPrepaidPoint'
>

export type AccountSettingsQueryVariables = Exact<{ [key: string]: never }>

export type AccountSettingsQuery = {
  user?: Maybe<Pick<User, 'id' | 'isMembershipPersonalInformationRequired'>>
}

export type ArtistCollectionsQueryVariables = Exact<{
  userCollectionsPageInput?: Maybe<PageInputDate>
  userCollectionsArtistId?: Maybe<Scalars['ID']>
}>

export type ArtistCollectionsQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  user?: Maybe<CurrentUserFragment>
  userCollections: {
    edges: Array<{ node: CollectionBoxFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
}

export type FetchNewArtistCollectionsQueryVariables = Exact<{
  pageInput?: Maybe<PageInputDate>
  artistId?: Maybe<Scalars['ID']>
}>

export type FetchNewArtistCollectionsQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  userCollections: {
    edges: Array<{ node: CollectionBoxFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
}

export type MonthlyEventsByArtistFragment = Pick<
  Event,
  | 'id'
  | 'title'
  | 'startedAt'
  | 'endedAt'
  | 'closeDate'
  | 'dateRange'
  | 'aboutDateRange'
  | 'dateArray'
> & {
  thumbnail?: Maybe<Pick<Image, 'mediumUri' | 'uri'>>
  location?: Maybe<Pick<GpsData, 'approximateAddress'>>
  artist: { currentLive?: Maybe<Pick<LiveStream, 'id'>> }
}

export type MonthlyEventsByArtistQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>
  artistId: Scalars['String']
  month: Scalars['String']
}>

export type MonthlyEventsByArtistQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  monthlyEventsByArtist: Array<MonthlyEventsByArtistFragment>
}

export type ArtistSearchQueryVariables = Exact<{ [key: string]: never }>

export type ArtistSearchQuery = { user?: Maybe<CurrentUserFragment> }

export type GachasConnectionFragment = {
  edges: Array<{ node: GachaBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type ArtistGachasQueryVariables = Exact<{
  gachasArtistId?: Maybe<Scalars['ID']>
  gachasPageInput?: Maybe<PageInputDate>
}>

export type ArtistGachasQuery = {
  user?: Maybe<CurrentUserFragment>
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  gachas: GachasConnectionFragment
}

export type FetchNewArtistGachasQueryVariables = Exact<{
  gachasArtistId?: Maybe<Scalars['ID']>
  gachasPageInput?: Maybe<PageInputDate>
}>

export type FetchNewArtistGachasQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  gachas: GachasConnectionFragment
}

export type GalleryPostFragment = Pick<
  Post,
  | 'id'
  | 'categoryId'
  | 'content'
  | 'createdAt'
  | 'status'
  | 'publishedAt'
  | 'formattedPublishedAt'
  | 'formattedRelativePublishedAt'
  | 'formattedCreatedAt'
  | 'planTitle'
  | 'likeCount'
  | 'replyCount'
  | 'resourceType'
  | 'isLike'
  | 'isPremium'
  | 'planId'
  | 'artistId'
  | 'isAccessible'
> & {
  category?: Maybe<
    Pick<
      PostCategoryDetail,
      'id' | 'name' | 'description' | 'createdAt' | 'updatedAt'
    >
  >
  videoThumbnail?: Maybe<
    Pick<PostVideoThumbnail, 'uri' | 'mediumUri' | 'blurUri'>
  >
  imageThumbnail?: Maybe<Pick<PostImageThumbnail, 'blurUri'>>
  resources?: Maybe<Array<Pick<PostImageResource, 'uri' | 'mediumUri'>>>
  resource?: Maybe<Pick<PostResource, 'uri' | 'videoId'>>
  artist: Pick<Artist, 'id' | 'displayName'> & {
    patron?: Maybe<
      Pick<Patron, 'isPersonalInformationRequired'> & {
        subscription?: Maybe<
          Pick<PatronSubsription, 'planId' | 'amount' | 'title'>
        >
      }
    >
    subscription?: Maybe<{
      plans?: Maybe<
        Array<Pick<MembershipSubscriptionPlan, 'planId' | 'amount' | 'title'>>
      >
      products?: Maybe<
        Array<
          Pick<MembershipSubscriptionProduct, 'productId' | 'title'> & {
            plans: Array<Pick<MembershipSubscriptionPlan, 'planId' | 'amount'>>
          }
        >
      >
    }>
  }
}

export type GalleryPostsFragment = {
  edges: Array<{ node: GalleryPostFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type GalleryPostsQueryVariables = Exact<{
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
  resourceTypes?: Maybe<Array<PostType> | PostType>
  categoryId?: Maybe<Scalars['String']>
}>

export type GalleryPostsQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName' | 'totalUnreadMentionCount'> & {
      postCategory?: Maybe<
        Pick<PostCategory, 'id'> & {
          categories: Array<
            Pick<
              PostCategoryDetail,
              'id' | 'name' | 'description' | 'createdAt' | 'updatedAt'
            >
          >
        }
      >
      posts: GalleryPostsFragment
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    }
  >
  user?: Maybe<CurrentUserFragment>
}

export type GalleryCategoryQueryVariables = Exact<{
  artistId: Scalars['ID']
}>

export type GalleryCategoryQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      postCategory?: Maybe<
        Pick<PostCategory, 'id'> & {
          categories: Array<
            Pick<
              PostCategoryDetail,
              'id' | 'name' | 'description' | 'createdAt' | 'updatedAt'
            >
          >
        }
      >
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  user?: Maybe<CurrentUserFragment>
}

export type ArtistWithSubscriptionFragment = Pick<
  Artist,
  'id' | 'displayName'
> & {
  avatar?: Maybe<Pick<Avatar, 'uri' | 'mediumUri' | 'smallUri'>>
  subscription?: Maybe<{
    products?: Maybe<
      Array<{
        plans: Array<{
          appstore?: Maybe<Pick<IapProductInfo, 'productId'>>
          playstore?: Maybe<Pick<IapProductInfo, 'productId'>>
        }>
      }>
    >
  }>
  pendingPurchasePatron?: Maybe<
    Pick<
      PendingPurchaseItem,
      | 'id'
      | 'artistId'
      | 'status'
      | 'expiredAt'
      | 'formattedExpiredAt'
      | 'voucherUrl'
    > & {
      metadata?: Maybe<{
        membership?: Maybe<
          Pick<PendingPurchaseItemMetadataMembership, 'planId'>
        >
      }>
    }
  >
  patron?: Maybe<
    Pick<
      Patron,
      | 'id'
      | 'amount'
      | 'expiredAt'
      | 'formattedExpiredAt'
      | 'downgradeAtNextPeriod'
      | 'cancelAtPeriodEnd'
      | 'type'
      | 'paymentMethod'
      | 'isPersonalInformationRequired'
    > & {
      subscription?: Maybe<SubscriptionDetailFragment>
      iapSubscription?: Maybe<Pick<IapSubscriptionInfo, 'store'>>
    }
  >
}

export type PatronDetailFragment = Pick<
  Patron,
  | 'id'
  | 'amount'
  | 'createdAt'
  | 'updatedAt'
  | 'continueMonth'
  | 'formattedUpdatedAt'
  | 'paymentMethod'
  | 'formattedCreatedAt'
  | 'cancelAtPeriodEnd'
  | 'expiredAt'
  | 'formattedExpiredAt'
  | 'artistId'
  | 'type'
  | 'isPersonalInformationRequired'
  | 'downgradeAtNextPeriod'
> & {
  user: Pick<User, 'id'>
  subscription?: Maybe<SubscriptionDetailFragment>
  artist: Pick<
    Artist,
    'id' | 'accountId' | 'formattedAccountId' | 'displayName' | 'paymentType'
  > & {
    avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    pendingPurchasePatron?: Maybe<
      Pick<
        PendingPurchaseItem,
        | 'id'
        | 'artistId'
        | 'status'
        | 'expiredAt'
        | 'formattedExpiredAt'
        | 'voucherUrl'
      > & {
        metadata?: Maybe<{
          membership?: Maybe<
            Pick<PendingPurchaseItemMetadataMembership, 'planId'>
          >
        }>
      }
    >
  } & ArtistMembershipSubscriptionDetailFragment
  iapSubscription?: Maybe<Pick<IapSubscriptionInfo, 'store'>>
}

export type SubscriptionDetailFragment = Pick<
  PatronSubsription,
  'planId' | 'status' | 'amount' | 'downgradePlanId' | 'subscriptionId'
>

export type ArtistMembershipQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>
}>

export type ArtistMembershipQuery = {
  artist?: Maybe<
    ArtistWithSubscriptionFragment & ArtistMembershipSubscriptionDetailFragment
  >
  user?: Maybe<Pick<User, 'id' | 'isMembershipPersonalInformationRequired'>>
  paymentSource?: Maybe<PaymentDetailFragment>
}

export type PreviewSubscriptionUpdateQueryVariables = Exact<{
  planId: Scalars['String']
  artistId: Scalars['String']
}>

export type PreviewSubscriptionUpdateQuery = {
  previewSubscriptionUpdate: Pick<
    PreviewSubscriptionUpdate,
    'planId' | 'diffAmount'
  >
}

export type ArtistMembershipForGuestQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>
}>

export type ArtistMembershipForGuestQuery = {
  artist?: Maybe<
    ArtistWithSubscriptionFragment & ArtistMembershipSubscriptionDetailFragment
  >
}

export type StartSubscriptionMutationVariables = Exact<{
  artistId: Scalars['ID']
  planId: Scalars['ID']
}>

export type StartSubscriptionMutation = {
  startSubscription: PatronDetailFragment
}

export type UpgradeSubscriptionPlanMutationVariables = Exact<{
  planId: Scalars['String']
  paymentMethod?: Maybe<PaymentMethod>
  artistId: Scalars['String']
}>

export type UpgradeSubscriptionPlanMutation = {
  upgradeSubscriptionPlan: PatronDetailFragment
}

export type DowngradeSubscriptionPlanMutationVariables = Exact<{
  planId: Scalars['String']
  paymentMethod?: Maybe<PaymentMethod>
  artistId: Scalars['String']
}>

export type DowngradeSubscriptionPlanMutation = {
  downgradeSubscriptionPlan: PatronDetailFragment
}

export type CancelDowngradeSubscriptionPlanMutationVariables = Exact<{
  artistId: Scalars['String']
}>

export type CancelDowngradeSubscriptionPlanMutation = {
  cancelDowngradeSubscriptionPlan: PatronDetailFragment
}

export type TryPaymentLockMutationVariables = Exact<{
  uuid: Scalars['String']
}>

export type TryPaymentLockMutation = {
  tryPaymentLock: Pick<PaymentLockResponse, 'success'>
}

export type CreateIapSubscriptionMutationVariables = Exact<{
  productId: Scalars['String']
  uuid?: Maybe<Scalars['String']>
  app?: Maybe<Scalars['String']>
  artistId?: Maybe<Scalars['String']>
}>

export type CreateIapSubscriptionMutation = {
  createIapSubscription: PatronDetailFragment
}

export type ArtistMembershipCompletionQueryVariables = Exact<{
  pendingPurchaseId: Scalars['ID']
}>

export type ArtistMembershipCompletionQuery = {
  pendingPurchaseItem: Pick<
    PendingPurchaseItem,
    'id' | 'expiredAt' | 'formattedExpiredAt' | 'voucherUrl'
  >
}

export type ArtistMembershipSubscriptionDetailFragment = {
  subscription?: Maybe<{
    products?: Maybe<Array<MembershipSubscriptionProductDetailFragment>>
  }>
}

export type MembershipSubscriptionProductDetailFragment = Pick<
  MembershipSubscriptionProduct,
  'title' | 'productId' | 'annualContent' | 'annualDiscountText' | 'content'
> & { plans: Array<MembershipSubscriptionPlanDetailFragment> }

export type MembershipSubscriptionPlanDetailFragment = Pick<
  MembershipSubscriptionPlan,
  | 'interval'
  | 'planId'
  | 'amount'
  | 'formattedAmount'
  | 'planRelationship'
  | 'month'
  | 'status'
>

export type ArtistMembershipPaymentEditQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>
}>

export type ArtistMembershipPaymentEditQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      patron?: Maybe<
        Pick<Patron, 'id' | 'amount' | 'paymentMethod'> & {
          subscription?: Maybe<SubscriptionDetailFragment>
        }
      >
    } & ArtistMembershipSubscriptionDetailFragment
  >
  user?: Maybe<Pick<User, 'id'> & { credit?: Maybe<PaymentDetailFragment> }>
}

export type ArtistMembershipPurchaseConfirmationQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>
}>

export type ArtistMembershipPurchaseConfirmationQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & ArtistMembershipSubscriptionDetailFragment
  >
  user?: Maybe<Pick<User, 'id'> & { credit?: Maybe<PaymentDetailFragment> }>
}

export type StartPendinggSubscriptionMutationVariables = Exact<{
  artistId: Scalars['ID']
  planId: Scalars['String']
}>

export type StartPendinggSubscriptionMutation = {
  startPendinggSubscription:
    | (Pick<Patron, 'id'> & {
        artist: Pick<Artist, 'id'> & {
          pendingPurchasePatron?: Maybe<Pick<PendingPurchaseItem, 'id'>>
        }
      })
    | Pick<PendingPurchaseItem, 'id'>
}

export type ArtistMembershipPurchaseProceedQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>
}>

export type ArtistMembershipPurchaseProceedQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & ArtistMembershipSubscriptionDetailFragment
  >
  user?: Maybe<Pick<User, 'id'> & { credit?: Maybe<PaymentDetailFragment> }>
}

export type MissionsConnectionFragment = {
  edges: Array<Pick<Edge, 'cursor'> & { node: MissionBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type UserStepMissionsConnectionFragment = {
  edges: Array<Pick<Edge, 'cursor'> & { node: UserStepMissionBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type ArtistMissionsQueryVariables = Exact<{
  missionsTypeFilter?: Maybe<Array<MissionType> | MissionType>
  missionsPageInput?: Maybe<PageInput>
  missionsArtistId: Scalars['ID']
  stepMissionsType?: Maybe<StepMissionType>
  stepMissionsFilter?: Maybe<StepMissionFilter>
  stepMissionsPageInput?: Maybe<PageInput>
}>

export type ArtistMissionsQuery = {
  user?: Maybe<CurrentUserFragment>
  missionsWithFilteres: MissionsConnectionFragment
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  artistStepMissions: UserStepMissionsConnectionFragment
}

export type ArtistMissionsForGuestQueryVariables = Exact<{
  missionsTypeFilter?: Maybe<Array<MissionType> | MissionType>
  missionsPageInput?: Maybe<PageInput>
  missionsArtistId: Scalars['ID']
}>

export type ArtistMissionsForGuestQuery = {
  missionsWithFilteres: MissionsConnectionFragment
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
}

export type FetchNewArtistMissionsQueryVariables = Exact<{
  missionsTypeFilter?: Maybe<Array<MissionType> | MissionType>
  missionsPageInput?: Maybe<PageInput>
  missionsArtistId: Scalars['ID']
}>

export type FetchNewArtistMissionsQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  missionsWithFilteres: MissionsConnectionFragment
}

export type GetStepMissionRewardMutationVariables = Exact<{
  stepMissionId: Scalars['String']
}>

export type GetStepMissionRewardMutation = {
  getStepMissionReward: UserStepMissionBoxFragment
}

export type ArtistOfficialInformationQueryVariables = Exact<{
  id: Scalars['ID']
  artistId: Scalars['String']
  pageInput?: Maybe<PageInput>
}>

export type ArtistOfficialInformationQuery = {
  organizationOfficialArtistNotifications: OrganizationNotificationConnectionFragment
  artist?: Maybe<
    Pick<Artist, 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
}

export type FetchNewArtistOfficialInformationQueryVariables = Exact<{
  id: Scalars['ID']
  artistId: Scalars['String']
  pageInput?: Maybe<PageInput>
}>

export type FetchNewArtistOfficialInformationQuery = {
  organizationOfficialArtistNotifications: OrganizationNotificationConnectionFragment
  artist?: Maybe<
    Pick<Artist, 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
}

export type ArtistPostVideoFragment = Pick<
  Post,
  | 'id'
  | 'content'
  | 'createdAt'
  | 'status'
  | 'publishedAt'
  | 'formattedPublishedAt'
  | 'formattedRelativePublishedAt'
  | 'formattedCreatedAt'
  | 'planTitle'
  | 'likeCount'
  | 'replyCount'
  | 'resourceType'
  | 'isLike'
  | 'isPremium'
  | 'planId'
  | 'artistId'
  | 'isAccessible'
> & {
  category?: Maybe<Pick<PostCategoryDetail, 'id' | 'name'>>
  resources?: Maybe<Array<Pick<PostImageResource, 'uri'>>>
  resource?: Maybe<Pick<PostResource, 'uri' | 'videoId'>>
  videoThumbnail?: Maybe<Pick<PostVideoThumbnail, 'uri' | 'blurUri'>>
  artist: Pick<
    Artist,
    'id' | 'displayName' | 'accountId' | 'formattedAccountId'
  > & {
    avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    patron?: Maybe<
      Pick<Patron, 'id' | 'isPersonalInformationRequired'> & {
        subscription?: Maybe<
          Pick<PatronSubsription, 'planId' | 'amount' | 'title'>
        >
      }
    >
    subscription?: Maybe<{
      plans?: Maybe<
        Array<Pick<MembershipSubscriptionPlan, 'planId' | 'amount' | 'title'>>
      >
      products?: Maybe<
        Array<
          Pick<MembershipSubscriptionProduct, 'productId' | 'title'> & {
            plans: Array<Pick<MembershipSubscriptionPlan, 'planId' | 'amount'>>
          }
        >
      >
    }>
  }
}

export type ArtistPostVideosQueryVariables = Exact<{
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
  resourceType?: Maybe<PostType>
  categoryId?: Maybe<Scalars['String']>
}>

export type ArtistPostVideosQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      posts: ArtistPostsFragment
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  user?: Maybe<CurrentUserFragment>
}

export type FetchNewArtistPostVideosQueryVariables = Exact<{
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
  resourceType?: Maybe<PostType>
  categoryId?: Maybe<Scalars['String']>
}>

export type FetchNewArtistPostVideosQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      posts: ArtistPostsFragment
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
}

export type ArtistPostsFragment = {
  edges: Array<{ node: PostBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type ArtistPostsQueryVariables = Exact<{
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
  resourceType?: Maybe<PostType>
}>

export type ArtistPostsQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      posts: ArtistPostsFragment
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  user?: Maybe<CurrentUserFragment>
}

export type FetchNewArtistPostsQueryVariables = Exact<{
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
  resourceType?: Maybe<PostType>
}>

export type FetchNewArtistPostsQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      posts: ArtistPostsFragment
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
}

export type RankingsConnectionFragment = {
  edges: Array<{ node: RankingUserConnectionFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type ArtistRankingQueryVariables = Exact<{
  artistId: Scalars['String']
  periodType: RankingPeriodType
  pageInput?: Maybe<PageInput>
}>

export type ArtistRankingQuery = {
  user?: Maybe<
    Pick<User, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
  artistRankings?: Maybe<{
    artist: Pick<Artist, 'id' | 'displayName'>
    latestRankingPeriod?: Maybe<
      {
        myRanking?: Maybe<RankingUserWithoutIdConnectionFragment>
        rankings?: Maybe<RankingsConnectionFragment>
      } & RankingPeriodBaseConnectionFragment
    >
  }>
}

export type ArtistStoreQueryVariables = Exact<{
  artistId: Scalars['ID']
  storeArtistId: Scalars['String']
}>

export type ArtistStoreQuery = {
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    'prepaidPointId' | 'balance' | 'formattedPrepaidPointBarance'
  >
  user?: Maybe<CurrentUserFragment>
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  storeCollections: { edges: Array<{ node: ArtistStoreCollectionBoxFragment }> }
  recentlyViewedCollections: RecommendCollectionConnectionFragment
  recentlyStoreViewedItems: RecentViewedItemConnectionFragment
}

export type ArtistStoreForGuestQueryVariables = Exact<{
  artistId: Scalars['ID']
  storeArtistId: Scalars['String']
}>

export type ArtistStoreForGuestQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  storeCollections: { edges: Array<{ node: ArtistStoreCollectionBoxFragment }> }
}

export type StoreItemDetailFragment = StoreItemDetailGuestFragment

export type StoreItemDetailSkuFragment = StoreItemDetailGuestSkuFragment

export type StoreItemDetailGuestFragment = Pick<
  Item,
  | 'id'
  | 'title'
  | 'description'
  | 'type'
  | 'isPremium'
  | 'commentCount'
  | 'price'
  | 'personalInformationRequirements'
  | 'resaleEnabled'
  | 'hideSalesNumber'
  | 'paymentType'
  | 'purchasedByPointNumber'
  | 'purchaseLimitByUser'
  | 'purchaseLimitPerOrder'
  | 'remainingNumber'
  | 'formattedPrice'
  | 'buyOverLimit'
  | 'salesStatus'
  | 'isPublished'
  | 'resaleOpenCount'
  | 'formattedResaleOpenCount'
  | 'resaleRaffleAppliedCount'
  | 'formattedResaleRaffleAppliedCount'
  | 'issuedNumber'
  | 'purchasedNumber'
  | 'isResaleAvailable'
  | 'isResaleEnable'
  | 'willResaleAvailable'
  | 'pendingPurchaseNumber'
  | 'requirePersonalInformation'
> & {
  rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
  fees?: Maybe<{
    collections?: Maybe<Array<Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>>>
    konbini?: Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>
    shipping?: Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>
  }>
  nft?: Maybe<{ chains?: Maybe<Array<Pick<ItemNftChain, 'chainName'>>> }>
  ogp?: Maybe<Pick<Ogp, 'uri'>>
  images?: Maybe<Array<Pick<ItemImage, 'uri' | 'mediumUri' | 'caption'>>>
  sku?: Maybe<
    Pick<
      SkuUnit,
      | 'id'
      | 'order'
      | 'pendingPurchaseNumber'
      | 'purchasedNumber'
      | 'stock'
      | 'unitName'
      | 'price'
      | 'formattedPrice'
    > & {
      thumbnail?: Maybe<
        Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
      >
    }
  >
  skuSettings?: Maybe<SkuTypeFragment>
  prepaidPoint?: Maybe<Pick<PrepaidPoint, 'exchangeRate'>>
  issuedArtist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'mediumUri' | 'smallUri'>>
    }
  >
  thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'mediumUri' | 'smallUri'>> }
  metadata?: Maybe<{
    ticket?: Maybe<
      Pick<
        ItemMetadataDetailTicket,
        'description' | 'note' | 'isThroughTicket' | 'expirationTerm'
      > & {
        resalePeriod?: Maybe<
          Pick<
            ReceptionPeriod,
            'startedAt' | 'endedAt' | 'formattedStartedAt' | 'formattedEndedAt'
          >
        >
      }
    >
    magazine?: Maybe<Pick<ItemMetadataDetailMagazine, 'pageDirection'>>
  }>
  purchasableUsers?: Maybe<Array<PurchasableUserDetailFragment>>
  collection?: Maybe<
    Pick<Collection, 'id' | 'isPublishOnStore' | 'isEventPickup'> & {
      event?: Maybe<Pick<Event, 'id'>>
    }
  >
  preSale?: Maybe<
    Pick<PreSale, 'id'> & {
      lottery?: Maybe<Pick<PreSaleLottery, 'purchasableCount'>>
      firstComeFirstServed?: Maybe<
        Pick<PreSaleFirstComeFirstServed, 'purchasableCount'>
      >
    }
  >
}

export type StoreItemDetailGuestSkuFragment = Pick<
  Item,
  | 'id'
  | 'title'
  | 'description'
  | 'type'
  | 'isPremium'
  | 'commentCount'
  | 'price'
  | 'personalInformationRequirements'
  | 'resaleEnabled'
  | 'hideSalesNumber'
  | 'paymentType'
  | 'purchasedByPointNumber'
  | 'purchaseLimitByUser'
  | 'purchaseLimitPerOrder'
  | 'remainingNumber'
  | 'formattedPrice'
  | 'buyOverLimit'
  | 'salesStatus'
  | 'isPublished'
  | 'resaleOpenCount'
  | 'isResaleEnable'
  | 'formattedResaleOpenCount'
  | 'resaleRaffleAppliedCount'
  | 'formattedResaleRaffleAppliedCount'
  | 'issuedNumber'
  | 'purchasedNumber'
  | 'isResaleAvailable'
  | 'willResaleAvailable'
  | 'pendingPurchaseNumber'
  | 'requirePersonalInformation'
> & {
  rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
  fees?: Maybe<{
    collections?: Maybe<Array<Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>>>
    konbini?: Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>
    shipping?: Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>
  }>
  nft?: Maybe<{ chains?: Maybe<Array<Pick<ItemNftChain, 'chainName'>>> }>
  ogp?: Maybe<Pick<Ogp, 'uri'>>
  images?: Maybe<Array<Pick<ItemImage, 'uri' | 'mediumUri' | 'caption'>>>
  sku?: Maybe<
    Pick<
      SkuUnit,
      | 'id'
      | 'order'
      | 'pendingPurchaseNumber'
      | 'purchasedNumber'
      | 'stock'
      | 'unitName'
      | 'price'
      | 'formattedPrice'
    > & {
      thumbnail?: Maybe<
        Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
      >
    }
  >
  skuSettings?: Maybe<SkuTypeFragment>
  prepaidPoint?: Maybe<Pick<PrepaidPoint, 'exchangeRate'>>
  issuedArtist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'mediumUri' | 'smallUri'>>
    }
  >
  thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'mediumUri' | 'smallUri'>> }
  metadata?: Maybe<{
    ticket?: Maybe<
      Pick<
        ItemMetadataDetailTicket,
        'description' | 'note' | 'isThroughTicket' | 'expirationTerm'
      > & {
        resalePeriod?: Maybe<
          Pick<
            ReceptionPeriod,
            'startedAt' | 'endedAt' | 'formattedStartedAt' | 'formattedEndedAt'
          >
        >
      }
    >
    magazine?: Maybe<Pick<ItemMetadataDetailMagazine, 'pageDirection'>>
  }>
  purchasableUsers?: Maybe<Array<PurchasableUserDetailFragment>>
  collection?: Maybe<
    Pick<Collection, 'id' | 'isPublishOnStore' | 'isEventPickup'> & {
      event?: Maybe<Pick<Event, 'id'>>
    }
  >
  preSale?: Maybe<
    Pick<PreSale, 'id'> & {
      lottery?: Maybe<Pick<PreSaleLottery, 'purchasableCount'>>
      firstComeFirstServed?: Maybe<
        Pick<PreSaleFirstComeFirstServed, 'purchasableCount'>
      >
    }
  >
}

export type ArtistStoreItemDetailQueryVariables = Exact<{
  id: Scalars['ID']
  itemId: Scalars['String']
  platform: Scalars['String']
  purchasablePrepaidPointPageInput?: Maybe<PageInput>
  skuId?: Maybe<Scalars['String']>
}>

export type ArtistStoreItemDetailQuery = Pick<Query, 'userCartCount'> & {
  user?: Maybe<
    Pick<
      User,
      | 'id'
      | 'phoneNumber'
      | 'fullName'
      | 'isPhoneNumberVerified'
      | 'formattedPhoneNumber'
    > & {
      cryptoWallet?: Maybe<Pick<CryptoWallet, 'address'>>
    } & UserDeliveryInfoFragment
  >
  item?: Maybe<StoreItemDetailGuestFragment>
  userActiveResaleItemRaffle?: Maybe<
    Pick<ItemResaleRaffleUser, 'id' | 'itemId' | 'skuId'>
  >
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    'balance' | 'prepaidPointId' | 'formattedPrepaidPointBarance'
  >
  purchasablePrepaidPoints: PurchasablePrepaidPointConnectionFragment
  paymentSource?: Maybe<PaymentCreateDetailFragment>
}

export type ArtistStoreItemDetailWithSkuQueryVariables = Exact<{
  id: Scalars['ID']
  itemId: Scalars['String']
  skuId?: Maybe<Scalars['String']>
}>

export type ArtistStoreItemDetailWithSkuQuery = {
  item?: Maybe<StoreItemDetailGuestSkuFragment>
  userActiveResaleItemRaffle?: Maybe<
    Pick<ItemResaleRaffleUser, 'id' | 'itemId'>
  >
}

export type ArtistStoreItemDetailForGuestQueryVariables = Exact<{
  id: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type ArtistStoreItemDetailForGuestQuery = {
  item?: Maybe<StoreItemDetailGuestFragment>
}

export type AddItemToCartMutationVariables = Exact<{
  inputs: AddToCartInput
}>

export type AddItemToCartMutation = {
  addToCart: Pick<UserCart, 'id' | 'totalCount'>
}

export type PurchaseItemByPointMutationVariables = Exact<{
  itemId: Scalars['ID']
  purchaseNumber?: Maybe<Scalars['Int']>
  prepaidPoint?: Maybe<Scalars['Int']>
  skuId?: Maybe<Scalars['String']>
}>

export type PurchaseItemByPointMutation = {
  purchaseItemByPoint: Pick<ItemOrder, 'id' | 'price' | 'userId'> & {
    item?: Maybe<StoreItemDetailFragment>
  }
}

export type ViewStoreItemMutationVariables = Exact<{
  inputs: ViewStoreItemInput
}>

export type ViewStoreItemMutation = {
  viewStoreItem: Pick<
    RecentlyStoreViewedItem,
    'id' | 'userId' | 'itemId' | 'viewCount'
  >
}

export type PurchaseItemImmediatelyMutationVariables = Exact<{
  inputs: PurchaseItemImmediatelyInput
  skuId?: Maybe<Scalars['String']>
}>

export type PurchaseItemImmediatelyMutation = {
  purchaseItemImmediately: Pick<
    UserCartTransactionOrder,
    'id' | 'paymentType'
  > & { items: Array<{ item: StoreItemDetailFragment }> }
}

export type ArtistThreadChannelConnectionFragment = {
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  edges: Array<{ node: ThreadBoxFragment }>
}

export type ArtistThreadsQueryVariables = Exact<{
  inputs: ArtistThreadChannelsInput
  pageInput?: Maybe<PageInput>
  artistId?: Maybe<Scalars['ID']>
}>

export type ArtistThreadsQuery = {
  artistThreadChannels: ArtistThreadChannelConnectionFragment
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName' | 'accountId' | 'formattedAccountId'> & {
      patron?: Maybe<Pick<Patron, 'id' | 'isPersonalInformationRequired'>>
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  user?: Maybe<CurrentUserFragment>
}

export type FetchNewArtistThreadsQueryVariables = Exact<{
  inputs: ArtistThreadChannelsInput
  pageInput?: Maybe<PageInput>
  artistId?: Maybe<Scalars['ID']>
}>

export type FetchNewArtistThreadsQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName' | 'accountId' | 'formattedAccountId'> & {
      patron?: Maybe<Pick<Patron, 'id' | 'isPersonalInformationRequired'>>
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  artistThreadChannels: ArtistThreadChannelConnectionFragment
}

export type ArtistThreadsForGuestQueryVariables = Exact<{
  inputs: ArtistThreadChannelsInput
  pageInput?: Maybe<PageInput>
  artistId?: Maybe<Scalars['ID']>
}>

export type ArtistThreadsForGuestQuery = {
  artistThreadChannels: ArtistThreadChannelConnectionFragment
  artist?: Maybe<ArtistBottomTabFragment & ArtistSmallDetailFragment>
}

export type FollowerFragment = Pick<Follower, 'id'> & {
  artist: Pick<Artist, 'id' | 'displayName'> & {
    follower?: Maybe<Pick<Follower, 'id' | 'artistId'>>
    avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
  }
  user: Pick<User, 'id'>
}

export type ArtistSmallDetailFragment = Pick<
  Artist,
  'id' | 'displayName' | 'totalUnreadMentionCount'
> & {
  patron?: Maybe<Pick<Patron, 'id'>>
  avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
  currentLive?: Maybe<Pick<LiveStream, 'id'>>
}

export type EventsByArtistConnectionFragment = {
  edges: Array<{ node: ArtistTopEventFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type ArtistTopEventFragment = Pick<
  Event,
  | 'id'
  | 'title'
  | 'startedAt'
  | 'endedAt'
  | 'closeDate'
  | 'dateRange'
  | 'aboutDateRange'
  | 'dateArray'
> & {
  thumbnail?: Maybe<Pick<Image, 'mediumUri' | 'uri'>>
  preSales: EventPreSaleConnectionFragment
  location?: Maybe<Pick<GpsData, 'approximateAddress'>>
}

export type UnfollowMutationVariables = Exact<{
  artistId: Scalars['ID']
}>

export type UnfollowMutation = { unfollow?: Maybe<FollowerFragment> }

export type FollowMutationVariables = Exact<{
  artistId: Scalars['ID']
}>

export type FollowMutation = { follow: FollowerFragment }

export type ArtistTopQueryVariables = Exact<{
  artistId: Scalars['ID']
  artistIdString: Scalars['String']
  rankingArtistId: Scalars['String']
  missionsTypeFilter?: Maybe<Array<MissionType> | MissionType>
  artistThreadChannelsInput: ArtistThreadChannelsInput
  filters?: Maybe<ItemsFilterInput>
}>

export type ArtistTopQuery = {
  user?: Maybe<CurrentUserFragment>
  artistRankings?: Maybe<
    {
      latestRankingPeriod?: Maybe<{
        myRanking?: Maybe<RankingUserWithoutIdConnectionFragment>
      }>
    } & RankingBaseConnectionFragment
  >
  artist?: Maybe<
    Pick<
      Artist,
      | 'id'
      | 'displayName'
      | 'accountId'
      | 'introduction'
      | 'formattedAccountId'
      | 'profileText'
      | 'isBlocked'
    > & {
      textLink?: Maybe<Array<Maybe<Pick<ArtistTextLink, 'content' | 'uri'>>>>
      avatar?: Maybe<Pick<Avatar, 'uri' | 'mediumUri' | 'smallUri'>>
      follower?: Maybe<Pick<Follower, 'id' | 'artistId'>>
      subscription?: Maybe<{
        products?: Maybe<
          Array<
            Pick<MembershipSubscriptionProduct, 'content'> & {
              plans: Array<
                Pick<MembershipSubscriptionPlan, 'planId' | 'status'>
              >
            }
          >
        >
      }>
      sns?: Maybe<
        Pick<
          ArtistSns,
          'facebook' | 'instagram' | 'tiktok' | 'twitter' | 'youtube'
        >
      >
      banners?: Maybe<
        Array<{
          thumbnail: Pick<Image, 'uri' | 'mediumUri' | 'smallUri'>
          link?: Maybe<Pick<BannerLink, 'innerPath' | 'externalUrl'>>
        }>
      >
      posts: {
        edges: Array<{ node: PostBoxFragment }>
        pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      }
      patron?: Maybe<
        Pick<Patron, 'id' | 'isPersonalInformationRequired'> & {
          subscription?: Maybe<Pick<PatronSubsription, 'planId'>>
        }
      >
    } & ArtistBottomTabFragment
  >
  gachas: GachasConnectionFragment
  missionsWithFilteres: MissionsConnectionFragment
  artistStepMissions: UserStepMissionsConnectionFragment
  artistThreadChannels: ArtistThreadChannelConnectionFragment
  items: NewStoreItemConnectionFragment
  eventsByArtist: EventsByArtistConnectionFragment
  organizationOfficialArtistNotifications: OrganizationNotificationConnectionFragment
}

export type ArtistTopForGuestQueryVariables = Exact<{
  artistId: Scalars['ID']
  artistIdString: Scalars['String']
  missionsTypeFilter?: Maybe<Array<MissionType> | MissionType>
  artistThreadChannelsInput: ArtistThreadChannelsInput
  filters?: Maybe<ItemsFilterInput>
}>

export type ArtistTopForGuestQuery = {
  artist?: Maybe<
    Pick<
      Artist,
      | 'id'
      | 'displayName'
      | 'accountId'
      | 'formattedAccountId'
      | 'profileText'
      | 'introduction'
    > & {
      textLink?: Maybe<Array<Maybe<Pick<ArtistTextLink, 'content' | 'uri'>>>>
      avatar?: Maybe<Pick<Avatar, 'uri' | 'mediumUri' | 'smallUri'>>
      follower?: Maybe<Pick<Follower, 'id' | 'artistId'>>
      subscription?: Maybe<{
        products?: Maybe<
          Array<
            Pick<MembershipSubscriptionProduct, 'content'> & {
              plans: Array<
                Pick<MembershipSubscriptionPlan, 'planId' | 'status'>
              >
            }
          >
        >
      }>
      sns?: Maybe<
        Pick<
          ArtistSns,
          'facebook' | 'instagram' | 'tiktok' | 'twitter' | 'youtube'
        >
      >
      banners?: Maybe<
        Array<{
          thumbnail: Pick<Image, 'uri' | 'mediumUri' | 'smallUri'>
          link?: Maybe<Pick<BannerLink, 'innerPath' | 'externalUrl'>>
        }>
      >
      posts: {
        edges: Array<{ node: PostBoxFragment }>
        pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      }
      patron?: Maybe<Pick<Patron, 'id'>>
    } & ArtistBottomTabFragment
  >
  gachas: GachasConnectionFragment
  missionsWithFilteres: MissionsConnectionFragment
  artistThreadChannels: ArtistThreadChannelConnectionFragment
  items: NewStoreItemConnectionFragment
  eventsByArtist: EventsByArtistConnectionFragment
  organizationOfficialArtistNotifications: OrganizationNotificationConnectionFragment
}

export type ArtistLiveStreamQueryVariables = Exact<{
  artistId: Scalars['ID']
}>

export type ArtistLiveStreamQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id' | 'isPremium'>>
    }
  >
}

export type ArtistTradeQueryVariables = Exact<{
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type ArtistTradeQuery = {
  tradeNegotiationsMyRequests: { edges: Array<{ node: TradeBoxFragment }> }
  tradeMyCandidates: {
    edges: Array<{ node: { tradeNegotiation: TradeBoxFragment } }>
  }
  artistTradeNegotiations: {
    edges: Array<{ node: TradeBoxFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  user?: Maybe<CurrentUserFragment>
}

export type FetchNewArtistTradeQueryVariables = Exact<{
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type FetchNewArtistTradeQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
  tradeNegotiationsMyRequests: { edges: Array<{ node: TradeBoxFragment }> }
  tradeMyCandidates: {
    edges: Array<{ node: { tradeNegotiation: TradeBoxFragment } }>
  }
  artistTradeNegotiations: {
    edges: Array<{ node: TradeBoxFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
}

export type ArtistTradeForGuestQueryVariables = Exact<{
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type ArtistTradeForGuestQuery = {
  tradeNegotiationsMyRequests: { edges: Array<{ node: TradeBoxFragment }> }
  tradeMyCandidates: {
    edges: Array<{ node: { tradeNegotiation: TradeBoxFragment } }>
  }
  artistTradeNegotiations: {
    edges: Array<{ node: TradeBoxFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    } & ArtistBottomTabFragment
  >
}

export type AutoFollowSettingsQueryVariables = Exact<{ [key: string]: never }>

export type AutoFollowSettingsQuery = {
  user?: Maybe<
    Pick<User, 'id'> & {
      settings?: Maybe<{ follow?: Maybe<Pick<FollowSettings, 'autoFollow'>> }>
    }
  >
}

export type UpdateUserFollowSettingsMutationVariables = Exact<{
  inputs: UserFollowSettingsInput
}>

export type UpdateUserFollowSettingsMutation = {
  updateUserFollowSettings: Pick<User, 'id'> & {
    settings?: Maybe<{ follow?: Maybe<Pick<FollowSettings, 'autoFollow'>> }>
  }
}

export type BankCreateQueryVariables = Exact<{ [key: string]: never }>

export type BankCreateQuery = {
  user?: Maybe<
    Pick<User, 'id'> & {
      bank?: Maybe<
        Pick<
          UserBank,
          | 'accountHolderName'
          | 'accountNumber'
          | 'accountType'
          | 'bankName'
          | 'branchCode'
          | 'branchName'
        >
      >
    }
  >
}

export type RegisterUserBankMutationVariables = Exact<{
  inputs: UserBankInput
}>

export type RegisterUserBankMutation = { registerUserBank: Pick<User, 'id'> }

export type CampaignDetailFragment = Pick<
  Campaign,
  | 'id'
  | 'startedAt'
  | 'formattedStartedAt'
  | 'endedAt'
  | 'formattedEndedAt'
  | 'description'
  | 'title'
  | 'rankingType'
> & {
  thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>>
  acquisitionPoints: Array<Pick<AcquisitionPoints, 'type' | 'point'>>
}

export type ArtistCampaignRankingFragment = Pick<
  ArtistCampaignRanking,
  'id' | 'createdAt' | 'formattedCreatedAt'
> & {
  ranker: Array<
    Pick<
      CampaignArtistRanker,
      'rank' | 'point' | 'displayName' | 'artistId'
    > & { avatar?: Maybe<Pick<Image, 'uri' | 'smallUri'>> }
  >
  myRank?: Maybe<
    Pick<
      CampaignArtistRanker,
      'rank' | 'point' | 'displayName' | 'artistId'
    > & { avatar?: Maybe<Pick<Image, 'uri' | 'smallUri'>> }
  >
}

export type UserCampaignRankingFragment = Pick<
  UserCampaignRanking,
  'id' | 'createdAt' | 'formattedCreatedAt'
> & {
  ranker: Array<
    Pick<CampaignUserRanker, 'rank' | 'point' | 'displayName' | 'userId'> & {
      avatar?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
    }
  >
  myRank?: Maybe<
    Pick<CampaignUserRanker, 'rank' | 'point' | 'displayName' | 'userId'> & {
      avatar?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
    }
  >
}

export type CampaignDetailQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>
}>

export type CampaignDetailQuery = { campaign?: Maybe<CampaignDetailFragment> }

export type CampaignUserRankingQueryVariables = Exact<{
  inputs?: Maybe<CampaignRankingInput>
}>

export type CampaignUserRankingQuery = {
  campaignRanking?: Maybe<UserCampaignRankingFragment>
}

export type CampaignArtistRankingQueryVariables = Exact<{
  inputs?: Maybe<CampaignRankingInput>
}>

export type CampaignArtistRankingQuery = {
  campaignRanking?: Maybe<ArtistCampaignRankingFragment>
}

export type CollectionDetailConnectionFragment = Pick<
  Collection,
  'id' | 'title' | 'artistId' | 'itemCount' | 'description'
> & {
  artist: Pick<Artist, 'displayName'>
  thumbnail: Pick<CollectionThumbnail, 'uri'>
  labels?: Maybe<Array<Maybe<Pick<CollectionLabel, 'name' | 'id'>>>>
  userCollection?: Maybe<
    Pick<
      UserCollection,
      | 'userId'
      | 'id'
      | 'itemOwnedCount'
      | 'totalOwnedCount'
      | 'totalOwnedItemCount'
    > & {
      ogp?: Maybe<Pick<Image, 'uri'>>
      ownedItems?: Maybe<
        Array<
          Pick<UserCollectionItem, 'itemId' | 'ownedCount'> & {
            userItem?: Maybe<{
              serials: Array<{ serial?: Maybe<Pick<ItemSerial, 'skuId'>> }>
            }>
          }
        >
      >
    }
  >
}

export type CollectionItemsConnectionFragment = {
  edges: Array<{
    node: Pick<CollectionItem, 'id'> & {
      item: Pick<
        Item,
        'id' | 'title' | 'type' | 'usedNumber' | 'isResaleAvailable'
      > & {
        rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
        rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
        thumbnail: {
          front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>>
          back?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>>
        }
        sku?: Maybe<
          Pick<
            SkuUnit,
            | 'id'
            | 'order'
            | 'pendingPurchaseNumber'
            | 'purchasedNumber'
            | 'remainingNumber'
            | 'stock'
            | 'unitName'
          > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>> }
        >
        skuSettings?: Maybe<
          Pick<SkuSettings, 'keyName'> & {
            units: Array<
              Pick<
                SkuUnit,
                'id' | 'unitName' | 'purchasedNumber' | 'price' | 'order'
              > & { thumbnail?: Maybe<Pick<Image, 'mediumUri'>> }
            >
          }
        >
      }
    }
  }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type CollectionDetailQueryVariables = Exact<{
  collectionId: Scalars['ID']
  collectionItemsLabel?: Maybe<Scalars['String']>
  collectionItemsFirstLabel?: Maybe<Scalars['Boolean']>
  pageInput?: Maybe<PageInput>
  userId?: Maybe<Scalars['ID']>
}>

export type CollectionDetailQuery = {
  user?: Maybe<
    Pick<User, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
  collection?: Maybe<
    Pick<
      Collection,
      'id' | 'title' | 'artistId' | 'itemCount' | 'description'
    > & {
      artist: Pick<Artist, 'id' | 'displayName'>
      thumbnail: Pick<CollectionThumbnail, 'uri'>
      userCollection?: Maybe<
        Pick<
          UserCollection,
          | 'itemOwnedCount'
          | 'totalOwnedCount'
          | 'totalOwnedItemCount'
          | 'id'
          | 'userId'
        > & {
          ogp?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
          ownedItems?: Maybe<
            Array<
              Pick<UserCollectionItem, 'itemId' | 'ownedCount'> & {
                userItem?: Maybe<{
                  serials: Array<{ serial?: Maybe<Pick<ItemSerial, 'skuId'>> }>
                }>
              }
            >
          >
        }
      >
      labels?: Maybe<Array<Maybe<Pick<CollectionLabel, 'name' | 'id'>>>>
      collectionItems: CollectionItemsConnectionFragment
    }
  >
}

export type CollectionDetailForGuestQueryVariables = Exact<{
  collectionId: Scalars['ID']
  collectionItemsLabel?: Maybe<Scalars['String']>
  collectionItemsFirstLabel?: Maybe<Scalars['Boolean']>
  pageInput?: Maybe<PageInput>
}>

export type CollectionDetailForGuestQuery = {
  collection?: Maybe<
    Pick<
      Collection,
      'id' | 'title' | 'artistId' | 'itemCount' | 'description'
    > & {
      artist: Pick<Artist, 'id' | 'displayName'>
      thumbnail: Pick<CollectionThumbnail, 'uri'>
      labels?: Maybe<Array<Maybe<Pick<CollectionLabel, 'name' | 'id'>>>>
      collectionItems: CollectionItemsConnectionFragment
    }
  >
}

export type TradableUsersConnectionFragment = {
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  edges: Array<{ node: UserTradeBoxFragment }>
}

export type CollectionItemDetailQueryVariables = Exact<{
  itemId: Scalars['ID']
}>

export type CollectionItemDetailQuery = {
  item?: Maybe<
    Pick<
      Item,
      | 'id'
      | 'price'
      | 'formattedPrice'
      | 'paymentType'
      | 'description'
      | 'commentCount'
      | 'isPublished'
    > & {
      issuedArtist?: Maybe<Pick<Artist, 'id'>>
      serials: SerialItemOwnersFragment
      collection?: Maybe<Pick<Collection, 'id' | 'isPublishOnStore'>>
      gachas?: Maybe<
        Array<
          Pick<Gacha, 'id' | 'name'> & {
            thumbnail: Pick<Image, 'uri' | 'mediumUri'>
          }
        >
      >
    } & ItemImageAreaFragment
  >
  user?: Maybe<
    Pick<User, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
}

export type TradableUsersQueryVariables = Exact<{
  itemId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type TradableUsersQuery = {
  item?: Maybe<
    Pick<Item, 'id'> & { tradableUsers: TradableUsersConnectionFragment }
  >
}

export type ConsoleAccountSettingQueryVariables = Exact<{
  artistId?: Maybe<Scalars['ID']>
}>

export type ConsoleAccountSettingQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'paymentType' | 'hasArrangingDeliveryItems'> & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<Pick<MembershipSubscriptionProduct, 'productId'>>
        >
      }>
    }
  >
}

export type ConsoleBankEditQueryVariables = Exact<{ [key: string]: never }>

export type ConsoleBankEditQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      bank?: Maybe<
        Pick<
          Bank,
          | 'id'
          | 'accountHolderName'
          | 'accountHolderType'
          | 'bankName'
          | 'last4'
          | 'routingNumber'
        >
      >
    }
  >
}

export type UpdateBankMutationVariables = Exact<{
  inputs?: Maybe<BankInput>
}>

export type UpdateBankMutation = {
  updateBank: Pick<Artist, 'id'> & {
    bank?: Maybe<
      Pick<
        Bank,
        | 'id'
        | 'accountHolderName'
        | 'accountHolderType'
        | 'bankName'
        | 'last4'
        | 'routingNumber'
      >
    >
  }
}

export type BusinessDetailFragment = Pick<
  Business,
  | 'birthday'
  | 'formattedBirthday'
  | 'firstNameKana'
  | 'firstNameKanji'
  | 'gender'
  | 'lastNameKana'
  | 'lastNameKanji'
  | 'phone'
> & {
  addressKanji?: Maybe<
    Pick<Address, 'city' | 'line1' | 'line2' | 'postalCode' | 'state' | 'town'>
  >
}

export type ConsoleBusinessEditQueryVariables = Exact<{ [key: string]: never }>

export type ConsoleBusinessEditQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      bank?: Maybe<
        Pick<
          Bank,
          | 'id'
          | 'accountHolderName'
          | 'accountHolderType'
          | 'bankName'
          | 'last4'
          | 'routingNumber'
        >
      >
      business?: Maybe<BusinessDetailFragment>
    }
  >
}

export type BusinessEditMutationVariables = Exact<{
  updateBusinessInputs?: Maybe<BusinessInput>
}>

export type BusinessEditMutation = {
  updateBusiness: Pick<Artist, 'id'> & {
    business?: Maybe<BusinessDetailFragment>
  }
}

export type ArtistBannerDetailFragment = {
  link?: Maybe<Pick<BannerLink, 'innerPath' | 'externalUrl'>>
  thumbnail: Pick<Image, 'uri' | 'mediumUri'>
}

export type ConsoleCampaignBannerSettingQueryVariables = Exact<{
  [key: string]: never
}>

export type ConsoleCampaignBannerSettingQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & { banners?: Maybe<Array<ArtistBannerDetailFragment>> }
  >
}

export type ArtistUpdateArtistBannersMutationVariables = Exact<{
  input: ArtistUpdateArtistBannersInput
}>

export type ArtistUpdateArtistBannersMutation = {
  artistUpdateArtistBanners: Pick<Artist, 'id'> & {
    banners?: Maybe<Array<ArtistBannerDetailFragment>>
  }
}

export type ConsoleCampaignDetailFragment = Pick<
  Campaign,
  | 'id'
  | 'title'
  | 'description'
  | 'startedAt'
  | 'formattedStartedAt'
  | 'endedAt'
  | 'formattedEndedAt'
> & {
  thumbnail?: Maybe<Pick<Image, 'uri'>>
  entryCondition?: Maybe<
    Pick<EntryCondition, 'description'> & {
      items: {
        common?: Maybe<EntryConditionItemFragment>
        rare?: Maybe<EntryConditionItemFragment>
        superrare?: Maybe<EntryConditionItemFragment>
      }
    }
  >
}

export type EntryConditionItemFragment = Pick<
  EntryConditionItem,
  'count' | 'type'
>

export type EntryCampaignImageTemplatesFragment = {
  items?: Maybe<
    Array<
      Pick<ImageTemplateItem, 'rarity'> & {
        front?: Maybe<ImageTemplateFragment>
        back?: Maybe<ImageTemplateFragment>
      }
    >
  >
  gacha?: Maybe<{ banner: ImageTemplateFragment }>
  collection?: Maybe<{ banner: ImageTemplateFragment }>
}

export type ImageTemplateFragment = Pick<
  ImageTemplate,
  'templateFormatName' | 'templateId'
> & {
  overrideElements: Array<
    Pick<ImageTemplateOverrideElement, 'elementName' | 'type' | 'value'>
  >
}

export type ConsoleCampaignDetailQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>
  artistId: Scalars['String']
}>

export type ConsoleCampaignDetailQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri' | 'mediumUri'>>
    }
  >
  campaign?: Maybe<
    {
      imageTemplates?: Maybe<EntryCampaignImageTemplatesFragment>
    } & ConsoleCampaignDetailFragment
  >
}

export type EntryCampaignMutationVariables = Exact<{
  id: Scalars['ID']
  inputs: CampaignEntryInput
}>

export type EntryCampaignMutation = {
  entryCampaign: ConsoleCampaignDetailFragment
}

export type CreateResourceMutationVariables = Exact<{
  filePath: Scalars['String']
  name: Scalars['String']
}>

export type CreateResourceMutation = {
  createResource: Pick<
    ArtistResource,
    'id' | 'uri' | 'name' | 'type' | 'createdAt'
  >
}

export type ConsoleCampaignsConnectionFragment = {
  edges: Array<{ node: ConsoleCampaignBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type ConsoleCampaignsQueryVariables = Exact<{
  excludeExpired?: Maybe<Scalars['Boolean']>
  campaignsPageInput?: Maybe<PageInput>
}>

export type ConsoleCampaignsQuery = {
  campaigns: ConsoleCampaignsConnectionFragment
  artist?: Maybe<
    Pick<Artist, 'id' | 'paymentType' | 'hasArrangingDeliveryItems'> & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<Pick<MembershipSubscriptionProduct, 'productId'>>
        >
      }>
    }
  >
}

export type CreateCollectionMutationVariables = Exact<{
  inputs?: Maybe<CreateCollectionInput>
}>

export type CreateCollectionMutation = {
  createCollection: CollectionsSelectBoxFragment
}

export type ConsoleCollectionDetailFragment = Pick<
  Collection,
  | 'id'
  | 'title'
  | 'description'
  | 'status'
  | 'startedAt'
  | 'endedAt'
  | 'formattedStartedAt'
  | 'formattedEndedAt'
> & {
  event?: Maybe<EventBoxFragment>
  artist: Pick<Artist, 'id' | 'displayName'>
  nft?: Maybe<
    Pick<CollectionNft, 'salesType'> & {
      chains?: Maybe<Array<Pick<CollectionNftChain, 'royalty' | 'chainName'>>>
    }
  >
  thumbnail: Pick<CollectionThumbnail, 'uri'>
}

export type ConsoleCollectionItemsConnectionFragment = {
  edges: Array<{ node: ConsoleCollectionItemDetailFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type ConsoleCollectionItemDetailFragment = Pick<CollectionItem, 'id'> & {
  item: Pick<
    Item,
    | 'id'
    | 'title'
    | 'price'
    | 'formattedPrice'
    | 'remainingNumber'
    | 'formattedRemainingNumber'
    | 'paymentType'
    | 'type'
    | 'isPremium'
    | 'salesStatus'
    | 'isPublished'
    | 'usedNumber'
    | 'purchasedNumber'
    | 'issuedNumber'
    | 'pendingPurchaseNumber'
    | 'hideSalesNumber'
    | 'isResaleAvailable'
    | 'isDrm'
  > & {
    issuedArtist?: Maybe<Pick<Artist, 'id'>>
    rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
    rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
    thumbnail: {
      front?: Maybe<
        Pick<Resource, 'uri' | 'smallUri'> & {
          drm?: Maybe<Pick<ResourceDrm, 'encryptingStatus'>>
        }
      >
      back?: Maybe<Pick<Resource, 'uri' | 'smallUri'>>
    }
    skuSettings?: Maybe<
      Pick<SkuSettings, 'keyName'> & {
        units: Array<Pick<SkuUnit, 'id' | 'unitName' | 'order'>>
      }
    >
    avatarResources?: Maybe<{
      avatarResource?: Maybe<
        Pick<Resource, 'uri'> & {
          drm?: Maybe<Pick<ResourceDrm, 'encryptingStatus'>>
        }
      >
    }>
    wallpaperResources?: Maybe<{
      wallpaperResource?: Maybe<
        Pick<Resource, 'uri'> & {
          drm?: Maybe<Pick<ResourceDrm, 'encryptingStatus'>>
        }
      >
    }>
  }
}

export type ConsoleCollectionDetailQueryVariables = Exact<{
  id: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type ConsoleCollectionDetailQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & { cryptoWallet?: Maybe<Pick<CryptoWallet, 'address'>> }
  >
  collection?: Maybe<
    {
      collectionItems: ConsoleCollectionItemsConnectionFragment
      userCollection?: Maybe<
        Pick<UserCollection, 'id'> & {
          ownedItems?: Maybe<
            Array<Pick<UserCollectionItem, 'itemId' | 'ownedCount'>>
          >
        }
      >
    } & ConsoleCollectionDetailFragment
  >
}

export type AddItemToCollectionMutationVariables = Exact<{
  collectionId: Scalars['ID']
  itemId: Scalars['ID']
}>

export type AddItemToCollectionMutation = {
  addItemToCollection: ConsoleCollectionItemDetailFragment
}

export type RemoveItemFromCollectionMutationVariables = Exact<{
  collectionId: Scalars['ID']
  itemId: Scalars['ID']
}>

export type RemoveItemFromCollectionMutation = {
  removeItemFromCollection: ConsoleCollectionItemDetailFragment
}

export type RemoveCollectionMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoveCollectionMutation = {
  removeCollection: Pick<Collection, 'id'>
}

export type ConsoleCollectionEditQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ConsoleCollectionEditQuery = {
  collection?: Maybe<CollectionsSelectBoxFragment>
}

export type UpdateCollectionMutationVariables = Exact<{
  input?: Maybe<UpdateCollectionInput>
}>

export type UpdateCollectionMutation = {
  updateCollection: CollectionsSelectBoxFragment
}

export type ArtistCollectionsConnectionFragment = {
  edges: Array<{ node: CollectionsSelectBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type ConsoleCollectionsQueryVariables = Exact<{
  pageInput?: Maybe<PageInputDate>
  orderBy?: Maybe<OrderBy>
}>

export type ConsoleCollectionsQuery = {
  artistCollections: ArtistCollectionsConnectionFragment
}

export type FetchNewConsoleCollectionsQueryVariables = Exact<{
  pageInput?: Maybe<PageInputDate>
  orderBy?: Maybe<OrderBy>
}>

export type FetchNewConsoleCollectionsQuery = {
  artistCollections: ArtistCollectionsConnectionFragment
}

export type ArtistCryptoWalletQueryVariables = Exact<{ [key: string]: never }>

export type ArtistCryptoWalletQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      cryptoWallet?: Maybe<Pick<CryptoWallet, 'address'>>
    }
  >
}

export type ConsoleDataQueryVariables = Exact<{ [key: string]: never }>

export type ConsoleDataQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'paymentType' | 'hasArrangingDeliveryItems'> & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<Pick<MembershipSubscriptionProduct, 'productId'>>
        >
      }>
    }
  >
  dailySalesData: Array<
    Pick<DailySales, 'createdAt'> & {
      store?: Maybe<Pick<TotalSales, 'totalSales' | 'totalSalesWithoutFee'>>
      subscription?: Maybe<
        Pick<TotalSales, 'totalSales' | 'totalSalesWithoutFee'>
      >
    }
  >
  salesReport: {
    last30Days: Pick<
      Last30DaysSalesReport,
      'salesWithoutFee' | 'formattedSalesWithoutFee'
    >
  }
}

export type ConsoleDeliveryItemBoxFragment = Pick<
  DeliveryItem,
  | 'id'
  | 'appliedAt'
  | 'shippedAt'
  | 'formattedShippedAt'
  | 'formattedAppliedAt'
  | 'status'
> & {
  deliveryInfo?: Maybe<
    Pick<DeliveryInformation, 'fullName' | 'phoneNumber'> & {
      address?: Maybe<
        Pick<
          UserAddress,
          | 'postalCode'
          | 'prefecture'
          | 'city'
          | 'line1'
          | 'line2'
          | 'fullAddress'
        >
      >
    }
  >
  user?: Maybe<Pick<User, 'email'>>
  itemSerial?: Maybe<
    Pick<ItemSerial, 'serialNumber' | 'formattedSerialNumber'> & {
      sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
    }
  >
  item: Pick<Item, 'id' | 'title' | 'description' | 'hideSalesNumber'> & {
    thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
    receptionPeriod?: Maybe<
      Pick<ReceptionPeriod, 'endedAt' | 'formattedEndedAt'>
    >
  }
}

export type ConsoleDeliveryItemsConnectionFragment = {
  edges: Array<{ node: ConsoleDeliveryItemBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type ConsoleDeliveryManagementQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
  deliveryItemsStatusFilter?: Maybe<Array<DeliveryStatus> | DeliveryStatus>
}>

export type ConsoleDeliveryManagementQuery = {
  artistDeliveryItems: ConsoleDeliveryItemsConnectionFragment
  artist?: Maybe<
    Pick<Artist, 'id' | 'paymentType' | 'hasArrangingDeliveryItems'> & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<Pick<MembershipSubscriptionProduct, 'productId'>>
        >
      }>
    }
  >
}

export type ArtistMerchItemShipCompleteMutationVariables = Exact<{
  input: ArtistMerchItemShipCompleteInput
}>

export type ArtistMerchItemShipCompleteMutation = {
  artistMerchItemShipComplete: Array<
    Pick<DeliveryItem, 'id' | 'status' | 'serialId'>
  >
}

export type ConsoleEventCollectionReceiveTimeEditQueryVariables = Exact<{
  eventId: Scalars['ID']
  collectionId: Scalars['ID']
}>

export type ConsoleEventCollectionReceiveTimeEditQuery = {
  event: EventBoxFragment
  collection?: Maybe<Pick<Collection, 'id' | 'title'>>
}

export type UpdateEventCollectionPickupTimeMutationVariables = Exact<{
  inputs: UpdateEventCollectionPickupTimeInput
}>

export type UpdateEventCollectionPickupTimeMutation = {
  updateEventCollectionPickupTime: EventCollectionBoxFragment
}

export type ArtistCreateEventMutationVariables = Exact<{
  inputs: ArtistCreateEventInput
}>

export type ArtistCreateEventMutation = { artistCreateEvent: Pick<Event, 'id'> }

export type ConsoleEventDetailFragment = Pick<
  Event,
  | 'id'
  | 'title'
  | 'description'
  | 'startedAt'
  | 'formattedStartedAt'
  | 'endedAt'
  | 'note'
  | 'closeDate'
  | 'dateRange'
  | 'aboutDateRange'
  | 'dateArray'
  | 'formattedEventTime'
  | 'status'
> & {
  location?: Maybe<
    Pick<
      GpsData,
      'approximateAddress' | 'latitude' | 'longitude' | 'radius' | 'mapUri'
    >
  >
  thumbnail?: Maybe<Pick<Image, 'mediumUri' | 'uri'>>
  preSalesAggregation?: Maybe<ConsolePreSalesAggregationDetailFragment>
}

export type ConsoleEventDetailCollectionBoxFragment = Pick<
  EventCollection,
  'id' | 'eventId' | 'collectionId'
> & {
  collection: Pick<
    Collection,
    | 'id'
    | 'artistId'
    | 'isEventPickup'
    | 'status'
    | 'formattedCreatedAt'
    | 'title'
    | 'itemCount'
  > & {
    thumbnail: Pick<CollectionThumbnail, 'uri'>
    collectionItems: {
      edges: Array<{
        node: Pick<CollectionItem, 'id'> & { item: Pick<Item, 'id' | 'type'> }
      }>
    }
  }
  pickupTime?: Maybe<Array<{ options: Pick<EventPickupTimeOption, 'time'> }>>
}

export type ConsolePreSalesAggregationDetailFragment = {
  aggregation?: Maybe<{
    purchases?: Maybe<
      Pick<PreSaleAggregationPurchases, 'issuedNumber' | 'purchasedNumber'>
    >
    ticketUsage?: Maybe<
      Pick<
        PreSaleAggregationTicketUsage,
        'ticketUnusedCount' | 'ticketUsedCount'
      >
    >
  }>
  preSaleItems?: Maybe<
    Array<
      Pick<
        PreSaleAggregationItem,
        'itemId' | 'ticketUnusedCount' | 'ticketUsedCount'
      > & { item: Pick<Item, 'id' | 'title' | 'issuedNumber'> }
    >
  >
}

export type ConsoleEventCollectionSelectConnectionFragment = {
  edges: Array<{ node: ConsoleEventCollectionSelectBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type ConsoleEventCollectionSelectBoxFragment = Pick<
  Collection,
  | 'id'
  | 'status'
  | 'startedAt'
  | 'formattedStartedAt'
  | 'title'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'itemCount'
> & {
  thumbnail: Pick<CollectionThumbnail, 'uri'>
  event?: Maybe<Pick<Event, 'id'>>
}

export type ConsoleEventDetailQueryVariables = Exact<{
  id: Scalars['ID']
  idString: Scalars['String']
  eventPreSalePageInput?: Maybe<PageInput>
}>

export type ConsoleEventDetailQuery = {
  artistPreSales: {
    edges: Array<{ node: EventPreSaleBoxFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
  event: {
    preSales: {
      edges: Array<{ node: EventPreSaleBoxFragment }>
      pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    }
    eventCollections: {
      edges: Array<{ node: ConsoleEventDetailCollectionBoxFragment }>
    }
  } & ConsoleEventDetailFragment
  user?: Maybe<CurrentUserFragment>
}

export type SelectCollectionQueryVariables = Exact<{
  pageInput?: Maybe<PageInputDate>
}>

export type SelectCollectionQuery = {
  artistCollections: ConsoleEventCollectionSelectConnectionFragment
}

export type ArtistAddCollectionsToEventMutationVariables = Exact<{
  inputs: ArtistAddCollecitonToEventInput
}>

export type ArtistAddCollectionsToEventMutation = {
  artistAddCollectionsToEvent: Pick<Event, 'id'> & {
    eventCollections: {
      edges: Array<{ node: ConsoleEventDetailCollectionBoxFragment }>
    }
  }
}

export type ArtistRemoveCollectionFromEventMutationVariables = Exact<{
  inputs: ArtistRemoveCollectionFromEventInput
}>

export type ArtistRemoveCollectionFromEventMutation = {
  artistRemoveCollectionFromEvent: Pick<Event, 'id'>
}

export type ArtistDeleteEventMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ArtistDeleteEventMutation = { artistDeleteEvent: Pick<Event, 'id'> }

export type ConsoleEventEditDetailFragment = Pick<
  Event,
  | 'id'
  | 'title'
  | 'status'
  | 'description'
  | 'note'
  | 'startedAt'
  | 'endedAt'
  | 'closeDate'
> & {
  thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>>
  location?: Maybe<
    Pick<GpsData, 'approximateAddress' | 'latitude' | 'longitude' | 'mapUri'>
  >
}

export type ConsoleEventEditQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ConsoleEventEditQuery = { event: ConsoleEventEditDetailFragment }

export type ArtistUpdateEventMutationVariables = Exact<{
  id: Scalars['ID']
  input: ArtistUpdateEventInput
}>

export type ArtistUpdateEventMutation = {
  artistUpdateEvent: ConsoleEventDetailFragment
}

export type ConsoleEventListFragment = Pick<
  Event,
  | 'id'
  | 'title'
  | 'description'
  | 'startedAt'
  | 'endedAt'
  | 'closeDate'
  | 'status'
  | 'dateRange'
  | 'aboutDateRange'
> & {
  preSales: EventPreSaleConnectionFragment
  thumbnail?: Maybe<Pick<Image, 'mediumUri' | 'uri'>>
  eventCollections: {
    edges: Array<{
      node: Pick<EventCollection, 'id'> & {
        collection: Pick<Collection, 'id' | 'artistId'>
      }
    }>
  }
}

export type ConsoleEventsQueryVariables = Exact<{
  filter: EventFilter
  pageInput?: Maybe<PageInput>
}>

export type ConsoleEventsQuery = {
  artistEvents: {
    edges: Array<{ node: ConsoleEventListFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
  artist?: Maybe<
    Pick<Artist, 'id' | 'paymentType' | 'hasArrangingDeliveryItems'> & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<Pick<MembershipSubscriptionProduct, 'productId'>>
        >
      }>
    }
  >
}

export type ConsoleEventAggregationConnectionFragment = {
  edges: Array<{ node: ConsoleEventTicketInfoBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type ConsoleHomeQueryVariables = Exact<{
  inputs: ArtistThreadChannelsInput
  limit?: Maybe<Scalars['Int']>
}>

export type ConsoleHomeQuery = {
  salesReport: Pick<SalesReport, 'totalSales' | 'formattedTotalSales'>
  artist?: Maybe<
    Pick<
      Artist,
      | 'id'
      | 'paymentType'
      | 'status'
      | 'patronCount'
      | 'formattedPatronCount'
      | 'followerCount'
      | 'totalPurchasedNumber'
      | 'hasArrangingDeliveryItems'
    > & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<
            Pick<MembershipSubscriptionProduct, 'productId' | 'content'> & {
              plans: Array<
                Pick<MembershipSubscriptionPlan, 'amount' | 'status'>
              >
            }
          >
        >
      }>
      posts: {
        edges: Array<{
          node:
            | Pick<AccomplishedMission, 'id'>
            | Pick<Artist, 'id'>
            | Pick<ArtistCustomer, 'id'>
            | Pick<ArtistResource, 'id'>
            | Pick<ArtistThreadChannel, 'id'>
            | Pick<Campaign, 'id'>
            | Pick<Collection, 'id'>
            | Pick<CollectionItem, 'id'>
            | Pick<Comment, 'id'>
            | Pick<Copyright, 'id'>
            | Pick<DeliveryItem, 'id'>
            | Pick<EntryArtist, 'id'>
            | Pick<Event, 'id'>
            | Pick<EventCollection, 'id'>
            | Pick<Feed, 'id'>
            | Pick<Follower, 'id'>
            | Pick<Gacha, 'id'>
            | Pick<GachaTicket, 'id'>
            | Pick<GachaTicketOrder, 'id'>
            | Pick<Item, 'id'>
            | Pick<ItemOrder, 'id'>
            | Pick<ItemResaleRaffleUser, 'id'>
            | Pick<ItemSerial, 'id'>
            | Pick<LiveStream, 'id'>
            | Pick<Mission, 'id'>
            | Pick<Notification, 'id'>
            | Pick<OrganizationNotification, 'id'>
            | Pick<Patron, 'id'>
            | Pick<Payout, 'id'>
            | Pick<PendingPurchaseItem, 'id'>
            | Pick<PickupUser, 'id'>
            | Pick<Post, 'id'>
            | Pick<PostReply, 'id'>
            | Pick<PreSale, 'id'>
            | Pick<PreSaleLotteryApplicant, 'id'>
            | Pick<PrepaidPointTransaction, 'id'>
            | Pick<PurchasablePrepaidPoint, 'id'>
            | Pick<Ranking, 'id'>
            | Pick<RankingUser, 'id'>
            | Pick<RecentlyStoreViewedItem, 'id'>
            | Pick<RecentlyViewedCollection, 'id'>
            | Pick<ResaleItem, 'id'>
            | Pick<ShortLink, 'id'>
            | Pick<StepMission, 'id'>
            | Pick<TradableUser, 'id'>
            | Pick<TradeCandidate, 'id'>
            | Pick<TradeNegotiation, 'id'>
            | Pick<TradeRequest, 'id'>
            | Pick<Transaction, 'id'>
            | Pick<User, 'id'>
            | Pick<UserCart, 'id'>
            | Pick<UserCartTransactionOrder, 'id'>
            | Pick<UserCollection, 'id'>
            | Pick<UserItem, 'id'>
            | Pick<UserStepMission, 'id'>
        }>
      }
    }
  >
  artistItems: {
    edges: Array<{
      node:
        | Pick<AccomplishedMission, 'id'>
        | Pick<Artist, 'id'>
        | Pick<ArtistCustomer, 'id'>
        | Pick<ArtistResource, 'id'>
        | Pick<ArtistThreadChannel, 'id'>
        | Pick<Campaign, 'id'>
        | Pick<Collection, 'id'>
        | Pick<CollectionItem, 'id'>
        | Pick<Comment, 'id'>
        | Pick<Copyright, 'id'>
        | Pick<DeliveryItem, 'id'>
        | Pick<EntryArtist, 'id'>
        | Pick<Event, 'id'>
        | Pick<EventCollection, 'id'>
        | Pick<Feed, 'id'>
        | Pick<Follower, 'id'>
        | Pick<Gacha, 'id'>
        | Pick<GachaTicket, 'id'>
        | Pick<GachaTicketOrder, 'id'>
        | Pick<Item, 'id'>
        | Pick<ItemOrder, 'id'>
        | Pick<ItemResaleRaffleUser, 'id'>
        | Pick<ItemSerial, 'id'>
        | Pick<LiveStream, 'id'>
        | Pick<Mission, 'id'>
        | Pick<Notification, 'id'>
        | Pick<OrganizationNotification, 'id'>
        | Pick<Patron, 'id'>
        | Pick<Payout, 'id'>
        | Pick<PendingPurchaseItem, 'id'>
        | Pick<PickupUser, 'id'>
        | Pick<Post, 'id'>
        | Pick<PostReply, 'id'>
        | Pick<PreSale, 'id'>
        | Pick<PreSaleLotteryApplicant, 'id'>
        | Pick<PrepaidPointTransaction, 'id'>
        | Pick<PurchasablePrepaidPoint, 'id'>
        | Pick<Ranking, 'id'>
        | Pick<RankingUser, 'id'>
        | Pick<RecentlyStoreViewedItem, 'id'>
        | Pick<RecentlyViewedCollection, 'id'>
        | Pick<ResaleItem, 'id'>
        | Pick<ShortLink, 'id'>
        | Pick<StepMission, 'id'>
        | Pick<TradableUser, 'id'>
        | Pick<TradeCandidate, 'id'>
        | Pick<TradeNegotiation, 'id'>
        | Pick<TradeRequest, 'id'>
        | Pick<Transaction, 'id'>
        | Pick<User, 'id'>
        | Pick<UserCart, 'id'>
        | Pick<UserCartTransactionOrder, 'id'>
        | Pick<UserCollection, 'id'>
        | Pick<UserItem, 'id'>
        | Pick<UserStepMission, 'id'>
    }>
  }
  artistEvents: ConsoleEventAggregationConnectionFragment
  artistThreadChannels: {
    edges: Array<{
      node:
        | Pick<AccomplishedMission, 'id'>
        | Pick<Artist, 'id'>
        | Pick<ArtistCustomer, 'id'>
        | Pick<ArtistResource, 'id'>
        | Pick<ArtistThreadChannel, 'id'>
        | Pick<Campaign, 'id'>
        | Pick<Collection, 'id'>
        | Pick<CollectionItem, 'id'>
        | Pick<Comment, 'id'>
        | Pick<Copyright, 'id'>
        | Pick<DeliveryItem, 'id'>
        | Pick<EntryArtist, 'id'>
        | Pick<Event, 'id'>
        | Pick<EventCollection, 'id'>
        | Pick<Feed, 'id'>
        | Pick<Follower, 'id'>
        | Pick<Gacha, 'id'>
        | Pick<GachaTicket, 'id'>
        | Pick<GachaTicketOrder, 'id'>
        | Pick<Item, 'id'>
        | Pick<ItemOrder, 'id'>
        | Pick<ItemResaleRaffleUser, 'id'>
        | Pick<ItemSerial, 'id'>
        | Pick<LiveStream, 'id'>
        | Pick<Mission, 'id'>
        | Pick<Notification, 'id'>
        | Pick<OrganizationNotification, 'id'>
        | Pick<Patron, 'id'>
        | Pick<Payout, 'id'>
        | Pick<PendingPurchaseItem, 'id'>
        | Pick<PickupUser, 'id'>
        | Pick<Post, 'id'>
        | Pick<PostReply, 'id'>
        | Pick<PreSale, 'id'>
        | Pick<PreSaleLotteryApplicant, 'id'>
        | Pick<PrepaidPointTransaction, 'id'>
        | Pick<PurchasablePrepaidPoint, 'id'>
        | Pick<Ranking, 'id'>
        | Pick<RankingUser, 'id'>
        | Pick<RecentlyStoreViewedItem, 'id'>
        | Pick<RecentlyViewedCollection, 'id'>
        | Pick<ResaleItem, 'id'>
        | Pick<ShortLink, 'id'>
        | Pick<StepMission, 'id'>
        | Pick<TradableUser, 'id'>
        | Pick<TradeCandidate, 'id'>
        | Pick<TradeNegotiation, 'id'>
        | Pick<TradeRequest, 'id'>
        | Pick<Transaction, 'id'>
        | Pick<User, 'id'>
        | Pick<UserCart, 'id'>
        | Pick<UserCartTransactionOrder, 'id'>
        | Pick<UserCollection, 'id'>
        | Pick<UserItem, 'id'>
        | Pick<UserStepMission, 'id'>
    }>
  }
}

export type CreateAcceptedItemMutationVariables = Exact<{
  inputs?: Maybe<CreateItemInput>
}>

export type CreateAcceptedItemMutation = {
  createAcceptedItem: ItemSelectBoxFragment
}

export type ConsoleItemDetailFragment = Pick<
  Item,
  'salesStatus' | 'isPublished'
> &
  ItemDescriptionFragment

export type ConsoleItemDetailQueryVariables = Exact<{
  id: Scalars['ID']
  pageInput?: Maybe<PageInput>
  filter?: Maybe<SerialFilter>
  skuId?: Maybe<Scalars['String']>
}>

export type ConsoleItemDetailQuery = {
  item?: Maybe<
    Pick<
      Item,
      | 'salesStatus'
      | 'isPublished'
      | 'isPremium'
      | 'status'
      | 'formattedPrice'
      | 'usedNumber'
      | 'pendingPurchaseNumber'
    > & {
      skuSettings?: Maybe<
        Pick<SkuSettings, 'keyName'> & { units: Array<Pick<SkuUnit, 'id'>> }
      >
      serials: SerialItemOwnersFragment
      collection?: Maybe<Pick<Collection, 'id' | 'isEventPickup'>>
    } & ItemDescriptionFragment
  >
}

export type ArtistUseTicketsMutationVariables = Exact<{
  inputs: UseTicketsInput
}>

export type ArtistUseTicketsMutation = {
  artistUseTickets: Array<
    Pick<ItemSerial, 'id' | 'obtainedDate'> & {
      ticketStatus?: Maybe<TicketStatusDetailFragment>
    }
  >
}

export type ArtistUnuseTicketsMutationVariables = Exact<{
  inputs: UnuseTicketsInput
}>

export type ArtistUnuseTicketsMutation = {
  artistUnuseTickets: Array<
    Pick<ItemSerial, 'id' | 'obtainedDate'> & {
      ticketStatus?: Maybe<TicketStatusDetailFragment>
    }
  >
}

export type ConsoleItemEditQueryVariables = Exact<{
  itemId: Scalars['ID']
}>

export type ConsoleItemEditQuery = {
  item?: Maybe<
    {
      preSale?: Maybe<Pick<PreSale, 'id'>>
      metadata?: Maybe<{
        magazine?: Maybe<Pick<ItemMetadataDetailMagazine, 'pageDirection'>>
        ticket?: Maybe<
          Pick<
            ItemMetadataDetailTicket,
            | 'expirationTerm'
            | 'note'
            | 'description'
            | 'assignedNumberPrefix'
            | 'startAssignedNumber'
            | 'isThroughTicket'
            | 'distributionEnabled'
            | 'distributionEndedAt'
          > & {
            throughTicket?: Maybe<
              Array<Pick<TicketItemMetadataThroughTicket, 'name'>>
            >
          }
        >
        merch?: Maybe<{
          receptionPeriod?: Maybe<Pick<ReceptionPeriod, 'endedAt'>>
        }>
        wallpaper?: Maybe<
          Pick<
            ItemWallpaperMetadata,
            'isPrintEnabled' | 'printCount' | 'printType'
          >
        >
      }>
    } & ItemSelectBoxFragment
  >
}

export type ArtistUpdateItemMutationVariables = Exact<{
  artistUpdateItemId: Scalars['ID']
  inputs?: Maybe<ArtistUpdateItemInput>
}>

export type ArtistUpdateItemMutation = {
  artistUpdateItem: ItemSelectBoxFragment
}

export type ConsoleSerialItemOwnersFragment = {
  edges: Array<{
    node: Pick<
      ItemSerial,
      | 'id'
      | 'itemId'
      | 'serialNumber'
      | 'formattedSerialNumber'
      | 'createdAt'
      | 'formattedCreatedAt'
      | 'updatedAt'
      | 'obtainedDate'
      | 'formattedObtainedDate'
      | 'formattedObtainedDateTime'
      | 'formattedAssignedNumber'
      | 'isNft'
    > & {
      sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
      ticketStatus?: Maybe<
        Pick<TicketStatus, 'isUsed' | 'usedAt' | 'formattedUsedAt'>
      >
      user?: Maybe<
        Pick<User, 'id' | 'displayName' | 'phoneNumber'> & {
          avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
        }
      >
      authentication?: Maybe<Pick<SerialAuthentication, 'authenticatedAt'>>
    }
  }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type ConsoleItemInformationQueryVariables = Exact<{
  id: Scalars['ID']
  userId?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInput>
  skuId?: Maybe<Scalars['String']>
}>

export type ConsoleItemInformationQuery = {
  item?: Maybe<
    { serials: ConsoleSerialItemOwnersFragment } & ItemDescriptionFragment
  >
  user?: Maybe<UserInfoFragment>
}

export type PurchasableUserDetailFragment = Pick<
  PurchasableUser,
  'userId' | 'purchasableCount' | 'purchasedCount'
> & {
  user: Pick<User, 'id' | 'displayName'> & {
    avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
  }
}

export type ConsoleItemInformationLimitUserQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ConsoleItemInformationLimitUserQuery = {
  item?: Maybe<
    Pick<Item, 'id'> & {
      purchasableUsers?: Maybe<Array<PurchasableUserDetailFragment>>
    }
  >
}

export type ArtistItemOrdersConnectionFragment = {
  edges: Array<{ node: OrderedItemBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type ConsoleItemOrdersQueryVariables = Exact<{
  pageInput?: Maybe<PageInputDate>
}>

export type ConsoleItemOrdersQuery = {
  organizationArtistItemOrders: ArtistItemOrdersConnectionFragment
}

export type ConsoleItemsConnectionFragment = {
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  edges: Array<{ node: ItemSelectBoxFragment }>
}

export type ConsoleItemsQueryVariables = Exact<{
  filters?: Maybe<ArtistItemsFilterInput>
  pageInput?: Maybe<PageInput>
}>

export type ConsoleItemsQuery = {
  artistItems: ConsoleItemsConnectionFragment
  artist?: Maybe<
    Pick<Artist, 'id' | 'paymentType' | 'hasArrangingDeliveryItems'> & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<Pick<MembershipSubscriptionProduct, 'productId'>>
        >
      }>
    }
  >
}

export type PublishItemMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type PublishItemMutation = {
  publishItem: Pick<Item, 'id' | 'isPublished'>
}

export type UnpublishItemMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type UnpublishItemMutation = {
  unpublishItem: Pick<Item, 'id' | 'isPublished'>
}

export type StartSellingItemMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type StartSellingItemMutation = {
  startSellingItem: Pick<Item, 'id' | 'salesStatus'>
}

export type StopSellingItemMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type StopSellingItemMutation = {
  stopSellingItem: Pick<Item, 'id' | 'salesStatus'>
}

export type ArtistRemoveItemMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ArtistRemoveItemMutation = { artistRemoveItem: Pick<Item, 'id'> }

export type ConsoleLiveDetailQueryVariables = Exact<{
  id: Scalars['String']
}>

export type ConsoleLiveDetailQuery = {
  liveStream: Pick<
    LiveStream,
    | 'id'
    | 'status'
    | 'token'
    | 'channelName'
    | 'startedAt'
    | 'endedAt'
    | 'likeCount'
    | 'isPremium'
  >
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'mediumUri'>>
    }
  >
}

export type EndLiveStreamMutationVariables = Exact<{
  id: Scalars['String']
}>

export type EndLiveStreamMutation = {
  endLiveStream: Pick<
    LiveStream,
    'id' | 'likeCount' | 'startedAt' | 'endedAt' | 'status'
  >
}

export type ConsoleLiveFinishQueryVariables = Exact<{
  id: Scalars['String']
}>

export type ConsoleLiveFinishQuery = {
  liveStream: Pick<
    LiveStream,
    | 'id'
    | 'status'
    | 'token'
    | 'channelName'
    | 'startedAt'
    | 'endedAt'
    | 'likeCount'
    | 'commentCount'
    | 'subscriberCount'
    | 'tipTotal'
    | 'liveStreamDuration'
    | 'formattedLikeCount'
    | 'formattedCommentCount'
    | 'formattedSubscriberCount'
    | 'formattedTipTotal'
  >
}

export type ConsoleLiveSettingQueryVariables = Exact<{ [key: string]: never }>

export type ConsoleLiveSettingQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'mediumUri'>>
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    }
  >
}

export type CreateLiveStreamMutationVariables = Exact<{
  inputs: CreateLiveStreamInput
}>

export type CreateLiveStreamMutation = {
  createLiveStream: Pick<LiveStream, 'id' | 'channelName' | 'token' | 'status'>
}

export type StartLiveStreamMutationVariables = Exact<{
  id: Scalars['String']
  input?: Maybe<StartLiveStreamInput>
}>

export type StartLiveStreamMutation = {
  startLiveStream: Pick<LiveStream, 'id'>
}

export type ExportPreSaleApplicantsFragment = Pick<
  PreSaleApplicantCsv,
  'csvUrl'
>

export type ConsolePreSaleTitleFragment = Pick<PreSale, 'id' | 'title'> & {
  items: Array<Pick<Item, 'id' | 'title'>>
  lottery?: Maybe<Pick<PreSaleLottery, 'resultNoticedAt'>>
}

export type ConsoleLotteryCsvQueryVariables = Exact<{
  preSaleId: Scalars['ID']
  preSaleIdString: Scalars['String']
}>

export type ConsoleLotteryCsvQuery = {
  artistPreSale: ConsolePreSaleTitleFragment
  exportPreSaleApplicants: ExportPreSaleApplicantsFragment
}

export type UpdatePreSaleLotteryResultFromCsvMutationVariables = Exact<{
  inputs: UpdatePreSaleLotteryResultFromCsvInput
}>

export type UpdatePreSaleLotteryResultFromCsvMutation = {
  updatePreSaleLotteryResultFromCsv: Pick<PreSale, 'id'>
}

export type ArtistPreSaleLotteryApplicantDetailFragment = Pick<
  PreSaleLotteryApplicant,
  | 'id'
  | 'status'
  | 'winChoiceNumber'
  | 'paymentMethod'
  | 'purchaseCountChoices'
  | 'appliedAt'
  | 'formattedAppliedAt'
> & {
  preSale: Pick<PreSale, 'id' | 'title'>
  user: Pick<
    User,
    | 'id'
    | 'fullName'
    | 'isPhoneNumberVerified'
    | 'phoneNumber'
    | 'formattedPhoneNumber'
    | 'email'
  > & {
    address?: Maybe<
      Pick<
        UserAddress,
        'postalCode' | 'prefecture' | 'city' | 'line1' | 'line2' | 'fullAddress'
      >
    >
    credit?: Maybe<PrepaidPointsPaymentSourceFragment>
  }
  cartTransactionOrder?: Maybe<
    Pick<UserCartTransactionOrder, 'id' | 'isKonbini'>
  >
  choiceItemDetails: Array<
    Pick<ChoiceItemDetail, 'count' | 'itemId' | 'skuId'> & {
      item?: Maybe<Pick<Item, 'id' | 'title' | 'price' | 'formattedPrice'>>
      fees: {
        collections?: Maybe<
          Array<Maybe<Pick<Fee, 'id' | 'feeName' | 'feeType' | 'fee'>>>
        >
        konbini?: Maybe<Pick<Fee, 'id' | 'feeName' | 'feeType' | 'fee'>>
        shipping?: Maybe<Pick<Fee, 'id' | 'feeName' | 'feeType' | 'fee'>>
      }
      skuUnit?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
    }
  >
}

export type ConsoleLotteryManagementDetailQueryVariables = Exact<{
  preSaleId: Scalars['String']
  userId: Scalars['ID']
}>

export type ConsoleLotteryManagementDetailQuery = {
  artistPreSaleLotteryApplicant: ArtistPreSaleLotteryApplicantDetailFragment
}

export type ArtistPreSaleLotteryApplicantsConnectionFragment = {
  edges: Array<{ node: ArtistPreSaleLotteryApplicantDetailFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type PreSaleItemFilterFragment = Pick<Item, 'id' | 'title'> & {
  skuSettings?: Maybe<{ units: Array<Pick<SkuUnit, 'id' | 'unitName'>> }>
}

export type ConsoleLotteryManagementsQueryVariables = Exact<{
  filters?: Maybe<PreSaleLotteryApplicantFilterInputs>
  pageInput?: Maybe<PageInput>
  artistPreSaleId: Scalars['String']
  preSaleId: Scalars['ID']
}>

export type ConsoleLotteryManagementsQuery = {
  artistPreSaleLotteryApplicants: ArtistPreSaleLotteryApplicantsConnectionFragment
  artistPreSale: Pick<
    PreSale,
    | 'id'
    | 'title'
    | 'description'
    | 'type'
    | 'preSaleStatus'
    | 'eventId'
    | 'itemIds'
    | 'isPublished'
    | 'endedAt'
    | 'startedAt'
    | 'note'
    | 'paymentMethods'
  > & {
    lottery?: Maybe<
      Pick<
        PreSaleLottery,
        | 'applicationStartedAt'
        | 'applicationEndedAt'
        | 'resultNoticedAt'
        | 'resultNoticeStatus'
        | 'maxChoice'
        | 'formattedResultNoticedAt'
      >
    >
    items: Array<PreSaleItemFilterFragment>
  }
}

export type ArtistUpdatePreSaleLotteryApplicantStatusMutationVariables = Exact<{
  inputs: ArtistUpdatePreSaleLotteryApplicantStatusInput
}>

export type ArtistUpdatePreSaleLotteryApplicantStatusMutation = {
  artistUpdatePreSaleLotteryApplicantStatus: Array<ArtistPreSaleLotteryApplicantDetailFragment>
}

export type ArtistUpdatePreSaleLotteryResultNoticedAtMutationVariables = Exact<{
  id: Scalars['ID']
  inputs: ArtistUpdatePreSaleInput
}>

export type ArtistUpdatePreSaleLotteryResultNoticedAtMutation = {
  artistUpdatePreSale: Pick<PreSale, 'id' | 'isPublished'> & {
    lottery?: Maybe<Pick<PreSaleLottery, 'resultNoticedAt'>>
  }
}

export type ConsoleMembershipConnectionFragment = {
  edges: Array<{ node: MemberBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type ConsolePlanDetailFragment = Pick<
  MembershipSubscriptionPlan,
  | 'title'
  | 'content'
  | 'planId'
  | 'amount'
  | 'formattedAmount'
  | 'status'
  | 'patronCount'
> & { patrons?: Maybe<{ edges: Array<{ node: ConsolePatronDetailFragment }> }> }

export type ConsoleProductDetailFragment = Pick<
  MembershipSubscriptionProduct,
  'title' | 'productId' | 'annualContent' | 'content'
> & {
  plans: Array<
    Pick<
      MembershipSubscriptionPlan,
      | 'planId'
      | 'amount'
      | 'formattedAmount'
      | 'status'
      | 'interval'
      | 'patronCount'
    > & {
      patrons?: Maybe<{ edges: Array<{ node: ConsolePatronDetailFragment }> }>
    }
  >
}

export type ConsolePatronDetailFragment = Pick<Patron, 'id'> & {
  user: Pick<User, 'id'> & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> }
}

export type ConsoleMembershipQueryVariables = Exact<{
  artistId?: Maybe<Scalars['ID']>
}>

export type ConsoleMembershipQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'paymentType' | 'hasArrangingDeliveryItems'> & {
      subscription?: Maybe<
        Pick<MembershipSubscription, 'requirePersonalInformation'> & {
          products?: Maybe<
            Array<
              Pick<MembershipSubscriptionProduct, 'productId'> &
                ConsoleProductDetailFragment
            >
          >
          plans?: Maybe<Array<ConsolePlanDetailFragment>>
        }
      >
    }
  >
}

export type ArtistExportPatronsCsvQueryVariables = Exact<{
  productIds: Array<Scalars['String']> | Scalars['String']
}>

export type ArtistExportPatronsCsvQuery = {
  artistExportPatronsCsv: Pick<CsvDownload, 'csvUrl'>
}

export type UpdateSubscriptionRequiredPersonalInformationMutationVariables =
  Exact<{
    isRequired: Scalars['Boolean']
  }>

export type UpdateSubscriptionRequiredPersonalInformationMutation = {
  updateSubscriptionRequirePersonalInformation: Pick<
    Artist,
    'id' | 'paymentType' | 'hasArrangingDeliveryItems'
  > & {
    subscription?: Maybe<
      Pick<MembershipSubscription, 'requirePersonalInformation'> & {
        plans?: Maybe<Array<ConsolePlanDetailFragment>>
        products?: Maybe<Array<ConsoleProductDetailFragment>>
      }
    >
  }
}

export type ArtistRegisterSubscriptionMutationVariables = Exact<{
  inputs: RegisterSubscriptionsInput
}>

export type ArtistRegisterSubscriptionMutation = {
  artistRegisterSubscriptions: Pick<Artist, 'id'> & {
    subscription?: Maybe<{
      plans?: Maybe<Array<ConsolePlanDetailFragment>>
      products?: Maybe<Array<ConsoleProductDetailFragment>>
    }>
  }
}

export type ConsoleMembershipDetailQueryVariables = Exact<{
  filters?: Maybe<PatronsFilters>
  pageInput?: Maybe<PageInputDate>
}>

export type ConsoleMembershipDetailQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      subscription?: Maybe<
        Pick<MembershipSubscription, 'productId'> & {
          plans?: Maybe<Array<ConsolePlanDetailFragment>>
          products?: Maybe<Array<ConsoleProductDetailFragment>>
        }
      >
      patrons: {
        edges: Array<{ node: MemberBoxFragment }>
        pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage' | 'hits'>
      }
    }
  >
}

export type ConsoleMembershipEditQueryVariables = Exact<{
  [key: string]: never
}>

export type ConsoleMembershipEditQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      subscription?: Maybe<
        Pick<MembershipSubscription, 'productId'> & {
          plans?: Maybe<Array<ConsolePlanDetailFragment>>
          products?: Maybe<Array<ConsoleProductDetailFragment>>
        }
      >
    }
  >
}

export type UpdateSubscriptionMutationVariables = Exact<{
  artistId: Scalars['ID']
  inputs: UpdateSubscriptionInput
}>

export type UpdateSubscriptionMutation = {
  updateSubscription: Pick<Artist, 'id'> & {
    subscription?: Maybe<
      Pick<MembershipSubscription, 'productId'> & {
        plans?: Maybe<Array<ConsolePlanDetailFragment>>
        products?: Maybe<Array<ConsoleProductDetailFragment>>
      }
    >
  }
}

export type ConsoleMembershipExplainQueryVariables = Exact<{
  [key: string]: never
}>

export type ConsoleMembershipExplainQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      subscription?: Maybe<
        Pick<
          MembershipSubscription,
          'amount' | 'content' | 'status' | 'productId'
        > & {
          playstore?: Maybe<Pick<IapProductInfo, 'productId'>>
          appstore?: Maybe<Pick<IapProductInfo, 'productId'>>
        }
      >
    }
  >
}

export type CreateMissionMutationVariables = Exact<{
  inputs: CreateMissionInput
}>

export type CreateMissionMutation = { createMission: ConsoleMissionBoxFragment }

export type ConsoleMissionEditQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ConsoleMissionEditQuery = {
  mission?: Maybe<ConsoleMissionBoxFragment>
}

export type UpdateMissionMutationVariables = Exact<{
  id: Scalars['ID']
  inputs: UpdateMissionInput
}>

export type UpdateMissionMutation = { updateMission: ConsoleMissionBoxFragment }

export type ArtistMissionsConnectionFragment = {
  edges: Array<{ node: ConsoleMissionBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type ConsoleMissionsQueryVariables = Exact<{
  orderBy?: Maybe<OrderBy>
  pageInput?: Maybe<PageInput>
  typeFilter?: Maybe<Array<MissionType> | MissionType>
}>

export type ConsoleMissionsQuery = {
  artistMissions: ArtistMissionsConnectionFragment
  artist?: Maybe<
    Pick<Artist, 'id' | 'paymentType' | 'hasArrangingDeliveryItems'> & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<Pick<MembershipSubscriptionProduct, 'productId'>>
        >
      }>
    }
  >
}

export type DeleteMissionMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteMissionMutation = { deleteMission: Pick<Mission, 'id'> }

export type ConsoleMyProfileConnectionFragment = Pick<
  Artist,
  | 'id'
  | 'accountId'
  | 'formattedAccountId'
  | 'displayName'
  | 'introduction'
  | 'profileText'
> & {
  avatar?: Maybe<
    Pick<Avatar, 'uri' | 'smallUri' | 'mediumUri' | 'compressedUri'>
  >
  sns?: Maybe<
    Pick<ArtistSns, 'twitter' | 'tiktok' | 'instagram' | 'facebook' | 'youtube'>
  >
  textLink?: Maybe<Array<Maybe<Pick<ArtistTextLink, 'content' | 'uri'>>>>
}

export type ConsoleMyProfileEditQueryVariables = Exact<{
  artistId?: Maybe<Scalars['ID']>
}>

export type ConsoleMyProfileEditQuery = {
  artist?: Maybe<ConsoleMyProfileConnectionFragment>
}

export type ArtistUpdateArtistProfileMutationVariables = Exact<{
  inputs: ArtistUpdateArtistProfileInput
}>

export type ArtistUpdateArtistProfileMutation = {
  artistUpdateArtistProfile: ConsoleMyProfileConnectionFragment
}

export type UpdateArtistSnsMutationVariables = Exact<{
  inputs: UpdateArtistSnsInput
}>

export type UpdateArtistSnsMutation = {
  updateArtistSns: ConsoleMyProfileConnectionFragment
}

export type UpdateArtistProfileTextMutationVariables = Exact<{
  profileText?: Maybe<Scalars['String']>
}>

export type UpdateArtistProfileTextMutation = {
  updateArtistProfileText: ConsoleMyProfileConnectionFragment
}

export type UpdateArtistTextLinkMutationVariables = Exact<{
  inputs:
    | Array<Maybe<UpdateArtistTextLinkInput>>
    | Maybe<UpdateArtistTextLinkInput>
}>

export type UpdateArtistTextLinkMutation = {
  updateArtistTextLink: ConsoleMyProfileConnectionFragment
}

export type ArtistCreateOrganizationNotificationMutationVariables = Exact<{
  inputs: ArtistCreateOrganizationNotificationInput
}>

export type ArtistCreateOrganizationNotificationMutation = {
  artistCreateOrganizationNotification: OfficialInformationConsoleBoxFragment
}

export type ConsoleOfficialInformationEditQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ConsoleOfficialInformationEditQuery = {
  artistOrganizationNotification?: Maybe<OfficialInformationConsoleBoxFragment>
}

export type ArtistUpdateOrganizationNotificationMutationVariables = Exact<{
  id: Scalars['ID']
  inputs: ArtistUpdateOrganizationNotificationInput
}>

export type ArtistUpdateOrganizationNotificationMutation = {
  artistUpdateOrganizationNotification: OfficialInformationConsoleBoxFragment
}

export type ConsoleOfficialInformationConnectionFragment = {
  edges: Array<{ node: OfficialInformationConsoleBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type ConsoleOfficialInformationsQueryVariables = Exact<{
  status?: Maybe<
    Array<OrganizationNotificationStatus> | OrganizationNotificationStatus
  >
  pageInput?: Maybe<PageInput>
}>

export type ConsoleOfficialInformationsQuery = {
  artistOrganizationOfficialNotifications: ConsoleOfficialInformationConnectionFragment
}

export type FetchNewConsoleOfficialInformationsQueryVariables = Exact<{
  status?: Maybe<
    Array<OrganizationNotificationStatus> | OrganizationNotificationStatus
  >
}>

export type FetchNewConsoleOfficialInformationsQuery = {
  artistOrganizationOfficialNotifications: ConsoleOfficialInformationConnectionFragment
}

export type ArtistArchiveOrganizationNotificationMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ArtistArchiveOrganizationNotificationMutation = {
  artistArchiveOrganizationNotification: OfficialInformationConsoleBoxFragment
}

export type ConsoleOrderDetailQueryVariables = Exact<{
  id: Scalars['ID']
  userId: Scalars['String']
  skuId?: Maybe<Scalars['String']>
}>

export type ConsoleOrderDetailQuery = {
  artistUserCartTransactionOrder: {
    itemOrders: Array<{
      user?: Maybe<
        Pick<
          User,
          | 'id'
          | 'fullName'
          | 'phoneNumber'
          | 'displayName'
          | 'formattedPhoneNumber'
          | 'isPhoneNumberVerified'
          | 'status'
        > & { credit?: Maybe<PaymentDetailFragment> } & UserAddressFragment
      >
    }>
  } & UserCartTransactionOrderDetailFragment
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    | 'lastUpdatedAt'
    | 'prepaidPointId'
    | 'balance'
    | 'formattedPrepaidPointBarance'
  >
}

export type UserCartTransactionOrderDetailFragment = Pick<
  UserCartTransactionOrder,
  | 'id'
  | 'totalAmount'
  | 'formattedTotalAmount'
  | 'paymentType'
  | 'createdAt'
  | 'isKonbini'
  | 'totalAmountWithoutFee'
  | 'formattedTotalAmountWithoutFee'
  | 'storeFee'
  | 'formattedStoreFee'
  | 'formattedSaleProfit'
  | 'originalTotalAmount'
  | 'formattedOriginalTotalAmount'
  | 'formattedCreatedAt'
> & {
  pickupUsers?: Maybe<
    Array<
      Pick<
        PickupUser,
        'id' | 'pickupTime' | 'collectionId' | 'formattedPickupStartEndTime'
      > & { eventCollection: Pick<EventCollection, 'id' | 'validMinutes'> }
    >
  >
  itemOrders: Array<
    Pick<ItemOrder, 'id'> & {
      user?: Maybe<
        Pick<
          User,
          | 'id'
          | 'fullName'
          | 'phoneNumber'
          | 'displayName'
          | 'formattedPhoneNumber'
          | 'isPhoneNumberVerified'
        > & { credit?: Maybe<PaymentDetailFragment> } & UserAddressFragment
      >
      item?: Maybe<Pick<Item, 'id'>>
    }
  >
  checkoutFees?: Maybe<
    Pick<CheckoutFees, 'totalFee'> & {
      shipping?: Maybe<FeeDetailFragment>
      collections?: Maybe<Array<Maybe<CollectionFeeDetailFragment>>>
      konbini?: Maybe<FeeDetailFragment>
    }
  >
  cancels?: Maybe<Array<Pick<CartCancel, 'canceledAt'>>>
  items: Array<
    Pick<UserCartTransactionItem, 'itemId' | 'count' | 'amount'> & {
      item: {
        collection?: Maybe<Pick<Collection, 'id' | 'title'>>
      } & StoreItemDetailFragment
      skus: Array<
        Maybe<
          Pick<UserCartItemSku, 'count' | 'skuId'> & {
            sku: Pick<SkuUnit, 'unitName'> & {
              thumbnail?: Maybe<Pick<Image, 'uri'>>
            }
          }
        >
      >
    }
  >
}

export type UserCartTransactionOrderConnectionFragment = {
  edges: Array<{ node: UserCartTransactionOrderBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type UserCartTransactionOrderBoxFragment = Pick<
  UserCartTransactionOrder,
  | 'id'
  | 'totalAmount'
  | 'formattedTotalAmount'
  | 'paymentType'
  | 'createdAt'
  | 'isKonbini'
  | 'totalAmountWithoutFee'
  | 'storeFee'
  | 'formattedSaleProfit'
  | 'formattedCreatedAt'
> & {
  pickupUsers?: Maybe<
    Array<
      Pick<
        PickupUser,
        'id' | 'pickupTime' | 'collectionId' | 'formattedPickupStartEndTime'
      > & { eventCollection: Pick<EventCollection, 'id' | 'validMinutes'> }
    >
  >
  cancels?: Maybe<Array<Pick<CartCancel, 'canceledAt'>>>
  itemOrders: Array<
    Pick<ItemOrder, 'id' | 'userId' | 'itemId'> & {
      user?: Maybe<Pick<User, 'id' | 'fullName'>>
    }
  >
  checkoutFees?: Maybe<
    Pick<CheckoutFees, 'totalFee'> & {
      shipping?: Maybe<FeeDetailFragment>
      collections?: Maybe<Array<Maybe<CollectionFeeDetailFragment>>>
      konbini?: Maybe<FeeDetailFragment>
    }
  >
  items: Array<
    Pick<UserCartTransactionItem, 'itemId' | 'count' | 'amount'> & {
      item: Pick<Item, 'id' | 'title'>
      skus: Array<
        Maybe<
          Pick<UserCartItemSku, 'count' | 'skuId'> & {
            sku: Pick<SkuUnit, 'unitName'> & {
              thumbnail?: Maybe<Pick<Image, 'uri'>>
            }
          }
        >
      >
    }
  >
}

export type ConsoleOrdersQueryVariables = Exact<{
  orderBy?: Maybe<OrderBy>
  pageInput?: Maybe<PageInput>
  userId?: Maybe<Scalars['String']>
}>

export type ConsoleOrdersQuery = {
  artistUserCartTransactionOrders: UserCartTransactionOrderConnectionFragment
  artist?: Maybe<
    Pick<Artist, 'id' | 'paymentType' | 'hasArrangingDeliveryItems'> & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<Pick<MembershipSubscriptionProduct, 'productId'>>
        >
      }>
    }
  >
}

export type FetchNewConsoleOrdersQueryVariables = Exact<{
  orderBy?: Maybe<OrderBy>
  pageInput?: Maybe<PageInput>
  userId?: Maybe<Scalars['String']>
}>

export type FetchNewConsoleOrdersQuery = {
  artistUserCartTransactionOrders: UserCartTransactionOrderConnectionFragment
}

export type ArtistCategoryDetailQueryVariables = Exact<{ [key: string]: never }>

export type ArtistCategoryDetailQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      postCategory?: Maybe<{
        categories: Array<
          Pick<
            PostCategoryDetail,
            'id' | 'name' | 'description' | 'createdAt' | 'updatedAt'
          >
        >
      }>
    }
  >
}

export type CreatePostCategoryMutationVariables = Exact<{
  inputs: ArtistCreatePostCategoryInput
}>

export type CreatePostCategoryMutation = {
  artistCreatePostCategory: {
    categories: Array<Pick<PostCategoryDetail, 'id'>>
  }
}

export type UpdatePostCategoryMutationVariables = Exact<{
  inputs: ArtistUpdatePostCategoryInput
}>

export type UpdatePostCategoryMutation = {
  artistUpdatePostCategory?: Maybe<{
    categories: Array<Pick<PostCategoryDetail, 'id'>>
  }>
}

export type DeletePostCategoryMutationVariables = Exact<{
  inputs: ArtistRemovePostCategoryInput
}>

export type DeletePostCategoryMutation = {
  artistRemovePostCategory: {
    categories: Array<Pick<PostCategoryDetail, 'id'>>
  }
}

export type ArtistSubscriptionPlanQueryVariables = Exact<{
  [key: string]: never
}>

export type ArtistSubscriptionPlanQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      subscription?: Maybe<{
        plans?: Maybe<
          Array<
            Pick<MembershipSubscriptionPlan, 'planId' | 'title' | 'interval'>
          >
        >
        products?: Maybe<
          Array<
            Pick<MembershipSubscriptionProduct, 'productId' | 'title'> & {
              plans: Array<
                Pick<MembershipSubscriptionPlan, 'interval' | 'planId'>
              >
            }
          >
        >
      }>
    }
  >
}

export type ArtistCategoryQueryVariables = Exact<{ [key: string]: never }>

export type ArtistCategoryQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      postCategory?: Maybe<{
        categories: Array<Pick<PostCategoryDetail, 'id' | 'name'>>
      }>
    }
  >
}

export type CreatePostMutationVariables = Exact<{
  inputs: CreatePostInput
}>

export type CreatePostMutation = { createPost: PostBoxFragment }

export type ConsolePostDetailQueryVariables = Exact<{
  postId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type ConsolePostDetailQuery = {
  post?: Maybe<{ replies: RepliesConnectionFragment } & PostBoxFragment>
  user?: Maybe<
    Pick<User, 'id' | 'accountId' | 'formattedAccountId' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri'>>
    }
  >
}

export type DeletePostMutationVariables = Exact<{
  postId: Scalars['ID']
}>

export type DeletePostMutation = { deletePost: PostBoxFragment }

export type ConsolePostEditQueryVariables = Exact<{
  postId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type ConsolePostEditQuery = {
  post?: Maybe<{ replies: RepliesConnectionFragment } & PostBoxFragment>
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      subscription?: Maybe<{
        plans?: Maybe<
          Array<
            Pick<MembershipSubscriptionPlan, 'planId' | 'title' | 'interval'>
          >
        >
        products?: Maybe<
          Array<
            Pick<MembershipSubscriptionProduct, 'productId' | 'title'> & {
              plans: Array<
                Pick<MembershipSubscriptionPlan, 'interval' | 'planId'>
              >
            }
          >
        >
      }>
    }
  >
  user?: Maybe<
    Pick<User, 'id' | 'accountId' | 'formattedAccountId' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
}

export type UpdatePostMutationVariables = Exact<{
  postId: Scalars['ID']
  inputs: UpdatePostInput
}>

export type UpdatePostMutation = { updatePost: PostBoxFragment }

export type ConsolePostQueryVariables = Exact<{
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
  resourceType?: Maybe<PostType>
  status?: Maybe<Array<Maybe<PostStatus>> | Maybe<PostStatus>>
}>

export type ConsolePostQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'paymentType' | 'hasArrangingDeliveryItems'> & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<Pick<MembershipSubscriptionProduct, 'productId'>>
        >
      }>
      posts: ArtistPostsFragment
    }
  >
  user?: Maybe<Pick<User, 'id' | 'accountId' | 'formattedAccountId'>>
}

export type CreatePreSaleMutationVariables = Exact<{
  inputs: ArtistCreatePreSaleInput
}>

export type CreatePreSaleMutation = { artistCreatePreSale: Pick<PreSale, 'id'> }

export type ConsolePreSaleDetailFragment = Pick<
  PreSale,
  | 'id'
  | 'title'
  | 'description'
  | 'type'
  | 'isPublished'
  | 'endedAt'
  | 'formattedEndedAt'
  | 'startedAt'
  | 'formattedStartedAt'
  | 'itemIds'
  | 'note'
  | 'isSoldOut'
  | 'paymentMethods'
  | 'preSaleStatus'
  | 'isPremium'
> & {
  event: Pick<Event, 'id'>
  firstComeFirstServed?: Maybe<
    Pick<
      PreSaleFirstComeFirstServed,
      | 'purchasableCount'
      | 'purchasableStartedAt'
      | 'purchasableEndedAt'
      | 'formattedPurchasableStartedAt'
      | 'formattedPurchasableEndedAt'
      | 'personalInformationRequirements'
    > & {
      applicantAggregation?: Maybe<{
        creditCard?: Maybe<
          Pick<
            PreSaleFirstComeFirstServedAggregationCreditCard,
            'appliedCount' | 'purchasedCount'
          >
        >
        konbini?: Maybe<
          Pick<
            PreSaleFirstComeFirstServedAggregationKonbini,
            'appliedCount' | 'purchasedCount' | 'unpaidCount'
          >
        >
      }>
    }
  >
  lottery?: Maybe<
    Pick<
      PreSaleLottery,
      | 'applicationStartedAt'
      | 'applicationEndedAt'
      | 'purchasableStartedAt'
      | 'purchasableEndedAt'
      | 'resultNoticedAt'
      | 'formattedApplicationStartedAt'
      | 'formattedApplicationEndedAt'
      | 'formattedPurchasableStartedAt'
      | 'formattedPurchasableEndedAt'
      | 'formattedResultNoticedAt'
      | 'resultNoticeStatus'
      | 'purchasableCount'
      | 'maxChoice'
      | 'personalInformationRequirements'
    > & {
      applicantAggregation?: Maybe<{
        creditCard?: Maybe<
          Pick<
            PreSaleLotteryAggregationCreditCard,
            'appliedCount' | 'failedCount' | 'purchasedCount'
          >
        >
        konbini?: Maybe<
          Pick<
            PreSaleLotteryAggregationKonbini,
            'appliedCount' | 'purchasedCount' | 'unpaidCount'
          >
        >
      }>
    }
  >
  preSalesAggregation?: Maybe<ConsolePreSalesAggregationDetailFragment>
  items: Array<ConsolePreSaleItemSelectedBoxFragment>
}

export type ConsolePreSaleItemSelectedBoxFragment = Pick<
  Item,
  | 'id'
  | 'type'
  | 'title'
  | 'issuedNumber'
  | 'usedNumber'
  | 'paymentType'
  | 'purchasedNumber'
  | 'pendingPurchaseNumber'
  | 'price'
  | 'formattedPrice'
> & {
  thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
  ticketUsage?: Maybe<Pick<TicketUsage, 'unusedCount' | 'usedCount'>>
  sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
  skuSettings?: Maybe<
    Pick<SkuSettings, 'keyName'> & {
      units: Array<Pick<SkuUnit, 'id' | 'unitName'>>
    }
  >
  throughTicketUsedNumbers?: Maybe<
    Array<Pick<ThroughTicketUsage, 'subTicketId' | 'usedNumber'>>
  >
  metadata?: Maybe<{
    ticket?: Maybe<{
      throughTicket?: Maybe<
        Array<Pick<TicketItemMetadataThroughTicket, 'name'>>
      >
    }>
  }>
}

export type ConsolePreSaleItemSelectBoxFragment = Pick<
  Item,
  | 'id'
  | 'type'
  | 'title'
  | 'isPremium'
  | 'isPublished'
  | 'salesStatus'
  | 'paymentType'
  | 'isDrm'
  | 'price'
  | 'formattedPrice'
> & {
  thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
  sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
  skuSettings?: Maybe<
    Pick<SkuSettings, 'keyName'> & {
      units: Array<Pick<SkuUnit, 'id' | 'unitName'>>
    }
  >
}

export type ConsolePreSaleDetailQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ConsolePreSaleDetailQuery = {
  artistPreSale: {
    purchasedCartTransactionOrders: Array<Pick<UserCartTransactionOrder, 'id'>>
  } & ConsolePreSaleDetailFragment
}

export type ConsolePresaleItemSearchQueryVariables = Exact<{
  filters?: Maybe<ArtistItemsFilterInput>
  pageInput?: Maybe<PageInput>
}>

export type ConsolePresaleItemSearchQuery = {
  artistItems: {
    edges: Array<{ node: ConsolePreSaleItemSelectBoxFragment }>
    pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
  }
}

export type ArtistUpdatePreSaleItemsMutationVariables = Exact<{
  id: Scalars['ID']
  inputs: ArtistUpdatePreSaleInput
}>

export type ArtistUpdatePreSaleItemsMutation = {
  artistUpdatePreSale: Pick<PreSale, 'id' | 'itemIds'> & {
    items: Array<ConsolePreSaleItemSelectedBoxFragment>
  }
}

export type ConsolePreSaleEditFragment = Pick<
  PreSale,
  | 'id'
  | 'title'
  | 'description'
  | 'type'
  | 'isPublished'
  | 'endedAt'
  | 'startedAt'
  | 'itemIds'
  | 'note'
  | 'isSoldOut'
  | 'paymentMethods'
> & {
  firstComeFirstServed?: Maybe<
    Pick<
      PreSaleFirstComeFirstServed,
      | 'purchasableCount'
      | 'purchasableStartedAt'
      | 'purchasableEndedAt'
      | 'personalInformationRequirements'
    >
  >
  lottery?: Maybe<
    Pick<
      PreSaleLottery,
      | 'applicationStartedAt'
      | 'applicationEndedAt'
      | 'purchasableStartedAt'
      | 'purchasableEndedAt'
      | 'resultNoticedAt'
      | 'resultNoticeStatus'
      | 'purchasableCount'
      | 'maxChoice'
      | 'personalInformationRequirements'
    >
  >
}

export type ConsolePreSaleEditQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ConsolePreSaleEditQuery = {
  artistPreSale: ConsolePreSaleEditFragment
}

export type ArtistUpdatePreSaleMutationVariables = Exact<{
  id: Scalars['ID']
  inputs: ArtistUpdatePreSaleInput
}>

export type ArtistUpdatePreSaleMutation = {
  artistUpdatePreSale: Pick<PreSale, 'id' | 'isPublished'> & {
    lottery?: Maybe<Pick<PreSaleLottery, 'resultNoticedAt'>>
  }
}

export type PickupTimeItemsAggregationDetailFragment = Pick<
  PickupTimeItemsAggregation,
  'pickupTime'
> & {
  items?: Maybe<
    Array<
      Pick<
        PickupTimeItem,
        'itemId' | 'count' | 'completedCount' | 'pendingCount'
      > & {
        item: Pick<Item, 'title'> & {
          thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
        }
        sku?: Maybe<
          Pick<
            PickupTimeItemSku,
            'skuId' | 'count' | 'pendingCount' | 'completedCount'
          > & {
            sku: Pick<SkuUnit, 'id' | 'unitName'> & {
              thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
            }
          }
        >
        skus?: Maybe<
          Array<
            Pick<
              PickupTimeItemSku,
              'skuId' | 'count' | 'pendingCount' | 'completedCount'
            > & {
              sku: Pick<SkuUnit, 'id' | 'unitName'> & {
                thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
              }
            }
          >
        >
      }
    >
  >
}

export type EventCollectionPickupTimeFragment = Pick<
  EventCollection,
  'id' | 'collectionId' | 'validMinutes'
> & {
  pickupTime?: Maybe<
    Array<
      Pick<EventPickupTime, 'eventId'> & {
        options: Pick<EventPickupTimeOption, 'eventId' | 'time'>
      }
    >
  >
}

export type ConsoleQuantityManagementsQueryVariables = Exact<{
  collectionId: Scalars['String']
  eventId: Scalars['ID']
  pickupDate: Scalars['Date']
}>

export type ConsoleQuantityManagementsQuery = {
  artistAggregatePickupTimeItems?: Maybe<
    Array<PickupTimeItemsAggregationDetailFragment>
  >
  event: Pick<Event, 'id' | 'title'> & {
    eventCollections: {
      edges: Array<{ node: EventCollectionPickupTimeFragment }>
    }
  }
}

export type TransactionsConnectionsFragment = {
  edges: Array<{
    node: Pick<
      Transaction,
      | 'balance'
      | 'balanceInPayout'
      | 'type'
      | 'amount'
      | 'createdAt'
      | 'formattedCreatedAt'
      | 'formattedBalance'
      | 'formattedBalanceInPayout'
      | 'formattedTotalBalance'
      | 'formattedAmount'
      | 'formattedTotalBalanceNumber'
    >
  }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type ConsoleRevenueQueryVariables = Exact<{ [key: string]: never }>

export type ConsoleRevenueQuery = {
  salesReport: Pick<SalesReport, 'totalSales' | 'formattedTotalSales'> & {
    store: Pick<TotalSalesReportDetail, 'totalSales' | 'formattedTotalSales'>
    subscription: Pick<
      TotalSalesReportDetail,
      'totalSales' | 'formattedTotalSales'
    >
    tip: Pick<TotalSalesReportDetail, 'totalSales' | 'formattedTotalSales'>
  }
  transactions: TransactionsConnectionsFragment
  artist?: Maybe<
    Pick<
      Artist,
      | 'id'
      | 'paymentType'
      | 'hasArrangingDeliveryItems'
      | 'status'
      | 'createdAt'
    > & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<Pick<MembershipSubscriptionProduct, 'productId'>>
        >
      }>
      bank?: Maybe<Pick<Bank, 'id'>>
      business?: Maybe<Pick<Business, 'firstNameKana' | 'firstNameKanji'>>
    }
  >
}

export type ExportRevenueTransactionsQueryVariables = Exact<{
  year: Scalars['Int']
}>

export type ExportRevenueTransactionsQuery = {
  exportRevenueTransactions: Pick<RevenueTransaction, 'csvUrl'>
}

export type TransactionsQueryVariables = Exact<{
  pageInputDate?: Maybe<PageInputDate>
}>

export type TransactionsQuery = {
  transactions: TransactionsConnectionsFragment
}

export type OpenPayoutMutationVariables = Exact<{ [key: string]: never }>

export type OpenPayoutMutation = {
  openPayout?: Maybe<Pick<Payout, 'id' | 'artistId' | 'status'>>
}

export type PickupUserDetailFragment = Pick<
  PickupUser,
  | 'id'
  | 'eventId'
  | 'collectionId'
  | 'pickupTime'
  | 'formattedPickupTime'
  | 'formattedPickupStartEndTime'
  | 'isCompleted'
  | 'userItemIds'
  | 'itemsString'
  | 'actualTotalAmount'
  | 'formattedActualTotalAmount'
> & {
  eventCollection: Pick<EventCollection, 'id' | 'validMinutes'>
  pickupOrder: Array<
    Pick<PickupOrder, 'itemId' | 'title' | 'serialIds' | 'count'> & {
      serials: Array<Pick<ItemSerial, 'id' | 'skuId'>>
    }
  >
  pickupRemainingTime?: Maybe<
    Pick<PickupRemainingTime, 'hours' | 'minutes' | 'expiring'>
  >
  collection: Pick<Collection, 'id' | 'title'>
  userItems: Array<
    Pick<UserItem, 'id' | 'number'> & {
      item: Pick<Item, 'id'> & ItemBoxFragment
      serials: Array<
        Pick<UserItemSerial, 'id' | 'isUsed'> & {
          serial?: Maybe<
            Pick<ItemSerial, 'id'> & {
              sku?: Maybe<
                Pick<
                  SkuUnit,
                  'id' | 'unitName' | 'price' | 'formattedPrice'
                > & { thumbnail?: Maybe<Pick<Image, 'smallUri'>> }
              >
            }
          >
        }
      >
    }
  >
  cartTransactionOrders: Array<CartTransactionOrderDetailFragment>
  ticketItemSerialsInput: Array<Pick<TicketItemSerials, 'itemId' | 'serialIds'>>
  user: Pick<
    User,
    | 'id'
    | 'displayName'
    | 'phoneNumber'
    | 'fullName'
    | 'email'
    | 'formattedPhoneNumber'
  > & {
    address?: Maybe<
      Pick<
        UserAddress,
        'postalCode' | 'prefecture' | 'city' | 'line1' | 'line2' | 'fullAddress'
      >
    >
  }
}

export type CartTransactionOrderDetailFragment = Pick<
  UserCartTransactionOrder,
  'id' | 'totalAmount' | 'paymentType'
> & {
  cancels?: Maybe<
    Array<
      Pick<
        CartCancel,
        'canceledAt' | 'canceledCount' | 'reason' | 'refundedAmount'
      > & {
        canceledItems: Array<
          Pick<UserCartItem, 'count' | 'itemId'> & {
            skus: Array<Pick<UserCartItemSku, 'skuId' | 'count'>>
            item: Pick<Item, 'id' | 'title'>
          }
        >
      }
    >
  >
  items: Array<
    Pick<UserCartTransactionItem, 'count'> & {
      item: Pick<
        Item,
        'id' | 'title' | 'paymentType' | 'price' | 'formattedPrice'
      > & {
        thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>> }
        sku?: Maybe<
          Pick<
            SkuUnit,
            'id' | 'unitName' | 'order' | 'price' | 'formattedPrice'
          >
        >
        skuSettings?: Maybe<{
          units: Array<
            Pick<
              SkuUnit,
              'id' | 'unitName' | 'order' | 'price' | 'formattedPrice'
            >
          >
        }>
      }
      skus: Array<
        Maybe<{ sku: Pick<SkuUnit, 'unitName' | 'price' | 'formattedPrice'> }>
      >
    }
  >
  itemOrders: Array<
    Pick<ItemOrder, 'id' | 'serialIds' | 'canceledSerialIds'> & {
      item?: Maybe<Pick<Item, 'id' | 'title'>>
      serials?: Maybe<Array<Pick<ItemSerial, 'id' | 'skuId'>>>
    }
  >
}

export type ConsoleTicketReceiveDetailQueryVariables = Exact<{
  collectionId: Scalars['String']
  eventId: Scalars['String']
  pickupUserId: Scalars['ID']
}>

export type ConsoleTicketReceiveDetailQuery = {
  artistPickupUser: PickupUserDetailFragment
}

export type ArtistUseEventPickupTicketsMutationVariables = Exact<{
  inputs: UseEventPickupTicketsInput
}>

export type ArtistUseEventPickupTicketsMutation = {
  artistUseEventPickupTickets: Array<
    Pick<ItemSerial, 'id' | 'obtainedDate'> & {
      ticketStatus?: Maybe<TicketStatusDetailFragment>
    }
  >
}

export type ArtistUnuseEventPickupTicketsMutationVariables = Exact<{
  inputs: UnuseEventPickupTicketsInput
}>

export type ArtistUnuseEventPickupTicketsMutation = {
  artistUnuseEventPickupTickets: Array<
    Pick<ItemSerial, 'id' | 'obtainedDate'> & {
      ticketStatus?: Maybe<TicketStatusDetailFragment>
    }
  >
}

export type EventPickupUserConnectionFragment = {
  edges: Array<{ node: PickupUserBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type PickupUserBoxFragment = Pick<
  PickupUser,
  | 'id'
  | 'eventId'
  | 'collectionId'
  | 'pickupTime'
  | 'isCompleted'
  | 'userItemIds'
  | 'formattedPickupTime'
  | 'formattedPickupStartEndTime'
  | 'itemsString'
  | 'actualTotalAmount'
  | 'formattedActualTotalAmount'
> & {
  pickupOrder: Array<
    Pick<PickupOrder, 'itemId' | 'title' | 'serialIds' | 'count'> & {
      serials: Array<
        Pick<ItemSerial, 'id' | 'skuId'> & {
          sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
        }
      >
    }
  >
  eventCollection: Pick<EventCollection, 'id' | 'validMinutes'>
  collection: Pick<Collection, 'id'>
  cartTransactionOrders: Array<CartTransactionOrdersTipFragment>
  user: Pick<
    User,
    | 'id'
    | 'displayName'
    | 'phoneNumber'
    | 'fullName'
    | 'email'
    | 'formattedPhoneNumber'
  > & {
    address?: Maybe<
      Pick<
        UserAddress,
        'postalCode' | 'prefecture' | 'city' | 'line1' | 'line2' | 'fullAddress'
      >
    >
  }
}

export type CartTransactionOrdersTipFragment = Pick<
  UserCartTransactionOrder,
  | 'id'
  | 'totalAmount'
  | 'formattedTotalAmount'
  | 'paymentType'
  | 'createdAt'
  | 'isKonbini'
  | 'totalAmountWithoutFee'
  | 'formattedTotalAmountWithoutFee'
  | 'storeFee'
  | 'formattedStoreFee'
  | 'formattedSaleProfit'
  | 'originalTotalAmount'
  | 'formattedOriginalTotalAmount'
  | 'formattedCreatedAt'
> & {
  cancels?: Maybe<
    Array<
      Pick<CartCancel, 'canceledCount' | 'refundedAmount'> & {
        canceledItems: Array<
          Pick<UserCartItem, 'count' | 'itemId'> & {
            skus: Array<Pick<UserCartItemSku, 'skuId' | 'count'>>
            item: Pick<Item, 'id'>
          }
        >
      }
    >
  >
  itemOrders: Array<
    Pick<ItemOrder, 'id' | 'serialIds' | 'canceledSerialIds'> & {
      item?: Maybe<Pick<Item, 'id' | 'title'>>
      serials?: Maybe<
        Array<
          Pick<ItemSerial, 'id' | 'skuId'> & {
            sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
          }
        >
      >
    }
  >
}

export type ConsoleTicketReceivesQueryVariables = Exact<{
  collectionId: Scalars['String']
  colId: Scalars['ID']
  eventId: Scalars['String']
  options: EventPickupUsersOptions
  pageInput?: Maybe<PageInput>
}>

export type ConsoleTicketReceivesQuery = {
  collection?: Maybe<
    Pick<Collection, 'id' | 'title'> & { artist: Pick<Artist, 'id'> }
  >
  artistEventPickupUsers: EventPickupUserConnectionFragment
}

export type FetchNewConsoleTicketReceivesQueryVariables = Exact<{
  collectionId: Scalars['String']
  eventId: Scalars['String']
  options: EventPickupUsersOptions
  pageInput?: Maybe<PageInput>
}>

export type FetchNewConsoleTicketReceivesQuery = {
  artistEventPickupUsers: EventPickupUserConnectionFragment
}

export type ConsoleUserDetailUserFragment = Pick<
  User,
  | 'id'
  | 'email'
  | 'fullName'
  | 'displayName'
  | 'gender'
  | 'birthday'
  | 'phoneNumber'
  | 'formattedPhoneNumber'
  | 'formattedBirthday'
> &
  UserProfileFragment &
  UserAddressFragment

export type ConsoleUserDetailQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type ConsoleUserDetailQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      subscription?: Maybe<
        Pick<MembershipSubscription, 'productId'> & {
          plans?: Maybe<Array<ConsolePlanDetailFragment>>
          products?: Maybe<Array<ConsoleProductDetailFragment>>
        }
      >
    }
  >
  artistCustomer?: Maybe<
    Pick<ArtistCustomer, 'id' | 'purchaseCount' | 'ticketUnusedCount'> & {
      follower?: Maybe<Pick<Follower, 'id'>>
      patron?: Maybe<MemberBoxFragment>
      user?: Maybe<ConsoleUserDetailUserFragment>
    }
  >
}

export type FetchConsoleUserDetailQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type FetchConsoleUserDetailQuery = {
  user?: Maybe<ConsoleUserDetailUserFragment>
}

export type ConsoleUserOwnedItemDetailQueryVariables = Exact<{
  itemId: Scalars['ID']
  userId: Scalars['String']
  skuId?: Maybe<Scalars['String']>
}>

export type ConsoleUserOwnedItemDetailQuery = {
  artistPortfolio?: Maybe<UserItemDetailBoxFragment>
}

export type ConsoleUserOwnedItemsPortfolioFragment = {
  edges: Array<{ node: ConsoleUserOwnedItemsPortfolioDetailFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type ConsoleUserOwnedItemsPortfolioDetailFragment = Pick<
  UserItem,
  'id' | 'number' | 'isSystemProtected' | 'isUserProtected'
> & {
  serials: Array<
    Pick<UserItemSerial, 'serialId' | 'isUsed'> & {
      serial?: Maybe<{
        ticketStatus?: Maybe<TicketStatusDetailFragment>
        sku?: Maybe<Pick<SkuUnit, 'id'>>
      }>
    }
  >
  item: Pick<
    Item,
    | 'isResaleAvailable'
    | 'id'
    | 'title'
    | 'type'
    | 'hideSalesNumber'
    | 'isDownloadable'
  > & {
    preSale?: Maybe<
      Pick<PreSale, 'id'> & { event: Pick<Event, 'id' | 'title'> }
    >
    metadata?: Maybe<{
      wallpaper?: Maybe<
        Pick<
          ItemWallpaperMetadata,
          'isPrintEnabled' | 'printCount' | 'printType'
        >
      >
      ticket?: Maybe<
        Pick<ItemMetadataDetailTicket, 'isThroughTicket'> & {
          throughTicket?: Maybe<
            Array<Pick<TicketItemMetadataThroughTicket, 'name'>>
          >
        }
      >
    }>
    rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
    sku?: Maybe<
      Pick<
        SkuUnit,
        | 'id'
        | 'order'
        | 'pendingPurchaseNumber'
        | 'purchasedNumber'
        | 'stock'
        | 'unitName'
      > & {
        thumbnail?: Maybe<
          Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
        >
      }
    >
    skuSettings?: Maybe<
      Pick<SkuSettings, 'keyName'> & {
        units: Array<
          Pick<
            SkuUnit,
            | 'id'
            | 'order'
            | 'pendingPurchaseNumber'
            | 'purchasedNumber'
            | 'stock'
            | 'unitName'
          > & {
            thumbnail?: Maybe<
              Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
            >
          }
        >
      }
    >
    thumbnail: {
      front?: Maybe<
        Pick<Resource, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
      >
    }
  }
}

export type ConsoleUserOwnedItemsQueryVariables = Exact<{
  userId: Scalars['ID']
  type?: Maybe<ItemType>
  pageInput?: Maybe<PageInput>
}>

export type ConsoleUserOwnedItemsQuery = {
  user?: Maybe<
    Pick<User, 'id'> & {
      artistPortfolios: ConsoleUserOwnedItemsPortfolioFragment
    }
  >
}

export type ArtistCustomerDetailFragment = Pick<
  ArtistCustomer,
  | 'id'
  | 'userId'
  | 'lastPurchasedAt'
  | 'formattedLastPurchasedAt'
  | 'purchaseCount'
  | 'formattedPurchaseCount'
> & {
  user?: Maybe<
    Pick<
      User,
      'id' | 'displayName' | 'phoneNumber' | 'fullName' | 'formattedPhoneNumber'
    > & { avatar?: Maybe<Pick<Avatar, 'smallUri' | 'uri'>> }
  >
  patron?: Maybe<Pick<Patron, 'id'>>
}

export type ArtistCustomerConnectionFragment = {
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
  edges: Array<{ node: ArtistCustomerDetailFragment }>
}

export type ConsoleUsersQueryVariables = Exact<{
  orderBy?: Maybe<OrderBy>
  pageInput?: Maybe<PageInput>
  searchTerm?: Maybe<Scalars['String']>
}>

export type ConsoleUsersQuery = {
  artistCustomers: ArtistCustomerConnectionFragment
  artist?: Maybe<
    Pick<Artist, 'id' | 'paymentType' | 'hasArrangingDeliveryItems'> & {
      subscription?: Maybe<{
        products?: Maybe<
          Array<Pick<MembershipSubscriptionProduct, 'productId'>>
        >
      }>
    }
  >
}

export type FetchNewConsoleUsersQueryVariables = Exact<{
  orderBy?: Maybe<OrderBy>
  pageInput?: Maybe<PageInput>
  searchTerm?: Maybe<Scalars['String']>
}>

export type FetchNewConsoleUsersQuery = {
  artistCustomers: ArtistCustomerConnectionFragment
}

export type ArtistExportCustomersCsvQueryVariables = Exact<{
  [key: string]: never
}>

export type ArtistExportCustomersCsvQuery = {
  artistExportCustomersCsv: Pick<CsvDownload, 'csvUrl'>
}

export type UserItemsAvatarConnectionFragment = {
  edges: Array<{
    node: Pick<UserItem, 'id'> & {
      item: Pick<Item, 'id'> & {
        avatarResources?: Maybe<{ avatarResource?: Maybe<ResourceFragment> }>
      }
    }
  }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type CustomProfileEditAvatarQueryVariables = Exact<{
  organizationPortfoliosType?: Maybe<ItemType>
  pageInput?: Maybe<PageInput>
}>

export type CustomProfileEditAvatarQuery = {
  user?: Maybe<
    Pick<User, 'id'> & {
      organizationPortfolios: UserItemsAvatarConnectionFragment
      customProfile?: Maybe<CustomProfileConnectionFragment>
    }
  >
}

export type UserItemsConnectionFragment = {
  edges: Array<{
    node: Pick<UserItem, 'id'> & {
      item: Pick<Item, 'id'> & {
        wallpaperResources?: Maybe<{
          wallpaperResource?: Maybe<ResourceFragment>
        }>
      }
    }
  }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type CustomProfileConnectionFragment = {
  avatarItem?: Maybe<
    Pick<Item, 'id'> & {
      avatarResources?: Maybe<{
        avatarResource?: Maybe<ResourceFragment>
        voiceResource?: Maybe<ResourceFragment>
      }>
    }
  >
  wallpaperItem?: Maybe<
    Pick<Item, 'id'> & {
      wallpaperResources?: Maybe<{
        wallpaperResource?: Maybe<ResourceFragment>
        audioResource?: Maybe<ResourceFragment>
      }>
    }
  >
}

export type CustomProfileEditWallPaperQueryVariables = Exact<{
  organizationPortfoliosType?: Maybe<ItemType>
  pageInput?: Maybe<PageInput>
}>

export type CustomProfileEditWallPaperQuery = {
  user?: Maybe<
    Pick<User, 'id'> & {
      organizationPortfolios: UserItemsConnectionFragment
      customProfile?: Maybe<CustomProfileConnectionFragment>
    }
  >
}

export type UpdateCustomProfileMutationVariables = Exact<{
  updateCustomProfileInputs?: Maybe<UpdateCustomProfileInput>
}>

export type UpdateCustomProfileMutation = {
  updateCustomProfile: Pick<User, 'id'> & {
    customProfile?: Maybe<CustomProfileConnectionFragment>
  }
}

export type DeactivateUserMutationVariables = Exact<{
  input: DeactivateUserInput
}>

export type DeactivateUserMutation = { deactivateUser: Pick<User, 'id'> }

export type UserDeliveryInfoFragment = Pick<
  User,
  | 'id'
  | 'email'
  | 'fullName'
  | 'phoneNumber'
  | 'birthday'
  | 'gender'
  | 'isPhoneNumberVerified'
  | 'isMembershipPersonalInformationRequired'
> & {
  address?: Maybe<
    Pick<
      UserAddress,
      'postalCode' | 'prefecture' | 'city' | 'line1' | 'line2' | 'fullAddress'
    >
  >
  bank?: Maybe<
    Pick<
      UserBank,
      | 'bankName'
      | 'accountType'
      | 'accountHolderName'
      | 'branchCode'
      | 'branchName'
      | 'accountNumber'
    >
  >
}

export type UserDeliveryInfoQueryVariables = Exact<{ [key: string]: never }>

export type UserDeliveryInfoQuery = { user?: Maybe<UserDeliveryInfoFragment> }

export type UpdateUserDeliveryInfoMutationVariables = Exact<{
  updateUserDeliveryInfoInputs: UserDeliveryInput
}>

export type UpdateUserDeliveryInfoMutation = {
  updateUserDeliveryInfo: UserDeliveryInfoFragment
}

export type UserAddressFragment = {
  address?: Maybe<
    Pick<
      UserAddress,
      | 'postalCode'
      | 'prefecture'
      | 'city'
      | 'line1'
      | 'line2'
      | 'fullAddress'
      | 'shortAddress'
    >
  >
}

export type DeliveryItemsConnectionFragment = {
  edges: Array<{ node: DeliveryItemBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type DeliveryManagementQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
  deliveryItemsStatusFilter?: Maybe<Array<DeliveryStatus> | DeliveryStatus>
}>

export type DeliveryManagementQuery = {
  user?: Maybe<
    Pick<
      User,
      'id' | 'phoneNumber' | 'formattedPhoneNumber' | 'isPhoneNumberVerified'
    > &
      UserAddressFragment
  >
  deliveryItems: DeliveryItemsConnectionFragment
}

export type ApplyMerchItemDeliveryMutationVariables = Exact<{
  applyMerchItemDeliveryIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type ApplyMerchItemDeliveryMutation = {
  applyMerchItemDelivery: Array<DeliveryItemBoxFragment>
}

export type SendVerificationSmsMutationVariables = Exact<{
  [key: string]: never
}>

export type SendVerificationSmsMutation = {
  sendVerificationSMS: Pick<PhoneNumberVerification, 'phoneNumber' | 'id'>
}

export type UserSettingFragment = {
  settings?: Maybe<{
    notifications?: Maybe<{
      campaign?: Maybe<{
        startCampaign?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
        endCampaign?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
      }>
      mission?: Maybe<{
        newMission?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
        replyCommentMission?: Maybe<
          Pick<NotificationChannels, 'email' | 'push'>
        >
      }>
      threadChannel?: Maybe<{
        commentThread?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
        replyCommentThread?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
      }>
      post?: Maybe<{
        newPost?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
        replyPost?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
      }>
      gacha?: Maybe<{
        upcomingExpiry?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
      }>
      trade?: Maybe<{
        requestTrade?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
        commentTrade?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
        agreedTrade?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
        declinedTrade?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
      }>
      liveStream?: Maybe<{
        startLiveStream?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
      }>
      announcement?: Maybe<{
        official?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
      }>
    }>
  }>
}

export type ArtistSettingFragment = {
  settings?: Maybe<{
    notifications?: Maybe<{
      post?: Maybe<{
        replyPost?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
      }>
      artistThreadChannel?: Maybe<{
        newThread?: Maybe<Pick<NotificationChannels, 'email' | 'push'>>
      }>
      membership?: Maybe<{
        subscribe?: Maybe<Pick<NotificationChannels, 'push' | 'email'>>
      }>
    }>
  }>
}

export type EmailNotificationSettingsQueryVariables = Exact<{
  [key: string]: never
}>

export type EmailNotificationSettingsQuery = {
  user?: Maybe<Pick<User, 'id'> & UserSettingFragment>
}

export type ArtistNotificationSettingsQueryVariables = Exact<{
  [key: string]: never
}>

export type ArtistNotificationSettingsQuery = {
  artist?: Maybe<Pick<Artist, 'id'> & ArtistSettingFragment>
}

export type UpdateUserNotificationSettingsMutationVariables = Exact<{
  updateUserNotificationSettingsInputs: UserNotificationSettingsInput
}>

export type UpdateUserNotificationSettingsMutation = {
  updateUserNotificationSettings: Pick<User, 'id'> & UserSettingFragment
}

export type UpdateArtistNotificationSettingsMutationVariables = Exact<{
  inputs: ArtistNotificationSettingsInput
}>

export type UpdateArtistNotificationSettingsMutation = {
  updateArtistNotificationSettings: Pick<Artist, 'id'> & ArtistSettingFragment
}

export type EventCollectionItemsConnectionFragment = {
  edges: Array<{
    node: Pick<CollectionItem, 'id'> & {
      item: Pick<
        Item,
        | 'id'
        | 'title'
        | 'price'
        | 'formattedPrice'
        | 'remainingNumber'
        | 'formattedRemainingNumber'
        | 'paymentType'
        | 'type'
        | 'salesStatus'
        | 'isPublished'
        | 'usedNumber'
        | 'purchasedNumber'
        | 'issuedNumber'
        | 'pendingPurchaseNumber'
        | 'hideSalesNumber'
        | 'isResaleAvailable'
        | 'isPremium'
      > & {
        rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
        rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
        thumbnail: {
          front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>>
          back?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>>
        }
        skuSettings?: Maybe<
          Pick<SkuSettings, 'keyName'> & {
            units: Array<Pick<SkuUnit, 'id' | 'unitName' | 'price' | 'order'>>
          }
        >
      }
    }
  }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type EventCollectionDetailQueryVariables = Exact<{
  collectionId: Scalars['ID']
  collectionItemsLabel?: Maybe<Scalars['String']>
  collectionItemsFirstLabel?: Maybe<Scalars['Boolean']>
  pageInput?: Maybe<PageInput>
  publishedOnly?: Maybe<Scalars['Boolean']>
}>

export type EventCollectionDetailQuery = Pick<Query, 'userCartCount'> & {
  collection?: Maybe<
    Pick<
      Collection,
      | 'id'
      | 'title'
      | 'artistId'
      | 'isEventPickup'
      | 'itemCount'
      | 'description'
    > & {
      artist: Pick<Artist, 'id' | 'displayName'>
      thumbnail: Pick<CollectionThumbnail, 'uri'>
      labels?: Maybe<Array<Maybe<Pick<CollectionLabel, 'id' | 'name'>>>>
      collectionItems: EventCollectionItemsConnectionFragment
    }
  >
}

export type EventCollectionDetailForGuestQueryVariables = Exact<{
  collectionId: Scalars['ID']
  collectionItemsLabel?: Maybe<Scalars['String']>
  collectionItemsFirstLabel?: Maybe<Scalars['Boolean']>
  pageInput?: Maybe<PageInput>
  publishedOnly?: Maybe<Scalars['Boolean']>
}>

export type EventCollectionDetailForGuestQuery = {
  collection?: Maybe<
    Pick<
      Collection,
      | 'id'
      | 'title'
      | 'artistId'
      | 'isEventPickup'
      | 'itemCount'
      | 'description'
    > & {
      artist: Pick<Artist, 'id' | 'displayName'>
      thumbnail: Pick<CollectionThumbnail, 'uri'>
      labels?: Maybe<Array<Maybe<Pick<CollectionLabel, 'name' | 'id'>>>>
      collectionItems: EventCollectionItemsConnectionFragment
    }
  >
}

export type EventDetailFragment = Pick<
  Event,
  | 'id'
  | 'title'
  | 'description'
  | 'startedAt'
  | 'endedAt'
  | 'closeDate'
  | 'dateRange'
  | 'aboutDateRange'
  | 'dateArray'
  | 'formattedEventTime'
> & {
  location?: Maybe<
    Pick<
      GpsData,
      'approximateAddress' | 'latitude' | 'longitude' | 'radius' | 'mapUri'
    >
  >
  thumbnail?: Maybe<Pick<Image, 'mediumUri' | 'uri'>>
  banners: Array<{
    link?: Maybe<Pick<BannerLink, 'externalUrl' | 'innerPath'>>
    thumbnail: Pick<Image, 'uri' | 'mediumUri'>
  }>
}

export type EventDetailCollectionBoxFragment = Pick<
  EventCollection,
  'id' | 'eventId' | 'collectionId'
> & {
  collection: Pick<
    Collection,
    'id' | 'artistId' | 'isEventPickup' | 'title' | 'itemCount'
  > & {
    thumbnail: Pick<CollectionThumbnail, 'uri'>
    artist: Pick<Artist, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  }
}

export type EventPreSaleBoxFragment = Pick<
  PreSale,
  | 'id'
  | 'title'
  | 'type'
  | 'isSoldOut'
  | 'isPublished'
  | 'description'
  | 'itemIds'
  | 'note'
  | 'preSaleStatus'
  | 'startedAt'
  | 'formattedStartedAt'
  | 'endedAt'
  | 'formattedEndedAt'
  | 'isPremium'
  | 'planId'
> & {
  items: Array<Pick<Item, 'id' | 'isResaleAvailable'>>
  lottery?: Maybe<
    Pick<
      PreSaleLottery,
      | 'applicationStartedAt'
      | 'applicationEndedAt'
      | 'purchasableStartedAt'
      | 'purchasableEndedAt'
      | 'resultNoticedAt'
      | 'formattedApplicationStartedAt'
      | 'formattedApplicationEndedAt'
      | 'formattedPurchasableStartedAt'
      | 'formattedPurchasableEndedAt'
      | 'formattedResultNoticedAt'
      | 'resultNoticeStatus'
      | 'purchasableCount'
      | 'maxChoice'
      | 'personalInformationRequirements'
    >
  >
  firstComeFirstServed?: Maybe<
    Pick<
      PreSaleFirstComeFirstServed,
      | 'purchasableCount'
      | 'purchasableStartedAt'
      | 'purchasableEndedAt'
      | 'formattedPurchasableStartedAt'
      | 'formattedPurchasableEndedAt'
      | 'personalInformationRequirements'
    >
  >
}

export type EventDetailQueryVariables = Exact<{
  id: Scalars['ID']
  eventCollectionPageInput?: Maybe<PageInput>
  eventPreSalePageInput?: Maybe<PageInput>
  filter?: Maybe<CollectionStatus>
}>

export type EventDetailQuery = Pick<Query, 'userCartCount'> & {
  event: {
    eventCollections: {
      edges: Array<{ node: EventDetailCollectionBoxFragment }>
      pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    }
    preSales: {
      edges: Array<{ node: EventPreSaleBoxFragment }>
      pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    }
  } & EventDetailFragment
  user?: Maybe<CurrentUserFragment>
}

export type EventListFragment = Pick<
  Event,
  | 'id'
  | 'title'
  | 'description'
  | 'startedAt'
  | 'endedAt'
  | 'closeDate'
  | 'dateRange'
  | 'aboutDateRange'
> & {
  preSales: EventPreSaleConnectionFragment
  location?: Maybe<
    Pick<
      GpsData,
      'approximateAddress' | 'latitude' | 'longitude' | 'radius' | 'mapUri'
    >
  >
  thumbnail?: Maybe<Pick<Image, 'mediumUri' | 'uri'>>
}

export type EventBoxFragment = EventBoxCommonFragment &
  EventCollectionsBoxFragment

export type EventBoxCommonFragment = Pick<
  Event,
  | 'id'
  | 'title'
  | 'description'
  | 'startedAt'
  | 'endedAt'
  | 'closeDate'
  | 'dateRange'
  | 'aboutDateRange'
  | 'dateArray'
  | 'createdAt'
  | 'updatedAt'
> & {
  preSales: EventPreSaleConnectionFragment
  location?: Maybe<
    Pick<
      GpsData,
      'approximateAddress' | 'latitude' | 'longitude' | 'radius' | 'mapUri'
    >
  >
  thumbnail?: Maybe<Pick<Image, 'mediumUri' | 'uri'>>
  banners: Array<
    Pick<EventBanner, 'order'> & {
      thumbnail: Pick<Image, 'uri' | 'mediumUri' | 'smallUri' | 'compressedUri'>
      link?: Maybe<Pick<BannerLink, 'externalUrl' | 'innerPath'>>
    }
  >
}

export type EventPreSaleConnectionFragment = {
  edges: Array<{
    node: Pick<PreSale, 'id' | 'type'> & {
      lottery?: Maybe<
        Pick<
          PreSaleLottery,
          | 'applicationStartedAt'
          | 'applicationEndedAt'
          | 'purchasableStartedAt'
          | 'purchasableEndedAt'
          | 'resultNoticedAt'
          | 'resultNoticeStatus'
          | 'purchasableCount'
          | 'maxChoice'
          | 'personalInformationRequirements'
        >
      >
      firstComeFirstServed?: Maybe<
        Pick<
          PreSaleFirstComeFirstServed,
          | 'purchasableCount'
          | 'purchasableStartedAt'
          | 'purchasableEndedAt'
          | 'personalInformationRequirements'
        >
      >
    }
  }>
}

export type EventCollectionsBoxFragment = {
  eventCollections: {
    edges: Array<{ node: EventCollectionBoxFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
}

export type EventCollectionBoxFragment = Pick<
  EventCollection,
  | 'id'
  | 'eventId'
  | 'collectionId'
  | 'validMinutes'
  | 'pickupLocation'
  | 'purchaseLimitPerPickup'
> & {
  collection: {
    collectionItems: {
      edges: Array<{
        node: Pick<CollectionItem, 'id'> & {
          item: Pick<Item, 'id' | 'type' | 'hideSalesNumber'>
        }
      }>
    }
  } & CollectionBoxFragment
  myPickupUsers?: Maybe<
    Array<
      Pick<
        PickupUser,
        'id' | 'userId' | 'pickupTime' | 'formattedPickupStartEndTime'
      > & {
        cartTransactionOrders: Array<CartTransactionOrderDetailFragment>
        userItems: Array<
          Pick<UserItem, 'number'> & {
            item: Pick<Item, 'id' | 'hideSalesNumber'>
          }
        >
        eventCollection: Pick<EventCollection, 'id' | 'validMinutes'>
      }
    >
  >
  pickupTime?: Maybe<
    Array<{
      options: Pick<
        EventPickupTimeOption,
        'availableUserNumber' | 'currentUserNumber' | 'time'
      >
    }>
  >
}

export type EventsQueryVariables = Exact<{
  artistId?: Maybe<Scalars['ID']>
  filter: EventFilter
  pageInput?: Maybe<PageInput>
  isStandalone: Scalars['Boolean']
}>

export type EventsQuery = {
  user?: Maybe<CurrentUserFragment>
  artist?: Maybe<
    { currentLive?: Maybe<Pick<LiveStream, 'id'>> } & ArtistBottomTabFragment
  >
  events: {
    edges: Array<{ node: EventListFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
}

export type FetchNewEventsQueryVariables = Exact<{
  artistId?: Maybe<Scalars['ID']>
  filter: EventFilter
  pageInput?: Maybe<PageInput>
  isStandalone: Scalars['Boolean']
}>

export type FetchNewEventsQuery = {
  artist?: Maybe<
    { currentLive?: Maybe<Pick<LiveStream, 'id'>> } & ArtistBottomTabFragment
  >
  events: {
    edges: Array<{ node: EventListFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
}

export type FirstComeFirstServedCompleteQueryVariables = Exact<{
  userCartTransactionOrderId: Scalars['ID']
}>

export type FirstComeFirstServedCompleteQuery = {
  userCartTransactionOrder: UserCartTransactionOrderFragment
}

export type FirstComeFirstServedConfirmationFragment = Pick<
  PreSale,
  'id' | 'title' | 'type' | 'preSaleStatus' | 'isSoldOut'
> & {
  firstComeFirstServed?: Maybe<
    Pick<
      PreSaleFirstComeFirstServed,
      | 'purchasableCount'
      | 'purchasableStartedAt'
      | 'purchasableEndedAt'
      | 'formattedPurchasableStartedAt'
      | 'formattedPurchasableEndedAt'
      | 'personalInformationRequirements'
    >
  >
  items: Array<
    Pick<Item, 'id' | 'title' | 'price' | 'formattedPrice' | 'type'> & {
      metadata?: Maybe<{
        ticket?: Maybe<
          Pick<
            ItemMetadataDetailTicket,
            'isThroughTicket' | 'description' | 'note'
          >
        >
      }>
      skuSettings?: Maybe<{
        units: Array<
          Pick<SkuUnit, 'id' | 'unitName' | 'price' | 'formattedPrice'>
        >
      }>
      fees?: Maybe<{
        collections?: Maybe<Array<Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>>>
        konbini?: Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>
        shipping?: Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>
      }>
    }
  >
}

export type FirstComeFirstServedConfirmationQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type FirstComeFirstServedConfirmationQuery = {
  preSale: FirstComeFirstServedConfirmationFragment
  paymentSource?: Maybe<PaymentDetailFragment>
}

export type PurchaseFirstComeFirstServedMutationVariables = Exact<{
  inputs: PurchaseItemImmediatelyInput
}>

export type PurchaseFirstComeFirstServedMutation = {
  purchaseItemImmediately: Pick<UserCartTransactionOrder, 'id' | 'paymentType'>
}

export type FirstComeFirstServedDetailFragment = {
  purchasedCartTransactionOrders: Array<PurchasedCartTransactionOrderDetailFragment>
  items: Array<PreSaleItemFragment>
} & FirstComeFirstServedDetailForGuestFragment

export type FirstComeFirstServedDetailForGuestFragment = Pick<
  PreSale,
  | 'id'
  | 'title'
  | 'type'
  | 'description'
  | 'startedAt'
  | 'endedAt'
  | 'note'
  | 'paymentMethods'
  | 'preSaleStatus'
  | 'isSoldOut'
  | 'itemIds'
> & {
  event: Pick<Event, 'id' | 'title' | 'note' | 'status'>
  firstComeFirstServed?: Maybe<
    Pick<
      PreSaleFirstComeFirstServed,
      | 'purchasableCount'
      | 'purchasableStartedAt'
      | 'purchasableEndedAt'
      | 'formattedPurchasableStartedAt'
      | 'formattedPurchasableEndedAt'
      | 'personalInformationRequirements'
    >
  >
  items: Array<PreSaleItemFragment>
}

export type PreSaleItemFragment = Pick<
  Item,
  | 'id'
  | 'title'
  | 'price'
  | 'purchasedNumber'
  | 'pendingPurchaseNumber'
  | 'issuedNumber'
  | 'formattedPrice'
  | 'isResaleAvailable'
  | 'resaleEnabled'
  | 'type'
> & {
  metadata?: Maybe<{
    ticket?: Maybe<
      Pick<ItemMetadataDetailTicket, 'isThroughTicket'> & {
        resalePeriod?: Maybe<
          Pick<
            ReceptionPeriod,
            'startedAt' | 'endedAt' | 'formattedStartedAt' | 'formattedEndedAt'
          >
        >
      }
    >
  }>
  skuSettings?: Maybe<{
    units: Array<
      Pick<
        SkuUnit,
        | 'id'
        | 'order'
        | 'unitName'
        | 'stock'
        | 'purchasedNumber'
        | 'pendingPurchaseNumber'
        | 'price'
        | 'formattedPrice'
      >
    >
  }>
  fees?: Maybe<{
    collections?: Maybe<
      Array<
        Maybe<Pick<Fee, 'fee' | 'feeName' | 'per' | 'feeType' | 'formattedFee'>>
      >
    >
    konbini?: Maybe<
      Pick<Fee, 'fee' | 'feeName' | 'per' | 'feeType' | 'formattedFee'>
    >
    shipping?: Maybe<
      Pick<Fee, 'fee' | 'feeName' | 'per' | 'feeType' | 'formattedFee'>
    >
  }>
}

export type PurchasedCartTransactionOrderDetailFragment = Pick<
  UserCartTransactionOrder,
  'id' | 'isKonbini'
> & {
  pendingPurchaseItem?: Maybe<Pick<PendingPurchaseItem, 'id' | 'voucherUrl'>>
}

export type FirstComeFirstServedDetailQueryVariables = Exact<{
  id: Scalars['ID']
  clientId?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}>

export type FirstComeFirstServedDetailQuery = {
  preSale: FirstComeFirstServedDetailFragment
  paymentSource?: Maybe<PaymentDetailFragment>
  user?: Maybe<UserDeliveryDetailFragment>
}

export type FirstComeFirstServedDetailForGuestQueryVariables = Exact<{
  id: Scalars['ID']
  clientId?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}>

export type FirstComeFirstServedDetailForGuestQuery = {
  preSale: FirstComeFirstServedDetailForGuestFragment
}

export type GachaTicketConnectionFragment = Pick<
  GachaTicket,
  'id' | 'isPurchasable'
> & { thumbnails?: Maybe<GachaTicketThumbnailsBoxFragment> }

export type UserGachaTicketConnectionFragment = Pick<
  UserGachaTicket,
  'available'
>

export type GachasDetailConnectionFragment = {
  edges: Array<{
    node: Pick<
      Gacha,
      | 'id'
      | 'name'
      | 'description'
      | 'artistId'
      | 'isPremium'
      | 'planId'
      | 'isAccessible'
      | 'endAt'
      | 'deliveryDateText'
      | 'formattedEndAt'
      | 'totalLimitedItemCount'
      | 'remainingLimitedItemCount'
      | 'isSoldOut'
      | 'isLimited'
      | 'rollAlgorithm'
      | 'price'
      | 'gachaRollType'
    > & {
      thumbnail: Pick<Image, 'uri' | 'smallUri' | 'mediumUri'>
      animation: { roll: Pick<Image, 'uri' | 'mediumUri' | 'smallUri'> }
      purchasableGachaTickets: Array<
        Maybe<
          Pick<
            PurchasableGachaTicket,
            'count' | 'displayCount' | 'price' | 'prepaidPoints'
          >
        >
      >
      gpsData?: Maybe<
        Pick<
          GpsData,
          'approximateAddress' | 'latitude' | 'longitude' | 'radius'
        >
      >
      standByAnimations?: Maybe<{
        bulk?: Maybe<Pick<Image, 'contentType' | 'uri' | 'smallUri'>>
        single?: Maybe<Pick<Image, 'contentType' | 'uri' | 'smallUri'>>
      }>
      rewardAnimations?: Maybe<{
        bulk: {
          animation: {
            normal?: Maybe<Pick<Image, 'uri'>>
            special?: Maybe<Pick<Image, 'uri'>>
          }
        }
        single: {
          animation: {
            normal?: Maybe<Pick<Image, 'uri'>>
            special?: Maybe<Pick<Image, 'uri'>>
          }
        }
      }>
    }
  }>
}

export type ArtistGachaDetailFragment = Pick<
  Artist,
  'id' | 'displayName' | 'totalUnreadMentionCount'
> & {
  avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
  patron?: Maybe<Pick<Patron, 'isPersonalInformationRequired'>>
} & ArtistMembershipSubscriptionDetailFragment

export type GachaDetailQueryVariables = Exact<{
  gachasArtistId?: Maybe<Scalars['ID']>
  gachasPageInput?: Maybe<PageInputDate>
  gachaTicketId: Scalars['ID']
  platform: Scalars['String']
  purchasablePrepaidPointPageInput?: Maybe<PageInput>
}>

export type GachaDetailQuery = {
  gachas: GachasDetailConnectionFragment
  user?: Maybe<
    {
      gachaTicket?: Maybe<UserGachaTicketConnectionFragment>
    } & CurrentUserFragment
  >
  gachaTicket?: Maybe<GachaTicketConnectionFragment>
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    'prepaidPointId' | 'balance' | 'formattedPrepaidPointBarance'
  >
  artist?: Maybe<ArtistBottomTabFragment & ArtistGachaDetailFragment>
  purchasablePrepaidPoints: PurchasablePrepaidPointConnectionFragment
  paymentSource?: Maybe<PrepaidPointsPaymentSourceFragment>
}

export type GachaDetailForGuestQueryVariables = Exact<{
  gachasArtistId?: Maybe<Scalars['ID']>
  gachasPageInput?: Maybe<PageInputDate>
  gachaTicketId: Scalars['ID']
}>

export type GachaDetailForGuestQuery = {
  gachas: GachasDetailConnectionFragment
  gachaTicket?: Maybe<GachaTicketConnectionFragment>
  artist?: Maybe<ArtistBottomTabFragment & ArtistGachaDetailFragment>
}

export type RollGachaMutationVariables = Exact<{
  rollGachaGachaId: Scalars['ID']
  rollGachaInputs: RollGachaInput
}>

export type RollGachaMutation = {
  rollGacha: {
    gachaOrder: Pick<GachaOrder, 'id' | 'gachaId'> & {
      rewardItems: Array<Pick<GachaRewardItem, 'count'>>
      rewardAnimation?: Maybe<Pick<Image, 'uri' | 'mediumUri' | 'smallUri'>>
    }
  }
}

export type PurchaseGachaTicketByPointMutationVariables = Exact<{
  gachaTicketId: Scalars['ID']
  inputs: PurchaseGachaTicketPointInput
}>

export type PurchaseGachaTicketByPointMutation = {
  purchaseGachaTicketByPoint: Pick<GachaTicketOrder, 'gachaTicketId' | 'count'>
}

export type PurchaseGachaTicketByPointInBulkMutationVariables = Exact<{
  gachaTicketId: Scalars['ID']
  inputs: PurchaseGachaTicketPointInBulkInput
}>

export type PurchaseGachaTicketByPointInBulkMutation = {
  purchaseGachaTicketByPointInBulk: Pick<
    GachaTicketOrder,
    'gachaTicketId' | 'count'
  >
}

export type GachaItemsConnectionFragment = Pick<
  GachaItem,
  'weight' | 'count'
> & {
  item: Pick<Item, 'title' | 'id'> & {
    thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
  }
}

export type GachaItemListQueryVariables = Exact<{
  gachaId: Scalars['ID']
}>

export type GachaItemListQuery = {
  gacha?: Maybe<
    Pick<Gacha, 'id' | 'rollAlgorithm'> & {
      items: Array<GachaItemsConnectionFragment>
    }
  >
}

export type GachaOrderDetailFragment = {
  rewardItems: Array<
    Pick<GachaRewardItem, 'count' | 'itemId'> & {
      userItem: UserItemDetailFragment
      item: GachaItemDetailFragment
      obtainedUserItemSerials: Array<
        Pick<UserItemSerial, 'itemId' | 'formattedSerialNumber'>
      >
    }
  >
  rewardAnimation?: Maybe<Pick<Image, 'uri' | 'mediumUri'>>
  gacha: Pick<
    Gacha,
    | 'id'
    | 'artistId'
    | 'rollAlgorithm'
    | 'remainingLimitedItemCount'
    | 'totalLimitedItemCount'
    | 'isLimited'
    | 'price'
    | 'gachaRollType'
  > & {
    animation: { roll: Pick<Image, 'uri' | 'smallUri'> }
    standByAnimations?: Maybe<{
      bulk?: Maybe<Pick<Image, 'contentType' | 'uri' | 'smallUri'>>
      single?: Maybe<Pick<Image, 'contentType' | 'uri' | 'smallUri'>>
    }>
    rewardAnimations?: Maybe<{
      bulk: {
        animation: {
          normal?: Maybe<Pick<Image, 'uri'>>
          special?: Maybe<Pick<Image, 'uri'>>
        }
      }
      single: {
        animation: {
          normal?: Maybe<Pick<Image, 'uri'>>
          special?: Maybe<Pick<Image, 'uri'>>
        }
      }
    }>
  }
}

export type UserItemDetailFragment = Pick<
  UserItem,
  'id' | 'isUsed' | 'number' | 'type' | 'createdAt'
>

export type GachaItemDetailFragment = Pick<
  Item,
  'id' | 'title' | 'issuedNumber' | 'thumbnailType'
> & {
  rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
  rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
  thumbnail: {
    front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>>
    back?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>>
  }
  avatarResources?: Maybe<{ voiceResource?: Maybe<ResourceFragment> }>
}

export type GachaResultQueryVariables = Exact<{
  gachaOrderId: Scalars['ID']
  gachaTicketId: Scalars['ID']
}>

export type GachaResultQuery = {
  user?: Maybe<
    Pick<User, 'id'> & {
      gachaOrder?: Maybe<GachaOrderDetailFragment>
      gachaTicket?: Maybe<UserGachaTicketConnectionFragment>
      customProfile?: Maybe<CustomProfileConnectionFragment>
    }
  >
  gachaTicket?: Maybe<GachaTicketConnectionFragment>
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    'prepaidPointId' | 'balance' | 'formattedPrepaidPointBarance'
  >
}

export type GachaPortfolioQueryVariables = Exact<{
  id: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type GachaPortfolioQuery = {
  portfolio?: Maybe<PortfolioConnectionFragment>
}

export type FollowerDetailFragment = Pick<Follower, 'id'> & {
  artist: Pick<
    Artist,
    'id' | 'accountId' | 'formattedAccountId' | 'displayName'
  > & { avatar?: Maybe<Pick<Avatar, 'uri'>> }
}

export type FolloweesConnectionFragment = {
  edges: Array<{ node: FollowerDetailFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type GetUserFolloweesQueryVariables = Exact<{ [key: string]: never }>

export type GetUserFolloweesQuery = { user?: Maybe<CurrentUserFragment> }

export type GlobalCollectionsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  pageInput?: Maybe<PageInput>
}>

export type GlobalCollectionsQuery = {
  ownedUserCollections: {
    edges: Array<{ node: UserCollectionBoxFragment }>
    pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
  }
  user?: Maybe<CurrentUserFragment>
}

export type FetchNewGlobalCollectionsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>
  pageInput?: Maybe<PageInput>
}>

export type FetchNewGlobalCollectionsQuery = {
  ownedUserCollections: {
    edges: Array<{ node: UserCollectionBoxFragment }>
    pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
  }
}

export type CampaignsConnectionFragment = {
  edges: Array<{ node: CampaignBoxFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type GlobalEventsQueryVariables = Exact<{
  typeFilter?: Maybe<Array<MissionType> | MissionType>
  missionsPageInput?: Maybe<PageInput>
  campaignsPageInput?: Maybe<PageInput>
}>

export type GlobalEventsQuery = {
  organization?: Maybe<
    Pick<Organization, 'id'> & {
      campaigns: CampaignsConnectionFragment
      entryCampaignRankings?: Maybe<
        Array<Maybe<EntryCampaignRankingConnectionFragment>>
      >
      missions: MissionsConnectionFragment
    }
  >
  user?: Maybe<CurrentUserFragment>
}

export type FetchNewGlobalEventsQueryVariables = Exact<{
  typeFilter?: Maybe<Array<MissionType> | MissionType>
  missionsPageInput?: Maybe<PageInput>
  campaignsPageInput?: Maybe<PageInput>
}>

export type FetchNewGlobalEventsQuery = {
  organization?: Maybe<
    Pick<Organization, 'id'> & {
      campaigns: CampaignsConnectionFragment
      missions: MissionsConnectionFragment
    }
  >
}

export type CurrentUserFragment = Pick<
  User,
  | 'id'
  | 'displayName'
  | 'blockedUsers'
  | 'hasWaitingPaymentItems'
  | 'hasUnappliedDeliveryItems'
  | 'hasWaitingResaleRafflePaymentItems'
  | 'hasManagementTask'
  | 'unreadNotificationCount'
  | 'isMembershipPersonalInformationRequired'
> & {
  avatar?: Maybe<Pick<Avatar, 'mediumUri' | 'uri'>>
  organizationPatrons: Array<MemberArtistFragment>
  organizationFollowees: FollowArtistsFragment
}

export type FeedConnectionFragment = {
  edges: Array<{
    node: Pick<Feed, 'id' | 'type'> & {
      data?: Maybe<CommentBoxFragment | PostBoxFragment>
    }
  }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type EntryCampaignRankingConnectionFragment = Pick<
  EntryCampaignRanking,
  'id'
> & {
  thumbnail: Pick<Image, 'uri' | 'smallUri'>
  myRank:
    | Pick<
        EntryCampaignArtistMyRank,
        'artistId' | 'rank' | 'displayName' | 'point'
      >
    | Pick<EntryCampaignUserMyRank, 'rank' | 'displayName' | 'point'>
}

export type FollowArtistGachasConnectionFragment = {
  edges: Array<{
    node: Pick<
      Gacha,
      | 'id'
      | 'name'
      | 'description'
      | 'endAt'
      | 'formattedEndAt'
      | 'artistId'
      | 'isPremium'
    > & {
      thumbnail: Pick<Image, 'uri' | 'smallUri'>
      gpsData?: Maybe<
        Pick<
          GpsData,
          'approximateAddress' | 'latitude' | 'longitude' | 'radius'
        >
      >
      artist?: Maybe<Pick<Artist, 'id'>>
    }
  }>
}

export type FollowArtistMissionsConnectionFragment = {
  edges: Array<{ node: MissionBoxFragment }>
}

export type BannerConnectionFragment = Pick<
  OrganizationBanner,
  'text' | 'order'
> & {
  thumbnail: Pick<Image, 'uri' | 'mediumUri' | 'smallUri'>
  link?: Maybe<Pick<BannerLink, 'innerPath' | 'externalUrl'>>
}

export type GlobalHomeOrganizationArtistRankingsConnectionFragment = {
  edges: Array<{
    node: {
      latestRankingPeriod?: Maybe<
        {
          myRanking?: Maybe<RankingUserWithoutIdConnectionFragment>
          rankings?: Maybe<{
            edges: Array<{ node: RankingUserConnectionFragment }>
            pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
          }>
        } & RankingPeriodBaseConnectionFragment
      >
    } & RankingBaseConnectionFragment
  }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type FollowArtistsFragment = {
  edges: Array<{
    node: Pick<Follower, 'id'> & {
      artist: Pick<Artist, 'id' | 'displayName' | 'totalUnreadMentionCount'> & {
        avatar?: Maybe<
          Pick<Avatar, 'uri' | 'compressedUri' | 'mediumUri' | 'smallUri'>
        >
      }
    }
  }>
}

export type MemberArtistFragment = {
  artist: Pick<Artist, 'id' | 'displayName'> & {
    avatar?: Maybe<
      Pick<Avatar, 'uri' | 'compressedUri' | 'mediumUri' | 'smallUri'>
    >
  }
}

export type GlobalEventFragment = Pick<
  Event,
  | 'id'
  | 'title'
  | 'startedAt'
  | 'endedAt'
  | 'dateRange'
  | 'aboutDateRange'
  | 'dateArray'
> & {
  thumbnail?: Maybe<Pick<Image, 'mediumUri' | 'uri'>>
  preSales: EventPreSaleConnectionFragment
  location?: Maybe<
    Pick<
      GpsData,
      'approximateAddress' | 'latitude' | 'longitude' | 'radius' | 'mapUri'
    >
  >
}

export type GlobalHomeQueryVariables = Exact<{
  pageInput?: Maybe<PageInputDate>
  type?: Maybe<FeedType>
  followArtistGachasPageInput?: Maybe<PageInputDate>
  followArtistMissionsPageInput?: Maybe<PageInput>
  missionsTypeFilter?: Maybe<Array<MissionType> | MissionType>
}>

export type GlobalHomeQuery = {
  user?: Maybe<{ feeds: FeedConnectionFragment } & CurrentUserFragment>
  organizationOfficialNotifications: OrganizationNotificationConnectionFragment
  organization?: Maybe<
    Pick<Organization, 'id' | 'topDisplayArtistIds'> & {
      banners?: Maybe<Array<BannerConnectionFragment>>
      entryCampaignRankings?: Maybe<
        Array<Maybe<EntryCampaignRankingConnectionFragment>>
      >
      followArtistGachas: FollowArtistGachasConnectionFragment
      followArtistMissions: FollowArtistMissionsConnectionFragment
    }
  >
  prepaidPointBalance: Pick<PrepaidPointBalance, 'prepaidPointId' | 'balance'>
  organizationArtistRankings: GlobalHomeOrganizationArtistRankingsConnectionFragment
  events: {
    edges: Array<{ node: GlobalEventFragment }>
    pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
  }
}

export type GlobalHomeForGuestQueryVariables = Exact<{ [key: string]: never }>

export type GlobalHomeForGuestQuery = {
  organization?: Maybe<
    Pick<Organization, 'id' | 'topDisplayArtistIds'> & {
      banners?: Maybe<Array<BannerConnectionFragment>>
    }
  >
  organizationOfficialNotifications: OrganizationNotificationConnectionFragment
  events: {
    edges: Array<{ node: GlobalEventFragment }>
    pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
  }
}

export type LikeCommentMutationVariables = Exact<{
  likeCommentId: Scalars['ID']
}>

export type LikeCommentMutation = {
  likeComment: Pick<Comment, 'likeCount' | 'isLike' | 'id'>
}

export type UnlikeCommentMutationVariables = Exact<{
  unlikeCommentId: Scalars['ID']
}>

export type UnlikeCommentMutation = {
  unlikeComment: Pick<Comment, 'likeCount' | 'isLike' | 'id'>
}

export type RemoveOauthTokenMutationVariables = Exact<{
  providerId: ProviderType
}>

export type RemoveOauthTokenMutation = {
  removeOauthToken: Pick<Artist, 'id'> | Pick<User, 'id'>
}

export type ItemCommentQueryVariables = Exact<{
  itemId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type ItemCommentQuery = {
  item?: Maybe<
    Pick<Item, 'id' | 'commentCount'> & {
      issuedArtist?: Maybe<Pick<Artist, 'id'>>
      comments: CommentsConnectionFragment
    }
  >
  user?: Maybe<
    Pick<
      User,
      'id' | 'email' | 'displayName' | 'accountId' | 'formattedAccountId'
    > & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> }
  >
}

export type CreateItemCommentMutationVariables = Exact<{
  id: Scalars['String']
  inputs: CommunityCommentInput
}>

export type CreateItemCommentMutation = {
  createItemComment: CommentBoxFragment
}

export type DeleteItemCommentMutationVariables = Exact<{
  id: Scalars['String']
  commentId: Scalars['String']
}>

export type DeleteItemCommentMutation = {
  deleteItemComment: CommentBoxFragment
}

export type LikeItemCommentMutationVariables = Exact<{
  inputs: CommunityCommentLikeUnlikeInput
}>

export type LikeItemCommentMutation = {
  likeItemComment: Pick<Comment, 'id' | 'isLike' | 'likeCount'>
}

export type UnlikeItemCommentMutationVariables = Exact<{
  inputs: CommunityCommentLikeUnlikeInput
}>

export type UnlikeItemCommentMutation = {
  unlikeItemComment: Pick<Comment, 'id' | 'isLike' | 'likeCount'>
}

export type SerialItemOwnersFragment = {
  edges: Array<{
    node: Pick<
      ItemSerial,
      | 'id'
      | 'itemId'
      | 'serialNumber'
      | 'formattedSerialNumber'
      | 'createdAt'
      | 'formattedCreatedAt'
      | 'updatedAt'
      | 'obtainedDate'
      | 'formattedObtainedDate'
      | 'formattedObtainedDateTime'
      | 'formattedAssignedNumber'
      | 'isNft'
    > & {
      sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
      ticketStatus?: Maybe<
        Pick<TicketStatus, 'isUsed' | 'usedAt' | 'formattedUsedAt'>
      >
      user?: Maybe<
        Pick<User, 'id' | 'displayName' | 'fullName' | 'phoneNumber'> & {
          avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
        }
      >
      authentication?: Maybe<Pick<SerialAuthentication, 'authenticatedAt'>>
    }
  }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type PortfolioSerialsFragment = Pick<UserItem, 'id'> & {
  serials: Array<Pick<UserItemSerial, 'formattedSerialNumber'>>
}

export type ItemInformationQueryVariables = Exact<{
  id: Scalars['ID']
  userId?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInput>
  skuId?: Maybe<Scalars['String']>
}>

export type ItemInformationQuery = {
  item?: Maybe<{ serials: SerialItemOwnersFragment } & ItemDescriptionFragment>
  user?: Maybe<UserInfoFragment>
  userLoggedIn?: Maybe<Pick<User, 'id'>>
}

export type PortfolioDetailQueryVariables = Exact<{
  id: Scalars['ID']
  userId?: Maybe<Scalars['String']>
  skuId?: Maybe<Scalars['String']>
}>

export type PortfolioDetailQuery = {
  portfolio?: Maybe<PortfolioConnectionFragment>
}

export type RelatedMissionDetailFragment = Pick<
  Mission,
  | 'description'
  | 'id'
  | 'type'
  | 'title'
  | 'isAccomplished'
  | 'formattedExpiredAt'
  | 'label'
  | 'expiredAt'
> & {
  reward: { gachaTicket?: Maybe<Pick<QuizGachaTicket, 'count'>> }
  content: {
    image?: Maybe<Pick<Image, 'smallUri' | 'uri'>>
    items: Array<
      Pick<ItemCollectContentItem, 'itemId' | 'requiredCount'> & {
        item: MultipleItemsMissionFragment
      }
    >
  }
}

export type ItemMissionsQueryVariables = Exact<{
  itemId: Scalars['ID']
}>

export type ItemMissionsQuery = {
  item?: Maybe<
    Pick<Item, 'id'> & {
      relatedMissions?: Maybe<Array<RelatedMissionDetailFragment>>
    }
  >
}

export type ItemOrdersConnectionFragment = {
  edges: Array<{
    node: Pick<
      ItemOrder,
      'id' | 'createdAt' | 'formattedCreatedAt' | 'price'
    > & {
      item?: Maybe<
        Pick<Item, 'id' | 'title' | 'hideSalesNumber'> & {
          thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
        }
      >
      serials?: Maybe<
        Array<
          Pick<ItemSerial, 'id' | 'skuId'> & {
            sku?: Maybe<
              Pick<SkuUnit, 'id'> & {
                thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
              }
            >
          }
        >
      >
    }
  }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type ItemOrdersQueryVariables = Exact<{
  pageInput?: Maybe<PageInputDate>
}>

export type ItemOrdersQuery = { userItemOrders: ItemOrdersConnectionFragment }

export type LiveDetailQueryVariables = Exact<{
  id: Scalars['String']
  pageInput?: Maybe<PageInput>
  platform: Scalars['String']
}>

export type LiveDetailQuery = {
  prepaidPointBalance: Pick<PrepaidPointBalance, 'prepaidPointId' | 'balance'>
  purchasablePrepaidPoints: PurchasablePrepaidPointConnectionFragment
  paymentSource?: Maybe<PrepaidPointsPaymentSourceFragment>
  user?: Maybe<Pick<User, 'id' | 'isMembershipPersonalInformationRequired'>>
  liveStream: { __typename: 'LiveStream' } & Pick<
    LiveStream,
    | 'id'
    | 'artistId'
    | 'channelName'
    | 'token'
    | 'isPremium'
    | 'status'
    | 'isAccessible'
  > & {
      artist: Pick<Artist, 'id' | 'displayName'> & {
        avatar?: Maybe<Pick<Avatar, 'uri' | 'mediumUri'>>
        patron?: Maybe<Pick<Patron, 'id' | 'isPersonalInformationRequired'>>
      }
    }
}

export type GetOlderCommentsQueryVariables = Exact<{
  id: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type GetOlderCommentsQuery = {
  liveStreamComments: {
    edges: Array<{ node: CommentBoxWithoutRepliesFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
}

export type ArtistCurrentLiveStreamQueryVariables = Exact<{
  artistId: Scalars['ID']
}>

export type ArtistCurrentLiveStreamQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      currentLive?: Maybe<Pick<LiveStream, 'id' | 'status'>>
    }
  >
}

export type LikeLiveStreamMutationVariables = Exact<{
  id: Scalars['String']
}>

export type LikeLiveStreamMutation = {
  likeLiveStream: Pick<LiveStream, 'id' | 'likeCount'>
}

export type CreateLiveStreamCommentMutationVariables = Exact<{
  id: Scalars['String']
  inputs: CommunityCommentInput
}>

export type CreateLiveStreamCommentMutation = {
  createLiveStreamComment: CommentBoxWithoutRepliesFragment
}

export type LiveStreamLikedSubscriptionVariables = Exact<{
  id: Scalars['ID']
}>

export type LiveStreamLikedSubscription = {
  liveStreamLiked: Pick<Count, 'count'>
}

export type LiveStreamSubscribedSubscriptionVariables = Exact<{
  id: Scalars['ID']
}>

export type LiveStreamSubscribedSubscription = {
  liveStreamSubscribed: Pick<Count, 'count'>
}

export type LiveStreamCommentCreatedSubscriptionVariables = Exact<{
  id: Scalars['ID']
}>

export type LiveStreamCommentCreatedSubscription = {
  liveStreamCommentCreated?: Maybe<CommentBoxWithoutRepliesFragment>
}

export type UserProfileFragment = Pick<
  User,
  'id' | 'accountId' | 'formattedAccountId' | 'displayName' | 'introduction'
> & { avatar?: Maybe<Pick<Avatar, 'uri' | 'mediumUri' | 'smallUri'>> }

export type LoginQueryVariables = Exact<{ [key: string]: never }>

export type LoginQuery = {
  user?: Maybe<
    Pick<
      User,
      'id' | 'isMembershipPersonalInformationRequired' | 'formattedPhoneNumber'
    > &
      UserSettingFragment &
      UserDeliveryInfoFragment
  >
}

export type AddRegistrationTokenMutationVariables = Exact<{
  token: Scalars['String']
}>

export type AddRegistrationTokenMutation = {
  addRegistrationTokenForOrganization?: Maybe<Pick<UserOrganization, 'id'>>
}

export type SaveOauthTokenMutationVariables = Exact<{
  inputs: SaveOauthTokenInput
}>

export type SaveOauthTokenMutation = {
  saveOauthToken: Pick<Artist, 'id'> | Pick<User, 'id'>
}

export type DeleteExpiredCartsMutationVariables = Exact<{
  [key: string]: never
}>

export type DeleteExpiredCartsMutation = {
  deleteExpiredCarts: Array<UserCartFragment>
}

export type PreSaleLotteryApplicantDetailFragment = Pick<
  PreSaleLotteryApplicant,
  | 'id'
  | 'isPaymentError'
  | 'status'
  | 'appliedAt'
  | 'choices'
  | 'paymentMethod'
  | 'purchaseCountChoices'
  | 'resultNoticedAt'
  | 'winChoiceNumber'
> & {
  preSale: Pick<PreSale, 'id' | 'title' | 'description' | 'note' | 'type'> & {
    event: Pick<Event, 'id' | 'title'>
    lottery?: Maybe<
      Pick<
        PreSaleLottery,
        | 'applicationStartedAt'
        | 'applicationEndedAt'
        | 'maxChoice'
        | 'personalInformationRequirements'
        | 'purchasableCount'
        | 'purchasableStartedAt'
        | 'purchasableEndedAt'
        | 'resultNoticeStatus'
        | 'resultNoticedAt'
      >
    >
    items: Array<
      Pick<Item, 'type'> & {
        fees?: Maybe<{
          collections?: Maybe<
            Array<
              Maybe<
                Pick<
                  Fee,
                  | 'fee'
                  | 'feeDescription'
                  | 'feeName'
                  | 'feeType'
                  | 'id'
                  | 'per'
                >
              >
            >
          >
        }>
      }
    >
  }
  cartTransactionOrder?: Maybe<
    Pick<UserCartTransactionOrder, 'id' | 'isKonbini' | 'paymentType'> & {
      pendingPurchaseItem?: Maybe<
        Pick<
          PendingPurchaseItem,
          'id' | 'expiredAt' | 'formattedExpiredAt' | 'voucherUrl'
        >
      >
    }
  >
  choiceItemDetails: Array<
    Pick<ChoiceItemDetail, 'itemId' | 'skuId' | 'count'> & {
      item?: Maybe<Pick<Item, 'id' | 'title' | 'price'>>
      skuUnit?: Maybe<Pick<SkuUnit, 'id' | 'unitName' | 'price'>>
      fees: {
        collections?: Maybe<Array<Maybe<Pick<Fee, 'fee' | 'feeName'>>>>
        konbini?: Maybe<Pick<Fee, 'fee' | 'feeName'>>
        shipping?: Maybe<Pick<Fee, 'fee' | 'feeName'>>
      }
    }
  >
}

export type PreSaleLotteryApplicantQueryVariables = Exact<{
  preSaleId: Scalars['String']
}>

export type PreSaleLotteryApplicantQuery = {
  preSaleLotteryApplicant?: Maybe<PreSaleLotteryApplicantDetailFragment>
  paymentSource?: Maybe<PaymentCreateDetailFragment>
}

export type CancelPreSaleLotteryMutationVariables = Exact<{
  inputs: CancelPreSaleLotteryInput
}>

export type CancelPreSaleLotteryMutation = {
  cancelPreSaleLottery: PreSaleLotteryApplicantDetailFragment
}

export type PreSaleLotteryApplicantConfirmationFragment = Pick<
  PreSaleLotteryApplicant,
  'id' | 'winChoiceNumber'
> & {
  choiceItemDetails: Array<
    Pick<ChoiceItemDetail, 'count'> & {
      item?: Maybe<Pick<Item, 'id' | 'price'>>
      skuUnit?: Maybe<Pick<SkuUnit, 'id' | 'price'>>
      fees: {
        collections?: Maybe<Array<Maybe<Pick<Fee, 'fee' | 'feeName'>>>>
        konbini?: Maybe<Pick<Fee, 'fee' | 'feeName'>>
        shipping?: Maybe<Pick<Fee, 'fee' | 'feeName'>>
      }
    }
  >
}

export type LotteryApplicationDetailPaymentPaymentConfirmationQueryVariables =
  Exact<{
    preSaleId: Scalars['String']
  }>

export type LotteryApplicationDetailPaymentPaymentConfirmationQuery = {
  preSaleLotteryApplicant?: Maybe<PreSaleLotteryApplicantConfirmationFragment>
  paymentSource?: Maybe<PaymentDetailFragment>
}

export type PayPreSaleLotteryMutationVariables = Exact<{
  inputs: PayPreSaleLotteryInput
}>

export type PayPreSaleLotteryMutation = {
  payPreSaleLottery: Pick<UserCartTransactionOrder, 'id'>
}

export type PreSaleLotteryApplicantsFragment = {
  edges: Array<{ node: PreSaleLotteryApplicantFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type PreSaleLotteryApplicantFragment = Pick<
  PreSaleLotteryApplicant,
  | 'id'
  | 'preSaleId'
  | 'isPaymentError'
  | 'status'
  | 'appliedAt'
  | 'paymentMethod'
  | 'purchaseCountChoices'
  | 'resultNoticedAt'
  | 'winChoiceNumber'
> & {
  preSale: Pick<PreSale, 'id' | 'title' | 'type'> & {
    lottery?: Maybe<Pick<PreSaleLottery, 'resultNoticedAt'>>
    event: Pick<Event, 'id' | 'title'>
  }
  choiceItemDetails: Array<
    Pick<ChoiceItemDetail, 'itemId' | 'skuId' | 'count'> & {
      item?: Maybe<Pick<Item, 'id' | 'title' | 'price'>>
      skuUnit?: Maybe<Pick<SkuUnit, 'id' | 'unitName' | 'price'>>
    }
  >
}

export type PreSaleLotteryApplicantsQueryVariables = Exact<{
  filter?: Maybe<Array<Scalars['String']> | Scalars['String']>
  pageInput?: Maybe<PageInput>
}>

export type PreSaleLotteryApplicantsQuery = {
  preSaleLotteryApplicants: PreSaleLotteryApplicantsFragment
}

export type PreSaleLotteryConfirmationFragment = Pick<
  PreSale,
  'id' | 'title' | 'type'
> & {
  lottery?: Maybe<
    Pick<
      PreSaleLottery,
      | 'applicationStartedAt'
      | 'applicationEndedAt'
      | 'purchasableStartedAt'
      | 'purchasableEndedAt'
      | 'resultNoticedAt'
      | 'formattedApplicationStartedAt'
      | 'formattedApplicationEndedAt'
      | 'formattedPurchasableStartedAt'
      | 'formattedPurchasableEndedAt'
      | 'formattedResultNoticedAt'
      | 'resultNoticeStatus'
    >
  >
  items: Array<
    Pick<Item, 'id' | 'title' | 'price' | 'formattedPrice'> & {
      metadata?: Maybe<{
        ticket?: Maybe<
          Pick<
            ItemMetadataDetailTicket,
            'isThroughTicket' | 'description' | 'note'
          >
        >
      }>
      skuSettings?: Maybe<{
        units: Array<
          Pick<SkuUnit, 'id' | 'unitName' | 'price' | 'formattedPrice'>
        >
      }>
      fees?: Maybe<{
        collections?: Maybe<Array<Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>>>
        konbini?: Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>
        shipping?: Maybe<Pick<Fee, 'fee' | 'feeName' | 'per'>>
      }>
    }
  >
}

export type LotteryConfirmationQueryVariables = Exact<{
  id: Scalars['ID']
  clientId?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}>

export type LotteryConfirmationQuery = {
  preSale: PreSaleLotteryConfirmationFragment
  paymentSource?: Maybe<PaymentDetailFragment>
}

export type ApplyPreSaleLotteryMutationVariables = Exact<{
  inputs: ApplyPreSaleLotteryInput
  clientId?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}>

export type ApplyPreSaleLotteryMutation = {
  applyPreSaleLottery: Pick<PreSaleLotteryApplicant, 'id'>
}

export type LotteryDeliveryInfoQueryVariables = Exact<{ [key: string]: never }>

export type LotteryDeliveryInfoQuery = {
  user?: Maybe<UserDeliveryDetailFragment>
}

export type PreSaleLotteryDetailFragment = Pick<
  PreSale,
  | 'id'
  | 'title'
  | 'type'
  | 'description'
  | 'startedAt'
  | 'endedAt'
  | 'note'
  | 'paymentMethods'
  | 'preSaleStatus'
  | 'isPremium'
  | 'isAccessible'
  | 'planId'
  | 'itemIds'
> & {
  artist: Pick<Artist, 'id'> & {
    subscription?: Maybe<{
      products?: Maybe<
        Array<
          Pick<
            MembershipSubscriptionProduct,
            'productId' | 'title' | 'content'
          > & {
            plans: Array<Pick<MembershipSubscriptionPlan, 'planId' | 'amount'>>
          }
        >
      >
    }>
    patron?: Maybe<
      Pick<Patron, 'id' | 'updatedAt' | 'formattedUpdatedAt'> & {
        subscription?: Maybe<Pick<PatronSubsription, 'planId' | 'amount'>>
      }
    >
  }
  event: Pick<Event, 'id' | 'title' | 'note' | 'status'>
  lottery?: Maybe<
    Pick<
      PreSaleLottery,
      | 'applicationStartedAt'
      | 'applicationEndedAt'
      | 'purchasableStartedAt'
      | 'purchasableEndedAt'
      | 'resultNoticedAt'
      | 'formattedApplicationStartedAt'
      | 'formattedApplicationEndedAt'
      | 'formattedPurchasableStartedAt'
      | 'formattedPurchasableEndedAt'
      | 'formattedResultNoticedAt'
      | 'resultNoticeStatus'
      | 'purchasableCount'
      | 'maxChoice'
      | 'personalInformationRequirements'
    >
  >
  items: Array<
    Pick<
      Item,
      | 'id'
      | 'title'
      | 'price'
      | 'formattedPrice'
      | 'issuedNumber'
      | 'pendingPurchaseNumber'
      | 'purchasedNumber'
    > & {
      metadata?: Maybe<{
        ticket?: Maybe<Pick<ItemMetadataDetailTicket, 'isThroughTicket'>>
      }>
      skuSettings?: Maybe<{
        units: Array<
          Pick<
            SkuUnit,
            'id' | 'order' | 'unitName' | 'price' | 'formattedPrice'
          >
        >
      }>
      fees?: Maybe<{
        collections?: Maybe<
          Array<
            Maybe<
              Pick<Fee, 'fee' | 'feeName' | 'per' | 'feeType' | 'formattedFee'>
            >
          >
        >
        konbini?: Maybe<
          Pick<Fee, 'fee' | 'feeName' | 'per' | 'feeType' | 'formattedFee'>
        >
        shipping?: Maybe<
          Pick<Fee, 'fee' | 'feeName' | 'per' | 'feeType' | 'formattedFee'>
        >
      }>
    }
  >
}

export type UserDeliveryDetailFragment = Pick<
  User,
  | 'id'
  | 'phoneNumber'
  | 'formattedPhoneNumber'
  | 'birthday'
  | 'fullName'
  | 'gender'
  | 'isPhoneNumberVerified'
> & {
  address?: Maybe<
    Pick<UserAddress, 'postalCode' | 'prefecture' | 'city' | 'line1' | 'line2'>
  >
}

export type PreSaleLotteryDetailQueryVariables = Exact<{
  id: Scalars['ID']
  preSaleId: Scalars['String']
  clientId?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}>

export type PreSaleLotteryDetailQuery = {
  preSale: PreSaleLotteryDetailFragment
  preSaleLotteryApplicant?: Maybe<
    Pick<PreSaleLotteryApplicant, 'id' | 'status'>
  >
  paymentSource?: Maybe<PaymentDetailFragment>
  user?: Maybe<UserDeliveryDetailFragment>
}

export type PreSaleLotteryDetailForGuestQueryVariables = Exact<{
  id: Scalars['ID']
  clientId?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}>

export type PreSaleLotteryDetailForGuestQuery = {
  preSale: PreSaleLotteryDetailFragment
}

export type LotteryUserInfoQueryVariables = Exact<{
  id: Scalars['ID']
  clientId?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}>

export type LotteryUserInfoQuery = {
  preSale: Pick<PreSale, 'id'> & {
    lottery?: Maybe<Pick<PreSaleLottery, 'personalInformationRequirements'>>
    firstComeFirstServed?: Maybe<
      Pick<PreSaleFirstComeFirstServed, 'personalInformationRequirements'>
    >
  }
  user?: Maybe<UserDeliveryDetailFragment>
}

export type SignupAsArtistMutationVariables = Exact<{
  inputs: SignupAsArtistInput
}>

export type SignupAsArtistMutation = {
  signupAsArtist: Pick<
    Artist,
    'id' | 'accountId' | 'formattedAccountId' | 'displayName'
  > & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> }
}

export type ManagementQueryVariables = Exact<{ [key: string]: never }>

export type ManagementQuery = { user?: Maybe<CurrentUserFragment> }

export type MembershipsQueryVariables = Exact<{ [key: string]: never }>

export type MembershipsQuery = {
  user?: Maybe<
    Pick<User, 'id' | 'isMembershipPersonalInformationRequired'> & {
      organizationPatrons: Array<PatronDetailFragment>
    }
  >
}

export type StopSubscriptionMutationVariables = Exact<{
  artistId: Scalars['ID']
}>

export type StopSubscriptionMutation = {
  stopSubscription: PatronDetailFragment
}

export type ResumeSubscriptionPlanMutationVariables = Exact<{
  artistId: Scalars['String']
}>

export type ResumeSubscriptionPlanMutation = {
  resumeSubscriptionPlan: PatronDetailFragment
}

export type MissionRewardConnectionFragment = Pick<
  Mission,
  'artistId' | 'noAnswer'
> & {
  reward: {
    gachaTicket?: Maybe<
      Pick<QuizGachaTicket, 'count'> & {
        gachaTicket: {
          thumbnails?: Maybe<{
            reward?: Maybe<
              Pick<Image, 'uri' | 'smallUri' | 'mediumUri' | 'compressedUri'>
            >
          }>
        }
      }
    >
    item?: Maybe<{
      item?: Maybe<
        Pick<Item, 'id' | 'thumbnailType'> & {
          thumbnail: {
            front?: Maybe<
              Pick<Resource, 'uri' | 'smallUri' | 'mediumUri' | 'compressedUri'>
            >
          }
        }
      >
    }>
  }
}

export type MissionAccomplishedQueryVariables = Exact<{
  missionId: Scalars['ID']
}>

export type MissionAccomplishedQuery = {
  mission?: Maybe<Pick<Mission, 'id'> & MissionRewardConnectionFragment>
}

export type MissionDetailFragment = Pick<
  Mission,
  'commentCount' | 'noAnswer'
> & {
  content:
    | {
        image?: Maybe<Pick<Image, 'smallUri' | 'uri'>>
        items: Array<
          Pick<
            ItemCollectContentItem,
            'itemId' | 'requiredCount' | 'ownedCount'
          > & { item: MultipleItemsMissionFragment }
        >
      }
    | (Pick<QuizContent, 'answer'> & {
        image?: Maybe<Pick<Image, 'uri' | 'smallUri' | 'mediumUri'>>
      })
} & MissionBoxFragment

export type QuizResultConnectionFragment = Pick<
  QuizResult,
  'missionId' | 'correct'
> & { mission: MissionDetailFragment }

export type ItemCollectResultConnectionFragment = Pick<
  ItemCollectMissionResult,
  'missionId' | 'correct'
> & {
  lackItems?: Maybe<
    Array<
      Pick<ItemCollectContentItem, 'itemId' | 'requiredCount'> & {
        item: Pick<Item, 'id' | 'title' | 'hideSalesNumber'>
      }
    >
  >
  mission: MissionDetailFragment
}

export type MissionDetailQueryVariables = Exact<{
  missionId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type MissionDetailQuery = {
  mission?: Maybe<
    {
      reward: {
        item?: Maybe<{
          item?: Maybe<
            Pick<
              Item,
              | 'id'
              | 'title'
              | 'description'
              | 'type'
              | 'hideSalesNumber'
              | 'issuedNumber'
            > & {
              rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
              rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
              thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
            }
          >
        }>
        gachaTicket?: Maybe<
          Pick<QuizGachaTicket, 'count'> & {
            gachaTicket: Pick<GachaTicket, 'id' | 'name' | 'description'> & {
              thumbnails?: Maybe<{ standard?: Maybe<Pick<Image, 'uri'>> }>
            }
          }
        >
      }
      comments: CommentsConnectionFragment
    } & MissionDetailFragment
  >
  user?: Maybe<
    Pick<
      User,
      'id' | 'accountId' | 'formattedAccountId' | 'email' | 'displayName'
    > & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> }
  >
}

export type AnswerQuizMissionMutationVariables = Exact<{
  answerQuizMissionMissionId: Scalars['String']
  answerQuizMissionAnswer: Scalars['String']
}>

export type AnswerQuizMissionMutation = {
  answerQuizMission: QuizResultConnectionFragment
}

export type AnswerItemCollectMissionMutationVariables = Exact<{
  missionId: Scalars['String']
}>

export type AnswerItemCollectMissionMutation = {
  answerItemCollectMission: Pick<
    ItemCollectMissionResult,
    'missionId' | 'correct'
  > & {
    lackItems?: Maybe<
      Array<
        Pick<ItemCollectContentItem, 'itemId' | 'requiredCount'> & {
          item: Pick<Item, 'id' | 'title' | 'hideSalesNumber'>
        }
      >
    >
    mission: Pick<Mission, 'id' | 'isAccomplished'> & {
      artist: Pick<Artist, 'id'>
    }
  }
}

export type CreateMissionCommentMutationVariables = Exact<{
  id: Scalars['String']
  inputs: CommunityCommentInput
}>

export type CreateMissionCommentMutation = {
  createMissionComment: CommentBoxFragment
}

export type LikeMissionCommentMutationVariables = Exact<{
  inputs: CommunityCommentLikeUnlikeInput
}>

export type LikeMissionCommentMutation = {
  likeMissionComment: Pick<Comment, 'id' | 'isLike' | 'likeCount'>
}

export type UnlikeMissionCommentMutationVariables = Exact<{
  inputs: CommunityCommentLikeUnlikeInput
}>

export type UnlikeMissionCommentMutation = {
  unlikeMissionComment: Pick<Comment, 'id' | 'isLike' | 'likeCount'>
}

export type DeleteMissionCommentMutationVariables = Exact<{
  id: Scalars['String']
  commentId: Scalars['String']
}>

export type DeleteMissionCommentMutation = {
  deleteMissionComment: CommentBoxFragment
}

export type PickupUserFragment = Pick<
  PickupUser,
  | 'id'
  | 'userId'
  | 'isCompleted'
  | 'completedAt'
  | 'formattedCompletedAt'
  | 'isExpired'
  | 'pickupTime'
  | 'formattedPickupTime'
  | 'formattedPickupStartEndTime'
  | 'eventId'
  | 'collectionId'
  | 'itemsString'
  | 'userItemIds'
> & {
  pickupOrder: Array<
    Pick<PickupOrder, 'itemId' | 'title' | 'serialIds' | 'count'> & {
      serials: Array<Pick<ItemSerial, 'id' | 'skuId'>>
    }
  >
  pickupRemainingTime?: Maybe<
    Pick<PickupRemainingTime, 'hours' | 'minutes' | 'expiring'>
  >
  userItems: Array<
    Pick<UserItem, 'id' | 'number'> & {
      item: Pick<
        Item,
        'id' | 'title' | 'formattedPrice' | 'paymentType' | 'hideSalesNumber'
      > & {
        thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>> }
        sku?: Maybe<
          Pick<
            SkuUnit,
            | 'id'
            | 'order'
            | 'pendingPurchaseNumber'
            | 'purchasedNumber'
            | 'stock'
            | 'unitName'
            | 'price'
            | 'formattedPrice'
          > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>> }
        >
        skuSettings?: Maybe<
          Pick<SkuSettings, 'keyName'> & {
            units: Array<
              Pick<
                SkuUnit,
                | 'id'
                | 'order'
                | 'pendingPurchaseNumber'
                | 'purchasedNumber'
                | 'stock'
                | 'price'
                | 'formattedPrice'
                | 'unitName'
              > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>> }
            >
          }
        >
      }
      serials: Array<
        Pick<UserItemSerial, 'id'> & {
          serial?: Maybe<
            Pick<ItemSerial, 'id'> & {
              sku?: Maybe<
                Pick<
                  SkuUnit,
                  'id' | 'unitName' | 'price' | 'formattedPrice'
                > & { thumbnail?: Maybe<Pick<Image, 'smallUri'>> }
              >
            }
          >
        }
      >
    }
  >
  event: Pick<
    Event,
    | 'id'
    | 'title'
    | 'startedAt'
    | 'endedAt'
    | 'closeDate'
    | 'dateRange'
    | 'aboutDateRange'
    | 'dateArray'
  > & {
    location?: Maybe<Pick<GpsData, 'mapUri'>>
    thumbnail?: Maybe<Pick<Image, 'smallUri' | 'uri'>>
  }
  eventCollection: Pick<
    EventCollection,
    'id' | 'eventId' | 'collectionId' | 'validMinutes' | 'pickupLocation'
  > & {
    collection: Pick<Collection, 'id' | 'title'> & {
      thumbnail: Pick<CollectionThumbnail, 'uri'>
      artist: Pick<Artist, 'id' | 'displayName'> & {
        avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
      }
    }
    myPickupUsers?: Maybe<
      Array<
        Pick<
          PickupUser,
          'id' | 'userId' | 'pickupTime' | 'formattedPickupStartEndTime'
        > & {
          cartTransactionOrders: Array<CartTransactionOrderDetailFragment>
          userItems: Array<
            Pick<UserItem, 'number'> & {
              item: Pick<Item, 'id' | 'hideSalesNumber'>
            }
          >
          eventCollection: Pick<EventCollection, 'id' | 'validMinutes'>
        }
      >
    >
  }
  ticketItemSerialsInput: Array<Pick<TicketItemSerials, 'itemId' | 'serialIds'>>
  cartTransactionOrders: Array<{
    itemOrders: Array<
      Pick<ItemOrder, 'canceledSerialIds' | 'id' | 'serialIds'> & {
        item?: Maybe<Pick<Item, 'id' | 'title' | 'hideSalesNumber'>>
        serials?: Maybe<Array<Pick<ItemSerial, 'id' | 'skuId'>>>
      }
    >
  }>
  user: Pick<
    User,
    | 'id'
    | 'displayName'
    | 'phoneNumber'
    | 'fullName'
    | 'email'
    | 'formattedPhoneNumber'
  >
}

export type MyOwnedTicketDetailQueryVariables = Exact<{
  pickupUserId: Scalars['ID']
  eventId: Scalars['String']
  collectionId: Scalars['String']
}>

export type MyOwnedTicketDetailQuery = { pickupUser: PickupUserFragment }

export type UseEventPickupTicketsMutationVariables = Exact<{
  inputs: UseEventPickupTicketsInput
}>

export type UseEventPickupTicketsMutation = {
  useEventPickupTickets: Array<
    Pick<ItemSerial, 'id' | 'obtainedDate'> & {
      ticketStatus?: Maybe<TicketStatusDetailFragment>
    }
  >
}

export type PickupUserListItemFragment = Pick<
  PickupUser,
  | 'id'
  | 'userId'
  | 'isCompleted'
  | 'completedAt'
  | 'formattedCompletedAt'
  | 'isExpired'
  | 'pickupTime'
  | 'formattedPickupTime'
  | 'formattedPickupStartEndTime'
  | 'eventId'
  | 'collectionId'
  | 'itemsString'
  | 'userItemIds'
> & {
  pickupOrder: Array<
    Pick<PickupOrder, 'itemId' | 'title' | 'serialIds' | 'count'> & {
      serials: Array<Pick<ItemSerial, 'id' | 'skuId'>>
    }
  >
  pickupRemainingTime?: Maybe<
    Pick<PickupRemainingTime, 'hours' | 'minutes' | 'expiring'>
  >
  event: Pick<Event, 'id' | 'title'>
  eventCollection: Pick<
    EventCollection,
    'id' | 'eventId' | 'collectionId' | 'validMinutes' | 'pickupLocation'
  > & {
    collection: Pick<Collection, 'id' | 'artistId' | 'title'> & {
      thumbnail: Pick<CollectionThumbnail, 'uri'>
    }
  }
  cartTransactionOrders: Array<{
    itemOrders: Array<
      Pick<ItemOrder, 'canceledSerialIds' | 'id' | 'serialIds'> & {
        item?: Maybe<Pick<Item, 'id' | 'title' | 'hideSalesNumber'>>
      }
    >
  }>
}

export type PickupUsersByEventQueryVariables = Exact<{
  eventId: Scalars['ID']
  options: PickupUsersByEventOptions
  pageInput?: Maybe<PageInput>
}>

export type PickupUsersByEventQuery = {
  pickupUsersByEvent: {
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    edges: Array<{ node: PickupUserListItemFragment }>
  }
}

export type PickupUserEventFragment = Pick<
  Event,
  | 'id'
  | 'title'
  | 'startedAt'
  | 'endedAt'
  | 'dateRange'
  | 'aboutDateRange'
  | 'dateArray'
> & {
  thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
  preSales: EventPreSaleConnectionFragment
  eventCollections: {
    edges: Array<{
      node: Pick<EventCollection, 'id' | 'eventId' | 'collectionId'> & {
        myPickupUsers?: Maybe<
          Array<
            Pick<
              PickupUser,
              'id' | 'userId' | 'pickupTime' | 'formattedPickupStartEndTime'
            >
          >
        >
      }
    }>
  }
}

export type PickupUserEventsQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
}>

export type PickupUserEventsQuery = {
  user?: Maybe<CurrentUserFragment>
  pickupUserEvents: {
    edges: Array<{ node: PickupUserEventFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
}

export type UserConnectionFragment = Pick<
  User,
  | 'id'
  | 'accountId'
  | 'formattedAccountId'
  | 'displayName'
  | 'introduction'
  | 'hasWaitingPaymentItems'
  | 'hasUnappliedDeliveryItems'
  | 'hasManagementTask'
  | 'unreadNotificationCount'
  | 'isMembershipPersonalInformationRequired'
  | 'hasWaitingResaleRafflePaymentItems'
> & {
  avatar?: Maybe<
    Pick<Avatar, 'uri' | 'smallUri' | 'mediumUri' | 'compressedUri'>
  >
  customProfile?: Maybe<{
    avatarItem?: Maybe<
      Pick<Item, 'id'> & {
        avatarResources?: Maybe<{
          avatarResource?: Maybe<ResourceFragment>
          voiceResource?: Maybe<ResourceFragment>
        }>
      }
    >
    wallpaperItem?: Maybe<
      Pick<Item, 'id'> & {
        thumbnail: { back?: Maybe<Pick<Resource, 'uri'>> }
        wallpaperResources?: Maybe<{
          wallpaperResource?: Maybe<ResourceFragment>
          audioResource?: Maybe<ResourceFragment>
        }>
      }
    >
  }>
}

export type OrganizationArtistRankingsConnectionFragment = {
  edges: Array<{
    node: {
      latestRankingPeriod?: Maybe<
        {
          myRanking?: Maybe<RankingUserWithoutIdConnectionFragment>
          rankings?: Maybe<{
            edges: Array<{ node: RankingUserConnectionFragment }>
            pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
          }>
        } & RankingPeriodBaseConnectionFragment
      >
    } & RankingBaseConnectionFragment
  }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type MyProfileQueryVariables = Exact<{
  artistId?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInput>
  fetchMyPreSaleEvents: Scalars['Boolean']
  isStandalone: Scalars['Boolean']
}>

export type MyProfileQuery = {
  user?: Maybe<
    { organizationFollowees: FollowArtistsFragment } & UserConnectionFragment
  >
  artist?: Maybe<
    { currentLive?: Maybe<Pick<LiveStream, 'id'>> } & ArtistBottomTabFragment
  >
  prepaidPointBalance?: Maybe<
    Pick<PrepaidPointBalance, 'prepaidPointId' | 'balance'>
  >
  organizationArtistRankings?: Maybe<OrganizationArtistRankingsConnectionFragment>
  myPreSaleEvents: MyPreSaleEventConnectionFragment
}

export type MyProfileEditQueryVariables = Exact<{ [key: string]: never }>

export type MyProfileEditQuery = { user?: Maybe<UserConnectionFragment> }

export type UpdateMyProfileEditMutationVariables = Exact<{
  updateUserProfileInputs: UpdateProfileInput
}>

export type UpdateMyProfileEditMutation = {
  updateUserProfile: UserConnectionFragment
}

export type RankingBaseConnectionFragment = Pick<
  Ranking,
  'id' | 'artistId' | 'createdAt' | 'updatedAt' | 'status'
> & { artist: ArtistBottomTabFragment }

export type RankingPeriodBaseConnectionFragment = Pick<
  RankingPeriod,
  | 'id'
  | 'type'
  | 'periodType'
  | 'period'
  | 'totalUsers'
  | 'rankingId'
  | 'createdAt'
>

export type RankingUserConnectionFragment = Pick<RankingUser, 'id'> &
  RankingUserWithoutIdConnectionFragment

export type RankingUserWithoutIdConnectionFragment = Pick<
  RankingUser,
  'point' | 'rank' | 'previousRank' | 'userId' | 'rankingId' | 'periodId'
> & {
  user?: Maybe<
    Pick<User, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'smallUri' | 'uri'>>
    }
  >
}

export type MyRankingQueryVariables = Exact<{
  periodType: RankingPeriodType
  pageInput?: Maybe<PageInput>
}>

export type MyRankingQuery = {
  organizationArtistRankings: {
    pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    edges: Array<{
      node: {
        latestRankingPeriod?: Maybe<
          {
            myRanking?: Maybe<RankingUserWithoutIdConnectionFragment>
          } & RankingPeriodBaseConnectionFragment
        >
      } & RankingBaseConnectionFragment
    }>
  }
}

export type OngoingUpcomingPreSaleEventConnectionFragment = {
  edges: Array<{ node: EventTicketCardFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type MyPreSaleEventConnectionFragment = {
  edges: Array<{ node: EventTicketCardFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type MyTicketsQueryVariables = Exact<{
  filter: EventHeldStatus
  pageInput?: Maybe<PageInput>
  skipUser: Scalars['Boolean']
}>

export type MyTicketsQuery = {
  myPreSaleEvents: MyPreSaleEventConnectionFragment
  user?: Maybe<
    Pick<
      User,
      | 'id'
      | 'hasWaitingPaymentItems'
      | 'hasUnappliedDeliveryItems'
      | 'hasManagementTask'
      | 'unreadNotificationCount'
      | 'isMembershipPersonalInformationRequired'
    >
  >
}

export type TradeNegotiationsMyRequestsConnectionFragment = {
  edges: Array<{ node: TradeBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type TradeMyCandidatesConnectionFragment = {
  edges: Array<{ node: { tradeNegotiation: TradeBoxFragment } }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type MyTradeQueryVariables = Exact<{
  status?: Maybe<
    Array<TradeCandidateNegotiationStatus> | TradeCandidateNegotiationStatus
  >
  pageInput?: Maybe<PageInput>
}>

export type MyTradeQuery = {
  tradeMyCandidates: TradeMyCandidatesConnectionFragment
}

export type FetchNewMyTradeQueryVariables = Exact<{
  status?: Maybe<
    Array<TradeCandidateNegotiationStatus> | TradeCandidateNegotiationStatus
  >
  pageInput?: Maybe<PageInput>
}>

export type FetchNewMyTradeQuery = {
  tradeMyCandidates: TradeMyCandidatesConnectionFragment
}

export type MyRequestsQueryVariables = Exact<{
  status?: Maybe<Array<TradeNegotiationStatus> | TradeNegotiationStatus>
  pageInput?: Maybe<PageInput>
}>

export type MyRequestsQuery = {
  tradeNegotiationsMyRequests: TradeNegotiationsMyRequestsConnectionFragment
}

export type MyTradeDetailQueryVariables = Exact<{
  tradeNegotiationId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type MyTradeDetailQuery = {
  user?: Maybe<
    Pick<User, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
  tradeNegotiation: Pick<
    TradeNegotiation,
    'expiredAt' | 'formattedExpiredAt'
  > & { comments: CommentsConnectionFragment } & TradeBoxFragment
}

export type CreateTradeCommentMutationVariables = Exact<{
  createTradeCommentId: Scalars['String']
  inputs: CommunityCommentInput
}>

export type CreateTradeCommentMutation = {
  createTradeComment: CommentBoxFragment
}

export type DeclineTradeRequestMutationVariables = Exact<{
  inputs: DeclineTradeRequestInput
}>

export type DeclineTradeRequestMutation = {
  declineTradeRequest: Pick<TradeNegotiation, 'id'>
}

export type AcceptTradeRequestMutationVariables = Exact<{
  inputs: AcceptTradeRequestInput
}>

export type AcceptTradeRequestMutation = {
  acceptTradeRequest: Pick<TradeNegotiation, 'id'>
}

export type CancelTradeRequestMutationVariables = Exact<{
  inputs: CancelTradeRequestInput
}>

export type CancelTradeRequestMutation = {
  cancelTradeRequest: TradeBoxFragment
}

export type NewStoreItemConnectionFragment = {
  edges: Array<{ node: MagazineItemFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type NewStoreItemsQueryVariables = Exact<{
  artistId: Scalars['ID']
  filters?: Maybe<ItemsFilterInput>
  pageInput?: Maybe<PageInputDate>
  platform: Scalars['String']
  purchasablePrepaidPointPageInput?: Maybe<PageInput>
}>

export type NewStoreItemsQuery = {
  items: NewStoreItemConnectionFragment
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    'prepaidPointId' | 'balance' | 'formattedPrepaidPointBarance'
  >
  purchasablePrepaidPoints: PurchasablePrepaidPointConnectionFragment
  paymentSource?: Maybe<PrepaidPointsPaymentSourceFragment>
}

export type NftTokenDetailFragment = Pick<
  NftToken,
  | 'id'
  | 'chain'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'status'
  | 'ownedByUserAddress'
  | 'tokenDetailUri'
> & {
  metadata: {
    item?: Maybe<{
      item: Pick<Item, 'id'> & {
        issuedArtist?: Maybe<
          Pick<Artist, 'id' | 'displayName'> & {
            avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
          }
        >
      }
    }>
  }
}

export type NftTokenQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type NftTokenQuery = {
  user?: Maybe<
    Pick<User, 'id'> & { cryptoWallet?: Maybe<Pick<CryptoWallet, 'address'>> }
  >
  portfolio?: Maybe<
    Pick<UserItem, 'id'> & {
      item: Pick<Item, 'id' | 'title'> & {
        nft?: Maybe<{
          chains?: Maybe<Array<Pick<ItemNftChain, 'chainName' | 'royalty'>>>
        }>
      }
      serials: Array<
        Pick<
          UserItemSerial,
          'serialId' | 'formattedSerialNumber' | 'tokenId'
        > & { nftToken?: Maybe<NftTokenDetailFragment> }
      >
    }
  >
}

export type MintItemNftMutationVariables = Exact<{
  inputs: MintItemNftInput
}>

export type MintItemNftMutation = {
  mintItemNft: Pick<
    NftToken,
    | 'id'
    | 'chain'
    | 'createdAt'
    | 'formattedCreatedAt'
    | 'ownedByUserAddress'
    | 'tokenId'
    | 'tokenURI'
    | 'status'
  > & {
    metadata: {
      item?: Maybe<{
        item: Pick<Item, 'id' | 'title'> & {
          issuedArtist?: Maybe<
            Pick<Artist, 'id' | 'displayName'> & {
              avatar?: Maybe<Pick<Avatar, 'uri'>>
            }
          >
          nft?: Maybe<{
            chains?: Maybe<Array<Pick<ItemNftChain, 'chainName'>>>
          }>
        }
        itemSerial?: Maybe<Pick<ItemSerial, 'id' | 'formattedSerialNumber'>>
      }>
    }
  }
}

export type NotificationDetailQueryVariables = Exact<{
  notificationId: Scalars['ID']
}>

export type NotificationDetailQuery = {
  organization?: Maybe<
    Pick<Organization, 'id'> & { notification: NotificationBoxFragment }
  >
}

export type NotificationsConectionFragment = {
  edges: Array<{ node: NotificationBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type NotificationsQueryVariables = Exact<{
  artistId?: Maybe<Scalars['ID']>
  notificationsPageInput?: Maybe<PageInputDate>
  isStandalone: Scalars['Boolean']
}>

export type NotificationsQuery = {
  artist?: Maybe<
    { currentLive?: Maybe<Pick<LiveStream, 'id'>> } & ArtistBottomTabFragment
  >
  organization?: Maybe<
    Pick<Organization, 'id'> & { notifications: NotificationsConectionFragment }
  >
  user?: Maybe<CurrentUserFragment>
}

export type FetchNewNotificationsQueryVariables = Exact<{
  artistId?: Maybe<Scalars['ID']>
  pageInput?: Maybe<PageInputDate>
}>

export type FetchNewNotificationsQuery = {
  artist?: Maybe<
    { currentLive?: Maybe<Pick<LiveStream, 'id'>> } & ArtistBottomTabFragment
  >
  organization?: Maybe<
    Pick<Organization, 'id'> & { notifications: NotificationsConectionFragment }
  >
}

export type UpdateLastNotificationReadAtMutationVariables = Exact<{
  readAt: Scalars['Date']
}>

export type UpdateLastNotificationReadAtMutation = Pick<
  Mutation,
  'updateLastNotificationReadAt'
>

export type OrganizationNotificationConnectionFragment = {
  edges: Array<{ node: OrganizationNotificationBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type OfficialInformationQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
}>

export type OfficialInformationQuery = {
  organizationOfficialNotifications: OrganizationNotificationConnectionFragment
}

export type OfficialInformationDetailQueryVariables = Exact<{
  notificationId: Scalars['ID']
}>

export type OfficialInformationDetailQuery = {
  organizationOfficialNotification?: Maybe<OrganizationNotificationBoxFragment>
}

export type OfficialInformationDetailArtistQueryVariables = Exact<{
  artistId: Scalars['ID']
}>

export type OfficialInformationDetailArtistQuery = {
  artist?: Maybe<
    Pick<Artist, 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
}

export type PortfolioConnectionFragment = Pick<
  UserItem,
  | 'id'
  | 'isUsed'
  | 'number'
  | 'type'
  | 'createdAt'
  | 'isSystemProtected'
  | 'isUserProtected'
> & {
  item: ItemDescriptionFragment
  serials: Array<UserItemSerialDetailFragment>
  user: Pick<User, 'id' | 'fullName'>
}

export type PortfolioSkuFragment = Pick<
  UserItem,
  | 'id'
  | 'isUsed'
  | 'number'
  | 'type'
  | 'createdAt'
  | 'isSystemProtected'
  | 'isUserProtected'
> & {
  item: Pick<
    Item,
    | 'id'
    | 'title'
    | 'type'
    | 'description'
    | 'price'
    | 'formattedPrice'
    | 'isDrm'
    | 'isDownloadable'
    | 'ownedCount'
    | 'hideSalesNumber'
    | 'paymentType'
    | 'requirePersonalInformation'
    | 'formattedRemainingNumber'
    | 'personalInformationRequirements'
    | 'isResaleAvailable'
    | 'resaleOpenCount'
    | 'resaleRaffleAppliedCount'
    | 'resaleEnabled'
    | 'thumbnailType'
    | 'issuedNumber'
    | 'purchasedNumber'
    | 'commentCount'
    | 'isTicketDistributionEnabled'
  > & {
    preSale?: Maybe<
      Pick<PreSale, 'id'> & {
        event: Pick<Event, 'id' | 'title' | 'description'>
      }
    >
    collection?: Maybe<
      Pick<Collection, 'id' | 'itemCount' | 'isPublishOnStore'> & {
        event?: Maybe<EventBoxFragment>
        collectionItems: {
          edges: Array<{ node: CollectionItemDetailFragment }>
        }
      }
    >
    images?: Maybe<Array<Pick<ItemImage, 'caption' | 'mediumUri' | 'uri'>>>
    sku?: Maybe<
      Pick<
        SkuUnit,
        | 'id'
        | 'order'
        | 'pendingPurchaseNumber'
        | 'purchasedNumber'
        | 'stock'
        | 'unitName'
      > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>> }
    >
    skuSettings?: Maybe<
      Pick<SkuSettings, 'keyName'> & {
        units: Array<
          Pick<
            SkuUnit,
            | 'id'
            | 'order'
            | 'pendingPurchaseNumber'
            | 'purchasedNumber'
            | 'remainingNumber'
            | 'stock'
            | 'unitName'
          > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>> }
        >
      }
    >
    purchasableUsers?: Maybe<Array<PurchasableUserDetailFragment>>
    issuedArtist?: Maybe<Pick<Artist, 'id'>>
    rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
    thumbnail: {
      front?: Maybe<ThumbnailResourceFragment>
      back?: Maybe<ThumbnailResourceFragment>
    }
    nft?: Maybe<{ chains?: Maybe<Array<Pick<ItemNftChain, 'chainName'>>> }>
    rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
    resource?: Maybe<ResourceFragment>
    resources?: Maybe<Array<Maybe<ResourceFragment>>>
    avatarResources?: Maybe<{
      voiceResource?: Maybe<ResourceFragment>
      avatarResource?: Maybe<ResourceFragment>
    }>
    wallpaperResources?: Maybe<{
      audioResource?: Maybe<ResourceFragment>
      wallpaperResource?: Maybe<ResourceFragment>
    }>
    relatedMissions?: Maybe<
      Array<Pick<Mission, 'id' | 'expiredAt' | 'isAccomplished' | 'label'>>
    >
    ogp?: Maybe<Pick<Ogp, 'rectUri' | 'uri'>>
    metadata?: Maybe<{
      ticket?: Maybe<
        Pick<
          ItemMetadataDetailTicket,
          | 'note'
          | 'description'
          | 'expirationTerm'
          | 'isThroughTicket'
          | 'distributionEnabled'
          | 'assignedNumberType'
          | 'startAssignedNumber'
          | 'assignedNumberPrefix'
        > & {
          resalePeriod?: Maybe<Pick<ReceptionPeriod, 'startedAt' | 'endedAt'>>
          throughTicket?: Maybe<
            Array<Pick<TicketItemMetadataThroughTicket, 'name'>>
          >
        }
      >
      magazine?: Maybe<
        Pick<ItemMetadataDetailMagazine, 'pageDirection' | 'pageType'>
      >
      wallpaper?: Maybe<Pick<ItemWallpaperMetadata, 'isPrintEnabled'>>
    }>
    userOpenResaleItem?: Maybe<Pick<ResaleItem, 'id'>>
  }
  serials: Array<UserItemSerialDetailFragment>
  user: Pick<User, 'id' | 'fullName'> & {
    organizationPatrons: Array<PatronDetailFragment>
  }
}

export type UserItemSerialDetailFragment = Pick<
  UserItemSerial,
  | 'id'
  | 'serialId'
  | 'isProtected'
  | 'itemId'
  | 'formattedSerialNumber'
  | 'isNft'
  | 'tokenId'
  | 'isUsed'
  | 'obtainedDate'
  | 'formattedObtainedDateTime'
  | 'formattedObtainedDate'
> & {
  nftToken?: Maybe<Pick<NftToken, 'id' | 'status'>>
  serial?: Maybe<
    Pick<
      ItemSerial,
      'id' | 'formattedAssignedNumber' | 'formattedSerialNumber'
    > & {
      ticketStatus?: Maybe<TicketStatusDetailFragment>
      ticketDistributionLink?: Maybe<
        Pick<
          TicketDistributionLink,
          | 'id'
          | 'distributionId'
          | 'expiredAt'
          | 'isExpired'
          | 'formattedExpiredAt'
        > & {
          user?: Maybe<
            Pick<User, 'id' | 'displayName'> & {
              avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
            }
          >
        }
      >
      owner?: Maybe<
        Pick<User, 'id' | 'displayName'> & {
          avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
        }
      >
      sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
      metadata?: Maybe<{
        ticket?: Maybe<Pick<SerialMetadataTicket, 'fullName'>>
        wallpaper?: Maybe<{
          print?: Maybe<
            Pick<SerialMetadataWallpaperPrint, 'expiredAt' | 'contentsCode'> & {
              qrcode?: Maybe<Pick<Image, 'uri'>>
            }
          >
        }>
      }>
    }
  >
}

export type TicketStatusDetailFragment = Pick<
  TicketStatus,
  | 'usedAt'
  | 'isUsed'
  | 'formattedUsedAt'
  | 'expiredAt'
  | 'formattedExpiredAt'
  | 'canUseTicket'
  | 'isExpired'
> & {
  throughTicket?: Maybe<
    Array<
      Pick<
        TicketStatusThroughTicket,
        'name' | 'index' | 'isUsed' | 'usedAt' | 'seat' | 'formattedUsedAt'
      >
    >
  >
}

export type PortfolioQueryVariables = Exact<{
  id: Scalars['ID']
  platform: Scalars['String']
  pageInput?: Maybe<PageInput>
  skuId?: Maybe<Scalars['String']>
}>

export type PortfolioQuery = {
  portfolio?: Maybe<PortfolioSkuFragment>
  purchasablePrepaidPoints: PurchasablePrepaidPointConnectionFragment
  paymentSource?: Maybe<PrepaidPointsPaymentSourceFragment>
  prepaidPointBalance: Pick<PrepaidPointBalance, 'prepaidPointId' | 'balance'>
  user?: Maybe<
    Pick<User, 'id'> & {
      customProfile?: Maybe<CustomProfileConnectionFragment>
    }
  >
}

export type ProtectUserItemMutationVariables = Exact<{
  inputs: ProtectUserItemInput
  skuId?: Maybe<Scalars['String']>
}>

export type ProtectUserItemMutation = {
  protectUserItem: PortfolioConnectionFragment
}

export type UseTicketsMutationVariables = Exact<{
  inputs: UseTicketsInput
}>

export type UseTicketsMutation = {
  useTickets: Array<
    Pick<ItemSerial, 'id' | 'obtainedDate'> & {
      ticketStatus?: Maybe<TicketStatusDetailFragment>
    }
  >
}

export type ViewCollectionItemMutationVariables = Exact<{
  inputs: ViewCollectionItemInput
}>

export type ViewCollectionItemMutation = {
  viewCollectionItem: Pick<
    RecentlyViewedCollection,
    'id' | 'userId' | 'latestItemId' | 'collectionId' | 'viewCount'
  >
}

export type GenerateWallpaperPrintQrcodeMutationVariables = Exact<{
  inputs: GenerateWallpaperPrintQrcodeInput
}>

export type GenerateWallpaperPrintQrcodeMutation = {
  generateWallpaperPrintQrcode: Pick<
    ItemSerial,
    'id' | 'formattedAssignedNumber'
  > & {
    ticketStatus?: Maybe<TicketStatusDetailFragment>
    sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
    metadata?: Maybe<{
      ticket?: Maybe<Pick<SerialMetadataTicket, 'fullName'>>
      wallpaper?: Maybe<{
        print?: Maybe<
          Pick<SerialMetadataWallpaperPrint, 'expiredAt' | 'contentsCode'> & {
            qrcode?: Maybe<Pick<Image, 'uri'>>
          }
        >
      }>
    }>
  }
}

export type PortfolioItemsConnectionFragment = {
  edges: Array<{ node: PortfolioItemsDetailFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type PortfolioItemsDetailFragment = Pick<
  UserItem,
  'id' | 'number' | 'isSystemProtected' | 'isUserProtected'
> & {
  serials: Array<
    Pick<UserItemSerial, 'serialId'> & {
      serial?: Maybe<{ sku?: Maybe<Pick<SkuUnit, 'id'>> }>
    }
  >
  item: Pick<
    Item,
    | 'isResaleAvailable'
    | 'id'
    | 'title'
    | 'hideSalesNumber'
    | 'type'
    | 'isDownloadable'
  > & {
    rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
    sku?: Maybe<
      Pick<
        SkuUnit,
        | 'id'
        | 'order'
        | 'pendingPurchaseNumber'
        | 'purchasedNumber'
        | 'remainingNumber'
        | 'stock'
        | 'unitName'
      > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>> }
    >
    skuSettings?: Maybe<
      Pick<SkuSettings, 'keyName'> & {
        units: Array<
          Pick<
            SkuUnit,
            | 'id'
            | 'order'
            | 'pendingPurchaseNumber'
            | 'purchasedNumber'
            | 'remainingNumber'
            | 'stock'
            | 'unitName'
          > & { thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>> }
        >
      }
    >
    thumbnail: { front?: Maybe<Pick<Resource, 'mediumUri' | 'uri'>> }
    nft?: Maybe<{ chains?: Maybe<Array<Pick<ItemNftChain, 'chainName'>>> }>
    metadata?: Maybe<{
      wallpaper?: Maybe<
        Pick<
          ItemWallpaperMetadata,
          'isPrintEnabled' | 'printCount' | 'printType'
        >
      >
      ticket?: Maybe<
        Pick<
          ItemMetadataDetailTicket,
          | 'note'
          | 'description'
          | 'expirationTerm'
          | 'isThroughTicket'
          | 'distributionEnabled'
          | 'assignedNumberType'
          | 'startAssignedNumber'
          | 'assignedNumberPrefix'
        > & {
          resalePeriod?: Maybe<Pick<ReceptionPeriod, 'startedAt' | 'endedAt'>>
          throughTicket?: Maybe<
            Array<Pick<TicketItemMetadataThroughTicket, 'name'>>
          >
        }
      >
      magazine?: Maybe<
        Pick<ItemMetadataDetailMagazine, 'pageDirection' | 'pageType'>
      >
    }>
  }
}

export type PortfolioItemsQueryVariables = Exact<{
  type?: Maybe<ItemType>
  pageInput?: Maybe<PageInput>
  option?: Maybe<PortfoliosOptionInput>
}>

export type PortfolioItemsQuery = {
  user?: Maybe<
    Pick<User, 'id'> & {
      organizationPortfolios: PortfolioItemsConnectionFragment
    }
  >
}

export type FetchNewPortfolioItemsQueryVariables = Exact<{
  type?: Maybe<ItemType>
  pageInput?: Maybe<PageInput>
}>

export type FetchNewPortfolioItemsQuery = {
  user?: Maybe<
    Pick<User, 'id'> & {
      organizationPortfolios: PortfolioItemsConnectionFragment
    }
  >
}

export type PaymentCreateDetailFragment = Pick<
  CreditCard,
  'id' | 'name' | 'last4' | 'expMonth' | 'expYear' | 'brand'
>

export type RequiresActionDetailFragment = Pick<
  RequiresAction,
  'status' | 'clientSecret'
>

export type CreatePaymentMethodMutationVariables = Exact<{
  createPaymentMethodToken: Scalars['String']
}>

export type CreatePaymentMethodMutation = {
  createPaymentMethod:
    | PaymentCreateDetailFragment
    | RequiresActionDetailFragment
}

export type PaymentDetailFragment = Pick<
  CreditCard,
  'id' | 'name' | 'last4' | 'expMonth' | 'expYear' | 'brand'
>

export type PaymentDetailQueryVariables = Exact<{ [key: string]: never }>

export type PaymentDetailQuery = {
  paymentSource?: Maybe<PaymentDetailFragment>
}

export type DeletePaymentSourceMutationVariables = Exact<{
  [key: string]: never
}>

export type DeletePaymentSourceMutation = {
  deletePaymentSource: PaymentDetailFragment
}

export type StoreItemUnsettledDetailQueryVariables = Exact<{
  pendingPurchaseId: Scalars['ID']
}>

export type StoreItemUnsettledDetailQuery = {
  pendingPurchaseItem: PendingPurchaseItemDetailFragment
  user?: Maybe<
    Pick<
      User,
      | 'id'
      | 'fullName'
      | 'phoneNumber'
      | 'formattedPhoneNumber'
      | 'isPhoneNumberVerified'
    > &
      UserAddressFragment
  >
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    | 'lastUpdatedAt'
    | 'prepaidPointId'
    | 'balance'
    | 'formattedPrepaidPointBarance'
  >
}

export type PendingPurchaseItemsConnectionFragment = {
  edges: Array<{ node: PendingPurchaseItemDetailFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type PaymentStatusesQueryVariables = Exact<{
  pageInput?: Maybe<PageInputDate>
}>

export type PaymentStatusesQuery = {
  pendingPurchaseItems: PendingPurchaseItemsConnectionFragment
}

export type PersonalInfoFragment = Pick<
  User,
  | 'id'
  | 'gender'
  | 'birthday'
  | 'fullName'
  | 'email'
  | 'phoneNumber'
  | 'formattedPhoneNumber'
  | 'isPhoneNumberVerified'
  | 'formattedBirthday'
  | 'isMembershipPersonalInformationRequired'
> & {
  bank?: Maybe<
    Pick<
      UserBank,
      | 'bankName'
      | 'accountType'
      | 'accountHolderName'
      | 'branchCode'
      | 'branchName'
      | 'accountNumber'
    >
  >
} & UserAddressFragment

export type PersonalInfoQueryVariables = Exact<{ [key: string]: never }>

export type PersonalInfoQuery = {
  user?: Maybe<PersonalInfoFragment>
  paymentSource?: Maybe<PaymentDetailFragment>
}

export type UpdatePersonalInformationSettingsMutationVariables = Exact<{
  inputs: UpdateProfileInput
}>

export type UpdatePersonalInformationSettingsMutation = {
  updateUserProfile: PersonalInfoFragment
}

export type PostDetailQueryVariables = Exact<{
  postId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type PostDetailQuery = {
  post?: Maybe<{ replies: RepliesConnectionFragment } & PostBoxFragment>
  user?: Maybe<CurrentUserFragment>
}

export type LikePostMutationVariables = Exact<{
  likePostPostId: Scalars['ID']
}>

export type LikePostMutation = {
  likePost: Pick<Post, 'id' | 'likeCount' | 'isLike'>
}

export type UnlikePostMutationVariables = Exact<{
  unlikePostPostId: Scalars['ID']
}>

export type UnlikePostMutation = {
  unlikePost: Pick<Post, 'id' | 'likeCount' | 'isLike'>
}

export type ReplyPostMutationVariables = Exact<{
  replyPostInputs: ReplyPostInput
}>

export type ReplyPostMutation = { replyPost: ReplyBoxFragment }

export type DeletePostReplyMutationVariables = Exact<{
  deletePostReplyId: Scalars['ID']
}>

export type DeletePostReplyMutation = { deletePostReply: ReplyBoxFragment }

export type BlockUserMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type BlockUserMutation = {
  blockUser?: Maybe<
    Pick<User, 'id' | 'accountId' | 'formattedAccountId' | 'blockedUsers'>
  >
}

export type LikePostReplyMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type LikePostReplyMutation = {
  likePostReply: Pick<PostReply, 'id' | 'isLike' | 'likeCount'>
}

export type UnlikePostReplyMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type UnlikePostReplyMutation = {
  unlikePostReply: Pick<PostReply, 'id' | 'isLike' | 'likeCount'>
}

export type ArtistPostVideosFragment = {
  edges: Array<{ node: ArtistPostVideoFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type VideoDetailQueryVariables = Exact<{
  postId: Scalars['ID']
}>

export type VideoDetailQuery = { post?: Maybe<PostBoxFragment> }

export type ArtistPostVideosToCurrentVideoQueryVariables = Exact<{
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
  resourceType?: Maybe<PostType>
  categoryId?: Maybe<Scalars['String']>
}>

export type ArtistPostVideosToCurrentVideoQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      posts: ArtistPostVideosFragment
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    }
  >
  user?: Maybe<CurrentUserFragment>
}

export type FetchNewArtistPostVideosToCurrentVideoQueryVariables = Exact<{
  artistId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
  resourceType?: Maybe<PostType>
  categoryId?: Maybe<Scalars['String']>
}>

export type FetchNewArtistPostVideosToCurrentVideoQuery = {
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      posts: ArtistPostsFragment
      currentLive?: Maybe<Pick<LiveStream, 'id'>>
    }
  >
}

export type PurchasablePrepaidPointConnectionFragment = {
  edges: Array<{ node: PurchasablePrepaidPointDetailFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type PrepaidPointsPaymentSourceFragment = Pick<
  CreditCard,
  'id' | 'last4' | 'brand'
>

export type PurchasablePrepaidPointDetailFragment = Pick<
  PurchasablePrepaidPoint,
  'id' | 'points' | 'price' | 'description' | 'prepaidPointId'
> & { metadata: Pick<PurchasablePrepaidPointMetadata, 'id' | 'platform'> }

export type PrepaidPointChargeResultFragment = Pick<
  PrepaidPointTransaction,
  | 'id'
  | 'prepaidPointBalance'
  | 'prepaidPointBalanceBefore'
  | 'transactionPrepaidPoint'
  | 'prepaidPointId'
  | 'expiredPrepaidPoint'
  | 'type'
  | 'createdAt'
  | 'formattedCreatedAt'
>

export type PurchasablePrepaidPointsQueryVariables = Exact<{
  platform: Scalars['String']
  pageInput?: Maybe<PageInput>
}>

export type PurchasablePrepaidPointsQuery = {
  prepaidPointBalance: Pick<PrepaidPointBalance, 'prepaidPointId' | 'balance'>
  purchasablePrepaidPoints: PurchasablePrepaidPointConnectionFragment
  paymentSource?: Maybe<PrepaidPointsPaymentSourceFragment>
}

export type ChargePrepaidPointMutationVariables = Exact<{
  inputs: ChargePrepaidPointInput
}>

export type ChargePrepaidPointMutation = {
  chargePrepaidPoint: PrepaidPointChargeResultFragment
}

export type ChargePrepaidPointByIapMutationMutationVariables = Exact<{
  inputs: ChargePrepaidPointIapInput
}>

export type ChargePrepaidPointByIapMutationMutation = {
  chargePrepaidPointByIap: PrepaidPointChargeResultFragment
}

export type PrepaidPointOrdersQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
}>

export type PrepaidPointOrdersQuery = {
  prepaidPointTransactions: {
    edges: Array<{ node: PrepaidPointBoxFragment }>
    pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
  }
}

export type PrivacySettingsQueryVariables = Exact<{ [key: string]: never }>

export type PrivacySettingsQuery = {
  user?: Maybe<
    Pick<User, 'id'> & {
      settings?: Maybe<{ privacy?: Maybe<Pick<PrivacySettings, 'item'>> }>
    }
  >
}

export type UpdateUserPrivacySettingsMutationVariables = Exact<{
  inputs: UserPrivacySettingsInput
}>

export type UpdateUserPrivacySettingsMutation = {
  updateUserPrivacySettings: Pick<User, 'id'> & {
    settings?: Maybe<{ privacy?: Maybe<Pick<PrivacySettings, 'item'>> }>
  }
}

export type GachaTicketFragment = Pick<
  GachaTicket,
  | 'id'
  | 'artistId'
  | 'name'
  | 'description'
  | 'createdAt'
  | 'updatedAt'
  | 'serialNumber'
  | 'specifiedGachas'
  | 'price'
  | 'isPurchasable'
> & {
  thumbnails?: Maybe<{
    reward?: Maybe<Pick<Image, 'uri'>>
    standard?: Maybe<Pick<Image, 'uri'>>
  }>
  artist?: Maybe<
    Pick<Artist, 'id'> & {
      standalone?: Maybe<{ link?: Maybe<Pick<StandaloneLink, 'common'>> }>
    }
  >
}

export type GachaTicketOrderFragment = Pick<
  GachaTicketOrder,
  | 'id'
  | 'gachaId'
  | 'orderType'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'count'
  | 'gachaTicketId'
>

export type GachaTicketSerialFragment = Pick<
  Serial,
  'id' | 'createdAt' | 'updatedAt' | 'issueCount'
> & {
  authentication?: Maybe<
    Pick<SerialAuthentication, 'authenticatedAt' | 'expiredAt'>
  >
}

export type GachaTicketSerialQueryVariables = Exact<{
  id: Scalars['ID']
  serialId: Scalars['ID']
}>

export type GachaTicketSerialQuery = {
  user?: Maybe<
    Pick<User, 'id'> & {
      gachaTicket?: Maybe<Pick<UserGachaTicket, 'available'>>
    }
  >
  gachaTicket?: Maybe<
    { serial?: Maybe<GachaTicketSerialFragment> } & GachaTicketFragment
  >
}

export type ObtainGachaTicketSerialMutationVariables = Exact<{
  id: Scalars['String']
  serialId: Scalars['String']
}>

export type ObtainGachaTicketSerialMutation = {
  obtainGachaTicketSerial: GachaTicketOrderFragment
}

export type ObtainSerialMutationVariables = Exact<{
  itemId: Scalars['ID']
  serialId: Scalars['ID']
}>

export type ObtainSerialMutation = {
  obtainSerial: Pick<Item, 'id' | 'isPurchased'> & {
    serial?: Maybe<
      Pick<Serial, 'id' | 'skuId'> & {
        authentication?: Maybe<Pick<SerialAuthentication, 'authenticatedAt'>>
      }
    >
  }
}

export type QRcodeGetItemQueryVariables = Exact<{
  itemId: Scalars['ID']
  serialId: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type QRcodeGetItemQuery = {
  item?: Maybe<
    {
      serial?: Maybe<
        Pick<Serial, 'id' | 'skuId'> & {
          authentication?: Maybe<Pick<SerialAuthentication, 'authenticatedAt'>>
        }
      >
    } & ItemDetailBoxFragment
  >
}

export type ItemSerialCountDetailFragment = Pick<
  ItemSerialCount,
  'count' | 'itemSkuId'
> & {
  item: Pick<Item, 'id' | 'title'> & {
    rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
    thumbnail: { front?: Maybe<Pick<Resource, 'smallUri' | 'uri'>> }
    rarityIconImage?: Maybe<Pick<Image, 'smallUri' | 'uri'>>
  }
  sku?: Maybe<Pick<SkuUnit, 'id' | 'unitName'>>
}

export type MultiItemSerialDetailFragment = Pick<
  MultiItemSerial,
  'id' | 'expiredAt' | 'isExpired' | 'authenticatedAt' | 'canBeObtain'
> & { items: Array<ItemSerialCountDetailFragment> }

export type QRcodeGetItemMultiQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type QRcodeGetItemMultiQuery = {
  multiItemSerials: MultiItemSerialDetailFragment
}

export type ObtainMultiItemSerialsMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ObtainMultiItemSerialsMutation = {
  obtainMultiItemSerials: MultiItemSerialDetailFragment
}

export type RecentViewedItemConnectionFragment = {
  edges: Array<{
    node: Pick<RecentlyStoreViewedItem, 'id'> & { item: MagazineItemFragment }
  }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type RecentViewedItemsQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
  artistId?: Maybe<Scalars['String']>
}>

export type RecentViewedItemsQuery = {
  recentlyStoreViewedItems: RecentViewedItemConnectionFragment
}

export type RecommendCollectionFragment = Pick<
  RecentlyViewedCollection,
  'id'
> & {
  collection: Pick<
    Collection,
    'id' | 'title' | 'description' | 'artistId' | 'itemCount'
  > & { thumbnail: Pick<CollectionThumbnail, 'uri'> }
}

export type RecommendCollectionConnectionFragment = {
  edges: Array<{ node: RecommendCollectionFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type RecommendCollectionsQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
  artistId?: Maybe<Scalars['String']>
}>

export type RecommendCollectionsQuery = {
  recentlyViewedCollections: RecommendCollectionConnectionFragment
}

export type ThreadReplyDetailQueryVariables = Exact<{
  id: Scalars['ID']
  commentId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
}>

export type ThreadReplyDetailQuery = {
  artistThreadChannel: Pick<ArtistThreadChannel, 'id' | 'artistId'>
  artistThreadChannelComment?: Maybe<
    {
      replies: {
        edges: Array<{
          node: {
            replies: {
              edges: Array<{ node: CommentBoxWithoutRepliesFragment }>
              pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
            }
          } & CommentBoxWithoutRepliesFragment
        }>
        pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      }
    } & CommentBoxWithoutRepliesFragment
  >
  user?: Maybe<
    Pick<User, 'id' | 'accountId' | 'formattedAccountId' | 'email'> & {
      avatar?: Maybe<Pick<Avatar, 'uri'>>
    }
  >
}

export type MissionReplyDetailQueryVariables = Exact<{
  id: Scalars['ID']
  commentId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
}>

export type MissionReplyDetailQuery = {
  mission?: Maybe<Pick<Mission, 'id' | 'artistId'>>
  missionComment?: Maybe<
    {
      replies: {
        edges: Array<{
          node: {
            replies: {
              edges: Array<{ node: CommentBoxWithoutRepliesFragment }>
              pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
            }
          } & CommentBoxWithoutRepliesFragment
        }>
        pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      }
    } & CommentBoxWithoutRepliesFragment
  >
  user?: Maybe<
    Pick<User, 'id' | 'accountId' | 'formattedAccountId' | 'email'> & {
      avatar?: Maybe<Pick<Avatar, 'uri'>>
    }
  >
}

export type ItemReplyDetailQueryVariables = Exact<{
  id: Scalars['ID']
  commentId: Scalars['ID']
  pageInput?: Maybe<PageInputDate>
}>

export type ItemReplyDetailQuery = {
  item?: Maybe<Pick<Item, 'id'> & { issuedArtist?: Maybe<Pick<Artist, 'id'>> }>
  itemComment?: Maybe<
    {
      replies: {
        edges: Array<{
          node: {
            replies: {
              edges: Array<{ node: CommentBoxWithoutRepliesFragment }>
              pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
            }
          } & CommentBoxWithoutRepliesFragment
        }>
        pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      }
    } & CommentBoxWithoutRepliesFragment
  >
  user?: Maybe<
    Pick<User, 'id' | 'accountId' | 'formattedAccountId' | 'email'> & {
      avatar?: Maybe<Pick<Avatar, 'uri'>>
    }
  >
}

export type ResaleApplicationCreateQueryVariables = Exact<{
  id: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleApplicationCreateQuery = {
  item?: Maybe<
    {
      preSale?: Maybe<
        Pick<PreSale, 'id'> & {
          lottery?: Maybe<Pick<PreSaleLottery, 'purchasableCount'>>
          firstComeFirstServed?: Maybe<
            Pick<PreSaleFirstComeFirstServed, 'purchasableCount'>
          >
        }
      >
    } & ItemDetailBoxFragment
  >
  paymentSource?: Maybe<PaymentDetailFragment>
}

export type ResaleApplicationCreateCompletionQueryVariables = Exact<{
  raffleId: Scalars['ID']
  itemId: Scalars['String']
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleApplicationCreateCompletionQuery = {
  itemResaleRaffleUser: ItemResaleRaffleUserDetailFragment
}

export type ResaleApplicationCreateConfirmationQueryVariables = Exact<{
  id: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleApplicationCreateConfirmationQuery = {
  item?: Maybe<ItemDetailBoxFragment>
  paymentSource?: Maybe<PaymentDetailFragment>
}

export type ApplyResaleItemRaffleMutationVariables = Exact<{
  inputs: ApplyResaleItemRaffleInput
}>

export type ApplyResaleItemRaffleMutation = {
  applyResaleItemRaffle: Pick<ItemResaleRaffleUser, 'id'>
}

export type ResaleApplicationCreatePrecautionQueryVariables = Exact<{
  id: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleApplicationCreatePrecautionQuery = {
  item?: Maybe<ItemDetailBoxFragment>
}

export type ResaleApplicationDetailQueryVariables = Exact<{
  id: Scalars['ID']
  itemId: Scalars['String']
  userId?: Maybe<Scalars['String']>
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleApplicationDetailQuery = {
  item?: Maybe<ItemDetailBoxFragment>
  userActiveResaleItemRaffle?: Maybe<ItemResaleRaffleUserDetailFragment>
}

export type ResaleCreateQueryVariables = Exact<{
  id: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleCreateQuery = {
  item?: Maybe<ItemDetailBoxFragment>
  portfolio?: Maybe<PortfolioConnectionFragment>
}

export type ResaleCreateConfirmationQueryVariables = Exact<{
  id: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleCreateConfirmationQuery = {
  item?: Maybe<ItemDetailBoxFragment>
  portfolio?: Maybe<PortfolioConnectionFragment>
}

export type CreateResaleItemMutationVariables = Exact<{
  inputs: CreateResaleItemInput
}>

export type CreateResaleItemMutation = {
  createResaleItem: Pick<ResaleItem, 'id'>
}

export type ResaleCreatePrecautionQueryVariables = Exact<{
  id: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleCreatePrecautionQuery = {
  item?: Maybe<ItemDetailBoxFragment>
}

export type UserBankAccountFragment = Pick<User, 'id'> & {
  bank?: Maybe<
    Pick<
      UserBank,
      | 'accountHolderName'
      | 'accountNumber'
      | 'accountType'
      | 'bankName'
      | 'branchCode'
      | 'branchName'
    >
  >
}

export type ResaleDetailQueryVariables = Exact<{
  id: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleDetailQuery = {
  resaleItem: ResaleItemBoxFragment
  user?: Maybe<UserBankAccountFragment>
}

export type CancelResaleItemMutationVariables = Exact<{
  inputs: CancelResaleItemInput
  skuId?: Maybe<Scalars['String']>
}>

export type CancelResaleItemMutation = {
  cancelResaleItem: ResaleItemBoxFragment
}

export type ResaleConnectionFragment = {
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  edges: Array<{ node: ResaleItemBoxFragment }>
}

export type ResaleRaffleConnectionFragment = {
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  edges: Array<{ node: ResaleRaffleBoxFragment }>
}

export type ResaleManagementQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
  status?: Maybe<Array<Scalars['String']> | Scalars['String']>
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleManagementQuery = {
  resaleItemsByUser: ResaleConnectionFragment
}

export type ItemResaleRaffleUsersQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
  status: Array<Scalars['String']> | Scalars['String']
  skuId?: Maybe<Scalars['String']>
}>

export type ItemResaleRaffleUsersQuery = {
  itemResaleRaffleUsers: ResaleRaffleConnectionFragment
}

export type ItemResaleRaffleUserDetailFragment = Pick<
  ItemResaleRaffleUser,
  | 'id'
  | 'skuId'
  | 'itemCount'
  | 'totalItemPrice'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'formattedUpdatedAt'
  | 'updatedAt'
  | 'status'
> & {
  item: ItemDetailBoxFragment
  resaleItems: Array<
    Pick<ResaleItem, 'id' | 'paymentExpiredAt' | 'formattedPaymentExpiredAt'>
  >
}

export type ResaleRaffleDetailQueryVariables = Exact<{
  raffleId: Scalars['ID']
  itemId: Scalars['String']
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleRaffleDetailQuery = {
  itemResaleRaffleUser: ItemResaleRaffleUserDetailFragment
}

export type CancelResaleItemRaffleMutationVariables = Exact<{
  inputs: CancelResaleItemRaffleInput
  skuId?: Maybe<Scalars['String']>
}>

export type CancelResaleItemRaffleMutation = {
  cancelResaleItemRaffle: ItemResaleRaffleUserDetailFragment
}

export type ResaleRaffleRepaymentCompletionQueryVariables = Exact<{
  raffleId: Scalars['ID']
  itemId: Scalars['String']
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleRaffleRepaymentCompletionQuery = {
  itemResaleRaffleUser: ItemResaleRaffleUserDetailFragment
  paymentSource?: Maybe<PaymentDetailFragment>
}

export type ResaleRaffleRepaymentConfirmationQueryVariables = Exact<{
  raffleId: Scalars['ID']
  itemId: Scalars['String']
  skuId?: Maybe<Scalars['String']>
}>

export type ResaleRaffleRepaymentConfirmationQuery = {
  itemResaleRaffleUser: ItemResaleRaffleUserDetailFragment
  paymentSource?: Maybe<PaymentDetailFragment>
}

export type PurchaseResaleItemMutationVariables = Exact<{
  inputs: PurchaseResaleItemInput
}>

export type PurchaseResaleItemMutation = {
  purchaseResaleItem: Pick<ItemResaleRaffleUser, 'id'>
}

export type SmsVerificationQueryVariables = Exact<{ [key: string]: never }>

export type SmsVerificationQuery = { user?: Maybe<UserDeliveryDetailFragment> }

export type PreSaleDetailQueryVariables = Exact<{
  id: Scalars['ID']
  clientId?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}>

export type PreSaleDetailQuery = {
  preSale: Pick<PreSale, 'id'> & {
    lottery?: Maybe<Pick<PreSaleLottery, 'personalInformationRequirements'>>
    firstComeFirstServed?: Maybe<
      Pick<PreSaleFirstComeFirstServed, 'personalInformationRequirements'>
    >
  }
}

export type VerifySmsCodeMutationVariables = Exact<{
  code: Scalars['String']
}>

export type VerifySmsCodeMutation = {
  verifySMSCode: Pick<PhoneNumberVerification, 'verified'>
}

export type UserTradableUserItemsConnectionFragment = {
  edges: Array<{
    node: Pick<
      UserItem,
      'id' | 'number' | 'isSystemProtected' | 'isUserProtected'
    > & {
      item: Pick<Item, 'id' | 'title' | 'type' | 'hideSalesNumber'> & {
        sku?: Maybe<
          Pick<
            SkuUnit,
            | 'id'
            | 'order'
            | 'pendingPurchaseNumber'
            | 'purchasedNumber'
            | 'stock'
            | 'unitName'
          > & {
            thumbnail?: Maybe<
              Pick<Image, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
            >
          }
        >
        skuSettings?: Maybe<
          Pick<SkuSettings, 'keyName'> & {
            units: Array<
              Pick<
                SkuUnit,
                | 'id'
                | 'order'
                | 'pendingPurchaseNumber'
                | 'purchasedNumber'
                | 'stock'
                | 'unitName'
              > & {
                thumbnail?: Maybe<
                  Pick<
                    Image,
                    'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'
                  >
                >
              }
            >
          }
        >
        rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
        thumbnail: {
          front?: Maybe<
            Pick<Resource, 'uri' | 'smallUri' | 'mediumUri' | 'compressedUri'>
          >
        }
      }
      user: Pick<User, 'id' | 'displayName'> & {
        avatar?: Maybe<
          Pick<Avatar, 'uri' | 'smallUri' | 'mediumUri' | 'compressedUri'>
        >
      }
    }
  }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type UserItemBoxFragment = Pick<
  UserItem,
  | 'id'
  | 'number'
  | 'type'
  | 'createdAt'
  | 'isSystemProtected'
  | 'isUserProtected'
> & {
  item: Pick<Item, 'id' | 'title' | 'ownedCount'> & {
    thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>> }
  }
  serials: Array<
    Pick<
      UserItemSerial,
      | 'isProtected'
      | 'formattedSerialNumber'
      | 'serialId'
      | 'isUsed'
      | 'obtainedDate'
      | 'formattedObtainedDate'
    >
  >
  user: UserInfoFragment
}

export type TradeItemDetailSelectItemDetailBoxFragment = Pick<
  Item,
  | 'id'
  | 'title'
  | 'type'
  | 'description'
  | 'price'
  | 'ownedCount'
  | 'resaleOpenCount'
  | 'resaleRaffleAppliedCount'
  | 'issuedNumber'
  | 'commentCount'
> & {
  images?: Maybe<Array<Pick<ItemImage, 'caption' | 'mediumUri' | 'uri'>>>
  sku?: Maybe<
    Pick<SkuUnit, 'id' | 'order' | 'unitName'> & {
      thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>>
    }
  >
  skuSettings?: Maybe<{
    units: Array<
      Pick<SkuUnit, 'id' | 'order' | 'unitName'> & {
        thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>>
      }
    >
  }>
  purchasableUsers?: Maybe<Array<PurchasableUserDetailFragment>>
  issuedArtist?: Maybe<Pick<Artist, 'id'>>
  rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
  thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>> }
  nft?: Maybe<{ chains?: Maybe<Array<Pick<ItemNftChain, 'chainName'>>> }>
  metadata?: Maybe<{
    ticket?: Maybe<
      Pick<ItemMetadataDetailTicket, 'description' | 'isThroughTicket'>
    >
  }>
}

export type SerialNumberSelectQueryVariables = Exact<{
  itemId: Scalars['ID']
  userId?: Maybe<Scalars['String']>
  skuId?: Maybe<Scalars['String']>
}>

export type SerialNumberSelectQuery = { portfolio?: Maybe<UserItemBoxFragment> }

export type UserTradableUserItemsQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
}>

export type UserTradableUserItemsQuery = {
  userTradableUserItems: UserTradableUserItemsConnectionFragment
}

export type ItemDetailSelectQueryVariables = Exact<{
  id: Scalars['ID']
  userId?: Maybe<Scalars['String']>
  skuId?: Maybe<Scalars['String']>
}>

export type ItemDetailSelectQuery = {
  portfolio?: Maybe<
    Pick<
      UserItem,
      | 'id'
      | 'number'
      | 'type'
      | 'createdAt'
      | 'isSystemProtected'
      | 'isUserProtected'
    > & {
      item: TradeItemDetailSelectItemDetailBoxFragment
      serials: Array<
        Pick<
          UserItemSerial,
          | 'id'
          | 'isProtected'
          | 'serialId'
          | 'isUsed'
          | 'itemId'
          | 'obtainedDate'
          | 'formattedObtainedDate'
          | 'formattedSerialNumber'
        >
      >
      user: UserInfoFragment
    }
  >
}

export type CreateTradeRequestMutationVariables = Exact<{
  inputs: CreateTradeRequestInput
}>

export type CreateTradeRequestMutation = {
  createTradeRequest: TradeBoxFragment
}

export type SettingsQueryVariables = Exact<{ [key: string]: never }>

export type SettingsQuery = {
  user?: Maybe<Pick<User, 'id' | 'isMembershipPersonalInformationRequired'>>
}

export type SignUpUserFragment = Pick<
  User,
  'id' | 'displayName' | 'introduction'
> & { avatar?: Maybe<Pick<Avatar, 'uri'>> }

export type VerifyCodeFragment = Pick<EmailVerification, 'id' | 'verified'>

export type VerifyCodeMutationVariables = Exact<{
  email: Scalars['String']
  code: Scalars['String']
}>

export type VerifyCodeMutation = {
  verifyCode: Pick<EmailVerification, 'id' | 'verified'>
}

export type SendVerificationEmailMutationVariables = Exact<{
  email: Scalars['String']
}>

export type SendVerificationEmailMutation = {
  sendVerificationEmail: Pick<EmailVerification, 'id' | 'email'>
}

export type SignupAsUserMutationVariables = Exact<{
  inputs: SignupAsUserInput
}>

export type SignupAsUserMutation = { signupAsUser: SignUpUserFragment }

export type UpdateProfileMutationVariables = Exact<{
  inputs: UpdateProfileInput
}>

export type UpdateProfileMutation = { updateUserProfile: UserProfileFragment }

export type LinkAnonymousAccountMutationVariables = Exact<{
  inputs: LinkAnonymousAccountInput
}>

export type LinkAnonymousAccountMutation = {
  linkAnonymousAccount: Pick<User, 'id'>
}

export type ValidateAccountQueryVariables = Exact<{
  input?: Maybe<ValidateAccountInput>
}>

export type ValidateAccountQuery = {
  validateAccount: Pick<AccountValidation, 'accountId' | 'email'>
}

export type UserCartFragment = Pick<
  UserCart,
  'id' | 'paymentType' | 'totalCount' | 'totalPrice' | 'formattedTotalPrice'
> & {
  artist: Pick<Artist, 'id' | 'displayName' | 'introduction'> & {
    avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
  }
  items: Array<
    Pick<
      UserCartItem,
      'count' | 'itemId' | 'totalPrice' | 'formattedTotalPrice'
    > & {
      item: Pick<
        Item,
        | 'id'
        | 'title'
        | 'price'
        | 'hideSalesNumber'
        | 'personalInformationRequirements'
        | 'formattedPrice'
        | 'paymentType'
        | 'type'
      > & {
        collection?: Maybe<Pick<Collection, 'id' | 'title'>>
        issuedArtist?: Maybe<Pick<Artist, 'id' | 'displayName'>>
        sku?: Maybe<
          Pick<SkuUnit, 'id' | 'unitName'> & {
            thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
          }
        >
        thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
      }
      skus: Array<
        Pick<UserCartItemSku, 'skuId' | 'count'> & {
          sku: Pick<SkuUnit, 'id' | 'unitName'> & {
            thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
          }
        }
      >
    }
  >
}

export type FeeDetailFragment = Pick<
  Fee,
  'fee' | 'formattedFee' | 'feeDescription' | 'feeName' | 'feeType'
>

export type CollectionFeeDetailFragment = Pick<
  Fee,
  'id' | 'fee' | 'feeDescription' | 'feeName' | 'feeType'
> & { collectionFee?: Maybe<Pick<Collection, 'title'>> }

export type StoreCardDetailFragment = Pick<
  UserCart,
  'id' | 'totalCount' | 'totalPrice' | 'formattedTotalPrice'
> & {
  artist: Pick<Artist, 'id' | 'displayName' | 'introduction'> & {
    avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
  }
  items: Array<
    Pick<UserCartItem, 'count' | 'totalPrice' | 'formattedTotalPrice'> & {
      item: Pick<
        Item,
        | 'id'
        | 'title'
        | 'price'
        | 'formattedPrice'
        | 'paymentType'
        | 'type'
        | 'personalInformationRequirements'
        | 'hideSalesNumber'
      > & {
        collection?: Maybe<Pick<Collection, 'id' | 'title'>>
        issuedArtist?: Maybe<Pick<Artist, 'id'>>
        sku?: Maybe<
          Pick<SkuUnit, 'id' | 'unitName' | 'price' | 'formattedPrice'> & {
            thumbnail?: Maybe<Pick<Image, 'uri'>>
          }
        >
        thumbnail: { front?: Maybe<Pick<Resource, 'uri'>> }
      }
      skus: Array<
        Pick<UserCartItemSku, 'skuId' | 'count'> & {
          sku: Pick<SkuUnit, 'id' | 'price' | 'formattedPrice' | 'unitName'> & {
            thumbnail?: Maybe<Pick<Image, 'uri'>>
          }
        }
      >
    }
  >
}

export type UserCartQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserCartQuery = {
  userCart?: Maybe<StoreCardDetailFragment>
  user?: Maybe<
    Pick<
      User,
      | 'id'
      | 'fullName'
      | 'phoneNumber'
      | 'formattedPhoneNumber'
      | 'isPhoneNumberVerified'
    > &
      UserAddressFragment
  >
}

export type ValidateCartQueryVariables = Exact<{
  inputs: ValidateCartInput
}>

export type ValidateCartQuery = { validateCart: Pick<UserCart, 'id'> }

export type AddToCartMutationVariables = Exact<{
  inputs: AddToCartInput
}>

export type AddToCartMutation = {
  addToCart: Pick<
    UserCart,
    'id' | 'totalCount' | 'totalPrice' | 'formattedTotalPrice'
  > & {
    items: Array<
      Pick<UserCartItem, 'count' | 'totalPrice' | 'formattedTotalPrice'> & {
        item: Pick<Item, 'id' | 'price' | 'hideSalesNumber'>
        skus: Array<
          Pick<UserCartItemSku, 'skuId' | 'count'> & {
            sku: Pick<
              SkuUnit,
              'id' | 'price' | 'formattedPrice' | 'unitName'
            > & { thumbnail?: Maybe<Pick<Image, 'uri'>> }
          }
        >
      }
    >
  }
}

export type RemoveFromCartMutationVariables = Exact<{
  inputs: RemoveFromCartInput
}>

export type RemoveFromCartMutation = {
  removeFromCart?: Maybe<
    Pick<
      UserCart,
      'id' | 'totalCount' | 'totalPrice' | 'formattedTotalPrice'
    > & {
      items: Array<
        Pick<UserCartItem, 'count' | 'totalPrice' | 'formattedTotalPrice'> & {
          item: Pick<Item, 'id' | 'price' | 'hideSalesNumber'>
          skus: Array<
            Pick<UserCartItemSku, 'skuId' | 'count'> & {
              sku: Pick<
                SkuUnit,
                'id' | 'price' | 'formattedPrice' | 'unitName'
              > & { thumbnail?: Maybe<Pick<Image, 'uri'>> }
            }
          >
        }
      >
    }
  >
}

export type UserCartsConnectionFragment = {
  edges: Array<{
    node: Pick<
      UserCart,
      'id' | 'totalCount' | 'totalPrice' | 'formattedTotalPrice'
    > & {
      artist: Pick<Artist, 'id' | 'displayName'> & {
        avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
      }
      items: Array<
        Pick<UserCartItem, 'count'> & {
          item: Pick<Item, 'id' | 'price' | 'hideSalesNumber'>
          skus: Array<
            Pick<UserCartItemSku, 'count'> & {
              sku: Pick<SkuUnit, 'id' | 'price'>
            }
          >
        }
      >
    }
  }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type StoreCartListQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
}>

export type StoreCartListQuery = { userCarts: UserCartsConnectionFragment }

export type DeleteCartMutationVariables = Exact<{
  inputs: DeleteCartInput
}>

export type DeleteCartMutation = { deleteCart: Pick<UserCart, 'id'> }

export type StoreCollectionItemsConnectionFragment = {
  edges: Array<{
    node: Pick<CollectionItem, 'id'> & {
      item: Pick<
        Item,
        | 'id'
        | 'title'
        | 'price'
        | 'formattedPrice'
        | 'remainingNumber'
        | 'formattedRemainingNumber'
        | 'paymentType'
        | 'type'
        | 'salesStatus'
        | 'isPublished'
        | 'usedNumber'
        | 'purchasedNumber'
        | 'issuedNumber'
        | 'pendingPurchaseNumber'
        | 'hideSalesNumber'
        | 'isResaleAvailable'
        | 'isPremium'
      > & {
        rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
        rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
        thumbnail: {
          front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>>
          back?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>>
        }
        skuSettings?: Maybe<
          Pick<SkuSettings, 'keyName'> & {
            units: Array<Pick<SkuUnit, 'id' | 'unitName' | 'price' | 'order'>>
          }
        >
      }
    }
  }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type StoreCollectionDetailsQueryVariables = Exact<{
  collectionId: Scalars['ID']
  collectionItemsLabel?: Maybe<Scalars['String']>
  collectionItemsFirstLabel?: Maybe<Scalars['Boolean']>
  pageInput?: Maybe<PageInput>
  userId?: Maybe<Scalars['ID']>
}>

export type StoreCollectionDetailsQuery = Pick<Query, 'userCartCount'> & {
  user?: Maybe<
    Pick<User, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
    }
  >
  userCarts: UserCartsConnectionFragment
  collection?: Maybe<
    Pick<
      Collection,
      | 'id'
      | 'title'
      | 'artistId'
      | 'isEventPickup'
      | 'isPublishOnStore'
      | 'itemCount'
      | 'description'
    > & {
      artist: Pick<Artist, 'id' | 'displayName'>
      thumbnail: Pick<CollectionThumbnail, 'uri'>
      userCollection?: Maybe<
        Pick<
          UserCollection,
          | 'itemOwnedCount'
          | 'totalOwnedCount'
          | 'totalOwnedItemCount'
          | 'id'
          | 'userId'
        > & {
          ogp?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
          ownedItems?: Maybe<
            Array<Pick<UserCollectionItem, 'itemId' | 'ownedCount'>>
          >
        }
      >
      labels?: Maybe<Array<Maybe<Pick<CollectionLabel, 'name' | 'id'>>>>
      collectionItems: StoreCollectionItemsConnectionFragment
    }
  >
}

export type StoreCollectionDetailsForGuestQueryVariables = Exact<{
  collectionId: Scalars['ID']
  collectionItemsLabel?: Maybe<Scalars['String']>
  collectionItemsFirstLabel?: Maybe<Scalars['Boolean']>
  pageInput?: Maybe<PageInput>
}>

export type StoreCollectionDetailsForGuestQuery = {
  collection?: Maybe<
    Pick<
      Collection,
      | 'id'
      | 'title'
      | 'artistId'
      | 'isEventPickup'
      | 'isPublishOnStore'
      | 'itemCount'
      | 'description'
    > & {
      artist: Pick<Artist, 'id' | 'displayName'>
      thumbnail: Pick<CollectionThumbnail, 'uri'>
      labels?: Maybe<Array<Maybe<Pick<CollectionLabel, 'name' | 'id'>>>>
      collectionItems: StoreCollectionItemsConnectionFragment
    }
  >
}

export type StoreCollectionsSelectQueryVariables = Exact<{
  artistId: Scalars['String']
  pageInput?: Maybe<PageInput>
}>

export type StoreCollectionsSelectQuery = {
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    'balance' | 'prepaidPointId' | 'formattedPrepaidPointBarance'
  >
  storeCollections: {
    edges: Array<{ node: ArtistStoreCollectionBoxFragment }>
    pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
  }
}

export type UserCartTransactionOrderFragment = Pick<
  UserCartTransactionOrder,
  | 'id'
  | 'createdAt'
  | 'isKonbini'
  | 'itemOrderIds'
  | 'paymentType'
  | 'totalAmount'
  | 'formattedTotalAmount'
  | 'originalTotalAmount'
  | 'formattedOriginalTotalAmount'
> & {
  checkoutFees?: Maybe<
    Pick<CheckoutFees, 'totalFee'> & {
      shipping?: Maybe<FeeDetailFragment>
      konbini?: Maybe<FeeDetailFragment>
      collections?: Maybe<Array<Maybe<CollectionFeeDetailFragment>>>
    }
  >
  items: Array<
    Pick<UserCartTransactionItem, 'itemId' | 'amount' | 'count'> & {
      item: {
        collection?: Maybe<Pick<Collection, 'id' | 'title'>>
      } & ItemBoxFragment
      skus: Array<
        Maybe<
          Pick<UserCartItemSku, 'skuId' | 'count'> & {
            sku: Pick<
              SkuUnit,
              | 'id'
              | 'unitName'
              | 'order'
              | 'pendingPurchaseNumber'
              | 'purchasedNumber'
              | 'stock'
            > & {
              thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri' | 'mediumUri'>>
            }
          }
        >
      >
    }
  >
  pendingPurchaseItem?: Maybe<PendingPurchaseItemDetailFragment>
}

export type StoreItemCompleteDetailFragment = Pick<
  Item,
  'id' | 'title' | 'hideSalesNumber' | 'issuedNumber' | 'commentCount' | 'price'
> & {
  rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
  rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
  thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>> }
  sku?: Maybe<
    Pick<SkuUnit, 'id' | 'unitName' | 'price'> & {
      thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>>
    }
  >
  skuSettings?: Maybe<
    Pick<SkuSettings, 'keyName'> & {
      units: Array<
        Pick<SkuUnit, 'id' | 'unitName' | 'price'> & {
          thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>>
        }
      >
    }
  >
}

export type StoreItemCompleteFreeQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type StoreItemCompleteFreeQuery = {
  portfolio?: Maybe<
    Pick<UserItem, 'id' | 'number'> & { item: StoreItemCompleteDetailFragment }
  >
}

export type StoreItemPurchaseCompleteQueryVariables = Exact<{
  userCartTransactionOrderId: Scalars['ID']
}>

export type StoreItemPurchaseCompleteQuery = {
  userCartTransactionOrder: UserCartTransactionOrderFragment
}

export type UserCartStoreItemPurchaseConfirmationFragment = Pick<
  UserCart,
  'id' | 'paymentType' | 'totalCount' | 'totalPrice' | 'formattedTotalPrice'
> & {
  artist: Pick<Artist, 'id'>
  items: Array<
    Pick<
      UserCartItem,
      'count' | 'itemId' | 'totalPrice' | 'formattedTotalPrice'
    > & {
      item: Pick<
        Item,
        | 'id'
        | 'title'
        | 'price'
        | 'formattedPrice'
        | 'paymentType'
        | 'type'
        | 'hideSalesNumber'
        | 'purchaseLimitByUser'
        | 'purchaseLimitPerOrder'
        | 'ownedCount'
        | 'personalInformationRequirements'
      > & {
        collection?: Maybe<
          Pick<Collection, 'id' | 'title'> & {
            event?: Maybe<
              Pick<Event, 'id' | 'title' | 'note'> & {
                eventCollections: {
                  edges: Array<{
                    node: UserCartStoreItemConfirmationEventCollectionFragment
                  }>
                }
              }
            >
          }
        >
        issuedArtist?: Maybe<Pick<Artist, 'id' | 'displayName'>>
        sku?: Maybe<
          Pick<SkuUnit, 'id' | 'unitName' | 'price' | 'formattedPrice'> & {
            thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
          }
        >
        thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
      }
      skus: Array<
        Pick<UserCartItemSku, 'skuId' | 'count'> & {
          sku: Pick<SkuUnit, 'id' | 'unitName' | 'price' | 'formattedPrice'> & {
            thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
          }
        }
      >
    }
  >
}

export type UserCartStoreItemConfirmationEventCollectionFragment = Pick<
  EventCollection,
  'id' | 'eventId' | 'validMinutes' | 'collectionId' | 'purchaseLimitPerPickup'
> & {
  myPickupUsers?: Maybe<
    Array<
      Pick<
        PickupUser,
        'id' | 'userId' | 'pickupTime' | 'formattedPickupStartEndTime'
      > & {
        cartTransactionOrders: Array<
          Pick<
            UserCartTransactionOrder,
            'id' | 'totalAmount' | 'paymentType'
          > & {
            items: Array<Pick<UserCartTransactionItem, 'itemId' | 'count'>>
          }
        >
      }
    >
  >
  pickupTime?: Maybe<
    Array<{
      options: Pick<
        EventPickupTimeOption,
        'availableUserNumber' | 'currentUserNumber' | 'time'
      >
    }>
  >
}

export type CheckoutCartMutationVariables = Exact<{
  inputs: CheckoutCartInput
}>

export type CheckoutCartMutation = {
  checkoutCart: Pick<UserCartTransactionOrder, 'id' | 'isKonbini'>
}

export type StoreItemPurchaseConfirmationQueryVariables = Exact<{
  cartId: Scalars['ID']
  isKonbini?: Maybe<Scalars['Boolean']>
}>

export type StoreItemPurchaseConfirmationQuery = {
  userCart?: Maybe<
    {
      checkoutFees?: Maybe<
        Pick<CheckoutFees, 'totalFee'> & {
          shipping?: Maybe<FeeDetailFragment>
          collections?: Maybe<Array<Maybe<CollectionFeeDetailFragment>>>
          konbini?: Maybe<FeeDetailFragment>
        }
      >
    } & UserCartStoreItemPurchaseConfirmationFragment
  >
  paymentSource?: Maybe<PaymentDetailFragment>
  user?: Maybe<
    Pick<
      User,
      | 'id'
      | 'fullName'
      | 'phoneNumber'
      | 'formattedPhoneNumber'
      | 'isPhoneNumberVerified'
    > &
      UserAddressFragment
  >
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    | 'lastUpdatedAt'
    | 'prepaidPointId'
    | 'balance'
    | 'formattedPrepaidPointBarance'
  >
}

export type UserCartStoreItemPurchaseProceedFragment = Pick<
  UserCart,
  'id' | 'paymentType' | 'totalCount' | 'totalPrice' | 'formattedTotalPrice'
> & {
  items: Array<
    Pick<
      UserCartItem,
      'count' | 'itemId' | 'totalPrice' | 'formattedTotalPrice'
    > & {
      item: Pick<
        Item,
        | 'id'
        | 'title'
        | 'price'
        | 'formattedPrice'
        | 'paymentType'
        | 'hideSalesNumber'
        | 'type'
        | 'purchaseLimitByUser'
        | 'purchaseLimitPerOrder'
        | 'ownedCount'
        | 'personalInformationRequirements'
      > & {
        collection?: Maybe<
          Pick<Collection, 'id' | 'title'> & {
            event?: Maybe<
              Pick<Event, 'id' | 'title' | 'note'> & {
                eventCollections: {
                  edges: Array<{
                    node: UserCartStoreItemProceedEventCollectionFragment
                  }>
                }
              }
            >
          }
        >
        issuedArtist?: Maybe<Pick<Artist, 'id'>>
        sku?: Maybe<
          Pick<SkuUnit, 'id' | 'unitName' | 'price' | 'formattedPrice'> & {
            thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
          }
        >
        thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'smallUri'>> }
      }
      skus: Array<
        Pick<UserCartItemSku, 'skuId' | 'count'> & {
          sku: Pick<SkuUnit, 'id' | 'unitName' | 'price' | 'formattedPrice'> & {
            thumbnail?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
          }
        }
      >
    }
  >
}

export type UserCartStoreItemProceedEventCollectionFragment = Pick<
  EventCollection,
  'id' | 'eventId' | 'validMinutes' | 'collectionId' | 'purchaseLimitPerPickup'
> & {
  myPickupUsers?: Maybe<
    Array<
      Pick<
        PickupUser,
        'id' | 'userId' | 'pickupTime' | 'formattedPickupStartEndTime'
      > & {
        cartTransactionOrders: Array<
          Pick<
            UserCartTransactionOrder,
            'id' | 'totalAmount' | 'paymentType'
          > & {
            items: Array<Pick<UserCartTransactionItem, 'itemId' | 'count'>>
          }
        >
      }
    >
  >
  pickupTime?: Maybe<
    Array<{
      options: Pick<
        EventPickupTimeOption,
        'availableUserNumber' | 'currentUserNumber' | 'time'
      >
    }>
  >
}

export type StoreItemPurchaseProceedQueryVariables = Exact<{
  cartId: Scalars['ID']
  platform: Scalars['String']
  purchasablePrepaidPointPageInput?: Maybe<PageInput>
  isKonbini?: Maybe<Scalars['Boolean']>
}>

export type StoreItemPurchaseProceedQuery = {
  userCart?: Maybe<
    {
      checkoutFees?: Maybe<
        Pick<CheckoutFees, 'totalFee'> & {
          shipping?: Maybe<FeeDetailFragment>
          collections?: Maybe<Array<Maybe<CollectionFeeDetailFragment>>>
          konbini?: Maybe<FeeDetailFragment>
        }
      >
    } & UserCartStoreItemPurchaseProceedFragment
  >
  paymentSource?: Maybe<PaymentDetailFragment>
  user?: Maybe<
    Pick<
      User,
      | 'id'
      | 'fullName'
      | 'phoneNumber'
      | 'formattedPhoneNumber'
      | 'isPhoneNumberVerified'
    > &
      UserAddressFragment
  >
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    | 'lastUpdatedAt'
    | 'prepaidPointId'
    | 'balance'
    | 'formattedPrepaidPointBarance'
  >
  purchasablePrepaidPoints: PurchasablePrepaidPointConnectionFragment
}

export type StorePurchaseOrderDetailQueryVariables = Exact<{
  id: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type StorePurchaseOrderDetailQuery = {
  userCartTransactionOrder: StorePurchaseHistoryFragment
  user?: Maybe<
    Pick<
      User,
      | 'id'
      | 'fullName'
      | 'phoneNumber'
      | 'formattedPhoneNumber'
      | 'isPhoneNumberVerified'
    > &
      UserAddressFragment
  >
  paymentSource?: Maybe<PaymentDetailFragment>
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    | 'lastUpdatedAt'
    | 'prepaidPointId'
    | 'balance'
    | 'formattedPrepaidPointBarance'
  >
}

export type StorePurchaseOrderConnectionFragment = {
  edges: Array<{ node: StoreOrderListHistoryFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type StoreOrderListHistoryFragment = Pick<
  UserCartTransactionOrder,
  | 'id'
  | 'paymentType'
  | 'createdAt'
  | 'formattedCreatedAt'
  | 'totalAmount'
  | 'formattedTotalAmount'
> & {
  items: Array<
    Pick<UserCartTransactionItem, 'count'> & {
      item: Pick<Item, 'title'>
      skus: Array<
        Maybe<
          Pick<UserCartItemSku, 'count' | 'skuId'> & {
            sku: Pick<SkuUnit, 'id' | 'unitName' | 'price'>
          }
        >
      >
    }
  >
  cancels?: Maybe<Array<Pick<CartCancel, 'canceledAt'>>>
}

export type StorePurchaseOrdersQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
  skuId?: Maybe<Scalars['String']>
}>

export type StorePurchaseOrdersQuery = {
  userCartTransactionOrders: StorePurchaseOrderConnectionFragment
}

export type StoreRequestProvidableItemSearchQueryVariables = Exact<{
  [key: string]: never
}>

export type StoreRequestProvidableItemSearchQuery = {
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    'prepaidPointId' | 'balance' | 'formattedPrepaidPointBarance'
  >
}

export type StoreUserInfoFragment = Pick<User, 'fullName' | 'phoneNumber'>

export type StoreUserInfoQueryVariables = Exact<{ [key: string]: never }>

export type StoreUserInfoQuery = {
  user?: Maybe<
    Pick<User, 'id' | 'formattedPhoneNumber'> & UserDeliveryInfoFragment
  >
}

export type UpdateStoreUserInfoMutationVariables = Exact<{
  updateUserDeliveryInfoInputs: UserDeliveryInput
}>

export type UpdateStoreUserInfoMutation = {
  updateUserDeliveryInfo: UserDeliveryInfoFragment
}

export type ThreadCreateQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>
}>

export type ThreadCreateQuery = {
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      patron?: Maybe<Pick<Patron, 'id'>>
      avatar?: Maybe<Pick<Avatar, 'uri'>>
    }
  >
  user?: Maybe<
    Pick<User, 'id' | 'displayName'> & { avatar?: Maybe<Pick<Avatar, 'uri'>> }
  >
}

export type CreateArtistThreadChannelMutationVariables = Exact<{
  inputs: CreateArtistThreadInput
}>

export type CreateArtistThreadChannelMutation = {
  createArtistThreadChannel: ThreadBoxFragment
}

export type ThreadDetailQueryVariables = Exact<{
  artistThreadChannelId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type ThreadDetailQuery = {
  artistThreadChannel: {
    comments: CommentsConnectionFragment
  } & ThreadBoxFragment
  user?: Maybe<
    Pick<User, 'id' | 'accountId' | 'formattedAccountId' | 'email'> & {
      avatar?: Maybe<Pick<Avatar, 'uri'>>
    }
  >
}

export type LikeArtistThreadChannelMutationVariables = Exact<{
  likeArtistThreadChannelId: Scalars['ID']
}>

export type LikeArtistThreadChannelMutation = {
  likeArtistThreadChannel: Pick<
    ArtistThreadChannel,
    'id' | 'likeCount' | 'isLike'
  >
}

export type UnlikeArtistThreadChannelMutationVariables = Exact<{
  unlikeArtistThreadChannelId: Scalars['ID']
}>

export type UnlikeArtistThreadChannelMutation = {
  unlikeArtistThreadChannel: Pick<
    ArtistThreadChannel,
    'id' | 'likeCount' | 'isLike'
  >
}

export type CreateArtistThreadCommentMutationVariables = Exact<{
  id: Scalars['String']
  inputs: CommunityCommentInput
}>

export type CreateArtistThreadCommentMutation = {
  createArtistThreadComment: CommentBoxFragment
}

export type DeleteArtistThreadCommentMutationVariables = Exact<{
  id: Scalars['String']
  commentId: Scalars['String']
}>

export type DeleteArtistThreadCommentMutation = {
  deleteArtistThreadComment: CommentBoxFragment
}

export type ArchiveArtistThreadChannelMutationVariables = Exact<{
  archiveArtistThreadChannelId: Scalars['ID']
}>

export type ArchiveArtistThreadChannelMutation = {
  archiveArtistThreadChannel: ThreadBoxFragment
}

export type LikeThreadCommentMutationVariables = Exact<{
  inputs: CommunityCommentLikeUnlikeInput
}>

export type LikeThreadCommentMutation = {
  likeArtistThreadChannelComment: Pick<Comment, 'id' | 'isLike' | 'likeCount'>
}

export type UnlikeThreadCommentMutationVariables = Exact<{
  inputs: CommunityCommentLikeUnlikeInput
}>

export type UnlikeThreadCommentMutation = {
  unlikeArtistThreadChannelComment: Pick<Comment, 'id' | 'isLike' | 'likeCount'>
}

export type UpdateThreadUnreadMutationVariables = Exact<{
  inputs: UpdateUnreadInput
}>

export type UpdateThreadUnreadMutation = {
  updateUserUnread: Pick<
    UserUnread,
    | 'id'
    | 'lastReadAt'
    | 'parentId'
    | 'totalUnreadMentionCount'
    | 'unreadMentionCount'
    | 'unreadType'
  >
}

export type UpdateArtistThreadMuteStatusMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type UpdateArtistThreadMuteStatusMutation = {
  updateArtistThreadMuteStatus: Pick<
    ArtistThreadChannel,
    'id' | 'isAllowNotification'
  >
}

export type ThreadEditQueryVariables = Exact<{
  artistThreadChannelId: Scalars['ID']
  artistId?: Maybe<Scalars['ID']>
}>

export type ThreadEditQuery = {
  artistThreadChannel: ThreadBoxFragment
  artist?: Maybe<
    Pick<Artist, 'id' | 'displayName'> & {
      avatar?: Maybe<Pick<Avatar, 'uri'>>
      patron?: Maybe<Pick<Patron, 'id'>>
    }
  >
  user?: Maybe<
    Pick<User, 'id' | 'displayName'> & { avatar?: Maybe<Pick<Avatar, 'uri'>> }
  >
}

export type UpdateArtistThreadChannelMutationVariables = Exact<{
  updateArtistThreadChannelId: Scalars['ID']
  inputs: UpdateArtistThreadInput
}>

export type UpdateArtistThreadChannelMutation = {
  updateArtistThreadChannel: ThreadBoxFragment
}

export type TicketChargeQueryVariables = Exact<{
  inputs?: Maybe<PurchasableGachaTicketInput>
  gachaTicketId: Scalars['ID']
}>

export type TicketChargeQuery = {
  user?: Maybe<
    Pick<User, 'id'> & {
      gachaTicket?: Maybe<UserGachaTicketConnectionFragment>
    }
  >
  purchasableGachaTickets: Array<
    Maybe<
      Pick<
        PurchasableGachaTicket,
        | 'price'
        | 'displayCount'
        | 'count'
        | 'prepaidPoints'
        | 'formattedPrice'
        | 'formattedPrepaidPoint'
      >
    >
  >
  gachaTicket?: Maybe<GachaTicketConnectionFragment>
}

export type GachaTicketOrdersConnectionFragment = {
  edges: Array<{ node: GachaTicketOrderBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type TicketOrdersQueryVariables = Exact<{
  pageInput?: Maybe<PageInput>
}>

export type TicketOrdersQuery = {
  gachaTicketOrders: GachaTicketOrdersConnectionFragment
}

export type TicketShareConfirmFragment = Pick<
  TicketDistributionLink,
  'id' | 'expiredAt' | 'isExpired' | 'formattedExpiredAt' | 'userId'
> & {
  item: Pick<
    Item,
    'id' | 'title' | 'ownedCount' | 'isTicketDistributionEnabled'
  > & {
    preSale?: Maybe<Pick<PreSale, 'title' | 'id'>>
    issuedArtist?: Maybe<Pick<Artist, 'id'>>
    metadata?: Maybe<{
      ticket?: Maybe<
        Pick<
          ItemMetadataDetailTicket,
          | 'assignedNumberType'
          | 'distributionEnabled'
          | 'distributionEndedAt'
          | 'isTicketDistributionEnabled'
        >
      >
    }>
  }
  serial: Pick<
    ItemSerial,
    'serialNumber' | 'formattedSerialNumber' | 'formattedAssignedNumber'
  > & {
    sku?: Maybe<Pick<SkuUnit, 'unitName' | 'id'>>
    ticketStatus?: Maybe<{
      throughTicket?: Maybe<
        Array<Pick<TicketStatusThroughTicket, 'seat' | 'name'>>
      >
    }>
  }
}

export type TicketDistributionLinkQueryVariables = Exact<{
  token: Scalars['String']
}>

export type TicketDistributionLinkQuery = {
  ticketDistributionLink?: Maybe<TicketShareConfirmFragment>
}

export type ReceiveTicketDistributionMutationVariables = Exact<{
  inputs: ReceiveTicketDistributionInput
}>

export type ReceiveTicketDistributionMutation = {
  receiveTicketDistribution: Pick<
    TicketDistributionLink,
    'id' | 'distributionId'
  >
}

export type TicketShareCopyUserItemSerialFragment = Pick<
  UserItemSerial,
  'id'
> & {
  serial?: Maybe<
    Pick<
      ItemSerial,
      'id' | 'formattedSerialNumber' | 'formattedAssignedNumber'
    > & {
      ticketDistributionLink?: Maybe<
        Pick<
          TicketDistributionLink,
          'id' | 'distributionId' | 'expiredAt' | 'formattedExpiredAt' | 'token'
        > & {
          user?: Maybe<
            Pick<User, 'id' | 'displayName'> & {
              avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>>
            }
          >
        }
      >
      ticketStatus?: Maybe<{
        throughTicket?: Maybe<Array<Pick<TicketStatusThroughTicket, 'seat'>>>
      }>
    }
  >
}

export type TicketShareCopyQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type TicketShareCopyQuery = {
  portfolio?: Maybe<{
    serials: Array<TicketShareCopyUserItemSerialFragment>
    item: {
      issuedArtist?: Maybe<Pick<Artist, 'id'>>
      metadata?: Maybe<{
        ticket?: Maybe<Pick<ItemMetadataDetailTicket, 'isThroughTicket'>>
      }>
    }
  }>
}

export type RegenerateTicketDistributionLinkMutationVariables = Exact<{
  inputs: RegenerateTicketDistributionLinkInput
}>

export type RegenerateTicketDistributionLinkMutation = {
  regenerateTicketDistributionLink: Pick<
    TicketDistributionLink,
    'id' | 'distributionId' | 'expiredAt' | 'formattedExpiredAt' | 'token'
  >
}

export type CancelTicketDistributionMutationVariables = Exact<{
  inputs: CancelTicketDistributionInput
}>

export type CancelTicketDistributionMutation = {
  cancelTicketDistribution: Pick<
    TicketDistributionLink,
    | 'id'
    | 'distributionId'
    | 'expiredAt'
    | 'formattedExpiredAt'
    | 'token'
    | 'serialId'
  >
}

export type TicketShareSelectUserItemSerialFragment = Pick<
  UserItemSerial,
  'id'
> & {
  serial?: Maybe<
    Pick<
      ItemSerial,
      'id' | 'formattedSerialNumber' | 'formattedAssignedNumber'
    > & {
      ticketStatus?: Maybe<{
        throughTicket?: Maybe<Array<Pick<TicketStatusThroughTicket, 'seat'>>>
      }>
      ticketDistributionLink?: Maybe<
        Pick<TicketDistributionLink, 'id' | 'expiredAt' | 'isExpired' | 'token'>
      >
    }
  >
}

export type TicketShareSelectQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type TicketShareSelectQuery = {
  portfolio?: Maybe<{
    item: {
      metadata?: Maybe<{
        ticket?: Maybe<
          Pick<
            ItemMetadataDetailTicket,
            'assignedNumberType' | 'isThroughTicket'
          >
        >
      }>
      issuedArtist?: Maybe<Pick<Artist, 'id'>>
    }
    serials: Array<TicketShareSelectUserItemSerialFragment>
  }>
}

export type GenerateTicketDistributionLinkMutationVariables = Exact<{
  inputs: GenerateTicketDistributionLinkInput
}>

export type GenerateTicketDistributionLinkMutation = {
  generateTicketDistributionLink: Pick<TicketDistributionLink, 'id'>
}

export type ShortLinkQueryVariables = Exact<{
  shortLinkId: Scalars['ID']
}>

export type ShortLinkQuery = {
  shortLink: Pick<ShortLink, 'id' | 'redirectUrl' | 'shortLink' | 'createdAt'>
}

export type CreateShortLinkMutationVariables = Exact<{
  redirectUrl: Scalars['String']
}>

export type CreateShortLinkMutation = {
  createShortLink: Pick<
    ShortLink,
    'createdAt' | 'id' | 'redirectUrl' | 'shortLink'
  >
}

export type UserItemDetailBoxFragment = Pick<
  UserItem,
  | 'id'
  | 'isUsed'
  | 'number'
  | 'type'
  | 'createdAt'
  | 'isSystemProtected'
  | 'isUserProtected'
> & {
  item: ItemDetailBoxFragment
  serials: Array<
    Pick<
      UserItemSerial,
      'id' | 'isUsed' | 'isProtected' | 'formattedSerialNumber' | 'serialId'
    > & {
      serial?: Maybe<
        Pick<ItemSerial, 'id' | 'formattedAssignedNumber'> & {
          ticketStatus?: Maybe<TicketStatusDetailFragment>
          sku?: Maybe<Pick<SkuUnit, 'id'>>
        }
      >
    }
  >
}

export type ItemDetailBoxFragment = Pick<
  Item,
  | 'id'
  | 'type'
  | 'hideSalesNumber'
  | 'purchaseLimitByUser'
  | 'purchasedByPointNumber'
  | 'price'
  | 'formattedPrice'
  | 'resaleEnabled'
  | 'thumbnailType'
  | 'resaleOpenCount'
  | 'resaleRaffleAppliedCount'
  | 'description'
  | 'issuedNumber'
  | 'title'
  | 'commentCount'
> & {
  issuedArtist?: Maybe<Pick<Artist, 'id'>>
  preSale?: Maybe<
    Pick<PreSale, 'id'> & { event: Pick<Event, 'id' | 'title' | 'description'> }
  >
  images?: Maybe<
    Array<
      Pick<
        ItemImage,
        'uri' | 'smallUri' | 'mediumUri' | 'compressedUri' | 'caption'
      >
    >
  >
  thumbnail: {
    front?: Maybe<
      Pick<Resource, 'uri' | 'smallUri' | 'mediumUri' | 'compressedUri'>
    >
    back?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>>
  }
  rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
  rarity?: Maybe<Pick<Rarity, 'id' | 'name'>>
  nft?: Maybe<{ chains?: Maybe<Array<Pick<ItemNftChain, 'chainName'>>> }>
  metadata?: Maybe<{
    ticket?: Maybe<
      Pick<
        ItemMetadataDetailTicket,
        | 'expirationTerm'
        | 'note'
        | 'description'
        | 'assignedNumberType'
        | 'isThroughTicket'
      > & {
        throughTicket?: Maybe<
          Array<Pick<TicketItemMetadataThroughTicket, 'name'>>
        >
        resaleFee?: Maybe<
          Pick<
            ItemResaleFees,
            | 'bankTransferFeePerTicket'
            | 'bankTransferFeePerOrder'
            | 'feeRatePerTicket'
            | 'purchaseFee'
          >
        >
        resalePeriod?: Maybe<
          Pick<
            ReceptionPeriod,
            'startedAt' | 'endedAt' | 'formattedStartedAt' | 'formattedEndedAt'
          >
        >
      }
    >
    magazine?: Maybe<Pick<ItemMetadataDetailMagazine, 'pageDirection'>>
    wallpaper?: Maybe<
      Pick<ItemWallpaperMetadata, 'isPrintEnabled' | 'printCount' | 'printType'>
    >
  }>
  purchasableUsers?: Maybe<Array<PurchasableUserDetailFragment>>
  sku?: Maybe<
    Pick<
      SkuUnit,
      | 'id'
      | 'order'
      | 'pendingPurchaseNumber'
      | 'purchasedNumber'
      | 'stock'
      | 'unitName'
      | 'price'
      | 'formattedPrice'
    > & {
      thumbnail?: Maybe<
        Pick<Image, 'uri' | 'smallUri' | 'mediumUri' | 'compressedUri'>
      >
    }
  >
  skuSettings?: Maybe<
    Pick<SkuSettings, 'keyName'> & {
      units: Array<
        Pick<
          SkuUnit,
          | 'id'
          | 'order'
          | 'pendingPurchaseNumber'
          | 'purchasedNumber'
          | 'stock'
          | 'price'
          | 'formattedPrice'
          | 'unitName'
        > & {
          thumbnail?: Maybe<
            Pick<Image, 'uri' | 'smallUri' | 'mediumUri' | 'compressedUri'>
          >
        }
      >
    }
  >
}

export type UserItemDetailQueryVariables = Exact<{
  itemId: Scalars['ID']
  userId?: Maybe<Scalars['String']>
  uid: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type UserItemDetailQuery = {
  portfolio?: Maybe<UserItemDetailBoxFragment>
  user?: Maybe<
    Pick<User, 'id'> & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> }
  >
  userLoggedIn?: Maybe<Pick<User, 'id'>>
}

export type UserItemDetailForGuestQueryVariables = Exact<{
  id: Scalars['ID']
  userId: Scalars['ID']
  skuId?: Maybe<Scalars['String']>
}>

export type UserItemDetailForGuestQuery = {
  item?: Maybe<ItemDetailBoxFragment>
  user?: Maybe<
    Pick<User, 'id'> & { avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri'>> }
  >
}

export type HasItemUserItemDetailQueryVariables = Exact<{
  itemId: Scalars['ID']
  userId?: Maybe<Scalars['String']>
}>

export type HasItemUserItemDetailQuery = {
  portfolio?: Maybe<
    Pick<UserItem, 'id'> & {
      serials: Array<{ serial?: Maybe<Pick<ItemSerial, 'skuId'>> }>
    }
  >
}

export type UserOwnedItemsConnectionFragment = {
  edges: Array<{ node: UserOwnedItemDetailFragment }>
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor'>
}

export type UserOwnedItemDetailFragment = Pick<UserItem, 'id' | 'number'> & {
  serials: Array<
    Pick<UserItemSerial, 'id' | 'serialId'> & {
      serial?: Maybe<
        Pick<ItemSerial, 'id'> & { sku?: Maybe<Pick<SkuUnit, 'id'>> }
      >
    }
  >
  item: UserOwnedItemDetailBoxFragment
}

export type UserOwnedItemDetailBoxFragment = Pick<
  Item,
  'id' | 'type' | 'title'
> & {
  thumbnail: { front?: Maybe<Pick<Resource, 'uri' | 'mediumUri'>> }
  rarityIconImage?: Maybe<Pick<Image, 'uri' | 'smallUri'>>
  rarity?: Maybe<Pick<Rarity, 'id'>>
  nft?: Maybe<{ chains?: Maybe<Array<Pick<ItemNftChain, 'chainName'>>> }>
  metadata?: Maybe<{
    wallpaper?: Maybe<Pick<ItemWallpaperMetadata, 'isPrintEnabled'>>
  }>
  sku?: Maybe<
    Pick<SkuUnit, 'id' | 'unitName'> & {
      thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>>
    }
  >
  skuSettings?: Maybe<{
    units: Array<
      Pick<SkuUnit, 'id' | 'unitName'> & {
        thumbnail?: Maybe<Pick<Image, 'uri' | 'mediumUri'>>
      }
    >
  }>
}

export type UserOwnedItemsQueryVariables = Exact<{
  type?: Maybe<ItemType>
  pageInput?: Maybe<PageInput>
  userId?: Maybe<Scalars['ID']>
}>

export type UserOwnedItemsQuery = {
  user?: Maybe<
    Pick<User, 'id' | 'displayName'> & {
      organizationPortfolios: UserOwnedItemsConnectionFragment
    }
  >
}

export type UserCommonWithCustomProfileConnectionFragment = Pick<
  User,
  'id' | 'accountId' | 'formattedAccountId' | 'displayName' | 'introduction'
> & {
  avatar?: Maybe<Pick<Avatar, 'uri' | 'smallUri' | 'mediumUri'>>
  customProfile?: Maybe<{
    avatarItem?: Maybe<
      Pick<Item, 'id'> & {
        avatarResources?: Maybe<{
          avatarResource?: Maybe<
            Pick<Resource, 'uri' | 'smallUri' | 'compressedUri' | 'mediumUri'>
          >
        }>
      }
    >
    wallpaperItem?: Maybe<
      Pick<Item, 'id'> & {
        wallpaperResources?: Maybe<{
          wallpaperResource?: Maybe<
            Pick<Resource, 'uri' | 'mediumUri' | 'compressedUri' | 'smallUri'>
          >
        }>
      }
    >
  }>
}

export type PortfoliosConnectionFragment = {
  edges: Array<{
    node: Pick<UserItem, 'id'> & {
      item: Pick<Item, 'id' | 'title' | 'hideSalesNumber'> & {
        thumbnail: {
          front?: Maybe<
            Pick<Resource, 'uri' | 'smallUri' | 'mediumUri' | 'compressedUri'>
          >
        }
      }
    }
  }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type UserCollectionsConnectionFragment = {
  edges: Array<{ node: CollectionBoxFragment }>
  pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>
}

export type UserProfileQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>
  userId?: Maybe<Scalars['String']>
  pageInput?: Maybe<PageInput>
  pageInputCollection?: Maybe<PageInput>
}>

export type UserProfileQuery = {
  user?: Maybe<
    {
      organizationPortfolios: PortfoliosConnectionFragment
    } & UserCommonWithCustomProfileConnectionFragment
  >
  organization?: Maybe<
    Pick<Organization, 'id'> & {
      entryCampaignRankings?: Maybe<
        Array<Maybe<EntryCampaignRankingConnectionFragment>>
      >
    }
  >
  ownedUserCollections: { edges: Array<{ node: UserCollectionBoxFragment }> }
  organizationArtistRankings: {
    edges: Array<{
      node: {
        latestRankingPeriod?: Maybe<
          {
            myRanking?: Maybe<RankingUserWithoutIdConnectionFragment>
          } & RankingPeriodBaseConnectionFragment
        >
      } & RankingBaseConnectionFragment
    }>
  }
}

export type UserTradeDetailQueryVariables = Exact<{
  tradeNegotiationId: Scalars['ID']
  pageInput?: Maybe<PageInput>
}>

export type UserTradeDetailQuery = {
  tradeNegotiation: Pick<
    TradeNegotiation,
    'expiredAt' | 'formattedExpiredAt'
  > & { comments: CommentsConnectionFragment } & TradeBoxFragment
}

export type WalletQueryVariables = Exact<{
  gachaTicketId: Scalars['ID']
}>

export type WalletQuery = {
  user?: Maybe<
    Pick<User, 'id' | 'displayName'> & {
      gachaTicket?: Maybe<
        Pick<UserGachaTicket, 'available' | 'formattedAvailable'>
      >
      cryptoWallet?: Maybe<Pick<CryptoWallet, 'address' | 'walletType'>>
    }
  >
  prepaidPointBalance: Pick<
    PrepaidPointBalance,
    'balance' | 'formattedPrepaidPointBarance'
  >
  gachaTicket?: Maybe<GachaTicketConnectionFragment>
}

export const UserAvatarFragmentDoc = gql`
  fragment UserAvatar on User {
    id
    displayName
    accountId
    avatar {
      uri
      smallUri
    }
  }
`
export const ItemCardFragmentDoc = gql`
  fragment ItemCard on Item {
    id
    hideSalesNumber
    sku @client {
      id
      order
      pendingPurchaseNumber
      purchasedNumber
      stock
      unitName
      thumbnail {
        uri
        mediumUri
        compressedUri
        smallUri
      }
    }
    skuSettings {
      keyName
      units {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        stock
        thumbnail {
          uri
          mediumUri
          compressedUri
          smallUri
        }
        unitName
      }
    }
  }
`
export const UserCollectionBoxFragmentDoc = gql`
  fragment UserCollectionBox on UserCollection {
    collection {
      id
      title
      isEventPickup
      thumbnail {
        uri
      }
      itemCount
      artistId
    }
    userId
    id
    itemOwnedCount
    totalOwnedItemCount @client
    ownedItems {
      itemId
      ownedCount
    }
  }
`
export const CommentBoxWithoutRepliesFragmentDoc = gql`
  fragment CommentBoxWithoutReplies on Comment {
    id
    commenter {
      ... on User {
        id
        displayName
        accountId
        formattedAccountId @client
        avatar {
          uri
          smallUri
        }
      }
      ... on Artist {
        id
        displayName
        accountId
        formattedAccountId @client
        avatar {
          uri
          smallUri
        }
      }
    }
    isLike
    content
    createdAt
    parentComment {
      id
    }
    formattedCreatedAt @client
    likeCount
    replyCount
    resource {
      uri
    }
    resources {
      uri
    }
    parentComment {
      id
    }
    tip {
      points
    }
  }
`
export const CommentBoxFragmentDoc = gql`
  fragment CommentBox on Comment {
    ...CommentBoxWithoutReplies
    replies {
      edges {
        node {
          ... on Comment {
            ...CommentBoxWithoutReplies
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const CommentsConnectionFragmentDoc = gql`
  fragment CommentsConnection on PageResult {
    edges {
      node {
        ...CommentBox
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${CommentBoxFragmentDoc}
`
export const ReplyBoxFragmentDoc = gql`
  fragment ReplyBox on PostReply {
    id
    content
    createdAt
    formattedCreatedAt @client
    replyToId
    isLike
    likeCount
    responder {
      ... on Artist {
        id
        accountId
        formattedAccountId @client
        displayName
        avatar {
          uri
          smallUri
        }
      }
      ... on User {
        id
        accountId
        formattedAccountId @client
        displayName
        avatar {
          uri
          smallUri
        }
      }
      ... on Artist {
        id
        accountId
        formattedAccountId @client
        displayName
        avatar {
          uri
          smallUri
        }
      }
    }
  }
`
export const RepliesConnectionFragmentDoc = gql`
  fragment RepliesConnection on PageResult {
    edges {
      node {
        ... on PostReply {
          ...ReplyBox
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${ReplyBoxFragmentDoc}
`
export const LoginBonusBoxFragmentDoc = gql`
  fragment LoginBonusBox on Bonus {
    type
    bonusKey
    metadata {
      count
      point
      gachaTicket {
        id
        thumbnails {
          standard {
            uri
            smallUri
          }
        }
      }
    }
  }
`
export const ConsecutiveLoginBonusModalFragmentDoc = gql`
  fragment ConsecutiveLoginBonusModal on LoginBonus {
    id
    loginBonusUser {
      id
      obtainedBonuses {
        ...LoginBonusBox
      }
    }
    todayTargetDate
    bonuses {
      ...LoginBonusBox
    }
  }
  ${LoginBonusBoxFragmentDoc}
`
export const ItemImageAreaFragmentDoc = gql`
  fragment ItemImageArea on Item {
    id
    thumbnailType
    hideSalesNumber
    issuedArtist {
      id
    }
    skuSettings {
      keyName
      units {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        stock
        thumbnail {
          uri
          smallUri
        }
        unitName
      }
    }
    thumbnail {
      front {
        uri
        mediumUri
      }
      back {
        uri
        mediumUri
      }
    }
    rarityIconImage {
      uri
      smallUri
    }
    rarity {
      id
      name
    }
    issuedNumber
    title
    sku @client {
      id
      order
      pendingPurchaseNumber
      purchasedNumber
      stock
      unitName
      thumbnail {
        uri
        smallUri
      }
    }
    skuSettings {
      keyName
      units {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        stock
        thumbnail {
          uri
          smallUri
        }
        unitName
      }
    }
  }
`
export const BonusElementFragmentDoc = gql`
  fragment BonusElement on Bonus {
    type
    bonusKey
    metadata {
      count
      point
      gachaTicket {
        thumbnails {
          standard {
            uri
            smallUri
          }
        }
      }
    }
  }
`
export const LoginBonusModalFragmentDoc = gql`
  fragment LoginBonusModal on LoginBonus {
    id
    startDate
    endDate
    todayTargetDate
    loginBonusUser {
      id
      obtainedBonuses {
        ...BonusElement
      }
    }
    bonuses {
      ...BonusElement
    }
    todayBonus @client {
      ...BonusElement
    }
  }
  ${BonusElementFragmentDoc}
`
export const MembershipPlanBoxFragmentDoc = gql`
  fragment MembershipPlanBox on MembershipSubscriptionPlan {
    status
    content
    amount
  }
`
export const ArtistStoreCollectionBoxFragmentDoc = gql`
  fragment ArtistStoreCollectionBox on Collection {
    id
    title
    thumbnail {
      uri
    }
    itemCount
    artistId
  }
`
export const StoreItemFragmentDoc = gql`
  fragment StoreItem on Item {
    id
    rarity {
      name
    }
    title
    price
    type
    formattedPrice @client
    salesStatus
    paymentType
    isPremium
    prepaidPoint {
      exchangeRate
    }
    pendingPurchaseNumber
    isResaleAvailable
    remainingNumber @client
    hideSalesNumber
    issuedNumber
    issuedArtist {
      id
    }
    resaleEnabled
    sku @client {
      id
      order
      pendingPurchaseNumber
      purchasedNumber
      stock
      unitName
      thumbnail {
        uri
        mediumUri
        compressedUri
        smallUri
      }
    }
    skuSettings {
      keyName
      units {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        stock
        price
        thumbnail {
          uri
          mediumUri
          compressedUri
          smallUri
        }
        unitName
      }
    }
    purchasedNumber
    paymentType
    pendingPurchaseNumber
    thumbnail {
      front {
        uri
        mediumUri
        compressedUri
        smallUri
      }
    }
    nft {
      chains {
        chainName
      }
    }
    metadata {
      wallpaper {
        isPrintEnabled
        printCount
        printType
      }
    }
    isDownloadable
  }
`
export const StoreItemAreaTemporaryFragmentDoc = gql`
  fragment StoreItemAreaTemporary on Item {
    id
    title
    isPremium
    collection {
      id
      title
    }
    issuedArtist {
      id
    }
    thumbnail {
      front {
        uri
        smallUri
      }
    }
    price
    formattedPrice @client
    paymentType
  }
`
export const FeeDetailFragmentDoc = gql`
  fragment FeeDetail on Fee {
    fee
    formattedFee @client
    feeDescription
    feeName
    feeType
  }
`
export const CollectionFeeDetailFragmentDoc = gql`
  fragment CollectionFeeDetail on Fee {
    id
    fee
    feeDescription
    feeName
    feeType
    collectionFee @client {
      title
    }
  }
`
export const SkuTagFragmentDoc = gql`
  fragment SkuTag on SkuUnit {
    id
    order
    pendingPurchaseNumber
    purchasedNumber
    stock
    thumbnail {
      smallUri
      uri
      mediumUri
      compressedUri
    }
    price
    formattedPrice @client
    remainingNumber @client
    unitName
  }
`
export const SkuTypeFragmentDoc = gql`
  fragment SkuType on SkuSettings {
    keyName
    units {
      ...SkuTag
    }
  }
  ${SkuTagFragmentDoc}
`
export const PurchasableUserDetailFragmentDoc = gql`
  fragment PurchasableUserDetail on PurchasableUser {
    userId
    purchasableCount
    purchasedCount
    user {
      id
      displayName
      avatar {
        uri
        smallUri
      }
    }
  }
`
export const StoreItemDetailGuestFragmentDoc = gql`
  fragment StoreItemDetailGuest on Item {
    id
    rarity {
      id
      name
    }
    fees(isKonbini: true) {
      collections {
        fee
        feeName
        per
      }
      konbini {
        fee
        feeName
        per
      }
      shipping {
        fee
        feeName
        per
      }
    }
    nft {
      chains {
        chainName
      }
    }
    ogp {
      uri
    }
    images {
      uri
      mediumUri
      caption
    }
    title
    description
    type
    isPremium
    commentCount
    price
    personalInformationRequirements
    resaleEnabled
    hideSalesNumber
    paymentType
    purchasedByPointNumber
    purchaseLimitByUser
    purchaseLimitPerOrder
    remainingNumber @client
    formattedPrice @client
    buyOverLimit @client
    salesStatus
    isPublished
    resaleOpenCount(skuId: $skuId)
    formattedResaleOpenCount @client
    resaleRaffleAppliedCount(skuId: $skuId)
    formattedResaleRaffleAppliedCount @client
    sku @client {
      id
      order
      pendingPurchaseNumber
      purchasedNumber
      stock
      unitName
      price
      formattedPrice @client
      thumbnail {
        uri
        mediumUri
        compressedUri
        smallUri
      }
    }
    skuSettings {
      ...SkuType
    }
    prepaidPoint {
      exchangeRate
    }
    issuedArtist {
      id
      displayName
      avatar {
        uri
        mediumUri
        smallUri
      }
    }
    issuedNumber
    purchasedNumber
    thumbnail {
      front {
        uri
        mediumUri
        smallUri
      }
    }
    isResaleAvailable
    isResaleEnable @client
    willResaleAvailable @client
    pendingPurchaseNumber
    metadata {
      ticket {
        description
        note
        isThroughTicket
        expirationTerm
        resalePeriod {
          startedAt
          endedAt
          formattedStartedAt @client
          formattedEndedAt @client
        }
      }
      magazine {
        pageDirection
      }
    }
    requirePersonalInformation
    purchasableUsers {
      ...PurchasableUserDetail
    }
    collection {
      id
      isPublishOnStore
      isEventPickup
      event {
        id
      }
    }
    preSale {
      id
      lottery {
        purchasableCount
      }
      firstComeFirstServed {
        purchasableCount
      }
    }
  }
  ${SkuTypeFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const StoreItemDetailFragmentDoc = gql`
  fragment StoreItemDetail on Item {
    ...StoreItemDetailGuest
  }
  ${StoreItemDetailGuestFragmentDoc}
`
export const StorePurchaseHistoryFragmentDoc = gql`
  fragment StorePurchaseHistory on UserCartTransactionOrder {
    id
    paymentType
    createdAt
    formattedCreatedAt @client
    isKonbini
    totalAmount
    formattedTotalAmount @client
    originalTotalAmount @client
    formattedOriginalTotalAmount @client
    pickupUsers {
      id
      collectionId
      pickupTime
      formattedPickupTime @client
      formattedPickupStartEndTime @client
      eventCollection {
        eventId
        validMinutes
      }
    }
    checkoutFees {
      totalFee
      shipping {
        ...FeeDetail
      }
      konbini {
        ...FeeDetail
      }
      collections {
        ...CollectionFeeDetail
      }
    }
    items {
      itemId
      count
      amount
      item {
        ...StoreItemDetail
        collection {
          id
          title
          event {
            id
            title
            note
          }
        }
      }
      skus {
        count
        skuId
        sku {
          id
          unitName
          price
          formattedPrice @client
          thumbnail {
            uri
          }
        }
      }
    }
    cancels {
      canceledAt
      canceledCount
      canceledItems {
        count
        itemId
        skus {
          skuId
          count
        }
        item {
          id
          title
        }
      }
      reason
      refundedAmount
    }
  }
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${StoreItemDetailFragmentDoc}
`
export const PrepaidPointBoxFragmentDoc = gql`
  fragment PrepaidPointBox on PrepaidPointTransaction {
    id
    type
    transactionPrepaidPoint
    createdAt
    formattedCreatedAt @client
    formattedTransactionPrepaidPoint @client
  }
`
export const MonthlyEventsByArtistFragmentDoc = gql`
  fragment MonthlyEventsByArtist on Event {
    id
    title
    thumbnail {
      mediumUri
      uri
    }
    startedAt
    endedAt
    closeDate
    dateRange @client
    aboutDateRange @client
    dateArray @client
    location {
      approximateAddress
    }
    artist {
      currentLive {
        id
      }
    }
  }
`
export const GachaBoxFragmentDoc = gql`
  fragment GachaBox on Gacha {
    id
    name
    thumbnail {
      uri
      smallUri
    }
    endAt
    isPremium
    formattedEndAt @client
    gpsData {
      approximateAddress
      latitude
      longitude
      radius
    }
  }
`
export const GachasConnectionFragmentDoc = gql`
  fragment GachasConnection on PageResult {
    edges {
      node {
        ...GachaBox
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${GachaBoxFragmentDoc}
`
export const GalleryPostFragmentDoc = gql`
  fragment GalleryPost on Post {
    id
    category {
      id
      name
      description
      createdAt
      updatedAt
    }
    categoryId
    content
    createdAt
    status
    publishedAt
    formattedPublishedAt @client
    formattedRelativePublishedAt @client
    formattedCreatedAt @client
    planTitle @client
    likeCount
    replyCount
    resourceType
    isLike
    isPremium
    planId
    artistId
    isAccessible
    videoThumbnail {
      uri
      mediumUri
      blurUri
    }
    imageThumbnail {
      blurUri
    }
    resources {
      uri
      mediumUri
    }
    resource {
      uri
      videoId
    }
    artist {
      id
      displayName
      patron {
        isPersonalInformationRequired
        subscription {
          planId
          amount
          title
        }
      }
      subscription {
        plans {
          planId
          amount
          title
        }
        products {
          productId
          title
          plans {
            planId
            amount
          }
        }
      }
    }
  }
`
export const GalleryPostsFragmentDoc = gql`
  fragment GalleryPosts on PageResult {
    edges {
      node {
        ...GalleryPost
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${GalleryPostFragmentDoc}
`
export const SubscriptionDetailFragmentDoc = gql`
  fragment SubscriptionDetail on PatronSubsription {
    planId
    status
    amount
    downgradePlanId
    subscriptionId
  }
`
export const ArtistWithSubscriptionFragmentDoc = gql`
  fragment ArtistWithSubscription on Artist {
    id
    displayName
    avatar {
      uri
      mediumUri
      smallUri
    }
    subscription {
      products {
        plans {
          appstore {
            productId
          }
          playstore {
            productId
          }
        }
      }
    }
    pendingPurchasePatron {
      id
      artistId
      status
      expiredAt
      formattedExpiredAt @client
      voucherUrl
      metadata {
        membership {
          planId
        }
      }
    }
    patron {
      id
      amount
      expiredAt
      formattedExpiredAt @client
      downgradeAtNextPeriod
      cancelAtPeriodEnd
      type
      paymentMethod
      isPersonalInformationRequired
      subscription {
        ...SubscriptionDetail
      }
      iapSubscription {
        store
      }
    }
  }
  ${SubscriptionDetailFragmentDoc}
`
export const MultipleItemsMissionFragmentDoc = gql`
  fragment MultipleItemsMission on Item {
    id
    title
    description
    thumbnail {
      front {
        smallUri
        uri
      }
    }
    rarityIconImage {
      uri
      smallUri
    }
    rarity {
      name
    }
    issuedNumber
    sku @client {
      id
      order
      pendingPurchaseNumber
      purchasedNumber
      stock
      unitName
      thumbnail {
        uri
        mediumUri
        compressedUri
        smallUri
      }
    }
    skuSettings {
      keyName
      units {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        stock
        thumbnail {
          uri
          mediumUri
          compressedUri
          smallUri
        }
        unitName
      }
    }
  }
`
export const MissionBoxFragmentDoc = gql`
  fragment MissionBox on Mission {
    id
    title
    description
    type
    isAccomplished
    formattedExpiredAt @client
    label @client
    expiredAt
    gpsData {
      approximateAddress
      latitude
      longitude
      radius
    }
    artist {
      id
      displayName
      avatar {
        uri
        smallUri
      }
    }
    reward {
      item {
        id
        item {
          ...MultipleItemsMission
        }
      }
      gachaTicket {
        gachaTicketId
        gachaTicket {
          id
          thumbnails {
            reward {
              uri
              smallUri
            }
          }
        }
      }
    }
  }
  ${MultipleItemsMissionFragmentDoc}
`
export const MissionsConnectionFragmentDoc = gql`
  fragment MissionsConnection on PageResult {
    edges {
      node {
        ...MissionBox
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${MissionBoxFragmentDoc}
`
export const GachaTicketThumbnailsBoxFragmentDoc = gql`
  fragment GachaTicketThumbnailsBox on GachaTicketThumbnails {
    reward {
      uri
      smallUri
      mediumUri
    }
    standard {
      uri
      smallUri
      mediumUri
    }
  }
`
export const GachaTicketConnectionFragmentDoc = gql`
  fragment GachaTicketConnection on GachaTicket {
    id
    isPurchasable
    thumbnails {
      ...GachaTicketThumbnailsBox
    }
  }
  ${GachaTicketThumbnailsBoxFragmentDoc}
`
export const ItemBoxFragmentDoc = gql`
  fragment ItemBox on Item {
    id
    title
    price
    formattedPrice @client
    type
    paymentType
    requiredPrepaidPoint
    personalInformationRequirements
    hideSalesNumber
    thumbnail {
      front {
        uri
        mediumUri
        smallUri
        compressedUri
      }
    }
    prepaidPoint {
      id
      exchangeRate
      pointName
    }
    issuedArtist {
      id
      displayName
      introduction
      avatar {
        uri
        smallUri
      }
    }
    sku @client {
      id
      order
      pendingPurchaseNumber
      purchasedNumber
      stock
      unitName
      price
      formattedPrice @client
      thumbnail {
        uri
        mediumUri
        compressedUri
        smallUri
      }
    }
    skuSettings {
      keyName
      units {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        stock
        price
        formattedPrice @client
        thumbnail {
          uri
          mediumUri
          compressedUri
          smallUri
        }
        unitName
      }
    }
    collection {
      id
      title
    }
  }
`
export const UserStepMissionBoxFragmentDoc = gql`
  fragment UserStepMissionBox on UserStepMission {
    id
    accomplishedAt
    artistId
    createdAt
    isAccomplished
    isRewardReceived
    organizationId
    progress
    label @client
    step {
      ... on MissionStep {
        id
        requiredCount
        reward {
          ... on StepMissionReward {
            value
            type
            metadata {
              ... on StepMissionRewardMetadata {
                itemId
                gachaTicketId
                prepaidPointId
                gachaTicket {
                  ...GachaTicketConnection
                }
                item {
                  id
                  ...ItemBox
                }
              }
            }
          }
        }
      }
    }
    type
    updatedAt
  }
  ${GachaTicketConnectionFragmentDoc}
  ${ItemBoxFragmentDoc}
`
export const UserStepMissionsConnectionFragmentDoc = gql`
  fragment UserStepMissionsConnection on PageResult {
    edges {
      node {
        ...UserStepMissionBox
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${UserStepMissionBoxFragmentDoc}
`
export const PostBoxFragmentDoc = gql`
  fragment PostBox on Post {
    id
    categoryId
    content
    category {
      id
      name
    }
    createdAt
    status
    publishedAt
    formattedPublishedAt @client
    formattedRelativePublishedAt @client
    formattedCreatedAt @client
    planTitle @client
    likeCount
    replyCount
    resourceType
    isLike
    isPremium
    planId
    artistId
    isAccessible
    resources {
      uri
      mediumUri
    }
    resource {
      uri
      videoId
    }
    videoThumbnail {
      uri
      mediumUri
      blurUri
    }
    imageThumbnail {
      blurUri
    }
    artist {
      id
      displayName
      accountId
      formattedAccountId @client
      avatar {
        uri
        smallUri
        mediumUri
      }
      patron {
        id
        isPersonalInformationRequired
        subscription {
          planId
          amount
          title
        }
      }
      subscription {
        plans {
          planId
          amount
          title
        }
        products {
          productId
          title
          plans {
            planId
            amount
          }
        }
      }
    }
  }
`
export const ArtistPostsFragmentDoc = gql`
  fragment ArtistPosts on PageResult {
    edges {
      node {
        ...PostBox
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${PostBoxFragmentDoc}
`
export const RankingUserWithoutIdConnectionFragmentDoc = gql`
  fragment RankingUserWithoutIdConnection on RankingUser {
    point
    rank
    previousRank
    userId
    rankingId
    periodId
    user {
      id
      displayName
      avatar {
        smallUri
        uri
      }
    }
  }
`
export const RankingUserConnectionFragmentDoc = gql`
  fragment RankingUserConnection on RankingUser {
    id
    ...RankingUserWithoutIdConnection
  }
  ${RankingUserWithoutIdConnectionFragmentDoc}
`
export const RankingsConnectionFragmentDoc = gql`
  fragment RankingsConnection on PageResult {
    edges {
      node {
        ...RankingUserConnection
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${RankingUserConnectionFragmentDoc}
`
export const StoreItemDetailGuestSkuFragmentDoc = gql`
  fragment StoreItemDetailGuestSku on Item {
    id
    rarity {
      id
      name
    }
    fees(isKonbini: true) {
      collections {
        fee
        feeName
        per
      }
      konbini {
        fee
        feeName
        per
      }
      shipping {
        fee
        feeName
        per
      }
    }
    nft {
      chains {
        chainName
      }
    }
    ogp {
      uri
    }
    images {
      uri
      mediumUri
      caption
    }
    title
    description
    type
    isPremium
    commentCount
    price
    personalInformationRequirements
    resaleEnabled
    hideSalesNumber
    paymentType
    purchasedByPointNumber
    purchaseLimitByUser
    purchaseLimitPerOrder
    remainingNumber @client
    formattedPrice @client
    buyOverLimit @client
    salesStatus
    isPublished
    resaleOpenCount(skuId: $skuId)
    isResaleEnable @client
    formattedResaleOpenCount @client
    resaleRaffleAppliedCount(skuId: $skuId)
    formattedResaleRaffleAppliedCount @client
    sku @client {
      id
      order
      pendingPurchaseNumber
      purchasedNumber
      stock
      unitName
      price
      formattedPrice @client
      thumbnail {
        uri
        mediumUri
        compressedUri
        smallUri
      }
    }
    skuSettings {
      ...SkuType
    }
    prepaidPoint {
      exchangeRate
    }
    issuedArtist {
      id
      displayName
      avatar {
        uri
        mediumUri
        smallUri
      }
    }
    issuedNumber
    purchasedNumber
    thumbnail {
      front {
        uri
        mediumUri
        smallUri
      }
    }
    isResaleAvailable(skuId: $skuId)
    willResaleAvailable @client
    pendingPurchaseNumber
    metadata {
      ticket {
        description
        note
        isThroughTicket
        expirationTerm
        resalePeriod {
          startedAt
          endedAt
          formattedStartedAt @client
          formattedEndedAt @client
        }
      }
      magazine {
        pageDirection
      }
    }
    requirePersonalInformation
    purchasableUsers {
      ...PurchasableUserDetail
    }
    collection {
      id
      isPublishOnStore
      isEventPickup
      event {
        id
      }
    }
    preSale {
      id
      lottery {
        purchasableCount
      }
      firstComeFirstServed {
        purchasableCount
      }
    }
  }
  ${SkuTypeFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const StoreItemDetailSkuFragmentDoc = gql`
  fragment StoreItemDetailSku on Item {
    ...StoreItemDetailGuestSku
  }
  ${StoreItemDetailGuestSkuFragmentDoc}
`
export const ThreadBoxFragmentDoc = gql`
  fragment ThreadBox on ArtistThreadChannel {
    id
    name
    description
    artistId
    isAllowNotification
    userUnread {
      totalUnreadMentionCount
      id
      unreadMentionCount
      lastReadAt
      parentId
      unreadType
    }
    thumbnail {
      uri
      mediumUri
      smallUri
    }
    category
    likeCount
    commentCount
    isPremium
    isAccessible @client
    isOfficial
    createdAt
    formattedCreatedAt @client
    commentUsers {
      id
      accountId
      formattedAccountId @client
      displayName
      avatar {
        uri
        smallUri
      }
    }
    isLike
    artist {
      id
      accountId
      formattedAccountId @client
      displayName
      avatar {
        uri
        smallUri
      }
      totalUnreadMentionCount
    }
    createdByUser {
      id
      accountId
      formattedAccountId @client
      displayName
      avatar {
        uri
        smallUri
      }
    }
    userUnread {
      id
      lastReadAt
      parentId
      totalUnreadMentionCount
      unreadMentionCount
      unreadType
    }
    latestComment {
      id
      content
      createdAt
      formattedCreatedAt @client
      commenter {
        ... on User {
          id
          accountId
          formattedAccountId @client
          displayName
          avatar {
            uri
            smallUri
          }
        }
        ... on Artist {
          id
          accountId
          formattedAccountId @client
          displayName
          avatar {
            uri
            smallUri
          }
        }
      }
    }
  }
`
export const ArtistThreadChannelConnectionFragmentDoc = gql`
  fragment ArtistThreadChannelConnection on PageResult {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...ThreadBox
      }
    }
  }
  ${ThreadBoxFragmentDoc}
`
export const FollowerFragmentDoc = gql`
  fragment Follower on Follower {
    id
    artist {
      id
      follower {
        id
        artistId
      }
      displayName
      avatar {
        uri
        smallUri
      }
    }
    user {
      id
    }
  }
`
export const ArtistSmallDetailFragmentDoc = gql`
  fragment ArtistSmallDetail on Artist {
    id
    displayName
    patron {
      id
    }
    avatar {
      uri
      smallUri
    }
    totalUnreadMentionCount
    currentLive {
      id
    }
  }
`
export const EventPreSaleConnectionFragmentDoc = gql`
  fragment EventPreSaleConnection on PageResult {
    edges {
      node {
        ... on PreSale {
          id
          type
          lottery {
            applicationStartedAt
            applicationEndedAt
            purchasableStartedAt
            purchasableEndedAt
            resultNoticedAt
            resultNoticeStatus
            purchasableCount
            maxChoice
            personalInformationRequirements
          }
          firstComeFirstServed {
            purchasableCount
            purchasableStartedAt
            purchasableEndedAt
            personalInformationRequirements
          }
        }
      }
    }
  }
`
export const ArtistTopEventFragmentDoc = gql`
  fragment ArtistTopEvent on Event {
    id
    title
    thumbnail {
      mediumUri
      uri
    }
    preSales {
      ...EventPreSaleConnection
    }
    startedAt
    endedAt
    closeDate
    dateRange @client
    aboutDateRange @client
    dateArray @client
    location {
      approximateAddress
    }
  }
  ${EventPreSaleConnectionFragmentDoc}
`
export const EventsByArtistConnectionFragmentDoc = gql`
  fragment EventsByArtistConnection on PageResult {
    edges {
      node {
        ... on Event {
          ...ArtistTopEvent
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${ArtistTopEventFragmentDoc}
`
export const CampaignDetailFragmentDoc = gql`
  fragment CampaignDetail on Campaign {
    id
    startedAt
    formattedStartedAt @client
    endedAt
    formattedEndedAt @client
    description
    title
    thumbnail {
      uri
      mediumUri
    }
    acquisitionPoints {
      type
      point
    }
    rankingType
  }
`
export const ArtistCampaignRankingFragmentDoc = gql`
  fragment ArtistCampaignRanking on ArtistCampaignRanking {
    id
    ranker {
      rank
      point
      displayName
      artistId
      avatar {
        uri
        smallUri
      }
    }
    myRank {
      rank
      point
      displayName
      artistId
      avatar {
        uri
        smallUri
      }
    }
    createdAt
    formattedCreatedAt @client
  }
`
export const UserCampaignRankingFragmentDoc = gql`
  fragment UserCampaignRanking on UserCampaignRanking {
    id
    ranker {
      rank
      point
      displayName
      userId
      avatar {
        uri
        smallUri
      }
    }
    myRank {
      rank
      point
      displayName
      userId
      avatar {
        uri
        smallUri
      }
    }
    createdAt
    formattedCreatedAt @client
  }
`
export const CollectionDetailConnectionFragmentDoc = gql`
  fragment CollectionDetailConnection on Collection {
    id
    title
    artistId
    artist {
      displayName
    }
    thumbnail {
      uri
    }
    itemCount
    description
    labels {
      name
      id
    }
    userCollection {
      userId
      id
      ogp {
        uri
      }
      itemOwnedCount
      totalOwnedCount
      totalOwnedItemCount @client
      ownedItems {
        itemId
        ownedCount
        userItem {
          serials {
            serial {
              skuId
            }
          }
        }
      }
    }
  }
`
export const CollectionItemsConnectionFragmentDoc = gql`
  fragment CollectionItemsConnection on PageResult {
    edges {
      node {
        ... on CollectionItem {
          id
          item {
            id
            title
            type
            usedNumber
            isResaleAvailable
            rarityIconImage {
              uri
              smallUri
            }
            rarity {
              id
              name
            }
            thumbnail {
              front {
                uri
                mediumUri
              }
              back {
                uri
                mediumUri
              }
            }
            sku @client {
              id
              order
              pendingPurchaseNumber
              purchasedNumber
              remainingNumber @client
              stock
              unitName
              thumbnail {
                uri
                mediumUri
              }
            }
            skuSettings {
              keyName
              units {
                id
                unitName
                purchasedNumber
                price
                order
                thumbnail {
                  mediumUri
                }
              }
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`
export const UserTradeBoxFragmentDoc = gql`
  fragment UserTradeBox on TradableUser {
    id
    user {
      id
      displayName
      avatar {
        uri
        smallUri
      }
    }
    serials {
      id
      itemId
      serialId
      isProtected
      formattedSerialNumber
    }
  }
`
export const TradableUsersConnectionFragmentDoc = gql`
  fragment TradableUsersConnection on PageResult {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...UserTradeBox
      }
    }
  }
  ${UserTradeBoxFragmentDoc}
`
export const BusinessDetailFragmentDoc = gql`
  fragment BusinessDetail on Business {
    addressKanji {
      city
      line1
      line2
      postalCode
      state
      town
    }
    birthday
    formattedBirthday @client
    firstNameKana
    firstNameKanji
    gender
    lastNameKana
    lastNameKanji
    phone
  }
`
export const ArtistBannerDetailFragmentDoc = gql`
  fragment ArtistBannerDetail on ArtistBanner {
    link {
      innerPath
      externalUrl
    }
    thumbnail {
      uri
      mediumUri
    }
  }
`
export const EntryConditionItemFragmentDoc = gql`
  fragment EntryConditionItem on EntryConditionItem {
    count
    type
  }
`
export const ConsoleCampaignDetailFragmentDoc = gql`
  fragment ConsoleCampaignDetail on Campaign {
    id
    title
    description
    thumbnail {
      uri
    }
    startedAt
    formattedStartedAt @client
    endedAt
    formattedEndedAt @client
    entryCondition {
      description
      items {
        common {
          ...EntryConditionItem
        }
        rare {
          ...EntryConditionItem
        }
        superrare {
          ...EntryConditionItem
        }
      }
    }
  }
  ${EntryConditionItemFragmentDoc}
`
export const ImageTemplateFragmentDoc = gql`
  fragment ImageTemplate on ImageTemplate {
    overrideElements {
      elementName
      type
      value
    }
    templateFormatName
    templateId
  }
`
export const EntryCampaignImageTemplatesFragmentDoc = gql`
  fragment EntryCampaignImageTemplates on EntryCampaignImageTemplates {
    items {
      front {
        ...ImageTemplate
      }
      back {
        ...ImageTemplate
      }
      rarity
    }
    gacha {
      banner {
        ...ImageTemplate
      }
    }
    collection {
      banner {
        ...ImageTemplate
      }
    }
  }
  ${ImageTemplateFragmentDoc}
`
export const ConsoleCampaignBoxFragmentDoc = gql`
  fragment ConsoleCampaignBox on Campaign {
    id
    title
    startedAt
    formattedStartedAt @client
    endedAt
    formattedEndedAt @client
    open
    thumbnail {
      uri
      smallUri
    }
    isEntry
    openStatus @client
    entryStatus @client
  }
`
export const ConsoleCampaignsConnectionFragmentDoc = gql`
  fragment ConsoleCampaignsConnection on PageResult {
    edges {
      node {
        ...ConsoleCampaignBox
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${ConsoleCampaignBoxFragmentDoc}
`
export const EventBoxCommonFragmentDoc = gql`
  fragment EventBoxCommon on Event {
    id
    title
    description
    startedAt
    endedAt
    closeDate
    dateRange @client
    aboutDateRange @client
    dateArray @client
    preSales {
      ...EventPreSaleConnection
    }
    location {
      approximateAddress
      latitude
      longitude
      radius
      mapUri
    }
    thumbnail {
      mediumUri
      uri
    }
    banners {
      thumbnail {
        uri
        mediumUri
        smallUri
        compressedUri
      }
      order
      link {
        externalUrl
        innerPath
      }
    }
    createdAt
    updatedAt
  }
  ${EventPreSaleConnectionFragmentDoc}
`
export const CollectionBoxFragmentDoc = gql`
  fragment CollectionBox on Collection {
    id
    title
    formattedCreatedAt @client
    createdAt
    endedAt
    description
    isEventPickup
    event {
      id
    }
    artist {
      id
      displayName
      avatar {
        uri
        smallUri
      }
    }
    thumbnail {
      uri
    }
    status
    itemCount
    userCollection {
      userId
      id
      itemOwnedCount
      totalOwnedItemCount @client
      ownedItems {
        itemId
        ownedCount
      }
    }
  }
`
export const CartTransactionOrderDetailFragmentDoc = gql`
  fragment CartTransactionOrderDetail on UserCartTransactionOrder {
    id
    totalAmount
    paymentType
    cancels {
      canceledAt
      canceledCount
      canceledItems {
        count
        itemId
        skus {
          skuId
          count
        }
        item {
          id
          title
        }
      }
      reason
      refundedAmount
    }
    items {
      count
      item {
        id
        title
        paymentType
        thumbnail {
          front {
            uri
            mediumUri
          }
        }
        price
        formattedPrice @client
        sku @client {
          id
          unitName
          order
          price
          formattedPrice @client
        }
        skuSettings {
          units {
            id
            unitName
            order
            price
            formattedPrice @client
          }
        }
      }
      skus {
        sku {
          unitName
          price
          formattedPrice @client
        }
      }
    }
    itemOrders {
      id
      serialIds
      canceledSerialIds
      item {
        id
        title
      }
      serials {
        id
        skuId
      }
    }
    items {
      count
      item {
        id
        title
      }
      skus {
        sku {
          unitName
          price
          formattedPrice @client
        }
      }
    }
  }
`
export const EventCollectionBoxFragmentDoc = gql`
  fragment EventCollectionBox on EventCollection {
    id
    eventId
    collectionId
    validMinutes
    pickupLocation
    purchaseLimitPerPickup
    collection {
      ...CollectionBox
      collectionItems(pageInput: { limit: 1 }) {
        edges {
          node {
            ... on CollectionItem {
              id
              item {
                id
                type
                hideSalesNumber
              }
            }
          }
        }
      }
    }
    myPickupUsers {
      id
      userId
      pickupTime
      formattedPickupStartEndTime @client
      cartTransactionOrders {
        ...CartTransactionOrderDetail
      }
      userItems {
        number
        item {
          id
          hideSalesNumber
        }
      }
      eventCollection {
        id
        validMinutes
      }
    }
    pickupTime {
      options {
        availableUserNumber
        currentUserNumber
        time
      }
    }
  }
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
`
export const EventCollectionsBoxFragmentDoc = gql`
  fragment EventCollectionsBox on Event {
    eventCollections(pageInput: { limit: 30 }) {
      edges {
        node {
          ... on EventCollection {
            ...EventCollectionBox
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${EventCollectionBoxFragmentDoc}
`
export const EventBoxFragmentDoc = gql`
  fragment EventBox on Event {
    ...EventBoxCommon
    ...EventCollectionsBox
  }
  ${EventBoxCommonFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
`
export const ConsoleCollectionDetailFragmentDoc = gql`
  fragment ConsoleCollectionDetail on Collection {
    id
    title
    event {
      ...EventBox
    }
    artist {
      id
      displayName
    }
    nft {
      chains {
        royalty
        chainName
      }
      salesType
    }
    description
    thumbnail {
      uri
    }
    status
    startedAt
    endedAt
    formattedStartedAt @client
    formattedEndedAt @client
  }
  ${EventBoxFragmentDoc}
`
export const ConsoleCollectionItemDetailFragmentDoc = gql`
  fragment ConsoleCollectionItemDetail on CollectionItem {
    id
    item {
      id
      title
      price
      formattedPrice @client
      remainingNumber @client
      formattedRemainingNumber @client
      paymentType
      type
      issuedArtist {
        id
      }
      isPremium
      salesStatus
      isPublished
      usedNumber
      purchasedNumber
      issuedNumber
      pendingPurchaseNumber
      hideSalesNumber
      isResaleAvailable
      rarityIconImage {
        uri
        smallUri
      }
      rarity {
        id
        name
      }
      thumbnail {
        front {
          uri
          smallUri
          drm {
            encryptingStatus
          }
        }
        back {
          uri
          smallUri
        }
      }
      skuSettings {
        keyName
        units {
          id
          unitName
          order
        }
      }
      isDrm @client
      avatarResources {
        avatarResource {
          uri
          drm {
            encryptingStatus
          }
        }
      }
      wallpaperResources {
        wallpaperResource {
          uri
          drm {
            encryptingStatus
          }
        }
      }
    }
  }
`
export const ConsoleCollectionItemsConnectionFragmentDoc = gql`
  fragment ConsoleCollectionItemsConnection on PageResult {
    edges {
      node {
        ... on CollectionItem {
          ...ConsoleCollectionItemDetail
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${ConsoleCollectionItemDetailFragmentDoc}
`
export const CollectionsSelectBoxFragmentDoc = gql`
  fragment CollectionsSelectBox on Collection {
    createdAt
    formattedCreatedAt @client
    id
    artistId
    itemCount
    title
    description
    thumbnail {
      uri
    }
    nft {
      salesType
    }
    status
    startedAt
    endedAt
    isPublishOnStore
    formattedStartedAt @client
    formattedEndedAt @client
  }
`
export const ArtistCollectionsConnectionFragmentDoc = gql`
  fragment ArtistCollectionsConnection on PageResult {
    edges {
      node {
        ...CollectionsSelectBox
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${CollectionsSelectBoxFragmentDoc}
`
export const ConsoleDeliveryItemBoxFragmentDoc = gql`
  fragment ConsoleDeliveryItemBox on DeliveryItem {
    id
    deliveryInfo {
      fullName
      phoneNumber
      address {
        postalCode
        prefecture
        city
        line1
        line2
        fullAddress @client
      }
    }
    user {
      email
    }
    appliedAt
    shippedAt
    formattedShippedAt @client
    formattedAppliedAt @client
    status
    itemSerial {
      serialNumber
      formattedSerialNumber
      sku {
        id
        unitName
      }
    }
    item {
      id
      title
      description
      thumbnail {
        front {
          uri
          smallUri
        }
      }
      hideSalesNumber
      receptionPeriod {
        endedAt
        formattedEndedAt @client
      }
    }
  }
`
export const ConsoleDeliveryItemsConnectionFragmentDoc = gql`
  fragment ConsoleDeliveryItemsConnection on PageResult {
    edges {
      node {
        ...ConsoleDeliveryItemBox
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${ConsoleDeliveryItemBoxFragmentDoc}
`
export const ConsolePreSalesAggregationDetailFragmentDoc = gql`
  fragment ConsolePreSalesAggregationDetail on PreSalesAggregation {
    aggregation {
      purchases {
        issuedNumber
        purchasedNumber
      }
      ticketUsage {
        ticketUnusedCount
        ticketUsedCount
      }
    }
    preSaleItems {
      itemId
      ticketUnusedCount
      ticketUsedCount
      item {
        id
        title
        issuedNumber
      }
    }
  }
`
export const ConsoleEventDetailFragmentDoc = gql`
  fragment ConsoleEventDetail on Event {
    id
    title
    description
    startedAt
    formattedStartedAt @client
    endedAt
    note
    closeDate
    dateRange @client
    aboutDateRange @client
    dateArray @client
    formattedEventTime @client
    location {
      approximateAddress
      latitude
      longitude
      radius
      mapUri
    }
    thumbnail {
      mediumUri
      uri
    }
    status
    preSalesAggregation {
      ...ConsolePreSalesAggregationDetail
    }
  }
  ${ConsolePreSalesAggregationDetailFragmentDoc}
`
export const ConsoleEventDetailCollectionBoxFragmentDoc = gql`
  fragment ConsoleEventDetailCollectionBox on EventCollection {
    id
    eventId
    collectionId
    collection {
      id
      artistId
      isEventPickup
      status
      formattedCreatedAt @client
      thumbnail {
        uri
      }
      title
      itemCount
      collectionItems(pageInput: { limit: 30 }) {
        edges {
          node {
            ... on CollectionItem {
              id
              item {
                id
                type
              }
            }
          }
        }
      }
    }
    pickupTime {
      options {
        time
      }
    }
  }
`
export const ConsoleEventCollectionSelectBoxFragmentDoc = gql`
  fragment ConsoleEventCollectionSelectBox on Collection {
    id
    thumbnail {
      uri
    }
    status
    startedAt
    formattedStartedAt @client
    title
    createdAt
    formattedCreatedAt @client
    itemCount
    event {
      id
    }
  }
`
export const ConsoleEventCollectionSelectConnectionFragmentDoc = gql`
  fragment ConsoleEventCollectionSelectConnection on PageResult {
    edges {
      node {
        ... on Collection {
          ...ConsoleEventCollectionSelectBox
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${ConsoleEventCollectionSelectBoxFragmentDoc}
`
export const ConsoleEventEditDetailFragmentDoc = gql`
  fragment ConsoleEventEditDetail on Event {
    id
    title
    status
    description
    note
    startedAt
    endedAt
    closeDate
    thumbnail {
      uri
      mediumUri
    }
    location {
      approximateAddress
      latitude
      longitude
      mapUri
    }
  }
`
export const ConsoleEventListFragmentDoc = gql`
  fragment ConsoleEventList on Event {
    id
    title
    description
    startedAt
    endedAt
    closeDate
    preSales {
      ...EventPreSaleConnection
    }
    status
    dateRange @client
    aboutDateRange @client
    thumbnail {
      mediumUri
      uri
    }
    eventCollections(pageInput: { limit: 30 }) {
      edges {
        node {
          ... on EventCollection {
            id
            collection {
              id
              artistId
            }
          }
        }
      }
    }
  }
  ${EventPreSaleConnectionFragmentDoc}
`
export const ConsoleEventTicketInfoBoxFragmentDoc = gql`
  fragment ConsoleEventTicketInfoBox on Event {
    id
    title
    status
    preSales {
      ...EventPreSaleConnection
    }
    location {
      approximateAddress
    }
    startedAt
    endedAt
    closeDate
    dateRange @client
    preSalesAggregation {
      aggregation {
        purchases {
          purchasedNumber
          issuedNumber
        }
        ticketUsage {
          ticketUnusedCount
          ticketUsedCount
        }
      }
    }
  }
  ${EventPreSaleConnectionFragmentDoc}
`
export const ConsoleEventAggregationConnectionFragmentDoc = gql`
  fragment ConsoleEventAggregationConnection on PageResult {
    edges {
      node {
        ... on Event {
          ...ConsoleEventTicketInfoBox
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${ConsoleEventTicketInfoBoxFragmentDoc}
`
export const ThumbnailResourceFragmentDoc = gql`
  fragment ThumbnailResource on Resource {
    mediumUri
    uri
    hlsUri
    dashUri
    drm {
      encryptingStatus
    }
  }
`
export const CollectionItemDetailFragmentDoc = gql`
  fragment CollectionItemDetail on CollectionItem {
    id
    itemId
    order
    userItem {
      id
      type
    }
    item {
      id
      title
      paymentType
      price
      purchaseLimitByUser
      purchasedByPointNumber
      purchasedNumber
      buyOverLimit @client
      salesStatus
      issuedNumber
      formattedPrice @client
      thumbnail {
        front {
          ...ThumbnailResource
        }
        back {
          ...ThumbnailResource
        }
      }
    }
  }
  ${ThumbnailResourceFragmentDoc}
`
export const ResourceFragmentDoc = gql`
  fragment Resource on Resource {
    smallUri
    mediumUri
    compressedUri
    uri
    hlsUri
    dashUri
    drm {
      encryptingStatus
    }
  }
`
export const ItemDescriptionFragmentDoc = gql`
  fragment ItemDescription on Item {
    id
    title
    type
    description
    price
    formattedPrice @client
    isDrm @client
    isDownloadable
    ownedCount
    hideSalesNumber
    paymentType
    isTicketDistributionEnabled
    preSale {
      id
      title
      event {
        id
        title
        description
      }
    }
    collection {
      id
      itemCount
      isPublishOnStore
      event {
        ...EventBox
      }
      collectionItems {
        edges {
          node {
            ... on CollectionItem {
              ...CollectionItemDetail
            }
          }
        }
      }
    }
    requirePersonalInformation
    formattedRemainingNumber @client
    images {
      caption
      mediumUri
      uri
    }
    sku @client {
      id
      order
      pendingPurchaseNumber
      purchasedNumber
      stock
      unitName
      price
      formattedPrice @client
      thumbnail {
        uri
        mediumUri
      }
    }
    skuSettings {
      keyName
      units {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        remainingNumber @client
        stock
        price
        formattedPrice @client
        thumbnail {
          uri
          mediumUri
        }
        unitName
      }
    }
    personalInformationRequirements
    purchasableUsers {
      ...PurchasableUserDetail
    }
    isResaleAvailable
    skuIsResaleAvailable: isResaleAvailable(skuId: $skuId)
    resaleOpenCount(skuId: $skuId)
    resaleRaffleAppliedCount(skuId: $skuId)
    issuedArtist {
      id
    }
    rarity {
      id
      name
    }
    thumbnail {
      front {
        ...ThumbnailResource
      }
      back {
        ...ThumbnailResource
      }
    }
    nft {
      chains {
        chainName
      }
    }
    rarityIconImage {
      uri
      smallUri
    }
    resource {
      ...Resource
    }
    resources {
      ...Resource
    }
    avatarResources {
      voiceResource {
        ...Resource
      }
      avatarResource {
        ...Resource
      }
    }
    wallpaperResources {
      audioResource {
        ...Resource
      }
      wallpaperResource {
        ...Resource
      }
    }
    resaleEnabled
    relatedMissions {
      id
      expiredAt
      isAccomplished
      label @client
    }
    thumbnailType
    issuedNumber
    purchasedNumber
    pendingPurchaseNumber
    commentCount
    ogp {
      rectUri
      uri
    }
    metadata {
      ticket {
        note
        description
        expirationTerm
        isThroughTicket
        resalePeriod {
          startedAt
          endedAt
        }
        assignedNumberType
        startAssignedNumber
        assignedNumberPrefix
        isThroughTicket
        throughTicket {
          name
        }
        distributionEnabled
        distributionEndedAt
      }
      magazine {
        pageDirection
        pageType
      }
      wallpaper {
        isPrintEnabled
        printCount
        printType
      }
    }
    userOpenResaleItem {
      id
    }
  }
  ${EventBoxFragmentDoc}
  ${CollectionItemDetailFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${ThumbnailResourceFragmentDoc}
  ${ResourceFragmentDoc}
`
export const ConsoleItemDetailFragmentDoc = gql`
  fragment ConsoleItemDetail on Item {
    ...ItemDescription
    salesStatus
    isPublished
  }
  ${ItemDescriptionFragmentDoc}
`
export const ConsoleSerialItemOwnersFragmentDoc = gql`
  fragment ConsoleSerialItemOwners on PageResult {
    edges {
      node {
        ... on ItemSerial {
          id
          itemId
          serialNumber
          formattedSerialNumber
          createdAt
          formattedCreatedAt @client
          updatedAt
          obtainedDate
          formattedObtainedDate @client
          formattedObtainedDateTime @client
          formattedAssignedNumber
          sku {
            id
            unitName
          }
          ticketStatus {
            isUsed
            usedAt
            formattedUsedAt @client
          }
          isNft
          user {
            id
            displayName
            avatar {
              uri
              smallUri
            }
            phoneNumber
          }
          authentication {
            authenticatedAt
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`
export const OrderedItemBoxFragmentDoc = gql`
  fragment OrderedItemBox on ItemOrder {
    id
    serialIds
    purchaseNumber
    serials {
      id
      itemId
      skuId
      sku {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        stock
        thumbnail {
          uri
          mediumUri
          compressedUri
          smallUri
        }
        unitName
      }
    }
    item {
      issuedNumber
      purchasedNumber
      id
      title
      rarity {
        name
      }
      thumbnail {
        front {
          uri
          smallUri
        }
      }
    }
    createdAt
    formattedCreatedAt @client
    price
    prepaidPoint
    totalAmount
    user {
      avatar {
        smallUri
        uri
      }
    }
  }
`
export const ArtistItemOrdersConnectionFragmentDoc = gql`
  fragment ArtistItemOrdersConnection on PageResult {
    edges {
      node {
        ...OrderedItemBox
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${OrderedItemBoxFragmentDoc}
`
export const ItemSelectBoxFragmentDoc = gql`
  fragment ItemSelectBox on Item {
    id
    type
    description
    thumbnailType
    thumbnail {
      back {
        ...Resource
      }
      front {
        ...Resource
      }
    }
    collection {
      id
    }
    images {
      uri
      caption
    }
    issuedArtist {
      id
    }
    issuedNumber
    usedNumber
    purchasedNumber
    pendingPurchaseNumber
    formattedRemainingNumber @client
    purchaseLimitByUser
    purchaseLimitPerOrder
    personalInformationRequirements
    title
    isDownloadable
    hideSalesNumber
    prepaidPoint {
      exchangeRate
      pointName
    }
    price
    salesStatus
    isPublished
    status @client
    isDrm @client
    resaleEnabled
    paymentType
    sku @client {
      id
      order
      pendingPurchaseNumber
      purchasedNumber
      stock
      unitName
      price
      thumbnail {
        uri
        mediumUri
        compressedUri
        smallUri
      }
    }
    skuSettings {
      keyName
      units {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        stock
        price
        thumbnail {
          uri
          mediumUri
          compressedUri
          smallUri
        }
        unitName
      }
    }
    wallpaperResources {
      wallpaperResource {
        ...Resource
      }
      audioResource {
        ...Resource
      }
    }
    avatarResources {
      avatarResource {
        ...Resource
      }
      voiceResource {
        ...Resource
      }
    }
    resource {
      ...Resource
    }
    receptionPeriod {
      startedAt
      endedAt
      formattedEndedAt @client
    }
    nft {
      chains {
        chainName
      }
    }
    formattedPrice @client
    isPremium
    requiredPrepaidPoint
    metadata {
      ticket {
        description
        note
        expirationTerm
        throughTicket {
          name
        }
        resalePeriod {
          startedAt
          endedAt
        }
      }
      magazine {
        pageDirection
      }
    }
    skuSettings {
      keyName
      units {
        id
      }
    }
    rarity {
      id
      name
    }
    rarityIconImage {
      smallUri
      uri
    }
  }
  ${ResourceFragmentDoc}
`
export const ConsoleItemsConnectionFragmentDoc = gql`
  fragment ConsoleItemsConnection on PageResult {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...ItemSelectBox
      }
    }
  }
  ${ItemSelectBoxFragmentDoc}
`
export const ExportPreSaleApplicantsFragmentDoc = gql`
  fragment ExportPreSaleApplicants on PreSaleApplicantCsv {
    csvUrl
  }
`
export const ConsolePreSaleTitleFragmentDoc = gql`
  fragment ConsolePreSaleTitle on PreSale {
    id
    title
    items {
      id
      title
    }
    lottery {
      resultNoticedAt
    }
  }
`
export const PrepaidPointsPaymentSourceFragmentDoc = gql`
  fragment PrepaidPointsPaymentSource on CreditCard {
    id
    last4
    brand
  }
`
export const ArtistPreSaleLotteryApplicantDetailFragmentDoc = gql`
  fragment ArtistPreSaleLotteryApplicantDetail on PreSaleLotteryApplicant {
    id
    preSale {
      id
      title
    }
    status
    winChoiceNumber
    paymentMethod
    purchaseCountChoices
    appliedAt
    formattedAppliedAt @client
    user {
      id
      fullName
      isPhoneNumberVerified
      phoneNumber
      formattedPhoneNumber @client
      email
      address {
        postalCode
        prefecture
        city
        line1
        line2
        fullAddress @client
      }
      credit {
        ...PrepaidPointsPaymentSource
      }
    }
    cartTransactionOrder {
      id
      isKonbini
    }
    choiceItemDetails {
      item {
        id
        title
        price
        formattedPrice @client
      }
      fees {
        collections {
          id
          feeName
          feeType
          fee
        }
        konbini {
          id
          feeName
          feeType
          fee
        }
        shipping {
          id
          feeName
          feeType
          fee
        }
      }
      count
      itemId
      skuId
      skuUnit {
        id
        unitName
      }
    }
  }
  ${PrepaidPointsPaymentSourceFragmentDoc}
`
export const ArtistPreSaleLotteryApplicantsConnectionFragmentDoc = gql`
  fragment ArtistPreSaleLotteryApplicantsConnection on PageResult {
    edges {
      node {
        ... on PreSaleLotteryApplicant {
          ...ArtistPreSaleLotteryApplicantDetail
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${ArtistPreSaleLotteryApplicantDetailFragmentDoc}
`
export const PreSaleItemFilterFragmentDoc = gql`
  fragment PreSaleItemFilter on Item {
    id
    title
    skuSettings {
      units {
        id
        unitName
      }
    }
  }
`
export const MemberAvatarBoxFragmentDoc = gql`
  fragment MemberAvatarBox on Avatar {
    uri
    smallUri
    mediumUri
  }
`
export const MemberBoxFragmentDoc = gql`
  fragment MemberBox on Patron {
    id
    createdAt
    formattedCreatedAt @client
    continueMonth @client
    isFollower
    paymentMethod
    subscription {
      title
      content
      status
      amount
      planId
    }
    purchaseCount
    ticketUnusedCount
    user {
      id
      email
      gender
      birthday
      formattedBirthday @client
      displayName
      fullName
      phoneNumber
      formattedPhoneNumber @client
      avatar {
        ...MemberAvatarBox
      }
      address {
        postalCode
        prefecture
        city
        line1
        line2
        fullAddress @client
      }
    }
  }
  ${MemberAvatarBoxFragmentDoc}
`
export const ConsoleMembershipConnectionFragmentDoc = gql`
  fragment ConsoleMembershipConnection on PageResult {
    edges {
      node {
        ...MemberBox
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${MemberBoxFragmentDoc}
`
export const ConsolePatronDetailFragmentDoc = gql`
  fragment ConsolePatronDetail on Patron {
    id
    user {
      id
      avatar {
        uri
        smallUri
      }
    }
  }
`
export const ConsolePlanDetailFragmentDoc = gql`
  fragment ConsolePlanDetail on MembershipSubscriptionPlan {
    title
    content
    planId
    amount
    formattedAmount @client
    status
    patronCount
    patrons(pageInput: { limit: 3 }) {
      edges {
        node {
          ... on Patron {
            ...ConsolePatronDetail
          }
        }
      }
    }
  }
  ${ConsolePatronDetailFragmentDoc}
`
export const ConsoleProductDetailFragmentDoc = gql`
  fragment ConsoleProductDetail on MembershipSubscriptionProduct {
    title
    productId
    annualContent
    content
    plans {
      planId
      amount
      formattedAmount @client
      status
      interval
      patronCount
      patrons(pageInput: { limit: 3 }) {
        edges {
          node {
            ... on Patron {
              ...ConsolePatronDetail
            }
          }
        }
      }
    }
  }
  ${ConsolePatronDetailFragmentDoc}
`
export const ConsoleMissionBoxFragmentDoc = gql`
  fragment ConsoleMissionBox on Mission {
    id
    title
    description
    type
    isAccomplished
    formattedExpiredAt @client
    label @client
    expiredAt
    createdAt
    formattedCreatedAt @client
    startedAt
    isPublished
    gpsData {
      approximateAddress
      latitude
      longitude
      radius
    }
    artist {
      displayName
    }
    content {
      ... on QuizContent {
        answer
        image {
          uri
          path
        }
      }
      ... on ItemCollectContent {
        image {
          uri
          path
        }
        items {
          itemId
          requiredCount
        }
      }
    }
    reward {
      item {
        id
        item {
          id
          title
          issuedNumber
          isPremium
          isPublished
          isDrm @client
          salesStatus
          thumbnail {
            front {
              uri
              smallUri
            }
          }
        }
      }
      gachaTicket {
        id
        count
        gachaTicketId
        gachaTicket {
          id
          thumbnails {
            reward {
              uri
              smallUri
            }
          }
        }
      }
    }
  }
`
export const ArtistMissionsConnectionFragmentDoc = gql`
  fragment ArtistMissionsConnection on PageResult {
    edges {
      node {
        ...ConsoleMissionBox
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${ConsoleMissionBoxFragmentDoc}
`
export const ConsoleMyProfileConnectionFragmentDoc = gql`
  fragment ConsoleMyProfileConnection on Artist {
    id
    accountId
    formattedAccountId @client
    avatar {
      uri
      smallUri
      mediumUri
      compressedUri
    }
    displayName
    introduction
    sns {
      twitter
      tiktok
      instagram
      facebook
      youtube
    }
    profileText
    textLink {
      content
      uri
    }
  }
`
export const OfficialInformationConsoleBoxFragmentDoc = gql`
  fragment OfficialInformationConsoleBox on OrganizationNotification {
    id
    title
    body
    link
    status
    publishedAt
    formattedPublishedAt @client
    createdAt
    formattedCreatedAt @client
  }
`
export const ConsoleOfficialInformationConnectionFragmentDoc = gql`
  fragment ConsoleOfficialInformationConnection on PageResult {
    edges {
      node {
        ...OfficialInformationConsoleBox
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${OfficialInformationConsoleBoxFragmentDoc}
`
export const PaymentDetailFragmentDoc = gql`
  fragment PaymentDetail on CreditCard {
    id
    name
    last4
    expMonth
    expYear
    brand
  }
`
export const UserAddressFragmentDoc = gql`
  fragment UserAddress on User {
    address {
      postalCode
      prefecture
      city
      line1
      line2
      fullAddress @client
      shortAddress @client
    }
  }
`
export const UserCartTransactionOrderDetailFragmentDoc = gql`
  fragment UserCartTransactionOrderDetail on UserCartTransactionOrder {
    id
    totalAmount
    formattedTotalAmount @client
    paymentType
    createdAt
    isKonbini
    totalAmountWithoutFee
    formattedTotalAmountWithoutFee @client
    storeFee
    formattedStoreFee @client
    formattedSaleProfit @client
    originalTotalAmount @client
    formattedOriginalTotalAmount @client
    formattedCreatedAt @client
    pickupUsers {
      id
      pickupTime
      collectionId
      eventCollection {
        id
        validMinutes
      }
      formattedPickupStartEndTime @client
    }
    itemOrders {
      id
      user {
        id
        fullName
        phoneNumber
        displayName
        formattedPhoneNumber @client
        isPhoneNumberVerified
        credit {
          ...PaymentDetail
        }
        ...UserAddress
      }
      item {
        id
      }
    }
    checkoutFees {
      totalFee
      shipping {
        ...FeeDetail
      }
      collections {
        ...CollectionFeeDetail
      }
      konbini {
        ...FeeDetail
      }
    }
    cancels {
      canceledAt
    }
    items {
      itemId
      count
      amount
      item {
        ...StoreItemDetail
        collection {
          id
          title
        }
      }
      skus {
        count
        skuId
        sku {
          unitName
          thumbnail {
            uri
          }
        }
      }
    }
  }
  ${PaymentDetailFragmentDoc}
  ${UserAddressFragmentDoc}
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${StoreItemDetailFragmentDoc}
`
export const UserCartTransactionOrderBoxFragmentDoc = gql`
  fragment UserCartTransactionOrderBox on UserCartTransactionOrder {
    id
    totalAmount
    formattedTotalAmount @client
    paymentType
    createdAt
    isKonbini
    totalAmountWithoutFee
    storeFee
    formattedSaleProfit @client
    formattedCreatedAt @client
    pickupUsers {
      id
      pickupTime
      collectionId
      eventCollection {
        id
        validMinutes
      }
      formattedPickupStartEndTime @client
    }
    cancels {
      canceledAt
    }
    itemOrders {
      id
      userId
      itemId
      user {
        id
        fullName
      }
    }
    checkoutFees {
      totalFee
      shipping {
        ...FeeDetail
      }
      collections {
        ...CollectionFeeDetail
      }
      konbini {
        ...FeeDetail
      }
    }
    items {
      itemId
      count
      amount
      item {
        id
        title
      }
      skus {
        count
        skuId
        sku {
          unitName
          thumbnail {
            uri
          }
        }
      }
    }
  }
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
`
export const UserCartTransactionOrderConnectionFragmentDoc = gql`
  fragment UserCartTransactionOrderConnection on PageResult {
    edges {
      node {
        ...UserCartTransactionOrderBox
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${UserCartTransactionOrderBoxFragmentDoc}
`
export const ConsolePreSaleItemSelectedBoxFragmentDoc = gql`
  fragment ConsolePreSaleItemSelectedBox on Item {
    id
    type
    title
    thumbnail {
      front {
        uri
        smallUri
      }
    }
    issuedNumber
    usedNumber
    paymentType
    purchasedNumber
    pendingPurchaseNumber
    ticketUsage {
      unusedCount
      usedCount
    }
    price
    formattedPrice @client
    sku @client {
      id
      unitName
    }
    skuSettings {
      keyName
      units {
        id
        unitName
      }
    }
    throughTicketUsedNumbers {
      subTicketId
      usedNumber
    }
    metadata {
      ticket {
        throughTicket {
          name
        }
      }
    }
  }
`
export const ConsolePreSaleDetailFragmentDoc = gql`
  fragment ConsolePreSaleDetail on PreSale {
    id
    title
    description
    type
    event {
      id
    }
    firstComeFirstServed {
      purchasableCount
      purchasableStartedAt
      purchasableEndedAt
      formattedPurchasableStartedAt @client
      formattedPurchasableEndedAt @client
      personalInformationRequirements
      applicantAggregation {
        creditCard {
          appliedCount
          purchasedCount
        }
        konbini {
          appliedCount
          purchasedCount
          unpaidCount
        }
      }
    }
    lottery {
      applicationStartedAt
      applicationEndedAt
      purchasableStartedAt
      purchasableEndedAt
      resultNoticedAt
      formattedApplicationStartedAt @client
      formattedApplicationEndedAt @client
      formattedPurchasableStartedAt @client
      formattedPurchasableEndedAt @client
      formattedResultNoticedAt @client
      resultNoticeStatus
      purchasableCount
      maxChoice
      personalInformationRequirements
      applicantAggregation {
        creditCard {
          appliedCount
          failedCount
          purchasedCount
        }
        konbini {
          appliedCount
          purchasedCount
          unpaidCount
        }
      }
    }
    isPublished
    endedAt
    formattedEndedAt @client
    startedAt
    formattedStartedAt @client
    itemIds
    preSalesAggregation {
      ...ConsolePreSalesAggregationDetail
    }
    items {
      ...ConsolePreSaleItemSelectedBox
    }
    note
    isSoldOut
    paymentMethods
    preSaleStatus @client
    isPremium
  }
  ${ConsolePreSalesAggregationDetailFragmentDoc}
  ${ConsolePreSaleItemSelectedBoxFragmentDoc}
`
export const ConsolePreSaleItemSelectBoxFragmentDoc = gql`
  fragment ConsolePreSaleItemSelectBox on Item {
    id
    type
    title
    thumbnail {
      front {
        uri
        smallUri
      }
    }
    isPremium
    isPublished
    salesStatus
    paymentType
    isDrm @client
    price
    formattedPrice @client
    sku @client {
      id
      unitName
    }
    skuSettings {
      keyName
      units {
        id
        unitName
      }
    }
  }
`
export const ConsolePreSaleEditFragmentDoc = gql`
  fragment ConsolePreSaleEdit on PreSale {
    id
    title
    description
    type
    firstComeFirstServed {
      purchasableCount
      purchasableStartedAt
      purchasableEndedAt
      personalInformationRequirements
    }
    lottery {
      applicationStartedAt
      applicationEndedAt
      purchasableStartedAt
      purchasableEndedAt
      resultNoticedAt
      resultNoticeStatus
      purchasableCount
      maxChoice
      personalInformationRequirements
    }
    isPublished
    endedAt
    startedAt
    itemIds
    note
    isSoldOut
    paymentMethods
  }
`
export const PickupTimeItemsAggregationDetailFragmentDoc = gql`
  fragment PickupTimeItemsAggregationDetail on PickupTimeItemsAggregation {
    pickupTime
    items {
      itemId
      item {
        title
        thumbnail {
          front {
            uri
            smallUri
          }
        }
      }
      count
      completedCount
      pendingCount
      sku @client {
        skuId
        sku {
          id
          unitName
          thumbnail {
            uri
            smallUri
          }
        }
        count
        pendingCount
        completedCount
        pendingCount
      }
      skus {
        skuId
        sku {
          id
          unitName
          thumbnail {
            uri
            smallUri
          }
        }
        count
        pendingCount
        completedCount
        pendingCount
      }
    }
  }
`
export const EventCollectionPickupTimeFragmentDoc = gql`
  fragment EventCollectionPickupTime on EventCollection {
    id
    collectionId
    pickupTime {
      eventId
      options {
        eventId
        time
      }
    }
    validMinutes
  }
`
export const TransactionsConnectionsFragmentDoc = gql`
  fragment TransactionsConnections on PageResult {
    edges {
      node {
        ... on Transaction {
          balance
          balanceInPayout
          type
          amount
          createdAt
          formattedCreatedAt @client
          formattedBalance @client
          formattedBalanceInPayout @client
          formattedTotalBalance @client
          formattedAmount @client
          formattedTotalBalanceNumber @client
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`
export const PickupUserDetailFragmentDoc = gql`
  fragment PickupUserDetail on PickupUser {
    id
    eventId
    collectionId
    pickupTime
    formattedPickupTime @client
    formattedPickupStartEndTime @client
    eventCollection {
      id
      validMinutes
    }
    isCompleted
    pickupOrder @client {
      itemId
      title
      serialIds
      count
      serials {
        id
        skuId
      }
    }
    userItemIds
    itemsString @client
    actualTotalAmount @client
    formattedActualTotalAmount @client
    pickupRemainingTime @client {
      hours
      minutes
      expiring
    }
    collection {
      id
      title
    }
    userItems {
      id
      number
      item {
        id
        ...ItemBox
      }
      serials {
        id
        isUsed
        serial {
          id
          sku {
            id
            unitName
            price
            formattedPrice @client
            thumbnail {
              smallUri
            }
          }
        }
      }
    }
    cartTransactionOrders {
      ...CartTransactionOrderDetail
    }
    ticketItemSerialsInput @client {
      itemId
      serialIds
    }
    user {
      id
      displayName
      phoneNumber
      fullName
      email
      formattedPhoneNumber @client
      address {
        postalCode
        prefecture
        city
        line1
        line2
        fullAddress @client
      }
    }
  }
  ${ItemBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
`
export const CartTransactionOrdersTipFragmentDoc = gql`
  fragment cartTransactionOrdersTip on UserCartTransactionOrder {
    id
    totalAmount
    formattedTotalAmount @client
    paymentType
    createdAt
    isKonbini
    totalAmountWithoutFee
    formattedTotalAmountWithoutFee @client
    storeFee
    formattedStoreFee @client
    formattedSaleProfit @client
    originalTotalAmount @client
    formattedOriginalTotalAmount @client
    formattedCreatedAt @client
    cancels {
      canceledCount
      canceledItems {
        count
        itemId
        skus {
          skuId
          count
        }
        item {
          id
        }
      }
      refundedAmount
    }
    itemOrders {
      id
      serialIds
      canceledSerialIds
      item {
        id
        title
      }
      serials {
        id
        skuId
        sku {
          id
          unitName
        }
      }
    }
  }
`
export const PickupUserBoxFragmentDoc = gql`
  fragment PickupUserBox on PickupUser {
    id
    eventId
    collectionId
    pickupTime
    isCompleted
    userItemIds
    formattedPickupTime @client
    formattedPickupStartEndTime @client
    itemsString @client
    actualTotalAmount @client
    formattedActualTotalAmount @client
    pickupOrder @client {
      itemId
      title
      serialIds
      count
      serials {
        id
        skuId
        sku {
          id
          unitName
        }
      }
    }
    eventCollection {
      id
      validMinutes
    }
    collection {
      id
    }
    cartTransactionOrders {
      ...cartTransactionOrdersTip
    }
    user {
      id
      displayName
      phoneNumber
      fullName
      email
      formattedPhoneNumber @client
      address {
        postalCode
        prefecture
        city
        line1
        line2
        fullAddress @client
      }
    }
  }
  ${CartTransactionOrdersTipFragmentDoc}
`
export const EventPickupUserConnectionFragmentDoc = gql`
  fragment EventPickupUserConnection on PageResult {
    edges {
      node {
        ... on PickupUser {
          ...PickupUserBox
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${PickupUserBoxFragmentDoc}
`
export const UserProfileFragmentDoc = gql`
  fragment UserProfile on User {
    id
    accountId
    formattedAccountId @client
    displayName
    avatar {
      uri
      mediumUri
      smallUri
    }
    introduction
  }
`
export const ConsoleUserDetailUserFragmentDoc = gql`
  fragment ConsoleUserDetailUser on User {
    id
    email
    fullName
    displayName
    gender
    birthday
    phoneNumber
    formattedPhoneNumber @client
    formattedBirthday @client
    ...UserProfile
    ...UserAddress
  }
  ${UserProfileFragmentDoc}
  ${UserAddressFragmentDoc}
`
export const TicketStatusDetailFragmentDoc = gql`
  fragment TicketStatusDetail on TicketStatus {
    usedAt
    isUsed
    formattedUsedAt @client
    expiredAt
    formattedExpiredAt @client
    canUseTicket @client
    isExpired @client
    throughTicket {
      name
      index
      isUsed
      usedAt
      seat
      formattedUsedAt @client
    }
  }
`
export const ConsoleUserOwnedItemsPortfolioDetailFragmentDoc = gql`
  fragment ConsoleUserOwnedItemsPortfolioDetail on UserItem {
    id
    number
    isSystemProtected
    isUserProtected
    serials {
      serialId
      isUsed
      serial {
        ticketStatus {
          ...TicketStatusDetail
        }
        sku {
          id
        }
      }
    }
    item {
      isResaleAvailable
      id
      title
      type
      hideSalesNumber
      preSale {
        id
        event {
          id
          title
        }
      }
      metadata {
        wallpaper {
          isPrintEnabled
          printCount
          printType
        }
        ticket {
          isThroughTicket
          throughTicket {
            name
          }
        }
      }
      isDownloadable
      rarityIconImage {
        uri
        smallUri
      }
      type
      sku @client {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        stock
        unitName
        thumbnail {
          uri
          mediumUri
          compressedUri
          smallUri
        }
      }
      skuSettings {
        keyName
        units {
          id
          order
          pendingPurchaseNumber
          purchasedNumber
          stock
          thumbnail {
            uri
            mediumUri
            compressedUri
            smallUri
          }
          unitName
        }
      }
      thumbnail {
        front {
          uri
          mediumUri
          compressedUri
          smallUri
        }
      }
    }
  }
  ${TicketStatusDetailFragmentDoc}
`
export const ConsoleUserOwnedItemsPortfolioFragmentDoc = gql`
  fragment ConsoleUserOwnedItemsPortfolio on PageResult {
    edges {
      node {
        ...ConsoleUserOwnedItemsPortfolioDetail
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${ConsoleUserOwnedItemsPortfolioDetailFragmentDoc}
`
export const ArtistCustomerDetailFragmentDoc = gql`
  fragment ArtistCustomerDetail on ArtistCustomer {
    id
    userId
    user {
      id
      displayName
      phoneNumber
      fullName
      formattedPhoneNumber @client
      avatar {
        smallUri
        uri
      }
    }
    lastPurchasedAt
    formattedLastPurchasedAt @client
    purchaseCount
    formattedPurchaseCount @client
    patron {
      id
    }
  }
`
export const ArtistCustomerConnectionFragmentDoc = gql`
  fragment ArtistCustomerConnection on PageResult {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        ... on ArtistCustomer {
          ...ArtistCustomerDetail
        }
      }
    }
  }
  ${ArtistCustomerDetailFragmentDoc}
`
export const UserItemsAvatarConnectionFragmentDoc = gql`
  fragment UserItemsAvatarConnection on PageResult {
    edges {
      node {
        ... on UserItem {
          id
          item {
            id
            avatarResources {
              avatarResource {
                ...Resource
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${ResourceFragmentDoc}
`
export const UserItemsConnectionFragmentDoc = gql`
  fragment UserItemsConnection on PageResult {
    edges {
      node {
        ... on UserItem {
          id
          item {
            id
            wallpaperResources {
              wallpaperResource {
                ...Resource
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${ResourceFragmentDoc}
`
export const CustomProfileConnectionFragmentDoc = gql`
  fragment CustomProfileConnection on CustomProfile {
    avatarItem {
      id
      avatarResources {
        avatarResource {
          ...Resource
        }
        voiceResource {
          ...Resource
        }
      }
    }
    wallpaperItem {
      id
      wallpaperResources {
        wallpaperResource {
          ...Resource
        }
        audioResource {
          ...Resource
        }
      }
    }
  }
  ${ResourceFragmentDoc}
`
export const UserDeliveryInfoFragmentDoc = gql`
  fragment UserDeliveryInfo on User {
    id
    address {
      postalCode
      prefecture
      city
      line1
      line2
      fullAddress @client
    }
    email
    fullName
    phoneNumber
    birthday
    gender
    isPhoneNumberVerified
    isMembershipPersonalInformationRequired
    bank {
      bankName
      accountType
      accountHolderName
      branchCode
      branchName
      accountNumber
    }
  }
`
export const DeliveryItemBoxFragmentDoc = gql`
  fragment DeliveryItemBox on DeliveryItem {
    id
    deliveryInfo {
      address {
        postalCode
        prefecture
        city
        line1
        line2
        fullAddress @client
      }
    }
    appliedAt
    formattedAppliedAt @client
    status
    itemSerial {
      serialNumber
      formattedSerialNumber
      sku {
        id
        unitName
      }
    }
    item {
      id
      title
      description
      thumbnail {
        front {
          uri
          smallUri
        }
      }
      hideSalesNumber
      receptionPeriod {
        endedAt
        formattedEndedAt @client
      }
    }
  }
`
export const DeliveryItemsConnectionFragmentDoc = gql`
  fragment DeliveryItemsConnection on PageResult {
    edges {
      node {
        ...DeliveryItemBox
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${DeliveryItemBoxFragmentDoc}
`
export const UserSettingFragmentDoc = gql`
  fragment UserSetting on User {
    settings {
      notifications {
        campaign {
          startCampaign {
            email
            push
          }
          endCampaign {
            email
            push
          }
        }
        mission {
          newMission {
            email
            push
          }
          replyCommentMission {
            email
            push
          }
        }
        threadChannel {
          commentThread {
            email
            push
          }
          replyCommentThread {
            email
            push
          }
        }
        post {
          newPost {
            email
            push
          }
          replyPost {
            email
            push
          }
        }
        gacha {
          upcomingExpiry {
            email
            push
          }
        }
        trade {
          requestTrade {
            email
            push
          }
          commentTrade {
            email
            push
          }
          agreedTrade {
            email
            push
          }
          declinedTrade {
            email
            push
          }
        }
        liveStream {
          startLiveStream {
            email
            push
          }
        }
        announcement {
          official {
            email
            push
          }
        }
      }
    }
  }
`
export const ArtistSettingFragmentDoc = gql`
  fragment ArtistSetting on Artist {
    settings {
      notifications {
        post {
          replyPost {
            email
            push
          }
        }
        artistThreadChannel {
          newThread {
            email
            push
          }
        }
        membership {
          subscribe {
            push
            email
          }
        }
      }
    }
  }
`
export const EventCollectionItemsConnectionFragmentDoc = gql`
  fragment EventCollectionItemsConnection on PageResult {
    edges {
      node {
        ... on CollectionItem {
          id
          item {
            id
            title
            price
            formattedPrice @client
            remainingNumber @client
            formattedRemainingNumber @client
            paymentType
            type
            salesStatus
            isPublished
            usedNumber
            purchasedNumber
            issuedNumber
            pendingPurchaseNumber
            hideSalesNumber
            isResaleAvailable
            isPremium
            rarityIconImage {
              uri
              smallUri
            }
            rarity {
              id
              name
            }
            thumbnail {
              front {
                uri
                mediumUri
              }
              back {
                uri
                mediumUri
              }
            }
            skuSettings {
              keyName
              units {
                id
                unitName
                price
                order
              }
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`
export const EventDetailFragmentDoc = gql`
  fragment EventDetail on Event {
    id
    title
    description
    startedAt
    endedAt
    closeDate
    dateRange @client
    aboutDateRange @client
    dateArray @client
    formattedEventTime @client
    location {
      approximateAddress
      latitude
      longitude
      radius
      mapUri
    }
    thumbnail {
      mediumUri
      uri
    }
    banners {
      link {
        externalUrl
        innerPath
      }
      thumbnail {
        uri
        mediumUri
      }
    }
  }
`
export const EventDetailCollectionBoxFragmentDoc = gql`
  fragment EventDetailCollectionBox on EventCollection {
    id
    eventId
    collectionId
    collection {
      id
      artistId
      isEventPickup
      thumbnail {
        uri
      }
      title
      itemCount
      artist {
        id
        displayName
        avatar {
          uri
          smallUri
        }
      }
    }
  }
`
export const EventPreSaleBoxFragmentDoc = gql`
  fragment EventPreSaleBox on PreSale {
    id
    title
    type
    isSoldOut
    isPublished
    description
    itemIds
    note
    preSaleStatus @client
    startedAt
    formattedStartedAt @client
    endedAt
    formattedEndedAt @client
    isPremium
    planId
    items {
      id
      isResaleAvailable
    }
    lottery {
      applicationStartedAt
      applicationEndedAt
      purchasableStartedAt
      purchasableEndedAt
      resultNoticedAt
      formattedApplicationStartedAt @client
      formattedApplicationEndedAt @client
      formattedPurchasableStartedAt @client
      formattedPurchasableEndedAt @client
      formattedResultNoticedAt @client
      resultNoticeStatus
      purchasableCount
      maxChoice
      personalInformationRequirements
    }
    firstComeFirstServed {
      purchasableCount
      purchasableStartedAt
      purchasableEndedAt
      formattedPurchasableStartedAt @client
      formattedPurchasableEndedAt @client
      personalInformationRequirements
    }
  }
`
export const EventListFragmentDoc = gql`
  fragment EventList on Event {
    id
    title
    description
    startedAt
    endedAt
    closeDate
    preSales {
      ...EventPreSaleConnection
    }
    dateRange @client
    aboutDateRange @client
    location {
      approximateAddress
      latitude
      longitude
      radius
      mapUri
    }
    thumbnail {
      mediumUri
      uri
    }
  }
  ${EventPreSaleConnectionFragmentDoc}
`
export const FirstComeFirstServedConfirmationFragmentDoc = gql`
  fragment FirstComeFirstServedConfirmation on PreSale {
    id
    title
    type
    preSaleStatus @client
    isSoldOut
    firstComeFirstServed {
      purchasableCount
      purchasableStartedAt
      purchasableEndedAt
      formattedPurchasableStartedAt @client
      formattedPurchasableEndedAt @client
      personalInformationRequirements
    }
    items {
      id
      title
      price
      formattedPrice @client
      type
      metadata {
        ticket {
          isThroughTicket
          description
          note
        }
      }
      skuSettings {
        units {
          id
          unitName
          price
          formattedPrice @client
        }
      }
      fees(isKonbini: true) {
        collections {
          fee
          feeName
          per
        }
        konbini {
          fee
          feeName
          per
        }
        shipping {
          fee
          feeName
          per
        }
      }
    }
  }
`
export const PreSaleItemFragmentDoc = gql`
  fragment PreSaleItem on Item {
    id
    title
    price
    purchasedNumber
    pendingPurchaseNumber
    issuedNumber
    formattedPrice @client
    isResaleAvailable
    resaleEnabled
    type
    metadata {
      ticket {
        isThroughTicket
        resalePeriod {
          startedAt
          endedAt
          formattedStartedAt @client
          formattedEndedAt @client
        }
      }
    }
    skuSettings {
      units {
        id
        order
        unitName
        stock
        purchasedNumber
        pendingPurchaseNumber
        price
        formattedPrice @client
      }
    }
    fees(isKonbini: true, purchaseNumber: 1) {
      collections {
        fee
        feeName
        per
        feeType
        formattedFee @client
      }
      konbini {
        fee
        feeName
        per
        feeType
        formattedFee @client
      }
      shipping {
        fee
        feeName
        per
        feeType
        formattedFee @client
      }
    }
  }
`
export const FirstComeFirstServedDetailForGuestFragmentDoc = gql`
  fragment FirstComeFirstServedDetailForGuest on PreSale {
    id
    title
    type
    description
    startedAt
    endedAt
    note
    paymentMethods
    preSaleStatus @client
    isSoldOut
    event {
      id
      title
      note
      status
    }
    firstComeFirstServed {
      purchasableCount
      purchasableStartedAt
      purchasableEndedAt
      formattedPurchasableStartedAt @client
      formattedPurchasableEndedAt @client
      personalInformationRequirements
    }
    itemIds
    items {
      ...PreSaleItem
    }
  }
  ${PreSaleItemFragmentDoc}
`
export const PurchasedCartTransactionOrderDetailFragmentDoc = gql`
  fragment PurchasedCartTransactionOrderDetail on UserCartTransactionOrder {
    id
    isKonbini
    pendingPurchaseItem {
      id
      voucherUrl
    }
  }
`
export const FirstComeFirstServedDetailFragmentDoc = gql`
  fragment FirstComeFirstServedDetail on PreSale {
    ...FirstComeFirstServedDetailForGuest
    purchasedCartTransactionOrders {
      ...PurchasedCartTransactionOrderDetail
    }
    items {
      ...PreSaleItem
    }
  }
  ${FirstComeFirstServedDetailForGuestFragmentDoc}
  ${PurchasedCartTransactionOrderDetailFragmentDoc}
  ${PreSaleItemFragmentDoc}
`
export const UserGachaTicketConnectionFragmentDoc = gql`
  fragment UserGachaTicketConnection on UserGachaTicket {
    available
  }
`
export const GachasDetailConnectionFragmentDoc = gql`
  fragment GachasDetailConnection on PageResult {
    edges {
      node {
        ... on Gacha {
          id
          name
          description
          artistId
          thumbnail {
            uri
            smallUri
            mediumUri
          }
          isPremium
          planId
          isAccessible
          animation {
            roll {
              uri
              mediumUri
              smallUri
            }
          }
          endAt
          deliveryDateText
          formattedEndAt @client
          purchasableGachaTickets {
            count
            displayCount
            price
            prepaidPoints
          }
          gpsData {
            approximateAddress
            latitude
            longitude
            radius
          }
          standByAnimations {
            bulk {
              contentType
              uri
              smallUri
            }
            single {
              contentType
              uri
              smallUri
            }
          }
          totalLimitedItemCount
          remainingLimitedItemCount
          isSoldOut @client
          isLimited @client
          rollAlgorithm
          price
          gachaRollType
          rewardAnimations {
            bulk {
              animation {
                normal {
                  uri
                }
                special {
                  uri
                }
              }
            }
            single {
              animation {
                normal {
                  uri
                }
                special {
                  uri
                }
              }
            }
          }
        }
      }
    }
  }
`
export const MembershipSubscriptionPlanDetailFragmentDoc = gql`
  fragment MembershipSubscriptionPlanDetail on MembershipSubscriptionPlan {
    interval
    planId
    amount
    formattedAmount @client
    planRelationship @client
    month @client
    status
  }
`
export const MembershipSubscriptionProductDetailFragmentDoc = gql`
  fragment MembershipSubscriptionProductDetail on MembershipSubscriptionProduct {
    title
    productId
    annualContent
    annualDiscountText
    content
    plans {
      ...MembershipSubscriptionPlanDetail
    }
  }
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const ArtistMembershipSubscriptionDetailFragmentDoc = gql`
  fragment ArtistMembershipSubscriptionDetail on Artist {
    subscription {
      products {
        ...MembershipSubscriptionProductDetail
      }
    }
  }
  ${MembershipSubscriptionProductDetailFragmentDoc}
`
export const ArtistGachaDetailFragmentDoc = gql`
  fragment ArtistGachaDetail on Artist {
    id
    displayName
    avatar {
      uri
      smallUri
    }
    patron {
      isPersonalInformationRequired
    }
    ...ArtistMembershipSubscriptionDetail
    totalUnreadMentionCount
  }
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
`
export const GachaItemsConnectionFragmentDoc = gql`
  fragment GachaItemsConnection on GachaItem {
    weight
    count
    item {
      title
      id
      thumbnail {
        front {
          uri
          smallUri
        }
      }
    }
  }
`
export const UserItemDetailFragmentDoc = gql`
  fragment UserItemDetail on UserItem {
    id
    isUsed
    number
    type
    createdAt
  }
`
export const GachaItemDetailFragmentDoc = gql`
  fragment GachaItemDetail on Item {
    id
    title
    rarity {
      id
      name
    }
    issuedNumber
    thumbnailType
    rarityIconImage {
      uri
      smallUri
    }
    thumbnail {
      front {
        uri
        mediumUri
      }
      back {
        uri
        mediumUri
      }
    }
    avatarResources {
      voiceResource {
        ...Resource
      }
    }
  }
  ${ResourceFragmentDoc}
`
export const GachaOrderDetailFragmentDoc = gql`
  fragment GachaOrderDetail on GachaOrder {
    rewardItems {
      count
      itemId
      userItem {
        ...UserItemDetail
      }
      item {
        ...GachaItemDetail
      }
      obtainedUserItemSerials {
        itemId
        formattedSerialNumber
      }
    }
    rewardAnimation {
      uri
      mediumUri
    }
    gacha {
      id
      artistId
      animation {
        roll {
          uri
          smallUri
        }
      }
      standByAnimations {
        bulk {
          contentType
          uri
          smallUri
        }
        single {
          contentType
          uri
          smallUri
        }
      }
      rewardAnimations {
        bulk {
          animation {
            normal {
              uri
            }
            special {
              uri
            }
          }
        }
        single {
          animation {
            normal {
              uri
            }
            special {
              uri
            }
          }
        }
      }
      rollAlgorithm
      remainingLimitedItemCount
      totalLimitedItemCount
      isLimited @client
      price
      gachaRollType
    }
  }
  ${UserItemDetailFragmentDoc}
  ${GachaItemDetailFragmentDoc}
`
export const FollowerDetailFragmentDoc = gql`
  fragment FollowerDetail on Follower {
    id
    artist {
      id
      accountId
      formattedAccountId @client
      displayName
      avatar {
        uri
      }
    }
  }
`
export const FolloweesConnectionFragmentDoc = gql`
  fragment FolloweesConnection on PageResult {
    edges {
      node {
        ...FollowerDetail
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${FollowerDetailFragmentDoc}
`
export const CampaignBoxFragmentDoc = gql`
  fragment CampaignBox on Campaign {
    id
    title
    thumbnail {
      uri
      smallUri
    }
  }
`
export const CampaignsConnectionFragmentDoc = gql`
  fragment CampaignsConnection on PageResult {
    edges {
      node {
        ...CampaignBox
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${CampaignBoxFragmentDoc}
`
export const MemberArtistFragmentDoc = gql`
  fragment MemberArtist on Patron {
    artist {
      id
      displayName
      avatar {
        uri
        compressedUri
        mediumUri
        smallUri
      }
    }
  }
`
export const FollowArtistsFragmentDoc = gql`
  fragment FollowArtists on PageResult {
    edges {
      node {
        ... on Follower {
          id
          artist {
            id
            displayName
            avatar {
              uri
              compressedUri
              mediumUri
              smallUri
            }
            totalUnreadMentionCount
          }
        }
      }
    }
  }
`
export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on User {
    id
    displayName
    avatar {
      mediumUri
      uri
    }
    organizationPatrons {
      ...MemberArtist
    }
    organizationFollowees(pageInput: { limit: 30 }) {
      ...FollowArtists
    }
    blockedUsers
    hasWaitingPaymentItems
    hasUnappliedDeliveryItems
    hasWaitingResaleRafflePaymentItems
    hasManagementTask @client
    unreadNotificationCount
    isMembershipPersonalInformationRequired
  }
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const FeedConnectionFragmentDoc = gql`
  fragment FeedConnection on PageResult {
    edges {
      node {
        ... on Feed {
          id
          type
          data {
            ... on Comment {
              ...CommentBox
            }
            ... on Post {
              ...PostBox
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${CommentBoxFragmentDoc}
  ${PostBoxFragmentDoc}
`
export const EntryCampaignRankingConnectionFragmentDoc = gql`
  fragment EntryCampaignRankingConnection on EntryCampaignRanking {
    id
    thumbnail {
      uri
      smallUri
    }
    myRank {
      ... on EntryCampaignUserMyRank {
        rank
        displayName
        point
      }
      ... on EntryCampaignArtistMyRank {
        artistId
        rank
        displayName
        point
      }
    }
  }
`
export const FollowArtistGachasConnectionFragmentDoc = gql`
  fragment FollowArtistGachasConnection on PageResult {
    edges {
      node {
        ... on Gacha {
          id
          name
          description
          endAt
          formattedEndAt @client
          artistId
          thumbnail {
            uri
            smallUri
          }
          isPremium
          gpsData {
            approximateAddress
            latitude
            longitude
            radius
          }
          artist {
            id
          }
        }
      }
    }
  }
`
export const FollowArtistMissionsConnectionFragmentDoc = gql`
  fragment FollowArtistMissionsConnection on PageResult {
    edges {
      node {
        ...MissionBox
      }
    }
  }
  ${MissionBoxFragmentDoc}
`
export const BannerConnectionFragmentDoc = gql`
  fragment BannerConnection on OrganizationBanner {
    thumbnail {
      uri
      mediumUri
      smallUri
    }
    text
    link {
      innerPath
      externalUrl
    }
    order
  }
`
export const ArtistBottomTabFragmentDoc = gql`
  fragment ArtistBottomTab on Artist {
    id
    displayName
    accountId
    avatar {
      uri
      smallUri
    }
    appLinks {
      link
      type
    }
    totalUnreadMentionCount
  }
`
export const RankingBaseConnectionFragmentDoc = gql`
  fragment RankingBaseConnection on Ranking {
    id
    artistId
    createdAt
    updatedAt
    status
    artist {
      ...ArtistBottomTab
    }
  }
  ${ArtistBottomTabFragmentDoc}
`
export const RankingPeriodBaseConnectionFragmentDoc = gql`
  fragment RankingPeriodBaseConnection on RankingPeriod {
    id
    type
    periodType
    period
    totalUsers
    rankingId
    createdAt
  }
`
export const GlobalHomeOrganizationArtistRankingsConnectionFragmentDoc = gql`
  fragment GlobalHomeOrganizationArtistRankingsConnection on PageResult {
    edges {
      node {
        ... on Ranking {
          ...RankingBaseConnection
          latestRankingPeriod(periodType: monthly) {
            ...RankingPeriodBaseConnection
            myRanking {
              ...RankingUserWithoutIdConnection
            }
            rankings {
              edges {
                node {
                  ...RankingUserConnection
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${RankingBaseConnectionFragmentDoc}
  ${RankingPeriodBaseConnectionFragmentDoc}
  ${RankingUserWithoutIdConnectionFragmentDoc}
  ${RankingUserConnectionFragmentDoc}
`
export const GlobalEventFragmentDoc = gql`
  fragment GlobalEvent on Event {
    id
    title
    thumbnail {
      mediumUri
      uri
    }
    preSales {
      ...EventPreSaleConnection
    }
    startedAt
    endedAt
    dateRange @client
    aboutDateRange @client
    dateArray @client
    location {
      approximateAddress
      latitude
      longitude
      radius
      mapUri
    }
  }
  ${EventPreSaleConnectionFragmentDoc}
`
export const SerialItemOwnersFragmentDoc = gql`
  fragment SerialItemOwners on PageResult {
    edges {
      node {
        ... on ItemSerial {
          id
          itemId
          serialNumber
          formattedSerialNumber
          createdAt
          formattedCreatedAt @client
          updatedAt
          obtainedDate
          formattedObtainedDate @client
          formattedObtainedDateTime @client
          formattedAssignedNumber
          sku {
            id
            unitName
          }
          ticketStatus {
            isUsed
            usedAt
            formattedUsedAt @client
          }
          isNft
          user {
            id
            displayName
            fullName
            avatar {
              uri
              smallUri
            }
            phoneNumber
          }
          authentication {
            authenticatedAt
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`
export const PortfolioSerialsFragmentDoc = gql`
  fragment PortfolioSerials on UserItem {
    id
    serials {
      formattedSerialNumber
    }
  }
`
export const RelatedMissionDetailFragmentDoc = gql`
  fragment RelatedMissionDetail on Mission {
    description
    id
    type
    reward {
      gachaTicket {
        count
      }
    }
    title
    isAccomplished
    formattedExpiredAt @client
    label @client
    expiredAt
    content {
      ... on ItemCollectContent {
        image {
          smallUri
          uri
        }
        items {
          itemId
          requiredCount
          item {
            ...MultipleItemsMission
          }
        }
      }
    }
  }
  ${MultipleItemsMissionFragmentDoc}
`
export const ItemOrdersConnectionFragmentDoc = gql`
  fragment ItemOrdersConnection on PageResult {
    edges {
      node {
        ... on ItemOrder {
          id
          item {
            id
            title
            hideSalesNumber
            thumbnail {
              front {
                uri
                smallUri
              }
            }
          }
          serials {
            id
            skuId
            sku {
              id
              thumbnail {
                uri
                smallUri
              }
            }
          }
          createdAt
          formattedCreatedAt @client
          price
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
`
export const PreSaleLotteryApplicantDetailFragmentDoc = gql`
  fragment PreSaleLotteryApplicantDetail on PreSaleLotteryApplicant {
    id
    isPaymentError
    preSale {
      id
      title
      description
      note
      type
      event {
        id
        title
      }
      lottery {
        applicationStartedAt
        applicationEndedAt
        maxChoice
        personalInformationRequirements
        purchasableCount
        purchasableStartedAt
        purchasableEndedAt
        resultNoticeStatus
        resultNoticedAt
      }
      items {
        type
        fees {
          collections {
            fee
            feeDescription
            feeName
            feeType
            id
            per
          }
        }
      }
    }
    status
    appliedAt
    choices
    paymentMethod
    purchaseCountChoices
    resultNoticedAt
    status
    winChoiceNumber
    cartTransactionOrder {
      id
      isKonbini
      paymentType
      pendingPurchaseItem {
        id
        expiredAt
        formattedExpiredAt @client
        voucherUrl
      }
    }
    choiceItemDetails {
      itemId
      skuId
      count
      item {
        id
        title
        price
      }
      skuUnit {
        id
        unitName
        price
      }
      fees {
        collections {
          fee
          feeName
        }
        konbini {
          fee
          feeName
        }
        shipping {
          fee
          feeName
        }
      }
    }
  }
`
export const PreSaleLotteryApplicantConfirmationFragmentDoc = gql`
  fragment PreSaleLotteryApplicantConfirmation on PreSaleLotteryApplicant {
    id
    winChoiceNumber
    choiceItemDetails {
      count
      item {
        id
        price
      }
      skuUnit {
        id
        price
      }
      fees {
        collections {
          fee
          feeName
        }
        konbini {
          fee
          feeName
        }
        shipping {
          fee
          feeName
        }
      }
    }
  }
`
export const PreSaleLotteryApplicantFragmentDoc = gql`
  fragment PreSaleLotteryApplicant on PreSaleLotteryApplicant {
    id
    preSaleId
    isPaymentError
    preSale {
      id
      title
      type
      lottery {
        resultNoticedAt
      }
      event {
        id
        title
      }
    }
    status
    appliedAt
    paymentMethod
    purchaseCountChoices
    resultNoticedAt
    status
    winChoiceNumber
    choiceItemDetails {
      itemId
      skuId
      count
      item {
        id
        title
        price
      }
      skuUnit {
        id
        unitName
        price
      }
    }
  }
`
export const PreSaleLotteryApplicantsFragmentDoc = gql`
  fragment PreSaleLotteryApplicants on PageResult {
    edges {
      node {
        ... on PreSaleLotteryApplicant {
          ...PreSaleLotteryApplicant
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${PreSaleLotteryApplicantFragmentDoc}
`
export const PreSaleLotteryConfirmationFragmentDoc = gql`
  fragment PreSaleLotteryConfirmation on PreSale {
    id
    title
    type
    lottery {
      applicationStartedAt
      applicationEndedAt
      purchasableStartedAt
      purchasableEndedAt
      resultNoticedAt
      formattedApplicationStartedAt @client
      formattedApplicationEndedAt @client
      formattedPurchasableStartedAt @client
      formattedPurchasableEndedAt @client
      formattedResultNoticedAt @client
      resultNoticeStatus
    }
    items {
      id
      title
      price
      formattedPrice @client
      metadata {
        ticket {
          isThroughTicket
          description
          note
        }
      }
      skuSettings {
        units {
          id
          unitName
          price
          formattedPrice @client
        }
      }
      fees(isKonbini: true) {
        collections {
          fee
          feeName
          per
        }
        konbini {
          fee
          feeName
          per
        }
        shipping {
          fee
          feeName
          per
        }
      }
    }
  }
`
export const PreSaleLotteryDetailFragmentDoc = gql`
  fragment PreSaleLotteryDetail on PreSale {
    id
    title
    type
    description
    startedAt
    endedAt
    note
    paymentMethods
    preSaleStatus @client
    isPremium
    isAccessible
    planId
    artist {
      id
      subscription {
        products {
          productId
          title
          content
          plans {
            planId
            amount
          }
        }
      }
      patron {
        id
        updatedAt
        formattedUpdatedAt @client
        subscription {
          planId
          amount
        }
      }
    }
    event {
      id
      title
      note
      status
    }
    lottery {
      applicationStartedAt
      applicationEndedAt
      purchasableStartedAt
      purchasableEndedAt
      resultNoticedAt
      formattedApplicationStartedAt @client
      formattedApplicationEndedAt @client
      formattedPurchasableStartedAt @client
      formattedPurchasableEndedAt @client
      formattedResultNoticedAt @client
      resultNoticeStatus
      purchasableCount
      maxChoice
      personalInformationRequirements
    }
    itemIds
    items {
      id
      title
      price
      formattedPrice @client
      issuedNumber
      pendingPurchaseNumber
      purchasedNumber
      metadata {
        ticket {
          isThroughTicket
        }
      }
      skuSettings {
        units {
          id
          order
          unitName
          price
          formattedPrice @client
        }
      }
      fees(isKonbini: true, purchaseNumber: 1) {
        collections {
          fee
          feeName
          per
          feeType
          formattedFee @client
        }
        konbini {
          fee
          feeName
          per
          feeType
          formattedFee @client
        }
        shipping {
          fee
          feeName
          per
          feeType
          formattedFee @client
        }
      }
    }
  }
`
export const UserDeliveryDetailFragmentDoc = gql`
  fragment UserDeliveryDetail on User {
    id
    phoneNumber
    formattedPhoneNumber @client
    birthday
    fullName
    gender
    isPhoneNumberVerified
    address {
      postalCode
      prefecture
      city
      line1
      line2
    }
  }
`
export const MissionRewardConnectionFragmentDoc = gql`
  fragment MissionRewardConnection on Mission {
    artistId
    noAnswer
    reward {
      gachaTicket {
        count
        gachaTicket {
          thumbnails {
            reward {
              uri
              smallUri
              mediumUri
              compressedUri
            }
          }
        }
      }
      item {
        item {
          id
          thumbnailType
          thumbnail {
            front {
              uri
              smallUri
              mediumUri
              compressedUri
            }
          }
        }
      }
    }
  }
`
export const MissionDetailFragmentDoc = gql`
  fragment MissionDetail on Mission {
    ...MissionBox
    commentCount
    noAnswer
    content {
      ... on QuizContent {
        image {
          uri
          smallUri
          mediumUri
        }
        answer
      }
      ... on ItemCollectContent {
        image {
          smallUri
          uri
        }
        items {
          itemId
          requiredCount
          ownedCount
          item {
            ...MultipleItemsMission
          }
        }
      }
    }
  }
  ${MissionBoxFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
`
export const QuizResultConnectionFragmentDoc = gql`
  fragment QuizResultConnection on QuizResult {
    missionId
    correct
    mission {
      ...MissionDetail
    }
  }
  ${MissionDetailFragmentDoc}
`
export const ItemCollectResultConnectionFragmentDoc = gql`
  fragment ItemCollectResultConnection on ItemCollectMissionResult {
    missionId
    correct
    lackItems {
      itemId
      requiredCount
      item {
        id
        title
        hideSalesNumber
      }
    }
    mission {
      ...MissionDetail
    }
  }
  ${MissionDetailFragmentDoc}
`
export const PickupUserFragmentDoc = gql`
  fragment PickupUser on PickupUser {
    id
    userId
    isCompleted
    completedAt
    formattedCompletedAt @client
    isExpired @client
    pickupTime
    formattedPickupTime @client
    formattedPickupStartEndTime @client
    eventId
    collectionId
    pickupOrder @client {
      itemId
      title
      serialIds
      count
      serials {
        id
        skuId
      }
    }
    itemsString @client
    userItemIds
    pickupRemainingTime @client {
      hours
      minutes
      expiring
    }
    userItems {
      id
      number
      item {
        id
        title
        formattedPrice @client
        paymentType
        hideSalesNumber
        thumbnail {
          front {
            uri
            mediumUri
          }
        }
        sku @client {
          id
          order
          pendingPurchaseNumber
          purchasedNumber
          stock
          unitName
          price
          formattedPrice @client
          thumbnail {
            uri
            smallUri
          }
        }
        skuSettings {
          keyName
          units {
            id
            order
            pendingPurchaseNumber
            purchasedNumber
            stock
            price
            formattedPrice @client
            thumbnail {
              uri
              smallUri
            }
            unitName
          }
        }
      }
      serials {
        id
        serial {
          id
          sku {
            id
            unitName
            price
            formattedPrice @client
            thumbnail {
              smallUri
            }
          }
        }
      }
    }
    event {
      id
      title
      startedAt
      endedAt
      closeDate
      dateRange @client
      aboutDateRange @client
      dateArray @client
      location {
        mapUri
      }
      thumbnail {
        smallUri
        uri
      }
    }
    eventCollection {
      id
      eventId
      collectionId
      validMinutes
      pickupLocation
      collection {
        id
        title
        thumbnail {
          uri
        }
        artist {
          id
          displayName
          avatar {
            uri
            smallUri
          }
        }
      }
      myPickupUsers {
        id
        userId
        pickupTime
        formattedPickupStartEndTime @client
        cartTransactionOrders {
          ...CartTransactionOrderDetail
        }
        userItems {
          number
          item {
            id
            hideSalesNumber
          }
        }
        eventCollection {
          id
          validMinutes
        }
      }
    }
    ticketItemSerialsInput @client {
      itemId
      serialIds
    }
    cartTransactionOrders {
      itemOrders {
        canceledSerialIds
        id
        serialIds
        item {
          id
          title
          hideSalesNumber
        }
        serials {
          id
          skuId
        }
      }
    }
    user {
      id
      displayName
      phoneNumber
      fullName
      email
      formattedPhoneNumber @client
    }
  }
  ${CartTransactionOrderDetailFragmentDoc}
`
export const PickupUserListItemFragmentDoc = gql`
  fragment PickupUserListItem on PickupUser {
    id
    userId
    isCompleted
    completedAt
    formattedCompletedAt @client
    isExpired @client
    pickupTime
    formattedPickupTime @client
    formattedPickupStartEndTime @client
    eventId
    collectionId
    itemsString @client
    pickupOrder @client {
      itemId
      title
      serialIds
      count
      serials {
        id
        skuId
      }
    }
    pickupRemainingTime @client {
      hours
      minutes
      expiring
    }
    event {
      id
      title
    }
    eventCollection {
      id
      eventId
      collectionId
      validMinutes
      pickupLocation
      collection {
        id
        artistId
        title
        thumbnail {
          uri
        }
      }
    }
    userItemIds
    cartTransactionOrders {
      itemOrders {
        canceledSerialIds
        id
        serialIds
        item {
          id
          title
          hideSalesNumber
        }
      }
    }
  }
`
export const PickupUserEventFragmentDoc = gql`
  fragment PickupUserEvent on Event {
    id
    title
    startedAt
    endedAt
    dateRange @client
    aboutDateRange @client
    dateArray @client
    thumbnail {
      uri
      smallUri
    }
    preSales {
      ...EventPreSaleConnection
    }
    eventCollections(pageInput: { limit: 30 }) {
      edges {
        node {
          ... on EventCollection {
            id
            eventId
            collectionId
            myPickupUsers {
              id
              userId
              pickupTime
              formattedPickupStartEndTime @client
            }
          }
        }
      }
    }
  }
  ${EventPreSaleConnectionFragmentDoc}
`
export const UserConnectionFragmentDoc = gql`
  fragment UserConnection on User {
    id
    accountId
    formattedAccountId @client
    avatar {
      uri
      smallUri
      mediumUri
      compressedUri
    }
    displayName
    introduction
    hasWaitingPaymentItems
    hasUnappliedDeliveryItems
    hasManagementTask @client
    unreadNotificationCount
    isMembershipPersonalInformationRequired
    hasWaitingResaleRafflePaymentItems
    customProfile {
      avatarItem {
        id
        avatarResources {
          avatarResource {
            ...Resource
          }
          voiceResource {
            ...Resource
          }
        }
      }
      wallpaperItem {
        id
        thumbnail {
          back {
            uri
          }
        }
        wallpaperResources {
          wallpaperResource {
            ...Resource
          }
          audioResource {
            ...Resource
          }
        }
      }
    }
  }
  ${ResourceFragmentDoc}
`
export const OrganizationArtistRankingsConnectionFragmentDoc = gql`
  fragment OrganizationArtistRankingsConnection on PageResult {
    edges {
      node {
        ... on Ranking {
          ...RankingBaseConnection
          latestRankingPeriod(periodType: monthly) {
            ...RankingPeriodBaseConnection
            myRanking {
              ...RankingUserWithoutIdConnection
            }
            rankings {
              edges {
                node {
                  ...RankingUserConnection
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${RankingBaseConnectionFragmentDoc}
  ${RankingPeriodBaseConnectionFragmentDoc}
  ${RankingUserWithoutIdConnectionFragmentDoc}
  ${RankingUserConnectionFragmentDoc}
`
export const EventTicketCardFragmentDoc = gql`
  fragment EventTicketCard on Event {
    id
    title
    location {
      approximateAddress
    }
    preSales {
      ...EventPreSaleConnection
    }
    startedAt
    endedAt
    closeDate
    dateRange @client
    myPreSaleItems {
      itemId
      skuId
      item {
        id
        title
        sku @client {
          id
          unitName
        }
        skuSettings {
          keyName
          units {
            id
            unitName
          }
        }
        metadata {
          ticket {
            isThroughTicket
          }
        }
      }
    }
  }
  ${EventPreSaleConnectionFragmentDoc}
`
export const OngoingUpcomingPreSaleEventConnectionFragmentDoc = gql`
  fragment OngoingUpcomingPreSaleEventConnection on PageResult {
    edges {
      node {
        ... on Event {
          ...EventTicketCard
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${EventTicketCardFragmentDoc}
`
export const MyPreSaleEventConnectionFragmentDoc = gql`
  fragment MyPreSaleEventConnection on PageResult {
    edges {
      node {
        ... on Event {
          ...EventTicketCard
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${EventTicketCardFragmentDoc}
`
export const TradeBoxFragmentDoc = gql`
  fragment TradeBox on TradeNegotiation {
    id
    status
    createdAt
    formattedCreatedAt @client
    request {
      id
      requestedByUser {
        id
        displayName
        avatar {
          uri
          smallUri
        }
      }
      providableItems {
        item {
          id
          issuedArtist {
            id
          }
          thumbnail {
            front {
              uri
              mediumUri
            }
          }
          ownedCount
          title
        }
        itemSerial {
          formattedSerialNumber
        }
        itemId
      }
    }
    candidates {
      id
      user {
        id
        displayName
        avatar {
          uri
          smallUri
        }
      }
      candidateItems {
        item {
          id
          issuedArtist {
            id
          }
          thumbnail {
            front {
              uri
              mediumUri
            }
          }
          ownedCount
          title
        }
        itemSerial {
          formattedSerialNumber
        }
        itemId
      }
    }
  }
`
export const TradeNegotiationsMyRequestsConnectionFragmentDoc = gql`
  fragment TradeNegotiationsMyRequestsConnection on PageResult {
    edges {
      node {
        ...TradeBox
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${TradeBoxFragmentDoc}
`
export const TradeMyCandidatesConnectionFragmentDoc = gql`
  fragment TradeMyCandidatesConnection on PageResult {
    edges {
      node {
        ... on TradeCandidate {
          tradeNegotiation {
            ...TradeBox
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${TradeBoxFragmentDoc}
`
export const MagazineItemFragmentDoc = gql`
  fragment MagazineItem on Item {
    id
    title
    description
    price
    formattedPrice @client
    paymentType
    isPremium
    issuedArtist {
      id
    }
    thumbnail {
      front {
        uri
        smallUri
        mediumUri
      }
    }
    collection {
      id
      title
    }
  }
`
export const NewStoreItemConnectionFragmentDoc = gql`
  fragment NewStoreItemConnection on PageResult {
    edges {
      node {
        ... on Item {
          ...MagazineItem
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${MagazineItemFragmentDoc}
`
export const NftTokenDetailFragmentDoc = gql`
  fragment NftTokenDetail on NftToken {
    id
    chain
    createdAt
    formattedCreatedAt @client
    status
    ownedByUserAddress
    tokenDetailUri
    metadata {
      item {
        item {
          id
          issuedArtist {
            id
            displayName
            avatar {
              uri
              smallUri
            }
          }
        }
      }
    }
  }
`
export const NotificationBoxFragmentDoc = gql`
  fragment NotificationBox on Notification {
    id
    body
    date
    formattedDate @client
    link
    title
  }
`
export const NotificationsConectionFragmentDoc = gql`
  fragment NotificationsConection on PageResult {
    edges {
      node {
        ... on Notification {
          ...NotificationBox
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${NotificationBoxFragmentDoc}
`
export const OrganizationNotificationBoxFragmentDoc = gql`
  fragment OrganizationNotificationBox on OrganizationNotification {
    id
    body
    link
    title
    createdAt
    formattedCreatedAt @client
    artist {
      id
      displayName
      avatar {
        uri
        smallUri
      }
    }
  }
`
export const OrganizationNotificationConnectionFragmentDoc = gql`
  fragment OrganizationNotificationConnection on PageResult {
    edges {
      node {
        ... on OrganizationNotification {
          ...OrganizationNotificationBox
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${OrganizationNotificationBoxFragmentDoc}
`
export const UserItemSerialDetailFragmentDoc = gql`
  fragment UserItemSerialDetail on UserItemSerial {
    id
    serialId
    isProtected
    itemId
    formattedSerialNumber
    isNft
    tokenId
    isUsed
    obtainedDate
    formattedObtainedDateTime @client
    formattedObtainedDate @client
    nftToken {
      id
      status
    }
    serial {
      id
      ticketStatus {
        ...TicketStatusDetail
      }
      ticketDistributionLink {
        id
        distributionId
        expiredAt
        isExpired @client
        formattedExpiredAt @client
        user {
          id
          avatar {
            uri
            smallUri
          }
          displayName
        }
      }
      owner {
        id
        avatar {
          uri
          smallUri
        }
        displayName
      }
      formattedAssignedNumber
      formattedSerialNumber
      sku {
        id
        unitName
      }
      metadata {
        ticket {
          fullName
        }
        wallpaper {
          print {
            expiredAt
            contentsCode
            qrcode {
              uri
            }
          }
        }
      }
    }
  }
  ${TicketStatusDetailFragmentDoc}
`
export const PortfolioConnectionFragmentDoc = gql`
  fragment PortfolioConnection on UserItem {
    id
    isUsed
    number
    type
    createdAt
    item {
      ...ItemDescription
    }
    isSystemProtected
    isUserProtected
    serials {
      ...UserItemSerialDetail
    }
    user {
      id
      fullName
    }
  }
  ${ItemDescriptionFragmentDoc}
  ${UserItemSerialDetailFragmentDoc}
`
export const PatronDetailFragmentDoc = gql`
  fragment PatronDetail on Patron {
    id
    amount
    createdAt
    updatedAt
    continueMonth @client
    formattedUpdatedAt @client
    paymentMethod
    formattedCreatedAt @client
    cancelAtPeriodEnd
    expiredAt
    formattedExpiredAt @client
    artistId
    type
    user {
      id
    }
    isPersonalInformationRequired
    downgradeAtNextPeriod
    subscription {
      ...SubscriptionDetail
    }
    artist {
      id
      accountId
      formattedAccountId @client
      displayName
      avatar {
        uri
        smallUri
      }
      paymentType
      pendingPurchasePatron {
        id
        artistId
        status
        expiredAt
        formattedExpiredAt @client
        voucherUrl
        metadata {
          membership {
            planId
          }
        }
      }
      ...ArtistMembershipSubscriptionDetail
    }
    iapSubscription {
      store
    }
  }
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
`
export const PortfolioSkuFragmentDoc = gql`
  fragment PortfolioSku on UserItem {
    id
    isUsed
    number
    type
    createdAt
    item {
      id
      title
      type
      description
      price
      formattedPrice @client
      isDrm @client
      isDownloadable
      ownedCount
      hideSalesNumber
      paymentType
      preSale {
        id
        event {
          id
          title
          description
        }
      }
      collection {
        id
        itemCount
        isPublishOnStore
        event {
          ...EventBox
        }
        collectionItems {
          edges {
            node {
              ... on CollectionItem {
                ...CollectionItemDetail
              }
            }
          }
        }
      }
      requirePersonalInformation
      formattedRemainingNumber @client
      images {
        caption
        mediumUri
        uri
      }
      sku @client {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        stock
        unitName
        thumbnail {
          uri
          mediumUri
        }
      }
      skuSettings {
        keyName
        units {
          id
          order
          pendingPurchaseNumber
          purchasedNumber
          remainingNumber @client
          stock
          thumbnail {
            uri
            mediumUri
          }
          unitName
        }
      }
      personalInformationRequirements
      purchasableUsers {
        ...PurchasableUserDetail
      }
      isResaleAvailable(skuId: $skuId)
      resaleOpenCount(skuId: $skuId)
      resaleRaffleAppliedCount(skuId: $skuId)
      issuedArtist {
        id
      }
      rarity {
        id
        name
      }
      thumbnail {
        front {
          ...ThumbnailResource
        }
        back {
          ...ThumbnailResource
        }
      }
      nft {
        chains {
          chainName
        }
      }
      rarityIconImage {
        uri
        smallUri
      }
      resource {
        ...Resource
      }
      resources {
        ...Resource
      }
      avatarResources {
        voiceResource {
          ...Resource
        }
        avatarResource {
          ...Resource
        }
      }
      wallpaperResources {
        audioResource {
          ...Resource
        }
        wallpaperResource {
          ...Resource
        }
      }
      resaleEnabled
      relatedMissions {
        id
        expiredAt
        isAccomplished
        label @client
      }
      thumbnailType
      issuedNumber
      purchasedNumber
      commentCount
      ogp {
        rectUri
        uri
      }
      metadata {
        ticket {
          note
          description
          expirationTerm
          isThroughTicket
          resalePeriod {
            startedAt
            endedAt
          }
          distributionEnabled
          assignedNumberType
          startAssignedNumber
          assignedNumberPrefix
          isThroughTicket
          throughTicket {
            name
          }
        }
        magazine {
          pageDirection
          pageType
        }
        wallpaper {
          isPrintEnabled
        }
      }
      isTicketDistributionEnabled
      userOpenResaleItem(skuId: $skuId) {
        id
      }
    }
    isSystemProtected
    isUserProtected
    serials {
      ...UserItemSerialDetail
    }
    user {
      id
      fullName
      organizationPatrons {
        ...PatronDetail
      }
    }
  }
  ${EventBoxFragmentDoc}
  ${CollectionItemDetailFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${ThumbnailResourceFragmentDoc}
  ${ResourceFragmentDoc}
  ${UserItemSerialDetailFragmentDoc}
  ${PatronDetailFragmentDoc}
`
export const PortfolioItemsDetailFragmentDoc = gql`
  fragment PortfolioItemsDetail on UserItem {
    id
    number
    isSystemProtected
    isUserProtected
    serials {
      serialId
      serial {
        sku {
          id
        }
      }
    }
    item {
      isResaleAvailable
      id
      title
      hideSalesNumber
      rarityIconImage {
        uri
        smallUri
      }
      type
      sku @client {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        remainingNumber @client
        stock
        unitName
        thumbnail {
          uri
          mediumUri
        }
      }
      skuSettings {
        keyName
        units {
          id
          order
          pendingPurchaseNumber
          purchasedNumber
          remainingNumber @client
          stock
          thumbnail {
            uri
            mediumUri
          }
          unitName
        }
      }
      thumbnail {
        front {
          mediumUri
          uri
        }
      }
      nft {
        chains {
          chainName
        }
      }
      metadata {
        wallpaper {
          isPrintEnabled
          printCount
          printType
        }
        ticket {
          note
          description
          expirationTerm
          isThroughTicket
          resalePeriod {
            startedAt
            endedAt
          }
          distributionEnabled
          assignedNumberType
          startAssignedNumber
          assignedNumberPrefix
          isThroughTicket
          throughTicket {
            name
          }
        }
        magazine {
          pageDirection
          pageType
        }
      }
      isDownloadable
    }
  }
`
export const PortfolioItemsConnectionFragmentDoc = gql`
  fragment PortfolioItemsConnection on PageResult {
    edges {
      node {
        ...PortfolioItemsDetail
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${PortfolioItemsDetailFragmentDoc}
`
export const PaymentCreateDetailFragmentDoc = gql`
  fragment paymentCreateDetail on CreditCard {
    id
    name
    last4
    expMonth
    expYear
    brand
  }
`
export const RequiresActionDetailFragmentDoc = gql`
  fragment requiresActionDetail on RequiresAction {
    status
    clientSecret
  }
`
export const PendingPurchaseItemSkuDetailFragmentDoc = gql`
  fragment PendingPurchaseItemSkuDetail on PendingPurchaseItemSku {
    itemId
    item {
      ...ItemBox
      collection {
        id
        title
      }
    }
    count
    skus {
      skuId
      count
      sku {
        id
        unitName
        order
        price
        formattedPrice @client
        pendingPurchaseNumber
        purchasedNumber
        stock
        thumbnail {
          uri
          smallUri
          mediumUri
        }
      }
    }
  }
  ${ItemBoxFragmentDoc}
`
export const PendingPurchaseItemDetailFragmentDoc = gql`
  fragment PendingPurchaseItemDetail on PendingPurchaseItem {
    id
    cartTransactionOrderId
    createdAt
    expiredAt
    formattedExpiredAt @client
    purchaseNumber
    status
    voucherUrl
    totalPrice @client
    checkoutFees {
      totalFee
      shipping {
        ...FeeDetail
      }
      konbini {
        ...FeeDetail
      }
      collections {
        ...CollectionFeeDetail
      }
    }
    formattedTotalPrice @client
    items {
      ...PendingPurchaseItemSkuDetail
    }
    metadata {
      membership {
        planId
      }
    }
    artist {
      ...ArtistMembershipSubscriptionDetail
      displayName
      avatar {
        uri
        smallUri
      }
    }
    artistId
  }
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${PendingPurchaseItemSkuDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
`
export const PendingPurchaseItemsConnectionFragmentDoc = gql`
  fragment PendingPurchaseItemsConnection on PageResult {
    edges {
      node {
        ... on PendingPurchaseItem {
          ...PendingPurchaseItemDetail
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${PendingPurchaseItemDetailFragmentDoc}
`
export const PersonalInfoFragmentDoc = gql`
  fragment PersonalInfo on User {
    id
    gender
    birthday
    fullName
    email
    phoneNumber
    formattedPhoneNumber @client
    isPhoneNumberVerified
    formattedBirthday @client
    isMembershipPersonalInformationRequired
    ...UserAddress
    bank {
      bankName
      accountType
      accountHolderName
      branchCode
      branchName
      accountNumber
    }
  }
  ${UserAddressFragmentDoc}
`
export const ArtistPostVideoFragmentDoc = gql`
  fragment ArtistPostVideo on Post {
    id
    content
    createdAt
    status
    publishedAt
    formattedPublishedAt @client
    formattedRelativePublishedAt @client
    formattedCreatedAt @client
    planTitle @client
    likeCount
    replyCount
    resourceType
    isLike
    isPremium
    planId
    artistId
    isAccessible
    category {
      id
      name
    }
    resources {
      uri
    }
    resource {
      uri
      videoId
    }
    videoThumbnail {
      uri
      blurUri
    }
    artist {
      id
      displayName
      accountId
      formattedAccountId @client
      avatar {
        uri
        smallUri
      }
      patron {
        id
        isPersonalInformationRequired
        subscription {
          planId
          amount
          title
        }
      }
      subscription {
        plans {
          planId
          amount
          title
        }
        products {
          productId
          title
          plans {
            planId
            amount
          }
        }
      }
    }
  }
`
export const ArtistPostVideosFragmentDoc = gql`
  fragment ArtistPostVideos on PageResult {
    edges {
      node {
        ...ArtistPostVideo
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${ArtistPostVideoFragmentDoc}
`
export const PurchasablePrepaidPointDetailFragmentDoc = gql`
  fragment PurchasablePrepaidPointDetail on PurchasablePrepaidPoint {
    id
    points
    price
    description
    prepaidPointId
    metadata {
      id
      platform
    }
  }
`
export const PurchasablePrepaidPointConnectionFragmentDoc = gql`
  fragment PurchasablePrepaidPointConnection on PageResult {
    edges {
      node {
        ...PurchasablePrepaidPointDetail
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${PurchasablePrepaidPointDetailFragmentDoc}
`
export const PrepaidPointChargeResultFragmentDoc = gql`
  fragment PrepaidPointChargeResult on PrepaidPointTransaction {
    id
    prepaidPointBalance
    prepaidPointBalanceBefore
    transactionPrepaidPoint
    prepaidPointId
    expiredPrepaidPoint
    type
    createdAt
    formattedCreatedAt @client
  }
`
export const GachaTicketFragmentDoc = gql`
  fragment gachaTicket on GachaTicket {
    id
    artistId
    name
    thumbnails {
      reward {
        uri
      }
      standard {
        uri
      }
    }
    description
    createdAt
    updatedAt
    serialNumber
    specifiedGachas
    price
    isPurchasable
    artist {
      id
      standalone {
        link {
          common
        }
      }
    }
  }
`
export const GachaTicketOrderFragmentDoc = gql`
  fragment gachaTicketOrder on GachaTicketOrder {
    id
    gachaId
    orderType
    createdAt
    formattedCreatedAt @client
    count
    gachaTicketId
  }
`
export const GachaTicketSerialFragmentDoc = gql`
  fragment gachaTicketSerial on Serial {
    id
    authentication {
      ... on SerialAuthentication {
        authenticatedAt
        expiredAt
      }
    }
    createdAt
    updatedAt
    issueCount
  }
`
export const ItemSerialCountDetailFragmentDoc = gql`
  fragment ItemSerialCountDetail on ItemSerialCount {
    count
    itemSkuId
    item {
      id
      title
      rarity {
        id
        name
      }
      thumbnail {
        front {
          smallUri
          uri
        }
      }
      rarityIconImage {
        smallUri
        uri
      }
    }
    sku {
      id
      unitName
    }
  }
`
export const MultiItemSerialDetailFragmentDoc = gql`
  fragment MultiItemSerialDetail on MultiItemSerial {
    id
    items {
      ...ItemSerialCountDetail
    }
    expiredAt
    isExpired @client
    authenticatedAt
    canBeObtain
  }
  ${ItemSerialCountDetailFragmentDoc}
`
export const RecentViewedItemConnectionFragmentDoc = gql`
  fragment RecentViewedItemConnection on PageResult {
    edges {
      node {
        ... on RecentlyStoreViewedItem {
          id
          item {
            ...MagazineItem
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${MagazineItemFragmentDoc}
`
export const RecommendCollectionFragmentDoc = gql`
  fragment RecommendCollection on RecentlyViewedCollection {
    id
    collection {
      id
      title
      description
      artistId
      itemCount
      thumbnail {
        uri
      }
    }
  }
`
export const RecommendCollectionConnectionFragmentDoc = gql`
  fragment RecommendCollectionConnection on PageResult {
    edges {
      node {
        ... on RecentlyViewedCollection {
          ...RecommendCollection
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${RecommendCollectionFragmentDoc}
`
export const UserBankAccountFragmentDoc = gql`
  fragment UserBankAccount on User {
    id
    bank {
      accountHolderName
      accountNumber
      accountType
      bankName
      branchCode
      branchName
    }
  }
`
export const ItemDetailBoxFragmentDoc = gql`
  fragment ItemDetailBox on Item {
    id
    issuedArtist {
      id
    }
    preSale {
      id
      event {
        id
        title
        description
      }
    }
    type
    hideSalesNumber
    purchaseLimitByUser
    purchasedByPointNumber
    price
    formattedPrice @client
    resaleEnabled
    thumbnailType
    resaleOpenCount(skuId: $skuId)
    resaleRaffleAppliedCount(skuId: $skuId)
    images {
      uri
      smallUri
      mediumUri
      compressedUri
      caption
    }
    thumbnail {
      front {
        uri
        smallUri
        mediumUri
        compressedUri
      }
      back {
        uri
        mediumUri
      }
    }
    rarityIconImage {
      uri
      smallUri
    }
    description
    issuedNumber
    title
    rarity {
      id
      name
    }
    commentCount
    nft {
      chains {
        chainName
      }
    }
    metadata {
      ticket {
        expirationTerm
        note
        description
        assignedNumberType
        isThroughTicket
        throughTicket {
          name
        }
        resaleFee {
          bankTransferFeePerTicket
          bankTransferFeePerOrder
          feeRatePerTicket
          purchaseFee
        }
        resalePeriod {
          startedAt
          endedAt
          formattedStartedAt @client
          formattedEndedAt @client
        }
      }
      magazine {
        pageDirection
      }
      wallpaper {
        isPrintEnabled
        printCount
        printType
      }
    }
    purchasableUsers {
      ...PurchasableUserDetail
    }
    sku @client {
      id
      order
      pendingPurchaseNumber
      purchasedNumber
      stock
      unitName
      price
      formattedPrice @client
      thumbnail {
        uri
        smallUri
        mediumUri
        compressedUri
      }
    }
    skuSettings {
      keyName
      units {
        id
        order
        pendingPurchaseNumber
        purchasedNumber
        stock
        price
        formattedPrice @client
        thumbnail {
          uri
          smallUri
          mediumUri
          compressedUri
        }
        unitName
      }
    }
  }
  ${PurchasableUserDetailFragmentDoc}
`
export const ResaleItemBoxFragmentDoc = gql`
  fragment ResaleItemBox on ResaleItem {
    id
    status
    itemId
    itemCount
    listedAt
    totalItemPrice
    listedAt
    skuId
    formattedListedAt @client
    formattedTotalItemPrice @client
    item {
      ...ItemDetailBox
    }
  }
  ${ItemDetailBoxFragmentDoc}
`
export const ResaleConnectionFragmentDoc = gql`
  fragment ResaleConnection on PageResult {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...ResaleItemBox
      }
    }
  }
  ${ResaleItemBoxFragmentDoc}
`
export const ResaleRaffleBoxFragmentDoc = gql`
  fragment ResaleRaffleBox on ItemResaleRaffleUser {
    id
    status
    itemCount
    createdAt
    totalItemPrice
    skuId
    formattedCreatedAt @client
    formattedTotalItemPrice @client
    itemId
    item {
      ...ItemDetailBox
    }
  }
  ${ItemDetailBoxFragmentDoc}
`
export const ResaleRaffleConnectionFragmentDoc = gql`
  fragment ResaleRaffleConnection on PageResult {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...ResaleRaffleBox
      }
    }
  }
  ${ResaleRaffleBoxFragmentDoc}
`
export const ItemResaleRaffleUserDetailFragmentDoc = gql`
  fragment ItemResaleRaffleUserDetail on ItemResaleRaffleUser {
    id
    item {
      ...ItemDetailBox
    }
    skuId
    itemCount
    totalItemPrice
    createdAt
    formattedCreatedAt @client
    formattedUpdatedAt @client
    updatedAt
    status
    resaleItems {
      id
      paymentExpiredAt
      formattedPaymentExpiredAt @client
    }
  }
  ${ItemDetailBoxFragmentDoc}
`
export const UserTradableUserItemsConnectionFragmentDoc = gql`
  fragment UserTradableUserItemsConnection on PageResult {
    edges {
      node {
        ... on UserItem {
          id
          number
          item {
            id
            title
            type
            hideSalesNumber
            sku @client {
              id
              order
              pendingPurchaseNumber
              purchasedNumber
              stock
              unitName
              thumbnail {
                uri
                mediumUri
                compressedUri
                smallUri
              }
            }
            skuSettings {
              keyName
              units {
                id
                order
                pendingPurchaseNumber
                purchasedNumber
                stock
                thumbnail {
                  uri
                  mediumUri
                  compressedUri
                  smallUri
                }
                unitName
              }
            }
            rarityIconImage {
              uri
              smallUri
            }
            thumbnail {
              front {
                uri
                smallUri
                mediumUri
                compressedUri
              }
            }
          }
          user {
            id
            displayName
            avatar {
              uri
              smallUri
              mediumUri
              compressedUri
            }
          }
          isSystemProtected
          isUserProtected
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
`
export const UserInfoFragmentDoc = gql`
  fragment UserInfo on User {
    id
    avatar {
      uri
      smallUri
    }
    displayName
  }
`
export const UserItemBoxFragmentDoc = gql`
  fragment UserItemBox on UserItem {
    id
    number
    type
    createdAt
    isSystemProtected
    isUserProtected
    item {
      id
      title
      ownedCount
      thumbnail {
        front {
          uri
          mediumUri
        }
      }
    }
    serials {
      isProtected
      formattedSerialNumber
      serialId
      isUsed
      obtainedDate
      formattedObtainedDate @client
    }
    user {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`
export const TradeItemDetailSelectItemDetailBoxFragmentDoc = gql`
  fragment TradeItemDetailSelectItemDetailBox on Item {
    id
    title
    type
    description
    price
    ownedCount
    images {
      caption
      mediumUri
      uri
    }
    sku @client {
      id
      order
      unitName
      thumbnail {
        uri
        mediumUri
      }
    }
    skuSettings {
      units {
        id
        order
        unitName
        thumbnail {
          uri
          mediumUri
        }
      }
    }
    purchasableUsers {
      ...PurchasableUserDetail
    }
    resaleOpenCount(skuId: $skuId)
    resaleRaffleAppliedCount(skuId: $skuId)
    issuedArtist {
      id
    }
    rarity {
      id
      name
    }
    thumbnail {
      front {
        uri
        mediumUri
      }
    }
    nft {
      chains {
        chainName
      }
    }
    issuedNumber
    commentCount
    metadata {
      ticket {
        description
        isThroughTicket
      }
    }
  }
  ${PurchasableUserDetailFragmentDoc}
`
export const SignUpUserFragmentDoc = gql`
  fragment SignUpUser on User {
    id
    displayName
    avatar {
      uri
    }
    introduction
  }
`
export const VerifyCodeFragmentDoc = gql`
  fragment VerifyCode on EmailVerification {
    id
    verified
  }
`
export const UserCartFragmentDoc = gql`
  fragment UserCart on UserCart {
    id
    paymentType
    totalCount
    totalPrice @client
    formattedTotalPrice @client
    artist {
      id
      displayName
      introduction
      avatar {
        uri
        smallUri
      }
    }
    items {
      count
      itemId
      totalPrice @client
      formattedTotalPrice @client
      item {
        id
        title
        price
        hideSalesNumber
        personalInformationRequirements
        formattedPrice @client
        paymentType
        type
        collection {
          id
          title
        }
        issuedArtist {
          id
          displayName
        }
        sku @client {
          id
          unitName
          thumbnail {
            uri
            smallUri
          }
        }
        thumbnail {
          front {
            uri
            smallUri
          }
        }
      }
      skus {
        skuId
        count
        sku {
          id
          unitName
          thumbnail {
            uri
            smallUri
          }
        }
      }
    }
  }
`
export const StoreCardDetailFragmentDoc = gql`
  fragment StoreCardDetail on UserCart {
    id
    artist {
      id
      displayName
      introduction
      avatar {
        uri
        smallUri
      }
    }
    totalCount
    totalPrice @client
    formattedTotalPrice @client
    items {
      count
      totalPrice @client
      formattedTotalPrice @client
      item {
        id
        title
        price
        formattedPrice @client
        paymentType
        type
        personalInformationRequirements
        hideSalesNumber
        collection {
          id
          title
        }
        issuedArtist {
          id
        }
        sku @client {
          id
          unitName
          price
          formattedPrice @client
          thumbnail {
            uri
          }
        }
        thumbnail {
          front {
            uri
          }
        }
      }
      skus {
        skuId
        count
        sku {
          id
          price
          formattedPrice @client
          unitName
          thumbnail {
            uri
          }
        }
      }
    }
  }
`
export const UserCartsConnectionFragmentDoc = gql`
  fragment UserCartsConnection on PageResult {
    edges {
      node {
        ... on UserCart {
          id
          totalCount
          totalPrice @client
          formattedTotalPrice @client
          artist {
            id
            displayName
            avatar {
              uri
              smallUri
            }
          }
          items {
            count
            item {
              id
              price
              hideSalesNumber
            }
            skus {
              count
              sku {
                id
                price
              }
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`
export const StoreCollectionItemsConnectionFragmentDoc = gql`
  fragment StoreCollectionItemsConnection on PageResult {
    edges {
      node {
        ... on CollectionItem {
          id
          item {
            id
            title
            price
            formattedPrice @client
            remainingNumber @client
            formattedRemainingNumber @client
            paymentType
            type
            salesStatus
            isPublished
            usedNumber
            purchasedNumber
            issuedNumber
            pendingPurchaseNumber
            hideSalesNumber
            isResaleAvailable
            isPremium
            rarityIconImage {
              uri
              smallUri
            }
            rarity {
              id
              name
            }
            thumbnail {
              front {
                uri
                mediumUri
              }
              back {
                uri
                mediumUri
              }
            }
            skuSettings {
              keyName
              units {
                id
                unitName
                price
                order
              }
            }
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`
export const UserCartTransactionOrderFragmentDoc = gql`
  fragment UserCartTransactionOrder on UserCartTransactionOrder {
    id
    createdAt
    isKonbini
    itemOrderIds
    checkoutFees {
      totalFee
      shipping {
        ...FeeDetail
      }
      konbini {
        ...FeeDetail
      }
      collections {
        ...CollectionFeeDetail
      }
    }
    items {
      itemId
      amount
      count
      item {
        ...ItemBox
        collection {
          id
          title
        }
      }
      skus {
        skuId
        count
        sku {
          id
          unitName
          order
          pendingPurchaseNumber
          purchasedNumber
          stock
          thumbnail {
            uri
            smallUri
            mediumUri
          }
        }
      }
    }
    paymentType
    totalAmount
    formattedTotalAmount @client
    originalTotalAmount @client
    formattedOriginalTotalAmount @client
    pendingPurchaseItem {
      ...PendingPurchaseItemDetail
    }
  }
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${ItemBoxFragmentDoc}
  ${PendingPurchaseItemDetailFragmentDoc}
`
export const StoreItemCompleteDetailFragmentDoc = gql`
  fragment StoreItemCompleteDetail on Item {
    id
    title
    rarityIconImage {
      uri
      smallUri
    }
    rarity {
      id
      name
    }
    hideSalesNumber
    issuedNumber
    commentCount
    price
    thumbnail {
      front {
        uri
        mediumUri
      }
    }
    sku @client {
      id
      unitName
      price
      thumbnail {
        uri
        mediumUri
      }
    }
    skuSettings {
      keyName
      units {
        id
        unitName
        price
        thumbnail {
          uri
          mediumUri
        }
      }
    }
  }
`
export const UserCartStoreItemConfirmationEventCollectionFragmentDoc = gql`
  fragment UserCartStoreItemConfirmationEventCollection on EventCollection {
    id
    eventId
    validMinutes
    collectionId
    purchaseLimitPerPickup
    myPickupUsers {
      id
      userId
      pickupTime
      formattedPickupStartEndTime @client
      cartTransactionOrders {
        id
        totalAmount
        paymentType
        items {
          itemId
          count
        }
      }
    }
    pickupTime {
      options {
        availableUserNumber
        currentUserNumber
        time
      }
    }
  }
`
export const UserCartStoreItemPurchaseConfirmationFragmentDoc = gql`
  fragment UserCartStoreItemPurchaseConfirmation on UserCart {
    id
    paymentType
    totalCount
    totalPrice @client
    formattedTotalPrice @client
    artist {
      id
    }
    items {
      count
      itemId
      totalPrice @client
      formattedTotalPrice @client
      item {
        id
        title
        price
        formattedPrice @client
        paymentType
        type
        hideSalesNumber
        purchaseLimitByUser
        purchaseLimitPerOrder
        ownedCount
        personalInformationRequirements
        collection {
          id
          title
          event {
            id
            title
            note
            eventCollections(pageInput: { limit: 30 }) {
              edges {
                node {
                  ...UserCartStoreItemConfirmationEventCollection
                }
              }
            }
          }
        }
        issuedArtist {
          id
          displayName
        }
        sku @client {
          id
          unitName
          price
          formattedPrice @client
          thumbnail {
            uri
            smallUri
          }
        }
        thumbnail {
          front {
            uri
            smallUri
          }
        }
      }
      skus {
        skuId
        count
        sku {
          id
          unitName
          price
          formattedPrice @client
          thumbnail {
            uri
            smallUri
          }
        }
      }
    }
  }
  ${UserCartStoreItemConfirmationEventCollectionFragmentDoc}
`
export const UserCartStoreItemProceedEventCollectionFragmentDoc = gql`
  fragment UserCartStoreItemProceedEventCollection on EventCollection {
    id
    eventId
    validMinutes
    collectionId
    purchaseLimitPerPickup
    myPickupUsers {
      id
      userId
      pickupTime
      formattedPickupStartEndTime @client
      cartTransactionOrders {
        id
        totalAmount
        paymentType
        items {
          itemId
          count
        }
      }
    }
    pickupTime {
      options {
        availableUserNumber
        currentUserNumber
        time
      }
    }
  }
`
export const UserCartStoreItemPurchaseProceedFragmentDoc = gql`
  fragment UserCartStoreItemPurchaseProceed on UserCart {
    id
    paymentType
    totalCount
    totalPrice @client
    formattedTotalPrice @client
    items {
      count
      itemId
      totalPrice @client
      formattedTotalPrice @client
      item {
        id
        title
        price
        formattedPrice @client
        paymentType
        hideSalesNumber
        type
        purchaseLimitByUser
        purchaseLimitPerOrder
        ownedCount
        personalInformationRequirements
        collection {
          id
          title
          event {
            id
            title
            note
            eventCollections(pageInput: { limit: 30 }) {
              edges {
                node {
                  ...UserCartStoreItemProceedEventCollection
                }
              }
            }
          }
        }
        issuedArtist {
          id
        }
        sku @client {
          id
          unitName
          price
          formattedPrice @client
          thumbnail {
            uri
            smallUri
          }
        }
        thumbnail {
          front {
            uri
            smallUri
          }
        }
      }
      skus {
        skuId
        count
        sku {
          id
          unitName
          price
          formattedPrice @client
          thumbnail {
            uri
            smallUri
          }
        }
      }
    }
  }
  ${UserCartStoreItemProceedEventCollectionFragmentDoc}
`
export const StoreOrderListHistoryFragmentDoc = gql`
  fragment StoreOrderListHistory on UserCartTransactionOrder {
    id
    paymentType
    createdAt
    formattedCreatedAt @client
    totalAmount
    formattedTotalAmount @client
    items {
      count
      item {
        title
      }
      skus {
        count
        skuId
        sku {
          id
          unitName
          price
        }
      }
    }
    cancels {
      canceledAt
    }
  }
`
export const StorePurchaseOrderConnectionFragmentDoc = gql`
  fragment StorePurchaseOrderConnection on PageResult {
    edges {
      node {
        ...StoreOrderListHistory
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${StoreOrderListHistoryFragmentDoc}
`
export const StoreUserInfoFragmentDoc = gql`
  fragment StoreUserInfo on User {
    fullName
    phoneNumber
  }
`
export const GachaTicketOrderBoxFragmentDoc = gql`
  fragment GachaTicketOrderBox on GachaTicketOrder {
    id
    orderType
    title @client
    count
    createdAt
    formattedCreatedAt @client
  }
`
export const GachaTicketOrdersConnectionFragmentDoc = gql`
  fragment GachaTicketOrdersConnection on PageResult {
    edges {
      node {
        ...GachaTicketOrderBox
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${GachaTicketOrderBoxFragmentDoc}
`
export const TicketShareConfirmFragmentDoc = gql`
  fragment TicketShareConfirm on TicketDistributionLink {
    id
    expiredAt
    isExpired @client
    formattedExpiredAt @client
    userId
    item {
      id
      preSale {
        title
        id
      }
      title
      issuedArtist {
        id
      }
      ownedCount
      metadata {
        ticket {
          assignedNumberType
          distributionEnabled
          distributionEndedAt
          isTicketDistributionEnabled @client
        }
      }
      isTicketDistributionEnabled
    }
    serial {
      serialNumber
      sku {
        unitName
        id
      }
      ticketStatus {
        throughTicket {
          seat
          name
        }
      }
      formattedSerialNumber
      formattedAssignedNumber
    }
  }
`
export const TicketShareCopyUserItemSerialFragmentDoc = gql`
  fragment TicketShareCopyUserItemSerial on UserItemSerial {
    id
    serial {
      id
      ticketDistributionLink {
        id
        distributionId
        expiredAt
        formattedExpiredAt @client
        token
        user {
          id
          displayName
          avatar {
            uri
            smallUri
          }
        }
      }
      formattedSerialNumber
      formattedAssignedNumber
      ticketStatus {
        throughTicket {
          seat
        }
      }
    }
  }
`
export const TicketShareSelectUserItemSerialFragmentDoc = gql`
  fragment TicketShareSelectUserItemSerial on UserItemSerial {
    id
    serial {
      id
      ticketStatus {
        throughTicket {
          seat
        }
      }
      ticketDistributionLink {
        id
        expiredAt
        isExpired @client
        token
      }
      formattedSerialNumber
      formattedAssignedNumber
    }
  }
`
export const UserItemDetailBoxFragmentDoc = gql`
  fragment UserItemDetailBox on UserItem {
    id
    isUsed
    number
    type
    createdAt
    item {
      ...ItemDetailBox
    }
    isSystemProtected
    isUserProtected
    serials {
      id
      isUsed
      isProtected
      formattedSerialNumber
      serialId
      serial {
        id
        ticketStatus {
          ...TicketStatusDetail
        }
        formattedAssignedNumber
        sku {
          id
        }
      }
    }
  }
  ${ItemDetailBoxFragmentDoc}
  ${TicketStatusDetailFragmentDoc}
`
export const UserOwnedItemDetailBoxFragmentDoc = gql`
  fragment UserOwnedItemDetailBox on Item {
    id
    type
    title
    thumbnail {
      front {
        uri
        mediumUri
      }
    }
    rarityIconImage {
      uri
      smallUri
    }
    rarity {
      id
    }
    nft {
      chains {
        chainName
      }
    }
    metadata {
      wallpaper {
        isPrintEnabled
      }
    }
    sku @client {
      id
      unitName
      thumbnail {
        uri
        mediumUri
      }
    }
    skuSettings {
      units {
        id
        unitName
        thumbnail {
          uri
          mediumUri
        }
      }
    }
  }
`
export const UserOwnedItemDetailFragmentDoc = gql`
  fragment UserOwnedItemDetail on UserItem {
    id
    number
    serials {
      id
      serialId
      serial {
        id
        sku {
          id
        }
      }
    }
    item {
      ...UserOwnedItemDetailBox
    }
  }
  ${UserOwnedItemDetailBoxFragmentDoc}
`
export const UserOwnedItemsConnectionFragmentDoc = gql`
  fragment UserOwnedItemsConnection on PageResult {
    edges {
      node {
        ... on UserItem {
          ...UserOwnedItemDetail
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
  ${UserOwnedItemDetailFragmentDoc}
`
export const UserCommonWithCustomProfileConnectionFragmentDoc = gql`
  fragment UserCommonWithCustomProfileConnection on User {
    id
    accountId
    formattedAccountId @client
    avatar {
      uri
      smallUri
      mediumUri
    }
    displayName
    introduction
    customProfile {
      avatarItem {
        id
        avatarResources {
          avatarResource {
            uri
            smallUri
            compressedUri
            mediumUri
          }
        }
      }
      wallpaperItem {
        id
        wallpaperResources {
          wallpaperResource {
            uri
            mediumUri
            compressedUri
            smallUri
          }
        }
      }
    }
  }
`
export const PortfoliosConnectionFragmentDoc = gql`
  fragment PortfoliosConnection on PageResult {
    edges {
      node {
        ... on UserItem {
          id
          item {
            id
            title
            hideSalesNumber
            thumbnail {
              front {
                uri
                smallUri
                mediumUri
                compressedUri
              }
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
`
export const UserCollectionsConnectionFragmentDoc = gql`
  fragment UserCollectionsConnection on PageResult {
    edges {
      node {
        ...CollectionBox
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ${CollectionBoxFragmentDoc}
`
export const UserDetailCache = gql`
  query UserDetailCache {
    user {
      id
    }
  }
`
export const ArtistPlanMonth = gql`
  query artistPlanMonth($artistId: ID!) {
    artist(id: $artistId) {
      id
      patron {
        id
        formattedCreatedAt
        subscription {
          planId
        }
      }
    }
  }
`
export const ArtistPatronPlan = gql`
  query artistPatronPlan($artistId: ID!) {
    artist(id: $artistId) {
      id
      subscription {
        products {
          productId
          plans {
            planId
            amount
          }
        }
      }
      pendingPurchasePatron {
        id
        artistId
        status
        metadata {
          membership {
            planId
          }
        }
      }
      patron {
        id
        downgradeAtNextPeriod
        cancelAtPeriodEnd
        paymentMethod
        subscription {
          planId
          amount
          downgradePlanId
        }
      }
    }
  }
`
export const LoginBonusModal = gql`
  query LoginBonusModal {
    loginBonus {
      ...LoginBonusModal
    }
    continuousLoginBonus {
      ...ConsecutiveLoginBonusModal
    }
  }
  ${LoginBonusModalFragmentDoc}
  ${BonusElementFragmentDoc}
  ${ConsecutiveLoginBonusModalFragmentDoc}
  ${LoginBonusBoxFragmentDoc}
`
export const ObtainLoginBonus = gql`
  mutation obtainLoginBonus($inputs: ObtainLoginBonusInput!) {
    obtainLoginBonus(inputs: $inputs) {
      id
      bonuses {
        type
        bonusKey
        metadata {
          count
          point
          gachaTicket {
            id
          }
        }
      }
      loginBonusUser {
        id
        obtainedBonuses {
          type
          bonusKey
          metadata {
            count
            point
            gachaTicket {
              id
            }
          }
        }
      }
      startDate
      endDate
      createdAt
      todayTargetDate
    }
  }
`
export const NeedSettingPersonalUserSnackbar = gql`
  query NeedSettingPersonalUserSnackbar($id: ID, $skipArtist: Boolean!) {
    user {
      id
      isMembershipPersonalInformationRequired
    }
    artist(id: $id) @skip(if: $skipArtist) {
      id
      patron {
        id
        isPersonalInformationRequired
      }
    }
  }
`
export const AccountSettings = gql`
  query AccountSettings {
    user {
      id
      isMembershipPersonalInformationRequired
    }
  }
`
export const ArtistCollections = gql`
  query ArtistCollections(
    $userCollectionsPageInput: PageInputDate
    $userCollectionsArtistId: ID
  ) {
    artist(id: $userCollectionsArtistId) {
      ...ArtistBottomTab
      id
      displayName
      currentLive {
        id
      }
    }
    user {
      ...CurrentUser
    }
    userCollections(
      pageInput: $userCollectionsPageInput
      artistId: $userCollectionsArtistId
    ) {
      edges {
        node {
          ...CollectionBox
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
  ${CollectionBoxFragmentDoc}
`
export const FetchNewArtistCollections = gql`
  query FetchNewArtistCollections($pageInput: PageInputDate, $artistId: ID) {
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      displayName
      currentLive {
        id
      }
    }
    userCollections(pageInput: $pageInput, artistId: $artistId) {
      edges {
        node {
          ...CollectionBox
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${CollectionBoxFragmentDoc}
`
export const MonthlyEventsByArtist = gql`
  query MonthlyEventsByArtist($id: ID, $artistId: String!, $month: String!) {
    artist(id: $id) {
      ...ArtistBottomTab
      id
      currentLive {
        id
      }
    }
    monthlyEventsByArtist(month: $month, artistId: $artistId) {
      ...MonthlyEventsByArtist
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${MonthlyEventsByArtistFragmentDoc}
`
export const ArtistSearch = gql`
  query ArtistSearch {
    user {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const ArtistGachas = gql`
  query ArtistGachas($gachasArtistId: ID, $gachasPageInput: PageInputDate) {
    user {
      ...CurrentUser
    }
    artist(id: $gachasArtistId) {
      ...ArtistBottomTab
      id
      displayName
      currentLive {
        id
      }
    }
    gachas(artistId: $gachasArtistId, pageInput: $gachasPageInput) {
      ...GachasConnection
    }
  }
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${GachasConnectionFragmentDoc}
  ${GachaBoxFragmentDoc}
`
export const FetchNewArtistGachas = gql`
  query FetchNewArtistGachas(
    $gachasArtistId: ID
    $gachasPageInput: PageInputDate
  ) {
    artist(id: $gachasArtistId) {
      ...ArtistBottomTab
      id
      displayName
      currentLive {
        id
      }
    }
    gachas(artistId: $gachasArtistId, pageInput: $gachasPageInput) {
      ...GachasConnection
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${GachasConnectionFragmentDoc}
  ${GachaBoxFragmentDoc}
`
export const GalleryPosts = gql`
  query GalleryPosts(
    $artistId: ID!
    $pageInput: PageInputDate
    $resourceTypes: [PostType!]
    $categoryId: String
  ) {
    artist(id: $artistId) {
      id
      displayName
      postCategory {
        id
        categories {
          id
          name
          description
          createdAt
          updatedAt
        }
      }
      posts(
        pageInput: $pageInput
        resourceTypes: $resourceTypes
        categoryId: $categoryId
      ) {
        ...GalleryPosts
      }
      currentLive {
        id
      }
      totalUnreadMentionCount
    }
    user {
      ...CurrentUser
    }
  }
  ${GalleryPostsFragmentDoc}
  ${GalleryPostFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const GalleryCategory = gql`
  query GalleryCategory($artistId: ID!) {
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      displayName
      postCategory {
        id
        categories {
          id
          name
          description
          createdAt
          updatedAt
        }
      }
      currentLive {
        id
      }
    }
    user {
      ...CurrentUser
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const ArtistMembership = gql`
  query ArtistMembership($id: ID) {
    artist(id: $id) {
      ...ArtistWithSubscription
      ...ArtistMembershipSubscriptionDetail
    }
    user {
      id
      isMembershipPersonalInformationRequired
    }
    paymentSource {
      ...PaymentDetail
    }
  }
  ${ArtistWithSubscriptionFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const PreviewSubscriptionUpdate = gql`
  query PreviewSubscriptionUpdate($planId: String!, $artistId: String!) {
    previewSubscriptionUpdate(planId: $planId, artistId: $artistId) {
      planId
      diffAmount
    }
  }
`
export const ArtistMembershipForGuest = gql`
  query ArtistMembershipForGuest($id: ID) {
    artist(id: $id) {
      ...ArtistWithSubscription
      ...ArtistMembershipSubscriptionDetail
    }
  }
  ${ArtistWithSubscriptionFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const StartSubscription = gql`
  mutation startSubscription($artistId: ID!, $planId: ID!) {
    startSubscription(artistId: $artistId, planId: $planId) {
      ...PatronDetail
    }
  }
  ${PatronDetailFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const UpgradeSubscriptionPlan = gql`
  mutation upgradeSubscriptionPlan(
    $planId: String!
    $paymentMethod: PaymentMethod
    $artistId: String!
  ) {
    upgradeSubscriptionPlan(
      planId: $planId
      paymentMethod: $paymentMethod
      artistId: $artistId
    ) {
      ...PatronDetail
    }
  }
  ${PatronDetailFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const DowngradeSubscriptionPlan = gql`
  mutation downgradeSubscriptionPlan(
    $planId: String!
    $paymentMethod: PaymentMethod
    $artistId: String!
  ) {
    downgradeSubscriptionPlan(
      planId: $planId
      paymentMethod: $paymentMethod
      artistId: $artistId
    ) {
      ...PatronDetail
    }
  }
  ${PatronDetailFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const CancelDowngradeSubscriptionPlan = gql`
  mutation cancelDowngradeSubscriptionPlan($artistId: String!) {
    cancelDowngradeSubscriptionPlan(artistId: $artistId) {
      ...PatronDetail
    }
  }
  ${PatronDetailFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const TryPaymentLock = gql`
  mutation tryPaymentLock($uuid: String!) {
    tryPaymentLock(uuid: $uuid) {
      success
    }
  }
`
export const CreateIapSubscription = gql`
  mutation createIapSubscription(
    $productId: String!
    $uuid: String
    $app: String
    $artistId: String
  ) {
    createIapSubscription(
      productId: $productId
      uuid: $uuid
      app: $app
      artistId: $artistId
    ) {
      ...PatronDetail
    }
  }
  ${PatronDetailFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const ArtistMembershipCompletion = gql`
  query ArtistMembershipCompletion($pendingPurchaseId: ID!) {
    pendingPurchaseItem(pendingPurchaseId: $pendingPurchaseId) {
      id
      expiredAt
      formattedExpiredAt @client
      voucherUrl
    }
  }
`
export const ArtistMembershipPaymentEdit = gql`
  query ArtistMembershipPaymentEdit($id: ID) {
    artist(id: $id) {
      id
      ...ArtistMembershipSubscriptionDetail
      patron {
        id
        amount
        paymentMethod
        subscription {
          ...SubscriptionDetail
        }
      }
    }
    user {
      id
      credit {
        ...PaymentDetail
      }
    }
  }
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const ArtistMembershipPurchaseConfirmation = gql`
  query ArtistMembershipPurchaseConfirmation($id: ID) {
    artist(id: $id) {
      id
      ...ArtistMembershipSubscriptionDetail
    }
    user {
      id
      credit {
        ...PaymentDetail
      }
    }
  }
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const StartPendinggSubscription = gql`
  mutation StartPendinggSubscription($artistId: ID!, $planId: String!) {
    startPendinggSubscription(artistId: $artistId, planId: $planId) {
      ... on PendingPurchaseItem {
        id
      }
      ... on Patron {
        id
        artist {
          id
          pendingPurchasePatron {
            id
          }
        }
      }
    }
  }
`
export const ArtistMembershipPurchaseProceed = gql`
  query ArtistMembershipPurchaseProceed($id: ID) {
    artist(id: $id) {
      id
      ...ArtistMembershipSubscriptionDetail
    }
    user {
      id
      credit {
        ...PaymentDetail
      }
    }
  }
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const ArtistMissions = gql`
  query ArtistMissions(
    $missionsTypeFilter: [MissionType!]
    $missionsPageInput: PageInput
    $missionsArtistId: ID!
    $stepMissionsType: StepMissionType
    $stepMissionsFilter: StepMissionFilter
    $stepMissionsPageInput: PageInput
  ) {
    user {
      ...CurrentUser
    }
    missionsWithFilteres(
      typeFilter: $missionsTypeFilter
      pageInput: $missionsPageInput
      artistId: $missionsArtistId
    ) {
      ...MissionsConnection
    }
    artist(id: $missionsArtistId) {
      ...ArtistBottomTab
      id
      displayName
      currentLive {
        id
      }
    }
    artistStepMissions(
      artistId: $missionsArtistId
      type: $stepMissionsType
      filter: $stepMissionsFilter
      pageInput: $stepMissionsPageInput
    ) {
      ...UserStepMissionsConnection
    }
  }
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
  ${MissionsConnectionFragmentDoc}
  ${MissionBoxFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${UserStepMissionsConnectionFragmentDoc}
  ${UserStepMissionBoxFragmentDoc}
  ${GachaTicketConnectionFragmentDoc}
  ${GachaTicketThumbnailsBoxFragmentDoc}
  ${ItemBoxFragmentDoc}
`
export const ArtistMissionsForGuest = gql`
  query ArtistMissionsForGuest(
    $missionsTypeFilter: [MissionType!]
    $missionsPageInput: PageInput
    $missionsArtistId: ID!
  ) {
    missionsWithFilteres(
      typeFilter: $missionsTypeFilter
      pageInput: $missionsPageInput
      artistId: $missionsArtistId
    ) {
      ...MissionsConnection
    }
    artist(id: $missionsArtistId) {
      ...ArtistBottomTab
      id
      displayName
      currentLive {
        id
      }
    }
  }
  ${MissionsConnectionFragmentDoc}
  ${MissionBoxFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
`
export const FetchNewArtistMissions = gql`
  query FetchNewArtistMissions(
    $missionsTypeFilter: [MissionType!]
    $missionsPageInput: PageInput
    $missionsArtistId: ID!
  ) {
    artist(id: $missionsArtistId) {
      ...ArtistBottomTab
      id
      displayName
      currentLive {
        id
      }
    }
    missionsWithFilteres(
      typeFilter: $missionsTypeFilter
      pageInput: $missionsPageInput
      artistId: $missionsArtistId
    ) {
      ...MissionsConnection
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${MissionsConnectionFragmentDoc}
  ${MissionBoxFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
`
export const GetStepMissionReward = gql`
  mutation GetStepMissionReward($stepMissionId: String!) {
    getStepMissionReward(stepMissionId: $stepMissionId) {
      ...UserStepMissionBox
    }
  }
  ${UserStepMissionBoxFragmentDoc}
  ${GachaTicketConnectionFragmentDoc}
  ${GachaTicketThumbnailsBoxFragmentDoc}
  ${ItemBoxFragmentDoc}
`
export const ArtistOfficialInformation = gql`
  query ArtistOfficialInformation(
    $id: ID!
    $artistId: String!
    $pageInput: PageInput
  ) {
    organizationOfficialArtistNotifications(
      artistId: $artistId
      pageInput: $pageInput
    ) {
      ...OrganizationNotificationConnection
    }
    artist(id: $id) {
      displayName
      avatar {
        uri
        smallUri
      }
    }
  }
  ${OrganizationNotificationConnectionFragmentDoc}
  ${OrganizationNotificationBoxFragmentDoc}
`
export const FetchNewArtistOfficialInformation = gql`
  query FetchNewArtistOfficialInformation(
    $id: ID!
    $artistId: String!
    $pageInput: PageInput
  ) {
    organizationOfficialArtistNotifications(
      artistId: $artistId
      pageInput: $pageInput
    ) {
      ...OrganizationNotificationConnection
    }
    artist(id: $id) {
      displayName
      avatar {
        uri
        smallUri
      }
    }
  }
  ${OrganizationNotificationConnectionFragmentDoc}
  ${OrganizationNotificationBoxFragmentDoc}
`
export const ArtistPostVideos = gql`
  query ArtistPostVideos(
    $artistId: ID!
    $pageInput: PageInputDate
    $resourceType: PostType
    $categoryId: String
  ) {
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      displayName
      posts(
        pageInput: $pageInput
        resourceType: $resourceType
        categoryId: $categoryId
      ) {
        ...ArtistPosts
      }
      currentLive {
        id
      }
    }
    user {
      ...CurrentUser
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${ArtistPostsFragmentDoc}
  ${PostBoxFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const FetchNewArtistPostVideos = gql`
  query FetchNewArtistPostVideos(
    $artistId: ID!
    $pageInput: PageInputDate
    $resourceType: PostType
    $categoryId: String
  ) {
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      posts(
        pageInput: $pageInput
        resourceType: $resourceType
        categoryId: $categoryId
      ) {
        ...ArtistPosts
      }
      currentLive {
        id
      }
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${ArtistPostsFragmentDoc}
  ${PostBoxFragmentDoc}
`
export const ArtistPosts = gql`
  query artistPosts(
    $artistId: ID!
    $pageInput: PageInputDate
    $resourceType: PostType
  ) {
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      displayName
      posts(pageInput: $pageInput, resourceType: $resourceType) {
        ...ArtistPosts
      }
      currentLive {
        id
      }
    }
    user {
      ...CurrentUser
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${ArtistPostsFragmentDoc}
  ${PostBoxFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const FetchNewArtistPosts = gql`
  query FetchNewArtistPosts(
    $artistId: ID!
    $pageInput: PageInputDate
    $resourceType: PostType
  ) {
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      posts(pageInput: $pageInput, resourceType: $resourceType) {
        ...ArtistPosts
      }
      currentLive {
        id
      }
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${ArtistPostsFragmentDoc}
  ${PostBoxFragmentDoc}
`
export const ArtistRanking = gql`
  query ArtistRanking(
    $artistId: String!
    $periodType: RankingPeriodType!
    $pageInput: PageInput
  ) {
    user {
      id
      displayName
      avatar {
        uri
        smallUri
      }
    }
    artistRankings(artistId: $artistId) {
      artist {
        id
        displayName
      }
      latestRankingPeriod(periodType: $periodType) {
        ...RankingPeriodBaseConnection
        myRanking {
          ...RankingUserWithoutIdConnection
        }
        rankings(pageInput: $pageInput) {
          ...RankingsConnection
        }
      }
    }
  }
  ${RankingPeriodBaseConnectionFragmentDoc}
  ${RankingUserWithoutIdConnectionFragmentDoc}
  ${RankingsConnectionFragmentDoc}
  ${RankingUserConnectionFragmentDoc}
`
export const ArtistStore = gql`
  query ArtistStore($artistId: ID!, $storeArtistId: String!) {
    prepaidPointBalance {
      prepaidPointId
      balance
      formattedPrepaidPointBarance @client
    }
    user {
      ...CurrentUser
    }
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      currentLive {
        id
      }
    }
    storeCollections(artistId: $storeArtistId, pageInput: { limit: 6 }) {
      edges {
        node {
          ... on Collection {
            ...ArtistStoreCollectionBox
          }
        }
      }
    }
    recentlyViewedCollections(artistId: $storeArtistId) {
      ...RecommendCollectionConnection
    }
    recentlyStoreViewedItems(artistId: $storeArtistId) {
      ...RecentViewedItemConnection
    }
  }
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${ArtistStoreCollectionBoxFragmentDoc}
  ${RecommendCollectionConnectionFragmentDoc}
  ${RecommendCollectionFragmentDoc}
  ${RecentViewedItemConnectionFragmentDoc}
  ${MagazineItemFragmentDoc}
`
export const ArtistStoreForGuest = gql`
  query ArtistStoreForGuest($artistId: ID!, $storeArtistId: String!) {
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      currentLive {
        id
      }
    }
    storeCollections(artistId: $storeArtistId, pageInput: { limit: 6 }) {
      edges {
        node {
          ... on Collection {
            ...ArtistStoreCollectionBox
          }
        }
      }
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${ArtistStoreCollectionBoxFragmentDoc}
`
export const ArtistStoreItemDetail = gql`
  query ArtistStoreItemDetail(
    $id: ID!
    $itemId: String!
    $platform: String!
    $purchasablePrepaidPointPageInput: PageInput
    $skuId: String
  ) {
    user {
      id
      phoneNumber
      fullName
      isPhoneNumberVerified
      formattedPhoneNumber @client
      ...UserDeliveryInfo
      cryptoWallet {
        address
      }
    }
    userCartCount
    item(id: $id) {
      ...StoreItemDetailGuest
    }
    userActiveResaleItemRaffle(itemId: $itemId) {
      id
      itemId
      skuId
    }
    prepaidPointBalance {
      balance
      prepaidPointId
      formattedPrepaidPointBarance @client
    }
    purchasablePrepaidPoints(
      platform: $platform
      pageInput: $purchasablePrepaidPointPageInput
    ) {
      ...PurchasablePrepaidPointConnection
    }
    paymentSource {
      ...paymentCreateDetail
    }
  }
  ${UserDeliveryInfoFragmentDoc}
  ${StoreItemDetailGuestFragmentDoc}
  ${SkuTypeFragmentDoc}
  ${SkuTagFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${PurchasablePrepaidPointConnectionFragmentDoc}
  ${PurchasablePrepaidPointDetailFragmentDoc}
  ${PaymentCreateDetailFragmentDoc}
`
export const ArtistStoreItemDetailWithSku = gql`
  query ArtistStoreItemDetailWithSku(
    $id: ID!
    $itemId: String!
    $skuId: String
  ) {
    item(id: $id) {
      ...StoreItemDetailGuestSku
    }
    userActiveResaleItemRaffle(itemId: $itemId, skuId: $skuId) {
      id
      itemId
    }
  }
  ${StoreItemDetailGuestSkuFragmentDoc}
  ${SkuTypeFragmentDoc}
  ${SkuTagFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const ArtistStoreItemDetailForGuest = gql`
  query ArtistStoreItemDetailForGuest($id: ID!, $skuId: String) {
    item(id: $id) {
      ...StoreItemDetailGuest
    }
  }
  ${StoreItemDetailGuestFragmentDoc}
  ${SkuTypeFragmentDoc}
  ${SkuTagFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const AddItemToCart = gql`
  mutation AddItemToCart($inputs: AddToCartInput!) {
    addToCart(inputs: $inputs) {
      id
      totalCount
    }
  }
`
export const PurchaseItemByPoint = gql`
  mutation PurchaseItemByPoint(
    $itemId: ID!
    $purchaseNumber: Int
    $prepaidPoint: Int
    $skuId: String
  ) {
    purchaseItemByPoint(
      itemId: $itemId
      purchaseNumber: $purchaseNumber
      prepaidPoint: $prepaidPoint
      skuId: $skuId
    ) {
      id
      price
      userId
      item {
        ...StoreItemDetail
      }
    }
  }
  ${StoreItemDetailFragmentDoc}
  ${StoreItemDetailGuestFragmentDoc}
  ${SkuTypeFragmentDoc}
  ${SkuTagFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const ViewStoreItem = gql`
  mutation ViewStoreItem($inputs: ViewStoreItemInput!) {
    viewStoreItem(inputs: $inputs) {
      id
      userId
      itemId
      viewCount
    }
  }
`
export const PurchaseItemImmediately = gql`
  mutation PurchaseItemImmediately(
    $inputs: PurchaseItemImmediatelyInput!
    $skuId: String
  ) {
    purchaseItemImmediately(inputs: $inputs) {
      id
      paymentType
      items {
        item {
          ...StoreItemDetail
        }
      }
    }
  }
  ${StoreItemDetailFragmentDoc}
  ${StoreItemDetailGuestFragmentDoc}
  ${SkuTypeFragmentDoc}
  ${SkuTagFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const ArtistThreads = gql`
  query ArtistThreads(
    $inputs: ArtistThreadChannelsInput!
    $pageInput: PageInput
    $artistId: ID
  ) {
    artistThreadChannels(inputs: $inputs, pageInput: $pageInput) {
      ...ArtistThreadChannelConnection
    }
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      displayName
      accountId
      formattedAccountId @client
      patron {
        id
        isPersonalInformationRequired
      }
      currentLive {
        id
      }
    }
    user {
      ...CurrentUser
    }
  }
  ${ArtistThreadChannelConnectionFragmentDoc}
  ${ThreadBoxFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const FetchNewArtistThreads = gql`
  query FetchNewArtistThreads(
    $inputs: ArtistThreadChannelsInput!
    $pageInput: PageInput
    $artistId: ID
  ) {
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      displayName
      accountId
      formattedAccountId @client
      patron {
        id
        isPersonalInformationRequired
      }
      currentLive {
        id
      }
    }
    artistThreadChannels(inputs: $inputs, pageInput: $pageInput) {
      ...ArtistThreadChannelConnection
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${ArtistThreadChannelConnectionFragmentDoc}
  ${ThreadBoxFragmentDoc}
`
export const ArtistThreadsForGuest = gql`
  query ArtistThreadsForGuest(
    $inputs: ArtistThreadChannelsInput!
    $pageInput: PageInput
    $artistId: ID
  ) {
    artistThreadChannels(inputs: $inputs, pageInput: $pageInput) {
      ...ArtistThreadChannelConnection
    }
    artist(id: $artistId) {
      ...ArtistBottomTab
      ...ArtistSmallDetail
    }
  }
  ${ArtistThreadChannelConnectionFragmentDoc}
  ${ThreadBoxFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${ArtistSmallDetailFragmentDoc}
`
export const Unfollow = gql`
  mutation unfollow($artistId: ID!) {
    unfollow(artistId: $artistId) {
      ...Follower
    }
  }
  ${FollowerFragmentDoc}
`
export const Follow = gql`
  mutation follow($artistId: ID!) {
    follow(artistId: $artistId) {
      ...Follower
    }
  }
  ${FollowerFragmentDoc}
`
export const ArtistTop = gql`
  query ArtistTop(
    $artistId: ID!
    $artistIdString: String!
    $rankingArtistId: String!
    $missionsTypeFilter: [MissionType!]
    $artistThreadChannelsInput: ArtistThreadChannelsInput!
    $filters: ItemsFilterInput
  ) {
    user {
      ...CurrentUser
    }
    artistRankings(artistId: $rankingArtistId) {
      ...RankingBaseConnection
      latestRankingPeriod(periodType: monthly) {
        myRanking {
          ...RankingUserWithoutIdConnection
        }
      }
    }
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      displayName
      accountId
      introduction
      formattedAccountId @client
      profileText
      textLink {
        content
        uri
      }
      avatar {
        uri
        mediumUri
        smallUri
      }
      follower {
        id
        artistId
      }
      subscription {
        products {
          plans {
            planId
            status
          }
          content
        }
      }
      sns {
        facebook
        instagram
        tiktok
        twitter
        youtube
      }
      banners {
        thumbnail {
          uri
          mediumUri
          smallUri
        }
        link {
          innerPath
          externalUrl
        }
      }
      posts {
        edges {
          node {
            ...PostBox
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      patron {
        id
        isPersonalInformationRequired
        subscription {
          planId
        }
      }
      isBlocked
    }
    gachas(artistId: $artistId) {
      ...GachasConnection
    }
    missionsWithFilteres(artistId: $artistId, typeFilter: $missionsTypeFilter) {
      ...MissionsConnection
    }
    artistStepMissions(artistId: $artistId) {
      ...UserStepMissionsConnection
    }
    artistThreadChannels(inputs: $artistThreadChannelsInput) {
      ...ArtistThreadChannelConnection
    }
    items(artistId: $artistId, filters: $filters) {
      ...NewStoreItemConnection
    }
    eventsByArtist(artistId: $artistIdString) {
      ...EventsByArtistConnection
    }
    organizationOfficialArtistNotifications(
      artistId: $artistIdString
      pageInput: { limit: 3 }
    ) {
      ...OrganizationNotificationConnection
    }
  }
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
  ${RankingBaseConnectionFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${RankingUserWithoutIdConnectionFragmentDoc}
  ${PostBoxFragmentDoc}
  ${GachasConnectionFragmentDoc}
  ${GachaBoxFragmentDoc}
  ${MissionsConnectionFragmentDoc}
  ${MissionBoxFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
  ${UserStepMissionsConnectionFragmentDoc}
  ${UserStepMissionBoxFragmentDoc}
  ${GachaTicketConnectionFragmentDoc}
  ${GachaTicketThumbnailsBoxFragmentDoc}
  ${ItemBoxFragmentDoc}
  ${ArtistThreadChannelConnectionFragmentDoc}
  ${ThreadBoxFragmentDoc}
  ${NewStoreItemConnectionFragmentDoc}
  ${MagazineItemFragmentDoc}
  ${EventsByArtistConnectionFragmentDoc}
  ${ArtistTopEventFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${OrganizationNotificationConnectionFragmentDoc}
  ${OrganizationNotificationBoxFragmentDoc}
`
export const ArtistTopForGuest = gql`
  query ArtistTopForGuest(
    $artistId: ID!
    $artistIdString: String!
    $missionsTypeFilter: [MissionType!]
    $artistThreadChannelsInput: ArtistThreadChannelsInput!
    $filters: ItemsFilterInput
  ) {
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      displayName
      accountId
      formattedAccountId @client
      profileText
      textLink {
        content
        uri
      }
      introduction
      avatar {
        uri
        mediumUri
        smallUri
      }
      follower {
        id
        artistId
      }
      subscription {
        products {
          plans {
            planId
            status
          }
          content
        }
      }
      sns {
        facebook
        instagram
        tiktok
        twitter
        youtube
      }
      banners {
        thumbnail {
          uri
          mediumUri
          smallUri
        }
        link {
          innerPath
          externalUrl
        }
      }
      posts {
        edges {
          node {
            ...PostBox
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      patron {
        id
      }
    }
    gachas(artistId: $artistId) {
      ...GachasConnection
    }
    missionsWithFilteres(artistId: $artistId, typeFilter: $missionsTypeFilter) {
      ...MissionsConnection
    }
    artistThreadChannels(inputs: $artistThreadChannelsInput) {
      ...ArtistThreadChannelConnection
    }
    items(artistId: $artistId, filters: $filters) {
      ...NewStoreItemConnection
    }
    eventsByArtist(artistId: $artistIdString) {
      ...EventsByArtistConnection
    }
    organizationOfficialArtistNotifications(
      artistId: $artistIdString
      pageInput: { limit: 3 }
    ) {
      ...OrganizationNotificationConnection
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${PostBoxFragmentDoc}
  ${GachasConnectionFragmentDoc}
  ${GachaBoxFragmentDoc}
  ${MissionsConnectionFragmentDoc}
  ${MissionBoxFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
  ${ArtistThreadChannelConnectionFragmentDoc}
  ${ThreadBoxFragmentDoc}
  ${NewStoreItemConnectionFragmentDoc}
  ${MagazineItemFragmentDoc}
  ${EventsByArtistConnectionFragmentDoc}
  ${ArtistTopEventFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${OrganizationNotificationConnectionFragmentDoc}
  ${OrganizationNotificationBoxFragmentDoc}
`
export const ArtistLiveStream = gql`
  query ArtistLiveStream($artistId: ID!) {
    artist(id: $artistId) {
      id
      currentLive {
        id
        isPremium
      }
    }
  }
`
export const ArtistTrade = gql`
  query ArtistTrade($artistId: ID!, $pageInput: PageInput) {
    tradeNegotiationsMyRequests(
      artistId: $artistId
      status: [in_progress, broken_off, dropped, agreed]
    ) {
      edges {
        node {
          ...TradeBox
        }
      }
    }
    tradeMyCandidates(
      artistId: $artistId
      status: [in_progress, rejected, accepted, expired, dropped]
    ) {
      edges {
        node {
          ... on TradeCandidate {
            tradeNegotiation {
              ...TradeBox
            }
          }
        }
      }
    }
    artistTradeNegotiations(artistId: $artistId, pageInput: $pageInput) {
      edges {
        node {
          ...TradeBox
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      displayName
      currentLive {
        id
      }
    }
    user {
      ...CurrentUser
    }
  }
  ${TradeBoxFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const FetchNewArtistTrade = gql`
  query FetchNewArtistTrade($artistId: ID!, $pageInput: PageInput) {
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      displayName
      currentLive {
        id
      }
    }
    tradeNegotiationsMyRequests(
      artistId: $artistId
      status: [in_progress, broken_off, dropped, agreed]
    ) {
      edges {
        node {
          ...TradeBox
        }
      }
    }
    tradeMyCandidates(
      artistId: $artistId
      status: [in_progress, rejected, accepted, expired, dropped]
    ) {
      edges {
        node {
          ... on TradeCandidate {
            tradeNegotiation {
              ...TradeBox
            }
          }
        }
      }
    }
    artistTradeNegotiations(artistId: $artistId, pageInput: $pageInput) {
      edges {
        node {
          ...TradeBox
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${TradeBoxFragmentDoc}
`
export const ArtistTradeForGuest = gql`
  query ArtistTradeForGuest($artistId: ID!, $pageInput: PageInput) {
    tradeNegotiationsMyRequests(
      artistId: $artistId
      status: [in_progress, broken_off, dropped, agreed]
    ) {
      edges {
        node {
          ...TradeBox
        }
      }
    }
    tradeMyCandidates(
      artistId: $artistId
      status: [in_progress, rejected, accepted, expired, dropped]
    ) {
      edges {
        node {
          ... on TradeCandidate {
            tradeNegotiation {
              ...TradeBox
            }
          }
        }
      }
    }
    artistTradeNegotiations(artistId: $artistId, pageInput: $pageInput) {
      edges {
        node {
          ...TradeBox
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    artist(id: $artistId) {
      ...ArtistBottomTab
      id
      displayName
      currentLive {
        id
      }
    }
  }
  ${TradeBoxFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
`
export const AutoFollowSettings = gql`
  query AutoFollowSettings {
    user {
      id
      settings {
        follow {
          autoFollow
        }
      }
    }
  }
`
export const UpdateUserFollowSettings = gql`
  mutation UpdateUserFollowSettings($inputs: UserFollowSettingsInput!) {
    updateUserFollowSettings(inputs: $inputs) {
      id
      settings {
        follow {
          autoFollow
        }
      }
    }
  }
`
export const BankCreate = gql`
  query bankCreate {
    user {
      id
      bank {
        accountHolderName
        accountNumber
        accountType
        bankName
        branchCode
        branchName
      }
    }
  }
`
export const RegisterUserBank = gql`
  mutation registerUserBank($inputs: UserBankInput!) {
    registerUserBank(inputs: $inputs) {
      id
    }
  }
`
export const CampaignDetail = gql`
  query CampaignDetail($code: String) {
    campaign(code: $code) {
      ...CampaignDetail
    }
  }
  ${CampaignDetailFragmentDoc}
`
export const CampaignUserRanking = gql`
  query CampaignUserRanking($inputs: CampaignRankingInput) {
    campaignRanking(inputs: $inputs) {
      ... on UserCampaignRanking {
        ...UserCampaignRanking
      }
    }
  }
  ${UserCampaignRankingFragmentDoc}
`
export const CampaignArtistRanking = gql`
  query CampaignArtistRanking($inputs: CampaignRankingInput) {
    campaignRanking(inputs: $inputs) {
      ... on ArtistCampaignRanking {
        ...ArtistCampaignRanking
      }
    }
  }
  ${ArtistCampaignRankingFragmentDoc}
`
export const CollectionDetail = gql`
  query CollectionDetail(
    $collectionId: ID!
    $collectionItemsLabel: String
    $collectionItemsFirstLabel: Boolean
    $pageInput: PageInput
    $userId: ID
  ) {
    user(id: $userId) {
      id
      displayName
      avatar {
        uri
        smallUri
      }
    }
    collection(id: $collectionId) {
      id
      title
      artistId
      artist {
        id
        displayName
      }
      thumbnail {
        uri
      }
      userCollection(userId: $userId) {
        ogp {
          uri
          smallUri
        }
        itemOwnedCount
        totalOwnedCount
        totalOwnedItemCount @client
        ownedItems {
          itemId
          ownedCount
          userItem {
            serials {
              serial {
                skuId
              }
            }
          }
        }
        id
        userId
      }
      itemCount
      description
      labels {
        name
        id
      }
      collectionItems(
        label: $collectionItemsLabel
        firstLabel: $collectionItemsFirstLabel
        pageInput: $pageInput
      ) {
        ...CollectionItemsConnection
      }
    }
  }
  ${CollectionItemsConnectionFragmentDoc}
`
export const CollectionDetailForGuest = gql`
  query CollectionDetailForGuest(
    $collectionId: ID!
    $collectionItemsLabel: String
    $collectionItemsFirstLabel: Boolean
    $pageInput: PageInput
  ) {
    collection(id: $collectionId) {
      id
      title
      artistId
      artist {
        id
        displayName
      }
      thumbnail {
        uri
      }
      itemCount
      description
      labels {
        name
        id
      }
      collectionItems(
        label: $collectionItemsLabel
        firstLabel: $collectionItemsFirstLabel
        pageInput: $pageInput
      ) {
        ...CollectionItemsConnection
      }
    }
  }
  ${CollectionItemsConnectionFragmentDoc}
`
export const CollectionItemDetail = gql`
  query CollectionItemDetail($itemId: ID!) {
    item(id: $itemId) {
      id
      issuedArtist {
        id
      }
      price
      formattedPrice @client
      paymentType
      serials(pageInput: { limit: 1 }) {
        ...SerialItemOwners
      }
      collection {
        id
        isPublishOnStore
      }
      ...ItemImageArea
      description
      commentCount
      isPublished
      gachas {
        id
        name
        thumbnail {
          uri
          mediumUri
        }
      }
    }
    user {
      id
      displayName
      avatar {
        uri
        smallUri
      }
    }
  }
  ${SerialItemOwnersFragmentDoc}
  ${ItemImageAreaFragmentDoc}
`
export const TradableUsers = gql`
  query TradableUsers($itemId: ID!, $pageInput: PageInput) {
    item(id: $itemId) {
      id
      tradableUsers(pageInput: $pageInput) {
        ...TradableUsersConnection
      }
    }
  }
  ${TradableUsersConnectionFragmentDoc}
  ${UserTradeBoxFragmentDoc}
`
export const ConsoleAccountSetting = gql`
  query ConsoleAccountSetting($artistId: ID) {
    artist(id: $artistId) {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        products {
          productId
        }
      }
    }
  }
`
export const ConsoleBankEdit = gql`
  query ConsoleBankEdit {
    artist {
      id
      bank {
        id
        accountHolderName
        accountHolderType
        bankName
        last4
        routingNumber
      }
    }
  }
`
export const UpdateBank = gql`
  mutation UpdateBank($inputs: BankInput) {
    updateBank(inputs: $inputs) {
      id
      bank {
        id
        accountHolderName
        accountHolderType
        bankName
        last4
        routingNumber
      }
    }
  }
`
export const ConsoleBusinessEdit = gql`
  query ConsoleBusinessEdit {
    artist {
      id
      bank {
        id
        accountHolderName
        accountHolderType
        bankName
        last4
        routingNumber
      }
      business {
        ...BusinessDetail
      }
    }
  }
  ${BusinessDetailFragmentDoc}
`
export const BusinessEdit = gql`
  mutation BusinessEdit($updateBusinessInputs: BusinessInput) {
    updateBusiness(inputs: $updateBusinessInputs) {
      id
      business {
        ...BusinessDetail
      }
    }
  }
  ${BusinessDetailFragmentDoc}
`
export const ConsoleCampaignBannerSetting = gql`
  query ConsoleCampaignBannerSetting {
    artist {
      id
      banners {
        ...ArtistBannerDetail
      }
    }
  }
  ${ArtistBannerDetailFragmentDoc}
`
export const ArtistUpdateArtistBanners = gql`
  mutation artistUpdateArtistBanners($input: ArtistUpdateArtistBannersInput!) {
    artistUpdateArtistBanners(inputs: $input) {
      id
      banners {
        ...ArtistBannerDetail
      }
    }
  }
  ${ArtistBannerDetailFragmentDoc}
`
export const ConsoleCampaignDetail = gql`
  query ConsoleCampaignDetail($code: String, $artistId: String!) {
    artist {
      id
      avatar {
        uri
        smallUri
        mediumUri
      }
    }
    campaign(code: $code) {
      ...ConsoleCampaignDetail
      imageTemplates(artistId: $artistId) {
        ...EntryCampaignImageTemplates
      }
    }
  }
  ${ConsoleCampaignDetailFragmentDoc}
  ${EntryConditionItemFragmentDoc}
  ${EntryCampaignImageTemplatesFragmentDoc}
  ${ImageTemplateFragmentDoc}
`
export const EntryCampaign = gql`
  mutation EntryCampaign($id: ID!, $inputs: CampaignEntryInput!) {
    entryCampaign(id: $id, inputs: $inputs) {
      ...ConsoleCampaignDetail
    }
  }
  ${ConsoleCampaignDetailFragmentDoc}
  ${EntryConditionItemFragmentDoc}
`
export const CreateResource = gql`
  mutation CreateResource($filePath: String!, $name: String!) {
    createResource(filePath: $filePath, name: $name) {
      id
      uri
      name
      type
      createdAt
    }
  }
`
export const ConsoleCampaigns = gql`
  query ConsoleCampaigns(
    $excludeExpired: Boolean
    $campaignsPageInput: PageInput
  ) {
    campaigns(excludeExpired: $excludeExpired, pageInput: $campaignsPageInput) {
      ...ConsoleCampaignsConnection
    }
    artist {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        products {
          productId
        }
      }
    }
  }
  ${ConsoleCampaignsConnectionFragmentDoc}
  ${ConsoleCampaignBoxFragmentDoc}
`
export const CreateCollection = gql`
  mutation CreateCollection($inputs: CreateCollectionInput) {
    createCollection(inputs: $inputs) {
      ...CollectionsSelectBox
    }
  }
  ${CollectionsSelectBoxFragmentDoc}
`
export const ConsoleCollectionDetail = gql`
  query ConsoleCollectionDetail($id: ID!, $pageInput: PageInput) {
    artist {
      id
      cryptoWallet {
        address
      }
    }
    collection(id: $id) {
      ...ConsoleCollectionDetail
      collectionItems(pageInput: $pageInput) {
        ...ConsoleCollectionItemsConnection
      }
      userCollection {
        id
        ownedItems {
          itemId
          ownedCount
        }
      }
    }
  }
  ${ConsoleCollectionDetailFragmentDoc}
  ${EventBoxFragmentDoc}
  ${EventBoxCommonFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
  ${ConsoleCollectionItemsConnectionFragmentDoc}
  ${ConsoleCollectionItemDetailFragmentDoc}
`
export const AddItemToCollection = gql`
  mutation AddItemToCollection($collectionId: ID!, $itemId: ID!) {
    addItemToCollection(collectionId: $collectionId, itemId: $itemId) {
      ...ConsoleCollectionItemDetail
    }
  }
  ${ConsoleCollectionItemDetailFragmentDoc}
`
export const RemoveItemFromCollection = gql`
  mutation RemoveItemFromCollection($collectionId: ID!, $itemId: ID!) {
    removeItemFromCollection(collectionId: $collectionId, itemId: $itemId) {
      ...ConsoleCollectionItemDetail
    }
  }
  ${ConsoleCollectionItemDetailFragmentDoc}
`
export const RemoveCollection = gql`
  mutation RemoveCollection($id: ID!) {
    removeCollection(id: $id) {
      id
    }
  }
`
export const ConsoleCollectionEdit = gql`
  query ConsoleCollectionEdit($id: ID!) {
    collection(id: $id) {
      ...CollectionsSelectBox
    }
  }
  ${CollectionsSelectBoxFragmentDoc}
`
export const UpdateCollection = gql`
  mutation UpdateCollection($input: UpdateCollectionInput) {
    updateCollection(inputs: $input) {
      ...CollectionsSelectBox
    }
  }
  ${CollectionsSelectBoxFragmentDoc}
`
export const ConsoleCollections = gql`
  query ConsoleCollections($pageInput: PageInputDate, $orderBy: OrderBy) {
    artistCollections(pageInput: $pageInput, orderBy: $orderBy) {
      ...ArtistCollectionsConnection
    }
  }
  ${ArtistCollectionsConnectionFragmentDoc}
  ${CollectionsSelectBoxFragmentDoc}
`
export const FetchNewConsoleCollections = gql`
  query FetchNewConsoleCollections(
    $pageInput: PageInputDate
    $orderBy: OrderBy
  ) {
    artistCollections(pageInput: $pageInput, orderBy: $orderBy) {
      ...ArtistCollectionsConnection
    }
  }
  ${ArtistCollectionsConnectionFragmentDoc}
  ${CollectionsSelectBoxFragmentDoc}
`
export const ArtistCryptoWallet = gql`
  query artistCryptoWallet {
    artist {
      id
      displayName
      cryptoWallet {
        address
      }
    }
  }
`
export const ConsoleData = gql`
  query ConsoleData {
    artist {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        products {
          productId
        }
      }
    }
    dailySalesData(days: 30) {
      createdAt
      store {
        totalSales
        totalSalesWithoutFee
      }
      subscription {
        totalSales
        totalSalesWithoutFee
      }
    }
    salesReport {
      last30Days {
        salesWithoutFee
        formattedSalesWithoutFee @client
      }
    }
  }
`
export const ConsoleDeliveryManagement = gql`
  query ConsoleDeliveryManagement(
    $pageInput: PageInput
    $deliveryItemsStatusFilter: [DeliveryStatus!]
  ) {
    artistDeliveryItems(
      pageInput: $pageInput
      statusFilter: $deliveryItemsStatusFilter
    ) {
      ...ConsoleDeliveryItemsConnection
    }
    artist {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        products {
          productId
        }
      }
    }
  }
  ${ConsoleDeliveryItemsConnectionFragmentDoc}
  ${ConsoleDeliveryItemBoxFragmentDoc}
`
export const ArtistMerchItemShipComplete = gql`
  mutation ArtistMerchItemShipComplete(
    $input: ArtistMerchItemShipCompleteInput!
  ) {
    artistMerchItemShipComplete(inputs: $input) {
      id
      status
      serialId
    }
  }
`
export const ConsoleEventCollectionReceiveTimeEdit = gql`
  query ConsoleEventCollectionReceiveTimeEdit(
    $eventId: ID!
    $collectionId: ID!
  ) {
    event(id: $eventId) {
      ...EventBox
    }
    collection(id: $collectionId) {
      id
      title
    }
  }
  ${EventBoxFragmentDoc}
  ${EventBoxCommonFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
`
export const UpdateEventCollectionPickupTime = gql`
  mutation UpdateEventCollectionPickupTime(
    $inputs: UpdateEventCollectionPickupTimeInput!
  ) {
    updateEventCollectionPickupTime(inputs: $inputs) {
      ...EventCollectionBox
    }
  }
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
`
export const ArtistCreateEvent = gql`
  mutation ArtistCreateEvent($inputs: ArtistCreateEventInput!) {
    artistCreateEvent(inputs: $inputs) {
      id
    }
  }
`
export const ConsoleEventDetail = gql`
  query ConsoleEventDetail(
    $id: ID!
    $idString: String!
    $eventPreSalePageInput: PageInput
  ) {
    artistPreSales(eventId: $idString, pageInput: $eventPreSalePageInput) {
      edges {
        node {
          ...EventPreSaleBox
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    event(id: $id) {
      ...ConsoleEventDetail
      preSales(pageInput: $eventPreSalePageInput) {
        edges {
          node {
            ...EventPreSaleBox
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      eventCollections(pageInput: { limit: 30 }) {
        edges {
          node {
            ...ConsoleEventDetailCollectionBox
          }
        }
      }
    }
    user {
      ...CurrentUser
    }
  }
  ${EventPreSaleBoxFragmentDoc}
  ${ConsoleEventDetailFragmentDoc}
  ${ConsolePreSalesAggregationDetailFragmentDoc}
  ${ConsoleEventDetailCollectionBoxFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const SelectCollection = gql`
  query SelectCollection($pageInput: PageInputDate) {
    artistCollections(pageInput: $pageInput) {
      ...ConsoleEventCollectionSelectConnection
    }
  }
  ${ConsoleEventCollectionSelectConnectionFragmentDoc}
  ${ConsoleEventCollectionSelectBoxFragmentDoc}
`
export const ArtistAddCollectionsToEvent = gql`
  mutation ArtistAddCollectionsToEvent(
    $inputs: ArtistAddCollecitonToEventInput!
  ) {
    artistAddCollectionsToEvent(inputs: $inputs) {
      id
      eventCollections(pageInput: { limit: 30 }) {
        edges {
          node {
            ...ConsoleEventDetailCollectionBox
          }
        }
      }
    }
  }
  ${ConsoleEventDetailCollectionBoxFragmentDoc}
`
export const ArtistRemoveCollectionFromEvent = gql`
  mutation ArtistRemoveCollectionFromEvent(
    $inputs: ArtistRemoveCollectionFromEventInput!
  ) {
    artistRemoveCollectionFromEvent(inputs: $inputs) {
      id
    }
  }
`
export const ArtistDeleteEvent = gql`
  mutation ArtistDeleteEvent($id: ID!) {
    artistDeleteEvent(id: $id) {
      id
    }
  }
`
export const ConsoleEventEdit = gql`
  query ConsoleEventEdit($id: ID!) {
    event(id: $id) {
      ...ConsoleEventEditDetail
    }
  }
  ${ConsoleEventEditDetailFragmentDoc}
`
export const ArtistUpdateEvent = gql`
  mutation ArtistUpdateEvent($id: ID!, $input: ArtistUpdateEventInput!) {
    artistUpdateEvent(id: $id, inputs: $input) {
      ...ConsoleEventDetail
    }
  }
  ${ConsoleEventDetailFragmentDoc}
  ${ConsolePreSalesAggregationDetailFragmentDoc}
`
export const ConsoleEvents = gql`
  query ConsoleEvents($filter: EventFilter!, $pageInput: PageInput) {
    artistEvents(filter: $filter, pageInput: $pageInput) {
      edges {
        node {
          ... on Event {
            ...ConsoleEventList
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    artist {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        products {
          productId
        }
      }
    }
  }
  ${ConsoleEventListFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
`
export const ConsoleHome = gql`
  query ConsoleHome($inputs: ArtistThreadChannelsInput!, $limit: Int) {
    salesReport {
      totalSales
      formattedTotalSales @client
    }
    artist {
      id
      paymentType
      subscription {
        products {
          productId
          plans {
            amount
            status
          }
          content
        }
      }
      status
      patronCount
      formattedPatronCount @client
      followerCount
      totalPurchasedNumber
      hasArrangingDeliveryItems
      subscription {
        products {
          plans {
            amount
            status
          }
          content
        }
      }
      posts(pageInput: { limit: $limit }) {
        edges {
          node {
            id
          }
        }
      }
    }
    artistItems(pageInput: { limit: $limit }) {
      edges {
        node {
          id
        }
      }
    }
    artistEvents(filter: all) {
      ...ConsoleEventAggregationConnection
    }
    artistThreadChannels(inputs: $inputs, pageInput: { limit: $limit }) {
      edges {
        node {
          id
        }
      }
    }
  }
  ${ConsoleEventAggregationConnectionFragmentDoc}
  ${ConsoleEventTicketInfoBoxFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
`
export const CreateAcceptedItem = gql`
  mutation CreateAcceptedItem($inputs: CreateItemInput) {
    createAcceptedItem(inputs: $inputs) {
      ...ItemSelectBox
    }
  }
  ${ItemSelectBoxFragmentDoc}
  ${ResourceFragmentDoc}
`
export const ConsoleItemDetail = gql`
  query ConsoleItemDetail(
    $id: ID!
    $pageInput: PageInput
    $filter: SerialFilter
    $skuId: String
  ) {
    item(id: $id) {
      ...ItemDescription
      salesStatus
      isPublished
      skuSettings {
        keyName
        units {
          id
        }
      }
      isPremium
      status @client
      formattedPrice @client
      usedNumber
      pendingPurchaseNumber
      serials(pageInput: $pageInput, filter: $filter) {
        ...SerialItemOwners
      }
      collection {
        id
        isEventPickup
      }
    }
  }
  ${ItemDescriptionFragmentDoc}
  ${EventBoxFragmentDoc}
  ${EventBoxCommonFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
  ${CollectionItemDetailFragmentDoc}
  ${ThumbnailResourceFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${ResourceFragmentDoc}
  ${SerialItemOwnersFragmentDoc}
`
export const ArtistUseTickets = gql`
  mutation artistUseTickets($inputs: UseTicketsInput!) {
    artistUseTickets(inputs: $inputs) {
      id
      obtainedDate
      ticketStatus {
        ...TicketStatusDetail
      }
    }
  }
  ${TicketStatusDetailFragmentDoc}
`
export const ArtistUnuseTickets = gql`
  mutation artistUnuseTickets($inputs: UnuseTicketsInput!) {
    artistUnuseTickets(inputs: $inputs) {
      id
      obtainedDate
      ticketStatus {
        ...TicketStatusDetail
      }
    }
  }
  ${TicketStatusDetailFragmentDoc}
`
export const ConsoleItemEdit = gql`
  query ConsoleItemEdit($itemId: ID!) {
    item(id: $itemId) {
      ...ItemSelectBox
      preSale {
        id
      }
      metadata {
        magazine {
          pageDirection
        }
        ticket {
          expirationTerm
          note
          description
          assignedNumberPrefix
          startAssignedNumber
          isThroughTicket
          throughTicket {
            name
          }
          distributionEnabled
          distributionEndedAt
        }
        merch {
          receptionPeriod {
            endedAt
          }
        }
        magazine {
          pageDirection
        }
        wallpaper {
          isPrintEnabled
          printCount
          printType
        }
      }
    }
  }
  ${ItemSelectBoxFragmentDoc}
  ${ResourceFragmentDoc}
`
export const ArtistUpdateItem = gql`
  mutation ArtistUpdateItem(
    $artistUpdateItemId: ID!
    $inputs: ArtistUpdateItemInput
  ) {
    artistUpdateItem(id: $artistUpdateItemId, inputs: $inputs) {
      ...ItemSelectBox
    }
  }
  ${ItemSelectBoxFragmentDoc}
  ${ResourceFragmentDoc}
`
export const ConsoleItemInformation = gql`
  query ConsoleItemInformation(
    $id: ID!
    $userId: ID
    $pageInput: PageInput
    $skuId: String
  ) {
    item(id: $id) {
      ...ItemDescription
      serials(pageInput: $pageInput) {
        ...ConsoleSerialItemOwners
      }
    }
    user(id: $userId) {
      ...UserInfo
    }
  }
  ${ItemDescriptionFragmentDoc}
  ${EventBoxFragmentDoc}
  ${EventBoxCommonFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
  ${CollectionItemDetailFragmentDoc}
  ${ThumbnailResourceFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${ResourceFragmentDoc}
  ${ConsoleSerialItemOwnersFragmentDoc}
  ${UserInfoFragmentDoc}
`
export const ConsoleItemInformationLimitUser = gql`
  query ConsoleItemInformationLimitUser($id: ID!) {
    item(id: $id) {
      id
      purchasableUsers {
        ...PurchasableUserDetail
      }
    }
  }
  ${PurchasableUserDetailFragmentDoc}
`
export const ConsoleItemOrders = gql`
  query ConsoleItemOrders($pageInput: PageInputDate) {
    organizationArtistItemOrders(pageInput: $pageInput) {
      ...ArtistItemOrdersConnection
    }
  }
  ${ArtistItemOrdersConnectionFragmentDoc}
  ${OrderedItemBoxFragmentDoc}
`
export const ConsoleItems = gql`
  query ConsoleItems($filters: ArtistItemsFilterInput, $pageInput: PageInput) {
    artistItems(filters: $filters, pageInput: $pageInput) {
      ...ConsoleItemsConnection
    }
    artist {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        products {
          productId
        }
      }
    }
  }
  ${ConsoleItemsConnectionFragmentDoc}
  ${ItemSelectBoxFragmentDoc}
  ${ResourceFragmentDoc}
`
export const PublishItem = gql`
  mutation PublishItem($id: ID!) {
    publishItem(id: $id) {
      id
      isPublished
    }
  }
`
export const UnpublishItem = gql`
  mutation UnpublishItem($id: ID!) {
    unpublishItem(id: $id) {
      id
      isPublished
    }
  }
`
export const StartSellingItem = gql`
  mutation StartSellingItem($id: ID!) {
    startSellingItem(id: $id) {
      id
      salesStatus
    }
  }
`
export const StopSellingItem = gql`
  mutation StopSellingItem($id: ID!) {
    stopSellingItem(id: $id) {
      id
      salesStatus
    }
  }
`
export const ArtistRemoveItem = gql`
  mutation ArtistRemoveItem($id: ID!) {
    artistRemoveItem(id: $id) {
      id
    }
  }
`
export const ConsoleLiveDetail = gql`
  query ConsoleLiveDetail($id: String!) {
    liveStream(id: $id) {
      id
      status
      token
      channelName
      startedAt
      endedAt
      likeCount
      isPremium
    }
    artist {
      id
      displayName
      avatar {
        uri
        mediumUri
      }
    }
  }
`
export const EndLiveStream = gql`
  mutation EndLiveStream($id: String!) {
    endLiveStream(id: $id) {
      id
      likeCount
      startedAt
      endedAt
      status
    }
  }
`
export const ConsoleLiveFinish = gql`
  query ConsoleLiveFinish($id: String!) {
    liveStream(id: $id) {
      id
      status
      token
      channelName
      startedAt
      endedAt
      likeCount
      commentCount
      subscriberCount
      tipTotal
      liveStreamDuration @client
      formattedLikeCount @client
      formattedCommentCount @client
      formattedSubscriberCount @client
      formattedTipTotal @client
    }
  }
`
export const ConsoleLiveSetting = gql`
  query ConsoleLiveSetting {
    artist {
      id
      avatar {
        uri
        mediumUri
      }
      currentLive {
        id
      }
    }
  }
`
export const CreateLiveStream = gql`
  mutation CreateLiveStream($inputs: CreateLiveStreamInput!) {
    createLiveStream(inputs: $inputs) {
      id
      channelName
      token
      status
    }
  }
`
export const StartLiveStream = gql`
  mutation StartLiveStream($id: String!, $input: StartLiveStreamInput) {
    startLiveStream(id: $id, input: $input) {
      id
    }
  }
`
export const ConsoleLotteryCsv = gql`
  query ConsoleLotteryCsv($preSaleId: ID!, $preSaleIdString: String!) {
    artistPreSale(id: $preSaleId) {
      ...ConsolePreSaleTitle
    }
    exportPreSaleApplicants(preSaleId: $preSaleIdString) {
      ...ExportPreSaleApplicants
    }
  }
  ${ConsolePreSaleTitleFragmentDoc}
  ${ExportPreSaleApplicantsFragmentDoc}
`
export const UpdatePreSaleLotteryResultFromCsv = gql`
  mutation updatePreSaleLotteryResultFromCsv(
    $inputs: UpdatePreSaleLotteryResultFromCsvInput!
  ) {
    updatePreSaleLotteryResultFromCsv(inputs: $inputs) {
      id
    }
  }
`
export const ConsoleLotteryManagementDetail = gql`
  query ConsoleLotteryManagementDetail($preSaleId: String!, $userId: ID!) {
    artistPreSaleLotteryApplicant(preSaleId: $preSaleId, userId: $userId) {
      ...ArtistPreSaleLotteryApplicantDetail
    }
  }
  ${ArtistPreSaleLotteryApplicantDetailFragmentDoc}
  ${PrepaidPointsPaymentSourceFragmentDoc}
`
export const ConsoleLotteryManagements = gql`
  query ConsoleLotteryManagements(
    $filters: PreSaleLotteryApplicantFilterInputs
    $pageInput: PageInput
    $artistPreSaleId: String!
    $preSaleId: ID!
  ) {
    artistPreSaleLotteryApplicants(
      filters: $filters
      pageInput: $pageInput
      preSaleId: $artistPreSaleId
    ) {
      ...ArtistPreSaleLotteryApplicantsConnection
    }
    artistPreSale(id: $preSaleId) {
      id
      title
      description
      type
      preSaleStatus @client
      eventId
      lottery {
        applicationStartedAt
        applicationEndedAt
        resultNoticedAt
        resultNoticeStatus
        maxChoice
        formattedResultNoticedAt @client
        resultNoticeStatus
      }
      items {
        ...PreSaleItemFilter
      }
      itemIds
      isPublished
      endedAt
      startedAt
      note
      paymentMethods
    }
  }
  ${ArtistPreSaleLotteryApplicantsConnectionFragmentDoc}
  ${ArtistPreSaleLotteryApplicantDetailFragmentDoc}
  ${PrepaidPointsPaymentSourceFragmentDoc}
  ${PreSaleItemFilterFragmentDoc}
`
export const ArtistUpdatePreSaleLotteryApplicantStatus = gql`
  mutation ArtistUpdatePreSaleLotteryApplicantStatus(
    $inputs: ArtistUpdatePreSaleLotteryApplicantStatusInput!
  ) {
    artistUpdatePreSaleLotteryApplicantStatus(inputs: $inputs) {
      ...ArtistPreSaleLotteryApplicantDetail
    }
  }
  ${ArtistPreSaleLotteryApplicantDetailFragmentDoc}
  ${PrepaidPointsPaymentSourceFragmentDoc}
`
export const ArtistUpdatePreSaleLotteryResultNoticedAt = gql`
  mutation ArtistUpdatePreSaleLotteryResultNoticedAt(
    $id: ID!
    $inputs: ArtistUpdatePreSaleInput!
  ) {
    artistUpdatePreSale(id: $id, inputs: $inputs) {
      id
      isPublished
      lottery {
        resultNoticedAt
      }
    }
  }
`
export const ConsoleMembership = gql`
  query ConsoleMembership($artistId: ID) {
    artist(id: $artistId) {
      id
      paymentType
      subscription {
        products {
          productId
          ...ConsoleProductDetail
        }
        requirePersonalInformation
        plans {
          ...ConsolePlanDetail
        }
      }
      hasArrangingDeliveryItems
      subscription {
        requirePersonalInformation
        plans {
          ...ConsolePlanDetail
        }
        products {
          ...ConsoleProductDetail
        }
      }
    }
  }
  ${ConsoleProductDetailFragmentDoc}
  ${ConsolePatronDetailFragmentDoc}
  ${ConsolePlanDetailFragmentDoc}
`
export const ArtistExportPatronsCsv = gql`
  query ArtistExportPatronsCsv($productIds: [String!]!) {
    artistExportPatronsCsv(productIds: $productIds) {
      csvUrl
    }
  }
`
export const UpdateSubscriptionRequiredPersonalInformation = gql`
  mutation UpdateSubscriptionRequiredPersonalInformation(
    $isRequired: Boolean!
  ) {
    updateSubscriptionRequirePersonalInformation(isRequired: $isRequired) {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        requirePersonalInformation
        plans {
          ...ConsolePlanDetail
        }
        products {
          ...ConsoleProductDetail
        }
      }
    }
  }
  ${ConsolePlanDetailFragmentDoc}
  ${ConsolePatronDetailFragmentDoc}
  ${ConsoleProductDetailFragmentDoc}
`
export const ArtistRegisterSubscription = gql`
  mutation ArtistRegisterSubscription($inputs: RegisterSubscriptionsInput!) {
    artistRegisterSubscriptions(inputs: $inputs) {
      id
      subscription {
        plans {
          ...ConsolePlanDetail
        }
        products {
          ...ConsoleProductDetail
        }
      }
    }
  }
  ${ConsolePlanDetailFragmentDoc}
  ${ConsolePatronDetailFragmentDoc}
  ${ConsoleProductDetailFragmentDoc}
`
export const ConsoleMembershipDetail = gql`
  query ConsoleMembershipDetail(
    $filters: PatronsFilters
    $pageInput: PageInputDate
  ) {
    artist {
      id
      subscription {
        productId
        plans {
          ...ConsolePlanDetail
        }
        products {
          ...ConsoleProductDetail
        }
      }
      patrons(filters: $filters, pageInput: $pageInput) {
        edges {
          node {
            ...MemberBox
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hits
        }
      }
    }
  }
  ${ConsolePlanDetailFragmentDoc}
  ${ConsolePatronDetailFragmentDoc}
  ${ConsoleProductDetailFragmentDoc}
  ${MemberBoxFragmentDoc}
  ${MemberAvatarBoxFragmentDoc}
`
export const ConsoleMembershipEdit = gql`
  query ConsoleMembershipEdit {
    artist {
      id
      subscription {
        productId
        plans {
          ...ConsolePlanDetail
        }
        products {
          ...ConsoleProductDetail
        }
      }
    }
  }
  ${ConsolePlanDetailFragmentDoc}
  ${ConsolePatronDetailFragmentDoc}
  ${ConsoleProductDetailFragmentDoc}
`
export const UpdateSubscription = gql`
  mutation UpdateSubscription(
    $artistId: ID!
    $inputs: UpdateSubscriptionInput!
  ) {
    updateSubscription(artistId: $artistId, inputs: $inputs) {
      id
      subscription {
        productId
        plans {
          ...ConsolePlanDetail
        }
        products {
          ...ConsoleProductDetail
        }
      }
    }
  }
  ${ConsolePlanDetailFragmentDoc}
  ${ConsolePatronDetailFragmentDoc}
  ${ConsoleProductDetailFragmentDoc}
`
export const ConsoleMembershipExplain = gql`
  query ConsoleMembershipExplain {
    artist {
      id
      subscription {
        amount
        content
        status
        productId
        playstore {
          productId
        }
        appstore {
          productId
        }
      }
    }
  }
`
export const CreateMission = gql`
  mutation CreateMission($inputs: CreateMissionInput!) {
    createMission(inputs: $inputs) {
      ...ConsoleMissionBox
    }
  }
  ${ConsoleMissionBoxFragmentDoc}
`
export const ConsoleMissionEdit = gql`
  query ConsoleMissionEdit($id: ID!) {
    mission(id: $id) {
      ...ConsoleMissionBox
    }
  }
  ${ConsoleMissionBoxFragmentDoc}
`
export const UpdateMission = gql`
  mutation UpdateMission($id: ID!, $inputs: UpdateMissionInput!) {
    updateMission(id: $id, inputs: $inputs) {
      ...ConsoleMissionBox
    }
  }
  ${ConsoleMissionBoxFragmentDoc}
`
export const ConsoleMissions = gql`
  query ConsoleMissions(
    $orderBy: OrderBy
    $pageInput: PageInput
    $typeFilter: [MissionType!]
  ) {
    artistMissions(
      orderBy: $orderBy
      pageInput: $pageInput
      typeFilter: $typeFilter
    ) {
      ...ArtistMissionsConnection
    }
    artist {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        products {
          productId
        }
      }
    }
  }
  ${ArtistMissionsConnectionFragmentDoc}
  ${ConsoleMissionBoxFragmentDoc}
`
export const DeleteMission = gql`
  mutation deleteMission($id: ID!) {
    deleteMission(id: $id) {
      id
    }
  }
`
export const ConsoleMyProfileEdit = gql`
  query ConsoleMyProfileEdit($artistId: ID) {
    artist(id: $artistId) {
      ...ConsoleMyProfileConnection
    }
  }
  ${ConsoleMyProfileConnectionFragmentDoc}
`
export const ArtistUpdateArtistProfile = gql`
  mutation ArtistUpdateArtistProfile($inputs: ArtistUpdateArtistProfileInput!) {
    artistUpdateArtistProfile(inputs: $inputs) {
      ...ConsoleMyProfileConnection
    }
  }
  ${ConsoleMyProfileConnectionFragmentDoc}
`
export const UpdateArtistSns = gql`
  mutation UpdateArtistSns($inputs: UpdateArtistSnsInput!) {
    updateArtistSns(inputs: $inputs) {
      ...ConsoleMyProfileConnection
    }
  }
  ${ConsoleMyProfileConnectionFragmentDoc}
`
export const UpdateArtistProfileText = gql`
  mutation UpdateArtistProfileText($profileText: String) {
    updateArtistProfileText(profileText: $profileText) {
      ...ConsoleMyProfileConnection
    }
  }
  ${ConsoleMyProfileConnectionFragmentDoc}
`
export const UpdateArtistTextLink = gql`
  mutation UpdateArtistTextLink($inputs: [UpdateArtistTextLinkInput]!) {
    updateArtistTextLink(inputs: $inputs) {
      ...ConsoleMyProfileConnection
    }
  }
  ${ConsoleMyProfileConnectionFragmentDoc}
`
export const ArtistCreateOrganizationNotification = gql`
  mutation ArtistCreateOrganizationNotification(
    $inputs: ArtistCreateOrganizationNotificationInput!
  ) {
    artistCreateOrganizationNotification(inputs: $inputs) {
      ...OfficialInformationConsoleBox
    }
  }
  ${OfficialInformationConsoleBoxFragmentDoc}
`
export const ConsoleOfficialInformationEdit = gql`
  query ConsoleOfficialInformationEdit($id: ID!) {
    artistOrganizationNotification(id: $id) {
      ...OfficialInformationConsoleBox
    }
  }
  ${OfficialInformationConsoleBoxFragmentDoc}
`
export const ArtistUpdateOrganizationNotification = gql`
  mutation ArtistUpdateOrganizationNotification(
    $id: ID!
    $inputs: ArtistUpdateOrganizationNotificationInput!
  ) {
    artistUpdateOrganizationNotification(id: $id, inputs: $inputs) {
      ...OfficialInformationConsoleBox
    }
  }
  ${OfficialInformationConsoleBoxFragmentDoc}
`
export const ConsoleOfficialInformations = gql`
  query ConsoleOfficialInformations(
    $status: [OrganizationNotificationStatus!]
    $pageInput: PageInput
  ) {
    artistOrganizationOfficialNotifications(
      status: $status
      pageInput: $pageInput
    ) {
      ...ConsoleOfficialInformationConnection
    }
  }
  ${ConsoleOfficialInformationConnectionFragmentDoc}
  ${OfficialInformationConsoleBoxFragmentDoc}
`
export const FetchNewConsoleOfficialInformations = gql`
  query FetchNewConsoleOfficialInformations(
    $status: [OrganizationNotificationStatus!]
  ) {
    artistOrganizationOfficialNotifications(status: $status) {
      ...ConsoleOfficialInformationConnection
    }
  }
  ${ConsoleOfficialInformationConnectionFragmentDoc}
  ${OfficialInformationConsoleBoxFragmentDoc}
`
export const ArtistArchiveOrganizationNotification = gql`
  mutation ArtistArchiveOrganizationNotification($id: ID!) {
    artistArchiveOrganizationNotification(id: $id) {
      ...OfficialInformationConsoleBox
    }
  }
  ${OfficialInformationConsoleBoxFragmentDoc}
`
export const ConsoleOrderDetail = gql`
  query ConsoleOrderDetail($id: ID!, $userId: String!, $skuId: String) {
    artistUserCartTransactionOrder(id: $id, userId: $userId) {
      itemOrders {
        user {
          id
          fullName
          phoneNumber
          displayName
          formattedPhoneNumber @client
          isPhoneNumberVerified
          status
          credit {
            ...PaymentDetail
          }
          ...UserAddress
        }
      }
      ...UserCartTransactionOrderDetail
    }
    prepaidPointBalance {
      lastUpdatedAt
      prepaidPointId
      balance
      formattedPrepaidPointBarance @client
    }
  }
  ${PaymentDetailFragmentDoc}
  ${UserAddressFragmentDoc}
  ${UserCartTransactionOrderDetailFragmentDoc}
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${StoreItemDetailFragmentDoc}
  ${StoreItemDetailGuestFragmentDoc}
  ${SkuTypeFragmentDoc}
  ${SkuTagFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const ConsoleOrders = gql`
  query ConsoleOrders(
    $orderBy: OrderBy
    $pageInput: PageInput
    $userId: String
  ) {
    artistUserCartTransactionOrders(
      orderBy: $orderBy
      pageInput: $pageInput
      userId: $userId
    ) {
      ...UserCartTransactionOrderConnection
    }
    artist {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        products {
          productId
        }
      }
    }
  }
  ${UserCartTransactionOrderConnectionFragmentDoc}
  ${UserCartTransactionOrderBoxFragmentDoc}
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
`
export const FetchNewConsoleOrders = gql`
  query FetchNewConsoleOrders(
    $orderBy: OrderBy
    $pageInput: PageInput
    $userId: String
  ) {
    artistUserCartTransactionOrders(
      orderBy: $orderBy
      pageInput: $pageInput
      userId: $userId
    ) {
      ...UserCartTransactionOrderConnection
    }
  }
  ${UserCartTransactionOrderConnectionFragmentDoc}
  ${UserCartTransactionOrderBoxFragmentDoc}
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
`
export const ArtistCategoryDetail = gql`
  query artistCategoryDetail {
    artist {
      id
      postCategory {
        categories {
          id
          name
          description
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const CreatePostCategory = gql`
  mutation CreatePostCategory($inputs: ArtistCreatePostCategoryInput!) {
    artistCreatePostCategory(inputs: $inputs) {
      categories {
        id
      }
    }
  }
`
export const UpdatePostCategory = gql`
  mutation UpdatePostCategory($inputs: ArtistUpdatePostCategoryInput!) {
    artistUpdatePostCategory(inputs: $inputs) {
      categories {
        id
      }
    }
  }
`
export const DeletePostCategory = gql`
  mutation DeletePostCategory($inputs: ArtistRemovePostCategoryInput!) {
    artistRemovePostCategory(inputs: $inputs) {
      categories {
        id
      }
    }
  }
`
export const ArtistSubscriptionPlan = gql`
  query artistSubscriptionPlan {
    artist {
      id
      subscription {
        plans {
          planId
          title
          interval
        }
        products {
          productId
          title
          plans {
            interval
            planId
          }
        }
      }
    }
  }
`
export const ArtistCategory = gql`
  query artistCategory {
    artist {
      id
      postCategory {
        categories {
          id
          name
        }
      }
    }
  }
`
export const CreatePost = gql`
  mutation CreatePost($inputs: CreatePostInput!) {
    createPost(inputs: $inputs) {
      ...PostBox
    }
  }
  ${PostBoxFragmentDoc}
`
export const ConsolePostDetail = gql`
  query ConsolePostDetail($postId: ID!, $pageInput: PageInput) {
    post(id: $postId) {
      ...PostBox
      replies(pageInput: $pageInput) {
        ...RepliesConnection
      }
    }
    user {
      id
      accountId
      formattedAccountId @client
      displayName
      avatar {
        uri
      }
    }
  }
  ${PostBoxFragmentDoc}
  ${RepliesConnectionFragmentDoc}
  ${ReplyBoxFragmentDoc}
`
export const DeletePost = gql`
  mutation DeletePost($postId: ID!) {
    deletePost(postId: $postId) {
      ...PostBox
    }
  }
  ${PostBoxFragmentDoc}
`
export const ConsolePostEdit = gql`
  query ConsolePostEdit($postId: ID!, $pageInput: PageInput) {
    post(id: $postId) {
      ...PostBox
      replies(pageInput: $pageInput) {
        ...RepliesConnection
      }
    }
    artist {
      id
      subscription {
        plans {
          planId
          title
          interval
        }
        products {
          productId
          title
          plans {
            interval
            planId
          }
        }
      }
    }
    user {
      id
      accountId
      formattedAccountId @client
      displayName
      avatar {
        uri
        smallUri
      }
    }
  }
  ${PostBoxFragmentDoc}
  ${RepliesConnectionFragmentDoc}
  ${ReplyBoxFragmentDoc}
`
export const UpdatePost = gql`
  mutation UpdatePost($postId: ID!, $inputs: UpdatePostInput!) {
    updatePost(postId: $postId, inputs: $inputs) {
      ...PostBox
    }
  }
  ${PostBoxFragmentDoc}
`
export const ConsolePost = gql`
  query ConsolePost(
    $artistId: ID!
    $pageInput: PageInputDate
    $resourceType: PostType
    $status: [PostStatus]
  ) {
    artist(id: $artistId) {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        products {
          productId
        }
      }
      posts(
        pageInput: $pageInput
        resourceType: $resourceType
        status: $status
      ) {
        ...ArtistPosts
      }
    }
    user {
      id
      accountId
      formattedAccountId @client
    }
  }
  ${ArtistPostsFragmentDoc}
  ${PostBoxFragmentDoc}
`
export const CreatePreSale = gql`
  mutation createPreSale($inputs: ArtistCreatePreSaleInput!) {
    artistCreatePreSale(inputs: $inputs) {
      id
    }
  }
`
export const ConsolePreSaleDetail = gql`
  query ConsolePreSaleDetail($id: ID!) {
    artistPreSale(id: $id) {
      ...ConsolePreSaleDetail
      purchasedCartTransactionOrders {
        id
      }
    }
  }
  ${ConsolePreSaleDetailFragmentDoc}
  ${ConsolePreSalesAggregationDetailFragmentDoc}
  ${ConsolePreSaleItemSelectedBoxFragmentDoc}
`
export const ConsolePresaleItemSearch = gql`
  query ConsolePresaleItemSearch(
    $filters: ArtistItemsFilterInput
    $pageInput: PageInput
  ) {
    artistItems(filters: $filters, pageInput: $pageInput) {
      edges {
        node {
          ...ConsolePreSaleItemSelectBox
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${ConsolePreSaleItemSelectBoxFragmentDoc}
`
export const ArtistUpdatePreSaleItems = gql`
  mutation ArtistUpdatePreSaleItems(
    $id: ID!
    $inputs: ArtistUpdatePreSaleInput!
  ) {
    artistUpdatePreSale(id: $id, inputs: $inputs) {
      id
      items {
        ...ConsolePreSaleItemSelectedBox
      }
      itemIds
    }
  }
  ${ConsolePreSaleItemSelectedBoxFragmentDoc}
`
export const ConsolePreSaleEdit = gql`
  query ConsolePreSaleEdit($id: ID!) {
    artistPreSale(id: $id) {
      ...ConsolePreSaleEdit
    }
  }
  ${ConsolePreSaleEditFragmentDoc}
`
export const ArtistUpdatePreSale = gql`
  mutation ArtistUpdatePreSale($id: ID!, $inputs: ArtistUpdatePreSaleInput!) {
    artistUpdatePreSale(id: $id, inputs: $inputs) {
      id
      isPublished
      lottery {
        resultNoticedAt
      }
    }
  }
`
export const ConsoleQuantityManagements = gql`
  query ConsoleQuantityManagements(
    $collectionId: String!
    $eventId: ID!
    $pickupDate: Date!
  ) {
    artistAggregatePickupTimeItems(
      eventId: $eventId
      collectionId: $collectionId
      pickupDate: $pickupDate
    ) {
      ...PickupTimeItemsAggregationDetail
    }
    event(id: $eventId) {
      id
      title
      eventCollections(pageInput: { limit: 30 }) {
        edges {
          node {
            ...EventCollectionPickupTime
          }
        }
      }
    }
  }
  ${PickupTimeItemsAggregationDetailFragmentDoc}
  ${EventCollectionPickupTimeFragmentDoc}
`
export const ConsoleRevenue = gql`
  query ConsoleRevenue {
    salesReport {
      totalSales
      formattedTotalSales @client
      store {
        totalSales
        formattedTotalSales @client
      }
      subscription {
        totalSales
        formattedTotalSales @client
      }
      tip {
        totalSales
        formattedTotalSales @client
      }
    }
    transactions {
      ...TransactionsConnections
    }
    artist {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        products {
          productId
        }
      }
      status
      createdAt
      bank {
        id
      }
      business {
        firstNameKana
        firstNameKanji
      }
    }
  }
  ${TransactionsConnectionsFragmentDoc}
`
export const ExportRevenueTransactions = gql`
  query ExportRevenueTransactions($year: Int!) {
    exportRevenueTransactions(year: $year) {
      csvUrl
    }
  }
`
export const Transactions = gql`
  query Transactions($pageInputDate: PageInputDate) {
    transactions(pageInput: $pageInputDate) {
      ...TransactionsConnections
    }
  }
  ${TransactionsConnectionsFragmentDoc}
`
export const OpenPayout = gql`
  mutation OpenPayout {
    openPayout {
      id
      artistId
      status
    }
  }
`
export const ConsoleTicketReceiveDetail = gql`
  query ConsoleTicketReceiveDetail(
    $collectionId: String!
    $eventId: String!
    $pickupUserId: ID!
  ) {
    artistPickupUser(
      collectionId: $collectionId
      eventId: $eventId
      pickupUserId: $pickupUserId
    ) {
      ...PickupUserDetail
    }
  }
  ${PickupUserDetailFragmentDoc}
  ${ItemBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
`
export const ArtistUseEventPickupTickets = gql`
  mutation ArtistUseEventPickupTickets($inputs: UseEventPickupTicketsInput!) {
    artistUseEventPickupTickets(inputs: $inputs) {
      id
      obtainedDate
      ticketStatus {
        ...TicketStatusDetail
      }
    }
  }
  ${TicketStatusDetailFragmentDoc}
`
export const ArtistUnuseEventPickupTickets = gql`
  mutation ArtistUnuseEventPickupTickets(
    $inputs: UnuseEventPickupTicketsInput!
  ) {
    artistUnuseEventPickupTickets(inputs: $inputs) {
      id
      obtainedDate
      ticketStatus {
        ...TicketStatusDetail
      }
    }
  }
  ${TicketStatusDetailFragmentDoc}
`
export const ConsoleTicketReceives = gql`
  query ConsoleTicketReceives(
    $collectionId: String!
    $colId: ID!
    $eventId: String!
    $options: EventPickupUsersOptions!
    $pageInput: PageInput
  ) {
    collection(id: $colId) {
      id
      title
      artist {
        id
      }
    }
    artistEventPickupUsers(
      collectionId: $collectionId
      eventId: $eventId
      options: $options
      pageInput: $pageInput
    ) {
      ...EventPickupUserConnection
    }
  }
  ${EventPickupUserConnectionFragmentDoc}
  ${PickupUserBoxFragmentDoc}
  ${CartTransactionOrdersTipFragmentDoc}
`
export const FetchNewConsoleTicketReceives = gql`
  query FetchNewConsoleTicketReceives(
    $collectionId: String!
    $eventId: String!
    $options: EventPickupUsersOptions!
    $pageInput: PageInput
  ) {
    artistEventPickupUsers(
      collectionId: $collectionId
      eventId: $eventId
      options: $options
      pageInput: $pageInput
    ) {
      ...EventPickupUserConnection
    }
  }
  ${EventPickupUserConnectionFragmentDoc}
  ${PickupUserBoxFragmentDoc}
  ${CartTransactionOrdersTipFragmentDoc}
`
export const ConsoleUserDetail = gql`
  query ConsoleUserDetail($userId: ID!) {
    artist {
      id
      subscription {
        productId
        plans {
          ...ConsolePlanDetail
        }
        products {
          ...ConsoleProductDetail
        }
      }
    }
    artistCustomer(userId: $userId) {
      id
      purchaseCount
      ticketUnusedCount
      follower {
        id
      }
      patron {
        ...MemberBox
      }
      user {
        ...ConsoleUserDetailUser
      }
    }
  }
  ${ConsolePlanDetailFragmentDoc}
  ${ConsolePatronDetailFragmentDoc}
  ${ConsoleProductDetailFragmentDoc}
  ${MemberBoxFragmentDoc}
  ${MemberAvatarBoxFragmentDoc}
  ${ConsoleUserDetailUserFragmentDoc}
  ${UserProfileFragmentDoc}
  ${UserAddressFragmentDoc}
`
export const FetchConsoleUserDetail = gql`
  query FetchConsoleUserDetail($userId: ID!) {
    user(id: $userId) {
      ...ConsoleUserDetailUser
    }
  }
  ${ConsoleUserDetailUserFragmentDoc}
  ${UserProfileFragmentDoc}
  ${UserAddressFragmentDoc}
`
export const ConsoleUserOwnedItemDetail = gql`
  query consoleUserOwnedItemDetail(
    $itemId: ID!
    $userId: String!
    $skuId: String
  ) {
    artistPortfolio(id: $itemId, userId: $userId) {
      ...UserItemDetailBox
    }
  }
  ${UserItemDetailBoxFragmentDoc}
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${TicketStatusDetailFragmentDoc}
`
export const ConsoleUserOwnedItems = gql`
  query consoleUserOwnedItems(
    $userId: ID!
    $type: ItemType
    $pageInput: PageInput
  ) {
    user(id: $userId) {
      id
      artistPortfolios(type: $type, pageInput: $pageInput) {
        ...ConsoleUserOwnedItemsPortfolio
      }
    }
  }
  ${ConsoleUserOwnedItemsPortfolioFragmentDoc}
  ${ConsoleUserOwnedItemsPortfolioDetailFragmentDoc}
  ${TicketStatusDetailFragmentDoc}
`
export const ConsoleUsers = gql`
  query ConsoleUsers(
    $orderBy: OrderBy
    $pageInput: PageInput
    $searchTerm: String
  ) {
    artistCustomers(
      orderBy: $orderBy
      pageInput: $pageInput
      searchTerm: $searchTerm
    ) {
      ...ArtistCustomerConnection
    }
    artist {
      id
      paymentType
      hasArrangingDeliveryItems
      subscription {
        products {
          productId
        }
      }
    }
  }
  ${ArtistCustomerConnectionFragmentDoc}
  ${ArtistCustomerDetailFragmentDoc}
`
export const FetchNewConsoleUsers = gql`
  query FetchNewConsoleUsers(
    $orderBy: OrderBy
    $pageInput: PageInput
    $searchTerm: String
  ) {
    artistCustomers(
      orderBy: $orderBy
      pageInput: $pageInput
      searchTerm: $searchTerm
    ) {
      ...ArtistCustomerConnection
    }
  }
  ${ArtistCustomerConnectionFragmentDoc}
  ${ArtistCustomerDetailFragmentDoc}
`
export const ArtistExportCustomersCsv = gql`
  query ArtistExportCustomersCsv {
    artistExportCustomersCsv {
      csvUrl
    }
  }
`
export const CustomProfileEditAvatar = gql`
  query CustomProfileEditAvatar(
    $organizationPortfoliosType: ItemType
    $pageInput: PageInput
  ) {
    user {
      id
      organizationPortfolios(
        type: $organizationPortfoliosType
        pageInput: $pageInput
      ) {
        ...UserItemsAvatarConnection
      }
      customProfile {
        ...CustomProfileConnection
      }
    }
  }
  ${UserItemsAvatarConnectionFragmentDoc}
  ${ResourceFragmentDoc}
  ${CustomProfileConnectionFragmentDoc}
`
export const CustomProfileEditWallPaper = gql`
  query CustomProfileEditWallPaper(
    $organizationPortfoliosType: ItemType
    $pageInput: PageInput
  ) {
    user {
      id
      organizationPortfolios(
        type: $organizationPortfoliosType
        pageInput: $pageInput
      ) {
        ...UserItemsConnection
      }
      customProfile {
        ...CustomProfileConnection
      }
    }
  }
  ${UserItemsConnectionFragmentDoc}
  ${ResourceFragmentDoc}
  ${CustomProfileConnectionFragmentDoc}
`
export const UpdateCustomProfile = gql`
  mutation UpdateCustomProfile(
    $updateCustomProfileInputs: UpdateCustomProfileInput
  ) {
    updateCustomProfile(inputs: $updateCustomProfileInputs) {
      id
      customProfile {
        ...CustomProfileConnection
      }
    }
  }
  ${CustomProfileConnectionFragmentDoc}
  ${ResourceFragmentDoc}
`
export const DeactivateUser = gql`
  mutation DeactivateUser($input: DeactivateUserInput!) {
    deactivateUser(inputs: $input) {
      id
    }
  }
`
export const UserDeliveryInfo = gql`
  query userDeliveryInfo {
    user {
      ...UserDeliveryInfo
    }
  }
  ${UserDeliveryInfoFragmentDoc}
`
export const UpdateUserDeliveryInfo = gql`
  mutation updateUserDeliveryInfo(
    $updateUserDeliveryInfoInputs: UserDeliveryInput!
  ) {
    updateUserDeliveryInfo(inputs: $updateUserDeliveryInfoInputs) {
      ...UserDeliveryInfo
    }
  }
  ${UserDeliveryInfoFragmentDoc}
`
export const DeliveryManagement = gql`
  query DeliveryManagement(
    $pageInput: PageInput
    $deliveryItemsStatusFilter: [DeliveryStatus!]
  ) {
    user {
      id
      phoneNumber
      formattedPhoneNumber @client
      isPhoneNumberVerified
      ...UserAddress
    }
    deliveryItems(
      pageInput: $pageInput
      statusFilter: $deliveryItemsStatusFilter
    ) {
      ...DeliveryItemsConnection
    }
  }
  ${UserAddressFragmentDoc}
  ${DeliveryItemsConnectionFragmentDoc}
  ${DeliveryItemBoxFragmentDoc}
`
export const ApplyMerchItemDelivery = gql`
  mutation ApplyMerchItemDelivery($applyMerchItemDeliveryIds: [ID!]!) {
    applyMerchItemDelivery(ids: $applyMerchItemDeliveryIds) {
      ...DeliveryItemBox
    }
  }
  ${DeliveryItemBoxFragmentDoc}
`
export const SendVerificationSms = gql`
  mutation SendVerificationSMS {
    sendVerificationSMS {
      phoneNumber
      id
    }
  }
`
export const EmailNotificationSettings = gql`
  query EmailNotificationSettings {
    user {
      id
      ...UserSetting
    }
  }
  ${UserSettingFragmentDoc}
`
export const ArtistNotificationSettings = gql`
  query ArtistNotificationSettings {
    artist {
      id
      ...ArtistSetting
    }
  }
  ${ArtistSettingFragmentDoc}
`
export const UpdateUserNotificationSettings = gql`
  mutation UpdateUserNotificationSettings(
    $updateUserNotificationSettingsInputs: UserNotificationSettingsInput!
  ) {
    updateUserNotificationSettings(
      inputs: $updateUserNotificationSettingsInputs
    ) {
      ... on User {
        id
        ...UserSetting
      }
    }
  }
  ${UserSettingFragmentDoc}
`
export const UpdateArtistNotificationSettings = gql`
  mutation UpdateArtistNotificationSettings(
    $inputs: ArtistNotificationSettingsInput!
  ) {
    updateArtistNotificationSettings(inputs: $inputs) {
      ... on Artist {
        id
        ...ArtistSetting
      }
    }
  }
  ${ArtistSettingFragmentDoc}
`
export const EventCollectionDetail = gql`
  query EventCollectionDetail(
    $collectionId: ID!
    $collectionItemsLabel: String
    $collectionItemsFirstLabel: Boolean
    $pageInput: PageInput
    $publishedOnly: Boolean
  ) {
    userCartCount
    collection(id: $collectionId) {
      id
      title
      artistId
      isEventPickup
      artist {
        id
        displayName
      }
      thumbnail {
        uri
      }
      labels {
        id
        name
      }
      itemCount
      description
      collectionItems(
        label: $collectionItemsLabel
        firstLabel: $collectionItemsFirstLabel
        publishedOnly: $publishedOnly
        pageInput: $pageInput
      ) {
        ...EventCollectionItemsConnection
      }
    }
  }
  ${EventCollectionItemsConnectionFragmentDoc}
`
export const EventCollectionDetailForGuest = gql`
  query EventCollectionDetailForGuest(
    $collectionId: ID!
    $collectionItemsLabel: String
    $collectionItemsFirstLabel: Boolean
    $pageInput: PageInput
    $publishedOnly: Boolean
  ) {
    collection(id: $collectionId) {
      id
      title
      artistId
      isEventPickup
      artist {
        id
        displayName
      }
      thumbnail {
        uri
      }
      itemCount
      description
      labels {
        name
        id
      }
      collectionItems(
        label: $collectionItemsLabel
        firstLabel: $collectionItemsFirstLabel
        publishedOnly: $publishedOnly
        pageInput: $pageInput
      ) {
        ...EventCollectionItemsConnection
      }
    }
  }
  ${EventCollectionItemsConnectionFragmentDoc}
`
export const EventDetail = gql`
  query EventDetail(
    $id: ID!
    $eventCollectionPageInput: PageInput
    $eventPreSalePageInput: PageInput
    $filter: CollectionStatus
  ) {
    event(id: $id) {
      ...EventDetail
      eventCollections(pageInput: $eventCollectionPageInput, filter: $filter) {
        edges {
          node {
            ...EventDetailCollectionBox
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      preSales(pageInput: $eventPreSalePageInput) {
        edges {
          node {
            ...EventPreSaleBox
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
    userCartCount
    user {
      ...CurrentUser
    }
  }
  ${EventDetailFragmentDoc}
  ${EventDetailCollectionBoxFragmentDoc}
  ${EventPreSaleBoxFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const Events = gql`
  query Events(
    $artistId: ID
    $filter: EventFilter!
    $pageInput: PageInput
    $isStandalone: Boolean!
  ) {
    user {
      ...CurrentUser
    }
    artist(id: $artistId) @include(if: $isStandalone) {
      ...ArtistBottomTab
      currentLive {
        id
      }
    }
    events(filter: $filter, pageInput: $pageInput) {
      edges {
        node {
          ... on Event {
            ...EventList
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${EventListFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
`
export const FetchNewEvents = gql`
  query FetchNewEvents(
    $artistId: ID
    $filter: EventFilter!
    $pageInput: PageInput
    $isStandalone: Boolean!
  ) {
    artist(id: $artistId) @include(if: $isStandalone) {
      ...ArtistBottomTab
      currentLive {
        id
      }
    }
    events(filter: $filter, pageInput: $pageInput) {
      edges {
        node {
          ... on Event {
            ...EventList
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${EventListFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
`
export const FirstComeFirstServedComplete = gql`
  query FirstComeFirstServedComplete($userCartTransactionOrderId: ID!) {
    userCartTransactionOrder(id: $userCartTransactionOrderId) {
      ...UserCartTransactionOrder
    }
  }
  ${UserCartTransactionOrderFragmentDoc}
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${ItemBoxFragmentDoc}
  ${PendingPurchaseItemDetailFragmentDoc}
  ${PendingPurchaseItemSkuDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const FirstComeFirstServedConfirmation = gql`
  query FirstComeFirstServedConfirmation($id: ID!) {
    preSale(id: $id) {
      ...FirstComeFirstServedConfirmation
    }
    paymentSource {
      ...PaymentDetail
    }
  }
  ${FirstComeFirstServedConfirmationFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const PurchaseFirstComeFirstServed = gql`
  mutation PurchaseFirstComeFirstServed(
    $inputs: PurchaseItemImmediatelyInput!
  ) {
    purchaseItemImmediately(inputs: $inputs) {
      id
      paymentType
    }
  }
`
export const FirstComeFirstServedDetail = gql`
  query FirstComeFirstServedDetail(
    $id: ID!
    $clientId: String
    $token: String
  ) {
    preSale(id: $id, clientId: $clientId, token: $token) {
      ...FirstComeFirstServedDetail
    }
    paymentSource {
      ...PaymentDetail
    }
    user {
      ...UserDeliveryDetail
    }
  }
  ${FirstComeFirstServedDetailFragmentDoc}
  ${FirstComeFirstServedDetailForGuestFragmentDoc}
  ${PreSaleItemFragmentDoc}
  ${PurchasedCartTransactionOrderDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
  ${UserDeliveryDetailFragmentDoc}
`
export const FirstComeFirstServedDetailForGuest = gql`
  query FirstComeFirstServedDetailForGuest(
    $id: ID!
    $clientId: String
    $token: String
  ) {
    preSale(id: $id, clientId: $clientId, token: $token) {
      ...FirstComeFirstServedDetailForGuest
    }
  }
  ${FirstComeFirstServedDetailForGuestFragmentDoc}
  ${PreSaleItemFragmentDoc}
`
export const GachaDetail = gql`
  query GachaDetail(
    $gachasArtistId: ID
    $gachasPageInput: PageInputDate
    $gachaTicketId: ID!
    $platform: String!
    $purchasablePrepaidPointPageInput: PageInput
  ) {
    gachas(artistId: $gachasArtistId, pageInput: $gachasPageInput) {
      ...GachasDetailConnection
    }
    user {
      ...CurrentUser
      gachaTicket(id: $gachaTicketId) {
        ...UserGachaTicketConnection
      }
    }
    gachaTicket(id: $gachaTicketId) {
      ...GachaTicketConnection
    }
    prepaidPointBalance {
      prepaidPointId
      balance
      formattedPrepaidPointBarance @client
    }
    artist(id: $gachasArtistId) {
      ...ArtistBottomTab
      ...ArtistGachaDetail
    }
    purchasablePrepaidPoints(
      platform: $platform
      pageInput: $purchasablePrepaidPointPageInput
    ) {
      ...PurchasablePrepaidPointConnection
    }
    paymentSource {
      ...PrepaidPointsPaymentSource
    }
  }
  ${GachasDetailConnectionFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
  ${UserGachaTicketConnectionFragmentDoc}
  ${GachaTicketConnectionFragmentDoc}
  ${GachaTicketThumbnailsBoxFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${ArtistGachaDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
  ${PurchasablePrepaidPointConnectionFragmentDoc}
  ${PurchasablePrepaidPointDetailFragmentDoc}
  ${PrepaidPointsPaymentSourceFragmentDoc}
`
export const GachaDetailForGuest = gql`
  query GachaDetailForGuest(
    $gachasArtistId: ID
    $gachasPageInput: PageInputDate
    $gachaTicketId: ID!
  ) {
    gachas(artistId: $gachasArtistId, pageInput: $gachasPageInput) {
      ...GachasDetailConnection
    }
    gachaTicket(id: $gachaTicketId) {
      ...GachaTicketConnection
    }
    artist(id: $gachasArtistId) {
      ...ArtistBottomTab
      ...ArtistGachaDetail
    }
  }
  ${GachasDetailConnectionFragmentDoc}
  ${GachaTicketConnectionFragmentDoc}
  ${GachaTicketThumbnailsBoxFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${ArtistGachaDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const RollGacha = gql`
  mutation RollGacha(
    $rollGachaGachaId: ID!
    $rollGachaInputs: RollGachaInput!
  ) {
    rollGacha(gachaId: $rollGachaGachaId, inputs: $rollGachaInputs) {
      gachaOrder {
        id
        gachaId
        rewardItems {
          count
        }
        rewardAnimation {
          uri
          mediumUri
          smallUri
        }
      }
    }
  }
`
export const PurchaseGachaTicketByPoint = gql`
  mutation PurchaseGachaTicketByPoint(
    $gachaTicketId: ID!
    $inputs: PurchaseGachaTicketPointInput!
  ) {
    purchaseGachaTicketByPoint(gachaTicketId: $gachaTicketId, inputs: $inputs) {
      gachaTicketId
      count
    }
  }
`
export const PurchaseGachaTicketByPointInBulk = gql`
  mutation PurchaseGachaTicketByPointInBulk(
    $gachaTicketId: ID!
    $inputs: PurchaseGachaTicketPointInBulkInput!
  ) {
    purchaseGachaTicketByPointInBulk(
      gachaTicketId: $gachaTicketId
      inputs: $inputs
    ) {
      gachaTicketId
      count
    }
  }
`
export const GachaItemList = gql`
  query GachaItemList($gachaId: ID!) {
    gacha(id: $gachaId) {
      id
      rollAlgorithm
      items {
        ...GachaItemsConnection
      }
    }
  }
  ${GachaItemsConnectionFragmentDoc}
`
export const GachaResult = gql`
  query GachaResult($gachaOrderId: ID!, $gachaTicketId: ID!) {
    user {
      id
      gachaOrder(id: $gachaOrderId) {
        ...GachaOrderDetail
      }
      gachaTicket(id: $gachaTicketId) {
        ...UserGachaTicketConnection
      }
      customProfile {
        ...CustomProfileConnection
      }
    }
    gachaTicket(id: $gachaTicketId) {
      ...GachaTicketConnection
    }
    prepaidPointBalance {
      prepaidPointId
      balance
      formattedPrepaidPointBarance @client
    }
  }
  ${GachaOrderDetailFragmentDoc}
  ${UserItemDetailFragmentDoc}
  ${GachaItemDetailFragmentDoc}
  ${ResourceFragmentDoc}
  ${UserGachaTicketConnectionFragmentDoc}
  ${CustomProfileConnectionFragmentDoc}
  ${GachaTicketConnectionFragmentDoc}
  ${GachaTicketThumbnailsBoxFragmentDoc}
`
export const GachaPortfolio = gql`
  query GachaPortfolio($id: ID!, $skuId: String) {
    portfolio(id: $id) {
      ...PortfolioConnection
    }
  }
  ${PortfolioConnectionFragmentDoc}
  ${ItemDescriptionFragmentDoc}
  ${EventBoxFragmentDoc}
  ${EventBoxCommonFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
  ${CollectionItemDetailFragmentDoc}
  ${ThumbnailResourceFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${ResourceFragmentDoc}
  ${UserItemSerialDetailFragmentDoc}
  ${TicketStatusDetailFragmentDoc}
`
export const GetUserFollowees = gql`
  query GetUserFollowees {
    user {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const GlobalCollections = gql`
  query GlobalCollections($userId: String, $pageInput: PageInput) {
    ownedUserCollections(pageInput: $pageInput, userId: $userId) {
      edges {
        node {
          ...UserCollectionBox
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    user {
      ...CurrentUser
    }
  }
  ${UserCollectionBoxFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const FetchNewGlobalCollections = gql`
  query FetchNewGlobalCollections($userId: String, $pageInput: PageInput) {
    ownedUserCollections(pageInput: $pageInput, userId: $userId) {
      edges {
        node {
          ...UserCollectionBox
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${UserCollectionBoxFragmentDoc}
`
export const GlobalEvents = gql`
  query GlobalEvents(
    $typeFilter: [MissionType!]
    $missionsPageInput: PageInput
    $campaignsPageInput: PageInput
  ) {
    organization {
      id
      campaigns(pageInput: $campaignsPageInput) {
        ...CampaignsConnection
      }
      entryCampaignRankings {
        ...EntryCampaignRankingConnection
      }
      missions(typeFilter: $typeFilter, pageInput: $missionsPageInput) {
        ...MissionsConnection
      }
    }
    user {
      ...CurrentUser
    }
  }
  ${CampaignsConnectionFragmentDoc}
  ${CampaignBoxFragmentDoc}
  ${EntryCampaignRankingConnectionFragmentDoc}
  ${MissionsConnectionFragmentDoc}
  ${MissionBoxFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const FetchNewGlobalEvents = gql`
  query FetchNewGlobalEvents(
    $typeFilter: [MissionType!]
    $missionsPageInput: PageInput
    $campaignsPageInput: PageInput
  ) {
    organization {
      id
      campaigns(pageInput: $campaignsPageInput) {
        ...CampaignsConnection
      }
      missions(typeFilter: $typeFilter, pageInput: $missionsPageInput) {
        ...MissionsConnection
      }
    }
  }
  ${CampaignsConnectionFragmentDoc}
  ${CampaignBoxFragmentDoc}
  ${MissionsConnectionFragmentDoc}
  ${MissionBoxFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
`
export const GlobalHome = gql`
  query GlobalHome(
    $pageInput: PageInputDate
    $type: FeedType
    $followArtistGachasPageInput: PageInputDate
    $followArtistMissionsPageInput: PageInput
    $missionsTypeFilter: [MissionType!]
  ) {
    user {
      ...CurrentUser
      feeds(pageInput: $pageInput, type: $type) {
        ...FeedConnection
      }
    }
    organizationOfficialNotifications {
      ...OrganizationNotificationConnection
    }
    organization {
      id
      banners {
        ...BannerConnection
      }
      entryCampaignRankings {
        ...EntryCampaignRankingConnection
      }
      followArtistGachas(pageInput: $followArtistGachasPageInput) {
        ...FollowArtistGachasConnection
      }
      followArtistMissions(
        pageInput: $followArtistMissionsPageInput
        typeFilter: $missionsTypeFilter
      ) {
        ...FollowArtistMissionsConnection
      }
      topDisplayArtistIds
    }
    prepaidPointBalance {
      prepaidPointId
      balance
    }
    organizationArtistRankings(pageInput: { limit: 3 }) {
      ...GlobalHomeOrganizationArtistRankingsConnection
    }
    events(filter: all) {
      edges {
        node {
          ...GlobalEvent
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
  ${FeedConnectionFragmentDoc}
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
  ${PostBoxFragmentDoc}
  ${OrganizationNotificationConnectionFragmentDoc}
  ${OrganizationNotificationBoxFragmentDoc}
  ${BannerConnectionFragmentDoc}
  ${EntryCampaignRankingConnectionFragmentDoc}
  ${FollowArtistGachasConnectionFragmentDoc}
  ${FollowArtistMissionsConnectionFragmentDoc}
  ${MissionBoxFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
  ${GlobalHomeOrganizationArtistRankingsConnectionFragmentDoc}
  ${RankingBaseConnectionFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${RankingPeriodBaseConnectionFragmentDoc}
  ${RankingUserWithoutIdConnectionFragmentDoc}
  ${RankingUserConnectionFragmentDoc}
  ${GlobalEventFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
`
export const GlobalHomeForGuest = gql`
  query GlobalHomeForGuest {
    organization {
      id
      banners {
        ...BannerConnection
      }
      topDisplayArtistIds
    }
    organizationOfficialNotifications {
      ...OrganizationNotificationConnection
    }
    events(filter: all) {
      edges {
        node {
          ...GlobalEvent
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${BannerConnectionFragmentDoc}
  ${OrganizationNotificationConnectionFragmentDoc}
  ${OrganizationNotificationBoxFragmentDoc}
  ${GlobalEventFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
`
export const LikeComment = gql`
  mutation LikeComment($likeCommentId: ID!) {
    likeComment(id: $likeCommentId) {
      likeCount
      isLike
      id
    }
  }
`
export const UnlikeComment = gql`
  mutation UnlikeComment($unlikeCommentId: ID!) {
    unlikeComment(id: $unlikeCommentId) {
      likeCount
      isLike
      id
    }
  }
`
export const RemoveOauthToken = gql`
  mutation RemoveOauthToken($providerId: ProviderType!) {
    removeOauthToken(providerId: $providerId) {
      ... on User {
        id
      }
      ... on Artist {
        id
      }
    }
  }
`
export const ItemComment = gql`
  query ItemComment($itemId: ID!, $pageInput: PageInput) {
    item(id: $itemId) {
      id
      issuedArtist {
        id
      }
      commentCount
      comments(pageInput: $pageInput) {
        ...CommentsConnection
      }
    }
    user {
      id
      email
      displayName
      accountId
      formattedAccountId @client
      avatar {
        uri
        smallUri
      }
    }
  }
  ${CommentsConnectionFragmentDoc}
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const CreateItemComment = gql`
  mutation CreateItemComment($id: String!, $inputs: CommunityCommentInput!) {
    createItemComment(id: $id, inputs: $inputs) {
      ...CommentBox
    }
  }
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const DeleteItemComment = gql`
  mutation DeleteItemComment($id: String!, $commentId: String!) {
    deleteItemComment(id: $id, commentId: $commentId) {
      ...CommentBox
    }
  }
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const LikeItemComment = gql`
  mutation LikeItemComment($inputs: CommunityCommentLikeUnlikeInput!) {
    likeItemComment(inputs: $inputs) {
      id
      isLike
      likeCount
    }
  }
`
export const UnlikeItemComment = gql`
  mutation UnlikeItemComment($inputs: CommunityCommentLikeUnlikeInput!) {
    unlikeItemComment(inputs: $inputs) {
      id
      isLike
      likeCount
    }
  }
`
export const ItemInformation = gql`
  query ItemInformation(
    $id: ID!
    $userId: ID
    $pageInput: PageInput
    $skuId: String
  ) {
    item(id: $id) {
      ...ItemDescription
      serials(pageInput: $pageInput) {
        ...SerialItemOwners
      }
    }
    user(id: $userId) {
      ...UserInfo
    }
    userLoggedIn: user {
      id
    }
  }
  ${ItemDescriptionFragmentDoc}
  ${EventBoxFragmentDoc}
  ${EventBoxCommonFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
  ${CollectionItemDetailFragmentDoc}
  ${ThumbnailResourceFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${ResourceFragmentDoc}
  ${SerialItemOwnersFragmentDoc}
  ${UserInfoFragmentDoc}
`
export const PortfolioDetail = gql`
  query PortfolioDetail($id: ID!, $userId: String, $skuId: String) {
    portfolio(id: $id, userId: $userId) {
      ...PortfolioConnection
    }
  }
  ${PortfolioConnectionFragmentDoc}
  ${ItemDescriptionFragmentDoc}
  ${EventBoxFragmentDoc}
  ${EventBoxCommonFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
  ${CollectionItemDetailFragmentDoc}
  ${ThumbnailResourceFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${ResourceFragmentDoc}
  ${UserItemSerialDetailFragmentDoc}
  ${TicketStatusDetailFragmentDoc}
`
export const ItemMissions = gql`
  query ItemMissions($itemId: ID!) {
    item(id: $itemId) {
      id
      relatedMissions {
        ...RelatedMissionDetail
      }
    }
  }
  ${RelatedMissionDetailFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
`
export const ItemOrders = gql`
  query ItemOrders($pageInput: PageInputDate) {
    userItemOrders(pageInput: $pageInput) {
      ...ItemOrdersConnection
    }
  }
  ${ItemOrdersConnectionFragmentDoc}
`
export const LiveDetail = gql`
  query LiveDetail($id: String!, $pageInput: PageInput, $platform: String!) {
    prepaidPointBalance {
      prepaidPointId
      balance
    }
    purchasablePrepaidPoints(platform: $platform, pageInput: $pageInput) {
      ...PurchasablePrepaidPointConnection
    }
    paymentSource {
      ...PrepaidPointsPaymentSource
    }
    user {
      id
      isMembershipPersonalInformationRequired
    }
    liveStream(id: $id) {
      __typename
      id
      artistId
      channelName
      token
      isPremium
      status
      isAccessible @client
      artist {
        id
        displayName
        avatar {
          uri
          mediumUri
        }
        patron {
          id
          isPersonalInformationRequired
        }
      }
    }
  }
  ${PurchasablePrepaidPointConnectionFragmentDoc}
  ${PurchasablePrepaidPointDetailFragmentDoc}
  ${PrepaidPointsPaymentSourceFragmentDoc}
`
export const GetOlderComments = gql`
  query getOlderComments($id: ID!, $pageInput: PageInput) {
    liveStreamComments(id: $id, pageInput: $pageInput) {
      edges {
        node {
          ... on Comment {
            ...CommentBoxWithoutReplies
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const ArtistCurrentLiveStream = gql`
  query ArtistCurrentLiveStream($artistId: ID!) {
    artist(id: $artistId) {
      id
      currentLive {
        id
        status
      }
    }
  }
`
export const LikeLiveStream = gql`
  mutation LikeLiveStream($id: String!) {
    likeLiveStream(id: $id) {
      id
      likeCount
    }
  }
`
export const CreateLiveStreamComment = gql`
  mutation CreateLiveStreamComment(
    $id: String!
    $inputs: CommunityCommentInput!
  ) {
    createLiveStreamComment(id: $id, inputs: $inputs) {
      ...CommentBoxWithoutReplies
    }
  }
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const LiveStreamLiked = gql`
  subscription LiveStreamLiked($id: ID!) {
    liveStreamLiked(id: $id) {
      ... on Count {
        count
      }
    }
  }
`
export const LiveStreamSubscribed = gql`
  subscription LiveStreamSubscribed($id: ID!) {
    liveStreamSubscribed(id: $id) {
      ... on Count {
        count
      }
    }
  }
`
export const LiveStreamCommentCreated = gql`
  subscription LiveStreamCommentCreated($id: ID!) {
    liveStreamCommentCreated(id: $id) {
      ...CommentBoxWithoutReplies
    }
  }
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const Login = gql`
  query Login {
    user {
      id
      ...UserSetting
      isMembershipPersonalInformationRequired
      formattedPhoneNumber @client
      ...UserDeliveryInfo
    }
  }
  ${UserSettingFragmentDoc}
  ${UserDeliveryInfoFragmentDoc}
`
export const AddRegistrationToken = gql`
  mutation addRegistrationToken($token: String!) {
    addRegistrationTokenForOrganization(token: $token) {
      id
    }
  }
`
export const SaveOauthToken = gql`
  mutation SaveOauthToken($inputs: SaveOauthTokenInput!) {
    saveOauthToken(inputs: $inputs) {
      ... on User {
        id
      }
      ... on Artist {
        id
      }
    }
  }
`
export const DeleteExpiredCarts = gql`
  mutation deleteExpiredCarts {
    deleteExpiredCarts {
      ...UserCart
    }
  }
  ${UserCartFragmentDoc}
`
export const PreSaleLotteryApplicant = gql`
  query PreSaleLotteryApplicant($preSaleId: String!) {
    preSaleLotteryApplicant(preSaleId: $preSaleId) {
      ...PreSaleLotteryApplicantDetail
    }
    paymentSource {
      ...paymentCreateDetail
    }
  }
  ${PreSaleLotteryApplicantDetailFragmentDoc}
  ${PaymentCreateDetailFragmentDoc}
`
export const CancelPreSaleLottery = gql`
  mutation CancelPreSaleLottery($inputs: CancelPreSaleLotteryInput!) {
    cancelPreSaleLottery(inputs: $inputs) {
      ...PreSaleLotteryApplicantDetail
    }
  }
  ${PreSaleLotteryApplicantDetailFragmentDoc}
`
export const LotteryApplicationDetailPaymentPaymentConfirmation = gql`
  query LotteryApplicationDetailPaymentPaymentConfirmation(
    $preSaleId: String!
  ) {
    preSaleLotteryApplicant(preSaleId: $preSaleId) {
      ...PreSaleLotteryApplicantConfirmation
    }
    paymentSource {
      ...PaymentDetail
    }
  }
  ${PreSaleLotteryApplicantConfirmationFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const PayPreSaleLottery = gql`
  mutation payPreSaleLottery($inputs: PayPreSaleLotteryInput!) {
    payPreSaleLottery(inputs: $inputs) {
      id
    }
  }
`
export const PreSaleLotteryApplicants = gql`
  query PreSaleLotteryApplicants($filter: [String!], $pageInput: PageInput) {
    preSaleLotteryApplicants(filter: $filter, pageInput: $pageInput) {
      ...PreSaleLotteryApplicants
    }
  }
  ${PreSaleLotteryApplicantsFragmentDoc}
  ${PreSaleLotteryApplicantFragmentDoc}
`
export const LotteryConfirmation = gql`
  query LotteryConfirmation($id: ID!, $clientId: String, $token: String) {
    preSale(id: $id, clientId: $clientId, token: $token) {
      ...PreSaleLotteryConfirmation
    }
    paymentSource {
      ...PaymentDetail
    }
  }
  ${PreSaleLotteryConfirmationFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const ApplyPreSaleLottery = gql`
  mutation ApplyPreSaleLottery(
    $inputs: ApplyPreSaleLotteryInput!
    $clientId: String
    $token: String
  ) {
    applyPreSaleLottery(inputs: $inputs, clientId: $clientId, token: $token) {
      id
    }
  }
`
export const LotteryDeliveryInfo = gql`
  query LotteryDeliveryInfo {
    user {
      ...UserDeliveryDetail
    }
  }
  ${UserDeliveryDetailFragmentDoc}
`
export const PreSaleLotteryDetail = gql`
  query PreSaleLotteryDetail(
    $id: ID!
    $preSaleId: String!
    $clientId: String
    $token: String
  ) {
    preSale(id: $id, clientId: $clientId, token: $token) {
      ...PreSaleLotteryDetail
    }
    preSaleLotteryApplicant(preSaleId: $preSaleId) {
      id
      status
    }
    paymentSource {
      ...PaymentDetail
    }
    user {
      ...UserDeliveryDetail
    }
  }
  ${PreSaleLotteryDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
  ${UserDeliveryDetailFragmentDoc}
`
export const PreSaleLotteryDetailForGuest = gql`
  query PreSaleLotteryDetailForGuest(
    $id: ID!
    $clientId: String
    $token: String
  ) {
    preSale(id: $id, clientId: $clientId, token: $token) {
      ...PreSaleLotteryDetail
    }
  }
  ${PreSaleLotteryDetailFragmentDoc}
`
export const LotteryUserInfo = gql`
  query LotteryUserInfo($id: ID!, $clientId: String, $token: String) {
    preSale(id: $id, clientId: $clientId, token: $token) {
      id
      lottery {
        personalInformationRequirements
      }
      firstComeFirstServed {
        personalInformationRequirements
      }
    }
    user {
      ...UserDeliveryDetail
    }
  }
  ${UserDeliveryDetailFragmentDoc}
`
export const SignupAsArtist = gql`
  mutation SignupAsArtist($inputs: SignupAsArtistInput!) {
    signupAsArtist(inputs: $inputs) {
      id
      accountId
      formattedAccountId @client
      avatar {
        uri
        smallUri
      }
      displayName
    }
  }
`
export const Management = gql`
  query Management {
    user {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const Memberships = gql`
  query memberships {
    user {
      id
      isMembershipPersonalInformationRequired
      organizationPatrons {
        ...PatronDetail
      }
    }
  }
  ${PatronDetailFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const StopSubscription = gql`
  mutation stopSubscription($artistId: ID!) {
    stopSubscription(artistId: $artistId) {
      ...PatronDetail
    }
  }
  ${PatronDetailFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const ResumeSubscriptionPlan = gql`
  mutation resumeSubscriptionPlan($artistId: String!) {
    resumeSubscriptionPlan(artistId: $artistId) {
      ...PatronDetail
    }
  }
  ${PatronDetailFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const MissionAccomplished = gql`
  query MissionAccomplished($missionId: ID!) {
    mission(id: $missionId) {
      id
      ...MissionRewardConnection
    }
  }
  ${MissionRewardConnectionFragmentDoc}
`
export const MissionDetail = gql`
  query MissionDetail($missionId: ID!, $pageInput: PageInput) {
    mission(id: $missionId) {
      ...MissionDetail
      reward {
        item {
          item {
            id
            title
            description
            type
            hideSalesNumber
            rarity {
              id
              name
            }
            rarityIconImage {
              uri
              smallUri
            }
            issuedNumber
            thumbnail {
              front {
                uri
                smallUri
              }
            }
          }
        }
        gachaTicket {
          count
          gachaTicket {
            id
            name
            description
            thumbnails {
              standard {
                uri
              }
            }
          }
        }
      }
      comments(pageInput: $pageInput) {
        ...CommentsConnection
      }
    }
    user {
      id
      accountId
      formattedAccountId @client
      email
      displayName
      avatar {
        uri
        smallUri
      }
    }
  }
  ${MissionDetailFragmentDoc}
  ${MissionBoxFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
  ${CommentsConnectionFragmentDoc}
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const AnswerQuizMission = gql`
  mutation AnswerQuizMission(
    $answerQuizMissionMissionId: String!
    $answerQuizMissionAnswer: String!
  ) {
    answerQuizMission(
      missionId: $answerQuizMissionMissionId
      answer: $answerQuizMissionAnswer
    ) {
      ...QuizResultConnection
    }
  }
  ${QuizResultConnectionFragmentDoc}
  ${MissionDetailFragmentDoc}
  ${MissionBoxFragmentDoc}
  ${MultipleItemsMissionFragmentDoc}
`
export const AnswerItemCollectMission = gql`
  mutation AnswerItemCollectMission($missionId: String!) {
    answerItemCollectMission(missionId: $missionId) {
      missionId
      correct
      lackItems {
        itemId
        requiredCount
        item {
          id
          title
          hideSalesNumber
        }
      }
      mission {
        id
        artist {
          id
        }
        isAccomplished
      }
    }
  }
`
export const CreateMissionComment = gql`
  mutation CreateMissionComment($id: String!, $inputs: CommunityCommentInput!) {
    createMissionComment(id: $id, inputs: $inputs) {
      ...CommentBox
    }
  }
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const LikeMissionComment = gql`
  mutation LikeMissionComment($inputs: CommunityCommentLikeUnlikeInput!) {
    likeMissionComment(inputs: $inputs) {
      id
      isLike
      likeCount
    }
  }
`
export const UnlikeMissionComment = gql`
  mutation UnlikeMissionComment($inputs: CommunityCommentLikeUnlikeInput!) {
    unlikeMissionComment(inputs: $inputs) {
      id
      isLike
      likeCount
    }
  }
`
export const DeleteMissionComment = gql`
  mutation DeleteMissionComment($id: String!, $commentId: String!) {
    deleteMissionComment(id: $id, commentId: $commentId) {
      ...CommentBox
    }
  }
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const MyOwnedTicketDetail = gql`
  query MyOwnedTicketDetail(
    $pickupUserId: ID!
    $eventId: String!
    $collectionId: String!
  ) {
    pickupUser(
      pickupUserId: $pickupUserId
      eventId: $eventId
      collectionId: $collectionId
    ) {
      ...PickupUser
    }
  }
  ${PickupUserFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
`
export const UseEventPickupTickets = gql`
  mutation UseEventPickupTickets($inputs: UseEventPickupTicketsInput!) {
    useEventPickupTickets(inputs: $inputs) {
      id
      obtainedDate
      ticketStatus {
        ...TicketStatusDetail
      }
    }
  }
  ${TicketStatusDetailFragmentDoc}
`
export const PickupUsersByEvent = gql`
  query pickupUsersByEvent(
    $eventId: ID!
    $options: PickupUsersByEventOptions!
    $pageInput: PageInput
  ) {
    pickupUsersByEvent(
      eventId: $eventId
      options: $options
      pageInput: $pageInput
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...PickupUserListItem
        }
      }
    }
  }
  ${PickupUserListItemFragmentDoc}
`
export const PickupUserEvents = gql`
  query PickupUserEvents($pageInput: PageInput) {
    user {
      ...CurrentUser
    }
    pickupUserEvents(pageInput: $pageInput) {
      edges {
        node {
          ... on Event {
            ...PickupUserEvent
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
  ${PickupUserEventFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
`
export const MyProfile = gql`
  query MyProfile(
    $artistId: ID
    $pageInput: PageInput
    $fetchMyPreSaleEvents: Boolean!
    $isStandalone: Boolean!
  ) {
    user @skip(if: $fetchMyPreSaleEvents) {
      ...UserConnection
      organizationFollowees {
        ...FollowArtists
      }
    }
    artist(id: $artistId) @include(if: $isStandalone) {
      ...ArtistBottomTab
      currentLive {
        id
      }
    }
    prepaidPointBalance @skip(if: $fetchMyPreSaleEvents) {
      prepaidPointId
      balance
    }
    organizationArtistRankings @skip(if: $fetchMyPreSaleEvents) {
      ...OrganizationArtistRankingsConnection
    }
    myPreSaleEvents(filter: all, pageInput: $pageInput) {
      ...MyPreSaleEventConnection
    }
  }
  ${UserConnectionFragmentDoc}
  ${ResourceFragmentDoc}
  ${FollowArtistsFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${OrganizationArtistRankingsConnectionFragmentDoc}
  ${RankingBaseConnectionFragmentDoc}
  ${RankingPeriodBaseConnectionFragmentDoc}
  ${RankingUserWithoutIdConnectionFragmentDoc}
  ${RankingUserConnectionFragmentDoc}
  ${MyPreSaleEventConnectionFragmentDoc}
  ${EventTicketCardFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
`
export const MyProfileEdit = gql`
  query MyProfileEdit {
    user {
      ...UserConnection
    }
  }
  ${UserConnectionFragmentDoc}
  ${ResourceFragmentDoc}
`
export const UpdateMyProfileEdit = gql`
  mutation UpdateMyProfileEdit($updateUserProfileInputs: UpdateProfileInput!) {
    updateUserProfile(inputs: $updateUserProfileInputs) {
      ...UserConnection
    }
  }
  ${UserConnectionFragmentDoc}
  ${ResourceFragmentDoc}
`
export const MyRanking = gql`
  query MyRanking($periodType: RankingPeriodType!, $pageInput: PageInput) {
    organizationArtistRankings(pageInput: $pageInput) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ... on Ranking {
            ...RankingBaseConnection
            latestRankingPeriod(periodType: $periodType) {
              ...RankingPeriodBaseConnection
              myRanking {
                ...RankingUserWithoutIdConnection
              }
            }
          }
        }
      }
    }
  }
  ${RankingBaseConnectionFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${RankingPeriodBaseConnectionFragmentDoc}
  ${RankingUserWithoutIdConnectionFragmentDoc}
`
export const MyTickets = gql`
  query MyTickets(
    $filter: EventHeldStatus!
    $pageInput: PageInput
    $skipUser: Boolean!
  ) {
    myPreSaleEvents(filter: $filter, pageInput: $pageInput) {
      ...MyPreSaleEventConnection
    }
    user @skip(if: $skipUser) {
      id
      hasWaitingPaymentItems
      hasUnappliedDeliveryItems
      hasManagementTask @client
      unreadNotificationCount
      isMembershipPersonalInformationRequired
    }
  }
  ${MyPreSaleEventConnectionFragmentDoc}
  ${EventTicketCardFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
`
export const MyTrade = gql`
  query MyTrade(
    $status: [TradeCandidateNegotiationStatus!]
    $pageInput: PageInput
  ) {
    tradeMyCandidates(status: $status, pageInput: $pageInput) {
      ...TradeMyCandidatesConnection
    }
  }
  ${TradeMyCandidatesConnectionFragmentDoc}
  ${TradeBoxFragmentDoc}
`
export const FetchNewMyTrade = gql`
  query FetchNewMyTrade(
    $status: [TradeCandidateNegotiationStatus!]
    $pageInput: PageInput
  ) {
    tradeMyCandidates(status: $status, pageInput: $pageInput) {
      ...TradeMyCandidatesConnection
    }
  }
  ${TradeMyCandidatesConnectionFragmentDoc}
  ${TradeBoxFragmentDoc}
`
export const MyRequests = gql`
  query MyRequests($status: [TradeNegotiationStatus!], $pageInput: PageInput) {
    tradeNegotiationsMyRequests(status: $status, pageInput: $pageInput) {
      ...TradeNegotiationsMyRequestsConnection
    }
  }
  ${TradeNegotiationsMyRequestsConnectionFragmentDoc}
  ${TradeBoxFragmentDoc}
`
export const MyTradeDetail = gql`
  query MyTradeDetail($tradeNegotiationId: ID!, $pageInput: PageInput) {
    user {
      id
      displayName
      avatar {
        uri
        smallUri
      }
    }
    tradeNegotiation(id: $tradeNegotiationId) {
      expiredAt
      formattedExpiredAt @client
      ...TradeBox
      comments(pageInput: $pageInput) {
        ...CommentsConnection
      }
    }
  }
  ${TradeBoxFragmentDoc}
  ${CommentsConnectionFragmentDoc}
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const CreateTradeComment = gql`
  mutation CreateTradeComment(
    $createTradeCommentId: String!
    $inputs: CommunityCommentInput!
  ) {
    createTradeComment(id: $createTradeCommentId, inputs: $inputs) {
      ...CommentBox
    }
  }
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const DeclineTradeRequest = gql`
  mutation DeclineTradeRequest($inputs: DeclineTradeRequestInput!) {
    declineTradeRequest(inputs: $inputs) {
      id
    }
  }
`
export const AcceptTradeRequest = gql`
  mutation AcceptTradeRequest($inputs: AcceptTradeRequestInput!) {
    acceptTradeRequest(inputs: $inputs) {
      id
    }
  }
`
export const CancelTradeRequest = gql`
  mutation CancelTradeRequest($inputs: CancelTradeRequestInput!) {
    cancelTradeRequest(inputs: $inputs) {
      ...TradeBox
    }
  }
  ${TradeBoxFragmentDoc}
`
export const NewStoreItems = gql`
  query NewStoreItems(
    $artistId: ID!
    $filters: ItemsFilterInput
    $pageInput: PageInputDate
    $platform: String!
    $purchasablePrepaidPointPageInput: PageInput
  ) {
    items(artistId: $artistId, filters: $filters, pageInput: $pageInput) {
      ...NewStoreItemConnection
    }
    prepaidPointBalance {
      prepaidPointId
      balance
      formattedPrepaidPointBarance @client
    }
    purchasablePrepaidPoints(
      platform: $platform
      pageInput: $purchasablePrepaidPointPageInput
    ) {
      ...PurchasablePrepaidPointConnection
    }
    paymentSource {
      ...PrepaidPointsPaymentSource
    }
  }
  ${NewStoreItemConnectionFragmentDoc}
  ${MagazineItemFragmentDoc}
  ${PurchasablePrepaidPointConnectionFragmentDoc}
  ${PurchasablePrepaidPointDetailFragmentDoc}
  ${PrepaidPointsPaymentSourceFragmentDoc}
`
export const NftToken = gql`
  query NftToken($id: ID!) {
    user {
      id
      cryptoWallet {
        address
      }
    }
    portfolio(id: $id) {
      id
      item {
        id
        title
        nft {
          chains {
            chainName
            royalty
          }
        }
      }
      serials {
        serialId
        formattedSerialNumber
        tokenId
        nftToken {
          ...NftTokenDetail
        }
      }
    }
  }
  ${NftTokenDetailFragmentDoc}
`
export const MintItemNft = gql`
  mutation MintItemNft($inputs: MintItemNftInput!) {
    mintItemNft(inputs: $inputs) {
      id
      chain
      createdAt
      formattedCreatedAt @client
      ownedByUserAddress
      metadata {
        item {
          item {
            id
            title
            issuedArtist {
              id
              displayName
              avatar {
                uri
              }
            }
            nft {
              chains {
                chainName
              }
            }
          }
          itemSerial {
            id
            formattedSerialNumber
          }
        }
      }
      tokenId
      tokenURI
      status
    }
  }
`
export const NotificationDetail = gql`
  query NotificationDetail($notificationId: ID!) {
    organization {
      id
      notification(id: $notificationId) {
        ...NotificationBox
      }
    }
  }
  ${NotificationBoxFragmentDoc}
`
export const Notifications = gql`
  query Notifications(
    $artistId: ID
    $notificationsPageInput: PageInputDate
    $isStandalone: Boolean!
  ) {
    artist(id: $artistId) @include(if: $isStandalone) {
      ...ArtistBottomTab
      currentLive {
        id
      }
    }
    organization {
      id
      notifications(pageInput: $notificationsPageInput) {
        ...NotificationsConection
      }
    }
    user {
      ...CurrentUser
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${NotificationsConectionFragmentDoc}
  ${NotificationBoxFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const FetchNewNotifications = gql`
  query FetchNewNotifications($artistId: ID, $pageInput: PageInputDate) {
    artist(id: $artistId) {
      ...ArtistBottomTab
      currentLive {
        id
      }
    }
    organization {
      id
      notifications(pageInput: $pageInput) {
        ...NotificationsConection
      }
    }
  }
  ${ArtistBottomTabFragmentDoc}
  ${NotificationsConectionFragmentDoc}
  ${NotificationBoxFragmentDoc}
`
export const UpdateLastNotificationReadAt = gql`
  mutation updateLastNotificationReadAt($readAt: Date!) {
    updateLastNotificationReadAt(readAt: $readAt)
  }
`
export const OfficialInformation = gql`
  query OfficialInformation($pageInput: PageInput) {
    organizationOfficialNotifications(pageInput: $pageInput) {
      ...OrganizationNotificationConnection
    }
  }
  ${OrganizationNotificationConnectionFragmentDoc}
  ${OrganizationNotificationBoxFragmentDoc}
`
export const OfficialInformationDetail = gql`
  query OfficialInformationDetail($notificationId: ID!) {
    organizationOfficialNotification(id: $notificationId) {
      ...OrganizationNotificationBox
    }
  }
  ${OrganizationNotificationBoxFragmentDoc}
`
export const OfficialInformationDetailArtist = gql`
  query OfficialInformationDetailArtist($artistId: ID!) {
    artist(id: $artistId) {
      displayName
      avatar {
        uri
        smallUri
      }
    }
  }
`
export const Portfolio = gql`
  query portfolio(
    $id: ID!
    $platform: String!
    $pageInput: PageInput
    $skuId: String
  ) {
    portfolio(id: $id) {
      ...PortfolioSku
    }
    purchasablePrepaidPoints(platform: $platform, pageInput: $pageInput) {
      ...PurchasablePrepaidPointConnection
    }
    paymentSource {
      ...PrepaidPointsPaymentSource
    }
    prepaidPointBalance {
      prepaidPointId
      balance
    }
    user {
      id
      customProfile {
        ...CustomProfileConnection
      }
    }
  }
  ${PortfolioSkuFragmentDoc}
  ${EventBoxFragmentDoc}
  ${EventBoxCommonFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
  ${CollectionItemDetailFragmentDoc}
  ${ThumbnailResourceFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${ResourceFragmentDoc}
  ${UserItemSerialDetailFragmentDoc}
  ${TicketStatusDetailFragmentDoc}
  ${PatronDetailFragmentDoc}
  ${SubscriptionDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
  ${PurchasablePrepaidPointConnectionFragmentDoc}
  ${PurchasablePrepaidPointDetailFragmentDoc}
  ${PrepaidPointsPaymentSourceFragmentDoc}
  ${CustomProfileConnectionFragmentDoc}
`
export const ProtectUserItem = gql`
  mutation ProtectUserItem($inputs: ProtectUserItemInput!, $skuId: String) {
    protectUserItem(inputs: $inputs) {
      ...PortfolioConnection
    }
  }
  ${PortfolioConnectionFragmentDoc}
  ${ItemDescriptionFragmentDoc}
  ${EventBoxFragmentDoc}
  ${EventBoxCommonFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
  ${CollectionItemDetailFragmentDoc}
  ${ThumbnailResourceFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${ResourceFragmentDoc}
  ${UserItemSerialDetailFragmentDoc}
  ${TicketStatusDetailFragmentDoc}
`
export const UseTickets = gql`
  mutation UseTickets($inputs: UseTicketsInput!) {
    useTickets(inputs: $inputs) {
      id
      obtainedDate
      ticketStatus {
        ...TicketStatusDetail
      }
    }
  }
  ${TicketStatusDetailFragmentDoc}
`
export const ViewCollectionItem = gql`
  mutation ViewCollectionItem($inputs: ViewCollectionItemInput!) {
    viewCollectionItem(inputs: $inputs) {
      id
      userId
      latestItemId
      collectionId
      viewCount
    }
  }
`
export const GenerateWallpaperPrintQrcode = gql`
  mutation GenerateWallpaperPrintQrcode(
    $inputs: GenerateWallpaperPrintQrcodeInput!
  ) {
    generateWallpaperPrintQrcode(inputs: $inputs) {
      id
      ticketStatus {
        ...TicketStatusDetail
      }
      formattedAssignedNumber
      sku {
        id
        unitName
      }
      metadata {
        ticket {
          fullName
        }
        wallpaper {
          print {
            expiredAt
            contentsCode
            qrcode {
              uri
            }
          }
        }
      }
    }
  }
  ${TicketStatusDetailFragmentDoc}
`
export const PortfolioItems = gql`
  query PortfolioItems(
    $type: ItemType
    $pageInput: PageInput
    $option: PortfoliosOptionInput
  ) {
    user {
      id
      organizationPortfolios(
        type: $type
        pageInput: $pageInput
        option: $option
      ) {
        ...PortfolioItemsConnection
      }
    }
  }
  ${PortfolioItemsConnectionFragmentDoc}
  ${PortfolioItemsDetailFragmentDoc}
`
export const FetchNewPortfolioItems = gql`
  query FetchNewPortfolioItems($type: ItemType, $pageInput: PageInput) {
    user {
      id
      organizationPortfolios(type: $type, pageInput: $pageInput) {
        ...PortfolioItemsConnection
      }
    }
  }
  ${PortfolioItemsConnectionFragmentDoc}
  ${PortfolioItemsDetailFragmentDoc}
`
export const CreatePaymentMethod = gql`
  mutation createPaymentMethod($createPaymentMethodToken: String!) {
    createPaymentMethod(token: $createPaymentMethodToken) {
      ...paymentCreateDetail
      ...requiresActionDetail
    }
  }
  ${PaymentCreateDetailFragmentDoc}
  ${RequiresActionDetailFragmentDoc}
`
export const PaymentDetail = gql`
  query PaymentDetail {
    paymentSource {
      ...PaymentDetail
    }
  }
  ${PaymentDetailFragmentDoc}
`
export const DeletePaymentSource = gql`
  mutation DeletePaymentSource {
    deletePaymentSource {
      ...PaymentDetail
    }
  }
  ${PaymentDetailFragmentDoc}
`
export const StoreItemUnsettledDetail = gql`
  query StoreItemUnsettledDetail($pendingPurchaseId: ID!) {
    pendingPurchaseItem(pendingPurchaseId: $pendingPurchaseId) {
      ...PendingPurchaseItemDetail
    }
    user {
      id
      fullName
      phoneNumber
      formattedPhoneNumber @client
      isPhoneNumberVerified
      ...UserAddress
    }
    prepaidPointBalance {
      lastUpdatedAt
      prepaidPointId
      balance
      formattedPrepaidPointBarance @client
    }
  }
  ${PendingPurchaseItemDetailFragmentDoc}
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${PendingPurchaseItemSkuDetailFragmentDoc}
  ${ItemBoxFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
  ${UserAddressFragmentDoc}
`
export const PaymentStatuses = gql`
  query PaymentStatuses($pageInput: PageInputDate) {
    pendingPurchaseItems(pageInput: $pageInput) {
      ...PendingPurchaseItemsConnection
    }
  }
  ${PendingPurchaseItemsConnectionFragmentDoc}
  ${PendingPurchaseItemDetailFragmentDoc}
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${PendingPurchaseItemSkuDetailFragmentDoc}
  ${ItemBoxFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const PersonalInfo = gql`
  query personalInfo {
    user {
      ...PersonalInfo
    }
    paymentSource {
      ...PaymentDetail
    }
  }
  ${PersonalInfoFragmentDoc}
  ${UserAddressFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const UpdatePersonalInformationSettings = gql`
  mutation updatePersonalInformationSettings($inputs: UpdateProfileInput!) {
    updateUserProfile(inputs: $inputs) {
      ...PersonalInfo
    }
  }
  ${PersonalInfoFragmentDoc}
  ${UserAddressFragmentDoc}
`
export const PostDetail = gql`
  query PostDetail($postId: ID!, $pageInput: PageInput) {
    post(id: $postId) {
      ...PostBox
      replies(pageInput: $pageInput) {
        ...RepliesConnection
      }
    }
    user {
      ...CurrentUser
    }
  }
  ${PostBoxFragmentDoc}
  ${RepliesConnectionFragmentDoc}
  ${ReplyBoxFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const LikePost = gql`
  mutation LikePost($likePostPostId: ID!) {
    likePost(postId: $likePostPostId) {
      id
      likeCount
      isLike
    }
  }
`
export const UnlikePost = gql`
  mutation UnlikePost($unlikePostPostId: ID!) {
    unlikePost(postId: $unlikePostPostId) {
      id
      likeCount
      isLike
    }
  }
`
export const ReplyPost = gql`
  mutation ReplyPost($replyPostInputs: ReplyPostInput!) {
    replyPost(inputs: $replyPostInputs) {
      ...ReplyBox
    }
  }
  ${ReplyBoxFragmentDoc}
`
export const DeletePostReply = gql`
  mutation DeletePostReply($deletePostReplyId: ID!) {
    deletePostReply(id: $deletePostReplyId) {
      ...ReplyBox
    }
  }
  ${ReplyBoxFragmentDoc}
`
export const BlockUser = gql`
  mutation BlockUser($id: ID!) {
    blockUser(id: $id) {
      id
      accountId
      formattedAccountId @client
      blockedUsers
    }
  }
`
export const LikePostReply = gql`
  mutation likePostReply($id: ID!) {
    likePostReply(id: $id) {
      id
      isLike
      likeCount
    }
  }
`
export const UnlikePostReply = gql`
  mutation unlikePostReply($id: ID!) {
    unlikePostReply(id: $id) {
      id
      isLike
      likeCount
    }
  }
`
export const VideoDetail = gql`
  query VideoDetail($postId: ID!) {
    post(id: $postId) {
      ...PostBox
    }
  }
  ${PostBoxFragmentDoc}
`
export const ArtistPostVideosToCurrentVideo = gql`
  query ArtistPostVideosToCurrentVideo(
    $artistId: ID!
    $pageInput: PageInputDate
    $resourceType: PostType
    $categoryId: String
  ) {
    artist(id: $artistId) {
      id
      displayName
      posts(
        pageInput: $pageInput
        resourceType: $resourceType
        categoryId: $categoryId
      ) {
        ...ArtistPostVideos
      }
      currentLive {
        id
      }
    }
    user {
      ...CurrentUser
    }
  }
  ${ArtistPostVideosFragmentDoc}
  ${ArtistPostVideoFragmentDoc}
  ${CurrentUserFragmentDoc}
  ${MemberArtistFragmentDoc}
  ${FollowArtistsFragmentDoc}
`
export const FetchNewArtistPostVideosToCurrentVideo = gql`
  query FetchNewArtistPostVideosToCurrentVideo(
    $artistId: ID!
    $pageInput: PageInputDate
    $resourceType: PostType
    $categoryId: String
  ) {
    artist(id: $artistId) {
      id
      posts(
        pageInput: $pageInput
        resourceType: $resourceType
        categoryId: $categoryId
      ) {
        ...ArtistPosts
      }
      currentLive {
        id
      }
    }
  }
  ${ArtistPostsFragmentDoc}
  ${PostBoxFragmentDoc}
`
export const PurchasablePrepaidPoints = gql`
  query purchasablePrepaidPoints($platform: String!, $pageInput: PageInput) {
    prepaidPointBalance {
      prepaidPointId
      balance
    }
    purchasablePrepaidPoints(platform: $platform, pageInput: $pageInput) {
      ...PurchasablePrepaidPointConnection
    }
    paymentSource {
      ...PrepaidPointsPaymentSource
    }
  }
  ${PurchasablePrepaidPointConnectionFragmentDoc}
  ${PurchasablePrepaidPointDetailFragmentDoc}
  ${PrepaidPointsPaymentSourceFragmentDoc}
`
export const ChargePrepaidPoint = gql`
  mutation ChargePrepaidPoint($inputs: ChargePrepaidPointInput!) {
    chargePrepaidPoint(inputs: $inputs) {
      ...PrepaidPointChargeResult
    }
  }
  ${PrepaidPointChargeResultFragmentDoc}
`
export const ChargePrepaidPointByIapMutation = gql`
  mutation ChargePrepaidPointByIapMutation(
    $inputs: ChargePrepaidPointIapInput!
  ) {
    chargePrepaidPointByIap(inputs: $inputs) {
      ...PrepaidPointChargeResult
    }
  }
  ${PrepaidPointChargeResultFragmentDoc}
`
export const PrepaidPointOrders = gql`
  query PrepaidPointOrders($pageInput: PageInput) {
    prepaidPointTransactions(pageInput: $pageInput) {
      edges {
        node {
          ...PrepaidPointBox
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PrepaidPointBoxFragmentDoc}
`
export const PrivacySettings = gql`
  query PrivacySettings {
    user {
      id
      settings {
        privacy {
          item
        }
      }
    }
  }
`
export const UpdateUserPrivacySettings = gql`
  mutation UpdateUserPrivacySettings($inputs: UserPrivacySettingsInput!) {
    updateUserPrivacySettings(inputs: $inputs) {
      id
      settings {
        privacy {
          item
        }
      }
    }
  }
`
export const GachaTicketSerial = gql`
  query GachaTicketSerial($id: ID!, $serialId: ID!) {
    user {
      id
      gachaTicket(id: $id) {
        available
      }
    }
    gachaTicket(id: $id) {
      ...gachaTicket
      serial(id: $serialId) {
        ...gachaTicketSerial
      }
    }
  }
  ${GachaTicketFragmentDoc}
  ${GachaTicketSerialFragmentDoc}
`
export const ObtainGachaTicketSerial = gql`
  mutation ObtainGachaTicketSerial($id: String!, $serialId: String!) {
    obtainGachaTicketSerial(gachaTicketId: $id, serialId: $serialId) {
      ...gachaTicketOrder
    }
  }
  ${GachaTicketOrderFragmentDoc}
`
export const ObtainSerial = gql`
  mutation ObtainSerial($itemId: ID!, $serialId: ID!) {
    obtainSerial(itemId: $itemId, serialId: $serialId) {
      id
      isPurchased
      serial(id: $serialId) {
        id
        skuId
        authentication {
          authenticatedAt
        }
      }
    }
  }
`
export const QRcodeGetItem = gql`
  query QRcodeGetItem($itemId: ID!, $serialId: ID!, $skuId: String) {
    item(id: $itemId) {
      ...ItemDetailBox
      serial(id: $serialId) {
        id
        authentication {
          authenticatedAt
        }
        skuId
      }
    }
  }
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const QRcodeGetItemMulti = gql`
  query QRcodeGetItemMulti($id: ID!) {
    multiItemSerials(id: $id) {
      ...MultiItemSerialDetail
    }
  }
  ${MultiItemSerialDetailFragmentDoc}
  ${ItemSerialCountDetailFragmentDoc}
`
export const ObtainMultiItemSerials = gql`
  mutation obtainMultiItemSerials($id: ID!) {
    obtainMultiItemSerials(id: $id) {
      ...MultiItemSerialDetail
    }
  }
  ${MultiItemSerialDetailFragmentDoc}
  ${ItemSerialCountDetailFragmentDoc}
`
export const RecentViewedItems = gql`
  query RecentViewedItems($pageInput: PageInput, $artistId: String) {
    recentlyStoreViewedItems(pageInput: $pageInput, artistId: $artistId) {
      ...RecentViewedItemConnection
    }
  }
  ${RecentViewedItemConnectionFragmentDoc}
  ${MagazineItemFragmentDoc}
`
export const RecommendCollections = gql`
  query RecommendCollections($pageInput: PageInput, $artistId: String) {
    recentlyViewedCollections(pageInput: $pageInput, artistId: $artistId) {
      ...RecommendCollectionConnection
    }
  }
  ${RecommendCollectionConnectionFragmentDoc}
  ${RecommendCollectionFragmentDoc}
`
export const ThreadReplyDetail = gql`
  query ThreadReplyDetail(
    $id: ID!
    $commentId: ID!
    $pageInput: PageInputDate
  ) {
    artistThreadChannel(id: $id) {
      id
      artistId
    }
    artistThreadChannelComment(id: $id, commentId: $commentId) {
      ... on Comment {
        ...CommentBoxWithoutReplies
        replies(pageInput: $pageInput) {
          edges {
            node {
              ... on Comment {
                ...CommentBoxWithoutReplies
                replies {
                  edges {
                    node {
                      ... on Comment {
                        ...CommentBoxWithoutReplies
                      }
                    }
                  }
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
    user {
      id
      accountId
      formattedAccountId @client
      email
      avatar {
        uri
      }
    }
  }
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const MissionReplyDetail = gql`
  query MissionReplyDetail(
    $id: ID!
    $commentId: ID!
    $pageInput: PageInputDate
  ) {
    mission(id: $id) {
      id
      artistId
    }
    missionComment(id: $id, commentId: $commentId) {
      ... on Comment {
        ...CommentBoxWithoutReplies
        replies(pageInput: $pageInput) {
          edges {
            node {
              ... on Comment {
                ...CommentBoxWithoutReplies
                replies {
                  edges {
                    node {
                      ... on Comment {
                        ...CommentBoxWithoutReplies
                      }
                    }
                  }
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
    user {
      id
      accountId
      formattedAccountId @client
      email
      avatar {
        uri
      }
    }
  }
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const ItemReplyDetail = gql`
  query ItemReplyDetail($id: ID!, $commentId: ID!, $pageInput: PageInputDate) {
    item(id: $id) {
      id
      issuedArtist {
        id
      }
    }
    itemComment(id: $id, commentId: $commentId) {
      ... on Comment {
        ...CommentBoxWithoutReplies
        replies(pageInput: $pageInput) {
          edges {
            node {
              ... on Comment {
                ...CommentBoxWithoutReplies
                replies {
                  edges {
                    node {
                      ... on Comment {
                        ...CommentBoxWithoutReplies
                      }
                    }
                  }
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
    user {
      id
      accountId
      formattedAccountId @client
      email
      avatar {
        uri
      }
    }
  }
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const ResaleApplicationCreate = gql`
  query ResaleApplicationCreate($id: ID!, $skuId: String) {
    item(id: $id) {
      ...ItemDetailBox
      preSale {
        id
        lottery {
          purchasableCount
        }
        firstComeFirstServed {
          purchasableCount
        }
      }
    }
    paymentSource {
      ...PaymentDetail
    }
  }
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const ResaleApplicationCreateCompletion = gql`
  query ResaleApplicationCreateCompletion(
    $raffleId: ID!
    $itemId: String!
    $skuId: String
  ) {
    itemResaleRaffleUser(raffleId: $raffleId, itemId: $itemId) {
      ...ItemResaleRaffleUserDetail
    }
  }
  ${ItemResaleRaffleUserDetailFragmentDoc}
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const ResaleApplicationCreateConfirmation = gql`
  query ResaleApplicationCreateConfirmation($id: ID!, $skuId: String) {
    item(id: $id) {
      ...ItemDetailBox
    }
    paymentSource {
      ...PaymentDetail
    }
  }
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const ApplyResaleItemRaffle = gql`
  mutation ApplyResaleItemRaffle($inputs: ApplyResaleItemRaffleInput!) {
    applyResaleItemRaffle(inputs: $inputs) {
      id
    }
  }
`
export const ResaleApplicationCreatePrecaution = gql`
  query ResaleApplicationCreatePrecaution($id: ID!, $skuId: String) {
    item(id: $id) {
      ...ItemDetailBox
    }
  }
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const ResaleApplicationDetail = gql`
  query ResaleApplicationDetail(
    $id: ID!
    $itemId: String!
    $userId: String
    $skuId: String
  ) {
    item(id: $id) {
      ...ItemDetailBox
    }
    userActiveResaleItemRaffle(
      itemId: $itemId
      skuId: $skuId
      userId: $userId
    ) {
      ...ItemResaleRaffleUserDetail
    }
  }
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${ItemResaleRaffleUserDetailFragmentDoc}
`
export const ResaleCreate = gql`
  query ResaleCreate($id: ID!, $skuId: String) {
    item(id: $id) {
      ...ItemDetailBox
    }
    portfolio(id: $id) {
      ...PortfolioConnection
    }
  }
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${PortfolioConnectionFragmentDoc}
  ${ItemDescriptionFragmentDoc}
  ${EventBoxFragmentDoc}
  ${EventBoxCommonFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
  ${CollectionItemDetailFragmentDoc}
  ${ThumbnailResourceFragmentDoc}
  ${ResourceFragmentDoc}
  ${UserItemSerialDetailFragmentDoc}
  ${TicketStatusDetailFragmentDoc}
`
export const ResaleCreateConfirmation = gql`
  query ResaleCreateConfirmation($id: ID!, $skuId: String) {
    item(id: $id) {
      ...ItemDetailBox
    }
    portfolio(id: $id) {
      ...PortfolioConnection
    }
  }
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${PortfolioConnectionFragmentDoc}
  ${ItemDescriptionFragmentDoc}
  ${EventBoxFragmentDoc}
  ${EventBoxCommonFragmentDoc}
  ${EventPreSaleConnectionFragmentDoc}
  ${EventCollectionsBoxFragmentDoc}
  ${EventCollectionBoxFragmentDoc}
  ${CollectionBoxFragmentDoc}
  ${CartTransactionOrderDetailFragmentDoc}
  ${CollectionItemDetailFragmentDoc}
  ${ThumbnailResourceFragmentDoc}
  ${ResourceFragmentDoc}
  ${UserItemSerialDetailFragmentDoc}
  ${TicketStatusDetailFragmentDoc}
`
export const CreateResaleItem = gql`
  mutation CreateResaleItem($inputs: CreateResaleItemInput!) {
    createResaleItem(inputs: $inputs) {
      id
    }
  }
`
export const ResaleCreatePrecaution = gql`
  query ResaleCreatePrecaution($id: ID!, $skuId: String) {
    item(id: $id) {
      ...ItemDetailBox
    }
  }
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const ResaleDetail = gql`
  query ResaleDetail($id: ID!, $skuId: String) {
    resaleItem(id: $id) {
      ...ResaleItemBox
    }
    user {
      ...UserBankAccount
    }
  }
  ${ResaleItemBoxFragmentDoc}
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${UserBankAccountFragmentDoc}
`
export const CancelResaleItem = gql`
  mutation cancelResaleItem($inputs: CancelResaleItemInput!, $skuId: String) {
    cancelResaleItem(inputs: $inputs) {
      ...ResaleItemBox
    }
  }
  ${ResaleItemBoxFragmentDoc}
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const ResaleManagement = gql`
  query ResaleManagement(
    $pageInput: PageInput
    $status: [String!]
    $skuId: String
  ) {
    resaleItemsByUser(pageInput: $pageInput, status: $status) {
      ...ResaleConnection
    }
  }
  ${ResaleConnectionFragmentDoc}
  ${ResaleItemBoxFragmentDoc}
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const ItemResaleRaffleUsers = gql`
  query ItemResaleRaffleUsers(
    $pageInput: PageInput
    $status: [String!]!
    $skuId: String
  ) {
    itemResaleRaffleUsers(pageInput: $pageInput, status: $status) {
      ...ResaleRaffleConnection
    }
  }
  ${ResaleRaffleConnectionFragmentDoc}
  ${ResaleRaffleBoxFragmentDoc}
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const ResaleRaffleDetail = gql`
  query ResaleRaffleDetail($raffleId: ID!, $itemId: String!, $skuId: String) {
    itemResaleRaffleUser(raffleId: $raffleId, itemId: $itemId) {
      ...ItemResaleRaffleUserDetail
    }
  }
  ${ItemResaleRaffleUserDetailFragmentDoc}
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const CancelResaleItemRaffle = gql`
  mutation cancelResaleItemRaffle(
    $inputs: CancelResaleItemRaffleInput!
    $skuId: String
  ) {
    cancelResaleItemRaffle(inputs: $inputs) {
      ...ItemResaleRaffleUserDetail
    }
  }
  ${ItemResaleRaffleUserDetailFragmentDoc}
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const ResaleRaffleRepaymentCompletion = gql`
  query ResaleRaffleRepaymentCompletion(
    $raffleId: ID!
    $itemId: String!
    $skuId: String
  ) {
    itemResaleRaffleUser(raffleId: $raffleId, itemId: $itemId) {
      ...ItemResaleRaffleUserDetail
    }
    paymentSource {
      ...PaymentDetail
    }
  }
  ${ItemResaleRaffleUserDetailFragmentDoc}
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const ResaleRaffleRepaymentConfirmation = gql`
  query ResaleRaffleRepaymentConfirmation(
    $raffleId: ID!
    $itemId: String!
    $skuId: String
  ) {
    itemResaleRaffleUser(raffleId: $raffleId, itemId: $itemId) {
      ...ItemResaleRaffleUserDetail
    }
    paymentSource {
      ...PaymentDetail
    }
  }
  ${ItemResaleRaffleUserDetailFragmentDoc}
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const PurchaseResaleItem = gql`
  mutation purchaseResaleItem($inputs: PurchaseResaleItemInput!) {
    purchaseResaleItem(inputs: $inputs) {
      id
    }
  }
`
export const SmsVerification = gql`
  query SMSVerification {
    user {
      ...UserDeliveryDetail
    }
  }
  ${UserDeliveryDetailFragmentDoc}
`
export const PreSaleDetail = gql`
  query preSaleDetail($id: ID!, $clientId: String, $token: String) {
    preSale(id: $id, clientId: $clientId, token: $token) {
      id
      lottery {
        personalInformationRequirements
      }
      firstComeFirstServed {
        personalInformationRequirements
      }
    }
  }
`
export const VerifySmsCode = gql`
  mutation VerifySMSCode($code: String!) {
    verifySMSCode(code: $code) {
      verified
    }
  }
`
export const SerialNumberSelect = gql`
  query SerialNumberSelect($itemId: ID!, $userId: String, $skuId: String) {
    portfolio(id: $itemId, userId: $userId) {
      ...UserItemBox
    }
  }
  ${UserItemBoxFragmentDoc}
  ${UserInfoFragmentDoc}
`
export const UserTradableUserItems = gql`
  query UserTradableUserItems($pageInput: PageInput) {
    userTradableUserItems(pageInput: $pageInput) {
      ...UserTradableUserItemsConnection
    }
  }
  ${UserTradableUserItemsConnectionFragmentDoc}
`
export const ItemDetailSelect = gql`
  query ItemDetailSelect($id: ID!, $userId: String, $skuId: String) {
    portfolio(id: $id, userId: $userId) {
      id
      number
      type
      createdAt
      isSystemProtected
      isUserProtected
      item {
        ...TradeItemDetailSelectItemDetailBox
      }
      serials {
        id
        isProtected
        serialId
        isUsed
        itemId
        obtainedDate
        formattedObtainedDate @client
        formattedSerialNumber
      }
      user {
        ...UserInfo
      }
    }
  }
  ${TradeItemDetailSelectItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${UserInfoFragmentDoc}
`
export const CreateTradeRequest = gql`
  mutation CreateTradeRequest($inputs: CreateTradeRequestInput!) {
    createTradeRequest(inputs: $inputs) {
      ...TradeBox
    }
  }
  ${TradeBoxFragmentDoc}
`
export const Settings = gql`
  query Settings {
    user {
      id
      isMembershipPersonalInformationRequired
    }
  }
`
export const VerifyCode = gql`
  mutation VerifyCode($email: String!, $code: String!) {
    verifyCode(email: $email, code: $code) {
      id
      verified
    }
  }
`
export const SendVerificationEmail = gql`
  mutation SendVerificationEmail($email: String!) {
    sendVerificationEmail(email: $email) {
      id
      email
    }
  }
`
export const SignupAsUser = gql`
  mutation SignupAsUser($inputs: SignupAsUserInput!) {
    signupAsUser(inputs: $inputs) {
      ...SignUpUser
    }
  }
  ${SignUpUserFragmentDoc}
`
export const UpdateProfile = gql`
  mutation updateProfile($inputs: UpdateProfileInput!) {
    updateUserProfile(inputs: $inputs) {
      ...UserProfile
    }
  }
  ${UserProfileFragmentDoc}
`
export const LinkAnonymousAccount = gql`
  mutation linkAnonymousAccount($inputs: LinkAnonymousAccountInput!) {
    linkAnonymousAccount(inputs: $inputs) {
      id
    }
  }
`
export const ValidateAccount = gql`
  query ValidateAccount($input: ValidateAccountInput) {
    validateAccount(inputs: $input) {
      accountId
      email
    }
  }
`
export const UserCart = gql`
  query UserCart($id: ID!) {
    userCart(id: $id) {
      ...StoreCardDetail
    }
    user {
      id
      fullName
      phoneNumber
      formattedPhoneNumber @client
      isPhoneNumberVerified
      ...UserAddress
    }
  }
  ${StoreCardDetailFragmentDoc}
  ${UserAddressFragmentDoc}
`
export const ValidateCart = gql`
  query ValidateCart($inputs: ValidateCartInput!) {
    validateCart(inputs: $inputs) {
      id
    }
  }
`
export const AddToCart = gql`
  mutation AddToCart($inputs: AddToCartInput!) {
    addToCart(inputs: $inputs) {
      id
      totalCount
      totalPrice @client
      formattedTotalPrice @client
      items {
        count
        totalPrice @client
        formattedTotalPrice @client
        item {
          id
          price
          hideSalesNumber
        }
        skus {
          skuId
          count
          sku {
            id
            price
            formattedPrice @client
            unitName
            thumbnail {
              uri
            }
          }
        }
      }
    }
  }
`
export const RemoveFromCart = gql`
  mutation RemoveFromCart($inputs: RemoveFromCartInput!) {
    removeFromCart(inputs: $inputs) {
      id
      totalCount
      totalPrice @client
      formattedTotalPrice @client
      items {
        count
        totalPrice @client
        formattedTotalPrice @client
        item {
          id
          price
          hideSalesNumber
        }
        skus {
          skuId
          count
          sku {
            id
            price
            formattedPrice @client
            unitName
            thumbnail {
              uri
            }
          }
        }
      }
    }
  }
`
export const StoreCartList = gql`
  query StoreCartList($pageInput: PageInput) {
    userCarts(pageInput: $pageInput) {
      ...UserCartsConnection
    }
  }
  ${UserCartsConnectionFragmentDoc}
`
export const DeleteCart = gql`
  mutation DeleteCart($inputs: DeleteCartInput!) {
    deleteCart(inputs: $inputs) {
      id
    }
  }
`
export const StoreCollectionDetails = gql`
  query StoreCollectionDetails(
    $collectionId: ID!
    $collectionItemsLabel: String
    $collectionItemsFirstLabel: Boolean
    $pageInput: PageInput
    $userId: ID
  ) {
    user(id: $userId) {
      id
      displayName
      avatar {
        uri
        smallUri
      }
    }
    userCartCount
    userCarts {
      ...UserCartsConnection
    }
    collection(id: $collectionId) {
      id
      title
      artistId
      isEventPickup
      artist {
        id
        displayName
      }
      isPublishOnStore
      thumbnail {
        uri
      }
      userCollection(userId: $userId) {
        ogp {
          uri
          smallUri
        }
        itemOwnedCount
        totalOwnedCount
        totalOwnedItemCount @client
        ownedItems {
          itemId
          ownedCount
        }
        id
        userId
      }
      itemCount
      description
      labels {
        name
        id
      }
      collectionItems(
        label: $collectionItemsLabel
        firstLabel: $collectionItemsFirstLabel
        pageInput: $pageInput
      ) {
        ...StoreCollectionItemsConnection
      }
    }
  }
  ${UserCartsConnectionFragmentDoc}
  ${StoreCollectionItemsConnectionFragmentDoc}
`
export const StoreCollectionDetailsForGuest = gql`
  query StoreCollectionDetailsForGuest(
    $collectionId: ID!
    $collectionItemsLabel: String
    $collectionItemsFirstLabel: Boolean
    $pageInput: PageInput
  ) {
    collection(id: $collectionId) {
      id
      title
      artistId
      isEventPickup
      artist {
        id
        displayName
      }
      isPublishOnStore
      thumbnail {
        uri
      }
      itemCount
      description
      labels {
        name
        id
      }
      collectionItems(
        label: $collectionItemsLabel
        firstLabel: $collectionItemsFirstLabel
        pageInput: $pageInput
      ) {
        ...StoreCollectionItemsConnection
      }
    }
  }
  ${StoreCollectionItemsConnectionFragmentDoc}
`
export const StoreCollectionsSelect = gql`
  query StoreCollectionsSelect($artistId: String!, $pageInput: PageInput) {
    prepaidPointBalance {
      balance
      prepaidPointId
      formattedPrepaidPointBarance @client
    }
    storeCollections(artistId: $artistId, pageInput: $pageInput) {
      edges {
        node {
          ... on Collection {
            ...ArtistStoreCollectionBox
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ArtistStoreCollectionBoxFragmentDoc}
`
export const StoreItemCompleteFree = gql`
  query StoreItemCompleteFree($id: ID!) {
    portfolio(id: $id) {
      id
      number
      item {
        ...StoreItemCompleteDetail
      }
    }
  }
  ${StoreItemCompleteDetailFragmentDoc}
`
export const StoreItemPurchaseComplete = gql`
  query StoreItemPurchaseComplete($userCartTransactionOrderId: ID!) {
    userCartTransactionOrder(id: $userCartTransactionOrderId) {
      ...UserCartTransactionOrder
    }
  }
  ${UserCartTransactionOrderFragmentDoc}
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${ItemBoxFragmentDoc}
  ${PendingPurchaseItemDetailFragmentDoc}
  ${PendingPurchaseItemSkuDetailFragmentDoc}
  ${ArtistMembershipSubscriptionDetailFragmentDoc}
  ${MembershipSubscriptionProductDetailFragmentDoc}
  ${MembershipSubscriptionPlanDetailFragmentDoc}
`
export const CheckoutCart = gql`
  mutation checkoutCart($inputs: CheckoutCartInput!) {
    checkoutCart(inputs: $inputs) {
      id
      isKonbini
    }
  }
`
export const StoreItemPurchaseConfirmation = gql`
  query StoreItemPurchaseConfirmation($cartId: ID!, $isKonbini: Boolean) {
    userCart(id: $cartId) {
      ...UserCartStoreItemPurchaseConfirmation
      checkoutFees(isKonbini: $isKonbini) {
        totalFee
        shipping {
          ...FeeDetail
        }
        collections {
          ...CollectionFeeDetail
        }
        konbini {
          ...FeeDetail
        }
      }
    }
    paymentSource {
      ...PaymentDetail
    }
    user {
      id
      fullName
      phoneNumber
      formattedPhoneNumber @client
      isPhoneNumberVerified
      ...UserAddress
    }
    prepaidPointBalance {
      lastUpdatedAt
      prepaidPointId
      balance
      formattedPrepaidPointBarance @client
    }
  }
  ${UserCartStoreItemPurchaseConfirmationFragmentDoc}
  ${UserCartStoreItemConfirmationEventCollectionFragmentDoc}
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
  ${UserAddressFragmentDoc}
`
export const StoreItemPurchaseProceed = gql`
  query StoreItemPurchaseProceed(
    $cartId: ID!
    $platform: String!
    $purchasablePrepaidPointPageInput: PageInput
    $isKonbini: Boolean
  ) {
    userCart(id: $cartId) {
      ...UserCartStoreItemPurchaseProceed
      checkoutFees(isKonbini: $isKonbini) {
        totalFee
        shipping {
          ...FeeDetail
        }
        collections {
          ...CollectionFeeDetail
        }
        konbini {
          ...FeeDetail
        }
      }
    }
    paymentSource {
      ...PaymentDetail
    }
    user {
      id
      fullName
      phoneNumber
      formattedPhoneNumber @client
      isPhoneNumberVerified
      ...UserAddress
    }
    prepaidPointBalance {
      lastUpdatedAt
      prepaidPointId
      balance
      formattedPrepaidPointBarance @client
    }
    purchasablePrepaidPoints(
      platform: $platform
      pageInput: $purchasablePrepaidPointPageInput
    ) {
      ...PurchasablePrepaidPointConnection
    }
  }
  ${UserCartStoreItemPurchaseProceedFragmentDoc}
  ${UserCartStoreItemProceedEventCollectionFragmentDoc}
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${PaymentDetailFragmentDoc}
  ${UserAddressFragmentDoc}
  ${PurchasablePrepaidPointConnectionFragmentDoc}
  ${PurchasablePrepaidPointDetailFragmentDoc}
`
export const StorePurchaseOrderDetail = gql`
  query StorePurchaseOrderDetail($id: ID!, $skuId: String) {
    userCartTransactionOrder(id: $id) {
      ...StorePurchaseHistory
    }
    user {
      id
      fullName
      phoneNumber
      formattedPhoneNumber @client
      isPhoneNumberVerified
      ...UserAddress
    }
    paymentSource {
      ...PaymentDetail
    }
    prepaidPointBalance {
      lastUpdatedAt
      prepaidPointId
      balance
      formattedPrepaidPointBarance @client
    }
  }
  ${StorePurchaseHistoryFragmentDoc}
  ${FeeDetailFragmentDoc}
  ${CollectionFeeDetailFragmentDoc}
  ${StoreItemDetailFragmentDoc}
  ${StoreItemDetailGuestFragmentDoc}
  ${SkuTypeFragmentDoc}
  ${SkuTagFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${UserAddressFragmentDoc}
  ${PaymentDetailFragmentDoc}
`
export const StorePurchaseOrders = gql`
  query StorePurchaseOrders($pageInput: PageInput, $skuId: String) {
    userCartTransactionOrders(pageInput: $pageInput) {
      ...StorePurchaseOrderConnection
    }
  }
  ${StorePurchaseOrderConnectionFragmentDoc}
  ${StoreOrderListHistoryFragmentDoc}
`
export const StoreRequestProvidableItemSearch = gql`
  query StoreRequestProvidableItemSearch {
    prepaidPointBalance {
      prepaidPointId
      balance
      formattedPrepaidPointBarance @client
    }
  }
`
export const StoreUserInfo = gql`
  query storeUserInfo {
    user {
      id
      formattedPhoneNumber @client
      ...UserDeliveryInfo
    }
  }
  ${UserDeliveryInfoFragmentDoc}
`
export const UpdateStoreUserInfo = gql`
  mutation updateStoreUserInfo(
    $updateUserDeliveryInfoInputs: UserDeliveryInput!
  ) {
    updateUserDeliveryInfo(inputs: $updateUserDeliveryInfoInputs) {
      ...UserDeliveryInfo
    }
  }
  ${UserDeliveryInfoFragmentDoc}
`
export const ThreadCreate = gql`
  query ThreadCreate($id: ID) {
    artist(id: $id) {
      patron {
        id
      }
      id
      displayName
      avatar {
        uri
      }
    }
    user {
      id
      avatar {
        uri
      }
      displayName
    }
  }
`
export const CreateArtistThreadChannel = gql`
  mutation CreateArtistThreadChannel($inputs: CreateArtistThreadInput!) {
    createArtistThreadChannel(inputs: $inputs) {
      ...ThreadBox
    }
  }
  ${ThreadBoxFragmentDoc}
`
export const ThreadDetail = gql`
  query ThreadDetail($artistThreadChannelId: ID!, $pageInput: PageInput) {
    artistThreadChannel(id: $artistThreadChannelId) {
      ...ThreadBox
      comments(pageInput: $pageInput) {
        ...CommentsConnection
      }
    }
    user {
      id
      accountId
      formattedAccountId @client
      email
      avatar {
        uri
      }
    }
  }
  ${ThreadBoxFragmentDoc}
  ${CommentsConnectionFragmentDoc}
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const LikeArtistThreadChannel = gql`
  mutation LikeArtistThreadChannel($likeArtistThreadChannelId: ID!) {
    likeArtistThreadChannel(id: $likeArtistThreadChannelId) {
      id
      likeCount
      isLike
    }
  }
`
export const UnlikeArtistThreadChannel = gql`
  mutation UnlikeArtistThreadChannel($unlikeArtistThreadChannelId: ID!) {
    unlikeArtistThreadChannel(id: $unlikeArtistThreadChannelId) {
      id
      likeCount
      isLike
    }
  }
`
export const CreateArtistThreadComment = gql`
  mutation CreateArtistThreadComment(
    $id: String!
    $inputs: CommunityCommentInput!
  ) {
    createArtistThreadComment(id: $id, inputs: $inputs) {
      ...CommentBox
    }
  }
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const DeleteArtistThreadComment = gql`
  mutation DeleteArtistThreadComment($id: String!, $commentId: String!) {
    deleteArtistThreadComment(id: $id, commentId: $commentId) {
      ...CommentBox
    }
  }
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const ArchiveArtistThreadChannel = gql`
  mutation ArchiveArtistThreadChannel($archiveArtistThreadChannelId: ID!) {
    archiveArtistThreadChannel(id: $archiveArtistThreadChannelId) {
      ...ThreadBox
    }
  }
  ${ThreadBoxFragmentDoc}
`
export const LikeThreadComment = gql`
  mutation LikeThreadComment($inputs: CommunityCommentLikeUnlikeInput!) {
    likeArtistThreadChannelComment(inputs: $inputs) {
      id
      isLike
      likeCount
    }
  }
`
export const UnlikeThreadComment = gql`
  mutation UnlikeThreadComment($inputs: CommunityCommentLikeUnlikeInput!) {
    unlikeArtistThreadChannelComment(inputs: $inputs) {
      id
      isLike
      likeCount
    }
  }
`
export const UpdateThreadUnread = gql`
  mutation UpdateThreadUnread($inputs: UpdateUnreadInput!) {
    updateUserUnread(inputs: $inputs) {
      id
      lastReadAt
      parentId
      totalUnreadMentionCount
      unreadMentionCount
      unreadType
    }
  }
`
export const UpdateArtistThreadMuteStatus = gql`
  mutation UpdateArtistThreadMuteStatus($id: ID!) {
    updateArtistThreadMuteStatus(id: $id) {
      id
      isAllowNotification
    }
  }
`
export const ThreadEdit = gql`
  query ThreadEdit($artistThreadChannelId: ID!, $artistId: ID) {
    artistThreadChannel(id: $artistThreadChannelId) {
      ...ThreadBox
    }
    artist(id: $artistId) {
      id
      displayName
      avatar {
        uri
      }
      patron {
        id
      }
    }
    user {
      id
      avatar {
        uri
      }
      displayName
    }
  }
  ${ThreadBoxFragmentDoc}
`
export const UpdateArtistThreadChannel = gql`
  mutation UpdateArtistThreadChannel(
    $updateArtistThreadChannelId: ID!
    $inputs: UpdateArtistThreadInput!
  ) {
    updateArtistThreadChannel(
      id: $updateArtistThreadChannelId
      inputs: $inputs
    ) {
      ...ThreadBox
    }
  }
  ${ThreadBoxFragmentDoc}
`
export const TicketCharge = gql`
  query TicketCharge(
    $inputs: PurchasableGachaTicketInput
    $gachaTicketId: ID!
  ) {
    user {
      id
      gachaTicket(id: $gachaTicketId) {
        ...UserGachaTicketConnection
      }
    }
    purchasableGachaTickets(inputs: $inputs) {
      price
      displayCount
      count
      prepaidPoints
      formattedPrice @client
      formattedPrepaidPoint @client
    }
    gachaTicket(id: $gachaTicketId) {
      ...GachaTicketConnection
    }
  }
  ${UserGachaTicketConnectionFragmentDoc}
  ${GachaTicketConnectionFragmentDoc}
  ${GachaTicketThumbnailsBoxFragmentDoc}
`
export const TicketOrders = gql`
  query TicketOrders($pageInput: PageInput) {
    gachaTicketOrders(pageInput: $pageInput) {
      ...GachaTicketOrdersConnection
    }
  }
  ${GachaTicketOrdersConnectionFragmentDoc}
  ${GachaTicketOrderBoxFragmentDoc}
`
export const TicketDistributionLink = gql`
  query TicketDistributionLink($token: String!) {
    ticketDistributionLink(token: $token) {
      ...TicketShareConfirm
    }
  }
  ${TicketShareConfirmFragmentDoc}
`
export const ReceiveTicketDistribution = gql`
  mutation ReceiveTicketDistribution($inputs: ReceiveTicketDistributionInput!) {
    receiveTicketDistribution(inputs: $inputs) {
      id
      distributionId
    }
  }
`
export const TicketShareCopy = gql`
  query TicketShareCopy($id: ID!) {
    portfolio(id: $id) {
      serials {
        ...TicketShareCopyUserItemSerial
      }
      item {
        issuedArtist {
          id
        }
        metadata {
          ticket {
            isThroughTicket
          }
        }
      }
    }
  }
  ${TicketShareCopyUserItemSerialFragmentDoc}
`
export const RegenerateTicketDistributionLink = gql`
  mutation regenerateTicketDistributionLink(
    $inputs: RegenerateTicketDistributionLinkInput!
  ) {
    regenerateTicketDistributionLink(inputs: $inputs) {
      id
      distributionId
      expiredAt
      formattedExpiredAt @client
      token
    }
  }
`
export const CancelTicketDistribution = gql`
  mutation cancelTicketDistribution($inputs: CancelTicketDistributionInput!) {
    cancelTicketDistribution(inputs: $inputs) {
      id
      distributionId
      expiredAt
      formattedExpiredAt @client
      token
      serialId
    }
  }
`
export const TicketShareSelect = gql`
  query TicketShareSelect($id: ID!) {
    portfolio(id: $id) {
      item {
        metadata {
          ticket {
            assignedNumberType
            isThroughTicket
          }
        }
        issuedArtist {
          id
        }
      }
      serials {
        ...TicketShareSelectUserItemSerial
      }
    }
  }
  ${TicketShareSelectUserItemSerialFragmentDoc}
`
export const GenerateTicketDistributionLink = gql`
  mutation GenerateTicketDistributionLink(
    $inputs: GenerateTicketDistributionLinkInput!
  ) {
    generateTicketDistributionLink(inputs: $inputs) {
      id
    }
  }
`
export const ShortLink = gql`
  query shortLink($shortLinkId: ID!) {
    shortLink(id: $shortLinkId) {
      id
      redirectUrl
      shortLink
      createdAt
    }
  }
`
export const CreateShortLink = gql`
  mutation CreateShortLink($redirectUrl: String!) {
    createShortLink(redirectUrl: $redirectUrl) {
      createdAt
      id
      redirectUrl
      shortLink
    }
  }
`
export const UserItemDetail = gql`
  query UserItemDetail(
    $itemId: ID!
    $userId: String
    $uid: ID!
    $skuId: String
  ) {
    portfolio(id: $itemId, userId: $userId) {
      ...UserItemDetailBox
    }
    user(id: $uid) {
      id
      avatar {
        uri
        smallUri
      }
    }
    userLoggedIn: user {
      id
    }
  }
  ${UserItemDetailBoxFragmentDoc}
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
  ${TicketStatusDetailFragmentDoc}
`
export const UserItemDetailForGuest = gql`
  query UserItemDetailForGuest($id: ID!, $userId: ID!, $skuId: String) {
    item(id: $id) {
      ...ItemDetailBox
    }
    user(id: $userId) {
      id
      avatar {
        uri
        smallUri
      }
    }
  }
  ${ItemDetailBoxFragmentDoc}
  ${PurchasableUserDetailFragmentDoc}
`
export const HasItemUserItemDetail = gql`
  query HasItemUserItemDetail($itemId: ID!, $userId: String) {
    portfolio(id: $itemId, userId: $userId) {
      id
      serials {
        serial {
          skuId
        }
      }
    }
  }
`
export const UserOwnedItems = gql`
  query UserOwnedItems($type: ItemType, $pageInput: PageInput, $userId: ID) {
    user(id: $userId) {
      id
      displayName
      organizationPortfolios(type: $type, pageInput: $pageInput) {
        ...UserOwnedItemsConnection
      }
    }
  }
  ${UserOwnedItemsConnectionFragmentDoc}
  ${UserOwnedItemDetailFragmentDoc}
  ${UserOwnedItemDetailBoxFragmentDoc}
`
export const UserProfile = gql`
  query UserProfile(
    $id: ID
    $userId: String
    $pageInput: PageInput
    $pageInputCollection: PageInput
  ) {
    user(id: $id) {
      ...UserCommonWithCustomProfileConnection
      organizationPortfolios(pageInput: $pageInput) {
        ...PortfoliosConnection
      }
    }
    organization {
      id
      entryCampaignRankings(id: $id) {
        ...EntryCampaignRankingConnection
      }
    }
    ownedUserCollections(userId: $userId, pageInput: $pageInputCollection) {
      edges {
        node {
          ...UserCollectionBox
        }
      }
    }
    organizationArtistRankings(userId: $userId) {
      edges {
        node {
          ... on Ranking {
            ...RankingBaseConnection
            latestRankingPeriod(periodType: monthly) {
              ...RankingPeriodBaseConnection
              myRanking(userId: $userId) {
                ...RankingUserWithoutIdConnection
              }
            }
          }
        }
      }
    }
  }
  ${UserCommonWithCustomProfileConnectionFragmentDoc}
  ${PortfoliosConnectionFragmentDoc}
  ${EntryCampaignRankingConnectionFragmentDoc}
  ${UserCollectionBoxFragmentDoc}
  ${RankingBaseConnectionFragmentDoc}
  ${ArtistBottomTabFragmentDoc}
  ${RankingPeriodBaseConnectionFragmentDoc}
  ${RankingUserWithoutIdConnectionFragmentDoc}
`
export const UserTradeDetail = gql`
  query UserTradeDetail($tradeNegotiationId: ID!, $pageInput: PageInput) {
    tradeNegotiation(id: $tradeNegotiationId) {
      expiredAt
      formattedExpiredAt @client
      ...TradeBox
      comments(pageInput: $pageInput) {
        ...CommentsConnection
      }
    }
  }
  ${TradeBoxFragmentDoc}
  ${CommentsConnectionFragmentDoc}
  ${CommentBoxFragmentDoc}
  ${CommentBoxWithoutRepliesFragmentDoc}
`
export const Wallet = gql`
  query Wallet($gachaTicketId: ID!) {
    user {
      id
      displayName
      gachaTicket(id: $gachaTicketId) {
        available
        formattedAvailable @client
      }
      cryptoWallet {
        address
        walletType
      }
    }
    prepaidPointBalance {
      balance
      formattedPrepaidPointBarance @client
    }
    gachaTicket(id: $gachaTicketId) {
      ...GachaTicketConnection
    }
  }
  ${GachaTicketConnectionFragmentDoc}
  ${GachaTicketThumbnailsBoxFragmentDoc}
`

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AccountRecord: ['Artist', 'User'],
    CampaignRanking: ['ArtistCampaignRanking', 'UserCampaignRanking'],
    CreatePaymentMethodResponse: ['CreditCard', 'RequiresAction'],
    EntryCampaignMyRank: [
      'EntryCampaignArtistMyRank',
      'EntryCampaignUserMyRank',
    ],
    FeedData: ['Comment', 'Post'],
    MissionContent: [
      'ItemCollectContent',
      'QuizContent',
      'TwitterFollowContent',
      'TwitterRetweetContent',
    ],
    Node: [
      'AccomplishedMission',
      'Artist',
      'ArtistCustomer',
      'ArtistResource',
      'ArtistThreadChannel',
      'Campaign',
      'Collection',
      'CollectionItem',
      'Comment',
      'Copyright',
      'DeliveryItem',
      'EntryArtist',
      'Event',
      'EventCollection',
      'Feed',
      'Follower',
      'Gacha',
      'GachaTicket',
      'GachaTicketOrder',
      'Item',
      'ItemOrder',
      'ItemResaleRaffleUser',
      'ItemSerial',
      'LiveStream',
      'Mission',
      'Notification',
      'OrganizationNotification',
      'Patron',
      'Payout',
      'PendingPurchaseItem',
      'PickupUser',
      'Post',
      'PostReply',
      'PreSale',
      'PreSaleLotteryApplicant',
      'PrepaidPointTransaction',
      'PurchasablePrepaidPoint',
      'Ranking',
      'RankingUser',
      'RecentlyStoreViewedItem',
      'RecentlyViewedCollection',
      'ResaleItem',
      'ShortLink',
      'StepMission',
      'TradableUser',
      'TradeCandidate',
      'TradeNegotiation',
      'TradeRequest',
      'Transaction',
      'User',
      'UserCart',
      'UserCartTransactionOrder',
      'UserCollection',
      'UserItem',
      'UserStepMission',
    ],
    PaymentSource: ['CreditCard'],
    PendingSubscription: ['Patron', 'PendingPurchaseItem'],
  },
}
export default result
